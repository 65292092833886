import React from 'react';
import styles from './PermissionsRolesAccess.module.scss';
import DataGrid from '../../../Components/DataGridView/DataGridView';
import ButtonsBarLookup from '../../../Components/ButtonsBarLookup';
import RadioButton from '../../../Components/RadioButton';
import http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import Messages from '../../../Components/Messages';
import Util from '../../../Util';
import Button from '../../../Components/Button';
import Card from '../../../Components/Card';
import Dropdown from '../../../Components/Dropdown';
//import GroupsSearch from '../../UsersAndAccessRights/PermissionsListGroups/GroupsSearch';
//import UsersSearch from '../../UsersAndAccessRights/Users/UsersSearch';
import MultiSelect from '../../../Components/MultiSelect';
import * as wjcCore from '@grapecity/wijmo';
//import EditRights from '../EditRights';
import AlertBox from '../../../Components/AlertBox';
import Print from '../../../Print';

const $ = window.$;
export default class PermissionsRolesAccess extends React.Component {
  constructor(props) {
    super(props);
    this.doHotKeys = true;
    this.systemList = Util.getSystemOptions();
    this.copyTypeList = this.getCopyType();
    this.hash = null;
    this.state = {
      loading: true,
      pageInfo: this.setPageInfo(),
      systemList: this.systemList,
      mainRoleList: this.mainRoleList,
      copyTypeList: this.copyTypeList,
      system: undefined,
      data: [],
      visibleData: [],
      dataAccessCategoties: [],
      selectedRows: {},
      id: 0,
      statusFilter: $.strings.all,
      localFilter: '',
      roleId: 0,
      isDesktop: window.innerWidth > 1250,
    };
  }

  componentDidMount = async () => {
    if (!Util.checkISHR()) {
      Util.goTo('/Access');
      return;
    }
    if (!Util.checkIsMainUser()) {
      Util.goTo('/Access');
      return;
    }
    let mainRolesListPromise = this.GetCAccessGroups();
    let dataMainRoles = await mainRolesListPromise;

    let roleOptionPromise = this.getRolesOptionList();
    let dataRoleOptions = await roleOptionPromise;

    let roleValue = dataRoleOptions && dataRoleOptions.length > 0 ? dataRoleOptions[0] : undefined;
    if (this.props.match.params.id && this.props.match.params.id > 0) {
      roleValue = dataRoleOptions.find((e) => e.id + '' === this.props.match.params.id + '');
    }
    this.setState(
      {
        loading: false,
        mainRoleList: dataMainRoles,
        mainRole: dataMainRoles && dataMainRoles.length > 0 ? dataMainRoles[0] : undefined,
        roleOptionList: dataRoleOptions,
        roleOption: roleValue,
      },
      () => {
        this.getData(false);
      }
    );
    document.addEventListener('keydown', this.onPageKeyDown);
    document.addEventListener('resize', this.updatePredicate);
  };

  onPageKeyDown = async (e) => {
    if (e.ctrlKey && e.keyCode === Util.keyboardKeys.F5) return;
    if (!this.doHotKeys) return;
    this.doHotKeys = false;
    switch (e.keyCode) {
      case Util.keyboardKeys.F3: {
        e.preventDefault();
        await this.onSave();
        break;
      }
      case Util.keyboardKeys.F5: {
        e.preventDefault();
        await this.getData(false);
        break;
      }
      default: {
      }
    }
    this.doHotKeys = true;
  };

  searchUsers = (e) => {
    //this.dlgUsersSearch.show();
    this.setState({ usersSearchVisible: true });
  };

  onUsersSearchOk = (obj) => {
    let selectedUser = Util.getNameByUserLanguage(obj.name, obj.name_lang2);
    this.setState({ userName: selectedUser, roleId: obj.id, usersSearchVisible: false }, () => {
      this.getUserRole();
      this.getData(false);
    });
  };
  onUsersSearchClose = () => {
    this.setState({ usersSearchVisible: false });
  };

  searchCopyUsers = (e) => {
    //this.dlgUsersCopySearch.show();
    this.setState({ usersCopySearchVisible: true });
  };

  onUsersCopySearchOk = (obj) => {
    let selectedUser = Util.getNameByUserLanguage(obj.name, obj.name_lang2);
    this.setState({ userNameCopyFrom: selectedUser, userCopyId: obj.id, usersCopySearchVisible: false });
  };
  onUsersCopySearchClose = () => {
    this.setState({ usersCopySearchVisible: false });
  };
  searchMainRole = (e) => {
    //this.dlgMainRole.show();
    this.setState({ mainRoleVisible: true, mainRoleId: undefined });
  };
  searchOtherRole = (e) => {
    let mainId = !this.state.mainRole ? 0 : this.state.mainRole.id;
    if (mainId <= 0) {
      Util.showErrorMsg(this.messages, $.strings.PermissionsRolesAccess.checkMainRole);
      return;
    }
    this.setState({ mainRoleId: mainId, otherRoleVisible: true });
    //this.dlgOtherRole.show(mainId);
  };
  onMainRoleSearchOk = (obj) => {
    let selectedValue = this.state.mainRoleList.filter((element) => element.id === obj.id);
    if (selectedValue && selectedValue.length > 0) {
      selectedValue = selectedValue[0];
    }
    this.setState({ mainRole: selectedValue, mainRoleVisible: false });
  };
  onMainRoleSearchClose = () => {
    this.setState({ mainRoleVisible: false });
  };
  onOtherRoleSearchOk = (obj) => {
    if (!this.state.otherRolesList || this.state.otherRolesList.length <= 0) {
      return;
    }
    let selectedValue = this.state.otherRolesList.filter((element) => element.id === obj.id);
    if (selectedValue && selectedValue.length > 0) {
      selectedValue = selectedValue[0];
    }
    this.setState({ otherRole: selectedValue, otherRoleVisible: false });
  };
  onOtherRoleSearchClose = () => {
    this.setState({ otherRoleVisible: false });
  };
  getMainRolesList = async () => {
    let dataTemp;
    const params = {id:-1};
    let res = await http.getJsonAxios(http.actions.Roles.urlGetRoles, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
      if (dataTemp) {
        dataTemp.splice(0, 0, {
          id: -1,
          name: $.strings.all,
          name_lang2: $.strings.all,
        });
      }
    }

    return dataTemp;
  };
  GetCAccessGroups = async () => {
    let dataTemp = [];
    const params = {
      id: -1,
    };

    let res = await http.getJsonAxios(http.actions.Roles.urlGetCAccessGroups, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
      dataTemp.splice(0, 0, {
        id: -1,
        name: $.strings.all,
        name_lang2: $.strings.all,
      });
    }
    return dataTemp;
  };

  handleMainRole = (e) => {
    const value = e.target.value;

    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    this.setState({ mainRole: value }, () => {
      Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      this.getSubRole(value);
    });
  };
  getSubRole = async (mainRole) => {
    let dataTemp;
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    if (mainRole.id === -1) {
      this.setState({ otherRoles: undefined, otherRolesList: [] });
      return;
    }
    const params = {
      id: mainRole.id,
    };
    let res = await http.getJsonAxios(http.actions.Roles.urlGetSubRoles, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
      this.setState(
        {
          otherRolesList: dataTemp,
          otherRoles: dataTemp && dataTemp.length > 0 ? dataTemp[0] : undefined,
        },
        () => {
          Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
        }
      );
    }
  };
  getRolesOptionList = async () => {
    let dataTemp;
    const params = {
      id :-1
    };
    let res = await http.getJsonAxios(http.actions.Roles.urlGetRoles, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
    }
    return dataTemp;
  };

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 1250 });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  render() {
    const gridStyle = { minHeight: '50vh' };

    return (
      <div ref={(e) => (this.divMain = e)} className={styles.PermissionsRolesAccess}>
        <ProgressSpinner loading={this.state.loading} />
        <div className="p-grid">
          <h2 className={styles.title}>{this.state.pageInfo.title}</h2>
        </div>
        {/* {this.state.mainRoleVisible && (
          <GroupsSearch ref={(ref) => (this.dlgMainRole = ref)} onSearchSuccess={this.onMainRoleSearchOk} onClose={this.onMainRoleSearchClose} />
        )}
        {this.state.otherRoleVisible && (
          <GroupsSearch
            ref={(ref) => (this.dlgOtherRole = ref)}
            mainRoleId={this.state.mainRoleId}
            onSearchSuccess={this.onOtherRoleSearchOk}
            onClose={this.onOtherRoleSearchClose}
          />
        )} */}
        {/* {this.state.usersSearchVisible && (
          <UsersSearch ref={(ref) => (this.dlgUsersSearch = ref)} onSearchSuccess={this.onUsersSearchOk} onClose={this.onUsersSearchClose} />
        )}
        {this.state.usersCopySearchVisible && (
          <UsersSearch
            ref={(ref) => (this.dlgUsersCopySearch = ref)}
            onSearchSuccess={this.onUsersCopySearchOk}
            onClose={this.onUsersCopySearchClose}
          />
        )} */}

        {this.state.saveBoxVisible && <AlertBox ref={(e) => (this.saveBox = e)} header={$.strings.appName} defaultButton={Util.defaultButton.No} />}

        {/* {this.state.editRightsVisible && (
          <EditRights
            ref={(e) => (this.dlgEditRights = e)}
            id={this.state.id}
            is_granted={this.state.is_granted}
            role_is_granted={this.state.role_is_granted}
            with_granted={this.state.with_granted}
            onSuccess={this.handleUserInputAccessRights}
            onClose={this.handleUserInputAccessRightsClose}
          />
        )} */}
        <Card>
          {/*Buttons*/}
          <div className="p-grid">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
              <ButtonsBarLookup
                onSave={(e) => this.onSave(e, true)}
                onPrevious={(e) => this.onPrevious(e, true)}
                onNext={(e) => this.onNext(e, true)}
                onRefresh={(e) => this.onRefresh(e, true)}
                onPrint={(e) => this.onPrint(e, true)}
                onExcelExport={(e) => this.onExcelExport()}
                pageId={this.state.pageInfo.pageId}
                parent={this}
              />
            </div>
          </div>
          <Messages innerRef={(el) => (this.messages = el)} />
        </Card>

        <div className="p-grid">
          <div className="p-col-12 p-xl-3 p-lg-12 p-md-12 p-sm-12">
            <Card style={{ minHeight: '639px' }}>
              <div className="p-grid">
                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                  {/*المجموعة الرئيسية*/}
                  <Dropdown
                    id="mainRole"
                    value={this.state.mainRole}
                    options={this.state.mainRoleList}
                    onChange={this.handleMainRole}
                    caption={$.strings.PermissionsRolesAccess.mainRole}
                    optionLabel="name"
                    optionLabelLang2="name_lang2"
                    filter
                    // withgroup={true}
                    // btn1icon="search"
                    // btn1event={this.searchMainRole}
                  />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                  {/*المجموعة الفرعية*/}
                  <Dropdown
                    id="otherRoles"
                    value={this.state.otherRoles}
                    options={this.state.otherRolesList}
                    onChange={this.handleUserInput}
                    caption={$.strings.PermissionsRolesAccess.subRole}
                    optionLabel="name"
                    optionLabelLang2="name_lang2"
                    filter
                    // withgroup={true}
                    // btn1icon="search"
                    // btn1event={this.searchOtherRole}
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className="p-col-12 p-xl-9 p-lg-12 p-md-12 p-sm-12">
            <Card>
              <Card>
                {/* User Card */}
                <div className="p-grid">
                  <div className="p-col-12 p-xl-4 p-lg-12 p-md-12 p-sm-12">
                    <Dropdown
                      id="roleOption"
                      value={this.state.roleOption}
                      options={this.state.roleOptionList}
                      onChange={(e) => this.onRoleChange(e, true)}
                      caption={$.strings.PermissionsRolesAccess.roleOption}
                      optionLabel="name"
                      optionLabelLang2="name_lang2"
                      filter
                    />
                  </div>
                  <div className="p-lg-12 p-xl-8 p-lg-10 p-md-12 p-sm-12">
                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">{$.strings.PermissionsRolesAccess.status}</div>
                    <div className="p-grid">
                      <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                        <RadioButton
                          inputId="rbAll"
                          value={$.strings.all}
                          name="Status"
                          onChange={(e) => {
                            let rowIndex = this.grid.flex.selection.row;
                            let colIndex = this.grid.flex.selection.col;
                            this.setState({ statusFilter: e.value }, () => {
                              Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
                              this.getData();
                            });
                          }}
                          checked={this.state.statusFilter === $.strings.all}
                        />
                      </div>
                      <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                        <RadioButton
                          inputId="rbInactive"
                          value={$.strings.PermissionsRolesAccess.inactive}
                          name="Status"
                          onChange={(e) => {
                            let rowIndex = this.grid.flex.selection.row;
                            let colIndex = this.grid.flex.selection.col;
                            this.setState({ statusFilter: e.value }, () => {
                              Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
                              this.getData();
                            });
                          }}
                          checked={this.state.statusFilter === $.strings.PermissionsRolesAccess.inactive}
                        />
                      </div>
                      <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                        <RadioButton
                          inputId="rbActive"
                          value={$.strings.PermissionsRolesAccess.active}
                          name="Status"
                          onChange={(e) => {
                            let rowIndex = this.grid.flex.selection.row;
                            let colIndex = this.grid.flex.selection.col;
                            this.setState({ statusFilter: e.value }, () => {
                              Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
                              this.getData();
                            });
                          }}
                          checked={this.state.statusFilter === $.strings.PermissionsRolesAccess.active}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              <Card header={<h2>{$.strings.PermissionsRolesAccess.rightsList}</h2>}>
                <div className="p-grid">
                  <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                    <Button
                      tooltip={$.strings.PermissionsRolesAccess.activeAll}
                      icon="pi pi-check-square"
                      className="p-button-success"
                      onClick={this.onActiveAll}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      tooltip={$.strings.PermissionsRolesAccess.inActiveAll}
                      icon="pi pi-times-circle"
                      className="p-button-danger"
                      onClick={this.onInActiveAll}
                    />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-12 p-xl-12 p-md-12 p-sm-12">
                    <DataGrid
                      style={gridStyle}
                      ref={(e) => (this.grid = e)}
                      exportFileName={this.state.pageInfo.title}
                      isReport={false}
                      theme="default-light"
                      idProperty="ser"
                      scheme={this.getScheme()}
                      dataSource={new wjcCore.CollectionView(this.state.data, { getError: this.getError.bind(this) })}
                      onRowDoubleClick={this.onRowDoubleClick}
                      pageId={this.state.pageInfo.pageId}
                    />
                  </div>
                </div>
              </Card>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  getError = (item, propName, parsing = false) => {
    switch (propName) {
      default: {
        break;
      }
    }
    return null;
  };

  onRoleChange = (e, checkChanging) => {
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      this.getData(false);
    });
  };
  onActiveAll = () => {
    let tempData = this.state.data;
    if (tempData) {
      let rowIndex = this.grid.flex.selection.row;
      let colIndex = this.grid.flex.selection.col;
      tempData.forEach((row) => (row.is_granted = true));
      this.setState({ data: tempData }, () => {
        Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      });
    }
  };
  onInActiveAll = () => {
    let tempData = this.state.data;
    if (tempData) {
      let rowIndex = this.grid.flex.selection.row;
      let colIndex = this.grid.flex.selection.col;
      tempData.forEach((row) => {
        row.is_granted = false;
      });
      this.setState({ data: tempData }, () => {
        Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      });
    }
  };
  onRefresh = (e) => {
    this.getData(false);
  };
  setAccessCategoties = (e) => {
    this.setState({ accessCategoties: e.target.value });
  };
  classTypeItemTemplate(option) {
    return <span>{option.name ? option.name : option.caption}</span>;
  }

  btnRefresh = (e) => {
    this.setState({ data: this.getData() });
  };

  onRoleIsGrantedChanged = (rowIndex, value) => {
    let tempData = this.state.data;
    if (tempData) {
      tempData[rowIndex].role_is_granted = value;
      this.setState({ data: tempData });
    }
  };
  onDisabledChanged = () => {};

  onEditClick = (e, ctx) => {
    //this.dlgEditRights.show(ctx.item.access_id, ctx.item.is_granted);
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;
    this.popupHasCalled();
    this.setState(
      { editRightsVisible: true, id: ctx.item.access_id, is_granted: ctx.item.is_granted, role_is_granted: ctx.item.role_is_granted },
      () => {
        Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      }
    );
  };

  getScheme = () => {
    return {
      name: 'PermissionsRolesAccessScheme_Table_' + this.props.lookupType,
      filter: true,
      showFooter: true,
      showFilter: true,
      responsiveColumnIndex: 3,
      columns: [
        { name: 'ser', header: '##', width: 70, dataType: 'Number', aggregate: 'Cnt', isReadOnly: true, columnWidth: '2' },
        {
          name: 'btnEdit',
          header: ' ',
          width: 50,
          buttonBody: 'button',
          align: 'center',
          title: ' ',
          iconType: 'edit',
          className: 'warning',
          onClick: (e, ctx) => this.onEditClick(e, ctx),
        },
        { name: 'access_id', header: $.strings.PermissionsRolesAccess.access_id, isReadOnly: true, columnWidth: '2' },
        {
          name: 'access_name',
          header: $.strings.PermissionsRolesAccess.access_name,
          visible:true,
          visibleInColumnChooser: true,
          width: '*',
          minwidth: 150,
          isReadOnly: true,
          columnWidth: '*',
        },
        {
          name: 'is_granted',
          header: $.strings.PermissionsRolesAccess.is_granted,
          columnWidth: '2',
          isReadOnly: false,
          visible: this.state.isDesktop,
          visibleInColumnChooser: this.state.isDesktop,
        },
        {
          name: 'is_granted_name',
          header: $.strings.PermissionsRolesAccess.is_granted,
          columnWidth: '2',
          isReadOnly: false,
          visible: !this.state.isDesktop,
          visibleInColumnChooser: !this.state.isDesktop,
        },
      ],
    };
  };

  validateField() {

    if (!this.state.roleOption || this.state.roleOption.id === -1) {
      Util.showErrorMsg(this.messages, $.strings.PermissionsRolesAccess.userISEmpty);
      return false;
    }
    return true;
  }

  onSave = async () => {
    if (!this.validateField()) {
      this.setState({ loading: false });
      return;
    }

    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    this.setState({ loading: true }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));

    let success = true;
    let result = await this.saveData();
    if (result.status === 200) {
      Util.showSuccessMsg(this.messages, $.strings.showSuccessMsg);
      success = true;
    } else if (result.status === 400) {
      Util.showErrorMsg(this.messages, result.message);
      success = false;
    } else {
      Util.showErrorMsg(this.messages);
      success = false;
    }
    this.setState({ loading: false }, () => {
      Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      this.getData(false);
    });
    return success;
  };

  saveData = async () => {
    let accessList = [];
    if (this.state.data) {
      let temp = this.state.data;
      for (let i = 0; i < temp.length; i++) {

        accessList.push({
          access_id: temp[i].access_id,
          
          is_granted : temp[i].is_granted+"" ==="true"?1:0
        });
      }
    }
    let obj = {
      selected_role_id: this.state.roleOption.id,
      list : accessList,
    };
    let res = await http.postMultipartAxios(http.actions.Roles.urlSaveCAccessGroups, obj, this.state.pageInfo.pageId);
    return res;
  };

  getStatusId = () => {
    switch (this.state.statusFilter) {
      case $.strings.PermissionsRolesAccess.active: {
        return 1;
      }
      case $.strings.PermissionsRolesAccess.inactive: {
        return 0;
      }
      default: {
        return -1;
      }
    }
  };
  handleUserInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    this.setState({ [name]: value }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
  };

  handleUserInputAccessRights = (id, is_granted) => {
    let _data = this.state.data;
    _data = _data.map((el) =>
      el.access_id + '' === id + ''
        ? {
            ...el,
            is_granted: is_granted,
            is_granted_name: is_granted ? $.strings.PermissionsRolesAccess.active : $.strings.PermissionsRolesAccess.inactive,
          }
        : el
    );
    this.setState({ data: _data });
  };

  handleUserInputAccessRightsClose = () => {
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;
    this.setState({ editRightsVisible: false, id: undefined, is_granted: undefined, role_is_granted: undefined }, () => {
      Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      this.popupHasClosed();
    });
  };

  getData = async (fromLoad) => {
    let dataTemp = [];
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    if (!fromLoad) {
      this.setState({ loading: true }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    }
    if (this.state.roleOption.id === -1) {
      Util.showErrorMsg(this.messages, $.strings.PermissionsRolesAccess.userISEmpty);
      this.setState({ data: dataTemp, loading: false }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
      return dataTemp;
    }
    const params = {
      role_id: this.state.roleOption.id,
      group_id:this.state.mainRole ? this.state.mainRole.id:-1,
      sub_group_id: this.state.otherRoles ? this.state.otherRoles.id : -1,
      is_granted: this.getStatusId(),
    };
    let res = await http.postMultipartAxios(http.actions.Roles.urlGetCAccessGroups, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
      dataTemp.forEach((e) => {
        e.is_granted_name = e.is_granted ? $.strings.PermissionsRolesAccess.active : $.strings.PermissionsRolesAccess.inactive;
      });
    }
    if (fromLoad) return dataTemp;
    else {
      this.setState({ data: dataTemp, loading: false }, () => {
        this.printFilters = this.state;
        Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      });
    }
  };

  onAddClose = (doRefreh) => {
    if (doRefreh) this.getData(false);
    this.setState({ dlgAddVisible: false });
  };

  onAddSuccess = (result) => {
    if (result) {
      this.setState({ dlgAddVisible: false });
      this.getData(false);
    }
  };

  setPageInfo = () => {
    let pageId;
    let title;

    pageId = 0;
    title = $.strings.PermissionsRolesAccess.title;

    return { pageId: pageId, title: title };
  };
  getCopyType = () => {
    let values = [
      { id: 1, name: $.strings.PermissionsRolesAccess.TypeOption1 },
      { id: 2, name: $.strings.PermissionsRolesAccess.TypeOption2 },
      { id: 3, name: $.strings.PermissionsRolesAccess.TypeOption3 },
    ];
    return values;
  };


  popupHasCalled = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
  };
  popupHasClosed = () => {
    document.addEventListener('keydown', this.onPageKeyDown);
  };

  onNext = async (e, checkChanging) => {
   let selectedRowNumber =this.state.roleOption.row_number;
    let objRowNumber = this.state.roleOptionList.find(e=>e.row_number+"" ===selectedRowNumber+"");
    if(objRowNumber !==undefined)
    {
      let index = parseInt(objRowNumber.row_number);
      index= index+1;

      objRowNumber = this.state.roleOptionList.find(e=>e.row_number+"" ===index+"");
      if(objRowNumber !==undefined)
      {
        this.setState({roleOption:objRowNumber},async()=>
        {
          await this.getData(false);
        });
 
      }
      else{
 Util.showInfoMsg(this.messages,$.strings.recordsEnds);
      }
    }
  };

  onPrevious = async (e, checkChanging) => {
    let selectedRowNumber =this.state.roleOption.row_number;
    let objRowNumber = this.state.roleOptionList.find(e=>e.row_number+"" ===selectedRowNumber+"");
    if(objRowNumber !==undefined)
    {
      let index = parseInt(objRowNumber.row_number);
      index= index-1;

      objRowNumber = this.state.roleOptionList.find(e=>e.row_number+"" ===index+"");
      if(objRowNumber !==undefined)
      {
        this.setState({roleOption:objRowNumber},async()=>
        {
          await this.getData(false);
        });
 
      }
      else{
 Util.showInfoMsg(this.messages,$.strings.recordsBeginning);
      }
    }
  };

  onPrint = async () => {
    if (!this.state.data || this.state.data.length <= 0) {
      Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint);
      return;
    }

    let obj = Print.getReportPrintObject(this.state.pageInfo.title, this.grid, this.state.data);
    let listOfFilters = [];
    if (this.printFilters) {
      if (this.printFilters.roleOption) {
        let name = Util.getNameByUserLanguage(this.printFilters.roleOption.name, this.printFilters.roleOption.name_lang2);
        listOfFilters.push($.strings.PermissionsRolesAccess.roleOption + ': ' + name);
      }
      if (this.printFilters.mainRole) {
        let name = Util.getNameByUserLanguage(this.printFilters.mainRole.name, this.printFilters.mainRole.name_lang2);
        listOfFilters.push($.strings.PermissionsRolesAccess.mainRole + ': ' + name);
      }
      if (this.printFilters.otherRoles) {
        let name = Util.getNameByUserLanguage(this.printFilters.otherRoles.name, this.printFilters.otherRoles.name_lang2);
        listOfFilters.push($.strings.PermissionsRolesAccess.subRole + ': ' + name);
      }
      listOfFilters.push($.strings.PermissionsRolesAccess.status + ': ' + this.state.statusFilter);
    }

    obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers);
    Print.doPrintReport(obj);
  };
  doPrint = async () => {
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    this.setState({ loading: true }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    let result = false;
    const params = {
      status: this.getStatusId(),
    };

    let res = await http.getJsonAxios(http.actions.StockModel.urlGetSavePrintLogLookups, params, this.state.pageInfo.pageId);
    if (res.status === 200) result = true;
    this.setState({ loading: false }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    return result;
  };

  onExcelExport = async () => {
      this.grid.excelExport();
    
  };
  doExcelExport = async () => {
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    this.setState({ loading: true }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    let result = false;
    const params = {
      status: this.getStatusId(),
    };
    let res = await http.getJsonAxios(http.actions.classification.urlGetSaveExportToExcelLogLookups, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
    }
    this.setState({ loading: false }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    return result;
  };
}
