import React from "react";
import styles from './SalarySlip.module.scss';
import Util from '../../Util';
import http from '../../Api/http';
import Card from '../../Components/Card';
import ProgressSpinner from "../../Components/ProgressSpinner";
import Dropdown from "../../Components/Dropdown";
import SiteMap from "../../Components/SiteMap";
import Button from "../../Components/Button";
import Messages from "../../Components/Messages";
import SalaryTrans from './SalaryTrans';
import { Accordion, AccordionTab } from "primereact/accordion";

const $ = window.$

export default class SalarySlip extends React.Component {
    constructor(props) {
        super(props);
        this.canPrintSalarySlip = false;
        this.state = {
            yearsList: [],
            monthsList: [],
            loading: true,
            isDesktop: true,
            listOfControl: [],
            activeIndex: 0,
            dataObject: {
                empCode: '',
                year: { id: new Date().getFullYear(), name: new Date().getFullYear() },
                month: { id: new Date().getMonth(), name: new Date().getMonth() },
                employeeCode: '',
                employeeName: '',
                appDate: '',
                degree: '',
                idNo: '',
                address: '',
                job: '',
                degreeStep: '',
                section: '',
                salaryType: '',
                classification: '',
                gradeYearOfSeniority: '',
                yearsOfSeniority: '',
                companyName: '',
                companyAddress: ''
            }
        };
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!this.props.match.params.id) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(657)) {
                Util.goTo('/Access');
                return;
            }
        }

        let empCode = ''
        if (this.props.match.params.id) {
            empCode = this.props.match.params.id;
        }
        else {
            empCode = localStorage.getItem("useridHR")
        }

        this.setState({
            yearsList: this.getYearsList(), monthsList: this.getMonthsList(),
            dataObject: { ...this.state.dataObject, empCode: empCode }, isDesktop: _isDesktop, loading: false
        });
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    render() {
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../../MyStaff' },
            { label: $.strings.salarySlip.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div ref="divMain" >
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <Messages innerRef={(el) => this.messages = el} />
                <div className="p-grid" ref={e => this.headerRef = e} style={{ display: 'none' }}>
                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                        <div className="p-grid">
                            <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: 'center' }}>
                                <br />
                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.companyName}</div>
                                <br />
                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.companyAddress}</div>
                            </div>
                            <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                                <img src="../../assets/layout/images/printlogo.png" alt="logo" />
                            </div>
                            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-grid" ref={e => this.employeeRef = e} style={{ display: 'none' }}>
                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                        <div className="p-grid">
                            <div className="p-col-4 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                {$.strings.salarySlip.employeeCode + ': ' + this.state.dataObject.employeeCode}
                            </div>
                            <div className="p-col-4 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                {$.strings.salarySlip.empName + ': ' + this.state.dataObject.employeeName}
                            </div>
                            <div className="p-col-4 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                {$.strings.salarySlip.idNo + ': ' + this.state.dataObject.idNo}
                            </div>
                            <div className="p-col-4 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                {$.strings.salarySlip.appDate + ': ' + this.state.dataObject.appDate}
                            </div>
                            <div className="p-col-4 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                {$.strings.salarySlip.job + ': ' + this.state.dataObject.job}
                            </div>
                            <div className="p-col-4 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                {$.strings.salarySlip.classification + ': ' + this.state.dataObject.classification}
                            </div>
                        </div>
                    </div>
                </div>
                <Accordion activeIndex={this.state.activeIndex} onTabChange={e => this.setState({ activeIndex: e.index })}>
                    <AccordionTab header={$.strings.filterCaption}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12">
                                <Dropdown
                                    id="year"
                                    innerRef={e => this.drpYear = e}
                                    value={this.state.dataObject.year}
                                    options={this.state.yearsList}
                                    caption={$.strings.salarySlip.year}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    placeholder={$.strings.salarySlip.selectYear}
                                    isRequired={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12">
                                <Dropdown
                                    id="month"
                                    innerRef={e => this.drpMonth = e}
                                    value={this.state.dataObject.month}
                                    options={this.state.monthsList}
                                    caption={$.strings.salarySlip.month}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    placeholder={$.strings.salarySlip.selectMonth}
                                    isRequired={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-1 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Button label={$.strings.salarySlip.showData} onClick={this.getSalaryInfo} />
                            </div>
                            <div className="p-col-12 p-xl-1 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Button label={$.strings.salarySlip.print} onClick={this.onPrint} className={styles.innerButton} />
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header={$.strings.employeeInfo.title}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                <div className="p-grid">
                                    <div className="p-col-6 p-xl-2 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.empCode + ': ' + this.state.dataObject.employeeCode}
                                    </div>
                                    <div className="p-col-6 p-xl-4 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.empName + ': ' + this.state.dataObject.employeeName}
                                    </div>
                                    <div className="p-col-6 p-xl-4 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.idNo + ': ' + this.state.dataObject.idNo}
                                    </div>
                                    <div className="p-col-6 p-xl-2 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.degree + ': ' + this.state.dataObject.degree}
                                    </div>
                                    <div className="p-col-6 p-xl-2 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.appDate + ': ' + this.state.dataObject.appDate}
                                    </div>
                                    <div className="p-col-6 p-xl-4 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.job + ': ' + this.state.dataObject.job}
                                    </div>
                                    <div className="p-col-6 p-xl-4 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.classification + ': ' + this.state.dataObject.classification}
                                    </div>
                                    <div className="p-col-6 p-xl-2 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.degreeStep + ': ' + (this.state.dataObject.degreeStep ? this.state.dataObject.degreeStep : '')}
                                    </div>
                                    <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.address + ': ' + this.state.dataObject.address}
                                    </div>
                                    <div className="p-col-6 p-xl-4 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.section + ': ' + this.state.dataObject.section}
                                    </div>
                                    <div className="p-col-6 p-xl-2 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.salaryType + ': ' + this.state.dataObject.salaryType}
                                    </div>
                                    <div className="p-col-6 p-xl-2 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.gradeYearOfSeniority + ': ' + this.state.dataObject.gradeYearOfSeniority}
                                    </div>
                                    <div className="p-col-6 p-xl-2 p-lg-6 p-md-6 p-sm-12">
                                        {$.strings.salarySlip.yearsOfSeniority + ': ' + this.state.dataObject.yearsOfSeniority}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>

                <Card >
                    <div ref={e => this.printRef = e} className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            {
                                this.state.listOfControl
                            }
                        </div>
                    </div>
                    <h1 ref={e => this.footerMsg = e} style={{ textAlign: 'center', color: 'red' }}>{$.strings.salarySlip.salaryHint}</h1>
                </Card>
                <SalaryTrans ref={e => this.dlgSalaryTrans = e}></SalaryTrans>
                <SalaryTrans type="Rewards" ref={e => this.dlgSalaryTransRewards = e}></SalaryTrans>
            </div >
        )
    }

    getYearsList = () => {
        let yearsList = [];
        let currentYear = new Date().getFullYear();
        for (let i = currentYear - 10; i <= currentYear; i++) {
            yearsList.push({ id: i, name: i })
        }
        return yearsList;
    }
    getMonthsList = () => {
        let monthsList = [];
        for (let i = 1; i <= 12; i++) {
            monthsList.push({ id: i, name: i })
        }
        return monthsList;
    }
    getSalaryInfo = async () => {
        this.messages.clear();
        this.canPrintSalarySlip = false;
        if (!this.state.dataObject.year) {
            Util.showErrorMsg(this.messages, $.strings.salarySlip.selectYearError);
            return;
        }
        if (!this.state.dataObject.month) {
            Util.showErrorMsg(this.messages, $.strings.salarySlip.selectMonthError);
            return;
        }
        this.setState({ loading: true });
        let result = []
        let obj = {
            empCode: this.props.match.params.id && this.props.match.params.id.length > 0 ? this.props.match.params.id :
                Util.encryptStringToHexa(localStorage.getItem("useridHR") + ""),
            year: this.state.dataObject.year.id,
            month: this.state.dataObject.month.id,
            isHr: Util.checkISHR(),
            userId: localStorage.getItem('useridHR')
        }

        let res = await http.getJsonAxios(http.actions.Employee.urlGetSalaryInfo, obj, 0);
        if (res.status === 200) {
            result = res.data.success;
        }
        if (result) {
            // draw salary info
            this.drawControl(res);
        }
        else {
            this.setState({ listOfControl: [], loading: false });
        }
    }

    onPrint = () => {
        this.messages.clear();
        if (!this.canPrintSalarySlip) {
            Util.showErrorMsg(this.messages, $.strings.salarySlip.noSalaryData);
            return;
        }
        const styles = Array.from(document.styleSheets)
            .map(styleSheet => {
                try {
                    return Array.from(styleSheet.cssRules)
                        .map(rule => rule.cssText)
                        .join('');
                } catch (e) {
                    return '';
                }
            })
            .join('');

        let title = $.strings.salarySlip.printTitle + " (" + this.state.dataObject.month.id + "/" + this.state.dataObject.year.id + ")";

        const newWindow = window.open('', '_blank');
        let html = "<html dir=" + $.strings.dir + "><head><title>" + title + "</title>";
        newWindow.document.write(html);
        newWindow.document.write(`<style>${styles}</style>`);
        newWindow.document.write('<style>@media print { body { -webkit-print-color-adjust: exact; } }</style>'); // Add any styles you need
        newWindow.document.write('</head><body >');

        const headerContent = this.headerRef.innerHTML
        newWindow.document.write(headerContent);

        let titleContent = "<h3 style='width:100%; text-align:center'>" + title + "</h3>";
        newWindow.document.write(titleContent);

        const employeeContent = this.employeeRef.innerHTML;
        newWindow.document.write(employeeContent);

        const printContent = this.printRef.innerHTML;
        newWindow.document.write(printContent);

        const footerContent = "<h3 style='width:100%; text-align:center; color: red; '>" + $.strings.salarySlip.salaryHint + "</h3>";
        newWindow.document.write(footerContent);
        newWindow.document.write("</body></html>");
        newWindow.document.close();
        newWindow.focus();
        setTimeout(() => {
            newWindow.print();
            newWindow.close();
        }, 100);
    }

    moreDetails = async (e, key) => {
        let year = this.state.dataObject.year.id;
        let month = this.state.dataObject.month.id;
        let empCode = this.props.match.params.id && this.props.match.params.id.length > 0 ? this.props.match.params.id :
            Util.encryptStringToHexa(localStorage.getItem("useridHR") + "");

        if (key === "Rewards") {
            await this.dlgSalaryTransRewards.show(empCode, key, month, year);
        }
        else {
            await this.dlgSalaryTrans.show(empCode, key, month, year);
        }
    }

    drawControl = (res) => {
        let SalaryDetailsObj = res.data.SalaryDetailsObj; // بنود الراتب
        let SalaryTotalObj = res.data.SalaryTotalObj; // الاجمالي - مجموع بنود الراتب
        let SalaryDiscountsObj = res.data.SalaryDiscountsObj; // بنود الخصميات
        let SalaryDiscountsTotalObj = res.data.SalaryDiscountsTotalObj; // مجموع الخصميات
        let NetSalaryObj = res.data.NetSalaryObj; // صافي الراتب

        let SalaryRewardsObj = res.data.SalaryRewardsObj; // تفاصيل المكافآت
        let CreditAdjustObj = res.data.CreditAdjustObj; // تفاصيل التسويات الدائنة
        let DeductionsObj = res.data.DeductionsObj; // تفاصيل السلف والاقتطاعات
        let LoansObj = res.data.LoansObj; // تفاصيل القروض

        let SalaryDetObj = res.data.SalaryDetObj; // تفاصيل الاجازات
        let SavingFundDetailsObj = res.data.SavingFundDetailsObj; // مساهمة صندوق التوفير
        let UnpaidLoansObj = res.data.UnpaidLoansObj; // قروض غير مسددة
        let BankDetailsObj = res.data.BankDetailsObj; // تفاصيل البنك
        let taxCreditAdjustObj = res.data.TaxCreditAdjustObj; // تفاصيل التسويات الدائنة الضريبية
        // console.log(res.data)
        // console.log(taxCreditAdjustObj)
        let objEmployee = res.data.employeeSalaryInfo;
        let listOfControls = [];

        listOfControls.push(
            <div className="p-grid" >
                <div className="p-col-12 p-lg-4 p-sm-12">
                    <Card title="" showFooter={false} >
                        {/* بنود الراتب */}
                        {
                            // سطر بنود الراتب
                            SalaryDetailsObj && SalaryDetailsObj.Text &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                    {
                                        SalaryDetailsObj.Text
                                    }
                                </div>
                            </div>
                        }
                        {
                            // أسطر بنود الراتب، مثل الراتب الاساسي
                            SalaryDetailsObj && SalaryDetailsObj.SalaryDetailslist && SalaryDetailsObj.SalaryDetailslist.length > 0 &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                {
                                    SalaryDetailsObj.SalaryDetailslist.map(item => (
                                        <>
                                            <div className="p-col-6 p-lg-6 p-sm-6" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                {item.Text}
                                            </div>
                                            <div className="p-col-6 p-lg-6 p-sm-6" style={{ fontSize: '14px', textAlign: 'center', borderBottom: 'solid 1px gray' }}>
                                                <span className={item.Key ? styles.moreDetails : ''} onClick={e => item.Key ? this.moreDetails(e, item.Key) : null}>
                                                    {item.Value}
                                                </span>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        }
                        {
                            // سطر الاجمالي - مجموع بنود الراتب
                            SalaryTotalObj && SalaryTotalObj.Text &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                <div className="p-col-6 p-lg-6 p-sm-6" style={{ textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                    {
                                        SalaryTotalObj.Text
                                    }
                                </div>
                                <div className="p-col-6 p-lg-6 p-sm-6" style={{ textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                    {
                                        SalaryTotalObj.Value
                                    }
                                </div>
                            </div>
                        }
                        <br />
                        {/* الخصميات */}
                        {
                            // سطر بنود الخصميات
                            SalaryDiscountsObj && SalaryDiscountsObj.Text && SalaryDiscountsObj.SalaryDiscountsList && SalaryDiscountsObj.SalaryDiscountsList.length > 0 &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                    {
                                        SalaryDiscountsObj.Text
                                    }
                                </div>
                            </div>
                        }
                        {
                            // أسطر بنود الخصميات، مثل خصم مغادرات
                            SalaryDiscountsObj && SalaryDiscountsObj.SalaryDiscountsList && SalaryDiscountsObj.SalaryDiscountsList.length > 0 &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                {SalaryDiscountsObj.SalaryDiscountsList.map(item => (
                                    <>
                                        <div className="p-col-6 p-lg-6 p-sm-6" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                            {item.Text}
                                        </div>
                                        <div className="p-col-6 p-lg-6 p-sm-6" style={{ fontSize: '14px', textAlign: 'center', borderBottom: 'solid 1px gray' }}>
                                            <span className={item.Key ? styles.moreDetails : ''} onClick={e => item.Key ? this.moreDetails(e, item.Key) : null}>
                                                {item.Value}
                                            </span>
                                        </div>
                                    </>
                                ))
                                }
                            </div>
                        }
                        {
                            // سطر مجموع الخصميات
                            SalaryDiscountsTotalObj && SalaryDiscountsTotalObj.Text && SalaryDiscountsObj.SalaryDiscountsList && SalaryDiscountsObj.SalaryDiscountsList.length > 0 &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                <div className="p-col-6 p-lg-6 p-sm-6" style={{ textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                    {
                                        SalaryDiscountsTotalObj.Text
                                    }
                                </div>
                                <div className="p-col-6 p-lg-6 p-sm-6" style={{ textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                    {
                                        SalaryDiscountsTotalObj.Value
                                    }
                                </div>
                            </div>
                        }
                        <br />
                        {/* صافي الراتب */}
                        {
                            // سطر صافي الراتب
                            NetSalaryObj && NetSalaryObj.Text.length > 0 &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                <div className="p-col-5 p-lg-5 p-sm-5" style={{ textAlign: "center", backgroundColor: "black", fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
                                    {
                                        NetSalaryObj.Text
                                    }
                                </div>
                                <div className="p-col-5 p-lg-5 p-sm-5" style={{ textAlign: "center", backgroundColor: "black", fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
                                    {
                                        NetSalaryObj.Value
                                    }
                                </div>
                                <div className="p-col-2 p-lg-2 p-sm-2" style={{ textAlign: "center", backgroundColor: "black", fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
                                    {
                                        NetSalaryObj.curr
                                    }
                                </div>
                            </div>
                        }
                    </Card>
                </div>

                <div className="p-col-12 p-lg-4 p-sm-12">
                    {

                        this.drawDetailsSection(SalaryRewardsObj, CreditAdjustObj, DeductionsObj, LoansObj, SavingFundDetailsObj, taxCreditAdjustObj) &&
                        <Card title="" showFooter={false} >
                            {/* تفاصيل المكافآت */}
                            {
                                // سطر تفاصيل المكافآت
                                SalaryRewardsObj && SalaryRewardsObj.Text.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                    <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                        {
                                            SalaryRewardsObj.Text
                                        }
                                    </div>
                                </div>
                            }
                            {
                                // أسطر تفاصيل المكافآت
                                SalaryRewardsObj && SalaryRewardsObj.SalaryRewardsList && SalaryRewardsObj.SalaryRewardsList.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                    {
                                        SalaryRewardsObj.SalaryRewardsList.map(item => (
                                            <>
                                                <div className="p-col-4 p-lg-3 p-sm-3" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Text}
                                                </div>
                                                <div className="p-col-2 p-lg-2 p-sm-2" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Value}
                                                </div>
                                                <div className="p-col-6 p-lg-7 p-sm-7" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Note}
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            }
                            {/* تفاصيل التسويات الدائنة */}
                            {
                                // سطر تفاصيل التسويات الدائنة
                                CreditAdjustObj && CreditAdjustObj.Text.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                    <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                        {
                                            CreditAdjustObj.Text
                                        }
                                    </div>
                                </div>
                            }
                            {
                                // أسطر تفاصيل التسويات الدائنة
                                CreditAdjustObj && CreditAdjustObj.CreditAdjustList && CreditAdjustObj.CreditAdjustList.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                    {
                                        CreditAdjustObj.CreditAdjustList.map(item => (
                                            <>
                                                <div className="p-col-4 p-lg-3 p-sm-3" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Text}
                                                </div>
                                                <div className="p-col-2 p-lg-2 p-sm-2" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Value}
                                                </div>
                                                <div className="p-col-6 p-lg-7 p-sm-7" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Note}
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            }
                            {/* تفاصيل التسويات الدائنة الضريبية*/}
                            {
                                // سطر تفاصيل التسويات الدائنة
                                taxCreditAdjustObj && taxCreditAdjustObj.Text.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                    <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                        {
                                            taxCreditAdjustObj.Text
                                        }
                                    </div>
                                </div>
                            }
                            {
                                // أسطر تفاصيل التسويات الدائنة الضريبية
                                taxCreditAdjustObj && taxCreditAdjustObj.CreditAdjustList && taxCreditAdjustObj.CreditAdjustList.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                    {
                                        taxCreditAdjustObj.CreditAdjustList.map(item => (
                                            <>
                                                <div className="p-col-4 p-lg-3 p-sm-3" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Text}
                                                </div>
                                                <div className="p-col-2 p-lg-2 p-sm-2" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Value}
                                                </div>
                                                <div className="p-col-6 p-lg-7 p-sm-7" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Note}
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            }
                            {/* تفاصيل السلف والاقتطاعات */}
                            {
                                // سطر تفاصيل السلف والاقتطاعات
                                DeductionsObj && DeductionsObj.Text.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                    <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                        {
                                            DeductionsObj.Text
                                        }
                                    </div>
                                </div>
                            }
                            {
                                // أسطر تفاصيل السلف والاقتطاعات
                                DeductionsObj && DeductionsObj.DeductionsList && DeductionsObj.DeductionsList.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                    {
                                        DeductionsObj.DeductionsList.map(item => (
                                            <>
                                                <div className="p-col-4 p-lg-3 p-sm-3" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Text}
                                                </div>
                                                <div className="p-col-2 p-lg-2 p-sm-2" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Value}
                                                </div>
                                                <div className="p-col-6 p-lg-7 p-sm-7" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Note}
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            }
                            {/* تفاصيل القروض */}
                            {
                                // سطر تفاصيل القروض
                                LoansObj && LoansObj.Text.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                    <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                        {
                                            LoansObj.Text
                                        }
                                    </div>
                                </div>
                            }
                            {
                                // أسطر تفاصيل القروض
                                LoansObj && LoansObj.LoansList && LoansObj.LoansList.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                    {
                                        LoansObj.LoansList.map(item => (
                                            <>
                                                <div className="p-col-4 p-lg-3 p-sm-3" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Text}
                                                </div>
                                                <div className="p-col-2 p-lg-2 p-sm-2" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Value}
                                                </div>
                                                <div className="p-col-6 p-lg-7 p-sm-7" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Note}
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            }
                            {/*مساهمة صندوق التوفير*/}
                            {
                                // سطر مساهمة صندوق التوفير
                                SavingFundDetailsObj && SavingFundDetailsObj.Text.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                    <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                        {
                                            SavingFundDetailsObj.Text
                                        }
                                    </div>
                                </div>
                            }
                            {
                                // مساهمة صندوق التوفير
                                SavingFundDetailsObj && SavingFundDetailsObj.SavingFundDetailsList && SavingFundDetailsObj.SavingFundDetailsList.length > 0 &&
                                <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                    {
                                        SavingFundDetailsObj.SavingFundDetailsList.map(item => (
                                            <>
                                                <div className="p-col-6 p-lg-6 p-sm-6" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Text}
                                                </div>
                                                <div className="p-col-6 p-lg-6 p-sm-6" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                    {item.Value}
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            }
                        </Card>
                    }
                </div>

                <div className="p-col-12 p-lg-4 p-sm-12">
                    <Card title="" showFooter={false} >
                        {/* تفاصيل الاجازات */}
                        {
                            // سطر تفاصيل الاجازات
                            SalaryDetObj && SalaryDetObj.Text.length > 0 &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                    {
                                        SalaryDetObj.Text
                                    }
                                </div>
                            </div>
                        }
                        {
                            // أسطر تفاصيل الاجازات
                            SalaryDetObj && SalaryDetObj.SalaryDetList && SalaryDetObj.SalaryDetList.length > 0 &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                {
                                    SalaryDetObj.SalaryDetList.map(item => (
                                        <>
                                            <div className="p-col-9 p-lg-9 p-sm-9" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                {item.Text}
                                            </div>
                                            <div className="p-col-3 p-lg-3 p-sm-3" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                <span className={item.Key ? styles.moreDetails : ''} onClick={e => item.Key ? this.moreDetails(e, item.Key) : null}>
                                                    {item.Value}
                                                </span>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        }
                        {/* قروض غير مسددة */}
                        {
                            // سطر قروض غير مسددة
                            UnpaidLoansObj && UnpaidLoansObj.UnpaidLoansList && UnpaidLoansObj.UnpaidLoansList.length > 0 && UnpaidLoansObj.UnpaidLoansList[0].Text !== "0" &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                    {
                                        UnpaidLoansObj.Text
                                    }
                                </div>
                            </div>
                        }
                        {
                            // أسطر قروض غير مسددة
                            UnpaidLoansObj && UnpaidLoansObj.UnpaidLoansList && UnpaidLoansObj.UnpaidLoansList.length > 0 && UnpaidLoansObj.UnpaidLoansList[0].Text !== "0" &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                {
                                    UnpaidLoansObj.UnpaidLoansList.map(item => (
                                        <>
                                            <div className="p-col-12 p-lg-12 p-sm-12" style={{ fontSize: '14px', textAlign: 'center', borderBottom: 'solid 1px gray' }}>
                                                {item.Text}
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        }
                        {/* تفاصيل البنك */}
                        {
                            // سطر تفاصيل البنك
                            BankDetailsObj && BankDetailsObj.Text.length > 0 &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }}>
                                <div className="p-col-12 p-lg-12 p-sm-12" style={{ border: 'solid 1px black', textAlign: "center", backgroundColor: "#c0c0c0", fontSize: '14px', fontWeight: 'bold' }}>
                                    {
                                        BankDetailsObj.Text
                                    }
                                </div>
                            </div>
                        }
                        {
                            // أسطر تفاصيل البنك
                            BankDetailsObj && BankDetailsObj.BankDetailsList && BankDetailsObj.BankDetailsList.length > 0 &&
                            <div className="p-grid" style={{ border: 'solid 1px black' }} >
                                {
                                    BankDetailsObj.BankDetailsList.map(item => (
                                        <>
                                            <div className="p-col-6 p-lg-6 p-sm-6" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                {item.Text}
                                            </div>
                                            <div className="p-col-6 p-lg-6 p-sm-6" style={{ fontSize: '14px', borderBottom: 'solid 1px gray' }}>
                                                {item.Value}
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        }
                    </Card>
                </div>
            </div >
        )

        this.setState({
            listOfControl: listOfControls, loading: false,
            dataObject: {
                ...this.state.dataObject, employeeCode: objEmployee ? objEmployee.Code : '',
                employeeName: objEmployee ? objEmployee.Name : '', appDate: objEmployee ? objEmployee.D_App : '', degree: objEmployee ? objEmployee.GradeName : '',
                idNo: objEmployee ? objEmployee.IDNO : '', address: objEmployee ? objEmployee.Address : '', job: objEmployee ? objEmployee.JobName : '',
                degreeStep: objEmployee ? objEmployee.gradeStep : '', section: objEmployee ? objEmployee.SectionName : '',
                salaryType: objEmployee ? objEmployee.salaryType : '', classification: objEmployee ? objEmployee.classification : '',
                gradeYearOfSeniority: objEmployee.gradeYearOfSeniority ? objEmployee.gradeYearOfSeniority : '',
                yearsOfSeniority: objEmployee.yearsOfSeniority ? objEmployee.yearsOfSeniority : '',
                companyName: objEmployee ? objEmployee.companyName : '', companyAddress: objEmployee ? objEmployee.companyAddress : ''
            }
        }, () => this.canPrintSalarySlip = true);
    }

    drawDetailsSection = (rewardsObj, creditObj, deductionsObj, loansObj, SavingFundObj, taxCreditAdjustObj) => {
        if ((rewardsObj && rewardsObj.SalaryRewardsList && rewardsObj.SalaryRewardsList.length > 0) || (creditObj && creditObj.CreditAdjustList && creditObj.CreditAdjustList.length > 0) ||
            (deductionsObj && deductionsObj.DeductionsList && deductionsObj.DeductionsList.length > 0) ||
            (loansObj && loansObj.LoansList && loansObj.LoansList.length > 0) || (SavingFundObj && SavingFundObj.SavingFundDetailsList && SavingFundObj.SavingFundDetailsList.length > 0) ||
            (taxCreditAdjustObj && taxCreditAdjustObj.CreditAdjustList && taxCreditAdjustObj.CreditAdjustList.length > 0)) {
            return true;
        }
        else {
            return false;
        }
    }
}