import React from "react";
import styles from "./Input.module.scss";
import { InputText as PrimeInput } from "primereact/inputtext";
import Label from "../../Components/Label";
import Button from "../../Components/Button";
import { FaSearch, FaSearchPlus, FaAutoprefixer, FaSitemap, FaTimesCircle, FaCheckDouble, FaPrint } from 'react-icons/fa'
export default class Input extends React.Component {

  render() {
    let isUpperCase = this.props.type && this.props.type.toLowerCase() === 'code' ? styles.upperCase : undefined
    return (
      <div>
        {this.props.caption && <Label htmlFor={this.props.id} isRequired={this.props.isRequired} autoComplete="off" style={{ width: '100%' }}>
          {this.props.caption}
        </Label>
        }
        {this.props.withgroup && (
          <div className="p-inputgroup">
            <PrimeInput
              ref={this.props.innerRef}
              {...this.props}
              autoComplete="off"
              onFocus={this.props.onFocus ? this.props.onFocus : this.onFocus}
              tooltipOptions={{ position: 'bottom' }}
            >
              {this.props.children}
            </PrimeInput>
            <span
              className="p-inputgroup-addon"
              style={{ verticalAlign: "bottom" }}
            >
              <Button onClick={this.props.btn1event} tooltip={this.props.btn1tooltip} >
                {
                  !this.props.btn1icon || this.props.btn1icon === 'search' ? <FaSearch /> : null
                }
                {
                  this.props.btn1icon === 'searchPlus' ? <FaSearchPlus /> : null
                }
                {
                  this.props.btn1icon === "codePrefix" ? <FaAutoprefixer /> : null
                }
                {
                  this.props.btn1icon === "accountTree" ? <FaSitemap /> : null
                }
                {
                  this.props.btn1icon === "clear" ? <FaTimesCircle /> : null
                }
                {
                  this.props.btn1icon === "check" ? <FaCheckDouble /> : null
                }
                {
                  this.props.btn1icon === "print" ? <FaPrint /> : null
                }
              </Button>
            </span>

          </div>
        )}
        {this.props.with2buttons && (
          <div className="p-inputgroup">
            <PrimeInput
              ref={this.props.innerRef}
              autocomplete="off"
              {...this.props}
              onFocus={this.props.onFocus ? this.props.onFocus : this.onFocus}
              tooltipOptions={{ position: 'bottom' }}
            >
              {this.props.children}
            </PrimeInput>
            <span
              className="p-inputgroup-addon"
              style={{ verticalAlign: "bottom" }}
            >
              <Button onClick={this.props.btn1event} tooltip={this.props.btn1tooltip} >
                {
                  this.props.btn1icon === 'search' ? <FaSearch /> : null
                }
                {
                  this.props.btn1icon === 'searchPlus' ? <FaSearchPlus /> : null
                }
                {
                  this.props.btn1icon === "codePrefix" ? <FaAutoprefixer /> : null
                }
                {
                  this.props.btn1icon === "accountTree" ? <FaSitemap /> : null
                }
                {
                  this.props.btn1icon === "clear" ? <FaTimesCircle /> : null
                }
                {
                  this.props.btn1icon === "check" ? <FaCheckDouble /> : null
                }
                {
                  this.props.btn1icon === "print" ? <FaPrint /> : null
                }
              </Button>
            </span>
            <span
              className="p-inputgroup-addon"
              style={{ verticalAlign: "bottom", marginInlineStart: '4px' }}
            >
              <Button onClick={this.props.btn2event} tooltip={this.props.btn2tooltip}>
                {
                  this.props.btn2icon === 'search' ? <FaSearch /> : null
                }
                {
                  this.props.btn2icon === 'searchPlus' ? <FaSearchPlus /> : null
                }
                {
                  this.props.btn2icon === "codePrefix" ? <FaAutoprefixer /> : null
                }
                {
                  this.props.btn2icon === "accountTree" ? <FaSitemap /> : null
                }
                {
                  this.props.btn2icon === "clear" ? <FaTimesCircle /> : null
                }
                {
                  this.props.btn2icon === "check" ? <FaCheckDouble /> : null
                }
                {
                  this.props.btn2icon === "print" ? <FaPrint /> : null
                }
              </Button>
            </span>

          </div>
        )}
        {!this.props.withgroup && !this.props.with2buttons && (
          <PrimeInput
            ref={this.props.innerRef}
            autocomplete="off"
            {...this.props}
            onFocus={this.props.onFocus ? this.props.onFocus : this.onFocus}
            tooltipOptions={{ position: 'bottom' }}
          >
            {this.props.children}
          </PrimeInput>
        )}
        {
          this.props.formErrors && this.props.formErrors[this.props.id] &&
          <div className={styles.errorField} >{this.props.formErrors[this.props.id]}</div>
        }
      </div>
    );
  }

  onFocus = event => {
    event.target.select();
  };

}
