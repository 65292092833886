import React from "react";
import styles from './WorkDetails.module.scss'
import Card from '../../Components/Card';
import SiteMap from '../../Components/SiteMap';
import DataTable from '../../Components/Datatable';
import Button from '../../Components/Button';
import Util from "../../Util";
import ScrollTop from '../ScrollButton';
import http from '../../Api/http';
import ProgressSpinner from '../../Components/ProgressSpinner';
import Calendar from '../../Components/Calendar';

const $ = window.$

export default class WorkDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            shiftDate: new Date(),
            isDesktop: window.innerWidth > 800
        };
        this.scheme = this.getScheme();
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        document.addEventListener("resize", this.updatePredicate);
        let dataPromise = this.getTodayShift()
        let temp = await dataPromise
        this.setState({ data: temp, loading: false })
    }

    componentWillUnmount() {
        document.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    render() {

        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.links.reports.workDetails }
        ];

        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" style={{ minHeight: '70vh' }}>
                {/* <ProgressSpinner loading={this.state.loading} /> */}
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>

                <Card>
                    <div className="p-grid" style={{ width: '100%', direction: $.strings.dir === 'rtl' ? 'rtl' : 'ltr' }}>
                        <div className="p-col-6 p-xl-4 p-lg-6 p-md-8 p-sm-8">
                            <Calendar
                                id="shiftDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.shiftDate}
                                caption={$.strings.workDetails.shiftDate}
                                onChange={this.handleUserInput}
                                tooltip={$.strings.workDetails.shiftDate}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-xl-4 p-lg-6 p-md-4 p-sm-4" style={{ alignSelf: 'flex-end' }}>
                            <Button label={$.strings.refresh} icon="" className="p-button-info" onClick={this.doRefresh}>
                                &nbsp;&nbsp;<i className="pi pi-refresh" />
                            </Button>
                        </div>
                    </div>
                </Card>

                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <DataTable
                                isReport={false}
                                ref={ref => (this.tblData = ref)}
                                scrollHeight="45vh"
                                scheme={this.scheme}
                                value={this.state.data}
                                exportFilename={$.strings.links.home}
                                printPreview={this.print}
                                loading={this.state.loading}
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rows={10}
                                responsiveLayout="stack" breakpoint="960px"
                                showContextMenu={false}
                                responsive={true}
                                globalFilter={this.state.globalFilter}
                                onRowDoubleClick={this.onRowDoubleClick}
                            />
                        </div>
                    </div>
                </Card>
                <ScrollTop />
            </div >
        );
    }

    doRefresh = async () => {
        this.setState({ loading: true })
        let dataPromise = this.getTodayShift();
        let temp = await dataPromise
        this.setState({ data: temp, loading: false })
    }

    getTodayShift = async () => {
        let fromDate = this.state.shiftDate
        let toDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        toDate.setMonth(toDate.getMonth() + 1);
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR"),
            fromDate: fromDate,
            toDate: toDate
        }
        let res = await http.getJsonAxios(http.actions.Shifts.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }

    serialTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData['serial']}</span>
        </>
    }

    shiftDateTemplate = (rowData, column) => {
        let date = rowData['shiftDate'];
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{date}</span>
        </>
    }

    mainTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData[column.field]}</span>
        </>
    }

    getScheme = () => {
        return {
            name: "myRequestsScheme_Table",
            filter: false,
            sigma: false,
            columns: [
                {
                    position: 0,
                    field: "serial",
                    header: "##",
                    filterable: false,
                    width: "150px",
                    sortable: false,
                    body: this.serialTemplate
                },
                // {
                //     position: 1,
                //     field: "empCode",
                //     header: $.strings.workDetails.empCode,
                //     width: '150px',
                //     body: this.mainTemplate
                // },
                // {
                //     position: 2,
                //     field: 'empName',
                //     header: $.strings.workDetails.empName,
                //     sortable: false,
                //     body: this.mainTemplate
                // },
                {
                    position: 3,
                    field: "shiftDate",
                    header: $.strings.workDetails.shiftDate,
                    body: this.shiftDateTemplate,
                    sortable: false,
                    width: '150px'
                },
                {
                    position: 4,
                    field: "dayName",
                    header: $.strings.workDetails.shiftDay,
                    body: this.mainTemplate,
                    sortable: false,
                    width: '150px'
                },
                {
                    position: 5,
                    field: "workFrom",
                    header: $.strings.workDetails.workFrom,
                    sortable: false,
                    body: this.mainTemplate,
                    width: '150px'
                },
                {
                    position: 6,
                    field: "workTo",
                    header: $.strings.workDetails.workTo,
                    sortable: false,
                    body: this.mainTemplate,
                    width: '150px'
                }
            ]
        };
    };
}
