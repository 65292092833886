import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import ScrollTop from '../ScrollButton';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import Checkbox from '../../Components/Checkbox';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Messages from "../../Components/Messages";
import Print from "../../Print";

const $ = window.$;

export default class DailyAttendanceRpt extends React.Component {
    constructor(props) {
        super(props);
        this.transTypesList = [
            { id: 1, name: $.strings.attendanceReport.allTrans },
            { id: 0, name: $.strings.attendanceReport.firstInLastOut }
        ]
        this.state = {
            isDesktop: window.innerWidth > 1250,
            transTypesList: this.transTypesList,
            fromCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            toCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            transType: this.transTypesList[0],
            fromDate: new Date(),
            toDate: new Date(),
            time: 32000,
            calcRestWorkAsOverTime: false,
            showVacationsAndHoildays: true,
            takeAllowancePeriod: true,
            attShortage: false,
            shift: undefined,
            section: undefined,
            branch: undefined,
            department: undefined,
            filterShown: true,
            data: []

        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(710)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(652)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();

        let shiftsPromise = this.getShifts();
        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();

        let shiftsList = await shiftsPromise;
        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        this.setState({
            shiftsList: shiftsList, branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            shift: shiftsList && shiftsList.length > 0 ? shiftsList[0] : undefined,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.dailyAttendanceRpt.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.dailyAttendanceRpt.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.dailyAttendanceRpt.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.dailyAttendanceRpt.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.dailyAttendanceRpt.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                            <Dropdown
                                id="transType"
                                value={this.state.transType}
                                options={this.state.transTypesList}
                                caption={$.strings.dailyAttendanceRpt.transType}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="calcRestWorkAsOverTime" caption={$.strings.dailyAttendanceRpt.calcRestWorkAsOverTime}
                                onChange={e => this.setState({ calcRestWorkAsOverTime: e.checked })} checked={this.state.calcRestWorkAsOverTime} />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showVacationsAndHoildays" caption={$.strings.dailyAttendanceRpt.showVacationsAndHoildays}
                                onChange={e => this.setState({ showVacationsAndHoildays: e.checked })} checked={this.state.showVacationsAndHoildays} />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="takeAllowancePeriod" caption={$.strings.dailyAttendanceRpt.takeAllowancePeriod}
                                onChange={e => this.setState({ takeAllowancePeriod: e.checked })} checked={this.state.takeAllowancePeriod} />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="attShortage" caption={$.strings.dailyAttendanceRpt.attShortage}
                                onChange={e => this.setState({ attShortage: e.checked })} checked={this.state.attShortage} />
                        </div>
                    </div>
                    {
                        Util.checkISHR() &&
                        <div className="p-grid">
                            <div className="p-col-6 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="shift"
                                    innerRef={e => this.drpShifts = e}
                                    value={this.state.shift}
                                    options={this.state.shiftsList}
                                    caption={$.strings.dailyAttendanceRpt.shift}
                                    onChange={this.handleUserInput}
                                    optionLabel="ShiftName"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="section"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.dailyAttendanceRpt.section}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="department"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.department}
                                    options={this.state.departmentsList}
                                    caption={$.strings.dailyAttendanceRpt.department}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="branch"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.branch}
                                    options={this.state.branchesList}
                                    caption={$.strings.dailyAttendanceRpt.branch}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                        </div>
                    }
                </AccordionTab>
            </Accordion>
            <Card>
                <DataGrid
                    ref={e => this.gridData = e}
                    isReport={true}
                    theme="default-light"
                    idProperty="serial"
                    style={gridStyle}
                    scheme={this.getScheme()}
                    exportFileName={$.strings.dailyAttendanceRpt.title}
                    dataSource={this.state.data}
                />
            </Card>
            <ScrollTop />
        </div>
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }

    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            paging: '0',
            dtpFromDate: this.state.fromDate,
            userId: localStorage.getItem("useridHR") + "",
            chkShortage: this.state.attShortage,
            chkTakeAllowancePeriod: this.state.takeAllowancePeriod,
            chkWorkInBreakAsOverTime: this.state.calcRestWorkAsOverTime,
            ShowVocations: this.state.showVacationsAndHoildays,
            timeArray1Session: '',
            DDL_clType: this.state.transType.id,
            dtpToDate: this.state.toDate,
            sectionVal: this.state.section ? this.state.section.Code : "-1",
            DDL_shift: this.state.shift && this.state.shift.ID >= 0 ? this.state.shift.ID : "-1",
            selectedDepartment: this.state.department && this.state.department.pos >= 0 ? this.state.department.pos : "-1",
            selectedBranch: this.state.branch && this.state.branch.Pos >= 0 ? this.state.branch.Pos : "-1",
            fromCode: this.state.fromCode && this.state.fromCode.length > 0 ? this.state.fromCode : '00000',
            toCode: this.state.toCode && this.state.toCode.length > 0 ? this.state.toCode : 'ZZZZZ',
            isHr: Util.checkISHR(),
            empCode: localStorage.getItem("useridHR") + "",
        }
        
        let res = await http.getJsonAxios(http.actions.DailyAttendanceRpt.urlGetDailyAttendanceRpt, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }

        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.dailyAttendanceRpt.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";

            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.dailyAttendanceRpt.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.dailyAttendanceRpt.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.dailyAttendanceRpt.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.dailyAttendanceRpt.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter);

            if (this.printFilters.transType) {
                let name = this.printFilters.transType.name
                listOfFilters.push($.strings.dailyAttendanceRpt.transType + ": " + name);
            }

            if (this.printFilters.calcRestWorkAsOverTime)
                listOfFilters.push($.strings.dailyAttendanceRpt.calcRestWorkAsOverTime + ": " + (this.printFilters.calcRestWorkAsOverTime ? $.strings.yes : $.strings.no))

            if (this.printFilters.showVacationsAndHoildays)
                listOfFilters.push($.strings.dailyAttendanceRpt.showVacationsAndHoildays + ": " + (this.printFilters.showVacationsAndHoildays ? $.strings.yes : $.strings.no))

            if (this.printFilters.takeAllowancePeriod)
                listOfFilters.push($.strings.dailyAttendanceRpt.takeAllowancePeriod + ": " + (this.printFilters.takeAllowancePeriod ? $.strings.yes : $.strings.no))

            if (this.printFilters.attShortage)
                listOfFilters.push($.strings.dailyAttendanceRpt.attShortage + ": " + (this.printFilters.attShortage ? $.strings.yes : $.strings.no))

            if (Util.checkISHR()) {
                if (this.printFilters.shift) {
                    listOfFilters.push($.strings.dailyAttendanceRpt.shift + ": " + this.printFilters.shift.ShiftName);
                }
                if (this.printFilters.section) {
                    listOfFilters.push($.strings.dailyAttendanceRpt.section + ": " + this.printFilters.section.Name);
                }
                if (this.printFilters.branch) {
                    listOfFilters.push($.strings.dailyAttendanceRpt.branch + ": " + this.printFilters.branch.Name);
                }
                if (this.printFilters.department) {
                    listOfFilters.push($.strings.dailyAttendanceRpt.department + ": " + this.printFilters.department.name);
                }

            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    getScheme = () => {
        return {
            name: "dailyAttendanceRptScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [
                { name: 'RowNumber', header: '#', width: 70, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                {
                    name: 'EmpCode', header: $.strings.dailyAttendanceRpt.empCode, width: 100, visible: false, visibleInColumnChooser: false, columnWidth: '2'
                },
                { name: 'EmpName', header: $.strings.dailyAttendanceRpt.empName, width: 300, columnWidth: '*' },
                { name: 'Section', header: $.strings.dailyAttendanceRpt.section, width: 80, columnWidth: '3' },
                {
                    name: 'Date', header: $.strings.dailyAttendanceRpt.date, width: 100, columnWidth: '2'
                },
                {
                    name: 'Day', header: $.strings.dailyAttendanceRpt.day, width: 70, columnWidth: '2'
                },
                {
                    name: 'In', header: $.strings.dailyAttendanceRpt.in, width: 70, columnWidth: '2'
                },
                {
                    name: 'Out', header: $.strings.dailyAttendanceRpt.out, width: 70, columnWidth: '2'
                },
                {
                    name: 'Period', header: $.strings.dailyAttendanceRpt.period, width: 70, columnWidth: '2'
                },
                {
                    name: 'In1', header: $.strings.dailyAttendanceRpt.in1, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In1'), columnWidth: '2'
                },
                {
                    name: 'Out1', header: $.strings.dailyAttendanceRpt.out1, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out1'), columnWidth: '2'
                },
                {
                    name: 'Period1', header: $.strings.dailyAttendanceRpt.period1, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period1'), columnWidth: '2'
                },
                {
                    name: 'In2', header: $.strings.dailyAttendanceRpt.in2, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In2'), columnWidth: '2'
                },
                {
                    name: 'Out2', header: $.strings.dailyAttendanceRpt.out2, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out2'), columnWidth: '2'
                },
                {
                    name: 'Period2', header: $.strings.dailyAttendanceRpt.period2, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period2'), columnWidth: '2'
                },
                {
                    name: 'In3', header: $.strings.dailyAttendanceRpt.in3, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In3'), columnWidth: '2'
                },
                {
                    name: 'Out3', header: $.strings.dailyAttendanceRpt.out3, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out3'), columnWidth: '2'
                },
                {
                    name: 'Period3', header: $.strings.dailyAttendanceRpt.period3, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period3'), columnWidth: '2'
                },
                {
                    name: 'In4', header: $.strings.dailyAttendanceRpt.in4, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In4'), columnWidth: '2'
                },
                {
                    name: 'Out4', header: $.strings.dailyAttendanceRpt.out4, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out4'), columnWidth: '2'
                },
                {
                    name: 'Period4', header: $.strings.dailyAttendanceRpt.period4, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period4'), columnWidth: '2'
                },
                {
                    name: 'In5', header: $.strings.dailyAttendanceRpt.in5, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In5'), columnWidth: '2'
                },
                {
                    name: 'Out5', header: $.strings.dailyAttendanceRpt.out5, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out5'), columnWidth: '2'
                },
                {
                    name: 'Period5', header: $.strings.dailyAttendanceRpt.period5, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period5'), columnWidth: '2'
                },
                {
                    name: 'In6', header: $.strings.dailyAttendanceRpt.in6, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In6'), columnWidth: '2'
                },
                {
                    name: 'Out6', header: $.strings.dailyAttendanceRpt.out6, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out6'), columnWidth: '2'
                },
                {
                    name: 'Period6', header: $.strings.dailyAttendanceRpt.period6, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period6')//this.state.data.filter(e => e.Period6 && e.Period6.length > 0).length > 0
                },
                {
                    name: 'In7', header: $.strings.dailyAttendanceRpt.in7, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In7')//this.state.data.filter(e => e.In7 && e.In7.length > 0).length > 0
                },
                {
                    name: 'Out7', header: $.strings.dailyAttendanceRpt.out7, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out7')//this.state.data.filter(e => e.Out7 && e.Out7.length > 0).length > 0
                },
                {
                    name: 'Period7', header: $.strings.dailyAttendanceRpt.period7, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period7')//visible: this.state.data.filter(e => e.Period7 && e.Period7.length > 0).length > 0
                },
                {
                    name: 'In8', header: $.strings.dailyAttendanceRpt.in8, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In8')//this.state.data.filter(e => e.In8 && e.In8.length > 0).length > 0
                },
                {
                    name: 'Out8', header: $.strings.dailyAttendanceRpt.out8, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out8')//this.state.data.filter(e => e.Out8 && e.Out8.length > 0).length > 0
                },
                {
                    name: 'Period8', header: $.strings.dailyAttendanceRpt.period8, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period8')//this.state.data.filter(e => e.Period8 && e.Period8.length > 0).length > 0
                },
                {
                    name: 'In9', header: $.strings.dailyAttendanceRpt.in9, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In9')//this.state.data.filter(e => e.In9 && e.In9.length > 0).length > 0
                },
                {
                    name: 'Out9', header: $.strings.dailyAttendanceRpt.out9, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out9')//this.state.data.filter(e => e.Out9 && e.Out9.length > 0).length > 0
                },
                {
                    name: 'Period9', header: $.strings.dailyAttendanceRpt.period9, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period9')//this.state.data.filter(e => e.Period9 && e.Period9.length > 0).length > 0
                },
                {
                    name: 'In10', header: $.strings.dailyAttendanceRpt.in10, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'In10')//this.state.data.filter(e => e.In10 && e.In10.length > 0).length > 0
                },
                {
                    name: 'Out10', header: $.strings.dailyAttendanceRpt.out10, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Out10')//this.state.data.filter(e => e.Out10 && e.Out10.length > 0).length > 0
                },
                {
                    name: 'Period10', header: $.strings.dailyAttendanceRpt.period10, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Period10')//this.state.data.filter(e => e.Period10 && e.Period10.length > 0).length > 0
                },
                {
                    name: 'Total', header: $.strings.dailyAttendanceRpt.total, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'Total'), dataType: "Object", customAggregate: true
                },
                {
                    name: 'From', header: $.strings.dailyAttendanceRpt.from, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'From'), columnWidth: '2'
                },
                {
                    name: 'To', header: $.strings.dailyAttendanceRpt.to, width: 70,
                    visible: Util.hideBlankColumn(this.state.data, 'To'), columnWidth: '2'
                },
                {
                    name: 'RequierdHours', header: $.strings.dailyAttendanceRpt.requiredHours, width: 120,
                    visible: Util.hideBlankColumn(this.state.data, 'RequierdHours'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'TotalInWork', header: $.strings.dailyAttendanceRpt.suminAtt, width: 120,
                    visible: Util.hideBlankColumn(this.state.data, 'TotalInWork'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'WorkInRest', header: $.strings.dailyAttendanceRpt.workInRest, width: 120,
                    visible: Util.hideBlankColumn(this.state.data, 'WorkInRest'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'Absence', header: $.strings.dailyAttendanceRpt.absence, width: 80,
                    visible: Util.hideBlankColumn(this.state.data, 'Absence'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'Lates', header: $.strings.dailyAttendanceRpt.late, width: 80,
                    visible: Util.hideBlankColumn(this.state.data, 'Lates'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'Leaves', header: $.strings.dailyAttendanceRpt.leaves, width: 80,
                    visible: Util.hideBlankColumn(this.state.data, 'Leaves'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'EarlyOut', header: $.strings.dailyAttendanceRpt.earlyOut, width: 80,
                    visible: Util.hideBlankColumn(this.state.data, 'EarlyOut'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'Shortage', header: $.strings.dailyAttendanceRpt.shortage, width: 80,
                    visible: Util.hideBlankColumn(this.state.data, 'Shortage'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'EarlyAttendance', header: $.strings.dailyAttendanceRpt.earlyAttend, width: 120,
                    visible: Util.hideBlankColumn(this.state.data, 'EarlyAttendance'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'ExtraAfterWork', header: $.strings.dailyAttendanceRpt.overTimeAfterWork, width: 80,
                    visible: Util.hideBlankColumn(this.state.data, 'ExtraAfterWork'), dataType: "Object", customAggregate: true, columnWidth: '2'
                },
                {
                    name: 'Note', header: $.strings.dailyAttendanceRpt.notes, width: 100,
                    visible: Util.hideBlankColumn(this.state.data, 'Note'), columnWidth: '2'
                },
                {
                    name: 'TransNote', header: $.strings.dailyAttendanceRpt.transNotes, width: 100,
                    visible: Util.hideBlankColumn(this.state.data, 'TransNote'), columnWidth: '2'
                }
            ]
        }
    }
}