import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import ScrollTop from '../ScrollButton';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import Checkbox from '../../Components/Checkbox';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Messages from "../../Components/Messages";
import Print from "../../Print";

const $ = window.$;

export default class TotalInOutReport extends React.Component {
    constructor(props) {
        super(props);
        this.transTypeList = [
            { id: 0, name: $.strings.totalInOutTransReport.allTrans },
            { id: 1, name: $.strings.totalInOutTransReport.lateAfterWorkStart },
            { id: 2, name: $.strings.totalInOutTransReport.leaveBeforeWorkEnd },
            { id: 3, name: $.strings.totalInOutTransReport.specialLeave },
            { id: 4, name: $.strings.totalInOutTransReport.delayDuringWork },
            { id: 5, name: $.strings.totalInOutTransReport.specialLeaveBeforeWorkEnd },
            { id: 6, name: $.strings.totalInOutTransReport.attendBeforeWorkStart },
            { id: 7, name: $.strings.totalInOutTransReport.leaveAfterWorkEnd },
            { id: 8, name: $.strings.totalInOutTransReport.workOutsideOfWork },
            { id: 9, name: $.strings.totalInOutTransReport.officialLeave },
            { id: 10, name: $.strings.totalInOutTransReport.totalHoursDuringWork },
            { id: 11, name: $.strings.totalInOutTransReport.totalWorkHours },
            { id: 12, name: $.strings.totalInOutTransReport.totalRequiredWorkHours }
        ]
        this.state = {
            isDesktop: window.innerWidth > 1250,
            transTypeList: this.transTypeList,
            fromCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            toCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            transType: this.transTypeList[0],
            fromDate: new Date(),
            toDate: new Date(),
            fromMinute: 0,
            toMinute: 32000,
            observeRestPeriod: false,
            showTotalInHours: true,
            shift: undefined,
            section: undefined,
            branch: undefined,
            department: undefined,
            filterShown: true,
            data: []

        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(708)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(774)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();

        let shiftsPromise = this.getShifts();
        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();

        let shiftsList = await shiftsPromise;
        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        this.setState({
            shiftsList: shiftsList, branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            shift: shiftsList && shiftsList.length > 0 ? shiftsList[0] : undefined,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.totalInOutTransReport.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.inOutTotalReport.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.inOutTotalReport.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Dropdown
                                id="transType"
                                innerRef={e => this.drpTransType = e}
                                value={this.state.transType}
                                options={this.state.transTypeList}
                                caption={$.strings.inOutTotalReport.transType}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Input
                                type="text"
                                id="fromMinute"
                                value={this.state.fromMinute}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.totalInOutTransReport.fromMinute}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Input
                                type="text"
                                id="toMinute"
                                value={this.state.toMinute}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.totalInOutTransReport.toMinute}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.inOutTotalReport.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.inOutTotalReport.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        {
                            Util.checkISHR() && <>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Dropdown
                                        id="shift"
                                        innerRef={e => this.drpShifts = e}
                                        value={this.state.shift}
                                        options={this.state.shiftsList}
                                        caption={$.strings.totalInOutTransReport.shift}
                                        onChange={this.handleUserInput}
                                        optionLabel="ShiftName"
                                    />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Dropdown
                                        id="section"
                                        innerRef={e => this.drpSections = e}
                                        value={this.state.section}
                                        options={this.state.sectionsList}
                                        caption={$.strings.inOutTotalReport.section}
                                        onChange={this.handleUserInput}
                                        optionLabel="Name"
                                    />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Dropdown
                                        id="department"
                                        innerRef={e => this.drpSections = e}
                                        value={this.state.department}
                                        options={this.state.departmentsList}
                                        caption={$.strings.totalInOutTransReport.department}
                                        onChange={this.handleUserInput}
                                        optionLabel="name"
                                    />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Dropdown
                                        id="branch"
                                        innerRef={e => this.drpSections = e}
                                        value={this.state.branch}
                                        options={this.state.branchesList}
                                        caption={$.strings.totalInOutTransReport.branch}
                                        onChange={this.handleUserInput}
                                        optionLabel="Name"
                                    />
                                </div>
                            </>
                        }
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="observeRestPeriod" caption={$.strings.totalInOutTransReport.observeRestPeriod}
                                onChange={e => this.setState({ observeRestPeriod: e.checked })} checked={this.state.observeRestPeriod} />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showTotalInHours" caption={$.strings.totalInOutTransReport.showTotalInHours}
                                onChange={e => this.setState({ showTotalInHours: e.checked })} checked={this.state.showTotalInHours} />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                        <DataGrid
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            style={gridStyle}
                            scheme={this.getScheme()}
                            exportFileName={$.strings.totalInOutTransReport.title}
                            dataSource={this.state.data}
                        />
                    </div>
                </div>
            </Card>

            <ScrollTop />
        </div>
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }

    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            sort: '0',
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: localStorage.getItem("useridHR") + "",
            empCodeFrom: this.state.fromCode && this.state.fromCode.length > 0 ? this.state.fromCode : '00000',
            empCodeTo: this.state.toCode && this.state.toCode.length > 0 ? this.state.toCode : 'ZZZZZ',
            isHr: Util.checkISHR(),
            empCode: localStorage.getItem("useridHR") + "",
            selectedBranchValue: this.state.branch && this.state.branch.Pos >= 0 ? this.state.branch.Pos : "-1",
            selectedDepartment: this.state.department && this.state.department.pos >= 0 ? this.state.department.pos : "-1",
            selectedSection: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            chkTakeAllowancePeriod: this.state.observeRestPeriod,
            ShowVocations: this.state.showTotalInHours,
            MinuteF: this.state.fromMinute,
            MinuteT: this.state.toMinute,
            type: this.state.transType.id
        }
        let res = await http.getJsonAxios(http.actions.TotalEntryAndExitReport.urlGetTotalEntryAndExit, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }

        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.totalInOutTransReport.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";

            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.inOutTotalReport.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.inOutTotalReport.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            if (this.printFilters.transType) {
                let name = this.printFilters.transType.name
                listOfFilters.push($.strings.inOutTotalReport.transType + ": " + name);
            }

            if (this.printFilters.fromMinute) {
                listOfFilters.push($.strings.totalInOutTransReport.fromMinute + ": " + this.printFilters.fromMinute);
            }
            if (this.printFilters.toMinute) {
                listOfFilters.push($.strings.totalInOutTransReport.toMinute + ": " + this.printFilters.toMinute);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.inOutTotalReport.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                dateFilter = $.strings.inOutTotalReport.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter);


            if (Util.checkISHR()) {
                if (this.printFilters.shift) {
                    listOfFilters.push($.strings.totalInOutTransReport.shift + ": " + this.printFilters.shift.ShiftName);
                }
                if (this.printFilters.section) {
                    listOfFilters.push($.strings.inOutTotalReport.section + ": " + this.printFilters.section.Name);
                }
                if (this.printFilters.branch) {
                    listOfFilters.push($.strings.totalInOutTransReport.branch + ": " + this.printFilters.branch.Name);
                }
                if (this.printFilters.department) {
                    listOfFilters.push($.strings.totalInOutTransReport.department + ": " + this.printFilters.department.name);
                }

            }

            if (this.printFilters.observeRestPeriod)
                listOfFilters.push($.strings.totalInOutTransReport.observeRestPeriod + ": " + (this.printFilters.observeRestPeriod ? $.strings.yes : $.strings.no))
            if (this.printFilters.noInOnly)
                listOfFilters.push($.strings.totalInOutTransReport.showTotalInHours + ": " + (this.printFilters.showTotalInHours ? $.strings.yes : $.strings.no))

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    getScheme = () => {
        return {
            name: "totalInOutReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [
                {
                    name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1'
                },
                {
                    name: 'No', header: $.strings.totalInOutTransReport.empCode, width: 100, columnWidth: '2'

                },
                {
                    name: 'Name', header: $.strings.totalInOutTransReport.empName, width: this.state.isDesktop ? 200 : "*", columnWidth: '*'

                },
                {
                    name: 'Section', header: $.strings.totalInOutTransReport.section, width: 150, columnWidth: '*'

                },
                {
                    name: 'PeriodOfDelay', header: $.strings.totalInOutTransReport.latePeriod, width: "*",
                    visible: this.state.data.filter(e => e.PeriodOfDelay && e.PeriodOfDelay.length).length > 0, columnWidth: '2'

                },
                {
                    name: 'AbsentDaysEarlyExit', header: $.strings.totalInOutTransReport.lateCount, width: "*",
                    visible: this.state.data.filter(e => e.AbsentDaysEarlyExit && e.AbsentDaysEarlyExit.length).length > 0, columnWidth: '2'

                },
                {
                    name: 'EarlyExit', header: $.strings.totalInOutTransReport.earlyOut, width: "*",
                    visible: this.state.data.filter(e => e.EarlyExit && e.EarlyExit.length).length > 0, columnWidth: '2'

                },
                {
                    name: 'PrivateExit', header: $.strings.totalInOutTransReport.specialOut, width: "*",
                    visible: this.state.data.filter(e => e.PrivateExit && e.PrivateExit.length).length > 0, columnWidth: '2'

                },
                {
                    name: 'NumberOfTimesPrivateExit', header: $.strings.totalInOutTransReport.lateCount, width: "*",
                    visible: this.state.data.filter(e => e.NumberOfTimesPrivateExit && e.NumberOfTimesPrivateExit.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'M_Delay', header: $.strings.totalInOutTransReport.late, width: "*",
                    visible: this.state.data.filter(e => e.M_Delay && e.M_Delay.length).length > 0, columnWidth: '2'

                },
                {
                    name: 'Private_BeforeGoingOut', header: $.strings.totalInOutTransReport.speicalBeforeOut, width: "*",
                    visible: this.state.data.filter(e => e.Private_BeforeGoingOut && e.Private_BeforeGoingOut.length).length > 0, columnWidth: '2'

                },
                {
                    name: 'BeforeEntering', header: $.strings.totalInOutTransReport.beforeIn, width: "*",
                    visible: this.state.data.filter(e => e.BeforeEntering && e.BeforeEntering.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'AfterExit', header: $.strings.totalInOutTransReport.afterOut, width: "*",
                    visible: this.state.data.filter(e => e.AfterExit && e.AfterExit.length).length > 0, columnWidth: '2'

                },
                {
                    name: 'ExtraDaysOff', header: $.strings.totalInOutTransReport.overTimeInHoliday, width: "*",
                    visible: this.state.data.filter(e => e.ExtraDaysOff && e.ExtraDaysOff.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'OutOfWorkingHours', header: $.strings.totalInOutTransReport.outWorkPeriod, width: "*",
                    visible: this.state.data.filter(e => e.OutOfWorkingHours && e.OutOfWorkingHours.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'WorkExit', header: $.strings.totalInOutTransReport.outJob, width: "*",
                    visible: this.state.data.filter(e => e.WorkExit && e.WorkExit.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'M_WorkDuringWork', header: $.strings.totalInOutTransReport.totalDuringWork, width: "*",
                    visible: this.state.data.filter(e => e.M_WorkDuringWork && e.M_WorkDuringWork.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'M_KidneyWork', header: $.strings.totalInOutTransReport.totalHours, width: "*",
                    visible: this.state.data.filter(e => e.M_KidneyWork && e.M_KidneyWork.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'RequiredHours', header: $.strings.totalInOutTransReport.requiredHours, width: "*", columnWidth: '2'
                },
                {
                    name: 'AbsentDays', header: $.strings.totalInOutTransReport.absenceDays, width: "*",
                    visible: this.state.data.filter(e => e.M_WorkDuringWork && e.M_WorkDuringWork.length).length > 0, columnWidth: '2'
                }
            ]
        }
    }
}