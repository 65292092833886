import React from 'react';
import Dialog from '../../../Components/Dialog';
import Input from '../../../Components/Password';
import http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import Util from '../../../Util';
import Button from '../../../Components/Button';
import AlertBox from '../../../Components/AlertBox/AlertBox';

const $ = window.$;

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.hash = null;
    this.state = {
      loading: true,
      pageInfo: this.setPageInfo(),
      formErrors: { newPwd: '', confirmPwd: '' },
      isDesktop: false,
      visibleDialog: false,
      // complexPassword: false,
      // minPasswordLength: 8,
      userId: '',
      newPwd: '',
      confirmPwd: '',
    };
  }

  componentDidMount = async () => {
    this.show(this.props.selectedUserId);
  };

  handleUserInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    this.setState({ [name]: value });
  };

  validateField() {
    let fieldValidationErrors = this.state.formErrors;

    let _newPwd = this.state.newPwd;
    fieldValidationErrors.newPwd = _newPwd ? '' : $.strings.requiredFiled;

    let _confirmPwd = this.state.confirmPwd;
    fieldValidationErrors.confirmPwd = _confirmPwd ? '' : $.strings.requiredFiled;

    if (_confirmPwd + '' !== _newPwd + '') {
      _confirmPwd = undefined;
      fieldValidationErrors.confirmPwd = $.strings.users.confirmPwdError;
    }

    // if (_newPwd && _newPwd.length < this.state.minPasswordLength) {
    //     fieldValidationErrors.newPwd = $.strings.users.passwordLengthError + ' ' + this.state.minPasswordLength
    //     return false;
    // }

    // if (this.state.complexPassword && !Util.checkComplexPassword(this.state.password)) {
    //     fieldValidationErrors.newPwd = $.strings.users.complexPasswordError;
    //     return false;
    // }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        newPwdValid: _newPwd,
        confirmPwdValid: _confirmPwd,
      },
      this.validateForm
    );

    return _newPwd && _confirmPwd;
  }
  validateForm() {
    let isFormValid = this.state.newPwdValid && this.state.confirmPwdValid;
    this.setState({ formValid: isFormValid });
    return isFormValid;
  }

  render() {
    const footer = (
      <div style={{ textAlign: 'center' }}>
        <Button tooltip={$.strings.save} icon="" className="p-button-success" onClick={this.onSave}>
          <i className="pi pi-check" />
        </Button>
        <Button tooltip={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
          <i className="pi pi-times" />
        </Button>
      </div>
    );

    const myIcon = <button className="p-dialog-titlebar-icon p-link"></button>;
    return (
      <div>
        <ProgressSpinner loading={this.state.loading} />
        {this.state.alertBoxVisible && <AlertBox ref={(e) => (this.alertBox = e)} header={$.strings.appName} yesCaption={$.strings.ok} />}
        <Dialog
          header={$.strings.changePassword}
          footer={footer}
          icons={myIcon}
          visible={true}
          style={{ width: '20vw', direction: $.strings.dir }}
          modal
          onHide={this.onHide}
        >
          <div ref="divMain">
            <div className="p-grid">
              <div className="p-col-12 p-lg-12 p-sm-12">
                <Input
                  innerRef={(el) => (this.txtNewPwd = el)}
                  type="text"
                  id="newPwd"
                  autoFocus={true}
                  inputId="newPwd"
                  value={this.state.newPwd || ''}
                  autocomplete="new-password"
                  onChange={this.handleUserInput}
                  caption={$.strings.newPwd}
                  isRequired={true}
                  formErrors={this.state.formErrors}
                  feedback={false}
                />
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-lg-12 p-sm-12">
                <Input
                  innerRef={(el) => (this.txtConfirmPwd = el)}
                  type="text"
                  id="confirmPwd"
                  inputId="confirmPwd"
                  value={this.state.confirmPwd || ''}
                  autocomplete="new-password"
                  onChange={this.handleUserInput}
                  caption={$.strings.confirmNewPwd}
                  isRequired={true}
                  formErrors={this.state.formErrors}
                  feedback={false}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
  show = async (userId) => {
    if (
      localStorage.getItem('userid') === undefined ||
      localStorage.getItem('userid') === null ||
      localStorage.getItem('userid').trim() === 'undefined' ||
      localStorage.getItem('userid').trim() === ''
    ) {
      Util.goTo('/login', '');
      return <div></div>;
    }
    this.setState({
      userId: userId,
      visibleDialog: true,
      newPwd: '',
      confirmPwd: '',
      loading: false,
      formErrors: { newPwd: '', confirmPwd: '' },
    });
  };

  onHide = () => {
    this.setState({
      visibleDialog: false,
      newPwd: '',
      confirmPwd: '',
      formErrors: { newPwd: '', confirmPwd: '' },
    });
    if (this.props.onClose) this.props.onClose();
  };

  getPermissionsSettings = async () => {
    let dataTemp = [];
    const params = {
      status: Util.getStatusId(this.state.StatusFilter),
      id: 0,
    };

    let res = await http.getJsonAxios(http.actions.PermissionsSettings.urlGetPermissionsSettings, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data._PermissionsSettings;
    }
    return dataTemp;
  };

  popupHasCalled = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
  };
  popupHasClosed = () => {
    document.addEventListener('keydown', this.onPageKeyDown);
  };

  onSave = async () => {
    if (!this.validateField()) {
      this.setState({ loading: false });
      return false;
    }
    this.setState({ loading: true });
    let obj = {
      userId: parseInt(this.props.selectedUserId),
      newPassword: Util.encryptStringToHexa(this.state.newPwd),
    };

    let res = await http.postMultipartAxios(http.actions.Users.urlGetResetUserPassword, obj, 0);
    if (res.status === 200) {
      if (res.data && res.data.success) {
        this.setState({ loading: false, visibleDialog: false }, () => {
          if (this.props.onSuccess) this.props.onSuccess();
        });
        return true;
      } else {
        if (res.data && res.data.errorText !== '') {
          this.popupHasCalled();
          this.setState({ loading: false, alertBoxVisible: true }, () => {
            this.alertBox.show(
              (e) => {
                this.popupHasClosed();
                this.setState({ alertBoxVisible: false });
              },
              undefined,
              undefined,
              res.data.errorText
            );
          });
          document.getElementById('newPwd').focus();
          return false;
        } else {
          this.popupHasCalled();
          this.setState({ loading: false, alertBoxVisible: true }, () => {
            this.alertBox.show(
              (e) => {
                this.popupHasClosed();
                this.setState({ alertBoxVisible: false });
              },
              undefined,
              undefined,
              $.strings.operationFailed
            );
          });
          document.getElementById('newPwd').focus();
          return false;
        }
      }
    } else {
      if (res.data && res.data.message !== '') {
        this.popupHasCalled();
        this.setState({ loading: false, alertBoxVisible: true }, () => {
          this.alertBox.show(
            (e) => {
              this.popupHasClosed();
              this.setState({ alertBoxVisible: false });
            },
            undefined,
            undefined,
            res.data.message
          );
        });
        return false;
      } else {
        this.popupHasCalled();
        this.setState({ loading: false, alertBoxVisible: true }, () => {
          this.alertBox.show(
            (e) => {
              this.popupHasClosed();
              this.setState({ alertBoxVisible: false });
            },
            undefined,
            undefined,
            $.strings.operationFailed
          );
        });
        return false;
      }
    }
  };

  setPageInfo = () => {
    let pageId = 0;
    let title = $.strings.changePassword;

    return { pageId: pageId, title: title };
  };
}
