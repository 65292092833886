import React from "react";
import styles from "./AlertBox.module.scss";
import Dialog from "../Dialog";
import Button from "../Button";
import RadioButton from "../RadioButton";
import Util from '../../Util';

const $ = window.$;

export default class AlertBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radioOption: this.props.parentOnly || "",
      showMsg: false,
      onYes: null,
      toDo: null,
      onCancel: null,
    };
    this.timeoutCounter = 0
    this.oldMessageText = ''
  }
  
  render() {
    let footer = (
      <div style={{ textAlign: "center", direction: $.strings.dir }}>
        {(this.props.onYes || this.state.onYes) && (
          <Button
            ref={(r) => (this.btnYes = r)}
            icon=""
            onClick={this.onYesClick}
            autoFocus={this.props.defaultButton === Util.defaultButton.Yes}
          >
            <i className="pi pi-check-circle" />
            &nbsp;&nbsp;
            <span>{this.props.yesCaption ? this.props.yesCaption : $.strings.yes}</span>
          </Button>
        )}
        &nbsp;&nbsp;
        {(this.props.toDo || this.state.toDo) && (
          <Button
            ref={(r) => (this.btnNo = r)}
            className="p-button-secondary"
            onClick={this.onNoClick}
            autoFocus={this.props.defaultButton === Util.defaultButton.No}
          >
            <i className="pi pi-times-circle" />
            &nbsp;&nbsp;
            <span>{this.props.noCaption ? this.props.noCaption : $.strings.no}</span>
          </Button>
        )}
        &nbsp;&nbsp;
        {(this.props.onCancel || this.state.onCancel) && (
          <Button
            ref={(r) => (this.btnCancel = r)}
            className="p-button-danger"
            onClick={this.onCancel}
            autoFocus={this.props.defaultButton === Util.defaultButton.Cancel}
          >
            <i className="pi pi-times" />
            &nbsp;&nbsp;
            <span>{$.strings.cancel}</span>
          </Button>
        )}
      </div>
    );
    let headerAlign = $.strings.dir === "rtl" ? "right" : "left";
    let header = (
      <div style={{ textAlign: headerAlign }}>{$.strings.appName}</div>
    );

    return (
      <Dialog
        visible={true}//this.props.showMsg || this.state.showMsg}
        ref={this.props.innerRef}
        className={styles.dialogMessageBox}
        showHeader={true}
        header={header}
        footer={footer}
        modal={true}
        draggable={true}
        closeOnEscape={false}
        style={{ width: "400px" }}
        contentStyle={{ minHeight: "50px", direction: $.strings.dir }}
        blockScroll={true}
        autoFocus={true}
        closable={false}
        onHide={this.onHide}
        rtl={$.strings.dir === "rtl" ? true : false}
        {...this.props}
      >
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {this.props.messageText
            ? this.props.messageText
            : this.state.messageText}
        </div>
        {this.props.showOptions && (
          <RadioButton
            inputId="rbParent"
            value={this.props.parentOnly}
            name="parentOnly"
            onChange={(e) =>
              this.setState({ radioOption: e.value }, this.doAction(e))
            }
            checked={this.state.radioOption === this.props.parentOnly}
          />
        )}
        {this.props.showOptions && (
          <RadioButton
            inputId="rbParentAndChilds"
            value={this.props.parentAndChilds}
            name="parentAndChilds"
            onChange={(e) =>
              this.setState({ radioOption: e.value }, this.doAction(e))
            }
            checked={this.state.radioOption === this.props.parentAndChilds}
          />
        )}
      </Dialog>
    );
  }

  doAction = (e) => {
    this.props.showOptions && this.props.setOptionsValue(e, e.value);
  };

  onNoClick = (e) => {
    if (this.props.toDo) this.props.toDo();
    else {
      if (this.state.toDo) this.state.toDo();
    }
    this.setState({ showMsg: false })
  };

  onYesClick = async (e) => {
    this.setState({ showMsg: false })
    if (this.props.onYes) {
      let res = await this.props.onYes(e);
      if (res) {
        if (this.props.toDo) {
          this.props.toDo();
        }
      }
    } else {
      if (this.state.onYes) {
        let res = await this.state.onYes(e);
        if (res) {
          if (this.state.toDo) {
            this.state.toDo();
          }
        }
      }
    }

  };

  onHide = () => {
    if (this.props.onCancel) this.props.onCancel();
    else {
      if (this.props.onCancel) this.state.onCancel();
    }
    this.setState({ showMsg: false })
  }

  onCancel = () => {
    if (this.props.onCancel) this.props.onCancel();
    else {
      if (this.state.onCancel) {
        this.setState({ showMsg: false });
        this.state.onCancel();
      }
    }
    this.setState({ showMsg: false })
  };

  setTimeoutt = () => {
    setTimeout(() => {
      try {
        this.timeoutCounter++;
        if (this.timeoutCounter < 100) {
          this.setTimeoutt()
        }
        else {
          this.oldMessageText = ''
          this.timeoutCounter = 0;
        }
      } catch (e) { }

    }, 10);
  }


  show = (_onYes, _toDo, _onCancel, messageText) => {
    // window.addEventListener("keydown", this.onPageKeyDown);
    // if (this.state.showMsg) {
    //   return;
    // }
    if (this.timeoutCounter < 100) {
      if (!this.oldMessageText.includes(messageText)) {
        messageText += '\n\n';
        messageText += this.oldMessageText;
      }
    }
    this.oldMessageText = messageText;

    this.setTimeoutt()
    this.setState({
      showMsg: true,
      onYes: _onYes,
      toDo: _toDo,
      onCancel: _onCancel,
      messageText
    });

  };
}
