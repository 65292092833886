import React from "react";
import styles from './SelectButton.module.scss';
import { SelectButton as PrimeSelectButton} from "primereact/selectbutton";

export default class SelectButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <PrimeSelectButton ref={this.props.innerRef} {...this.props} />            
        );
    }
}
