import React from "react";
import styles from "./Card.module.scss";
import { Card as PrimeCard } from 'primereact/card';

export default class Card extends React.Component {
    render() {
        return (
            < PrimeCard ref={this.props.innerRef} {...this.props} className="card">
                {this.props.children}
            </PrimeCard >
        );
    }
}
// style={{ paddingTop: '16px', paddingRight: '16px', paddingLeft: '16px', paddingBottom: '16px', margin: '16px' }}