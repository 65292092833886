import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import Dropdown from "../../Components/Dropdown";
import SearchEmployees from "../Employees/SearchEmployees";
import MultiSelect from "../../Components/MultiSelect";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class AdditionalFilters extends React.Component {

    constructor(props) {
        super(props);
        this.gendersList = [
            { id: -1, name: $.strings.all },
            { id: 0, name: $.strings.male },
            { id: 1, name: $.strings.female }
        ]
        this.emptyObj = {
            branch: undefined,
            department: undefined,
            classification: undefined,
            classification2: undefined,
            job: undefined,
            gender: this.gendersList[0],
            qualification: undefined,
            directManager: undefined,
            shift: undefined,
            periodType: undefined,
        }

        this.state = {
            loading: false,
            visibleDialog: false,
            empCode: localStorage.getItem("useridHR"),
            branchesList: [],
            departmentList: [],
            classificationsList: [],
            classifications2List: [],
            jobsList: [],
            qualificationsList: [],
            shiftsList: [],
            dataObject: {
                branch: undefined,
                department: undefined,
                classification: undefined,
                classification2: undefined,
                job: undefined,
                gender: this.gendersList[0],
                qualification: undefined,
                directManager: undefined,
                shift: undefined,
                periodType: undefined,
            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.ok} icon="" className="p-button-success" onClick={this.onSave}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <SearchEmployees ref={e => this.dlgSearchEmployee = e} onSuccess={this.searchEmployeeSuccess} />
                <Dialog header={$.strings.additionalFilters.title} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir }}
                    modal onHide={this.onHide}>
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <MultiSelect
                                    id="branch"
                                    innerRef={e => this.drpShifts = e}
                                    value={this.state.dataObject.branch}
                                    options={this.state.branchesList}
                                    caption={$.strings.additionalFilters.branch}
                                    onChange={(e) => this.handleUserInput(e)}

                                    maxSelectedLabels="2"
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <MultiSelect
                                    id="department"
                                    value={this.state.dataObject.department}
                                    options={this.state.departmentList}
                                    caption={$.strings.additionalFilters.department}
                                    onChange={(e) => this.handleUserInput(e)}

                                    maxSelectedLabels="2"
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <MultiSelect
                                    id="classification"
                                    value={this.state.dataObject.classification}
                                    options={this.state.classificationsList}
                                    caption={$.strings.additionalFilters.classification}
                                    onChange={(e) => this.handleUserInput(e)}

                                    maxSelectedLabels="2"
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <MultiSelect
                                    id="classification2"
                                    value={this.state.dataObject.classification2}
                                    options={this.state.classifications2List}
                                    caption={$.strings.additionalFilters.classification2}
                                    onChange={(e) => this.handleUserInput(e)}

                                    maxSelectedLabels="2"
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <MultiSelect
                                    id="job"
                                    value={this.state.dataObject.job}
                                    options={this.state.jobsList}
                                    caption={$.strings.additionalFilters.job}
                                    onChange={(e) => this.handleUserInput(e)}

                                    maxSelectedLabels="2"
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Dropdown
                                    id="gender"
                                    value={this.state.dataObject.gender}
                                    options={this.gendersList}
                                    caption={$.strings.additionalFilters.gender}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <MultiSelect
                                    id="qualification"
                                    value={this.state.dataObject.qualification}
                                    options={this.state.qualificationsList}
                                    caption={$.strings.additionalFilters.qualification}
                                    onChange={(e) => this.handleUserInput(e)}

                                    maxSelectedLabels="2"
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="employee"
                                    value={this.state.dataObject.directManager ?
                                        this.state.dataObject.directManager.code + " / " + this.state.dataObject.directManager.name
                                        : ""
                                    }
                                    caption={$.strings.additionalFilters.directManager}
                                    withgroup={true}
                                    btn1event={this.searchEmployee}
                                    btn1icon="search"
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <MultiSelect
                                    id="shift"
                                    value={this.state.dataObject.shift}
                                    options={this.state.shiftsList}
                                    caption={$.strings.additionalFilters.shift}
                                    onChange={(e) => this.handleUserInput(e)}

                                    maxSelectedLabels="2"
                                    optionLabel="ShiftName"
                                    filter
                                />
                            </div>
                            {/* <div className="p-col-12 p-lg-6 p-sm-12">
                                <Dropdown
                                    id="periodType"
                                    value={this.state.dataObject.periodType}
                                    options={this.state.periodTypesList}
                                    caption={$.strings.additionalFilters.periodType}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    filter
                                />
                            </div> */}
                        </div>
                    </div >
                </Dialog >
            </div>
        );
    }

    searchEmployee = async () => {
        await this.dlgSearchEmployee.show();
    }
    searchEmployeeSuccess = (obj) => {
        if (obj) {
            this.setState({ dataObject: { ...this.state.dataObject, directManager: obj } })
        }
    }

    getBranches = async (fromLoad) => {
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        let dataTemp = []
        let obj = {
            type: 7
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        if (fromLoad)
            return dataTemp
        else
            this.setState({ branchesList: dataTemp, loading: false })
    }
    getDepartments = async (fromLoad) => {
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        if (fromLoad)
            return dataTemp
        else
            this.setState({ departmentList: dataTemp })
    }
    getClassifications = async (fromLoad) => {
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        let dataTemp = []
        let obj = {
            type: 12
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        if (fromLoad)
            return dataTemp
        else
            this.setState({ classificationsList: dataTemp })
    }
    getClassifications2 = async (fromLoad) => {
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        let dataTemp = []
        let obj = {
            type: 25
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        if (fromLoad)
            return dataTemp
        else
            this.setState({ classifications2List: dataTemp })
    }
    getJobs = async (fromLoad) => {
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        let dataTemp = []
        let obj = {
            type: 3
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        if (fromLoad)
            return dataTemp
        else
            this.setState({ jobsList: dataTemp })
    }
    getQualificationsList = async (fromLoad) => {
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        let dataTemp = []
        let obj = {
            type: 13
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        if (fromLoad)
            return dataTemp
        else
            this.setState({ qualificationsList: dataTemp })
    }
    getShifts = async (fromLoad) => {
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        if (fromLoad) {
            return dataTemp;
        }
        else {
            this.setState({ shiftsList: dataTemp, loading: false })
        }
    }

    show = async (filterObject) => {
        this.setState({ loading: true });
        let branchesList = await this.getBranches(true);
        let departmentList = await this.getDepartments(true);
        let classificationsList = await this.getClassifications(true);
        let classifications2List = await this.getClassifications2(true);
        let jobsList = await this.getJobs(true);
        let qualificationsList = await this.getQualificationsList(true);
        let shiftsList = await this.getShifts(true);

        let dataObject = {
            ...this.state.dataObject,
            branch: undefined, department: undefined, classification: undefined, classification2: undefined,
            job: undefined, qualification: undefined, shift: undefined,
        }
        if (filterObject) {
            dataObject = filterObject
        }
        
        this.setState({
            visibleDialog: true, branchesList: branchesList, departmentList: departmentList,
            classificationsList: classificationsList, classifications2List: classifications2List,
            jobsList: jobsList, qualificationsList: qualificationsList, shiftsList: shiftsList,
            loading: false,
            dataObject: dataObject

        })
    }

    onHide = () => {
        this.setState({ visibleDialog: false, dataObject: this.emptyObj })
    }

    onSave = async () => {
        if (this.props.onSuccess) {
            this.props.onSuccess(this.state.dataObject);
        }
        this.onHide();
    }
}