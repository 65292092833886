import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollTop from '../ScrollButton';
import Messages from "../../Components/Messages";
import Print from "../../Print";

const $ = window.$;

export default class SeenNews extends React.Component {
    constructor(props) {
        super(props);
        this.statusList = [
            { id: -1, name: $.strings.all },
            { id: 0, name: $.strings.seenNews.notSeen },
            { id: 1, name: $.strings.seenNews.seen }
        ]
        this.state = {
            isDesktop: window.innerWidth > 1250,
            statusList: this.statusList,
            fromCode: undefined,
            toCode: undefined,
            newsTitle: undefined,
            fromDate: new Date(),
            toDate: new Date(),
            expieryDate: undefined,
            status: this.statusList[0],
            empCode: undefined,
            empId: undefined,
            jobId: undefined,
            data: []
        };
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(723)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
        }

        let _isDesktop = this.updatePredicate();
        this.setState({ isDesktop: _isDesktop, loading: false })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }
    handleTarget = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value }, () => this.getObjectsList());
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '50vh' }
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../MyStaff' },
            { label: $.strings.seenNews.reportTitle }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]}>
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.seenNews.fromCode}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.seenNews.toCode}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Calendar
                                id="fromDate"
                                value={this.state.fromDate}
                                caption={$.strings.seenNews.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Calendar
                                id="toDate"
                                value={this.state.toDate}
                                caption={$.strings.seenNews.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Calendar
                                id="expieryDate"
                                value={this.state.expieryDate}
                                caption={$.strings.seenNews.expieryDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Dropdown
                                id="status"
                                value={this.state.status}
                                options={this.state.statusList}
                                caption={$.strings.seenNews.status}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-6">
                            <Input
                                id="newsTitle"
                                value={this.state.newsTitle}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.seenNews.newsTitle}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            style={gridStyle}
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="RowNumber"
                            scheme={this.getScheme()}
                            dataSource={this.state.data}
                            exportFileName={$.strings.seenNews.title}
                        />
                    </div>
                </div>
            </Card>
            <ScrollTop />
        </div >
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            fromDate: this.state.fromDate || '',
            toDate: this.state.toDate || '',
            expieryDate: this.state.expieryDate || '',
            fromCode: this.state.fromCode || '',
            toCode: this.state.toCode || '',
            title: this.state.newsTitle || '',
            status: this.state.status && this.state.status.id >= 0 ? this.state.status.id : '-1'
        }

        let res = await http.getJsonAxios(http.actions.News.urlGetSeenNews, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.seenNews.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";
            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.seenNews.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.seenNews.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.seenNews.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.seenNews.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            // expieryDate Filter
            if (this.printFilters.expieryDate) {
                listOfFilters.push($.strings.seenNews.expieryDate + ": " + Util.getDateFormat(this.printFilters.expieryDate));
            }

            if (this.printFilters.status) {
                listOfFilters.push($.strings.seenNews.status + ": " + this.printFilters.status.name);
            }

            if (this.printFilters.newsTitle) {
                listOfFilters.push($.strings.seenNews.newsTitle + ": " + this.printFilters.newsTitle);
            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    getScheme = () => {
        return {
            name: "seenNewsScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 7,
            columns: [
                { name: 'RowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'ID', header: $.strings.seenNews.code, width: 80, visible: false, visibleInColumnChooser: false },
                { name: 'NewsDate', header: $.strings.seenNews.newsDate, width: 120, columnWidth: '3' },
                { name: 'NewsTime', header: $.strings.seenNews.newsTime, width: 120, columnWidth: '3' },
                { name: 'Target', header: $.strings.seenNews.target, width: 130, columnWidth: '3' },
                { name: 'NewsTitle', header: $.strings.seenNews.newsTitle, width: "*", columnWidth: '*' },
                { name: 'NewsDetails', header: $.strings.seenNews.newsDetails, width: "*", visible: false, visibleInColumnChooser: false },
                { name: 'EmpName', header: $.strings.seenNews.empName, width: "*", columnWidth: '*' },
                { name: 'EmpCode', header: $.strings.seenNews.empCode, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'Status', header: $.strings.seenNews.status, width: 120, columnWidth: '2' },
                { name: 'ReadDate', header: $.strings.seenNews.readDate, width: 120, columnWidth: '3' },
                { name: 'ReadTime', header: $.strings.seenNews.readTime, width: 120, columnWidth: '3' }
            ]
        }
    }
}