import React from "react";
import DataGridView from '../../Components/DataGridView/DataGridView';
import Http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import { FaFlag } from 'react-icons/fa'
import Messages from '../../Components/Messages';
import Util from '../../Util'
import Button from '../../Components/Button';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import SCC from "../ScreenCommonCode";
import { Accordion, AccordionTab } from "primereact/accordion";
import Input from "../../Components/Input";
import Dropdown from "../../Components/Dropdown";
import Checkbox from "../../Components/Checkbox";
import ButtonsBarLookupList from "../../Components/ButtonsBarLookupList";
import LateSettings from "../SystemSettings/LateSettings";
import MultiSelect from "../../Components/MultiSelect";
import Calendar from "../../Components/Calendar";
import AdditionalFilters from "../AdditionalFilters/AdditionalFilters";
import * as wjcCore from '@grapecity/wijmo';
import InputTime from "../../Components/InputTime";
import Print from "../../Print";

const $ = window.$;

export default class SalariesPerDay extends React.Component {
    constructor(props) {
        super(props);
        this.nowDate = new Date();
        this.nowDate.setHours(0, 0, 0, 0);
        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            sectionsList: [],
            employeesList: [],
            data: [],
            dataObject: {
                section: [],
                employees: [],
                fromDate: this.nowDate,
                toDate: this.nowDate,
                showDailyStaffOnly: true,
                additionalFiltersObj: undefined
            },
            isDesktop: true
        };
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR() || !Util.checkRights(516)) {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = window.innerWidth > 1250
        let sectionsPromise = this.getSections();
        let employeesPromise = this.getEmployeesList();

        let sectionsList = await sectionsPromise;
        let employeesList = await employeesPromise;
        this.transferToSalary = await Util.GetSettingById(42);

        let dataTemp = [];
        this.setState({
            sectionsList: sectionsList, employeesList: employeesList,
            data: dataTemp, loading: false, isDesktop: _isDesktop,
            dataObject: { ...this.state.dataObject }
        });
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    handleMultiSelect = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    render() {
        const gridStyle = { minHeight: '40vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref={e => this.divMain = e} >
                <ProgressSpinner loading={this.state.loading} />
                {/* <LateSettings ref={e => this.dlgLateSettings = e} /> */}
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    <ButtonsBarLookupList onRefresh={e => this.onRefresh(false)} onExcelExport={this.excelExport} onPrint={this.print} />

                    <Messages innerRef={(el) => this.messages = el} />
                    <LateSettings ref={e => this.dlgLateSettings = e} />
                    <AdditionalFilters ref={e => this.dlgAdditionalFilters = e} onSuccess={this.onAdditionalFiltersSuccess} />
                </Card>
                <Accordion multiple activeIndex={[0]}>
                    <AccordionTab header={$.strings.filterCaption}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <MultiSelect
                                    id="section"
                                    innerRef={e => this.drpSection = e}
                                    value={this.state.dataObject.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.salariesPerDay.section}
                                    onChange={(e) => this.handleUserInput(e)}
                                    optionLabel="Name"
                                    display="chip"
                                    maxSelectedLabels="2"
                                    filter={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <MultiSelect
                                    id="employees"
                                    value={this.state.dataObject.employees}
                                    options={this.state.employeesList}
                                    caption={$.strings.salariesPerDay.employee}
                                    onChange={this.handleMultiSelect}
                                    optionLabel="name"
                                    display="chip"
                                    maxSelectedLabels="2"
                                    showSelectAll={false}
                                    filter
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Calendar
                                    id="fromDate"
                                    innerRef={(el) => this.dtpFromDate = el}
                                    value={this.state.dataObject.fromDate}
                                    caption={$.strings.salariesPerDay.fromDate}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Calendar
                                    id="toDate"
                                    value={this.state.dataObject.toDate}
                                    caption={$.strings.salariesPerDay.toDate}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                <Checkbox id="showDailyStaffOnly" caption={$.strings.salariesPerDay.showDailyStaffOnly}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showDailyStaffOnly: e.checked } })}
                                    checked={this.state.dataObject.showDailyStaffOnly} />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                <Button label={$.strings.additionalFilters.title} className={this.state.dataObject.additionalFiltersObj ? 'p-button-warning' : ''}
                                    onClick={e => this.dlgAdditionalFilters.show(this.state.dataObject.additionalFiltersObj)} />
                                <Button label="" icon="pi pi-filter-slash" visible={this.state.dataObject.additionalFiltersObj} tooltip={$.strings.additionalFilters.clearFilters}
                                    onClick={e => this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: undefined } })}
                                />
                            </div>
                            {
                                this.transferToSalary === "1" &&
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                    <Button label={$.strings.salariesPerDay.transferToSalary} icon="pi pi-save" className="p-button-success" onClick={this.onSaveData}
                                        disabled={!this.state.data || this.state.data.length <= 0} />
                                </div>
                            }
                        </div>

                    </AccordionTab>
                </Accordion>
                <Card>
                    <DataGridView
                        style={gridStyle}
                        ref={e => this.gridData = e}
                        isReport={true}
                        scheme={this.getScheme()}
                        exportFileName={this.state.pageInfo.title}
                        dataSource={this.state.data}
                        defaultRowHeight={45}
                    />
                </Card>
            </div >
        );
    }
    getError(item, propName, parsing = false) {
        switch (propName) {
            default: {
                break;
            }
        }
        return null;
    }

    onSaveData = async () => {
        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.salariesPerDay.dataError)
            return;
        }
        this.setState({ loading: true });
        let success = false;
        let dataTemp = [];
        const params = {
            FromDate: this.state.data[0].FromDate,
            ToDate: this.state.data[0].ToDate,
            FromTime: this.state.data[0].FromTime,
            ToTime: this.state.data[0].ToTime,
            dt_result: this.state.data,
            UserId: localStorage.getItem("useridHR") + "",
        }

        let res = await Http.postMultipartAxios(Http.actions.Reports.urlPostSaveSalaryForDays, params, this.state.pageInfo.pageId);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                success = res.data.success;
                dataTemp = res.data.dt;
                if (success) {
                    let msg = $.strings.operationDone;
                    if (res.data.message && res.data.message.length > 0)
                        msg = res.data.message;
                    Util.showSuccessMsg(this.messages, msg);
                }
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data.message && res.data.message.length > 0) {
                    msg = res.data.message;
                    Util.showErrorMsg(this.messages, msg, false);
                }
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data.message && res.data.message.length > 0) {
                msg = res.data.message;
                Util.showErrorMsg(this.messages, msg, false);
            }
        }
        this.setState({ data: dataTemp, loading: false })
    }

    setPageInfo = () => {
        let pageId
        let title = $.strings.salariesPerDay.title

        return { pageId: pageId, title: title };
    };

    onAdditionalFiltersSuccess = (obj) => {
        if (obj) {

            this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: obj } })
        }
    }

    onClose = async (e) => {
        this.onRefresh();
        this.setState({ dlgSearchVisible: false });
    };


    btnRefresh = e => {
        this.setState({ data: this.onRefresh(false) });
    };

    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject(this.state.pageInfo.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let dateFilter = "";

            if (this.printFilters.section && this.printFilters.section.length === 1) {
                let sectionFilter = "";
                sectionFilter += " " + $.strings.salariesPerDay.section + ": ";
                for (let index = 0; index < this.printFilters.section.length; index++) {
                    let name = this.printFilters.section[index].Name;
                    if (index > 0)
                        sectionFilter += ", ";
                    sectionFilter += " " + name;
                }
                if (sectionFilter !== "")
                    listOfFilters.push(sectionFilter);
            }

            if (this.printFilters.employees && this.printFilters.employees.length === 1) {
                let employeesFilter = "";
                employeesFilter += " " + $.strings.salariesPerDay.employee + ": ";
                for (let index = 0; index < this.printFilters.employees.length; index++) {
                    let name = this.printFilters.employees[index].name;
                    if (index > 0)
                        employeesFilter += ", ";
                    employeesFilter += " " + name;
                }
                if (employeesFilter !== "")
                    listOfFilters.push(employeesFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.salariesPerDay.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.salariesPerDay.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.showDailyStaffOnly) {
                listOfFilters.push($.strings.salariesPerDay.showDailyStaffOnly + ": " + (this.printFilters.showDailyStaffOnly ? $.strings.yes : $.strings.no))
            }


        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    onRefresh = async (fromLoad) => {
        let dataTemp = [];
        if (!this.state.dataObject.fromDate) {
            Util.showErrorMsg(this.messages, $.strings.salariesPerDay.fromDateError)
            return;
        }
        if (!this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.salariesPerDay.toDateError)
            return;
        }
        if (this.state.dataObject.fromDate > this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.salariesPerDay.dateError)
            return;
        }

        this.setState({ loading: true, data: [] });

        let generalFillter = {};

        if (this.state.dataObject.section) {
            let sectionsList = []
            for (let index = 0; index < this.state.dataObject.section.length; index++) {
                sectionsList.push(this.state.dataObject.section[index].Code)
            }
            generalFillter.SectionList = sectionsList;
        }
        if (this.state.dataObject.employees) {
            let empList = [];
            for (let index = 0; index < this.state.dataObject.employees.length; index++) {
                empList.push(this.state.dataObject.employees[index].code)
            }
            generalFillter.EmpList = empList;
        }
        Util.fillAditionalsFilters(this.state.dataObject.additionalFiltersObj, generalFillter);

        const params = {
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            SalaryType5: this.state.dataObject.showDailyStaffOnly,
            generalFillter: generalFillter,
        }

        let res = await Http.postMultipartAxios(Http.actions.Reports.urlPostGetSalaryForDays, params, this.state.pageInfo.pageId);

        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.list;
                if (dataTemp && dataTemp.length > 0) {
                    dataTemp.forEach(element => {
                        //element.Selected = true;
                        element.FromDate = this.state.dataObject.fromDate;
                        element.ToDate = this.state.dataObject.toDate;
                        element.FromTime = this.state.dataObject.fromTime;
                        element.ToTime = this.state.dataObject.toTime;
                    });
                }
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data && res.data.message)
                    msg = res.data.message;
                Util.showErrorMsg(this.messages, msg);
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.message)
                msg = res.data.message;
            Util.showErrorMsg(this.messages, msg);
        }
        if (fromLoad) {
            return dataTemp;
        }
        else {
            this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state.dataObject)
        }
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.splice(0, 1);
            }
        }
        return dataTemp
    }
    getEmployeesList = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: !this.state.empCodeOrName || this.state.empCodeOrName.length <= 0 ? '' : this.state.empCodeOrName,
            isHr: Util.checkISHR(),
            getEmpStatus: this.state.getEmpStatus ? this.state.getEmpStatus : false,
            section: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            empStatus: this.state.empStatus && this.state.empStatus.id >= 0 ? this.state.empStatus.id : -1,
        }

        let res = await Http.getJsonAxios(Http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;

        }
        return dataTemp
    }

    getScheme = () => {
        let scheme = {
            name: "salariesPerDay_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                { name: 'RowNumber', header: '#', width: 60, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'Code', header: $.strings.salariesPerDay.empCode, width: 120, columnWidth: '2' },
                { name: 'Name', header: $.strings.salariesPerDay.empName, width: '*', minWidth: 150, columnWidth: '*' },
                { name: 'SectionName', header: $.strings.salariesPerDay.section, width: 200, visible: false, columnWidth: '*' },
                { name: 'JobName', header: $.strings.salariesPerDay.job, width: 200, visible: false, columnWidth: '*' },
                { name: 'DayCount', header: $.strings.salariesPerDay.daysCount, width: 100, columnWidth: '2' },
                { name: 'DayRent', header: $.strings.salariesPerDay.daySalary, width: 100, columnWidth: '2' },
                { name: 'Amount', header: $.strings.salariesPerDay.amount, width: 100, columnWidth: '2' },
                { name: 'Notes', header: $.strings.salariesPerDay.note, width: 200, columnWidth: '*' },
                { name: 'SalaryType', header: 'SalaryType', visible: false, visibleInColumnChooser: false, columnWidth: '2' },
                { name: 'Selected', header: 'Selected'.note, visible: false, visibleInColumnChooser: false, columnWidth: '2' }
            ]
        }
        return scheme;
    }
}