import React from "react";
import DataGridView from '../../Components/DataGridView/DataGridView';
import Http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import { FaFlag } from 'react-icons/fa'
import Messages from '../../Components/Messages';
import Util from '../../Util'
import Button from '../../Components/Button';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import SCC from "../ScreenCommonCode";
import { Accordion, AccordionTab } from "primereact/accordion";
import Input from "../../Components/Input";
import Dropdown from "../../Components/Dropdown";
import Checkbox from "../../Components/Checkbox";
import ButtonsBarLookupList from "../../Components/ButtonsBarLookupList";
import LateSettings from "../SystemSettings/LateSettings";
import MultiSelect from "../../Components/MultiSelect";
import Calendar from "../../Components/Calendar";
import AdditionalFilters from "../AdditionalFilters/AdditionalFilters";
import * as wjcCore from '@grapecity/wijmo';
import Print from "../../Print";

const $ = window.$;

export default class MonthlyEmployeeDelayRpt extends React.Component {
    constructor(props) {
        super(props);
        this.nowDate = new Date();
        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            firstExecution: false,
            sectionsList: [],
            employeesList: [],
            data: [],
            dataObject: {
                section: [],
                employees: [],
                fromDate: new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), 1),
                toDate: this.nowDate,
                considerAllowedPeriods: true,
                checkSpecialLeave: false,
                checkVacations: false,
                showReportToProcessDelay: false,
                recalculateThePreprocessor: false,
                checkHaveTrans: false,
                transDate: this.nowDate,
                additionalFiltersObj: undefined
            },
            isDesktop: true
        };
    }

    componentDidMount = async () => {
        // if (!$.license.HR_Module) {
        //     Util.goTo('/License');
        //     return;
        // }
        // if (!Util.checkISHR() || !Util.checkRights(167)) {
        //     Util.goTo('/Access');
        //     return;
        // }

        let _isDesktop = window.innerWidth > 1250
        let sectionsPromise = this.getSections();
        let employeesPromise = this.getEmployeesList();

        let sectionsList = await sectionsPromise;
        let employeesList = await employeesPromise;

        let dataTemp = [];//await this.onRefresh(true);
        this.setState({
            sectionsList: sectionsList, employeesList: employeesList,
            data: dataTemp, loading: false, isDesktop: _isDesktop,
            dataObject: { ...this.state.dataObject }
        });
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    handleMultiSelect = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    render() {
        const gridStyle = { minHeight: '40vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div ref={e => this.divMain = e} >
                <ProgressSpinner loading={this.state.loading} />
                {/* <LateSettings ref={e => this.dlgLateSettings = e} /> */}
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    <ButtonsBarLookupList onRefresh={e => this.onRefresh(false)} onExcelExport={this.excelExport} onPrint={this.onPrint} />

                    <Messages innerRef={(el) => this.messages = el} />
                    <LateSettings ref={e => this.dlgLateSettings = e} />
                    <AdditionalFilters ref={e => this.dlgAdditionalFilters = e} onSuccess={this.onAdditionalFiltersSuccess} />
                </Card>
                <Accordion multiple activeIndex={[0]}>
                    <AccordionTab header={$.strings.filterCaption}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <MultiSelect
                                    id="section"
                                    innerRef={e => this.drpSection = e}
                                    value={this.state.dataObject.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.monthlyEmployeeDelayRpt.section}
                                    onChange={(e) => this.handleUserInput(e)}
                                    optionLabel="Name"
                                    display="chip"
                                    maxSelectedLabels="2"
                                    filter={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <MultiSelect
                                    id="employees"
                                    value={this.state.dataObject.employees}
                                    options={this.state.employeesList}
                                    caption={$.strings.monthlyEmployeeDelayRpt.employee}
                                    onChange={this.handleMultiSelect}
                                    optionLabel="name"
                                    display="chip"
                                    maxSelectedLabels="2"
                                    showSelectAll={false}
                                    filter
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Calendar
                                    id="fromDate"
                                    innerRef={(el) => this.dtpFromDate = el}
                                    value={this.state.dataObject.fromDate}
                                    caption={$.strings.monthlyEmployeeDelayRpt.fromDate}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Calendar
                                    id="toDate"
                                    innerRef={(el) => this.dtpFromDate = el}
                                    value={this.state.dataObject.toDate}
                                    caption={$.strings.monthlyEmployeeDelayRpt.toDate}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                <Button label={$.strings.additionalFilters.title} className={this.state.dataObject.additionalFiltersObj ? 'p-button-warning' : ''}
                                    onClick={e => this.dlgAdditionalFilters.show()} />
                                <Button label="" icon="pi pi-filter-slash" visible={this.state.dataObject.additionalFiltersObj} tooltip={$.strings.additionalFilters.clearFilters}
                                    onClick={e => this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: undefined } })}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Checkbox id="considerAllowedPeriods" caption={$.strings.monthlyEmployeeDelayRpt.considerAllowedPeriods}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, considerAllowedPeriods: e.checked } })}
                                    checked={this.state.dataObject.considerAllowedPeriods} />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Checkbox id="checkSpecialLeave" caption={$.strings.monthlyEmployeeDelayRpt.checkSpecialLeave}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, checkSpecialLeave: e.checked } })}
                                    checked={this.state.dataObject.checkSpecialLeave} />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Checkbox id="checkVacations" caption={$.strings.monthlyEmployeeDelayRpt.checkVacations}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, checkVacations: e.checked } })}
                                    checked={this.state.dataObject.checkVacations} />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Checkbox id="showReportToProcessDelay" caption={$.strings.monthlyEmployeeDelayRpt.showReportToProcessDelay}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showReportToProcessDelay: e.checked } })}
                                    checked={this.state.dataObject.showReportToProcessDelay} />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Checkbox id="recalculateThePreprocessor" caption={$.strings.monthlyEmployeeDelayRpt.recalculateThePreprocessor}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, recalculateThePreprocessor: e.checked } })}
                                    checked={this.state.dataObject.recalculateThePreprocessor} />
                            </div>

                        </div>
                    </AccordionTab>
                    {
                        this.state.dataObject.showReportToProcessDelay && <AccordionTab header={$.strings.saveData}>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <Calendar
                                        id="transDate"
                                        value={this.state.dataObject.transDate}
                                        caption={$.strings.monthlyEmployeeDelayRpt.transDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                    <Checkbox id="checkHaveTrans" caption={$.strings.monthlyEmployeeDelayRpt.checkHaveTrans}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, checkHaveTrans: e.checked } })}
                                        checked={this.state.dataObject.checkHaveTrans} />
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                    <Button label={$.strings.firstExecution} icon="pi pi-check" onClick={this.onFirstExecution}
                                        disabled={!this.state.data || this.state.data.length <= 0 || !this.state.dataObject.showReportToProcessDelay} />
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                    <Button label={$.strings.saveData} icon="pi pi-save" className="p-button-success" onClick={this.onSavePenalties}
                                        disabled={!this.state.data || this.state.data.length <= 0 || !this.state.firstExecution} />
                                </div>
                            </div>
                        </AccordionTab>
                    }
                </Accordion>
                <Card>
                    <DataGridView
                        style={gridStyle}
                        ref={e => this.gridData = e}
                        isReport={true}
                        scheme={this.getScheme()}
                        exportFileName={this.state.pageInfo.title}
                        dataSource={this.state.data}
                        defaultRowHeight={45}
                    />
                </Card>
            </div >
        );
    }
    getError(item, propName, parsing = false) {
        switch (propName) {
            default: {
                break;
            }
        }
        return null;
    }
    onFirstExecution = () => {
        let dataTemp = this.state.data;
        if (dataTemp) {
            let data = dataTemp.filter(e => e.LatePenalties > 0);

            this.setState({ data: data, firstExecution: true });
        }
    }
    onSavePenalties = async () => {
        if (!this.state.dataObject.transDate) {
            Util.showErrorMsg(this.messages, $.strings.monthlyEmployeeDelayRpt.transDateError)
            return;
        }
        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.monthlyEmployeeDelayRpt.dataError)
            return;
        }
        this.setState({ loading: true });
        let success = false;
        let dataTemp = [];
        const params = {
            TransDate: this.state.dataObject.transDate,
            CheckHaveTrans: this.state.dataObject.checkHaveTrans,
            dt_Penalties: this.state.data,
            UserId: localStorage.getItem("useridHR") + ""
        }

        let res = await Http.postMultipartAxios(Http.actions.Reports.urlPostSavePenalties, params, this.state.pageInfo.pageId);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                success = res.data;
                dataTemp = res.data.dt_Penalties;
                if (success) {
                    Util.showSuccessMsg(this.messages);
                }
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data.errorText && res.data.errorText.length > 0) {
                    msg = res.data.errorText;
                    Util.showErrorMsg(this.messages, msg, false);
                }
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data.errorText && res.data.errorText.length > 0) {
                msg = res.data.errorText;
                Util.showErrorMsg(this.messages, msg, false);
            }
        }
        this.setState({ data: dataTemp, loading: false })
    }

    setPageInfo = () => {
        let pageId
        let title = $.strings.monthlyEmployeeDelayRpt.title

        return { pageId: pageId, title: title };
    };

    onAdditionalFiltersSuccess = (obj) => {
        if (obj) {

            this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: obj } })
        }
    }

    onClose = async (e) => {
        this.onRefresh();
        this.setState({ dlgSearchVisible: false });
    };


    btnRefresh = e => {
        this.setState({ data: this.onRefresh(false) });
    };

    excelExport = () => {
        this.gridData.exportCSV();
    }

    onPrint = () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject(this.state.pageInfo.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let dateFilter = "";

            if (this.printFilters.section && this.printFilters.section.length === 1) {
                let sectionFilter = "";
                sectionFilter += " " + $.strings.monthlyEmployeeDelayRpt.section + ": ";
                for (let index = 0; index < this.printFilters.section.length; index++) {
                    let name = this.printFilters.section[index].Name;
                    if (index > 0)
                        sectionFilter += ", ";
                    sectionFilter += " " + name;
                }
                if (sectionFilter !== "")
                    listOfFilters.push(sectionFilter);
            }

            if (this.printFilters.employees && this.printFilters.employees.length === 1) {
                let employeeFilter = "";
                employeeFilter += " " + $.strings.monthlyEmployeeDelayRpt.employee + ": ";
                for (let index = 0; index < this.printFilters.employees.length; index++) {
                    let name = this.printFilters.employees[index].Name;
                    if (index > 0)
                        employeeFilter += ", ";
                    employeeFilter += " " + name;
                }
                if (employeeFilter !== "")
                    listOfFilters.push(employeeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.monthlyEmployeeDelayRpt.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.monthlyEmployeeDelayRpt.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.considerAllowedPeriods) {
                listOfFilters.push($.strings.monthlyEmployeeDelayRpt.considerAllowedPeriods + ": " + (this.printFilters.considerAllowedPeriods ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters.checkSpecialLeave) {
                listOfFilters.push($.strings.monthlyEmployeeDelayRpt.checkSpecialLeave + ": " + (this.printFilters.checkSpecialLeave ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters.checkVacations) {
                listOfFilters.push($.strings.monthlyEmployeeDelayRpt.checkVacations + ": " + (this.printFilters.checkVacations ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters.showReportToProcessDelay) {
                listOfFilters.push($.strings.monthlyEmployeeDelayRpt.showReportToProcessDelay + ": " + (this.printFilters.showReportToProcessDelay ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters.recalculateThePreprocessor) {
                listOfFilters.push($.strings.monthlyEmployeeDelayRpt.recalculateThePreprocessor + ": " + (this.printFilters.recalculateThePreprocessor ? $.strings.yes : $.strings.no));
            }


        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    onRefresh = async (fromLoad) => {
        let dataTemp = [];
        if (!this.state.dataObject.fromDate) {
            Util.showErrorMsg(this.messages, $.strings.monthlyEmployeeDelayRpt.fromDateError)
            return;
        }
        if (!this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.monthlyEmployeeDelayRpt.toDateError)
            return;
        }
        if (this.state.dataObject.fromDate > this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.monthlyEmployeeDelayRpt.dateError)
            return;
        }

        if (this.state.dataObject.fromDate.getMonth() !== this.state.dataObject.toDate.getMonth() ||
            this.state.dataObject.fromDate.getFullYear() !== this.state.dataObject.toDate.getFullYear()) {
            Util.showErrorMsg(this.messages, $.strings.monthlyEmployeeDelayRpt.dateRangeError)
            return;
        }

        this.setState({ loading: true, firstExecution: false, data: [] });

        let GeneralFillter = {};

        if (this.state.dataObject.section) {
            let sectionsList = []
            for (let index = 0; index < this.state.dataObject.section.length; index++) {
                sectionsList.push(this.state.dataObject.section[index].Code)
            }
            GeneralFillter.SectionList = sectionsList;
        }
        if (this.state.dataObject.employees) {
            let EmpList = [];
            for (let index = 0; index < this.state.dataObject.employees.length; index++) {
                EmpList.push(this.state.dataObject.employees[index].code)
            }
            GeneralFillter.EmpList = EmpList;
        }


        if (this.state.dataObject.additionalFiltersObj) {
            // Branches
            if (this.state.dataObject.additionalFiltersObj.branch) {
                let BranchesList = [];
                for (let index = 0; index < this.state.dataObject.additionalFiltersObj.branch.length; index++) {
                    BranchesList.push(this.state.dataObject.additionalFiltersObj.branch[index].pos)
                }
                GeneralFillter.BranchesList = BranchesList;
            }
            // Departments
            if (this.state.dataObject.additionalFiltersObj.department) {
                let DepartmentsList = [];
                for (let index = 0; index < this.state.dataObject.additionalFiltersObj.department.length; index++) {
                    DepartmentsList.push(this.state.dataObject.additionalFiltersObj.department[index].pos)
                }
                GeneralFillter.DepartmentsList = DepartmentsList;
            }
            // Classifications List
            if (this.state.dataObject.additionalFiltersObj.classification) {
                let ClassificationsList = [];
                for (let index = 0; index < this.state.dataObject.additionalFiltersObj.classification.length; index++) {
                    ClassificationsList.push(this.state.dataObject.additionalFiltersObj.classification[index].pos)
                }
                GeneralFillter.ClassificationsList = ClassificationsList;
            }
            // Other Classifications List
            if (this.state.dataObject.additionalFiltersObj.classification2) {
                let OtherClassificationsList = [];
                for (let index = 0; index < this.state.dataObject.additionalFiltersObj.classification2.length; index++) {
                    OtherClassificationsList.push(this.state.dataObject.additionalFiltersObj.classification2[index].pos)
                }
                GeneralFillter.OtherClassificationsList = OtherClassificationsList;
            }
            // Jobs List
            if (this.state.dataObject.additionalFiltersObj.job) {
                let JobsList = [];
                for (let index = 0; index < this.state.dataObject.additionalFiltersObj.job.length; index++) {
                    JobsList.push(this.state.dataObject.additionalFiltersObj.job[index].pos)
                }
                GeneralFillter.JobsList = JobsList;
            }
            // Qualifications List
            if (this.state.dataObject.additionalFiltersObj.qualification) {
                let QualificationsList = [];
                for (let index = 0; index < this.state.dataObject.additionalFiltersObj.qualification.length; index++) {
                    QualificationsList.push(this.state.dataObject.additionalFiltersObj.qualification[index].pos)
                }
                GeneralFillter.QualificationsList = QualificationsList;
            }
            // Shifts List
            if (this.state.dataObject.additionalFiltersObj.shift) {
                let ShiftsList = [];
                for (let index = 0; index < this.state.dataObject.additionalFiltersObj.shift.length; index++) {
                    ShiftsList.push(this.state.dataObject.additionalFiltersObj.shift[index].pos)
                }
                GeneralFillter.ShiftsList = ShiftsList;
            }
            // DirectManger
            if (this.state.dataObject.additionalFiltersObj.directManager) {
                GeneralFillter.DirectManger = this.state.dataObject.additionalFiltersObj.directManager.code;
            }
            // Gender
            if (this.state.dataObject.additionalFiltersObj.gender) {
                GeneralFillter.Sex = this.state.dataObject.additionalFiltersObj.gender.id;
            }
        }

        const params = {
            FromDate: this.state.dataObject.fromDate,
            ToDate: this.state.dataObject.toDate,
            Observe_grace_periods: this.state.dataObject.considerAllowedPeriods,
            Check_for_delayed_departures: this.state.dataObject.checkSpecialLeave,
            Check_for_vacations: this.state.dataObject.checkVacations,
            Show_the_report_to_address_the_delay: this.state.dataObject.showReportToProcessDelay,
            Recalculate_the_preprocessor: this.state.dataObject.recalculateThePreprocessor,
            GeneralFillter: GeneralFillter
        }

        let res = await Http.postMultipartAxios(Http.actions.Reports.urlMonthlyDelaysReport, params, this.state.pageInfo.pageId);

        if (res.status === 200) {
            dataTemp = res.data;
        }

        if (fromLoad) {
            return dataTemp;
        }
        else {
            this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state.dataObject)
        }
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.splice(0, 1);
            }
        }
        return dataTemp
    }
    getEmployeesList = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: !this.state.empCodeOrName || this.state.empCodeOrName.length <= 0 ? '' : this.state.empCodeOrName,
            isHr: Util.checkISHR(),
            getEmpStatus: this.state.getEmpStatus ? this.state.getEmpStatus : false,
            section: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            empStatus: this.state.empStatus && this.state.empStatus.id >= 0 ? this.state.empStatus.id : -1,
        }

        let res = await Http.getJsonAxios(Http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;

        }
        return dataTemp
    }

    getScheme = () => {
        let scheme = {
            name: "monthlyEmployeeDelay_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [
                { name: 'RowNumber', header: '#', width: 60, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'Code', header: $.strings.monthlyEmployeeDelayRpt.code, width: 80, columnWidth: '2' },
                { name: 'Name', header: $.strings.monthlyEmployeeDelayRpt.empName, width: '*', minWidth: 150, columnWidth: '*' },
                { name: 'LateTotal', header: $.strings.monthlyEmployeeDelayRpt.lateTotal, width: 80, dataType: 'Object', customAggregate: true, columnWidth: '2' },
                { name: 'LateCount', header: $.strings.monthlyEmployeeDelayRpt.lateCount, width: 80, dataType: 'Number', aggregate: 'Sum', columnWidth: '1' },
                {
                    name: 'LatePenalties', header: $.strings.monthlyEmployeeDelayRpt.latePenalties, width: 80, dataType: 'Number', aggregate: 'Sum',
                    visible: this.state.dataObject.showReportToProcessDelay, columnWidth: '2'
                },
                { name: 'AbsenceTransType', header: $.strings.monthlyEmployeeDelayRpt.absenceTransType, width: 120, visible: false, visibleInColumnChooser: false, },
                { name: 'FullTime', header: $.strings.monthlyEmployeeDelayRpt.fullTime, width: 120, visible: false, visibleInColumnChooser: false },
                { name: 'Job', header: $.strings.monthlyEmployeeDelayRpt.job, width: 120, columnWidth: '3' }
                ,
                { name: 'Notes', header: $.strings.monthlyEmployeeDelayRpt.notes, width: '*', minWidth: 120, columnWidth: '3' }
            ]
        }
        if (this.state.data && this.state.data.length > 0) {
            let objKeys = Object.keys(this.state.data[0]);
            for (let key = 0; key < objKeys.length; key++) {
                if (objKeys[key] !== "RowNumber" && objKeys[key] !== "Code" && objKeys[key] !== "Name" && objKeys[key] !== "LateTotal" &&
                    objKeys[key] !== "LateCount" && objKeys[key] !== "LatePenalties" && objKeys[key] !== "AbsenceTransType" &&
                    objKeys[key] !== "FullTime" && objKeys[key] !== "Job" && objKeys[key] !== "Notes" && objKeys[key] !== "FromDate" && objKeys[key] !== "ToDate") {
                    let col = { name: objKeys[key], header: objKeys[key], width: 60, maxWidth: 60, filterType: 'None', columnWidth: '1' }
                    if (!scheme.columns.includes(col))
                        scheme.columns.push(col);
                }
            }
        }
        return scheme;
    }
}