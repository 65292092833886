import React from "react";
import Input from "../../../Components/Input";
import Http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import Util from '../../../Util';
import Card from "../../../Components/Card";
import Dialog from "../../../Components/Dialog";
import SCC from '../../ScreenCommonCode'
import DataGridView from "../../../Components/DataGridView/DataGridView";
import SectionsTree from "./SectionsTree";
import Button from "../../../Components/Button";
const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class SectionsSearch extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            data: [],
            dataTree: []
        }
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250

        this.setState({ data: [], dataTree: [], isDesktop: _isDesktop, loading: false });
    }

    render() {
        const gridStyle = { minHeight: '40vh' }
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.ok} icon="" className="p-button-success" onClick={this.onAccept}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onClose}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={this.state.pageInfo.title} onHide={this.onClose} modal
                    visible={this.state.visibleDialog} footer={footer} icons={myIcon}
                    style={{ width: this.state.isDesktop ? '45vw' : '80vw', direction: $.strings.dir }}>
                    <ProgressSpinner loading={this.state.loading} />
                    <div className="p-grid">
                        {
                            this.state.isDesktop &&
                            <div className="p-col-12 p-lg-4 p-sm-12">
                                <SectionsTree value={this.state.dataTree} />
                            </div>
                        }
                        <div className="p-col-12 p-lg-8 p-sm-12">
                            <Card>
                                <DataGridView
                                    style={gridStyle}
                                    ref={e => this.gridData = e}
                                    isReport={true}
                                    scheme={this.getScheme()}
                                    exportFileName={this.state.pageInfo.title}
                                    dataSource={this.state.data}
                                    defaultRowHeight={45}
                                    onRowDoubleClick={Util.checkISHR() && Util.checkRights(165) ? this.onRowDoubleClick : undefined}
                                />
                            </Card>
                        </div>
                    </div>

                </Dialog>
            </div>
        );
    }

    onRowDoubleClick = (item) => {
        let objSelected = this.gridData.getCurrentRowItem();
        if (!objSelected) {
            Util.showInfoMsg(this.messages, $.strings.selectRow)
            return;
        }
        if (this.props.onSuccess) {
            this.props.onSuccess(item);
            this.setState({ visibleDialog: false })
        }
    }

    show = async () => {
        let sectionsList = await this.getSections();
        let dataTree = await this.getSectionsTree();

        this.setState({ data: sectionsList, dataTree: dataTree, visibleDialog: true, loading: false });
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        const params = {
            pos: -1,
            depCode: ' ',
            getDepChild: false,
            classification: -1
        };

        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSectionsList, params, this.state.pageInfo.pageId);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }

    getSectionsTree = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSectionsTree, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.listTree;
        }
        return dataTemp
    }

    onClose = (e) => {
        this.setState({ visibleDialog: false })
    }

    setPageInfo = () => {
        let pageId
        let title = $.strings.sections.searchTitle;

        return { pageId: pageId, title: title };
    };

    getScheme = () => {
        return {
            name: "sectionsSearch_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                {
                    name: 'rowNumber', header: '#', width: 70, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'pos', header: $.strings.sections.id, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'depCode', header: $.strings.sections.code, with: 80
                },
                {
                    name: 'depName', header: $.strings.sections.name, width: '*',
                }
            ]
        }
    }
}