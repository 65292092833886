import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util';
import Dropdown from "../../Components/Dropdown";
import ScrollTop from '../ScrollButton';
import Calendar from "../../Components/Calendar";
import { Accordion, AccordionTab } from 'primereact/accordion';
import InputTime from "../../Components/InputTime/InputTime";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class ClockTransEdit extends React.Component {

    constructor(props) {
        super(props);
        this.transTypesList = Util.getInOutTypes()
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            transTypesList: this.transTypesList,
            success: false,
            empCode: undefined,
            empName: undefined,
            transDate: undefined,
            fpReqId: 0,
            fpTransType1: undefined,
            fpTime1: undefined,
            fpTransType2: undefined,
            fpTime2: undefined,
            data: [],
            approvalNote: '',
            notes: ''
        }
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 1250 });
    };

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;
        window.addEventListener("resize", this.updatePredicate);
        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }
    handleTransType = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        let dataTemp = this.state.data
        dataTemp = dataTemp.map(
            el => {
                if (el.id + '' === name + '') {
                    el["transType"] = value;
                    el["transTime"] = value + "" !== "0" ? el["transTime"] : "00:00:00"
                }
                return el;
            }
        )
        this.setState({ data: dataTemp });
    }
    handleTransTime = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        let dataTemp = this.state.data
        dataTemp = dataTemp.map(
            el => {
                if (el.id + '' === name + '') {
                    el["transTime"] = value;
                }
                return el;
            }
        )
        this.setState({ data: dataTemp });
    }
    handleTransTimeWijmo = (id, e) => {
        //const name = e.id;
        const value = e.value;
        let dataTemp = this.state.data
        dataTemp = dataTemp.map(
            el => {
                if (el.id + '' === id + '') {
                    el["transTime"] = value;
                }
                return el;
            }
        )
        this.setState({ data: dataTemp });
    }
    handleTransDate = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        let dataTemp = this.state.data
        dataTemp = dataTemp.map(
            el => {
                if (el.id + '' === name + '') {
                    el["transDate"] = value;
                }
                return el;
            }
        )
        this.setState({ data: dataTemp });
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        //const gridStyle = { minHeight: this.state.filterShown ? '38vh' : '55vh' }
        return (
            <div style={{ direction: $.strings.dir }}>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={$.strings.clockTrans.clockTransEditTitle} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '45vw' : '90vw', direction: $.strings.dir, background: '#f4f4f4' }}
                    modal onHide={this.onHide}>
                    <div ref="divMain" >
                        <Messages innerRef={(el) => this.messages = el} />
                        <Accordion multiple activeIndex={[0, 1]}>
                            <AccordionTab header={this.state.empName}>
                                <div className="p-grid">
                                    <div className="p-col-4 p-xl-1 p-lg-2 p-md-4 p-sm-4">
                                        {$.strings.clockTrans.transDate}
                                    </div>
                                    <div className="p-col-8 p-xl-2 p-lg-6 p-md-4 p-sm-8">
                                        <Calendar
                                            id="transDate"
                                            value={this.state.transDate}
                                            // caption={$.strings.clockTrans.transDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                            disabled={this.state.fpReqId && this.state.fpReqId > 0 ? true : false}
                                        />
                                    </div>
                                    <div className="p-col-4 p-xl-2 p-lg-4 p-md-6 p-sm-4" style={{ alignSelf: 'flex-end' }}>
                                        <Button label={$.strings.refresh} icon="" onClick={this.getData}>
                                            &nbsp; &nbsp;
                                            <i className="pi pi-refresh" />
                                        </Button>
                                    </div>
                                    <div className="p-col-4 p-xl-2 p-lg-2 p-md-3 p-sm-4" style={{ alignSelf: 'flex-end' }}>
                                        <Button label={$.strings.save} icon="" onClick={this.saveData} className="p-button-success">
                                            &nbsp; &nbsp;
                                            <i className="pi pi-save" />
                                        </Button>
                                    </div>
                                    <div className="p-col-4 p-xl-2 p-lg-3 p-md-3 p-sm-4" style={{ alignSelf: 'flex-end' }}>
                                        <Button label={$.strings.delete} icon="" onClick={this.deleteData} className="p-button-danger">
                                            &nbsp; &nbsp;
                                            <i className="pi pi-trash" />
                                        </Button>
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab header={$.strings.data}>
                                {

                                    this.drawControls()

                                }
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-12 p-sm-12">
                                        <Input
                                            id="notes"
                                            value={this.state.notes || ''}
                                            caption={$.strings.clockTrans.notes}
                                            onChange={this.handleUserInput}
                                        />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <ScrollTop />
                    </div>
                </Dialog >
            </div >
        );
    }
    setDefaultValues = () => {
        let data = []
        for (let i = 1; i <= 10; i++) {
            data.push({ id: i, transTime: '00:00:00', transType: undefined, transDate: this.state.transDate, clockRealData: '' });
        }

        this.setState({ data: data })
    }
    drawControls = () => {
        let controls = []
        let tempData = this.state.data || this.setDefaultValues()
        if (tempData && tempData.length > 0) {
            controls.push(tempData.map((item, index) => {

                let transTime = new Date(this.state.data[index]["transTime"])
                let transDate = new Date(this.state.data[index]["transDate"])
                return (
                    < div className="p-grid">
                        <div className="p-col-12 p-xl-1 p-lg-1 p-md-12 p-sm-12" style={{ alignSelf: 'center' }}>
                            {index % 2 === 0 ? $.strings.clockTrans.in : $.strings.clockTrans.out}
                        </div>
                        <div className="p-col-6 p-xl-2 p-lg-2 p-md-6 p-sm-6">
                            <InputTime
                                id={(index + 1)}
                                value={transTime}
                                caption={(index === 0 || !this.state.isDesktop) ? $.strings.clockTrans.time : ''}
                                valueChanged={e => this.handleTransTimeWijmo(index + 1, e)}
                            />
                        </div>
                        <div className="p-col-6 p-xl-3 p-lg-3 p-md-6 p-sm-6">
                            <Dropdown
                                id={(index + 1)}
                                value={this.state.data[index]["transType"]}
                                options={this.state.transTypesList}
                                caption={(index === 0 || !this.state.isDesktop) ? $.strings.clockTrans.transType : ''}
                                onChange={this.handleTransType}
                                optionLabel="name"
                                optionValue="id"
                            />
                        </div>
                        <div className="p-col-6 p-xl-3 p-lg-3 p-md-6 p-sm-6">
                            <Calendar
                                id={(index + 1)}
                                value={transDate}
                                caption={(index === 0 || !this.state.isDesktop) ? $.strings.clockTrans.date : ''}
                                onChange={this.handleTransDate}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-xl-3 p-lg-3 p-md-6 p-sm-6">
                            <Input
                                id={(index + 1)}
                                value={this.state.data[index]["clockRealData"]}
                                caption={(index === 0 || !this.state.isDesktop) ? $.strings.clockTrans.valueFromClock : ''}
                            />
                        </div>
                    </div >
                )
            }))
        }
        return controls;
    }

    show = (empCode, transDate, fpReqId, fpTransType1, fpTime1, fpTransType2, fpTime2, approvalNote) => {

        this.setState({
            fpReqId: fpReqId, empCode: empCode, transDate: transDate,
            fpTransType1: fpTransType1, fpTime1: fpTime1, fpTransType2: fpTransType2, fpTime2: fpTime2,
            visibleDialog: true, data: this.setDefaultValues(), dataObject: [], approvalNote: approvalNote
        }, async () => { await this.getData() })
    }

    onHide = () => {
        this.setState({ data: [], visibleDialog: false })
    }

    getData = async () => {
        this.setState({ loading: true });
        let data = []
        let obj = {
            empCode: this.state.empCode,
            transDate: this.state.transDate || new Date(),
            fingerPrintRequest: this.state.fpReqId && this.state.fpReqId > 0 ? this.state.fpReqId : 0,
            fingerPrintDate: this.state.fingerPrintDate,
            fpTransType1: this.state.fpTransType1 && this.state.fpTransType1.length > 0 ? this.state.fpTransType1 : '',
            cTime1: this.state.fpTime1 && this.state.fpTime1.length > 0 ? this.state.fpTime1 : '',
            fpTransType2: this.state.fpTransType2 && this.state.fpTransType2.length > 0 ? this.state.fpTransType2 : '',
            cTime2: this.state.fpTime2 && this.state.fpTime2.length > 0 ? this.state.fpTime2 : ''
        }


        let res = await http.postMultipartAxios(http.actions.ClockTrans.urlGetClockTransactionForEdit, obj, 0);
        if (res.status === 200) {
            data = res.data.listOfClockTrans;
        }

        let empName = data && data.length > 0 ? data[0].empName : ''
        let notes = data && data.length > 0 ? data[0].notes : ''
        this.setState({ data: data, empName: empName, notes: notes, loading: false }, () => this.drawControls());
    }

    saveData = async () => {
        if (!this.state.empCode || this.state.empCode.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.clockTrans.employeeNotFound);
            return;
        }
        if (!this.state.transDate || this.state.transDate.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.clockTrans.dateNotFound);
            return;
        }
        let listOfClockTrans = []
        let dataTemp = this.state.data
        if (!dataTemp || dataTemp.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.clockTrans.dataNotFound);
            return;
        }
        for (let i = 0; i < dataTemp.length; i++) {
            listOfClockTrans.push({
                id: dataTemp[i].id, transTime: dataTemp[i].transTime, transType: dataTemp[i].transType,
                transDate: dataTemp[i].transDate, clockRealData: dataTemp[i].clockRealData, empCode: dataTemp[i].empCode,
                empName: dataTemp[i].empName, notes: this.state.notes
            });

        }
        let data = []
        let obj = {
            empCode: this.state.empCode,
            date: new Date(this.state.transDate),
            notes: this.state.notes,
            fpReqId: this.state.fpReqId,
            fpType1: this.state.fpTransType1,
            fpTime1: this.state.fpTime1,
            fpType2: this.state.fpTransType2,
            fpTime2: this.state.fpTime2,
            userId: localStorage.getItem("useridHR"),
            userName: localStorage.getItem("userNameHR"),
            listOfClockTrans: listOfClockTrans,
            approvalNote: this.state.approvalNote && this.state.approvalNote.toString().trim() !== "" ? this.state.approvalNote : ''
        }
        let res = await http.postMultipartAxios(http.actions.ClockTrans.urlPostSaveClockTransactions, obj, 0);
        if (res.status === 200) {
            data = res.data;
        }
        if (data.success) {
            this.setState({ visibleDialog: false, loading: false }, () => this.props.onSuccess ? this.props.onSuccess() : undefined);
        }
        else {
            let msg = data.errorText || $.strings.operationFailed
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false });
        }
    }

    deleteData = async () => {
        if (!window.confirm($.strings.clockTrans.deleteConfirmation)) {
            return;
        }
        if (!this.state.empCode || this.state.empCode.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.clockTrans.employeeNotFound);
            return;
        }
        if (!this.state.transDate || this.state.transDate.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.clockTrans.dateNotFound);
            return;
        }

        let data = []
        let obj = {
            empCode: this.state.empCode,
            date: new Date(this.state.transDate)
        }
        let res = await http.getJsonAxios(http.actions.ClockTrans.urlGetDeleteClockTransactions, obj, 0);
        if (res.status === 200) {
            data = res.data;
        }
        if (data.success) {
            this.setState({ visibleDialog: false, loading: false }, () => this.props.onSuccess ? this.props.onSuccess() : undefined);
        }
        else {
            let msg = data.errorText || $.strings.operationFailed
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false });
        }
    }
}