import React from "react";
import Messages from "../Components/Messages";
import Button from "../Components/Button";
import ButtonsBarLookups from "../Components/ButtonsBarLookups";

import {
  FaFlag,
  FaRegPlusSquare,
  FaRegEdit,
  FaCopy,
  FaCheckDouble,
  FaPlus,
  FaTrashAlt,
  FaRegUser,
} from "react-icons/fa";

const $ = window.$;

const ScreenCommonCode = {
  getScreenHeader_Buttons: (scr, isDialog) => {
    return (
      <div className={`${"p-grid"}`}>
        <div className="p-lg-12 p-sm-12" style={{ alignSelf: "flex-end" }}>
          <ButtonsBarLookups
            onNew={scr.onNew ? (e) => scr.onNew(e, true) : null}
            onDelete={scr.onDelete ? (e) => scr.onDelete(e, true) : null}
            onClose={isDialog && scr.onClose ? (e) => scr.onClose(e, true) : null}
            onSaveThenClose={
              scr.onSaveThenClose ? (e) => scr.onSaveThenClose(e) : null
            }
            onPrevious={scr.onPrevious ? (e) => scr.onPrevious(e, true) : null}
            onNext={scr.onNext ? (e) => scr.onNext(e, true) : null}
            onFirst={scr.onFirst ? (e) => scr.onFirst(e, true) : null}
            onLast={scr.onLast ? (e) => scr.onLast(e, true) : null}
            onClone={scr.onClone ? (e) => scr.onClone(e, true) : null}
            onSave={scr.onSave ? (e) => scr.onSave(e) : null}
            onDeactivate={
              scr.onDeactivate ? (e) => scr.onDeactivate(e, true, true) : null
            }
            onActivate={
              scr.onActivate ? (e) => scr.onActivate(e, true, true) : null
            }
            onLogFile={scr.onLogFile ? (e) => scr.onLogFile() : null}
            onRefresh={scr.onRefresh ? (e) => scr.onRefresh() : null}
            onPrint={scr.onPrint ? (e) => scr.onPrint() : null}
            onEdit={scr.onEdit ? (e) => scr.onEdit() : null}
            onExcelExport={scr.onExcelExport ? (e) => scr.onExcelExport() : null}
          />
        </div>
      </div>
    );
  },
  getQueryScreenHeader_Title: (scr, styles, title, drawFilterButton = true) => {
    return (
      <div className="p-grid">
        <h3 className={styles.title}>{title}</h3>
        <div className="p-col-1">
          {
            drawFilterButton &&
            <Button onClick={scr.showFilters} tooltip={$.strings.showFilters}>
              <i className="pi pi-angle-double-up" />
            </Button>
          }
        </div>
      </div>
    );
  },
  getScreenHeader_AlertMessages: (scr, styles) => {
    return (
      <div className="p-grid">
        <div className="p-lg-12 p-sm-12">
          <Messages innerRef={(el) => (scr.messages = el)} />
        </div>
      </div>
    );
  },
  getModeStatus: (scr, global) => {
    let modeAling = global.strings.dir === "rtl" ? "left" : "right";
    return (
      (scr.state.mode === "new" && (
        <div>
          <span
            style={{
              color: "limegreen",
              fontSize: "16px",
              textAlign: modeAling,
              verticalAlign: "top",
            }}
          >
            {global.strings.newMode}
          </span>
          <FaRegPlusSquare style={{ fontSize: "22px", color: "limegreen" }} />
        </div>
      )) ||
      (scr.state.mode === "edit" && (
        <div>
          <span
            style={{
              color: "Orange",
              fontSize: "16px",
              textAlign: modeAling,
              verticalAlign: "top",
            }}
          >
            {global.strings.editMode}
          </span>
          <FaRegEdit style={{ fontSize: "22px", color: "Orange" }} />
        </div>
      )) ||
      (scr.state.mode === "copy" && (
        <div>
          <span
            style={{
              color: "blue",
              fontSize: "16px",
              textAlign: modeAling,
              verticalAlign: "top",
            }}
          >
            {global.strings.copyMode}
          </span>
          <FaCopy style={{ fontSize: "22px", color: "blue" }} />
        </div>
      ))
    );
  },
  handleUserInput: (scr, e) => {
    const name = e.target.id;
    const value = e.target.value;
    scr.setState({ dataObject: { ...scr.state.dataObject, [name]: value } });
  },
  handleUserCheckbox: (scr, e) => {
    const name = e.target.id;
    const value = e.checked;
    scr.setState({ dataObject: { ...scr.state.dataObject, [name]: value } });
  },
};

export default ScreenCommonCode;
