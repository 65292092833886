import React from 'react'

const $ = window.$;

export default class PrintProtal extends React.PureComponent {

    constructor(props) {
        super(props)
        this.resizeRequests = []
    }

    render() {
        if (!this.props.printable)
            return null;

        let size = this.getPaperSize("A4");
        let orientation = "Portrait";
        let marginTop = 20;
        let marginLeft = 20;
        let marginBottom = 20;
        let marginRight = 20;
        let fontSize = "14px";
        let pageSize = 'A4';
        let fontName = 'Tajwal';

        let printSettings = localStorage.getItem("printSettings");
        if (printSettings) {
            printSettings = JSON.parse(printSettings);
            orientation = printSettings.orientation;
            marginTop = printSettings.marginTop;
            marginLeft = printSettings.marginLeft;
            marginBottom = printSettings.marginBottom;
            marginRight = printSettings.marginRight;
            fontSize = printSettings.fontSize.value + "px";
            pageSize = printSettings.pageSize.value;
            fontName = printSettings.fontName.value;
        }
        if (orientation === "Portrait")
            size = this.getPaperSize(pageSize);
        else
            size = this.getPaperSize(pageSize + "-" + orientation);
        //{ width: size.width, overflowX: 'auto', overflowY: "hidden", margin: 0, padding: 20, paddingTop: 0 }
        return (
            <div id="printArea" style={{
                width: size.width, overflowX: 'auto', overflowY: "hidden", margin: 0, padding: 0,
                paddingRight: marginRight, paddingTop: marginTop, paddingLeft: marginLeft, paddingBottom: marginBottom,
                fontFamily: fontName, fontSize: fontSize
            }}>
                <table>
                    <thead>
                        <tr>
                            <td>{this.props.header}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.printable}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                {this.props.footer || this.getReportFooter()}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div >
        )
    }

    componentDidUpdate() {
        this.printInternal();
    }

    printInternal = () => {
        if (this.props.printable && $.printResizeRequests.length === 0) {
            window.print();
            this.props.onEnd();
        }
    }

    print = (toPrint, header, footer) => {
        this.props.onPrint(toPrint, header, footer);
    }

    getPaperSize = (paper) => {
        switch (paper) {
            case "A3":
                return { width: "297mm", height: "420mm" }
            case "A3-Landscape":
                return { width: "420mm", height: "297mm" }
            case "A4":
                return { width: "210mm", height: "297mm" }
            case "A4-Landscape":
                return { width: "297mm", height: "210mm" }
            case "A5":
                return { width: "148mm", height: "210mm" }
            case "A5-Landscape":
                return { width: "210mm", height: "148mm" }
            case "A6":
                return { width: "105mm", height: "148mm" }
            case "A6-Landscape":
                return { width: "148mm", height: "105mm" }
            default:
                return { width: "auto", height: "auto" }
        }
    }

    getReportFooter = () => {
        return (
            <React.Fragment>
                <div style={{ height: 30 }}></div>
                <div style={{ position: 'fixed', display: 'flex', bottom: 20, right: 0, left: 0, justifyContent: 'center' }}>
                    <span>{$.strings.printingFooter}</span>
                </div>
            </React.Fragment>
        )
    }
}