import React from "react";
import styles from './VacLessDay.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import InputTextArea from '../../Components/InputTextArea';
import Calendar from '../../Components/Calendar'
import SiteMap from '../../Components/SiteMap';
import ScrollTop from '../ScrollButton';
import WFTrans from '../WFTrans';
import InputTime from "../../Components/InputTime/InputTime";
import { FileUpload } from "primereact/fileupload";
import Attachments from "../Attachments";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class VacLessDay extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { empCode: '', empName: '', vacType: '', paidVacType: '', fromDate: '', toDate: '', notes: '' },
            deleteErrors: { deleteNote: '' },
            vacTypesList: Util.getVacationsTypes(),
            paidVacTypeList: [],
            isDesktop: window.innerWidth > 1250,
            dataObject: {
                id: 0,
                empCode: localStorage.getItem("useridHR") || '',
                empName: Util.getEmployeeInfo() || '',
                requestDate: new Date(),
                vacType: { id: 7, name: $.strings.vacLessDay.annualVac },
                paidVacType: undefined,
                fromDate: new Date(),
                toDate: new Date(),
                daysNo: '1',
                fromTime: new Date(),
                toTime: Util.getCurrentTimePlusHour(new Date()),
                period: '01:00',
                address: '',
                phone: '',
                notes: '',
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: 0,
                status: 1,

                showAlteEmp: false,
                showAlteEmp2: false
            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(608)) {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = this.updatePredicate();
        let paidVacTypesPromis = this.getPaidVacationsTypes()
        let paidVacTypes = await paidVacTypesPromis
        console.log('paidVacTypes', paidVacTypes);
        if (this.props.match.params.id === undefined)
            this.setState({ isDesktop: _isDesktop, paidVacTypeList: paidVacTypes, loading: false })
        else
            this.setState({ isDesktop: _isDesktop, paidVacTypeList: paidVacTypes })

        if (this.props.match.params.id) {
            let data = await this.showVacationInfo(this.props.match.params.id)
            this.loadData(data)
        }

        setTimeout(() => { this.drpVacType.focusInput.focus() }, 10);
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    handleFromDate = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, fromDate: e.target.value } }, () => this.setToDate())
    }
    setToDate = () => {
        //if (this.state.dataObject.toDate < this.state.dataObject.fromDate) {
        this.setState({ dataObject: { ...this.state.dataObject, toDate: this.state.dataObject.fromDate } }, () => this.calculateDaysNo())
        // }
        // else {
        //     this.calculateDaysNo()
        // }
    }
    handleToDate = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, toDate: e.target.value } }, () => this.setFromDate())
    }
    setFromDate = () => {
        if (this.state.dataObject.toDate < this.state.dataObject.fromDate) {
            this.setState({ dataObject: { ...this.state.dataObject, fromDate: this.state.dataObject.toDate } }, () => this.calculateDaysNo())
        }
        else {
            this.calculateDaysNo()
        }
    }

    calculateDaysNo = () => {
        try {
            let fromDate = this.state.dataObject.fromDate
            let toDate = this.state.dataObject.toDate

            const _fromDate = Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0);
            const _toDate = Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 0, 0, 0);
            let temp = 1000 * 3600 * 24;

            let daysNo = Math.floor((_toDate - _fromDate) / temp);

            daysNo += 1;

            this.setState({ dataObject: { ...this.state.dataObject, daysNo: daysNo } })
        }
        catch (e) {

        }
    }


    handleFromTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, fromTime: e.value } }, () => this.calculateHoursNo())
    }
    setToTime = () => {
        if (this.state.dataObject.toTime <= this.state.dataObject.fromTime) {
            let toTimeTemp = Util.getCurrentTimePlusHour(this.state.dataObject.fromTime)
            this.setState({ dataObject: { ...this.state.dataObject, toTime: toTimeTemp } }, () => this.calculateHoursNo())
        }
        else {
            this.calculateHoursNo()
        }
    }
    handleToTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, toTime: e.value } }, () => this.calculateHoursNo())
    }
    setFromTime = () => {
        if (this.state.dataObject.toTime <= this.state.dataObject.fromTime) {
            let fromTimeTemp = Util.getCurrentTimeMinusHour(this.state.dataObject.toTime)
            this.setState({ dataObject: { ...this.state.dataObject, fromTime: fromTimeTemp } }, () => this.calculateHoursNo())
        }
        else {
            this.calculateHoursNo()
        }
    }

    calculateHoursNo = () => {
        try {
            let fromDate = this.state.dataObject.fromTime;
            let toDate = this.state.dataObject.toTime;

            var hoursNo = ((new Date(1, 1, 1, toDate.getHours(), toDate.getMinutes(), 0) -
                new Date(1, 1, 1, fromDate.getHours(), fromDate.getMinutes(), 0)) / 60000);
            let obj = Util.getNumberByTimeFormat(hoursNo);
            let hours = obj.hours + "";
            if (hours.length === 1)
                hours = "0" + hours;

            let minutes = obj.minutes + "";
            if (minutes.length === 1)
                minutes = "0" + minutes;

            let result = hours + ":" + minutes;


            this.setState({ dataObject: { ...this.state.dataObject, period: result } })
        }
        catch (e) {

        }
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _empCode = this.state.dataObject.empCode
        fieldValidationErrors._empCode = _empCode ? '' : $.strings.requiredFiled;

        let _empName = this.state.dataObject.empName;
        fieldValidationErrors.empName = _empName ? '' : $.strings.requiredFiled;

        let _vacType = this.state.dataObject.vacType;
        fieldValidationErrors.vacType = _vacType ? '' : $.strings.requiredFiled;

        let _paidVacType = true
        if (_vacType && _vacType.id === 8) {
            _paidVacType = this.state.dataObject.paidVacType;
            fieldValidationErrors.paidVacType = _paidVacType ? '' : $.strings.requiredFiled;
        }

        let _fromDate = this.state.dataObject.fromDate
        fieldValidationErrors.fromDate = _fromDate ? '' : $.strings.requiredFiled;

        let _toDate = this.state.dataObject.toDate

        fieldValidationErrors.toDate = _toDate ? '' : $.strings.requiredFiled;

        let _notes = this.state.dataObject.notes;
        fieldValidationErrors.notes = _notes ? '' : $.strings.requiredFiled;

        if (this.state.dataObject.period.includes('-')) {
            Util.showErrorMsg(this.messages, $.strings.vacLessDay.timeError)
            return false;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            empCodeValid: _empCode,
            empNameValid: _empName,
            vacTypeValid: _vacType,
            paidVacTypeValid: _paidVacType,
            fromDateValid: _fromDate,
            toDateValid: _toDate,
            notesValid: _notes

        }, this.validateForm);

        return _empCode && _empName && _vacType && _paidVacType && _fromDate && _toDate && _notes;
    }
    validateForm() {
        let isFormValid = this.state.empCodeValid && this.state.empNameValid && this.state.vacTypeValid
            && this.state.paidVacTypeValid && this.state.fromDateValid && this.state.toDateValid && this.state.notesValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        let siteMapItems = [
            { label: $.strings.links.requests.menu },
            {
                label: <div>
                    {$.strings.links.requests.vacationLessThanOneDay + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];

        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" className={styles.addVacations}>
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={this.state.dataObject.id <= 0 ? this.resetFields : undefined}
                            onSave={this.state.dataObject.id <= 0 ? this.onSave : undefined}
                            onTransDetails={this.state.dataObject.id > 0 ? this.wfTransDetails : undefined}
                            onShowAttachment={this.state.dataObject.id > 0 && this.state.dataObject.file_id > 0 ? this.onShowAttachment : undefined}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid">
                    <div className="p-lg-12 p-sm-12">
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>

                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnPrevVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onPrevious(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNextVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNext(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnFirstVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onFirst(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnLastVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onLast(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnDeleteVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onDelete(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloneVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onClone(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloseVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.btnClose(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />

                <DeleteNoteDialog ref={ref => this.deleteNoteDlg = ref} visible={this.state.dlgDeleteNoteVisible} deleteErrors={this.state.deleteErrors} appendTo={this.props.parent}
                    onYes={this.btnDeleteYes} onNo={this.DeleteCancel} deleteNote='' />
                <div className='p-grid'>
                    <div className="p-lg-6 p-sm-12"></div>
                    <div className="p-lg-6 p-sm-12">

                    </div>
                </div>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtCode = el}
                                type="text"
                                id="id"
                                keyfilter="pnum"
                                value={this.state.dataObject.id || ''}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.vacLessDay.code}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                            <Calendar
                                id="requestDate"
                                innerRef={(el) => this.dtpRequestDate = el}
                                value={this.state.dataObject.requestDate}
                                caption={$.strings.vacLessDay.requestDate}
                                onChange={this.handleUserInput}
                                tooltip={$.strings.vacLessDay.requestDate}
                                readOnlyInput={true}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpCode = el}
                                type="text"
                                id="empCode"
                                keyfilter="pnum"
                                maxLength="4"
                                value={this.state.dataObject.empCode || ''}
                                autofocus={true}
                                autocomplete="off"
                                caption={$.strings.vacLessDay.empCode}
                                isRequired={true}
                                onChange={this.handleUserInput}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-4 p-lg-8 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpName = el}
                                type="text"
                                id="empName"
                                maxLength="70"
                                value={this.state.dataObject.empName || ''}
                                autofocus={true}
                                caption={$.strings.vacLessDay.empName}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                readOnly={true}
                                disabled
                                style={{ width: '100%', color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <Dropdown
                                id="vacType"
                                innerRef={e => this.drpVacType = e}
                                value={this.state.dataObject.vacType || ''}
                                options={this.state.vacTypesList}
                                showClear={true}
                                caption={$.strings.vacLessDay.vacType}
                                onChange={this.handleUserInput}
                                formErrors={this.state.formErrors}
                                optionLabel="name"
                                placeholder={$.strings.vacLessDay.selectVacType}
                                filter={true}
                                filterBy="code,name"
                                filterMatchMode="contains"
                                isRequired={true}
                                tooltip={$.strings.vacLessDay.memoType}
                                tooltipOptions={{ position: 'top' }}
                            //disabled
                            />
                        </div>
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                            {
                                this.state.dataObject.vacType && this.state.dataObject.vacType.id + '' === '8'
                                    ?
                                    <Dropdown
                                        id="paidVacType"
                                        innerRef={e => this.drpPaidVacType = e}
                                        value={this.state.dataObject.paidVacType || ''}
                                        options={this.state.paidVacTypeList}
                                        showClear={true}
                                        caption={$.strings.vacLessDay.paidVacType}
                                        onChange={this.handleUserInput}
                                        formErrors={this.state.formErrors}
                                        optionLabel="name"
                                        placeholder={$.strings.vacLessDay.selectPaidVacType}
                                        filter={true}
                                        filterBy="code,name"
                                        filterMatchMode="contains"
                                        isRequired={true}
                                        tooltip={$.strings.vacLessDay.memoType}
                                        tooltipOptions={{ position: 'top' }}
                                        disabled={this.state.dataObject.id > 0 ? true : false}
                                    /> :
                                    null
                            }
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                ref={(el) => this.dtpFromDate = el}
                                value={this.state.dataObject.fromDate}
                                caption={$.strings.vacLessDay.fromDate}
                                onChange={this.handleFromDate}
                                tooltip={$.strings.vacLessDay.fromDate}
                                readOnlyInput={true}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                        {/* <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                             <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                ref={(el) => this.dtpToDate = el}
                                value={this.state.dataObject.toDate}
                                caption={$.strings.vacLessDay.toDate}
                                onChange={this.handleToDate}
                                tooltip={$.strings.vacLessDay.toDate}
                                readOnlyInput={true}
                                isRequired={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                formErrors={this.state.formErrors}
                            />
                        </div> */}
                        {/* <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtDaysNo = el}
                                type="text"
                                id="daysNo"
                                keyfilter="pnum"
                                value={this.state.dataObject.daysNo || ''}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.vacLessDay.daysNo}
                                readOnly={true}
                                disabled
                                isRequired={true}
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                formErrors={this.state.formErrors}

                            />
                        </div> */}
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <InputTime
                                id="fromTime"
                                ref={e => this.dtpFromTime = e}
                                value={this.state.dataObject.fromTime}
                                caption={$.strings.vacLessDay.fromTime}
                                valueChanged={this.handleFromTime}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <InputTime
                                id="toTime"
                                ref={e => this.dtpFromTime = e}
                                value={this.state.dataObject.toTime}
                                caption={$.strings.vacLessDay.toTime}
                                valueChanged={this.handleToTime}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtPeriod = el}
                                type="text"
                                id="period"
                                keyfilter="pnum"
                                value={this.state.dataObject.period || ''}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.vacLessDay.period}
                                readOnly={true}
                                disabled
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                            <InputTextArea
                                innerRef={ref => this.txtNotes = ref}
                                type="text"
                                id="notes"
                                maxLength="100"
                                value={this.state.dataObject.notes}
                                caption={$.strings.vacLessDay.notes}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                            <div >
                                <label for="file-upload" className={styles.customFileUpload}>
                                    {$.strings.vacLessDay.uploadFile}
                                </label>
                                <FileUpload chooseLabel={$.strings.vacations.uploadFile} name="file-upload-prime" url=""
                                    onSelect={this.handleChangePrime} auto maxFileSize={5000000} disabled={this.state.dataObject.id > 0}
                                    invalidFileSizeMessageSummary={$.strings.fileSizeError}
                                    invalidFileSizeMessageDetail={""} />
                            </div>
                        </div>
                    </div>
                </Card>
                <ScrollTop />
                <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
                <Attachments ref={e => this.dlgAttachments = e}></Attachments>
            </div >
        );
    }

    getPaidVacationsTypes = async () => {
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }

    showVacationInfo = async (id) => {
        let dataTemp = []
        let obj = {
            id: id
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetHolidayReq, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    loadData = (data) => {
        let vacType = this.state.vacTypesList.filter(element => element.id + "" === data.transType + "")
        if (vacType && vacType.length > 0)
            vacType = vacType[0]

        let paidVacType = this.state.paidVacTypeList.filter(element => element.pos + "" === data.leaveType + "")
        if (paidVacType && paidVacType.length > 0)
            paidVacType = paidVacType[0]

        let requestDate = new Date(data.insDate)
        let fromDate = new Date(data.fromDate);
        let toDate = new Date(data.toDate);

        this.setState({
            mode: 'view',
            isDesktop: this.updatePredicate(),
            dataObject: {
                ...this.state.dataObject,
                id: data.serial,
                empCode: data.empNo,
                empName: data.empName,
                requestDate: requestDate,
                vacType: vacType,
                paidVacType: paidVacType,
                fromDate: fromDate,
                toDate: toDate,
                daysNo: data.dayCaption,
                address: data.address,
                phone: data.phone,
                notes: data.notes,

                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: data.AttachValue,

                showAlteEmp: data.alternativeEmp ? true : false,
                showAlteEmp2: data.alternativeEmp2 ? true : false

            }
            , loading: false
        })
    }

    handleChange = (event) => {
        if (event.target.files.length > 0) {
            let _uploadedFile = URL.createObjectURL(event.target.files[0])
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.target.files[0].size > 4 * 1024 * 1024) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                document.getElementById('file-upload').value = ''
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.target.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }

    handleChangePrime = (event) => {
        if (event.files.length > 0) {
            let _uploadedFile = event.files[0];
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.files[0].size > 5 * 1000 * 1000) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }

    setSubscriberImgSrc = () => {
        alert($.strings.fileUploaded)
    }

    wfTransDetails = async () => {
        let id = Util.encryptStringToHexa(this.state.dataObject.id);
        await this.wfTransDlg.show(1, id)
    }

    onShowAttachment = async () => {
        await this.dlgAttachments.show(1, this.state.dataObject.id)
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true })
        let files = [] // ملفات المرفقات
        let file = this.state.dataObject.uploadedFile2
        
        let obj = {
            serial: !this.state.dataObject.id ? 0 : this.state.dataObject.id,
            empcode: this.state.dataObject.empCode,
            transType: this.state.dataObject.vacType.id,
            transTypeText: this.state.dataObject.vacType.name,
            leaveType: this.state.dataObject.paidVacType ? this.state.dataObject.paidVacType.pos : 0,
            notes: this.state.dataObject.notes,
            alternativeEmp: this.state.dataObject.altEmp,
            alternativeEmp2: this.state.dataObject.altEmp2,
            address: this.state.dataObject.address,
            phone: this.state.dataObject.phone,
            empNo: this.state.dataObject.empCode,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            fromTime: this.state.dataObject.fromTime,
            toTime: this.state.dataObject.toTime,
            userCode: localStorage.getItem("useridHR"),
            checkVacationRequestPeriod: true
        }

        let res = await http.postMultipartAxios(http.actions.HolidaysReq.urlPostSaveVacLessDay, obj, file, files, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
                return true
            }
        }
        if (res.data.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return false
        }
    }

    resetFields = () => {
        this.setState({
            dataObject: {
                ...this.state.dataObject,
                id: 0,
                requestDate: new Date(),
                vacType: { id: 7, name: $.strings.vacLessDay.annualVac },
                paidVacType: undefined,
                fromDate: new Date(),
                toDate: new Date(),
                fromTime: new Date(),
                toTime: Util.getCurrentTimePlusHour(new Date()),
                daysNo: '1',
                address: '',
                phone: '',
                notes: '',
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: 0,
                status: 1,

                showAlteEmp: false,
                showAlteEmp2: false
            },
            formErrors: { empCode: '', empName: '', vacType: '', paidVacType: '', fromDate: '', toDate: '', notes: '' },
        })
    }
}