import React from "react";
import Util from '../../Util';
import http from '../../Api/http';
import Card from '../../Components/Card';
import userLogo from '../../user.png';
import ProgressSpinner from "../../Components/ProgressSpinner";
import Input from "../../Components/Input";
import SiteMap from "../../Components/SiteMap";

const $ = window.$

export default class EmployeeInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isDesktop: true,
            empCode: '',
            empName: '',
            idNo: '',
            address: '',
            phone: '',
            mobile: '',
            email: '',
            jobName: '',
            appDate: '',
            degree: '',
            degreeStep: '',
            branch: '',
            section: '',
            department: '',
            empImage: undefined
        };
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();
        if (Util.checkISHR()) {
            if (!this.props.match.params.id) {
                Util.goTo('/Access');
                return;
            }
        }
        let employeeListPromise = undefined;
        if (this.props.match.params.id) {
            employeeListPromise = this.getEmployeeInfo(this.props.match.params.id)
        }
        else {
            employeeListPromise = this.getEmployeeInfo()
        }
        let employeeInfo = await employeeListPromise;
        
        this.setState({
            empCode: employeeInfo.Code, empName: employeeInfo.Name, idNo: employeeInfo.IDNO, jobName: employeeInfo.JobName, department: employeeInfo.DepName,
            branch: employeeInfo.BranchName, section: employeeInfo.SectionName, phone: employeeInfo.Phone, address: employeeInfo.Address, mobile: employeeInfo.Mobile,
            degree: employeeInfo.GradeName, degreeStep: employeeInfo.gradeStep, appDate: employeeInfo.D_App, email: employeeInfo.Email, empImage: employeeInfo.img,
            managerName: employeeInfo.managerName, isDesktop: _isDesktop, loading: false
        });
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    render() {
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../../MyStaff' },
            { label: $.strings.employeeInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div ref="divMain" >
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <Card title={$.strings.employeeInfo.personal} showFooter={false} >
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <br />
                            <img src={this.setEmployeeImage()} alt="user" style={{ width: '200px', height: '200px', lineHeight: '100px', textAlign: 'center', borderRadius: '50%' }} />
                        </div>
                        <div className="p-col-12 p-xl-9 p-lg-9 p-md-12 p-sm-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.empCode}
                                        caption={$.strings.employeeInfo.empCode}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.empName}
                                        caption={$.strings.employeeInfo.empName}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.idNo}
                                        caption={$.strings.employeeInfo.idNo}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.managerName}
                                        caption={$.strings.employeeInfo.managerName}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.phone}
                                        caption={$.strings.employeeInfo.phone}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.mobile}
                                        caption={$.strings.employeeInfo.mobile}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.email}
                                        caption={$.strings.employeeInfo.email}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.address}
                                        caption={$.strings.employeeInfo.address}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card title={$.strings.employeeInfo.jobInfo} showFooter={false} >
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                        </div>
                        <div className="p-col-12 p-xl-9 p-lg-9 p-md-12 p-sm-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.jobName}
                                        caption={$.strings.employeeInfo.jobName}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.appDate}
                                        caption={$.strings.employeeInfo.appDate}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.degree}
                                        caption={$.strings.employeeInfo.degree}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.degreeStep}
                                        caption={$.strings.employeeInfo.degreeStep}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.branch}
                                        caption={$.strings.employeeInfo.branch}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.section}
                                        caption={$.strings.employeeInfo.section}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        value={this.state.department}
                                        caption={$.strings.employeeInfo.department}
                                        disable={true}
                                        readOnly={true}
                                    ></Input>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }

    getEmployeeInfo = async (id) => {
        let dataTemp = []
        let obj = {
            empCode: id && id.length > 0 ? id : Util.encryptStringToHexa(localStorage.getItem("useridHR") + ""),
            isHr: Util.checkISHR(),
            userId: localStorage.getItem('useridHR')
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeInfo, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployeeInfoObj;
        }

        return dataTemp
    }


    setEmployeeImage = () => {
        if (this.state.empImage) {
            return `data:image/jpeg;base64,${this.state.empImage}`
        }
        else {
            return userLogo;
        }
    }
}