import React from "react";
import styles from "./Password.module.scss";
import { Password as PrimePassword } from "primereact/password";
import Label from "../../Components/Label";
import Button from "../../Components/Button";
import { FaSearch, FaSearchPlus, FaAutoprefixer, FaSitemap, FaTimesCircle, FaCheckDouble, FaPrint } from 'react-icons/fa'

export default class Password extends React.Component {

    render() {

        return (
            <div>
                {this.props.caption && <Label htmlFor={this.props.id} isRequired={this.props.isRequired} autoComplete="off" style={{ width: '100%' }}>
                    {this.props.caption}
                </Label>
                }
                <PrimePassword ref={this.props.innerRef} autocomplete="off" tooltipOptions={{ position: 'bottom' }}
                    {...this.props} onFocus={this.props.onFocus ? this.props.onFocus : this.onFocus} style={{width:'100%'}}
                >
                    {this.props.children}
                </PrimePassword>

                {
                    this.props.formErrors && this.props.formErrors[this.props.id] &&
                    <div className={styles.errorField} >{this.props.formErrors[this.props.id]}</div>
                }
            </div>
        );
    }

    onFocus = event => {
        event.target.select();
    };

}
