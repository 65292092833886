import React from "react";
import styles from "./Dropdown.module.scss";
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import Label from "../../Components/Label";
import Util from '../../Util';
import Button from "../../Components/Button";
import { FaSearch, FaSearchPlus, FaAutoprefixer, FaSitemap, FaTimesCircle, FaCheckDouble, FaPrint, FaLevelDownAlt, FaPlus, FaUser } from 'react-icons/fa'

const $ = window.$;
const root = document.documentElement;
export default class Dropdown extends React.Component {

    constructor(props) {
        super(props);
        document.documentElement.style.setProperty('--dir', $.strings.dir);
        this.state = {
            floatLabel: false
        };
    };


    handleSassVariable = e => document.documentElement.style.setProperty("--dir", $.strings.dir);

    componentDidMount() {
        document.addEventListener('lang', this.handleSassVariable)
    }
    componentWillUnmount() {
        document.removeEventListener('lang', this.handleSassVariable)
    }
    render() {

        return (
            <div >
                {
                    this.props.caption &&
                    <Label htmlFor={this.props.htmlFor} isRequired={this.props.isRequired}
                        style={{ width: '100%' }}>
                        {this.props.caption}
                    </Label>
                }

                {this.props.withgroup && (
                    <div className="p-inputgroup">
                        <PrimeDropdown id="float-input" ref={this.props.innerRef} title={this.props.tooltip}
                            className={`${styles.dropDown} ${this.props.innerClass || ''} `}
                            {...this.props} tooltipOptions={{ position: 'top' }} placeholder={this.props.placeholder || $.strings.salesMan.select}
                            optionLabelLang2={this.props.optionLabelLang2 ? this.props.optionLabelLang2 : this.props.optionLabel}
                            itemTemplate={this.optionsTemplate} valueTemplate={this.selectedOptionTemplate} resetFilterOnHide={true}>
                            {this.props.children}
                        </PrimeDropdown>
                        <span
                            className="p-inputgroup-addon"
                            style={{ verticalAlign: "bottom" }}
                        >
                            <Button onClick={this.props.btn1event} tooltip={this.props.btn1tooltip} >
                                {
                                    !this.props.btn1icon || this.props.btn1icon === 'search' ? <FaSearch /> : null
                                }
                                {
                                    this.props.btn1icon === 'searchPlus' ? <FaSearchPlus /> : null
                                }
                                {
                                    this.props.btn1icon === "codePrefix" ? <FaAutoprefixer /> : null
                                }
                                {
                                    this.props.btn1icon === "accountTree" ? <FaSitemap /> : null
                                }
                                {
                                    this.props.btn1icon === "clear" ? <FaTimesCircle /> : null
                                }
                                {
                                    this.props.btn1icon === "check" ? <FaCheckDouble /> : null
                                }
                                {
                                    this.props.btn1icon === "print" ? <FaPrint /> : null
                                }
                                {
                                    this.props.btn1icon === "enter" ? <FaLevelDownAlt className={styles.rotateEnterIcon} /> : null
                                }
                                {
                                    this.props.btn1icon === "plus" ? <FaPlus /> : null
                                }
                                {
                                    this.props.btn1icon === "user" ? <FaUser /> : null
                                }
                            </Button>
                        </span>

                    </div>
                )}

                {
                    !this.props.withgroup &&
                    <PrimeDropdown id="float-input" ref={this.props.innerRef} title={this.props.tooltip}
                        className={`${styles.dropDown} ${this.props.innerClass || ''} `}
                        {...this.props} tooltipOptions={{ position: 'top' }} placeholder={this.props.placeholder || $.strings.select}
                        itemTemplate={this.optionsTemplate} valueTemplate={this.selectedOptionTemplate} resetFilterOnHide={true}>
                        {this.props.children}
                    </PrimeDropdown>
                }
                {
                    this.props.formErrors && this.props.formErrors[this.props.id] &&
                    <div className={styles.errorField} >{this.props.formErrors[this.props.id]}</div>
                }
            </div >
        );
    }
    optionsTemplate = (option) => {
        let statusColor = option.status && option.status !== 1 ? 'red' : ''
        return (
            <div style={{ color: statusColor }} dir={$.strings.dir}>
                {
                    option.code && option.code.length > 0 ? option.code + " / " : ''
                }
                {
                    this.props.optionLabelCode && this.props.optionLabelCode.length > 0 ? option[this.props.optionLabelCode] + ' / ' : ''
                }
                {
                    option[this.props.optionLabel]
                }
            </div>
        );
    }
    selectedOptionTemplate = (option, props) => {
        if (option) {
            return (
                <div>
                    <div>
                        {
                            option.code && option.code.length > 0 ? option.code + " / " : ''
                        }
                        {
                            this.props.optionLabelCode && this.props.optionLabelCode.length > 0 ? option[this.props.optionLabelCode] + ' / ' : ''
                        }
                        {
                            option[this.props.optionLabel]
                        }
                    </div>
                </div>
            );
        }
        else {
            return (
                <span>
                    {props.placeholder}
                </span>
            );
        }
    }

    onFocus = () => {
        this.setState({ floatLabel: true });
    }

    onBlur = () => {
        this.setState({ floatLabel: false });
        //this.props.isRequired && !this.props.value ? this.setState({ showValidationField: true }) : this.setState({ showValidationField: false });
    }

    setFloatLabelStyle = () => {
        if (this.props.value && this.state.floatLabel)
            return styles.floatLabelWithColorStyle;
        else if (this.props.value)
            return styles.floatLabelStyle;
        else if (this.state.floatLabel)
            return styles.floatLabelWithColorStyle;
        else return styles.label;
    }
}