import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import ScrollTop from '../ScrollButton';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import WFTrans from '../WFTrans';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Messages from "../../Components/Messages";
import Checkbox from "../../Components/Checkbox";
import Print from "../../Print";

const $ = window.$;

export default class RequestsRpt extends React.Component {
    constructor(props) {
        super(props);
        this.showFromTimeToTimeColumns = "0";
        this.showSubstituteColumns = "0";
        this.state = {
            isDesktop: window.innerWidth > 1250,
            transTypeList: [],
            statusList: [],
            fromCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            toCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            transType: undefined,
            fromDate: new Date(),
            toDate: new Date(),
            status: undefined,
            showDepartmentColumn: false,
            noOutOnly: false,
            noInOnly: false,
            lateOnly: false,
            outBeforePeriodEnd: false,
            editedTransOnly: false,
            filterShown: true,
            retroactively: false,
            data: [],

        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(713)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(655)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();

        let transTypeListPromise = this.getTransTypes();
        let shiftsPromise = this.getShifts();
        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();
        let statusListPromise = this.getStatus();

        let transTypesList = await transTypeListPromise;
        let statusList = await statusListPromise;
        let shiftsList = await shiftsPromise;
        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        this.showFromTimeToTimeColumns = await Util.GetSettingById(217);
        this.showSubstituteColumns = await Util.GetSettingById(218);

        this.setState({
            transTypesList: transTypesList, transType: transTypesList && transTypesList.length > 0 ? transTypesList[0] : undefined,
            statusList: statusList, status: statusList && statusList.length > 0 ? statusList[0] : undefined,
            shiftsList: shiftsList, branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            shift: shiftsList && shiftsList.length > 0 ? shiftsList[0] : undefined,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.requestsRpt.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.requestsRpt.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.requestsRpt.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.requestsRpt.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.requestsRpt.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-6">
                            <Dropdown
                                id="transType"
                                innerRef={e => this.drpTransType = e}
                                value={this.state.transType}
                                options={this.state.transTypesList}
                                caption={$.strings.requestsRpt.transType}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                filter
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Dropdown
                                id="status"
                                value={this.state.status}
                                options={this.state.statusList}
                                caption={$.strings.requestsRpt.status}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                            />
                        </div>
                        {
                            Util.checkISHR() &&
                            <div className="p-col-12 p-lg-2 p-sm-6">
                                <Dropdown
                                    id="section"
                                    value={this.state.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.requestsRpt.section}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                        }
                        {
                            Util.checkISHR() &&
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="branch"
                                    value={this.state.branch}
                                    options={this.state.branchesList}
                                    caption={$.strings.requestsRpt.branch}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                        }
                        {
                            Util.checkISHR() &&
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="department"
                                    value={this.state.department}
                                    options={this.state.departmentsList}
                                    caption={$.strings.requestsRpt.department}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                        }
                        {
                            Util.checkISHR() &&
                            <div className="p-col-12 p-lg-2 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                                <Checkbox id="checkRetroactively" caption={$.strings.requestsRpt.retroactively}
                                    onChange={e => this.setState({ retroactively: e.checked })} checked={this.state.retroactively} />
                            </div>
                        }
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <DataGrid
                    style={gridStyle}
                    ref={e => this.gridData = e}
                    isReport={true}
                    theme="default-light"
                    idProperty="RowNumber"
                    scheme={this.getScheme()}
                    exportFileName={$.strings.requestsRpt.title}
                    dataSource={this.state.data}
                />
            </Card>
            <ScrollTop />
        </div >
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }

    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }
    getTransTypes = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 22
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp)
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
        }
        return dataTemp;
    }
    getStatus = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            withAll: true
        }
        let res = await http.getJsonAxios(http.actions.RequestsStatus.urlGetRequestsStatus, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            isHr: Util.checkISHR(),
            fromNo: this.state.fromCode && this.state.fromCode.length > 0 ? this.state.fromCode : '00000',
            toNo: this.state.toCode && this.state.toCode.length > 0 ? this.state.toCode : 'ZZZZZ',
            empCode: localStorage.getItem("useridHR") + "",
            userId: localStorage.getItem("useridHR") + "",
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            type: this.state.transType ? this.state.transType.pos : 0,
            selectedDepartment: this.state.department && this.state.department.pos >= 0 ? this.state.department.pos : "-1",
            selectedBranchValue: this.state.branch && this.state.branch.Pos >= 0 ? this.state.branch.Pos : "-1",
            selectedStatus: this.state.status && this.state.status.ID >= 0 ? this.state.status.ID : "-1",
            selectedSection: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            retroactivelyOnly: this.state.retroactively
        }

        let res = await http.getJsonAxios(http.actions.RequestsReport.urlGetRequestsReport, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }

        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.requestsRpt.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";

            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.requestsRpt.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.requestsRpt.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.requestsRpt.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.requestsRpt.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter);

            if (this.printFilters.transType) {
                let name = this.printFilters.transType.name
                listOfFilters.push($.strings.requestsRpt.transType + ": " + name);
            }

            if (this.printFilters.status) {
                let name = this.printFilters.status.Name
                listOfFilters.push($.strings.requestsRpt.status + ": " + name);
            }

            if (Util.checkISHR()) {
                if (this.printFilters.section) {
                    listOfFilters.push($.strings.requestsRpt.section + ": " + this.printFilters.section.Name);
                }
                if (this.printFilters.branch) {
                    listOfFilters.push($.strings.requestsRpt.branch + ": " + this.printFilters.branch.Name);
                }
                if (this.printFilters.department) {
                    listOfFilters.push($.strings.requestsRpt.department + ": " + this.printFilters.department.name);
                }
                if (this.printFilters.retroactively) {
                    listOfFilters.push($.strings.requestsRpt.retroactively + ": " + this.printFilters.retroactively ? $.strings.yes : $.strings.no);
                }
            }
        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    viewRequest = (data) => {
        let id = Util.encryptStringToHexa(data.No);
        // طلبات الاجازات والمغادرات
        if (data.Type + "" === "6" || data.Type + "" === "7" || data.Type + "" === "8" || data.Type + "" === "9") {
            // اجازات
            if (data.PerType + "" === "0") {
                Util.goTo("../Requests/VacLessDay/" + id)
            }
            else {
                Util.goTo("../Requests/Vacations/" + id)
            }
        }
        else if (data.Type + "" === "5") {
            // عمل اضافي
            Util.goTo("../Requests/OverTime/" + id)
        }
        else if (data.Type + "" === "12") {
            //  مغادرة خاصة مع خصم
            Util.goTo("../Requests/SpecialLeave/" + id)
        }
        else if (data.Type + "" === "2") {
            // مغادرة رسمية بدون خصم 
            Util.goTo("../Requests/WorkLeave/" + id)
        }
    }
    btnWFTransDetails = async (e, ctx) => {
        if (!ctx.item.No || ctx.item.No.length <= 0)
            return;
        let id = Util.encryptStringToHexa(ctx.item.No)
        await this.wfTransDlg.show('1', id)
    }
    btnViewRequest = (e, ctx) => {
        if (!ctx.item.No || ctx.item.No.length <= 0)
            return;
        this.viewRequest(ctx.item);
    }
    getScheme = () => {
        return {
            name: "requestsRptScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 5,
            columns: [
                { name: 'RowNumber', header: '#', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                {
                    name: 'btnWFTransDetails', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.transDetails,
                    iconType: 'card', className: 'warning', onClick: (e, ctx) => this.btnWFTransDetails(e, ctx)
                },
                {
                    name: 'btnViewRequest', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.view,
                    iconType: 'view', className: 'info', onClick: (e, ctx) => this.btnViewRequest(e, ctx)
                },
                { name: 'OrderDate', header: $.strings.requestsRpt.requestDate, width: 120, visible: true, columnWidth: '2' },
                { name: 'No', header: $.strings.requestsRpt.code, width: 100, visible: false, columnWidth: '2' },
                { name: 'EmpCode', header: $.strings.requestsRpt.empCode, width: 100, visible: false, columnWidth: '2' },
                { name: 'EmpName', header: $.strings.requestsRpt.empName, width: 290, columnWidth: '*' },
                { name: 'OrderType', header: $.strings.requestsRpt.requestType, width: 150, columnWidth: '*' },
                { name: 'SubType', header: $.strings.requestsRpt.subType, width: 150, columnWidth: '*' },
                { name: 'DayCount', header: $.strings.requestsRpt.days, width: 100, columnWidth: '2' },
                { name: 'FromDate', header: $.strings.requestsRpt.startDate, width: 120, columnWidth: '3' },
                { name: 'BeginDay', header: $.strings.requestsRpt.fromDay, width: 110, columnWidth: '2' },
                { name: 'ToDate', header: $.strings.requestsRpt.endDate, width: 120, columnWidth: '2' },
                { name: 'EndDay', header: $.strings.requestsRpt.toDay, width: 110, columnWidth: '2' },
                {
                    name: 'FromTime', header: $.strings.requestsRpt.fromTime, width: 110, visible: this.showFromTimeToTimeColumns === "1", columnWidth: '2'
                },
                {
                    name: 'ToTime', header: $.strings.requestsRpt.toTime, width: 110, visible: this.showFromTimeToTimeColumns === "1", columnWidth: '2'
                },
                {
                    name: 'BeginOfVacation', header: $.strings.requestsRpt.actualFrom, width: 120, columnWidth: '3'
                },
                {
                    name: 'EndOfVacation', header: $.strings.requestsRpt.actualTo, width: 120, columnWidth: '3'
                },
                {
                    name: 'Note', header: $.strings.requestsRpt.notes, width: 120, columnWidth: '3'
                },
                {
                    name: 'AdminNote', header: $.strings.requestsRpt.managerNotes, width: 130, visible: false, columnWidth: '3'
                },
                {
                    name: 'OrderStatus', header: $.strings.requestsRpt.status, width: 100, columnWidth: '2'
                },
                {
                    name: 'HrNote', header: $.strings.requestsRpt.hrNotes, width: 120, visible: false, columnWidth: '3'
                },
                {
                    name: 'FirstAlternative', header: $.strings.requestsRpt.firstSubstitue, width: 120, visible: this.showSubstituteColumns === "1", columnWidth: '3'
                },
                {
                    name: 'SecondAlternative', header: $.strings.requestsRpt.secondSubstitue, width: 120, visible: this.showSubstituteColumns === "1", columnWidth: '3'
                }
            ]
        }
    }
}