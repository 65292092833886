import React, { Component } from 'react';

const $ = window.$;
var pjson = require('../package.json');

export class AppFooter extends Component {

    render() {
        return <div className="layout-footer" style={{ height: 'auto' }}>
            <div className="p-grid" >
                <div className="p-col-7">
                    {/* <img src="../../assets/layout/images/logo-roma-white.gif" alt="HR Self Service" style={{ width: '40px', height: '35px' }} /> */}
                    <img src="../../assets/layout/images/logo-roma-white.png" alt="HR Self Service" style={{ width: '35px', height: '35px' }} />
                    <p>{$.strings.ERP}</p>
                    <span className="version-no" style={{color:'black'}}>
                        {pjson.version}
                    </span>
                </div>
                <div className="p-col-5 footer-icons">
                    <button className="p-link" onClick={() => window.location = "/"} title={$.strings.links.home}>
                        <i className="pi pi-home"></i>
                    </button>
                    <button className="p-link" onClick={() => window.open("http://www.iscosoft.com", "_balank")} title={$.strings.iscosoftWebPage}>
                        <i className="pi pi-globe"></i>
                    </button>
                    {/* <button className="p-link">
                        <i className="pi pi-envelope"></i>
                    </button> */}
                </div>
            </div>
        </div>
    }
}