import React from "react";
import Button from '../../Components/Button'
import http from "../../Api/http";
import Dialog from '../../Components/Dialog';
import DataTable from '../../Components/Datatable';
import Util from "../../Util";

const $ = window.$;

export default class SalaryTrans extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            data: [],
            type: this.props.type
        }
        this.scheme = this.getScheme();
        this.schemeRewards = this.getSchemeRewards();
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();
        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <Dialog header={$.strings.salaryTrans.title} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                style={{ width: this.state.isDesktop ? '60vw' : '80vw', direction: $.strings.dir }}
                contentStyle={{ minHeight: '350px' }}
                modal onHide={this.onHide}>
                <div ref="divMain" >
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            {
                                (!this.state.type || this.state.type !== "Rewards") &&
                                <DataTable
                                    isReport={false}
                                    ref={ref => (this.tblData = ref)}
                                    scrollHeight={this.state.isDesktop ? "30vh" : '55vh'}
                                    scheme={this.getScheme()}
                                    value={this.state.data}
                                    fullData={this.state.dataObject}
                                    loading={this.state.loading}
                                    showContextMenu={false}
                                    responsive={true}
                                />
                            }
                            {
                                this.state.type && this.state.type === "Rewards" &&
                                <DataTable
                                    isReport={false}
                                    ref={ref => (this.tblData = ref)}
                                    scrollHeight={this.state.isDesktop ? "30vh" : '55vh'}
                                    scheme={this.getSchemeRewards()}
                                    value={this.state.data}
                                    fullData={this.state.dataObject}
                                    loading={this.state.loading}
                                    showContextMenu={false}
                                    responsive={true}
                                />
                            }
                        </div>
                    </div>
                </div >
            </Dialog >
        );
    }
    show = async (empCode, type, month, year) => {
        this.setState({
            visibleDialog: true, data: [],
            scheme: type === "Rewards" ? this.getSchemeRewards() : this.getScheme()
        }, () => { this.getData(empCode, type, month, year) })
    }

    getData = async (empCode, type, month, year) => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: empCode,
            type: type,
            month: month,
            year: year
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetSalaryTrans, obj, 0);
        if (res.status === 200) {
            if (type === "Rewards") {
                dataTemp = res.data.SalaryRewardList;
            }
            else {
                dataTemp = res.data.SalaryTransList;
            }
        }
        this.setState({ data: dataTemp, loading: false })
    }

    onHide = () => {
        this.setState({ visibleDialog: false })
    }

    mainTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData[column.field]}</span>
        </>
    }

    getScheme = () => {
        return {
            name: "salaryTransScheme_Table",
            filter: false,
            sigma: false,
            columns: [
                {
                    position: 0,
                    field: "RowNumber",
                    header: "##",
                    filterable: false,
                    width: "45px",
                    sortable: false,
                    body: this.mainTemplate,
                    sigma: {
                        count: true
                    }
                },
                {
                    position: 0,
                    field: "typeName",
                    header: $.strings.salaryTrans.typeName,
                    width: "160px",
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "FromDate",
                    header: $.strings.salaryTrans.fromDate,
                    width: "120px",
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "ToDate",
                    width: '120px',
                    header: $.strings.salaryTrans.toDate,
                    body: this.mainTemplate
                },
                {
                    position: 1,
                    field: 'FromTime',
                    header: $.strings.salaryTrans.fromTime,
                    width: '90px',
                    body: this.mainTemplate,
                    hidden: !Util.hideBlankColumn(this.state.data, "FromTime")
                },
                {
                    position: 2,
                    field: "ToTime",
                    header: $.strings.salaryTrans.toTime,
                    width: "90px",
                    body: this.mainTemplate,
                    hidden: !Util.hideBlankColumn(this.state.data, "ToTime")
                },
                {
                    position: 3,
                    field: "TotalTimeOrDays",
                    header: $.strings.salaryTrans.totalTimeOrDays,
                    width: "130px",
                    body: this.mainRequestTemplate,
                    sigma: {
                        sum: true
                    },
                    dataType: this.state.data.filter(e => e.TotalTimeOrDays && e.TotalTimeOrDays.includes(':')).length > 0 ? 'Time' : undefined
                },
                {
                    position: 4,
                    field: "Note",
                    header: $.strings.salaryTrans.notes,
                    width: '190px',
                    body: this.mainTemplate
                }
            ]
        };
    };
    getSchemeRewards = () => {
        return {
            name: "rewardsTransScheme_Table",
            filter: false,
            sigma: false,
            columns: [
                {
                    position: 0,
                    field: "RowNumber",
                    header: "##",
                    filterable: false,
                    width: "45px",
                    sortable: false,
                    body: this.mainTemplate,
                    sigma: {
                        count: true
                    }
                },
                {
                    position: 0,
                    field: "date",
                    header: $.strings.salaryTrans.date,
                    width: "90px",
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "Amount",
                    width: '200px',
                    header: $.strings.salaryTrans.amount,
                    body: this.mainTemplate
                },
                {
                    position: 4,
                    field: "Note",
                    header: $.strings.salaryTrans.notes,
                    width: '200px',
                    body: this.mainTemplate
                }
            ]
        };
    };
}