import React from "react";
import Button from '../../Components/Button'
import http from "../../Api/http";
import Dialog from '../../Components/Dialog';
import DataTable from '../../Components/Datatable';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class WFTrans extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            data: [],
            dataObject: {

            }
        }
        this.scheme = this.getScheme()
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <Dialog header={$.strings.wfTrans.title} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                style={{ width: this.state.isDesktop ? '60vw' : '80vw', direction: $.strings.dir }}
                modal onHide={this.onHide}>
                <div ref="divMain" >
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataTable
                                isReport={false}
                                ref={ref => (this.tblData = ref)}
                                scrollHeight="45vh"
                                style={{ direction: $.strings.dir }}
                                scheme={this.scheme}
                                value={this.state.data}
                                fullData={this.state.dataObject}
                                loading={this.state.loading}
                                showContextMenu={false}
                                responsive={true}
                            />
                        </div>
                    </div>
                </div >
            </Dialog >
        );
    }
    show = async (typeID, transID) => {
        this.setState({ visibleDialog: true, data: [] }, () => { this.getData(typeID, transID) })
    }

    getData = async (typeID, transID) => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            transID: transID,
            transType: typeID,
            statusId: -1
        }
        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetWFTrans, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.wfTransList;
        }
        this.setState({ data: dataTemp, loading: false })
    }

    onHide = () => {
        this.setState({ visibleDialog: false })
    }

    mainTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData[column.field]}</span>
        </>
    }

    getScheme = () => {
        return {
            name: "wfTransScheme_Table",
            filter: false,
            sigma: false,
            columns: [
                {
                    position: 0,
                    field: "rowNum",
                    header: "##",
                    filterable: false,
                    width: "45px",
                    sortable: false,
                    body: this.mainTemplate,
                },
                {
                    position: 0,
                    field: "step",
                    header: $.strings.wfTrans.stepNo,
                    width: "90px",
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "empName",
                    width: '200px',
                    header: $.strings.wfTrans.employee,
                    body: this.mainTemplate
                },
                {
                    position: 1,
                    field: 'status',
                    header: $.strings.status,
                    width: '105px',
                    body: this.mainTemplate
                },
                {
                    position: 2,
                    field: "approveDate",
                    header: $.strings.wfTrans.processDate,
                    width: "130px",
                    body: this.mainTemplate,
                },
                {
                    position: 3,
                    field: "approveTime",
                    header: $.strings.wfTrans.processTime,
                    width: "120px",
                    body: this.mainRequestTemplate,
                },
                {
                    position: 4,
                    field: "notes",
                    header: $.strings.wfTrans.notes,
                    width: '200px',
                    body: this.mainTemplate
                }
            ]
        };
    };
}