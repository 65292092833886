import React from 'react';
import styles from './Users.module.scss';
import Dialog from '../../../Components/Dialog';
import Input from '../../../Components/Input';
import http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import Util from '../../../Util';
import Button from '../../../Components/Button';
import DataGrid from '../../../Components/DataGridView/DataGridView';
import RadioButton from '../../../Components/RadioButton';
import MainTemplate from '../../../Components/SpecialComponents/EditorsAndTemplate/MainTemplate/EditorFunctions';
import AlertBox from '../../../Components/AlertBox/AlertBox';

const $ = window.$;

export default class UsersSearch extends React.Component {
  constructor(props) {
    super(props);
    this.hash = null;
    this.doHotKeys = true;
    this.state = {
      loading: true,
      pageInfo: this.setPageInfo(),
      formErrors: { newPwd: '', confirmPwd: '' },
      isDesktop: window.innerWidth > 800,
      visibleDialog: false,
      globalFilter: '',
      userName: '',
      StatusFilter: $.strings.active,
      data: [],
    };
  }

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 800 });
  };

  componentDidMount = async () => {
    if (!Util.checkIsSuperAdmin() && !Util.checkIsAdmin()) {
      return;
    }
    document.addEventListener('keydown', this.onPageKeyDown);
    document.addEventListener('resize', this.updatePredicate);
    this.show();
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
    document.removeEventListener('resize', this.updatePredicate);
  };

  handleUserInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    this.setState({ [name]: value });
  };
  onPageKeyDown = async (e) => {
    if (!this.doHotKeys) return;
    this.doHotKeys = false;
    switch (e.keyCode) {
      case Util.keyboardKeys.Esc: {
        e.preventDefault();
        this.onHide();
        break;
      }
      default: {
        break;
      }
    }
    this.doHotKeys = true;
  };
  render() {
    const gridStyle = { maxHeight: '30vh' };

    const footer = (
      <div style={{ textAlign: 'center' }}>
        <Button tooltip={$.strings.UsersSearch.accept} icon="" className="p-button-success" onClick={this.onOk}>
          <i className="pi pi-check" />
        </Button>
        <Button tooltip={$.strings.UsersSearch.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
          <i className="pi pi-times" />
        </Button>
      </div>
    );

    const myIcon = <button className="p-dialog-titlebar-icon p-link"></button>;
    let screenHeader = (
      <div className={`${'p-grid'} ${styles.titleBar}`}>
        <div className="p-col-12 p-lg-12 p-md-12" style={{ verticalAlign: 'middle' }}>
          <h2 className={styles.title}>
            {this.state.pageInfo.title + ' '}
            {this.state.mode === 'new' ? <span style={{ color: 'red' }}>{' (' + $.strings.newMode + ')'}</span> : ''}
          </h2>
        </div>
      </div>
    );

    return (
      <div>
        <Dialog
          showHeader={false}
          footer={footer}
          icons={myIcon}
          visible={true}
          closeOnEscape={true}
          style={{
            width: this.state.isDesktop ? '30vw' : '80vw',
            direction: $.strings.dir,
          }}
          blockScroll
          contentStyle={{ height: '500px' }}
          modal
          onHide={this.onHide}
        >
          <div ref="divMain">
            {this.state.alertBoxVisible && <AlertBox ref={(e) => (this.alertBox = e)} header={$.strings.appName} yesCaption={$.strings.ok} />}
            <ProgressSpinner loading={this.state.loading} />
            {screenHeader}
            <div className="p-grid">
              <div className="p-col-8 p-lg-8 p-sm-8">
                <Button icon="pi pi-plus" onClick={this.onAddUser} />
              </div>
              <div className="p-col-2 p-lg-2 p-sm-2">
                <RadioButton
                  inputId="rbActive"
                  value={$.strings.active}
                  name="Status"
                  onChange={(e) => this.setState({ StatusFilter: e.value }, () => this.getData(false, false))}
                  checked={this.state.StatusFilter === $.strings.active}
                ></RadioButton>
              </div>
              <div className="p-col-2 p-lg-2 p-sm-2">
                <RadioButton
                  inputId="rbInactive"
                  value={$.strings.inactive}
                  name="Status"
                  onChange={(e) => this.setState({ StatusFilter: e.value }, () => this.getData(false, false))}
                  checked={this.state.StatusFilter === $.strings.inactive}
                ></RadioButton>
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                <DataGrid
                  style={gridStyle}
                  ref={(e) => (this.tblData = e)}
                  exportFileName={this.state.pageInfo.title}
                  isReport={true}
                  showContextMenu={false}
                  theme="default-light"
                  idProperty="ser"
                  scheme={this.getScheme()}
                  defaultRowHeight={50}
                  dataSource={this.state.data}
                  onRowDoubleClick={(e) => this.onRowDoubleClick(e)}
                  pageId={this.state.pageInfo.pageId}
                  onKeyDown={this.onKeyDown}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
  onKeyDown = (s, e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onOk();
    }
  };

  show = async (userId) => {
    let data = await this.getData(true);
    this.setState({ visibleDialog: true, data: data, loading: false });
  };

  onOk = () => {
    this.onRowDoubleClick();
  };
  onHide = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({ visibleDialog: false, data: [], globalFilter: '' });
  };

  onAddUser = () => {
    Util.goToNewTab('../../../AccessRights/UsersAdd/');
  };

  getData = async (fromShowDialog) => {
    if (!fromShowDialog) {
      this.setState({ loading: true });
    }
    let dataTemp = [];
    const params = {
      userId :-1,
      status: Util.getStatusId(this.state.StatusFilter)
    };
    let res = await http.getJsonAxios(http.actions.Users.urlGetUsers, params, this.state.pageInfo.pageId);

    if (res.status === 200) {
      dataTemp = res.data.list;
    }
    if (!fromShowDialog) {
      this.setState({ data: dataTemp, loading: false });
    } else {
      return dataTemp;
    }
  };

  popupHasCalled = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
  };
  popupHasClosed = () => {
    document.addEventListener('keydown', this.onPageKeyDown);
  };

  onRowDoubleClick = (e) => {
    let selectedRowvalue = this.tblData.getCurrentRowItem();
    if (selectedRowvalue) {
      if (this.props.onSearchSuccess) {
        this.props.onSearchSuccess(selectedRowvalue);
        this.setState({ visibleDialog: false, data: [], globalFilter: '' });
      }
    } else {
      this.popupHasCalled();
      this.setState({ alertBoxVisible: true }, () => {
        this.alertBox.show(
          (e) => {
            this.popupHasClosed();
            this.setState({ alertBoxVisible: false });
          },
          undefined,
          undefined,
          $.strings.noRowsSelected
        );
      });
    }
  };

  getScheme = () => {
    return {
      name: 'roleUsersScheme_Table',
      filter: true,
      showFooter: true,
      showFilter: true,
      responsiveColumnIndex: 2,
      columns: [
        {
          name: 'RowNumber',
          header: '#',
          width: 70,
          dataType: 'Number',
          aggregate: 'Cnt',
          columnWidth: 1,
        },
        {
          name: 'ID',
          header: 'id',
          visible: false,
          visibleInColumnChooser: false,
        },
        {
          name: 'Name',
          header: $.strings.UsersSearch.name,
          visible: true,
          visibleInColumnChooser: true,
          width: '*',
          columnWidth: '*',
        },
        {
          width: 120,
          header: $.strings.UsersSearch.loginName,
          name: 'Code',
          columnWidth: 2,
        },
      ],
    };
  };

  setPageInfo = () => {
    let pageId = 0;
    let title = $.strings.UsersSearch.title;

    return { pageId: pageId, title: title };
  };
}
