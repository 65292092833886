import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollTop from '../ScrollButton';
import { Fieldset } from 'primereact/fieldset';
import WFTrans from "../WFTrans";
import Print from "../../Print";

const $ = window.$;

export default class ExpectedVacationRpt extends React.Component {
    constructor(props) {
        super(props);
        this.userTypeList = [
            { id: -1, name: $.strings.all },
            { id: 0, name: $.strings.expectedVacationsRpt.employee },
            { id: 1, name: $.strings.expectedVacationsRpt.hrEmployee }
        ];
        this.currDate = new Date();
        this.state = {
            isDesktop: window.innerWidth > 1250,
            fromDate: new Date(this.currDate.getFullYear(), this.currDate.getMonth(), 1),
            toDate: new Date(),
            fromDateVac: new Date(this.currDate.getFullYear(), this.currDate.getMonth(), 1),
            toDateVac: new Date(),
            status: undefined,
            empCode: undefined,
            statusList: [],
            data: []
        };
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(940)) {
                Util.goTo('/Access');
                return;
            }
        }
        else if (Util.checkISManager()) {
            if (!Util.checkRights(941)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!Util.checkRights(942)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _statusList = await this.getStatus();
        let _isDesktop = this.updatePredicate();
        this.setState({
            statusList: _statusList, status: _statusList && _statusList.length > 0 ? _statusList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../MyStaff' },
            { label: $.strings.expectedVacationsRpt.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card>
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
            </Card>
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <Accordion multiple activeIndex={[0, 1]}>
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-6">
                            <Fieldset legend={$.strings.expectedVacationsRpt.insDate}>
                                <div className="p-grid">
                                    <div className="p-col-6 p-lg-6 p-sm-6">
                                        <Calendar
                                            id="fromDate"
                                            value={this.state.fromDate}
                                            caption={$.strings.expectedVacationsRpt.fromDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="p-col-6 p-lg-6 p-sm-6">
                                        <Calendar
                                            id="toDate"
                                            value={this.state.toDate}
                                            caption={$.strings.expectedVacationsRpt.toDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-6">
                            <Fieldset legend={$.strings.expectedVacationsRpt.vacDate}>
                                <div className="p-grid">
                                    <div className="p-col-6 p-lg-6 p-sm-6">
                                        <Calendar
                                            id="fromDateVac"
                                            value={this.state.fromDateVac}
                                            caption={$.strings.expectedVacationsRpt.fromDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="p-col-6 p-lg-6 p-sm-6">
                                        <Calendar
                                            id="toDateVac"
                                            value={this.state.toDateVac}
                                            caption={$.strings.expectedVacationsRpt.toDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-6">
                            <Fieldset legend={$.strings.expectedVacationsRpt.status}>
                                <Dropdown
                                    id="status"
                                    value={this.state.status}
                                    options={this.state.statusList}
                                    caption={<br />}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-6">
                            <Fieldset legend={$.strings.expectedVacationsRpt.empCode}>
                                <Input
                                    type="text"
                                    id="empCode"
                                    value={this.state.empCode}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={<br />}
                                    withgroup={Util.checkISHR() || Util.checkISManager()}
                                    btn1icon="search"
                                    btn1event={this.getFromCode}
                                    disabled={!Util.checkISHR() && !Util.checkISManager()}
                                />
                            </Fieldset>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            style={gridStyle}
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.getScheme()}
                            dataSource={this.state.data}
                            exportFileName={$.strings.expectedVacationsRpt.title}
                        />
                    </div>
                </div>
            </Card>
            <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
            <ScrollTop />
        </div >
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ empCode: obj.code })
        }
    }
    getStatus = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            withAll: true
        }
        let res = await http.getJsonAxios(http.actions.RequestsStatus.urlGetRequestsStatus, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            fromDate: this.state.fromDate ? this.state.fromDate : '',
            toDate: this.state.toDate ? this.state.toDate : '',
            vacFromDate: this.state.fromDateVac ? this.state.fromDateVac : '',
            vacToDate: this.state.toDateVac ? this.state.toDateVac : '',
            empCode: this.state.empCode && this.state.empCode.length > 0 ? this.state.empCode : '',
            status: this.state.status && this.state.status.ID > 0 ? this.state.status.ID : "-1",
            isHr: Util.checkISHR(),
            userId: localStorage.getItem("useridHR"),
        }

        let res = await http.getJsonAxios(http.actions.ExpectedVacation.urlGetExpectedVacationsList, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.expectedVacationsRpt.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let vacDateFilter = "", dateFilter = "";

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.expectedVacationsRpt.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.expectedVacationsRpt.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter);

            // Vac Date Filter
            if (this.printFilters.fromDateVac) {
                vacDateFilter = $.strings.expectedVacationsRpt.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDateVac);
            }
            if (this.printFilters.toDateVac) {
                if (vacDateFilter !== "")
                    vacDateFilter += " - ";
                else
                    vacDateFilter = $.strings.expectedVacationsRpt.fromDate + ": ";
                vacDateFilter += Util.getDateFormat(this.printFilters.toDateVac);
            }
            if (vacDateFilter !== "")
                listOfFilters.push(vacDateFilter);

            if (this.printFilters.status) {
                let name = this.printFilters.status.Name
                listOfFilters.push($.strings.expectedVacationsRpt.status + ": " + name);
            }
            if (this.printFilters.empCode) {
                listOfFilters.push($.strings.expectedVacationsRpt.empCode + ": " + this.printFilters.empCode);
            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    onViewClick = (e, ctx) => {
        if (window.confirm($.strings.expectedVacationsRpt.editConfirmation)) {
        }
    }
    onEditClick = (e, ctx) => {
        if (window.confirm($.strings.expectedVacationsRpt.editConfirmation)) {
        }
    }
    onDeleteClick = (e, ctx) => {
        if (window.confirm($.strings.expectedVacationsRpt.editConfirmation)) {
        }
    }
    wfTransDetails = async (item) => {
        let id = Util.encryptStringToHexa(item.ID)
        await this.wfTransDlg.show(8, id)
    }
    viewRequest = (item) => {
        let id = Util.encryptStringToHexa(item.ID)
        Util.goToNewTab("../Requests/ExpectedVacations/" + id)
    }

    getScheme = () => {
        return {
            name: "newsReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 5,
            columns: [
                { name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                {
                    name: 'btnWfTransDetails', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.transDetails,
                    iconType: 'card', className: 'warning', onClick: (e, ctx) => this.wfTransDetails(ctx.item)
                },
                {
                    name: 'btnViewRequest', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.view,
                    iconType: 'eye', className: 'info', onClick: (e, ctx) => this.viewRequest(ctx.item)
                },
                {
                    name: 'Pos', header: '', visible: false, visibleInColumnChooser: false, columnWidth: '2'
                },
                {
                    name: 'EmpCode', header: $.strings.expectedVacationsRpt.empCode, width: 120, columnWidth: '2'
                },
                {
                    name: 'EmpName', header: $.strings.expectedVacationsRpt.empName, width: 290, columnWidth: '*'
                },
                {
                    name: 'InsDate', header: $.strings.expectedVacationsRpt.insDate, width: 130, columnWidth: '3'
                },
                {
                    name: 'FromDate', header: $.strings.expectedVacationsRpt.fromDate, width: 120, columnWidth: '3'
                },
                {
                    name: 'ToDate', header: $.strings.expectedVacationsRpt.toDate, width: 120, columnWidth: '3'
                },
                {
                    name: 'StatusID', header: $.strings.expectedVacationsRpt.status, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'Status', header: $.strings.expectedVacationsRpt.status, width: 100, columnWidth: '2'
                },
                {
                    name: 'Notes', header: $.strings.expectedVacationsRpt.notes, width: "*", columnWidth: '*'
                }
            ]
        }
    }
}