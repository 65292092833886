import React from 'react';
import styles from './Users.module.scss';
//import RolesAdd from "./RolesAdd"
import ButtonsBarLookup from '../../../Components/ButtonsBarLookup';
import Messages from '../../../Components/Messages';
import Http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import MessageBox from '../../../Components/MessageBox';
import DeleteNoteDialog from '../../../Components/DeleteNoteDialog';
import Util from '../../../Util';
//import MainStockSearch from './MainStockSearch'
import Card from '../../../Components/Card';
import Accordion from '../../../Components/Accordion';
import DropDown from '../../../Components/Dropdown';

import Input from '../../../Components/Input';
import Label from '../../../Components/Label';
import Checkbox from '../../../Components/Checkbox';
import Password from '../../../Components/Password';
import Spinner from '../../../Components/Spinner';
import Button from '../../../Components/Button';
import Calendar from '../../../Components/Calendar';
import ResetPassword from './ResetPassword';
import { AccordionTab } from 'primereact/accordion';
import DataGridView from '../../../Components/DataGridView/DataGridView';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcInput from '@grapecity/wijmo.input';
import Dialog from '../../../Components/Dialog';
import AlertBox from '../../../Components/AlertBox/AlertBox';
import { Toast } from 'primereact/toast';

const $ = window.$;

export default class UsersAdd extends React.Component {
  constructor(props) {
    super(props);
    this.doHotKeys = true;
    this.hash = null;
    this.ValidateRoleResult = true;
    this.userTypesList = Util.getUserTypesList();
    this.state = {
      loading: true,
      mode: this.props.match.params && this.props.match.params.id > 0 ? 'edit' : 'new',
      isDesktop: window.innerWidth > 1250,
      isDesktopStatus: window.innerWidth > 400,
      isDesktopScreenAdd: window.innerWidth > 750,
      pageInfo: this.setPageInfo(),
      formErrors: { name: '', loginName: '', password: '', confirmPassword: '', minPasswordLength: '' },
      deleteErrors: { deleteNote: '' },
      userTypesList: this.userTypesList,
      buttonsList: this.getButtonsList(0),
      fromArchive: false,
      rolesList: [],
      selectedDevice: {},
      selectedRole: undefined,
      dataObject: {
        id: 0,
        name: '',
        otherName: '',
        department: '',
        loginName: '',
        lastLoginTime: '',
        userType: this.userTypesList[0],
        dataRoles: [
          //{ ser: 1, id: 0, role_id: 0, role_name: '', role_name_lang2: '' }
        ],
        password: '',
        confirmPassword: '',
        passwordLastModifiedDate: '',
        passwordRequired: true,
        complexPassword: true,
        allowChangePassword: true,
        mustChangePassword: true,
        minPasswordLength: 8,
        rememberLastPasswords: 3,
        passwordExpireDaysCount: 90,
        wrongPasswordCountAllowed: 0,
        wrongPasswordCount: 0,
        accountLocked: false,
        accountLockDate: '',
        sendMsgWhenLoginFailed: false,
        maxLogCount: 0,
        logCount: 0,
        hasExpireDate: true,
        workExpireDate: '',
        concurrentLoginsLimit: 1,
        limitDeviceAccess: false,
        dataAuthorizedDevices: [],
        appointmentDate: '',
        retiredDate: '',
        jobTitle: '',
        phone: '',
        mobile: '',
        workMobile: '',
        extention: '',
        email: '',
        address: '',
        status: 1,
      },
    };
  }
  componentDidMount = async () => {
    if (!Util.checkISHR()) {
      Util.goTo('/Access');
      return;
    }
    if (!Util.checkIsMainUser()) {
      Util.goTo('/Access');
      return;
    }
    let rolesPromise = this.getRoles();
    let rolesList = await rolesPromise;
    this.setState(
      {
        dataObject: {
          ...this.state.dataObject,
          complexPassword: this.permissionSettings && this.permissionSettings.length > 0 ? this.permissionSettings[0].complex_password : true,
          minPasswordLength: this.permissionSettings && this.permissionSettings.length > 0 ? this.permissionSettings[0].minimum_password_length : 8,
          passwordExpireDaysCount:
            this.permissionSettings && this.permissionSettings.length > 0 ? this.permissionSettings[0].password_expire_days : undefined,
          wrongPasswordCountAllowed:
            this.permissionSettings && this.permissionSettings.length > 0 ? this.permissionSettings[0].wrong_password_count : undefined,
        },

        rolesList: rolesList,
        department: undefined,
        loading: false,
      }
    );
    if (this.props.match.params.id) {
      let objUser = await this.getData(this.props.match.params.id);
      if (objUser) this.loadData(objUser, false);
      else this.onNew(null, false);
    }
    window.addEventListener('resize', this.updatePredicate);
    document.addEventListener('keydown', this.onPageKeyDown);
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
    document.removeEventListener('keydown', this.onPageKeyDown);
  }
  popupHasCalled = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
  };
  popupHasClosed = () => {
    document.addEventListener('keydown', this.onPageKeyDown);
  };
  onPageKeyDown = async (e) => {
    if (!this.doHotKeys) return;
    this.doHotKeys = false;
    switch (e.keyCode) {
      case Util.keyboardKeys.F3: {
        e.preventDefault();
        if (!this.state.fromArchive) {
          await this.onSaveWithoutClose(e, true);
        }
        break;
      }
      case Util.keyboardKeys.F6: {
        e.preventDefault();
        this.onNew(e, true);
        break;
      }
      default: {
      }
    }
    this.doHotKeys = true;
  };


  render() {
    let statusColor = this.state.dataObject.status === 2 ? 'red' : 'limegreen';
    let screenHeader = (
      <div className={`${'p-grid'} ${styles.titleBar}`}>
        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ verticalAlign: 'middle' }}>
          <h2 className={styles.title}>
            {this.state.pageInfo.title + ' '}
            {this.state.mode === 'new' ? <span style={{ color: 'red' }}>{' (' + $.strings.newMode + ')'}</span> : ''}
          </h2>
        </div>
      </div>
    );
    const editRoleFooter = (
      <React.Fragment>
        <Button
          label={$.strings.saveData}
          icon="pi pi-check"
          iconPos={$.strings.dir === 'rtl' ? 'right' : 'left'}
          className="p-button-text p-button-success"
          onClick={this.saveEditRole}
        />
        <Button
          label={$.strings.cancel}
          icon="pi pi-times"
          iconPos={$.strings.dir === 'rtl' ? 'right' : 'left'}
          className="p-button-text p-button-danger"
          onClick={this.hideRoleDialog}
        />
      </React.Fragment>
    );
    const editAuthorizedDevicesFooter = (
      <React.Fragment>
        <Button
          label={$.strings.saveData}
          icon="pi pi-check"
          iconPos={$.strings.dir === 'rtl' ? 'right' : 'left'}
          className="p-button-text p-button-success"
          onClick={this.saveAuthorizedDevices}
        />
        <Button
          label={$.strings.cancel}
          icon="pi pi-times"
          iconPos={$.strings.dir === 'rtl' ? 'right' : 'left'}
          className="p-button-text p-button-danger"
          onClick={this.hideAuthorizedDevicesDialog}
        />
      </React.Fragment>
    );
    const gridStyle = { maxHeight: '300px' };
    return (
      <div ref="divMain" className={styles.AddBanks}>
        <ProgressSpinner loading={this.state.loading} />
        {this.state.alertBoxVisible && <AlertBox ref={(e) => (this.alertBox = e)} header={$.strings.appName} yesCaption={$.strings.ok} />}

        {this.state.saveBoxVisible && <AlertBox ref={(e) => (this.saveBox = e)} header={$.strings.appName} appendTo={this.props.parent} />}

        {this.state.dlgOnRemoveRoleVisible && (
          <MessageBox
            header={$.strings.appName}
            messageText={$.strings.users.removeRole}
            visible={true}
            onYes={(e) => this.deleteRoleWijmo(e, undefined, false)}
            onCancel={this.onCancelRole}
            appendTo={this.props.parent}
          />
        )}
        {this.state.dlgOnRemoveAuthorizedDeviceVisible && (
          <MessageBox
            header={$.strings.appName}
            messageText={$.strings.users.removeAuthorizedDevice}
            visible={true}
            onYes={(e) => this.deleteAuthorizedDeviceWijmo(e, undefined, false)}
            onCancel={this.onCancel}
            appendTo={this.props.parent}
          />
        )}

        {this.state.dlgDeleteNoteVisible && (
          <DeleteNoteDialog
            ref={(ref) => (this.deleteNoteDlg = ref)}
            visible={this.state.dlgDeleteNoteVisible}
            deleteErrors={this.state.deleteErrors}
            appendTo={this.props.parent}
            onYes={this.btnDeleteYes}
            onNo={this.DeleteCancel}
            deleteNote=""
          />
        )}
        {this.state.dlgDeactiveVisible && (
          <DeleteNoteDialog
            ref={(ref) => (this.dlgDeactive = ref)}
            deleteErrors={this.state.deleteErrors}
            onYes={(e) => this.btnDeactiveYes(e)}
            onNo={this.deactiveCancel}
            deleteNote={this.state.deactivateNote}
            noteIsNotRequired={true}
          />
        )}

        {screenHeader}
        <div className={styles.sticky}>
          <Card>
            <div className="p-grid">
              <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                <ButtonsBarLookup
                  onNew={(e) => this.onNew(e, true)}
                  onDelete={(e) => this.onDelete(e, true)}
                  onPrevious={(e) => this.onPrevious(e, true)}
                  onNext={(e) => this.onNext(e, true)}
                  onFirst={(e) => this.onFirst(e, true)}
                  onLast={(e) => this.onLast(e, true)}
                  onClone={(e) => this.onClone(e, true)}
                  onSave={(e) => this.onSaveWithoutClose(e, true)}
                  onDeactivate={(e) => this.onDeactivate(e, true)}
                  onActivate={(e) => this.onActivate(e, true)}
                  onLogFile={(e) => this.onLogFile(e, true)}
                  itemsList={this.state.buttonsList}
                  recordId={this.state.dataObject.id}
                  fromArchive={this.state.fromArchive}
                  pageId={this.state.pageInfo.pageId}
                  parent={this}
                />
                <Messages innerRef={(el) => (this.messages = el)} />
                <Toast ref={(el) => (this.toast = el)} position={$.strings.dir === 'rtl' ? 'top-left' : 'top-right'} style={{ top: 100 }} />
              </div>
            </div>
          </Card>
        </div>

        <div className="p-grid">
          <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
            <Card style={{ minHeight: '420px', maxHeight: '420px' }}>
              {/*الاسم*/}
              <div className="p-grid">
                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                  <Input
                    innerRef={(el) => (this.txtName = el)}
                    id="name"
                    maxLength="100"
                    value={this.state.dataObject.name}
                    autofocus={true}
                    autocomplete="off"
                    caption={$.strings.users.name}
                    tooltip={$.strings.users.nameTooltip}
                    onChange={this.handleUserInput}
                    onBlur={this.onNameBlur}
                    isRequired={true}
                    formErrors={this.state.formErrors}
                  />
                </div>
              </div>
              <div className="p-grid">
              {/*رقم المستخدم*/}
                <div className="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                  <Input
                    type="text"
                    id="loginName"
                    maxLength="50"
                    autocomplete="off"
                    value={this.state.dataObject.loginName}
                    caption={$.strings.users.loginName}
                    onChange={this.handleUserInput}
                    tooltip={$.strings.users.loginNameTooltip}
                    isRequired={true}
                    formErrors={this.state.formErrors}
                    onBlur={this.validateLoginName}
                  />
                </div>
                  {/*نوع المستخدم*/}
                <div className="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                  <DropDown
                    id="userType"
                    value={this.state.dataObject.userType}
                    options={this.state.userTypesList}
                    onChange={this.handleUserInput}
                    style={{ width: '100%' }}
                    optionLabel="name"
                    isRequired={true}
                    caption={$.strings.users.userType}
                    disabled={!Util.checkIsSuperAdmin()}
                  />
                </div>
              </div>
              <div className="p-grid">
              {/*المسمى الوظيفي*/}
              <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                <Input
                  id="jobTitle"
                  maxLength="70"
                  value={this.state.dataObject.jobTitle}
                  autocomplete="off"
                  caption={$.strings.users.jobTitle}
                  tooltip={$.strings.users.jobTitleTooltip}
                  onChange={this.handleUserInput}
                />
              </div>
            </div>
            </Card>
          </div>
          <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
            <Card style={{ minHeight: '420px', maxHeight: '420px' }}>
              <div className="p-grid">
                <div className="p-col-11 p-xl-7 p-lg-7 p-md-11 p-sm-11">
                  <Button icon="pi pi-plus" tooltip={$.strings.users.addRole} onClick={this.addRole} />
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                  <DataGridView
                    style={gridStyle}
                    ref={(e) => (this.grid = e)}
                    isReport={false}
                    showContextMenu={false}
                    idProperty="ser"
                    allowAddNew={false}
                    keyActionEnter="CycleEditable"
                    scheme={this.getSchemeRolesWijmo()}
                    dataSource={
                      new wjcCore.CollectionView(this.state.dataObject.dataRoles, {
                        getError: this._getError.bind(this),
                      })
                    }
                    cellEditEnding={this.cellEditEnding}
                    cellEditEnded={this.cellEditEnded}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
        <Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index }, () => this.onTabChange(e))}>
          {/*بيانات كلمة المرور*/}
          <AccordionTab header={$.strings.users.passwordInfo}>
            <div className="p-grid">
              {/*كلمة المرور*/}
              <div className="p-col-12 p-xl-5 p-lg-5 p-md-12 p-sm-12">
                <Password
                  innerRef={(el) => (this.txtNewPwd = el)}
                  type="text"
                  id="password"
                  inputId="password"
                  maxLength="64"
                  value={this.state.dataObject.password}
                  autocomplete="off"
                  onChange={this.handleUserInput}
                  caption={$.strings.users.password}
                  tooltip={$.strings.users.passwordTooltip}
                  isRequired={true}
                  formErrors={this.state.formErrors}
                  disabled={this.state.dataObject.id > 0 ? true : false}
                  feedback={false}
                />
              </div>
              {/*تأكيد كلمة المرور*/}
              <div className="p-col-12 p-xl-5 p-lg-5 p-md-12 p-sm-12">
                <Password
                  type="text"
                  id="confirmPassword"
                  autofocus={true}
                  inputId="confirmPassword"
                  value={this.state.dataObject.confirmPassword}
                  autocomplete="off"
                  onChange={this.handleUserInput}
                  caption={$.strings.users.confirmPassword}
                  isRequired={true}
                  formErrors={this.state.formErrors}
                  disabled={this.state.dataObject.id > 0 ? true : false}
                  feedback={false}
                />
              </div>
              {/*تاريخ اخر تعديل لكلمة المرور*/}
              <div className="p-col-12 p-xl-2 p-lg-2 p-md-12 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                <Label title={$.strings.users.passwordLastModifiedDate}>{this.state.dataObject.passwordLastModifiedDate}</Label>
              </div>
            </div>
            <div className="p-grid">
              {/*بحاجة لكلمة مرور*/}
              <div className="p-col-12 p-xl-5 p-lg-5 p-md-12 p-sm-12">
                <Checkbox
                  caption={$.strings.users.passwordRequired}
                  id="passwordRequired"
                  inputId="passwordRequired"
                  checked={this.state.dataObject.passwordRequired}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, passwordRequired: e.checked } })}
                  tooltip={$.strings.users.passwordRequiredTooltip}
                />
              </div>
              {/*كلمة المرور معقدة*/} 
              <div className="p-col-12 p-xl-5 p-lg-5 p-md-12 p-sm-12">
                <Checkbox
                  caption={$.strings.users.complexPassword}
                  id="complexPassword"
                  inputId="complexPassword"
                  checked={this.state.dataObject.complexPassword}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, complexPassword: e.checked } })}
                  tooltip={$.strings.users.complexPassword}
                />
              </div>
            </div>
            <div className="p-grid">
              {/*السماح بتعديل كلمة مرور*/}
              <div className="p-col-12 p-xl-5 p-lg-5 p-md-12 p-sm-12">
                <Checkbox
                  caption={$.strings.users.allowChangePassword}
                  id="allowChangePassword"
                  inputId="allowChangePassword"
                  checked={this.state.dataObject.allowChangePassword}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, allowChangePassword: e.checked } })}
                  tooltip={$.strings.users.allowChangePasswordTooltip}
                />
              </div>
              {/*طلب تغيير كلمة المرور عند أول دخول*/}
              <div className="p-col-12 p-xl-5 p-lg-5 p-md-12 p-sm-12">
                <Checkbox
                  caption={$.strings.users.mustChangePassword}
                  id="mustChangePassword"
                  inputId="mustChangePassword"
                  checked={this.state.dataObject.mustChangePassword}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, mustChangePassword: e.checked } })}
                  tooltip={$.strings.users.mustChangePasswordTooltip}
                />
              </div>
            </div>
            <div className="p-grid">
              {/*الحد الأدنى لطول كلمة مرور*/}
              <div className="p-col-12 p-xl-5 p-lg-5 p-md-12 p-sm-12">
                <Spinner
                  id="minPasswordLength"
                  value={this.state.dataObject.minPasswordLength}
                  min={1}
                  max={49}
                  caption={$.strings.users.minPasswordLength}
                  tooltip={$.strings.users.minPasswordLengthTooltip}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, minPasswordLength: e.value } })}
                  style={{ width: '100%' }}
                  isRequired={true}
                  formErrors={this.state.formErrors}
                />
              </div>
              {/*تذكر اخر كلمات مرور*/}
              <div className="p-col-12 p-xl-5 p-lg-5 p-md-12 p-sm-12">
                <Spinner
                  id="rememberLastPasswords"
                  value={this.state.dataObject.rememberLastPasswords}
                  caption={$.strings.users.rememberLastPasswords}
                  tooltip={$.strings.users.rememberLastPasswordsTooltip}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, rememberLastPasswords: e.value } })}
                  style={{ width: '100%' }}
                  min={0}
                  max={1000}
                />
              </div>
            </div>
            <div className="p-grid">
              {/*مدة انتهاء كلمة المرور بالايام*/}
              <div className="p-col-12 p-xl-5 p-lg-5 p-md-12 p-sm-12">
                <Spinner
                  id="passwordExpireDaysCount"
                  value={this.state.dataObject.passwordExpireDaysCount}
                  min={1}
                  max={49}
                  caption={$.strings.users.passwordExpireDaysCount}
                  tooltip={$.strings.users.passwordExpireDaysCountTooltip}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, passwordExpireDaysCount: e.value } })}
                  style={{ width: '100%' }}
                  isRequired={true}
                  formErrors={this.state.formErrors}
                />
              </div>
            </div>
          </AccordionTab>
          {/*تحديدات الدخول*/}
          <AccordionTab header={$.strings.users.loginLimitations}>
            <div className="p-grid">
              {/*عدد محاولات الدخول الخاطئ المسموح*/}
              <div className="p-col-12 p-xl-4 p-lg-6 p-md-12 p-sm-12">
                <Spinner
                  id="wrongPasswordCountAllowed"
                  min={0}
                  max={1000}
                  innerRef={(e) => (this.txtWrongPasswordCountAllowed = e)}
                  value={this.state.dataObject.wrongPasswordCountAllowed}
                  caption={$.strings.users.wrongPasswordCountAllowed}
                  tooltip={$.strings.users.wrongPasswordCountAllowedTooltip}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, wrongPasswordCountAllowed: e.value } })}
                  style={{ width: '100%' }}
                />
              </div>
              {/*عدد محاولات الدخول الخاطئ*/}
              <div className="p-col-12 p-xl-4 p-lg-6 p-md-12 p-sm-12">
                <Spinner
                  min={0}
                  max={1000}
                  id="wrongPasswordCount"
                  value={this.state.dataObject.wrongPasswordCount}
                  caption={$.strings.users.wrongPasswordCount}
                  tooltip={$.strings.users.wrongPasswordCountTooltip}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, wrongPasswordCount: e.value } })}
                  style={{ width: '100%' }}
                  disabled={true}
                />
              </div>
              {/*تم ايقاف عمل المستخدم*/}
              <div className="p-col-12 p-xl-3 p-lg-6 p-md-12 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                <Checkbox
                  caption={$.strings.users.accountLocked}
                  id="accountLocked"
                  inputId="accountLocked"
                  checked={this.state.dataObject.accountLocked}
                  onChange={this.setAccountLocked}
                  tooltip={$.strings.users.accountLocked}
                />
                <Label title={$.strings.users.accountLockDate}> {this.state.dataObject.accountLockDate}</Label>
              </div>
            </div>
            <div className="p-grid">
              {/*عدد مرات الدخول المسموح*/}
              <div className="p-col-12 p-xl-4 p-lg-6 p-md-12 p-sm-12">
                <Spinner
                  min={0}
                  max={1000}
                  id="maxLogCount"
                  value={this.state.dataObject.maxLogCount}
                  caption={$.strings.users.maxLogCount}
                  tooltip={$.strings.users.maxLogCountTooltip}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, maxLogCount: e.value } })}
                  style={{ width: '100%' }}
                />
              </div>
              {/*عدد مرات الدخول*/}
              <div className="p-col-12 p-xl-4 p-lg-6 p-md-12 p-sm-12">
                <Spinner
                  min={0}
                  max={1000}
                  id="logCount"
                  value={this.state.dataObject.logCount}
                  caption={$.strings.users.logCount}
                  tooltip={$.strings.users.logCountTooltip}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, logCount: e.value } })}
                  style={{ width: '100%' }}
                  disabled={true}
                />
              </div>
            </div>
            <div className="p-grid">
              {/*له تاريخ انتهاء عمل*/}
              <div className="p-col-12 p-xl-4 p-lg-6 p-md-12 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                <Checkbox
                  caption={$.strings.users.hasExpireDate}
                  id="hasExpireDate"
                  inputId="hasExpireDate"
                  checked={this.state.dataObject.hasExpireDate}
                  tooltip={$.strings.users.hasExpireDate}
                  onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, hasExpireDate: e.checked } })}
                />
              </div>
              {/*تاريخ انتهاء عمل المستخدم*/}
              <div className="p-col-12 p-xl-4 p-lg-6 p-md-12 p-sm-12">
                <Calendar
                  id="workExpireDate"
                  inputId="workExpireDate"
                  value={
                    this.state.dataObject.workExpireDate && this.state.dataObject.workExpireDate.getFullYear() > 2000
                      ? this.state.dataObject.workExpireDate
                      : ''
                  }
                  caption={$.strings.users.workExpireDate}
                  onChange={this.handleUserInput}
                  readOnlyInput={true}
                  tooltip={$.strings.users.workExpireDate}
                />
              </div>
            </div>


          </AccordionTab>
          {/*بيانات التعيين*/}
          <AccordionTab header={$.strings.users.appData}>
            <div className="p-grid">
              {/*تاريخ التعيين*/}
              <div className="p-col-12 p-xl-3 p-lg-3 p-md-12 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                <Calendar
                  id="appointmentDate"
                  inputId="appointmentDate"
                  ref={(e) => (this.txtAppointmentDate = e)}
                  value={
                    this.state.dataObject.appointmentDate && this.state.dataObject.appointmentDate.getFullYear() > 2000
                      ? this.state.dataObject.appointmentDate
                      : ''
                  }
                  caption={$.strings.users.appointmentDate}
                  onChange={this.handleUserInput}
                  readOnlyInput={true}
                  tooltip={$.strings.users.appointmentDateTooltip}
                />
              </div>
              {/*تاريخ انهاء العمل*/}
              <div className="p-col-12 p-xl-3 p-lg-3 p-md-12 p-sm-12">
                <Calendar
                  id="retiredDate"
                  inputId="retiredDate"
                  value={
                    this.state.dataObject.retiredDate && this.state.dataObject.retiredDate.getFullYear() > 2000
                      ? this.state.dataObject.retiredDate
                      : ''
                  }
                  caption={$.strings.users.retiredDate}
                  onChange={this.handleUserInput}
                  readOnlyInput={true}
                  tooltip={$.strings.users.retiredDate}
                />
              </div>
            </div>
          </AccordionTab>
          {/*بيانات العنوان*/}
          <AccordionTab header={$.strings.users.addressData}>
            <div className="p-grid">
              {/*رقم الهاتف*/}
              <div className="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                <Input
                  id="phone"
                  innerRef={(e) => (this.txtPhone = e)}
                  maxLength="50"
                  value={this.state.dataObject.phone}
                  autocomplete="off"
                  caption={$.strings.users.phone}
                  tooltip={$.strings.users.phone}
                  onChange={this.handleUserInput}
                />
              </div>
              {/*رقم المحمول*/}
              <div className="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                <Input
                  id="mobile"
                  maxLength="50"
                  value={this.state.dataObject.mobile}
                  autocomplete="off"
                  caption={$.strings.users.mobile}
                  tooltip={$.strings.users.mobileTooltip}
                  onChange={this.handleUserInput}
                />
              </div>
            </div>
            <div className="p-grid">
              {/*رقم هاتف العمل*/}
              <div className="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                <Input
                  id="workMobile"
                  maxLength="50"
                  value={this.state.dataObject.workMobile}
                  autocomplete="off"
                  caption={$.strings.users.workMobile}
                  tooltip={$.strings.users.workMobileTooltip}
                  onChange={this.handleUserInput}
                />
              </div>
              {/*رقم البدالة الداخلية*/}
              <div className="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                <Input
                  id="extention"
                  maxLength="15"
                  value={this.state.dataObject.extention}
                  autocomplete="off"
                  caption={$.strings.users.extention}
                  tooltip={$.strings.users.extention}
                  onChange={this.handleUserInput}
                />
              </div>
            </div>
            <div className="p-grid">
              {/*البريد الالكتروني*/}
              <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                <Input
                  id="email"
                  maxLength="70"
                  value={this.state.dataObject.email}
                  autocomplete="off"
                  caption={$.strings.users.email}
                  tooltip={$.strings.users.email}
                  onChange={this.handleUserInput}
                />
              </div>
            </div>
            <div className="p-grid">
              {/*العنوان*/}
              <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                <Input
                  id="address"
                  maxLength="70"
                  value={this.state.dataObject.address}
                  autocomplete="off"
                  caption={$.strings.users.address}
                  tooltip={$.strings.users.addressTooltip}
                  onChange={this.handleUserInput}
                />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        {this.state.dlgResetPasswordVisible && (
          <ResetPassword
            ref={(ref) => (this.dlgResetPassword = ref)}
            onSuccess={this.onResetPasswordSuccess}
            onClose={this.onResetPasswordClose}
            selectedUserId={this.state.selectedUserId}
          />
        )}
        <Dialog
          visible={this.state.visibleRoleDialog}
          style={{ width: this.state.isDesktop ? '300px' : '75%' }}
          header={$.strings.users.editRole}
          modal
          className="p-fluid"
          footer={editRoleFooter}
          onHide={this.hideRoleDialog}
          contentStyle={{ direction: $.strings.dir }}
        >
          <div className="p-grid">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
              <DropDown
                id="selectedRole"
                value={this.state.selectedRole}
                options={this.state.rolesList}
                onChange={(e) =>
                  this.setState({ selectedRole: e.target.value }, () => {
                    let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1;
                    this.grid.flex.select(rowIndex, 1);
                  })
                }
                style={{ width: '100%' }}
                optionLabel="name"
                optionLabelLang2="name_lang2"
                caption={$.strings.users.role}
                isRequired={true}
                formErrors={this.state.formErrors}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.visibleAuthorizedDevicesDialog}
          style={{ width: this.state.isDesktop ? '300px' : '75%' }}
          header={$.strings.users.editAuthorizedDevices}
          modal
          className="p-fluid"
          footer={editAuthorizedDevicesFooter}
          onHide={this.hideAuthorizedDevicesDialog}
          contentStyle={{ direction: $.strings.dir }}
        >
          <div className="p-grid">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
              <Input
                id="device_name"
                maxLength="50"
                value={this.state.selectedDevice.device_name}
                autoFocus={true}
                caption={$.strings.users.deviceName}
                onChange={(e) => this.setState({ selectedDevice: { ...this.state.selectedDevice, device_name: e.target.value } })}
                isRequired={true}
                formErrors={this.state.formErrors}
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
              <Input
                id="rdp_client_name"
                maxLength="50"
                value={this.state.selectedDevice.rdp_client_name}
                autoFocus={false}
                caption={$.strings.users.rdpClientName}
                onChange={(e) => this.setState({ selectedDevice: { ...this.state.selectedDevice, rdp_client_name: e.target.value } })}
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
              <Checkbox
                caption={$.strings.users.isAllowed}
                id="is_allowed"
                inputId="is_allowed"
                checked={this.state.selectedDevice.is_allowed}
                onChange={(e) => this.setState({ selectedDevice: { ...this.state.selectedDevice, is_allowed: e.checked } })}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
  onResetPasswordSuccess = () => {
    this.popupHasClosed();
    this.setState({ dlgResetPasswordVisible: false });
  };
  onResetPasswordClose = () => {
    this.popupHasClosed();
    this.setState({ dlgResetPasswordVisible: false });
  };
  getButtonsList = (id) => {
    let buttonsList = [
      {
        label: $.strings.users.userRights,
        tooltip: $.strings.users.userRightsTooltip,
        command: this.onUsersRights,
        visible: id > 0 && !this.state.fromArchive,
      },
      {
        label: $.strings.users.usersLoginLog,
        tooltip: $.strings.users.usersLoginLogTooltip,
        command: this.onUserLogin,
        visible: id > 0 && !this.state.fromArchive,
      },
      {
        label: $.strings.users.changePassword,
        tooltip: $.strings.users.changePasswordTooltip,
        command: this.onChangePassword,
        visible: id > 0 && !this.state.fromArchive,
      },
    ];
    return buttonsList;
  };

  onTabChange = (e) => {
    switch (e.index) {
      case 0: {
        setTimeout(() => {
          this.txtNewPwd.inputRef.current.focus();
        }, 50);
        break;
      }
      case 1: {
        setTimeout(() => {
          this.txtWrongPasswordCountAllowed.element.firstChild.focus();
        }, 50);
        break;
      }
      case 2: {
        // setTimeout(() => {
        //     this.txtAppointmentDate.calender.container.firstChild.focus()
        // }, 50)
        break;
      }
      case 3: {
        setTimeout(() => {
          this.txtPhone.focus();
        }, 50);
        break;
      }
      default: {
        break;
      }
    }
  };


  onUsersRights = () => {
    Util.goToNewTab('../../AccessRights/PermissionsUsersAccess/' + this.state.dataObject.id);
  };

  onUserLogin = async () => {
    Util.goToNewTab('../../AccessRights/PermissionsLogFile/' + this.state.dataObject.id);
  };

  onChangePassword = () => {
    if (!Util.checkIsSuperAdmin()) {
      if (this.state.dataObject.userType && this.state.dataObject.userType.id === 2) {
        Util.showErrorMsg(this.messages, $.strings.users.cannotChangeSuperAdminPassword);
        return;
      }
    }
    this.popupHasCalled();
    this.setState({ dlgResetPasswordVisible: true, selectedUserId: this.state.dataObject.id });
  };

  setAccountLocked = (e) => {
    this.setState({ dataObject: { ...this.state.dataObject, accountLocked: e.checked } }, this.setWrongPasswordCount);
  };
  setWrongPasswordCount = () => {
    if (!this.state.dataObject.accountLocked) {
      this.setState({ dataObject: { ...this.state.dataObject, setWrongPasswordCount: 0 } });
    }
  };

  validateLoginName = async () => {
    let loginNameValid = false;
    const params = {
      login_name: this.state.dataObject.loginName,
      user_id:this.state.dataObject.id
    };
    let res = await Http.getJsonAxios(Http.actions.Users.urlCheckDuplicateLoginName, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      loginNameValid = res.data._result;
    }
    if (!loginNameValid) {
      Util.showErrorMsg(this.messages, $.strings.users.loginNameDuplicated);
    }
  };

  addRole = () => {
    if (this.state.isDesktop) {
      let dataTemp = this.state.dataObject.dataRoles;
      if (dataTemp && dataTemp.length > 0 && dataTemp[dataTemp.length - 1]['role_id'] === 0) {
        Util.gridColumnStartEditing(this.grid, this.grid.flex.rows.length - 1, 4, true);
        return;
      }

      dataTemp.push({
        ser: dataTemp.length + 1,
        isDeleted: false,
        role_id: 0,
        role_name: '',
      });

      this.setState({ dataObject: { ...this.state.dataObject, dataRoles: dataTemp } }, () => {
        Util.gridColumnStartEditing(this.grid, this.grid.flex.rows.length - 1, 4, true);
        return;
      });
    } else {
      this.setState({ visibleRoleDialog: true, selectedRole: undefined, isNew: true }, () => {
        Util.gridColumnStartEditing(this.grid, this.grid.flex.rows.length - 1, 4, true);
        return;
      });
    }
  };



  onSearch = () => {
    //this.dlgMainStockSearch.show()
  };

  onSearchOk = async (obj) => {
    if (obj) {
      let objUser = await this.getData(obj.id);
      this.loadData(objUser);
    }
  };
  onSearchClose = () => {};

  onNameBlur = (e) => {
    if (!this.state.dataObject.otherName) this.setState({ dataObject: { ...this.state.dataObject, otherName: e.target.value } }, e.target.selectAll);
  };

  onSimilarityCheck = async () => {
    if (!this.state.dataObject.name && !this.state.dataObject.otherName) {
      Util.showInfoMsg(this.messages, $.strings.similarCheckMsg);
      return;
    }
    this.setState({ loading: true });
    let data = await this.doOnSimilarityCheck();
    if (data) {
      this.setState({ loading: false }, () => this.similarityCheck.showDialog(data));
    } else this.setState({ loading: false });
  };

  doOnSimilarityCheck = async () => {
    let param = {
      name: this.state.dataObject.name,
      name_lang2: this.state.dataObject.otherName,
      id: this.state.dataObject.id,
    };
    let data = [];
    let res = await Http.getJsonAxios(Http.actions.Users.urlGetCheckNameUsers, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      data = res.data._users;
    }

    return data;
  };

  onCancel = () => {
    this.setState({
      dlgOnRemoveRoleVisible: false,
      dlgOnRemoveAuthorizedDeviceVisible: false,
      dlgOnLogFileVisible: false,
    });
  };

  onCancelRole = () => {
    let rowIndex = this.state.messageBoxEventArgs.index;
    this.setState({ dlgOnRemoveRoleVisible: false }, () => {
      Util.gridColumnStartEditing(this.grid, rowIndex, 4, true);
    });
  };


  onSaveWithoutClose = async (e, fromSave) => {
    this.setState({ loading: true });
    if (!this.validateField() || !this.ValidateRoleResult) {
      this.setState({ loading: false });
      return false;
    }
    let saveDone = false;
    let result = await this.saveData();
    if (result.status === 200) {
      Util.showSuccessMsg(this.messages);
      if (fromSave) this.resetFields();
      saveDone = true;
    } else if (result.status === 400) {
      Util.showErrorMsg(this.messages, result.message.message);
      saveDone = false;
    } else {
      Util.showErrorMsg(this.messages);
      this.setState({ loading: false });
      saveDone = false;
    }
    this.setState({ loading: false });
    return saveDone;
  };

  onClone = (e, checkChanging) => {
    if (!this.state.dataObject.id) return;
    if (checkChanging &&!this.state.fromArchive) {
      this.popupHasCalled();
      this.setState({ saveBoxVisible: true }, () => {
        this.saveBox.show(
          async () => {
            let res = await this.onSaveWithoutClose(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
            return res;
          },
          () => {
            this.onClone(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          () => {
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          $.strings.dataChanged
        );
      });
      return;
    }

    this.setState(
      {
        dataObject: { ...this.state.dataObject, id: 0, status: 1 },
        formErrors: { name: '', otherName: '', loginName: '', password: '', confirmPassword: '', department: '', minPasswordLength: '' },
        deleteErrors: { deleteNote: '' },
        mode: 'new',
      },
      () => this.txtName.focus()
    );
  };

  onDeactivate = async (e, checkChanging) => {
    if (!this.state.dataObject.id) {
      return false;
    }
    if (checkChanging && !this.state.fromArchive) {
      this.popupHasCalled();
      this.setState({ saveBoxVisible: true }, () => {
        this.saveBox.show(
          async () => {
            let res = await this.onSaveWithoutClose(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
            return res;
          },
          () => {
            this.onDeactivate(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          () => {
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          $.strings.dataChanged
        );
      });
      return;
    }
    this.popupHasCalled();
    this.setState({ dlgDeactiveVisible: true });
  };
  btnDeactiveYes = async (deleteNote) => {
    this.setState({ loading: true });
    let obj = {
      id: this.state.dataObject.id,
      status: 2,
    };
    let result = 500;
    let res = await Http.postJsonAxios(Http.actions.Users.urlPostChangeStatusUsers, obj, this.state.pageInfo.pageId);
    result = res.status;
    if (result === 200) {
      if (res.data && res.data.successful) {
        this.popupHasCalled();
        Util.showSuccessMsg(this.messages);
        await this.resetFields();
        this.setState({
          dataObject: { ...this.state.dataObject, status: 2 },
          loading: false,
          dlgDeactiveVisible: false,
          refreshQueryRequired: true,
        });
        return true;
      } else {
        let msg = $.strings.operationFailed;
        if (res.data && res.data.message.length > 0) msg = res.data.message;
        Util.showErrorMsg(this.messages, msg);
        this.setState({ loading: false, dlgDeactiveVisible: false });
        return false;
      }
    } else {
      this.setState({ loading: false, dlgDeactiveVisible: false });
      return false;
    }
  };
  deactiveCancel = () => {
    this.popupHasClosed();
    this.setState({
      dlgDeactiveVisible: false,
      deleteErrors: { deleteNote: '' },
    });
  };
  onActivate = async (e, checkChanging) => {
    if (checkChanging && !this.state.fromArchive) {
      this.popupHasCalled();
      this.setState({ saveBoxVisible: true }, () => {
        this.saveBox.show(
          async () => {
            let res = await this.onSaveWithoutClose(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
            return res;
          },
          () => {
            this.onActivate(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          () => {
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          $.strings.dataChanged
        );
      });
      return;
    }
    this.setState({ loading: true });
    if (!this.state.dataObject.id) {
      this.setState({ loading: false });
      return;
    }
    let obj = {
      id: this.state.dataObject.id,
      status: 1,
    };
    let result = 500;

    let res = await Http.postJsonAxios(Http.actions.Users.urlPostChangeStatusUsers, obj, this.state.pageInfo.pageId);
    result = res.status;

    if (result === 200) {
      if (res.data && res.data.successful) {
        this.popupHasCalled();
        Util.showSuccessMsg(this.messages);
        await this.resetFields();
        this.setState({
          dataObject: { ...this.state.dataObject, status: 1 },
          loading: false,
          refreshQueryRequired: true,
        });
        return true;
      } else {
        let msg = $.strings.operationFailed;
        if (res.data && res.data.message.length > 0) msg = res.data.message;
        Util.showErrorMsg(this.messages, msg);
        this.setState({ loading: false });
        return false;
      }
    } else {
      this.setState({ loading: false });
      return false;
    }
  };

  onDelete = async (e, checkChanging) => {
    if (!this.state.dataObject.id) {
      this.setState({ loading: false });
      return false;
    }
    if (checkChanging && !this.state.fromArchive) {
      this.popupHasCalled();
      this.setState({ saveBoxVisible: true }, () => {
        this.saveBox.show(
          async () => {
            let res = await this.onSaveWithoutClose(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
            return res;
          },
          () => {
            this.onDelete(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          () => {
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          $.strings.dataChanged
        );
      });
      return;
    }
    this.setState({ dlgDeleteNoteVisible: true }, () => this.deleteNoteDlg.setFocus());
  };

  DeleteCancel = () => {
    this.setState({ dlgDeleteNoteVisible: false, dlgDeactivateUserVisible: false, deleteErrors: { deleteNote: '' } });
  };

  btnDeleteYes = async (deleteNote) => {
    if (!deleteNote) {
      let _deleteErrors = this.state.deleteErrors;
      _deleteErrors.deleteNote = $.strings.enterDeleteNote;
      this.setState({ deleteErrors: _deleteErrors }, () => this.deleteNoteDlg.setFocus());
      return;
    }
    this.setState({ loading: true });
    let obj = {
      id: this.state.dataObject.id,
      note: deleteNote,
      status: 3,
    };
    let result = 500;
    let res = await Http.postJsonAxios(Http.actions.Users.urlPostChangeStatusUsers, obj, this.state.pageInfo.pageId);
    result = res.status;
    if (result === 200) {
      if (res.data && res.data.successful) {
        this.popupHasCalled();
        Util.showSuccessMsg(this.messages);
        await this.resetFields();
        this.setState({
          dataObject: { ...this.state.dataObject },
          loading: false,
          dlgDeleteNoteVisible: false,
          refreshQueryRequired: true,
        });
        return true;
      } else {
        let msg = $.strings.operationFailed;
        if (res.data && res.data.message.length > 0) msg = res.data.message;
        Util.showErrorMsg(this.messages, msg);
        this.setState({ loading: false, dlgDeleteNoteVisible: false });
        return false;
      }
    } else {
      this.setState({ loading: false, dlgDeleteNoteVisible: false });
      return false;
    }
  };

  btnDeleteNo = () => {
    this.setState({ dlgDeleteNoteVisible: false, deleteErrors: { deleteNote: '' } });
  };

  onPrevious = async (e, checkChanging) => {
    if (this.state.dataObject.id === 0) {
      Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
      return;
    }
    this.setState({ loading: true });
    let id = -1;
    id = this.state.dataObject.id;
    let param = {
      id: id,
      navigation_enum_value:4
    };
    let result = false;
    let obj = [];

    let res = await Http.getJsonAxios(Http.actions.Users.urlGetUserNavigation, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
      obj = res.data;
      if (obj.id === id) Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
    } else {
      let msg = $.strings.operationFailed;
      if (res.message && res.message.length > 0) {
        msg = res.message;
      }
      Util.showErrorMsg(this.messages, msg);
    }
    if (result) this.loadData(obj);
    else this.setState({ loading: false });
  };

  onNext = async (e, checkChanging) => {
    if (this.state.dataObject.id === -1) {
      Util.showInfoMsg(this.messages, $.strings.recordsEnds);
      return;
    }
    this.setState({ loading: true });
    let id = -1;
    id = this.state.dataObject ? this.state.dataObject.id:-1;
    let obj = [];
    let param = {
      id: id,
      navigation_enum_value:3
    };
    let result = false;

    let res = await Http.getJsonAxios(Http.actions.Users.urlGetUserNavigation, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
      obj = res.data;
      if (!obj || obj.id === id) Util.showInfoMsg(this.messages, $.strings.recordsEnds);
    } else {
      let msg = $.strings.operationFailed;
      if (res.message && res.message.length > 0) {
        msg = res.message;
      }
      Util.showErrorMsg(this.messages, msg);
    }
    if (result) this.loadData(obj);
    else this.setState({ loading: false });
  };

  onFirst = async (e, checkChanging) => {
    this.setState({ loading: true });
    let obj = [];
    let id = -1;
    if (this.state.dataObject.id) id = this.state.dataObject.id;
    let param = {
      id: -1,
      navigation_enum_value:1
    };
    let result = false;

    let res = await Http.getJsonAxios(Http.actions.Users.urlGetUserNavigation, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
      obj = res.data;
      if (obj.id === id) Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
    } else {
      let msg = $.strings.operationFailed;
      if (res.message && res.message.length > 0) {
        msg = res.message;
      }
      Util.showErrorMsg(this.messages, msg);
    }
    if (result) this.loadData(obj);
    else this.setState({ loading: false });
  };

  onLast = async (e, checkChanging) => {

    this.setState({ loading: true });
    let obj = [];
    let id = -1;
    if (this.state.dataObject.id) id = this.state.dataObject.id;
    let param = {
      id: -1,
      navigation_enum_value:2
    };
    let result = false;

    let res = await Http.getJsonAxios(Http.actions.Users.urlGetUserNavigation, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
      obj = res.data;
      if (obj.id === id) Util.showInfoMsg(this.messages, $.strings.recordsEnds);
    } else {
      let msg = $.strings.operationFailed;
      if (res.message && res.message.length > 0) {
        msg = res.message;
      }
      Util.showErrorMsg(this.messages, msg);
    }
    if (result) this.loadData(obj);
    else this.setState({ loading: false });
  };

  resetFields = async () => {
    this.setState(
      {
        buttonsList: this.getButtonsList(0),
        selectedDevice: {},
        selectedRole: undefined,
        dataObject: {
          id: 0,
          name: '',
          otherName: '',
          department: '',
          loginName: '',
          lastLoginTime: '',
          userType: this.userTypesList[0],
          dataRoles: [],
          password: '',
          confirmPassword: '',
          passwordLastModifiedDate: '',
          passwordRequired: true,
          allowChangePassword: true,
          mustChangePassword: true,
          complexPassword: this.permissionSettings && this.permissionSettings.length > 0 ? this.permissionSettings[0].complex_password : true,
          minPasswordLength: this.permissionSettings && this.permissionSettings.length > 0 ? this.permissionSettings[0].minimum_password_length : 8,
          passwordExpireDaysCount:
            this.permissionSettings && this.permissionSettings.length > 0 ? this.permissionSettings[0].password_expire_days : 90,
          wrongPasswordCountAllowed:
            this.permissionSettings && this.permissionSettings.length > 0 ? this.permissionSettings[0].wrong_password_count : undefined,
          rememberLastPasswords: 3,
          wrongPasswordCount: 0,
          accountLocked: false,
          accountLockDate: '',
          sendMsgWhenLoginFailed: false,
          maxLogCount: 0,
          logCount: 0,
          hasExpireDate: true,
          workExpireDate: '',
          concurrentLoginsLimit: 1,
          limitDeviceAccess: false,
          dataAuthorizedDevices: [],
          appointmentDate: '',
          retiredDate: '',
          jobTitle: '',
          phone: '',
          mobile: '',
          workMobile: '',
          extention: '',
          email: '',
          address: '',
          status: 1,
        },

        dlgOnLogFileVisible: false,
        fromArchive: false,
        formErrors: { name: '', loginName: '', password: '', confirmPassword: '', minPasswordLength: '' },
        deleteErrors: { deleteNote: '' },
        mode: 'new',
      },
      () => {
        this.txtName.focus();
        Util.goTo('./');
      }
    );
  };

  saveData = async () => {
    // الأدوار الوظيفية
    let permissionRoleUserList = [];
    if (this.state.dataObject.dataRoles) {
      let dataTemp = this.state.dataObject.dataRoles;
      for (let i = 0; i < dataTemp.length; i++) {
        permissionRoleUserList.push({
          user_id: this.state.dataObject.id,
          role_id: dataTemp[i]['role_id'],
        });
      }
    }

    let obj = {
      id: this.state.dataObject.id,
      name: this.state.dataObject.name,
      user_type: this.state.dataObject.userType.id,
      login_name: this.state.dataObject.loginName,

      userpassword: this.state.dataObject.id <= 0 ? Util.encryptStringToHexa(this.state.dataObject.password) : '',
      confirmPassword: this.state.dataObject.id <= 0 ? Util.encryptStringToHexa(this.state.dataObject.confirmPassword) : '',

      pass_len: this.state.dataObject.minPasswordLength,
      require_pass: this.state.dataObject.passwordRequired,
    //  require_pass: this.state.dataObject.complexPassword,
    allow_change_pass: this.state.dataObject.allowChangePassword,
    mustPassword: this.state.dataObject.mustChangePassword,
      //change_password_days:this.state.dataObject.change
      lastPasswordCount: this.state.dataObject.rememberLastPasswords,
      wrongPassCountSett: this.state.dataObject.wrongPasswordCountAllowed,
      max_log: this.state.dataObject.maxLogCount,
      //log_count // لا تمرر في التعريف
      //last_login_date // لا تمرر في التعريف
      //last_login_failure_date // لا تمرر في التعريف
     // concurrent_logins_limit: this.state.dataObject.concurrentLoginsLimit,
     // limit_device_access: this.state.dataObject.limitDeviceAccess,
     // send_sms_failure_count: this.state.dataObject.sendMsgWhenLoginFailed ? 1 : 0,
     has_fdate: this.state.dataObject.hasExpireDate,
     date_Finish:
        this.state.dataObject.workExpireDate && this.state.dataObject.workExpireDate.getFullYear() > 2000
          ? this.state.dataObject.workExpireDate
          : new Date(2000, 1, 1),
          date_In:
        this.state.dataObject.appointmentDate && this.state.dataObject.appointmentDate.getFullYear() > 2000
          ? this.state.dataObject.appointmentDate
          : new Date(2000, 1, 1),
          retired_date:
        this.state.dataObject.retiredDate && this.state.dataObject.retiredDate.getFullYear() > 2000
          ? this.state.dataObject.retiredDate
          : new Date(2000, 1, 1),
      title: this.state.dataObject.jobTitle,
      phone: this.state.dataObject.phone,
      mobile: this.state.dataObject.mobile,
    //  work_mobile: this.state.dataObject.workMobile,
      email: this.state.dataObject.email,
      extention: this.state.dataObject.extention,
    //  address: this.state.dataObject.address,
      //time_zone
      status: this.state.dataObject.status,
      Is_locked: this.state.dataObject.accountLocked,
      permissionRoleUserList: permissionRoleUserList,
    };

    let saveResult;
    let res = await Http.postJsonAxios(Http.actions.Users.urlPostSaveUsers, obj, this.state.pageInfo.pageId);
    saveResult = res;

    return saveResult;
  };

  getDepartments = async (fromLoad) => {
    let dataTemp = [];
    return dataTemp;
    if (!fromLoad) this.setState({ loading: true });
    const params = {
      main_stock_type_id: 0,
      status: -1,
    };

    let res = await Http.getJsonAxios(Http.actions.Departments.urlGetPermissionsDepartments, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data._permissionsDepartments;
    }
    return dataTemp;
  };
  getPermissionsSettings = async () => {
    let dataTemp = [];
    return dataTemp;
    const params = {
      status: Util.getStatusId(this.state.StatusFilter),
      id: 0,
    };

    let res = await Http.getJsonAxios(Http.actions.PermissionsSettings.urlGetPermissionsSettings, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data._PermissionsSettings;
    }
    return dataTemp;
  };

  getRoles = async (fromLoad) => {
    let dataTemp = [];
    if (!fromLoad) this.setState({ loading: true });
    const params = {
      id: -1,
    };

    let res = await Http.getJsonAxios(Http.actions.Roles.urlGetRoles, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
    }
    return dataTemp;
  };

  

  getData = async (id) => {
    let dataTemp = [];
    const params = {
      user_id: id,
    };

    let res = await Http.getJsonAxios(Http.actions.Users.urlGetUserObjectById, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data;
    }
    return dataTemp;
  };

  loadData = (rowData, loadFromArchive) => {
    this.messages.clear();
    let obj = rowData;

    let selectedUserType = undefined;
    selectedUserType = this.state.userTypesList.filter((element) => element.id === obj.admin_access);
    if (selectedUserType && selectedUserType.length > 0) {
      selectedUserType = selectedUserType[0];
    }
    let last_date = new Date(2000, 1, 1);
    if (obj.last_date && obj.last_date.length > 0) {
      let temp = new Date(obj.last_date);
      if (temp.getFullYear() > 2000) {
        last_date = temp;
      }
    }
    let lastPassChangeDate = new Date(2000, 1, 1);
    if (obj.lastPassChangeDate && obj.lastPassChangeDate.length > 0) {
      let temp = new Date(obj.lastPassChangeDate);
      if (temp.getFullYear() > 2000) {
        lastPassChangeDate = temp;
      }
    }
    let date_Finish = new Date(2000, 1, 1);
    if (obj.date_Finish && obj.date_Finish.length > 0) {
      let temp = new Date(obj.date_Finish);
      if (temp.getFullYear() > 2000) {
        date_Finish = temp;
      }
    }
    let date_In = new Date(2000, 1, 1);
    if (obj.date_In && obj.date_In.length > 0) {
      let temp = new Date(obj.appointment_date);
      if (temp.getFullYear() > 2000) {
        date_In = temp;
      }
    }
    let retiredDate = new Date(2000, 1, 1);
    if (obj.retired_date && obj.retired_date.length > 0) {
      let temp = new Date(obj.retired_date);
      if (temp.getFullYear() > 2000) {
        retiredDate = temp;
      }
    }
    let lockedTime = new Date(2000, 1, 1);
    if (obj.locked_time && obj.locked_time.length > 0) {
      let temp = new Date(obj.locked_time);
      if (temp.getFullYear() > 2000) {
        lockedTime = temp;
      }
    }

    this.setState(
      {
        buttonsList: this.getButtonsList(obj.id),
        dataObject: {
          id: obj.id,//
          name: obj.name,//
          jobTitle: obj.title,//
          loginName: obj.code,//

          lastLoginTime: last_date && last_date.getFullYear() > 2000 ? last_date.toLocaleDateString() : '',//
          userType: selectedUserType,//
          dataRoles: obj.permissionRoleUserList,//
          userpassword: '',//
          confirmPassword: '',//
          passwordLastModifiedDate:
          lastPassChangeDate && lastPassChangeDate.getFullYear() > 2000 ? lastPassChangeDate.toLocaleDateString() : '',//
          passwordRequired: obj.require_pass,//
          complexPassword: obj.require_pass,//
          allowChangePassword: obj.allow_change_pass,//
          mustChangePassword: obj.mustPassword,//
          minPasswordLength: obj.pass_len,//
          rememberLastPasswords: obj.lastPasswordCount,//
          passwordExpireDaysCount: obj.changePasswordAfter,
          wrongPasswordCountAllowed: obj.wrongPassCountSett,//
          wrongPasswordCount: obj.wrongPassCount,//
         // accountLockDate: lockedTime && lockedTime.getFullYear() > 2000 ? lockedTime.toLocaleDateString() : '',//??
       //   sendMsgWhenLoginFailed: obj.send_sms_failure_count,
          maxLogCount: obj.max_log,//
          logCount: obj.log_count,//
          hasExpireDate: obj.has_fdate,//
          workExpireDate: date_Finish,//
         // concurrentLoginsLimit: obj.concurrent_logins_limit,//??
         // limitDeviceAccess: obj.limit_device_access,
          accountLocked: obj.wrongPassCountSett >0  && obj.wrongPassCount >=obj.wrongPassCountSett ? true:false,//
          appointmentDate: date_In,//
          //retiredDate: date_Finish, // ??
          phone: obj.phone,//
          mobile: obj.mobile,//
          //workMobile: obj.work_mobile,//??
          extention: obj.extention,//
          email: obj.email,//
          address: obj.address,
          status: obj.status,//
        },
        fromArchive: loadFromArchive,
        mode: 'edit',
        formErrors: {},
        loading: false,
      },
      () => this.doOnLoad(obj)
    );
  };

  doOnLoad = (obj) => {
    this.txtName.focus();
    Util.goTo('../UsersAdd/' + obj.id);
  };

  onNew = (e, checkChanging) => {
    if (checkChanging && !this.state.fromArchive) {
      this.popupHasCalled();
      this.setState({ saveBoxVisible: true }, () => {
        this.saveBox.show(
          async () => {
            let res = await this.onSaveWithoutClose(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
            return res;
          },
          () => {
            this.onNew(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          () => {
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          $.strings.dataChanged
        );
      });
      return;
    }
    this.messages.clear();
    this.resetFields();
  };
  handleIntegerInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    try {
      if (value && value.length > 0 && !Util.validatePhone(value)) {
        Util.showErrorToast(this.toast, $.strings.validateNumberMessage);
        return;
      }
      this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    } catch (e) {}
  };
  onLogFile = (e, checkChanging) => {
    if (!Util.checkUserAccess(9)) {
      return;
    }
    if (checkChanging && !this.state.fromArchive) {
      this.popupHasCalled();
      this.setState({ saveBoxVisible: true }, () => {
        this.saveBox.show(
          async () => {
            let res = await this.onSaveWithoutClose(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
            return res;
          },
          () => {
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
            this.onLogFile(e, false);
          },
          () => {
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          $.strings.dataChanged
        );
      });
      return;
    }
    if (this.state.dataObject.id) {
      this.popupHasCalled();
      this.setState({ logFileDialogVisible: true });
    } else Util.showInfoMsg(this.messages, $.strings.selectRecord);
  };
  showFromLog = (obj) => {
    if (obj) {
      this.loadData(obj, true);
    }
  };
  onSimilarityCheckSuccess = () => {
    this.popupHasClosed();
    this.setState({ dlgSimilarityCheckVisible: false });
  };

  onSimilarityCheckClose = () => {
    this.popupHasClosed();
    this.setState({ dlgSimilarityCheckVisible: false });
  };
  logFileDialogClose = () => {
    this.popupHasClosed();
    this.setState({ logFileDialogVisible: false });
  };
  btnClose = (e, checkChanging) => {
    if (checkChanging && !this.state.fromArchive) {
      this.popupHasCalled();
      this.setState({ saveBoxVisible: true }, () => {
        this.saveBox.show(
          async () => {
            let res = await this.onSaveWithoutClose(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
            return res;
          },
          () => {
            this.btnClose(e, false);
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          () => {
            this.popupHasClosed();
            this.setState({ saveBoxVisible: false });
          },
          $.strings.dataChanged
        );
      });
      return;
    }
    let doRefresh = checkChanging ? false : true; // if fields changed (Yes, or no) do refresh, else nothing
    this.props.onAddClose(doRefresh);
  };

  setPageInfo = () => {
    let pageId = 0;
    let title = $.strings.users.title;

    return { pageId: pageId, title: title };
  };

  saveEditRole = () => {
    let _formErrors = this.state.formErrors;
    _formErrors.selectedRole = '';
    if (!this.state.selectedRole) {
      _formErrors.selectedRole = $.strings.requiredFiled;
      this.setState({ formErrors: _formErrors }, () => {
        let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1;
        this.grid.flex.select(rowIndex, 1);
      });
      return;
    }
    let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1;
    let dataTemp = this.state.dataObject.dataRoles;
    if (this.state.isNew) {
      // add role
      if (dataTemp && dataTemp.length > 0) {
        let roleFound = dataTemp.filter((e) => e.role_id === this.state.selectedRole.id);
        if (roleFound && roleFound.length > 0) {
          _formErrors.selectedRole = $.strings.users.roleFound;
          this.setState({ formErrors: _formErrors }, () => {
            let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1;
            this.grid.flex.select(rowIndex, 1);
          });
          return;
        }
      }
      dataTemp.push({
        ser: dataTemp.length + 1,
        id: 0,
        role_id: this.state.selectedRole.id,
        role_name: this.state.selectedRole.name,
        role_name_lang2: this.state.selectedRole.name_lang2,
      });
    } else {
      // edit role
      if (dataTemp && dataTemp.length > 0) {
        let roleFound = dataTemp.filter((e) => e.role_id === this.state.selectedRole.id);
        let roleFoundIndex = dataTemp.findIndex((e) => e.role_id === this.state.selectedRole.id);
        if (roleFound && roleFound.length > 0) {
          if (rowIndex !== roleFoundIndex) {
            _formErrors.selectedRole = $.strings.users.roleFound;
            this.setState({ formErrors: _formErrors }, () => {
              let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1;
              this.grid.flex.select(rowIndex, 1);
            });
            return;
          }
        }
      }
      dataTemp[rowIndex]['role_id'] = this.state.selectedRole.id;
      dataTemp[rowIndex]['role_name'] = this.state.selectedRole.name;
      dataTemp[rowIndex]['role_name_lang2'] = this.state.selectedRole.name_lang2;
    }
    this.setState({ dataObject: { ...this.state.dataObject, dataRoles: dataTemp }, visibleRoleDialog: false }, () => {
      let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1;
      this.grid.flex.select(rowIndex, 1);
    });
  };
  hideRoleDialog = () => {
    this.setState({ visibleRoleDialog: false }, () => {
      let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1;
      this.grid.flex.select(rowIndex, 1);
    });
  };
  editRoleWijmo = (e, ctx) => {
    let item = ctx.item;
    let index = this.grid.flex.selection.row;
    let obj = this.state.rolesList.find((r) => r.id === item.role_id);
    this.setState({ messageBoxEventArgs: { item, index }, visibleRoleDialog: true, selectedRole: obj, isNew: false }, () =>
      this.grid.flex.select(index, 1)
    );
  };
  deleteRoleWijmo = async (e, ctx, showMsg) => {
    if (showMsg) {
      let rowIndex = this.grid.flex.selection.row;
      this.setState({ messageBoxEventArgs: { item: ctx.item, index: rowIndex }, dlgOnRemoveRoleVisible: true }, () => {
        Util.gridColumnStartEditing(this.grid, rowIndex, 4, true);
      });
      return;
    }
    let index = this.state.messageBoxEventArgs.index;
    let dataTemp = this.state.dataObject.dataRoles;
    if (dataTemp && dataTemp.length > 0) {
      let role_id = dataTemp[index]['role_id'];
      let user_id = dataTemp[index]['user_id'];
      let deleteRes = this.DeleteUserRow(user_id,role_id);
      if (deleteRes) dataTemp.splice(index, 1);
      
      this.setState({ dataObject: { ...this.state.dataObject, dataRoles: dataTemp }, loading: false, dlgOnRemoveRoleVisible: false }, () => {
        Util.gridColumnStartEditing(this.grid, index, 4, true);
      });
    } else {
      this.setState({ loading: false, dlgOnRemoveRoleVisible: false }, () => {
        Util.gridColumnStartEditing(this.grid, index, 4, true);
      });
    }
  };
  DeleteUserRow = async (user_id,role_id) => {
    let param = {
      user_id: user_id,
      role_id:role_id
    };
    let res = await Http.getJsonAxios(Http.actions.Roles.urlDeleteUserRole, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      Util.showSuccessMsg(this.messages, 'تم الحذف بنجاح');
      return true;
    }
    return false;
  };
  DeleteAuthorizedDevices = async (id) => {
    let param = {
      id: id,
    };
    let res = await Http.getJsonAxios(Http.actions.AuthorizedDevices.urlDeletePermissionsAuthorizedDevices, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      Util.showSuccessMsg(this.messages, 'تم الحذف بنجاح');
      return true;
    }
    return false;
  };

  onRoleChanged = (rowIndex, roleId, roleName) => {
    let dataTemp = this.state.dataObject.dataRoles;
    if (dataTemp && dataTemp.length > 0) {
      let roleFound = dataTemp.filter((e) => e.role_id === roleId);
      if (roleFound && roleFound.length > 0) {
        Util.showErrorMsg(this.messages, $.strings.users.roleFound);
        return;
      }
    }
    dataTemp[rowIndex]['role_id'] = roleId;
    dataTemp[rowIndex]['role_name'] = roleName;
    dataTemp[rowIndex]['role_name_lang2'] = roleName;

    this.setState({ dataObject: { ...this.state.dataObject, dataRoles: dataTemp } });
  };

  getWijmoEditor = (colName) => {
    const roleEditor = new wjcInput.ComboBox(document.createElement('div'), {
      itemsSource: this.state.rolesList,
      selectedIndex: 0,
      displayMemberPath: 'name',
      selectedValuePath: 'id',
    });
    switch (colName) {
      case 'role_name':
        return roleEditor;
      default:
        return null;
    }
  };
  _getError(item, propName, parsing = false) {
    switch (propName) {
      default: {
        break;
      }
    }
    return null;
  }
  cellEditEnding = (s, e) => {
    // s: grid, e: eventArgs
    this.ValidateRoleResult = true;
    let colName = e.panel._cols[e.col]._name;
    let value = s.activeEditor.value;
    switch (colName) {
      case 'role_name': {
        if (!value || value < 0) {
          e.cancel = true;
          this.ValidateRoleResult = false;
        } else {
          let objRole = this.state.rolesList.find((r) => r.name === value || r.name_lang2 === value);
          if (objRole) {
            let dataTemp = this.state.dataObject.dataRoles;
            if (dataTemp && dataTemp.length > 0) {
              let roleFound = dataTemp.filter((e) => e.role_id === objRole.id);
              if (roleFound && roleFound.length > 0) {
                let foundIndex = dataTemp.findIndex((e) => e.role_id === objRole.id);
                if (e.row !== foundIndex && foundIndex > -1) {
                  Util.showErrorMsg(this.messages, $.strings.users.roleFound);
                  e.cancel = true;
                  e.stayInEditMode = true;
                  this.ValidateRoleResult = false;
                }
              }
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };
  cellEditEnded = (s, e) => {
    // s: grid, e: eventArgs
    let colName = e.panel._cols[e.col]._name;
    let value = s.getCellData(e.row, e.col);
    let dataTemp = this.state.dataObject.dataRoles;
    switch (colName) {
      case 'role_name': {
        let obj = this.state.rolesList.find((e) => e.name + '' === value + '' || e.name_lang2 + '' === value);
        if (dataTemp && obj) {
          dataTemp[e.row]['role_id'] = obj.id;
          dataTemp[e.row]['role_name'] = obj.name;
          dataTemp[e.row]['role_name_lang2'] = obj.name_lang2;
          this.setState({ dataObject: { ...this.state.dataObject, dataRoles: dataTemp } }, () => {
            this.addRole();
          });
        }
        break;
      }
      default: {
        break;
      }
    }
    return;
  };

  // الأجهزة الموثوقة
  _getErrorAuthorizedDevices(item, propName, parsing = false) {
    switch (propName) {
      default: {
        break;
      }
    }
    return null;
  }
  cellEditEndingDevices = (s, e) => {
    // s: grid, e: eventArgs
    let colName = e.panel._cols[e.col]._name;
    let value = s.activeEditor.value;
    switch (colName) {
      case 'device_name': {
        if (!value || value < 0) {
          e.cancel = true;
          e.stayInEditMode = true;
        } else {
          let dataTemp = this.state.dataObject.dataAuthorizedDevices;
          if (dataTemp && dataTemp.length > 0) {
            let deviceFound = dataTemp.filter((e) => e.device_name === value);
            let foundIndex = dataTemp.findIndex((e) => e.device_name === value);
            if (deviceFound && deviceFound.length > 0) {
              if (e.row !== foundIndex && foundIndex > -1) {
                Util.showErrorMsg(this.messages, $.strings.users.deviceNameError);
                e.cancel = true;
                e.stayInEditMode = true;
                return;
              }
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  cellEditEndedDevices = (s, e) => {
    // s: grid, e: eventArgs
    let colName = e.panel._cols[e.col]._name;
    let value = s.getCellData(e.row, e.col);
    let dataTemp = this.state.dataObject.dataAuthorizedDevices;
    switch (colName) {
      case 'device_name': {
        if (value && value.length > 0) {
          dataTemp[e.row]['device_name'] = value;
          let colIndex = e.col;
          if (e.col !== dataTemp.length - 1) colIndex = colIndex + 1;
          this.setState({ dataObject: { ...this.state.dataObject, dataAuthorizedDevices: dataTemp } }, () =>
            Util.gridColumnStartEditing(this.gridAuthorizedDevices, e.row, colIndex, false)
          );
        }
        break;
      }
      default: {
        break;
      }
    }
    return;
  };


  onIsAllowedChanged = (rowIndex, value) => {
    let dataTemp = this.state.dataObject.dataAuthorizedDevices;
    if (dataTemp) {
      dataTemp[rowIndex].is_allowed = value;
      if (dataTemp[rowIndex]['id'] > 0) {
        dataTemp[rowIndex]['isEdited'] = true;
      }
      this.setState({ dataObject: { ...this.state.dataObject, dataAuthorizedDevices: dataTemp } });
    }
  };
  handleUserInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
  };
  validateField() {
    let fieldValidationErrors = this.state.formErrors;

    let _name = this.state.dataObject.name;
    fieldValidationErrors.name = _name ? '' : $.strings.requiredFiled;

    let _loginName = this.state.dataObject.loginName;
    fieldValidationErrors.loginName = _loginName ? '' : $.strings.requiredFiled;
    let _minPasswordLength = this.state.dataObject.minPasswordLength;
    fieldValidationErrors.minPasswordLength = _minPasswordLength ? '' : $.strings.users.minPasswordLengthError;
    if (fieldValidationErrors.minPasswordLength && fieldValidationErrors.minPasswordLength.length > 0) {
      Util.showErrorMsg(this.messages, fieldValidationErrors.password);
    }

    let _emailValid = true;
    if (this.state.dataObject.email && this.state.dataObject.email.length > 0) {
      if (!Util.checkEmailFormat(this.state.dataObject.email)) {
        _emailValid = false;
        Util.showErrorMsg(this.messages, $.strings.users.emailError);
      }
    }

    let _password = true;
    if (this.state.dataObject.id <= 0 && this.state.dataObject.passwordRequired) {
      let _password = this.state.dataObject.password;
      fieldValidationErrors.password = _password ? '' : $.strings.requiredFiled;
      if (_password && _password.length < this.state.dataObject.minPasswordLength) {
        fieldValidationErrors.password = $.strings.users.passwordLengthError + ' ' + this.state.dataObject.minPasswordLength;
        Util.showErrorMsg(this.messages, fieldValidationErrors.password);
      }
      if (this.state.dataObject.complexPassword && !Util.checkComplexPassword(this.state.dataObject.password)) {
        Util.showErrorMsg(this.messages, $.strings.users.complexPasswordError);
      }
    }

    if (this.state.dataObject.password !== this.state.dataObject.confirmPassword) {
      fieldValidationErrors.confirmPassword = $.strings.users.confirmPasswordError;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        nameValid: _name,
        loginNameValid: _loginName,
        minPasswordLengthValid: _minPasswordLength,
        passwordValid: _password,
        emailValid: _emailValid,
      },
      this.validateForm
    );
    return _name && _loginName && _minPasswordLength && _password && _emailValid;
  }
  validateForm() {
    let isFormValid =
      this.state.nameValid &&
      this.state.otherNameValid &&
      this.state.departmentValid &&
      this.state.loginNameValid &&
      this.state.minPasswordLengthValid &&
      this.state.passwordValid &&
      this.state.emailValid;
    this.setState({ formValid: isFormValid });
    return isFormValid;
  }
  getSchemeRolesWijmo = () => {
    let scheme = {
      name: 'rolesScheme_Table',
      filter: false,
      showFooter: false,
      sortable: false,
      allowGrouping: false,
      responsiveColumnIndex: 4,
      columns: [
        {
          name: 'ser',
          header: '##',
          width: 50,
          dataType: 'Number',
          isReadOnly: true,
        },
        {
          name: 'btnEdit',
          header: ' ',
          width: 50,
          buttonBody: 'button',
          align: 'center',
          title: $.strings.edit,
          iconType: 'edit',
          className: 'warning',
          visible: !this.state.fromArchive,
          visibleInColumnChooser: false,
          onClick: (e, ctx) => {
            this.editRoleWijmo(e, ctx);
          },
        },
        {
          name: 'user_id',
          header: 'user_id',
          visible: false,
          visibleInColumnChooser: false,
          isReadOnly: true,
        },
        {
          name: 'role_id',
          header: 'role_id',
          visible: false,
          visibleInColumnChooser: false,
          isReadOnly: true,
        },
        {
          name: 'role_name',
          header: $.strings.users.role,
          width: '*',
          minWidth: 120,
          isReadOnly: this.state.fromArchive || !this.state.isDesktop ? true : false,
          editor: this.state.fromArchive ? null : this.getWijmoEditor('role_name'),
          isRequired: true,
        },
        {
          name: 'btnDelete',
          header: ' ',
          width: 70,
          buttonBody: 'button',
          align: 'center',
          title: $.strings.delete,
          iconType: 'delete',
          className: 'danger',
          isReadOnly: true,
          onClick: (e, ctx) => this.deleteRoleWijmo(e, ctx, true),
          visible: !this.state.fromArchive,
        },
      ],
    };
    return scheme;
  };
}
