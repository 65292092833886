import React from "react";
import styles from './Shifts.module.scss'
import Input from "../../../Components/Input";
import ButtonsBarLookup from '../../../Components/ButtonsBarLookup'
import Messages from '../../../Components/Messages'
import http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import MessageBox from '../../../Components/MessageBox'
import DeleteNoteDialog from '../../../Components/DeleteNoteDialog'
import Util from '../../../Util'
import Button from '../../../Components/Button';
import Dropdown from '../../../Components/Dropdown';
import Card from '../../../Components/Card'
import InputTextArea from '../../../Components/InputTextArea';
import Calendar from '../../../Components/Calendar'
import SiteMap from '../../../Components/SiteMap';
import ScrollTop from '../../ScrollButton';
import InputTime from "../../../Components/InputTime/InputTime";
import SelectButton from "../../../Components/SelectButton";
import SCC from "../../ScreenCommonCode";
import SearchEmployees from "../../Employees/SearchEmployees";
import DataGridView from "../../../Components/DataGridView/DataGridView";
import ShiftDistribute from "./ShiftDistribute";
import ShiftsSearch from "./ShiftsSearch";
import { isInteger } from "lodash";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class Shifts extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.nowDate = new Date()
        this.fromDate = new Date(new Date().getFullYear(), 0, 1);
        this.toDate = new Date(new Date().getFullYear(), 11, 31);
        this.shiftOptions = [$.strings.shifts.employeeShift]
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: {},
            deleteErrors: { deleteNote: '' },
            isDesktop: window.innerWidth > 1250,
            shiftOption: $.strings.shifts.employeeShift,
            data: [],
            disableDistributePeriod: true,
            dataObject: {
                id: 0,
                empCode: undefined,
                empName: undefined,
                shiftCode: undefined,
                shiftName: undefined,
                fromDate: this.fromDate,
                toDate: this.toDate
            }
        }
    }


    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(611)) {
            Util.goTo('/Access');
            return;
        }
        if (Util.checkISHR()) {
            this.shiftOptions.push($.strings.shifts.generalShift)
        }
        let _isDesktop = window.innerWidth > 1250;
        this.setState({ isDesktop: _isDesktop, loading: false });
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    validateField() {

        let result = true;
        let fieldValidationErrors = this.state.formErrors;

        if (this.state.shiftOption === $.strings.shifts.employeeShift) {
            fieldValidationErrors.empCode = "";
            if (!this.state.dataObject.empCode || this.state.dataObject.empCode.length <= 0) {
                fieldValidationErrors.empCode = $.strings.requiredFiled;
                result = false;
            }

            fieldValidationErrors.empName = "";
            if (!this.state.dataObject.empName || this.state.dataObject.empName.length <= 0) {
                fieldValidationErrors.empName = $.strings.requiredFiled;
                result = false;
            }
        }
        else {
            fieldValidationErrors.shiftCode = "";
            if (!this.state.dataObject.shiftCode || this.state.dataObject.shiftCode.length <= 0) {
                fieldValidationErrors.shiftCode = $.strings.requiredFiled;
                result = false;
            }

            fieldValidationErrors.shiftName = "";
            if (!this.state.dataObject.shiftName || this.state.dataObject.shiftName.length <= 0) {
                fieldValidationErrors.shiftName = $.strings.requiredFiled;
                result = false;
            }
        }

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.shifts.dataError);
            result = false;
        }
        else {
            if (!this.state.data[0]["period"] || this.state.data[0]["period"] + "" === "") {
                Util.showErrorMsg(this.messages, $.strings.shifts.dataError);
                result = false;
            }
        }


        fieldValidationErrors.fromDate = "";
        if (!this.state.dataObject.fromDate || this.state.dataObject.fromDate.toString().length <= 0) {
            fieldValidationErrors.fromDate = $.strings.requiredFiled;
            result = false;
        }

        fieldValidationErrors.toDate = "";
        if (!this.state.dataObject.toDate || this.state.dataObject.toDate.toString().length <= 0) {
            fieldValidationErrors.toDate = $.strings.requiredFiled;
            result = false;
        }

        this.setState({ formErrors: fieldValidationErrors });
        return result;
    }


    render() {

        let siteMapItems = [
            { label: $.strings.links.shifts.menu },
            {
                label: <div>
                    {$.strings.shifts.title}
                </div>
            }
        ];

        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" className={styles.addWorkLeave}>
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-md-12" >
                            <SiteMap model={siteMapItems} home={siteMapHome} />
                            <ButtonsBarLookup
                                onNew={e => this.resetFields()}
                                onSave={e => this.onSave()}
                            />
                            <Messages innerRef={(el) => this.messages = el} />
                        </div>
                    </div>
                </Card>

                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnPrevVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onPrevious(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNextVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNext(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnFirstVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onFirst(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnLastVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onLast(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnDeleteVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onDelete(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloneVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onClone(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloseVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.btnClose(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />

                <DeleteNoteDialog ref={ref => this.deleteNoteDlg = ref} visible={this.state.dlgDeleteNoteVisible} deleteErrors={this.state.deleteErrors} appendTo={this.props.parent}
                    onYes={this.btnDeleteYes} onNo={this.DeleteCancel} deleteNote='' />

                <SearchEmployees ref={e => this.dlgSearchEmployees = e} onSuccess={this.onSearchEmployeeSuccess} />
                <ShiftsSearch ref={e => this.dlgSearchShifts = e} onSuccess={this.onSearchShiftsSuccess} />
                <ShiftDistribute ref={e => this.dlgShiftDistribute = e} onSuccess={this.onShiftDistribute} />
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <SelectButton value={this.state.shiftOption} options={this.shiftOptions} onChange={(e) => this.setState({ shiftOption: e.value })}
                                tooltip={$.strings.shifts.shiftOption} tooltipOptions={{ position: 'top' }} unselectable={false} />
                        </div>
                    </div>
                    {
                        this.state.shiftOption === $.strings.shifts.employeeShift &&
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Input
                                    id="empCode"
                                    maxLength="50"
                                    value={this.state.dataObject.empCode}
                                    autofocus={true}
                                    caption={$.strings.shifts.employee}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    withgroup={true}
                                    btn1event={e => this.onEmployeesSearch()}
                                    btn1icon="search"
                                    onBlur={e => this.getEmployee()}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                            <div className="p-col-12 p-lg-4 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Input
                                    id="empName"
                                    maxLength="50"
                                    value={this.state.dataObject.empName}
                                    autofocus={true}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    disabled
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                    }
                    {
                        this.state.shiftOption === $.strings.shifts.generalShift && Util.checkISHR() &&
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Input
                                    id="shiftCode"
                                    maxLength="50"
                                    value={this.state.dataObject.shiftCode}
                                    autofocus={true}
                                    caption={$.strings.shifts.shift}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    withgroup={true}
                                    btn1event={e => this.onShiftsSearch()}
                                    btn1icon="search"
                                    onBlur={e => this.getGeneralShift()}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                            <div className="p-col-12 p-lg-4 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Input
                                    id="shiftName"
                                    maxLength="50"
                                    value={this.state.dataObject.shiftName}
                                    autofocus={true}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    disabled
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                    }
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.dataObject.fromDate}
                                caption={$.strings.shifts.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.dataObject.toDate}
                                caption={$.strings.shifts.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                            <Button label={$.strings.refresh} onClick={async e => await this.getShift()}>
                                &nbsp;&nbsp;
                                <i className="pi pi-sync" />
                            </Button>
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                            <Button label={$.strings.shifts.distributePeriod} onClick={e => this.distributeShift()} disabled={this.state.disableDistributePeriod}>
                                &nbsp;&nbsp;
                                <i className="pi pi-clock" />
                            </Button>
                        </div>
                    </div>
                </Card>

                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGridView
                            // style={{ minHeight: this.state.isDesktop ? '40vh' : '100vh', height: this.state.isDesktop ? '40vh' : '100vh' }}
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.state.isDesktop ? this.getScheme() : this.getSchemeMobile()}
                            columnGroupsList={this.getScheme().columns}
                            exportFileName={$.strings.shifts.title}
                            dataSource={this.state.data}
                            defaultRowHeight={45}
                        />
                    </div>
                </div>

                <ScrollTop />
            </div>
        );
    }

    onEmployeesSearch = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
                async () => { await this.onSave(e, false); this.onEmployeesSearch(e, false) },
                () => this.onEmployeesSearch(e, false)
            );
            return;
        }
        this.dlgSearchEmployees.show();
    }
    onSearchEmployeeSuccess = (obj) => {
        if (obj) {
            this.setState({ dataObject: { ...this.state.dataObject, empCode: obj.code, empName: obj.name } });
        }
    }
    getEmployee = async () => {
        if (!this.state.dataObject.empCode || this.state.dataObject.empCode.length < 0) {
            this.setState({ dataObject: { ...this.state.dataObject, empCode: '', empName: '' }, loading: false });
            return;
        }
        this.setState({ loading: true });
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            isHr: Util.checkISHR(),
            empCode: Util.encryptStringToHexa(this.state.dataObject.empCode)
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeInfo, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployeeInfoObj;
            if (dataTemp) {
                this.setState({ dataObject: { ...this.state.dataObject, empCode: dataTemp.Code, empName: dataTemp.Name }, loading: false });
                return;
            }
            else {
                Util.showInfoMsg(this.messages, $.strings.shifts.employeeNotFound)
                this.setState({
                    dataObject: {
                        ...this.state.dataObject, empCode: '', empName: '', disableDistributePeriod: true
                    }, loading: false
                })
            }
        }
        else {
            this.setState({ loading: false, disableDistributePeriod: true });
        }
    }
    getGeneralShift = async () => {
        if (!this.state.dataObject.shiftCode || this.state.dataObject.shiftCode.length < 0) {
            this.setState({ dataObject: { ...this.state.dataObject, shiftCode: '', shiftName: '' }, loading: false });
            return;
        }
        this.setState({ loading: true });
        let obj = {
            code: this.state.dataObject.shiftCode,
            fromDate: this.state.dataObject.fromDate ? new Date(this.state.dataObject.fromDate) : new Date()
        }

        let res = await http.getJsonAxios(http.actions.Shifts.urlGetGeneralShift, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                this.setState({ dataObject: { ...this.state.dataObject, shiftCode: res.data.code, shiftName: res.data.name }, loading: false });
                return;
            }
            else {
                Util.showErrorMsg(this.messages, $.strings.shifts.shiftNotFound)
                this.setState({
                    dataObject: {
                        ...this.state.dataObject, shiftCode: '', shiftName: '', disableDistributePeriod: true
                    }, loading: false
                })
            }
        }
        else {
            this.setState({ loading: false, disableDistributePeriod: true });
        }
    }

    onShiftsSearch = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
                async () => { await this.onSave(e, false); this.onShiftsSearch(e, false) },
                () => this.onShiftsSearch(e, false)
            );
            return;
        }
        this.dlgSearchShifts.show();
    }
    onSearchShiftsSuccess = (obj) => {
        if (obj) {
            this.setState({
                dataObject: {
                    ...this.state.dataObject, shiftCode: obj.code, shiftName: obj.shiftName,
                    empCode: undefined, empName: undefined
                }
            });
        }
    }

    getShift = async () => {
        if (this.state.shiftOption === $.strings.shifts.employeeShift && (!this.state.dataObject.empCode || this.state.dataObject.empCode.length < 0)) {
            this.setState({ dataObject: { ...this.state.dataObject, empCode: '', empName: '' }, loading: false });
            return;
        }
        else if (this.state.shiftOption === $.strings.shifts.generalShift && (!this.state.dataObject.shiftCode || this.state.dataObject.shiftCode.length < 0)) {
            this.setState({ dataObject: { ...this.state.dataObject, shiftCode: '', shiftName: '' }, loading: false });
            return;
        }
        this.setState({ loading: true });
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            code: this.state.shiftOption === $.strings.shifts.employeeShift ? this.state.dataObject.empCode : this.state.dataObject.shiftCode,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            empShift: this.state.shiftOption === $.strings.shifts.employeeShift ? true : false,
            isDirector: Util.checkISManager()
        }

        let res = await http.getJsonAxios(http.actions.Shifts.urlGetShift, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.list;
                if (dataTemp) {
                    dataTemp.forEach(element => {
                        element.date = new Date(element.date);
                    });
                    this.setState({ data: dataTemp, loading: false, disableDistributePeriod: false });
                    return;
                }
            }
            else {
                Util.showInfoMsg(this.messages, $.strings.shifts.shiftNotFound)
                this.setState({ data: [], loading: false })
            }
        }
        else {
            this.setState({ loading: false });
        }
    }

    distributeShift = () => {
        this.dlgShiftDistribute.show();
    }

    onShiftDistribute = (obj) => {
        if (obj) {
            let dataTemp = this.state.data;
            if (dataTemp) {
                for (let i = 0; i < dataTemp.length; i++) {
                    if (obj.saturday && dataTemp[i]["day"] === $.strings.weekDays.saturday) {
                        dataTemp = this.setRowData(dataTemp, i, obj);
                    }
                    else if (obj.sunday && dataTemp[i]["day"] === $.strings.weekDays.sunday) {
                        dataTemp = this.setRowData(dataTemp, i, obj);
                    }
                    else if (obj.monday && dataTemp[i]["day"] === $.strings.weekDays.monday) {
                        dataTemp = this.setRowData(dataTemp, i, obj);
                    }
                    else if (obj.tuesday && dataTemp[i]["day"] === $.strings.weekDays.tuesday) {
                        dataTemp = this.setRowData(dataTemp, i, obj);
                    }
                    else if (obj.wednesday && dataTemp[i]["day"] === $.strings.weekDays.wednesday) {
                        dataTemp = this.setRowData(dataTemp, i, obj);
                    }
                    else if (obj.thursday && dataTemp[i]["day"] === $.strings.weekDays.thursday) {
                        dataTemp = this.setRowData(dataTemp, i, obj);
                    }
                    else if (obj.friday && dataTemp[i]["day"] === $.strings.weekDays.friday) {
                        dataTemp = this.setRowData(dataTemp, i, obj);
                    }
                }

                this.setState({ data: dataTemp }, () => this.gridData.flex.collectionView.refresh());
            }
        }
    }
    setRowData = (dataTemp, rowIndex, obj) => {
        dataTemp[rowIndex]["period"] = parseInt(obj.id) + 1;
        dataTemp[rowIndex]["fromTime"] = obj.fromTime;
        dataTemp[rowIndex]["toTime"] = obj.toTime;
        if (Util.checkISHR()) {
            if (obj.beforeIn instanceof Date)
                dataTemp[rowIndex]["ablimit1"] = (obj.beforeIn.getHours() * 60) + obj.beforeIn.getMinutes();
            else {
                let date = new Date(obj.beforeIn);
                dataTemp[rowIndex]["ablimit1"] = (date.getHours() * 60) + date.getMinutes();
            }
            if (obj.afterIn instanceof Date)
                dataTemp[rowIndex]["ablimit2"] = (obj.afterIn.getHours() * 60) + obj.afterIn.getMinutes();
            else {
                let date = new Date(obj.afterIn);
                dataTemp[rowIndex]["ablimit2"] = (date.getHours() * 60) + date.getMinutes();
            }
            if (obj.beforeOut instanceof Date)
                dataTemp[rowIndex]["ablimit3"] = (obj.beforeOut.getHours() * 60) + obj.beforeOut.getMinutes();
            else {
                let date = new Date(obj.beforeOut);
                dataTemp[rowIndex]["ablimit3"] = (date.getHours() * 60) + date.getMinutes();
            }
            if (obj.afterOut instanceof Date)
                dataTemp[rowIndex]["ablimit4"] = (obj.afterOut.getHours() * 60) + obj.afterOut.getMinutes();
            else {
                let date = new Date(obj.afterOut);
                dataTemp[rowIndex]["ablimit4"] = (date.getHours() * 60) + date.getMinutes();
            }
        }
        return dataTemp;
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true })
        if (this.state.shiftOption === $.strings.shifts.employeeShift) {
            await this.saveEmpShifts();
        }
        else {
            await this.saveGeneralShifts();
        }
        this.setState({ loading: false })
    }

    saveEmpShifts = async () => {
        let paramsList = []
        if (this.state.data) {
            for (let i = 0; i < this.state.data.length; i++) {
                paramsList.push({
                    Periods: this.state.data[i]["period"] ? parseInt(this.state.data[i]["period"]) : -1,
                    Ablimit1: this.state.data[i]["ablimit1"],
                    Ablimit2: this.state.data[i]["ablimit2"],
                    Ablimit3: this.state.data[i]["ablimit3"],
                    Ablimit4: this.state.data[i]["ablimit4"],
                    Code: this.state.dataObject.empCode,
                    ShiftDate: '',
                    ShiftDate_date: this.state.data[i]["date"]
                });
            }
        }
        let obj = {
            empCode: this.state.dataObject.empCode,
            empName: this.state.dataObject.empName,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            paramsList: paramsList
        }

        let res = await http.postMultipartAxios(http.actions.Shifts.urlPostSaveEmpShift, obj, null, null, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data && res.data.errorText)
                    msg = res.data.errorText;
                Util.showErrorMsg(this.messages, msg);
                this.setState({ loading: false })
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.errorText)
                msg = res.data.errorText;
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false })
        }
    }

    saveGeneralShifts = async () => {
        let paramsList = []
        if (this.state.data) {
            for (let i = 0; i < this.state.data.length; i++) {
                paramsList.push({
                    Periods: this.state.data[i]["period"] ? parseInt(this.state.data[i]["period"]) : -1,
                    Ablimit1: this.state.data[i]["ablimit1"],
                    Ablimit2: this.state.data[i]["ablimit2"],
                    Ablimit3: this.state.data[i]["ablimit3"],
                    Ablimit4: this.state.data[i]["ablimit4"],
                    Code: this.state.dataObject.shiftCode,
                    ShiftDate: '',
                    ShiftDate_date: this.state.data[i]["date"]
                });
            }
        }
        let obj = {
            empCode: this.state.dataObject.shiftCode,
            empName: this.state.dataObject.shiftName,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            paramsList: paramsList
        }

        let res = await http.postMultipartAxios(http.actions.Shifts.urlPostSaveGeneralShift, obj, null, null, 0);

        if (res.status === 200) {
            if (res.data && res.data.success) {
                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data && res.data.errorText)
                    msg = res.data.errorText;
                Util.showErrorMsg(this.messages, msg);
                this.setState({ loading: false })
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.errorText)
                msg = res.data.errorText;
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false })
        }
    }

    resetFields = () => {
        let shiftCode = "";
        if (this.state.shiftOption === $.strings.shifts.generalShift) {
            // get Max Code for general Shift
            shiftCode = "";
        }
        this.setState({
            data: [], formErrors: {}, /*shiftOption: $.strings.shifts.employeeShift,*/
            disableDistributePeriod: true,
            dataObject: {
                id: 0,
                empCode: "",
                empName: "",
                shiftCode: "",
                shiftName: "",
                fromDate: this.fromDate,
                toDate: this.toDate
            }, loading: false
        })
    }

    getScheme = () => {
        return {
            name: "shiftsScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                { name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', sortable: false },
                {
                    name: 'date', header: $.strings.shifts.date, dataType: 'Date', format: Util.gridDateFormat(),
                    width: this.state.isDesktop ? 100 : '*', sortable: false
                },
                { name: 'day', header: $.strings.shifts.day, width: 100, sortable: false },
                { name: 'period', header: $.strings.shifts.period, width: this.state.isDesktop ? 200 : "*", sortable: false },
                {
                    header: $.strings.shifts.period, align: 'center', columns: [
                        { name: 'fromTime', header: $.strings.shifts.from, width: 150, sortable: false },
                        { name: 'toTime', header: $.strings.shifts.to, width: 150, sortable: false },
                    ]
                },
                {
                    header: $.strings.shifts.rest, align: 'center', columns: [
                        { name: 'ablimit1', header: $.strings.shifts.ablimit1, width: 150, sortable: false },
                        { name: 'ablimit2', header: $.strings.shifts.ablimit2, width: 150, sortable: false },
                        { name: 'ablimit3', header: $.strings.shifts.ablimit3, width: 150, sortable: false },
                        { name: 'ablimit4', header: $.strings.shifts.ablimit4, width: 150, sortable: false }
                    ]
                }
            ]
        }
    }
    getSchemeMobile = () => {
        return {
            name: "shiftsScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 1,
            columns: [
                { name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', sortable: false },
                {
                    name: 'date', header: $.strings.shifts.date, dataType: 'Date', width: 100, sortable: false,
                    format: Util.gridDateFormat()
                },
                { name: 'day', header: $.strings.shifts.day, width: 100, sortable: false },
                { name: 'fromTime', header: $.strings.shifts.from, width: 150, sortable: false },
                { name: 'toTime', header: $.strings.shifts.to, width: 150, sortable: false },
                { name: 'ablimit1', header: $.strings.shifts.ablimit1, width: 150, sortable: false },
                { name: 'ablimit2', header: $.strings.shifts.ablimit2, width: 150, sortable: false },
                { name: 'ablimit3', header: $.strings.shifts.ablimit3, width: 150, sortable: false },
                { name: 'ablimit4', header: $.strings.shifts.ablimit4, width: 150, sortable: false }
            ]
        }
    }
}