import React from 'react'
import { DataTable as PrimeDataTable } from 'primereact/datatable'
import styles from './DataTable.module.scss'
import * as tableHelper from './DataTableHelper'

const $ = window.$;

export default class PrintableDataTable extends React.Component {

    static defaultProps = {
        expandLastColumn: true,
        parent: () => document.getElementById('printArea'),
    }

    componentDidMount() {
        this.makeTablePrintable();
        $.finishResize();
    }

    render() {
        $.requestResize(1);
        let props = this.props
        let { scheme, value } = props

        this.columnsMinWidths = []
        this.columnsMaxWidths = []
        this.columnsImportant = []
        scheme.columns.forEach(column => {
            let style = column.style || {}
            style.fontSize = '100%'
            this.columnsMaxWidths.push(style.maxWidth)
            this.columnsMinWidths.push(style.minWidth)
            this.columnsImportant.push(column.important)
            style.width = ''
        })

        let groupField = this.getGroupField()
        let multiSort = tableHelper.getMultiSort(scheme, props)
        let columns = tableHelper.getColumns(scheme, value, groupField)
        if (columns) {
            columns = columns.filter(function (obj) {
                return obj.props.rowReorder !== true;
            });
        }
        let filters = tableHelper.getFilters(scheme)

        return (
            <div
                ref={ref => this.container = ref}
                style={{ display: "table" }}>
                <PrimeDataTable
                    value={value}
                    header={props.header}
                    footer={props.footer}
                    style={props.style}
                    className={props.className}
                    tableStyle={props.tableStyle}
                    tableClassName={props.tableClassName}
                    totalRecords={props.totalRecords}
                    sortField={props.sortField || scheme.sortField}
                    sortOrder={props.sortOrder || scheme.sortOrder}
                    multiSortMeta={multiSort.multiSortMeta}
                    sortMode={multiSort.sortMode}
                    defaultSortOrder={props.defaultSortOrder}
                    emptyMessage={props.emptyMessage || $.strings.emptyRowMessage}
                    selection={null}
                    dataKey={props.dataKey}
                    headerColumnGroup={props.headerColumnGroup}
                    footerColumnGroup={props.footerColumnGroup}
                    frozenHeaderColumnGroup={props.frozenHeaderColumnGroup}
                    frozenFooterColumnGroup={props.frozenFooterColumnGroup}
                    expandedRows={props.expandedRows}
                    filters={filters}
                    globalFilter={props.globalFilter}
                    frozenWidth={props.frozenWidth}
                    frozenValue={props.frozenValue}
                    rowGroupMode={groupField ? (props.rowGroupMode || tableHelper.defaultValues.rowGroupMode) : (scheme.mergeField && 'rowspan')}
                    autoLayout={props.autoLayout}
                    groupField={groupField}
                    rowExpansionTemplate={props.rowExpansionTemplate}
                    rowClassName={props.rowClassName}
                    rowGroupHeaderTemplate={groupField ? (props.rowGroupHeaderTemplate || this.rowGroupHeaderTemplate) : undefined}
                    rowGroupFooterTemplate={groupField ? (props.rowGroupFooterTemplate || this.rowGroupFooterTemplate) : undefined}
                >
                    {
                        columns
                    }
                </PrimeDataTable>
            </div>
        )
    }

    rowGroupHeaderTemplate = (firstRowInGroup, index) => {
        let groupField = this.getGroupField();

        if (index === 0) {
            this.groupedData = this.props.value.reduce((a, b) => {
                let groupValue = b[groupField];
                a[groupValue] = a[groupValue] || [];
                a[groupValue].push(b);
                return a;
            }, {})
        }

        let groupColumn = this.props.scheme.columns.find(c => c.field === groupField)
        return (
            <div className={styles.collapsRows}>
                {`${groupColumn.header}: ${firstRowInGroup[groupColumn.field]}`}
            </div>
        );
    }

    rowGroupFooterTemplate = (lastRowInGroup, index) => {
        let columns = this.getVisibleColumns().sort((a, b) => a.position - b.position);
        if (columns.filter(c => c.sigma).length === 0)
            return null;

        let groupField = this.getGroupField();
        let groupValue = lastRowInGroup[groupField];
        let data = this.groupedData[groupValue]

        let footer = columns.map(c => {
            let sigma = c.sigma;
            let key = groupValue + c.field
            if (!sigma)
                return <td key={key}></td>
            return (
                <td key={key}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        {sigma.avg && <span>{$.strings.sigmaOptions.average}: {tableHelper.getAvg(c, data)}</span>}
                        {sigma.count && <span>{$.strings.sigmaOptions.count}: {tableHelper.getCount(c, data)}</span>}
                        {sigma.max && <span>{$.strings.sigmaOptions.maximum}: {tableHelper.getMax(c, data)}</span>}
                        {sigma.min && <span>{$.strings.sigmaOptions.minimum}: {tableHelper.getMin(c, data)}</span>}
                        {sigma.sum && <span>{$.strings.sigmaOptions.sum}: {tableHelper.getSum(c, data)}</span>}
                    </div>
                </td>
            )
        })
        return footer;
    }

    getGroupField = () => this.props.groupField || this.props.scheme.groupField

    getVisibleColumns = () => this.props.scheme.columns.filter(c => !c.hidden && c.field !== this.getGroupField())

    makeTablePrintable = () => {
        /*
        [x] disable wrap
        [x] set max font size
        [x] set min/max width for each column and set warp enabled for them
        [x] reduce font untill table width less than parent or smallest font reached
        [ ] if smallest font not enough then enable wrap and shrink long columns
        [x] find available extra space and distribute it among important columns and last column if enabled
         */
        let printArea = this.props.parent();
        let table = this.container;
        let maxFontSize = 14;
        let printSettings = localStorage.getItem("printSettings");
        if (printSettings) {
            printSettings = JSON.parse(printSettings);
            maxFontSize = printSettings.fontSize.value;
        }
        let minFontSize = 6;
        let fontSize = maxFontSize;

        table.style.fontSize = fontSize + 'px';

        let columns = table.querySelectorAll('thead tr th');

        let w = this.getTableAndPrintAreaWidths(table, printArea)
        while ((fontSize > minFontSize) && (w.totalTableWidth > w.printAreaWidth)) {

            fontSize--;
            table.style.fontSize = fontSize + 'px';
            w = this.getTableAndPrintAreaWidths(table, printArea);
        }

        //to make scoping css that can dropped after print
        let randomId = (Math.random() + 1).toString(36).substring(7);

        let scopedStylesheetRules = [];
        for (let i = 0; i < columns.length; i++) {
            columns[i].style.maxWidth = this.columnsMaxWidths[i] || '';
            columns[i].style.minWidth = this.columnsMinWidths[i] || '';
            if (this.columnsMaxWidths[i] && columns[i].getBoundingClientRect().width === this.columnsMaxWidths[i].replace("px", "")) {
                columns[i].style.width = this.columnsMaxWidths[i];
                scopedStylesheetRules[i] = `#${randomId} td:nth-child(${i + 1}) { white-space: normal;}`
            }
        }

        let importantColumns = [];
        for (let i = 0; i < columns.length; i++) {
            if (this.columnsImportant[i]) {
                importantColumns.push(columns[i]);
                scopedStylesheetRules[i] = `#${randomId} td:nth-child(${i + 1}) { white-space: normal;}`;
            }
        }

        let isLastColumnImportant = this.columnsImportant[this.columnsImportant.length - 1];
        if (this.props.expandLastColumn && !isLastColumnImportant) {
            importantColumns.push(columns[columns.length - 1]);
            scopedStylesheetRules[columns.length - 1] = `#${randomId} td:nth-child(${columns.length}) { white-space: normal;}`;
        }

        let scopedStylesheet = document.createElement('style');
        scopedStylesheet.type = 'text/css';
        scopedStylesheet.appendChild(document.createTextNode(scopedStylesheetRules.filter(s => s).join("\n")));
        let body = table.querySelector('tbody');
        body.setAttribute('id', randomId);
        body.insertBefore(scopedStylesheet, body.firstChild);

        w = this.getTableAndPrintAreaWidths(table, printArea);
        let extraSpace = w.printAreaWidth - w.totalTableWidth;
        if (extraSpace > 0) {
            let extraWidth = Math.floor(extraSpace / importantColumns.length);
            for (let i = 0; i < importantColumns.length; i++) {
                importantColumns[i].style.width = (importantColumns[i].getBoundingClientRect().width + extraWidth) + "px";
            }
        }
    }

    getTableAndPrintAreaWidths = (table, printArea) => {
        let printAreaRight = printArea.getBoundingClientRect().right;
        let printAreaLeft = printArea.getBoundingClientRect().left;
        let tableRight = table.getBoundingClientRect().right;
        let tableLeft = table.getBoundingClientRect().left;
        let rightSpace = Math.max(printAreaRight - tableRight, 0);
        let leftSpace = Math.max(printAreaLeft - tableLeft, 0);
        let tableWidth = table.clientWidth;
        let printAreaWidth = printArea.clientWidth;
        let totalTableWidth = tableWidth + rightSpace + leftSpace;
        return { totalTableWidth, printAreaWidth }
    }
}