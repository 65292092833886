import React from "react";
//import DataGrid from '../../Components/DataGrid/DataGrid';
import DataGrid from '../../Components/DataGridView/DataGridView';
import Button from '../../Components/Button';

const $ = window.$;
export default class AttReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.getData()
        };
    }

    componentDidMount() {
    }

    print = () => {
        this.dtData.printDoc();
    }
    exportExcel = () => {
        this.dtData.exportExcel();
    }
    exportPDF = () => {
        this.dtData.exportPDF();
    }

    render = () => {
        const gridStyle = { minHeight: 550 }

        return <React.Fragment>
            <div className="p-grid">
                <div className="p-col-12 p-lg-12 p-sm-12">
                    <Button label={$.strings.print} icon="pi pi-print" onClick={this.print} />
                    <Button label={$.strings.excelExport} icon="pi pi-file-excel" onClick={this.exportExcel} />
                    <Button label={$.strings.pdfExport} icon="pi pi-file-pdf" onClick={this.exportPDF} />
                </div>
            </div>
            <DataGrid
                isReport={true} ref={e => this.dtData = e}
                style={gridStyle}
                scheme={this.getScheme()}
                dataSource={this.state.data}
                exportFileName={$.strings.attendanceReport.title}
            />
        </React.Fragment>
    }

    getData() {
        let data = [
            { ser: 1, id: 1, firstName: "زياد خالد", email: 'zead@iscosoft.com', country: 'PSE', age: 35, gender: "ذكر", address: 'بيت دجن', TransTime: '00:30' },
            { ser: 2, id: 2, firstName: "مارية زياد", email: 'maria@iscosoft.com', country: 'PSE', age: 2, gender: "انثى", address: 'نابلس - بيت دجن', TransTime: '00:15' },
            { ser: 3, id: 3, firstName: "احمد عيران", email: 'ahmad@iscosoft.com', country: 'JOD', age: 39, gender: "ذكر", address: 'نابلس', TransTime: '01:30' },
            { ser: 4, id: 4, firstName: "مريم زياد", email: 'mariam@iscosoft.com', country: 'JOD', age: 4, gender: "انثى", address: 'بيت دجن', TransTime: '01:30' },
            { ser: 5, id: 5, firstName: "عامر خالد", email: 'amer@iscosoft.com', country: 'PSE', age: 38, gender: "ذكر", address: 'رام الله', TransTime: '00:40' },
            { ser: 6, id: 6, firstName: "ماسة امجد", email: 'masa@iscosoft.com', country: 'PSE', age: 4, gender: "انثى", address: 'بيت ايبا', TransTime: '00:20' },
            { ser: 7, id: 7, firstName: "احمد حنني", email: 'johar@iscosoft.com', country: 'JOD', age: 35, gender: "ذكر", address: 'بيت فوريك', TransTime: '00:15' },
            { ser: 8, id: 8, firstName: "امجد موسى", email: 'amjad@iscosoft.com', country: 'JOD', age: 42, gender: "ذكر", address: 'صرة', TransTime: '00:15' },
        ]

        for (let index = 10; index < 150000; index++) {
            data.push({ ser: index, id: index, firstName: "زياد خالد : " + index, email: 'zead@iscosoft.com', country: 'PSE', age: 35, gender: "ذكر", address: 'بيت دجن ' + index, TransTime: '00:30' },);

        }

        return data;
    }

    buttonClick = (e, ctx) => {
        if (ctx && ctx.item) {
        }
    }
    buttonClick2 = (e, ctx) => {
        if (ctx && ctx.item) {
        }
    }
    buttonClick3 = (e, ctx) => {
        if (ctx && ctx.item) {
        }
    }
    getScheme = () => {
        // GrapeCity Wijmo Grid (FlexGrid) Aggregate: Cnt (Count), Avg (Average), First, Last, Min, Max, Sum
        return {
            name: "myRequestsScheme_Table",
            filter: false,
            showFooter: true,
            sortable: true,
            allowGrouping: true,
            responsiveColumnIndex: 1,
            columns: [
                //{ name: '-', header: '', width: 100, render: ({ value }) => <Button label="Click Me" onClick={e => alert("I'm Clicked")} /> },
                { name: 'ser', header: '#', visible: true, width: 100, dataType: 'number', aggregate: 'Cnt', sortable: true },
                { name: 'firstName', header: 'الاسم', width: "*", sortable: true },
                {
                    name: 'btn', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.view, iconType: 'search', className: 'info',
                    onClick: (e, ctx) => this.buttonClick(e, ctx)
                },
                {
                    name: 'btn2', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.view, iconType: 'search', className: 'danger',
                    onClick: (e, ctx) => this.buttonClick2(e, ctx)
                },
                { name: 'id', header: 'الرقم', visible: false, width: 100, dataType: 'number', sortable: true, visibleInColumnChooser: false },
                { name: 'email', header: 'البريد الالكتروني', width: "*" },
                { name: 'country', header: 'الدولة', width: 100 },
                { name: 'age', header: 'العمر', visible: true, defaultFlex: 1, aggregate: "Sum" },
                { name: 'gender', header: 'الجنس', width: 100 },
                // {
                //     name: 'btn3', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.view, iconType: 'search', className: 'warning',
                //     onClick: (e, ctx) => this.buttonClick3(e, ctx)
                // },
                { name: 'address', header: 'العنوان', width: 100, multiLine: true, wordWrap: true },
                { name: 'TransTime', header: 'وقت الحركة', width: 120, dataType: "Time", customAggregate: true }
            ]
        }
    }
}