import React from "react";
import styles from "./Sections.module.scss";
import DataGridView from '../../../Components/DataGridView/DataGridView';
import Http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import { FaFlag } from 'react-icons/fa'
import Messages from '../../../Components/Messages';
import Util from '../../../Util'
import Button from '../../../Components/Button';
import Card from "../../../Components/Card";
import SiteMap from "../../../Components/SiteMap";
import SectionsAdd from "./SectionsAdd";
import SCC from "../../ScreenCommonCode";
import { Accordion, AccordionTab } from "primereact/accordion";
import Input from "../../../Components/Input";
import Dropdown from "../../../Components/Dropdown";
import Checkbox from "../../../Components/Checkbox";
import ButtonsBarLookupList from "../../../Components/ButtonsBarLookupList";
import LateSettings from "../../SystemSettings/LateSettings";

const $ = window.$;

export default class Sections extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            sectionsList: [],
            classificationsList: [],
            data: [],
            dataObject: {
                section: undefined,
                classification: undefined,
                showChildrens: false
            },
            isDesktop: true
        };
    }

    componentDidMount = async () => {
        if (!$.license.HR_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR() || !Util.checkRights(167)) {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = window.innerWidth > 1250
        let sectionsPromise = this.getSections();
        let classificationPromise = this.getClassifications();

        let sectionsList = await sectionsPromise;
        let classificationsList = await classificationPromise;

        let dataTemp = await this.onRefresh(true);
        this.setState({
            sectionsList: sectionsList, classificationsList: classificationsList,
            data: dataTemp, loading: false, isDesktop: _isDesktop,
            dataObject: {
                section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
                classification: classificationsList && classificationsList.length > 0 ? classificationsList[0] : undefined,
            }
        });
    }

    render() {
        const gridStyle = { minHeight: '40vh' }
        let siteMapItems = [
            { label: $.strings.links.staticFilesAndSettings },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div ref={e => this.divMain = e} >
                <ProgressSpinner loading={this.state.loading} />
                {/* <LateSettings ref={e => this.dlgLateSettings = e} /> */}
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    <ButtonsBarLookupList
                        onNew={Util.checkRights(164) ? this.onNew : undefined}

                        onRefresh={Util.checkRights(167) ? e => this.onRefresh(false) : undefined}
                        onExcelExport={Util.checkRights(168) ? this.excelExport : undefined} />

                    <Messages innerRef={(el) => this.messages = el} />
                    <SectionsAdd ref={e => this.dlgSectionsAdd = e} editModeId={this.state.selectedCode} onAddSuccess={this.onAddSuccess} onAddClose={this.onAddClose} />
                </Card>
                <Accordion multiple activeIndex={[0]}>
                    <AccordionTab header={$.strings.filterCaption}>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="section"
                                    innerRef={e => this.drpSection = e}
                                    value={this.state.dataObject.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.sections.section}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    optionLabel="Name"
                                    filter={true}
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="classification"
                                    innerRef={e => this.drpClassification = e}
                                    value={this.state.dataObject.classification}
                                    options={this.state.classificationsList}
                                    caption={$.strings.sections.classification}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    optionLabel="name"
                                    filter={true}
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Checkbox id="showChildrens" caption={$.strings.sections.showChildrens}
                                    onChange={(e) => SCC.handleUserCheckbox(this, e)}
                                    checked={this.state.dataObject.showChildrens} />

                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                {
                                    // !$.license.Att_Module && Util.checkISHR() && Util.checkRights(532) &&
                                    // <Button label="اعدادات التأخير" onClick={e => this.dlgLateSettings.show()} />
                                }
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <Card>
                    <DataGridView
                        style={gridStyle}
                        ref={e => this.gridData = e}
                        isReport={true}
                        scheme={this.getScheme()}
                        exportFileName={this.state.pageInfo.title}
                        dataSource={this.state.data}
                        defaultRowHeight={45}
                        onRowDoubleClick={Util.checkISHR() && Util.checkRights(165) ? this.onRowDoubleClick : undefined}
                    />
                </Card>
            </div >
        );
    }

    setPageInfo = () => {
        let pageId
        let title = $.strings.sections.title

        return { pageId: pageId, title: title };
    };

    onClose = async (e) => {
        this.onRefresh();
        this.setState({ dlgSearchVisible: false });
    };

    onRowDoubleClick = (item) => {
        this.setState({ selectedCode: item.depCode }, () => this.dlgSectionsAdd.show(item.depCode));
    }


    btnRefresh = e => {
        this.setState({ data: this.onRefresh(false) });
    };

    onRefresh = async (fromLoad) => {
        let dataTemp = [];
        if (!fromLoad) {
            this.setState({ loading: true })
        }

        const params = {
            pos: -1,
            depCode: this.state.dataObject.section && this.state.dataObject.section.Code && this.state.dataObject.section.Code > 0
                ? this.state.dataObject.section.Code : ' ',
            getDepChild: this.state.dataObject.showChildrens ? true : false,
            classification: this.state.dataObject.classification ? this.state.dataObject.classification.pos : -1
        };
        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSectionsList, params, this.state.pageInfo.pageId);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        if (fromLoad) {
            return dataTemp;
        }
        else {
            this.setState({ data: dataTemp, loading: false })
        }
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getClassifications = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 23
        }
        let res = await Http.getJsonAxios(Http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }

    onNew = e => {
        this.dlgSectionsAdd.show();
    };

    onAddClose = (doRefreh) => {
        if (doRefreh) {
            this.onRefresh(false)
        }
    };

    onAddSuccess = result => {
        if (result) {
            this.onRefresh(false)
        }
    };

    onEditClick = (e, ctx) => {
        this.setState({ selectedCode: ctx.item.depCode }, () => this.dlgSectionsAdd.show(ctx.item.depCode));
    }

    getScheme = () => {
        return {
            name: "sections_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 4,
            columns: [
                {
                    name: 'rowNumber', header: '#', width: 80, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'btnEdit', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.edit,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEditClick(e, ctx),
                    visible: Util.checkISHR() && Util.checkRights(165), visibleInColumnChooser: Util.checkISHR() && Util.checkRights(165)
                },
                {
                    name: 'pos', header: $.strings.sections.id, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'depCode', header: $.strings.sections.code, with: 100
                },
                {
                    name: 'depName', header: $.strings.sections.name, width: this.state.isDesktop ? 250 : '*',
                },
                {
                    name: 'classification', header: $.strings.sections.classification, width: this.state.isDesktop ? 170 : '*',
                },
                {
                    name: 'fatherCode', header: $.strings.sections.relatedTo, width: 100
                },
                {
                    name: 'fatherName', header: $.strings.sections.fatherName, width: this.state.isDesktop ? 250 : '*',
                },
                {
                    name: 'directMangerCode', header: $.strings.sections.managerCode, width: 120
                },
                {
                    name: 'directMangerName', header: $.strings.sections.managerName, width: this.state.isDesktop ? 250 : '*',
                }

            ]
        }
    }
}