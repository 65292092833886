import React from "react";
import styles from './ExpectedVacations.module.scss'
import Card from '../../Components/Card';
import SiteMap from '../../Components/SiteMap';
import DataTable from '../../Components/Datatable';
import Button from '../../Components/Button';
import Util from "../../Util";
import Input from "../../Components/Input";
import ScrollTop from '../ScrollButton';
import http from '../../Api/http';
import ProgressSpinner from '../../Components/ProgressSpinner';
import Messages from '../../Components/Messages'
import WFTrans from '../WFTrans';
import Calendar from '../../Components/Calendar';
import ButtonsBarLookup from '../../Components/ButtonsBarLookup';

const $ = window.$

export default class ExpectedVacations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            newMode: 'new',
            data: [],
            formErrors: { empCode: '', fromDate: '', toDate: '', notes: '' },
            dataObject: {
                id: 0,
                empCode: localStorage.getItem("useridHR") || '',
                empName: Util.getEmployeeInfo(),
                fromDate: new Date(),
                toDate: new Date(),
                InsDate: new Date(),
                days: 1,
                notes: ''
            },
            isDesktop: true
        };
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(615)) {
            Util.goTo('/Access')
            return;
        }
        let _isDesktop = this.updatePredicate();

        if (this.props.match.params.id === undefined)
            this.setState({ isDesktop: _isDesktop, loading: false })

        if (this.props.match.params.id) {
            let data = await this.showExpectedVacationInfo(this.props.match.params.id)
            this.loadData(data)
        }

        setTimeout(() => {
            if (this.state.dataObject.id === 0) {
                this.dtpFromDate.calender.container.firstChild.focus()
            }
        }, 10)
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    handleFromDate = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, fromDate: e.target.value } }, () => this.setToDate())
    }
    setToDate = () => {
        if (this.state.dataObject.toDate < this.state.dataObject.fromDate) {
            this.setState({ dataObject: { ...this.state.dataObject, toDate: this.state.dataObject.fromDate } }, () => this.calculateDaysNo())
        }
        else {
            this.calculateDaysNo();
        }
    }
    handleToDate = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, toDate: e.target.value } }, () => this.setFromDate())
    }
    setFromDate = () => {
        if (this.state.dataObject.toDate < this.state.dataObject.fromDate) {
            this.setState({ dataObject: { ...this.state.dataObject, fromDate: this.state.dataObject.toDate } }, () => this.calculateDaysNo())
        }
        else {
            this.calculateDaysNo();
        }
    }
    calculateDaysNo = () => {
        try {
            let fromDate = this.state.dataObject.fromDate
            let toDate = this.state.dataObject.toDate

            const _fromDate = Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0);
            const _toDate = Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 0, 0, 0);
            let temp = 1000 * 3600 * 24;

            let daysNo = Math.floor((_toDate - _fromDate) / temp);

            daysNo += 1;

            this.setState({ dataObject: { ...this.state.dataObject, days: daysNo } })
        }
        catch (e) {

        }
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _empCode = this.state.dataObject.empCode
        fieldValidationErrors._empCode = _empCode ? '' : $.strings.requiredFiled;

        let _fromDate = this.state.dataObject.fromDate
        fieldValidationErrors.fromDate = _fromDate ? '' : $.strings.requiredFiled;

        let _toDate = this.state.dataObject.toDate
        fieldValidationErrors.toDate = _toDate ? '' : $.strings.requiredFiled;

        let _notes = this.state.dataObject.notes;
        fieldValidationErrors.notes = _notes ? '' : $.strings.requiredFiled;

        this.setState({
            formErrors: fieldValidationErrors,
            empCodeValid: _empCode,
            fromDateValid: _fromDate,
            toDateValid: _toDate,
            notesValid: _notes

        }, this.validateForm);

        return _empCode && _fromDate && _toDate && _notes;
    }
    validateForm() {
        let isFormValid = this.state.empCodeValid && this.state.fromDateValid && this.state.toDateValid && this.state.notesValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        const messagesHeader = <Messages innerRef={(el) => this.messages = el} />;

        let siteMapItems = [
            { label: $.strings.links.home },
            {
                label: <div>
                    {$.strings.links.requests.expectedVacations + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];

        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" style={{ minHeight: '70vh' }}>
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={this.state.dataObject.id <= 0 ? this.resetFields : undefined}
                            onSave={this.state.dataObject.id <= 0 ? this.onSave : undefined}
                            onTransDetails={this.state.dataObject.id > 0 ? this.wfTransDetails : undefined}
                            onShowAttachment={this.state.dataObject.id > 0 && this.state.dataObject.file_id > 0 ? this.onShowAttachment : undefined}
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        {messagesHeader}
                    </div>
                </div>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtCode = el}
                                type="text"
                                id="id"
                                keyfilter="pnum"
                                value={this.state.dataObject.id || ''}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.expectedVacations.code}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                            <Calendar
                                id="requestDate"
                                innerRef={(el) => this.dtpRequestDate = el}
                                value={this.state.dataObject.InsDate}
                                caption={$.strings.expectedVacations.insDate}
                                onChange={this.handleUserInput}
                                tooltip={$.strings.expectedVacations.insDate}
                                readOnlyInput={true}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpCode = el}
                                type="text"
                                id="empCode"
                                keyfilter="pnum"
                                maxLength="4"
                                value={this.state.dataObject.empCode || ''}
                                autofocus={true}
                                autocomplete="off"
                                caption={$.strings.fingerPrint.empCode}
                                isRequired={true}
                                onChange={this.handleUserInput}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-4 p-lg-8 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpName = el}
                                type="text"
                                id="empName"
                                maxLength="70"
                                value={this.state.dataObject.empName || ''}
                                autofocus={true}
                                caption={$.strings.fingerPrint.empName}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                readOnly={true}
                                disabled
                                style={{ width: '100%', color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                ref={(el) => this.dtpFromDate = el}
                                value={this.state.dataObject.fromDate}
                                caption={$.strings.expectedVacations.fromDate}
                                onChange={this.handleFromDate}
                                tooltip={$.strings.expectedVacations.fromDate}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                readOnlyInput={true}
                                readOnly={true}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                ref={(el) => this.dtpToDate = el}
                                value={this.state.dataObject.toDate}
                                caption={$.strings.expectedVacations.toDate}
                                onChange={this.handleToDate}
                                tooltip={$.strings.expectedVacations.toDate}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                readOnlyInput={true}
                                readOnly={true}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtDaysNo = el}
                                type="text"
                                id="daysNo"
                                keyfilter="pnum"
                                value={this.state.dataObject.days}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.expectedVacations.daysNo}
                                readOnly={true}
                                disabled
                                isRequired={true}
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                formErrors={this.state.formErrors}

                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                            <Input
                                innerRef={ref => this.txtNotes = ref}
                                type="text"
                                id="notes"
                                maxLength="100"
                                value={this.state.dataObject.notes}
                                caption={$.strings.expectedVacations.notes}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>
                </Card>
                <ScrollTop />
                <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
            </div >
        );
    }

    onNew = () => {
        this.dlgAddExpectedVacations.addNew();
    }

    wfTransDetails = async () => {
        let id = Util.encryptStringToHexa(this.state.dataObject.id);
        await this.wfTransDlg.show(8, id);
    }

    showExpectedVacationInfo = async (id) => {
        let dataTemp = []
        let obj = {
            id: id
        }
        let res = await http.getJsonAxios(http.actions.ExpectedVacation.urlGetExpectedVacation, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    loadData = (data) => {
        let _isDesktop = this.updatePredicate();
        let id = 0;
        let insDate = new Date();
        let fromDate = new Date();
        let toDate = new Date();
        let days = 1;
        let notes = '';

        if (data.list && data.list.length > 0) {
            id = data.list[0].id
            insDate = new Date(data.list[0].InsDate);
            fromDate = new Date(data.list[0].fromDate);
            toDate = new Date(data.list[0].toDate);
            days = data.list[0].Days;
            notes = data.list[0].Remarks;
        }
        this.setState({
            data: data.list,
            mode: 'view',
            isDesktop: _isDesktop,
            dataObject: {
                ...this.state.dataObject,
                id: id,
                empCode: localStorage.getItem("useridHR") || '',
                empName: Util.getEmployeeInfo(),
                InsDate: insDate,
                fromDate: fromDate,
                toDate: toDate,
                days: days,
                notes: notes

            },
            formErrors: { empCode: '', fromDate: '', toDate: '', notes: '' }, loading: false
        })
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true })
        let files = [] // ملفات المرفقات
        let file = this.state.dataObject.uploadedFile2

        let obj = {
            empcode: this.state.dataObject.empCode,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            remarks: this.state.dataObject.notes
        }
        let saveResult
        let res = await http.postMultipartAxios(http.actions.ExpectedVacation.urlPostSaveExpectedVacation, obj, file, files, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.setState({ loading: false })
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return saveResult = false
        }
    }

    resetFields = () => {
        this.setState({
            loading: false,
            newMode: 'new',
            data: [],
            dataObject: {
                id: 0,
                empCode: localStorage.getItem("useridHR") || '',
                empName: Util.getEmployeeInfo(),
                InsDate: new Date(),
                fromDate: new Date(),
                toDate: new Date(),
                days: 1,
                notes: ''
            },
            formErrors: { empCode: '', fromDate: '', toDate: '', notes: '' }
        });
    }
}
