import React from "react";
import DataGrid from '../../../Components/DataGridView/DataGridView';
import Card from "../../../Components/Card";
import SiteMap from "../../../Components/SiteMap";
import ProgressSpinner from "../../../Components/ProgressSpinner";
import Util from "../../../Util";
import Input from '../../../Components/Input';
import SearchEmployees from '../../Employees/SearchEmployees';
import Dropdown from "../../../Components/Dropdown";
import Calendar from "../../../Components/Calendar";
import ButtonsBarLookupList from '../../../Components/ButtonsBarLookupList';
import http from '../../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollTop from '../../ScrollButton';
import PeriodsAdd from "./PeriodsAdd";
import { async } from "regenerator-runtime";
import Print from "../../../Print";

const $ = window.$;

export default class Periods extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDesktop: window.innerWidth > 1250,
            classificationsList: [],
            filterShown: true,
            data: []
        };
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(944)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = window.innerWidth > 1250;

        let classPromise = this.getClassifications();
        let dataPromise = this.getData(true);

        let classList = await classPromise;
        let data = await dataPromise;


        this.setState({
            data: data, classificationsList: classList, classification: classList && classList.length > 0 ? classList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        }, () => this.printFilters = this.state)

    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.shifts.menu },
            { label: $.strings.periods.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        const columnGroup = [
            {
                header: '', align: 'center', columns: [
                    { name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt' },
                    { name: 'code', header: $.strings.periods.code, width: 100, },
                ]
            },
            { name: 'classification', header: $.strings.periods.classification, width: this.state.isDesktop ? 200 : "*" },
            {
                header: $.strings.periods.workPeriod, align: 'center',
                columns: [
                    { name: 'fromTime', header: $.strings.periods.from, width: 120, cssClass: 'main-column' },
                    { name: 'toTime', header: $.strings.periods.to, width: 120 },
                ]
            },
            {
                header: $.strings.periods.rest, align: 'center',
                columns: [
                    { name: 'fromRest1', header: $.strings.periods.from, width: 120, cssClass: 'main-column' },
                    { name: 'toRest1', header: $.strings.periods.to, width: 120 },
                ]
            },
            {
                header: $.strings.periods.period, align: 'center',
                columns: [
                    { name: 'restPeriod', header: $.strings.periods.rest, width: 120 },
                    { name: 'period', header: $.strings.periods.period, width: 120, cssClass: 'main-column' },
                ]
            }
        ]
        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <PeriodsAdd ref={e => this.dlgPeriodsAdd = e} onSuccess={this.onAddSuccess} />
                <ButtonsBarLookupList
                    onNew={Util.checkRights(945) ? this.onNew : undefined}
                    onRefresh={e => this.getData(false)} onPrint={this.onPrint}
                    onExcelExport={Util.checkRights(894) ? this.excelExport : undefined} />
            </Card>
            <Accordion multiple activeIndex={[0, 1]}>
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="classification"
                                innerRef={e => this.drpTransType = e}
                                value={this.state.classification}
                                options={this.state.classificationsList}
                                caption={$.strings.periods.classification}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                    </div>

                </AccordionTab>

                <AccordionTab header={$.strings.data}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataGrid
                                style={gridStyle}
                                ref={e => this.gridData = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="serial"
                                scheme={this.getScheme()}
                                exportFileName={$.strings.periods.title}
                                dataSource={this.state.data}
                                defaultRowHeight={45}
                                onRowDoubleClick={Util.checkISHR() ? this.onRowDoubleClick : undefined}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <ScrollTop />
            {/* <ClockTransEdit ref={e => this.dlgClockTransEdit = e} onSuccess={this.clockTransEditSuccess} /> */}
        </div >
    }

    onRowDoubleClick = (item) => {
        if (Util.checkISHR()) {
            this.dlgPeriodsAdd.show(item.code);
        }
    }

    getClassifications = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 30
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp;
    }

    onNew = () => {
        this.dlgPeriodsAdd.show(-1, false);
    }
    onAddSuccess = async () => {
        await this.getData(false);
    }
    getData = async (fromLoad) => {
        if (!fromLoad)
            this.setState({ loading: true });
        let dataTemp = []
        let obj = {
            classPos: this.state.classification && this.state.classification.pos >= 0 ? this.state.classification.pos : "",
            fromTime: "",
            totime: "",
            periodPos: ""
        }

        let res = await http.getJsonAxios(http.actions.Periods.urlGetPeriodsList, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            // if (dataTemp) {
            //     dataTemp.forEach(element => {
            //         element.Date = new Date(element.Date);
            //     });
            // }
        }
        if (!fromLoad)
            this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
        else
            return dataTemp
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    onPrint = () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.periods.title, this.gridData, this.state.data);
        
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];

        if (this.printFilters) {
            if (this.printFilters.classification) {
                listOfFilters.push($.strings.periods.classification + ": " + this.printFilters.classification.name);
            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    buttonEdit = (e, ctx) => {
        this.dlgPeriodsAdd.show(ctx.item.code, false);
    }
    buttonView = (e, ctx) => {
        this.dlgPeriodsAdd.show(ctx.item.code, true);
    }
    clockTransEditSuccess = async () => {
        await this.getData(false);
    }
    getScheme = () => {
        return {
            name: "periodsScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 4,
            columns: [
                { name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'code', header: $.strings.periods.code, width: 100, columnWidth: '1' },
                {
                    name: 'buttonView', header: ' ', width: 50, buttonBody: 'button', align: 'center',
                    title: $.strings.view, iconType: 'view', className: 'info', onClick: (e, ctx) => this.buttonView(e, ctx),
                    visible: Util.checkISHR() && Util.checkRights(946), visibleInColumnChooser: Util.checkISHR() && Util.checkRights(946)
                },
                {
                    name: 'buttonEdit', header: ' ', width: 50, buttonBody: 'button', align: 'center',
                    title: $.strings.edit, iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.buttonEdit(e, ctx),
                    visible: Util.checkISHR() && Util.checkRights(946), visibleInColumnChooser: Util.checkISHR() && Util.checkRights(946)
                },
                { name: 'classification', header: $.strings.periods.classification, width: this.state.isDesktop ? 200 : "*", columnWidth: '2' },
                { name: 'fromTime', header: $.strings.periods.from, width: 150, columnWidth: '2' },
                { name: 'toTime', header: $.strings.periods.to, width: 150, columnWidth: '2' },
                { name: 'fromRest1', header: $.strings.periods.from, width: 150, columnWidth: '2' },
                { name: 'toRest1', header: $.strings.periods.to, width: 150, columnWidth: '2' },
                {
                    name: 'fromRest2', header: $.strings.clockTrans.date, width: 150, dataType: 'Date', visible: false,
                    visibleInColumnChooser: false, columnWidth: '2.5'
                },
                {
                    name: 'toRest2', header: $.strings.clockTrans.day, width: 150, visible: false, visibleInColumnChooser: false, columnWidth: '2'
                },
                { name: 'period', header: $.strings.periods.period, width: 150, columnWidth: '2' },
                { name: 'restPeriod', header: $.strings.periods.rest, width: 150, columnWidth: '2' },
                { name: 'periodDays', header: $.strings.periods.periodDays, width: 150, columnWidth: '3' }
            ]
        }
    }
}