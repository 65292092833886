import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollTop from '../ScrollButton';
import Print from "../../Print";

const $ = window.$;

export default class LoginsReport extends React.Component {
    constructor(props) {
        super(props);
        this.userTypeList = [
            { id: -1, name: $.strings.all },
            { id: 0, name: $.strings.loginsReport.employee },
            { id: 1, name: $.strings.loginsReport.hrEmployee }
        ];
        this.currDate = new Date();
        this.state = {
            isDesktop: window.innerWidth > 1250,
            fromDate: new Date(this.currDate.getFullYear(), this.currDate.getMonth(), 1),
            toDate: new Date(),
            userId: undefined,
            userType: this.userTypeList[0],
            data: []
        };
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(928)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = this.updatePredicate();
        this.setState({ isDesktop: _isDesktop, loading: false })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../MyStaff' },
            { label: $.strings.loginsReport.reportTitle }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card>
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
            </Card>
            <Accordion multiple activeIndex={[0, 1]}>
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Calendar
                                id="fromDate"
                                value={this.state.fromDate}
                                caption={$.strings.loginsReport.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Calendar
                                id="toDate"
                                value={this.state.toDate}
                                caption={$.strings.loginsReport.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-6">
                            <Dropdown
                                id="userType"
                                value={this.state.userType}
                                options={this.userTypeList}
                                caption={$.strings.loginsReport.userType}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                id="userId"
                                value={this.state.userId}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.loginsReport.userId}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            style={gridStyle}
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.getScheme()}
                            dataSource={this.state.data}
                            exportFileName={$.strings.loginsReport.NewsReportTitle}
                        />
                    </div>
                </div>
            </Card>
            <ScrollTop />
        </div >
    }

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userType: this.state.userType && this.state.userType.id >= 0 ? this.state.userType.id : -1,
            fromDate: this.state.fromDate ? this.state.fromDate : new Date(this.currDate.getFullYear(), this.currDate.getMonth(), 1),
            toDate: this.state.toDate ? this.state.toDate : new Date(),
            userCode: this.state.userId && this.state.userId.length > 0 ? this.state.userId : ''
        }

        let res = await http.getJsonAxios(http.actions.LoginsReport.urlGetLogHistory, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.loginsReport.NewsReportTitle, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let dateFilter = "";

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.loginsReport.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.loginsReport.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.userType) {
                listOfFilters.push($.strings.loginsReport.userType + ": " + this.printFilters.userType.name);
            }

            if (this.printFilters.userId) {
                listOfFilters.push($.strings.loginsReport.userId + ": " + this.printFilters.userId);
            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    onViewClick = (e, ctx) => {
        if (window.confirm($.strings.loginsReport.editConfirmation)) {
        }
    }
    onEditClick = (e, ctx) => {
        if (window.confirm($.strings.loginsReport.editConfirmation)) {
        }
    }
    onDeleteClick = (e, ctx) => {
        if (window.confirm($.strings.loginsReport.editConfirmation)) {
        }
    }

    getScheme = () => {
        return {
            name: "newsReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 8,
            columns: [
                { name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'Day', header: $.strings.loginsReport.day, width: 80, columnWidth: '2' },
                { name: 'Date', header: $.strings.loginsReport.date, width: 120, columnWidth: '3' },
                { name: 'Time', header: $.strings.loginsReport.time, width: 120, columnWidth: '2' },
                { name: 'IPAddress', header: $.strings.loginsReport.ipAddress, width: 130, columnWidth: '3' },
                { name: 'Name', header: $.strings.loginsReport.name, width: "*", columnWidth: '*' },
                { name: 'UserType', header: $.strings.loginsReport.userType, width: 120, columnWidth: '2' },
                { name: 'OperationType', header: $.strings.loginsReport.operationType, width: 120, columnWidth: '2' },
                { name: 'Status', header: $.strings.loginsReport.status, width: 100, columnWidth: '2' },
                { name: 'UserCode', header: $.strings.loginsReport.userId, width: 120, columnWidth: '2' },
                { name: 'Notes', header: $.strings.loginsReport.notes, width: "*", columnWidth: '*' }
            ]
        }
    }
}