import * as React from 'react';
import './styles.css';

// interface TickerListProps {
//     name?: string;
//     slideSpeed?: number; // @TODO not implemented
//     visibleItems?: number; // @TODO not implemented
//     isNewsTicker?: boolean;
// }

//const TickerList: React.FunctionComponent<TickerListProps> = (props) => {
export default class TickerList extends React.Component {
    render() {
        return <div className={'ticker'}>
            <div className={'ticker-list'}>
                {this.props.children}
            </div>
            {!this.props.isNewsTicker ?
                <div className={'ticker-list'}>
                    {this.props.children}
                </div>
                : ''
            }
        </div>
    }
}

//export default TickerList;