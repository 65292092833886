import React from "react";
import styles from "./Label.module.scss";

export default class Label extends React.Component {
    render() {
        return (
            <label ref={this.props.innerRef} className={styles.label} {...this.props}>
                {this.props.children}
                {
                    this.props.isRequired &&
                    <span style={{ color: 'red', fontWeight: 'bold' }}>&nbsp;*</span>
                }
            </label>
        );
    }
}