import React from "react";
import styles from "./ProgressSpinner.module.scss";
import { ProgressSpinner as PrimeProgressSpinner } from 'primereact/progressspinner';
export default class ProgressSpinner extends React.Component {

    render() {
        let displayStyle = this.props.loading ? styles.progressVisible : styles.progressHide
        return (

            <div className={`${styles.progressSpinner} ${displayStyle}`}>
                <PrimeProgressSpinner className={styles.progressPos} style={{width: '100px', height: '100px'}} strokeWidth="1" />
            </div>
        )
    }
}