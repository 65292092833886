import React from "react";
import './Dashboards.css';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import http from '../../Api/http';
import Late from "../../Components/SpecialComponents/Charts/Late";
import Absence from "../../Components/SpecialComponents/Charts/Absence";
import Requests from "../../Components/SpecialComponents/Charts/Requests/Requests";
import NoSignIn from "../../Components/SpecialComponents/Charts/NoSignIn/NoSignIn";
import NoExit from "../../Components/SpecialComponents/Charts/NoExit/NoExit";
import * as wjcCore from '@grapecity/wijmo';
import Tile from "../../Components/SpecialComponents/Charts/Tiles/Tile";
import Button from "../../Components/Button";

const $ = window.$;

export default class Dashboards extends React.Component {
    constructor(props) {
        super(props);
        this.key = 0;
        this.palette = ['#8e99f3', '#ffca28', '#5c6bc0', '#bbdefb'];
        this.state = {
            loading: false,
            isDesktop: window.innerWidth > 1250,
            sectionsList: [],
            section: undefined,
            departmentsList: [],
            department: undefined,
            fromDate: new Date(new Date().getFullYear(), 0, 1),
            toDate: new Date(),
            dataClockTrans: [],
            dataSelfServiceReq: [],
            dataLoans: [],
            dataSalary: [],
            dataNationality: [],
            dataEmployees: [],
            palette: ['rgba(42,159,214,1)', 'rgba(119,179,0,1)', 'rgba(153,51,204,1)', 'rgba(255,136,0,1)', 'rgba(204,0,0,1)',
                'rgba(0,204,163,1)', 'rgba(61,109,204,1)', 'rgba(82,82,82,1)', 'rgba(0,0,0,1)'],
            tiles: this.loadDashboardsDesign() || this.getTiles(),
            key: this.key
        };
    }

    getTiles = () => {
        return [
            { name: "Late", key: this.key += 1 },
            { name: "Absence", key: this.key += 1 },
            { name: "Requests", key: this.key += 1 },
            { name: "NoSignIn", key: this.key += 1 },
            { name: "NoExit", key: this.key += 1 },
        ];
    }
    removeTile(tileIndex) {
        const tiles = this.state.tiles.filter((item, index) => index + "" !== tileIndex + "");
        this.setState({ tiles: tiles }, () => this.saveDashboardsDesign());
    }

    // allow users to re-order elements within a panel element
    // we work with the DOM elements and update the state when done.
    enableItemReorder(panel) {
        let dragSource = null;
        let dropTarget = null;
        // add drag/drop event listeners
        panel.addEventListener('dragstart', (e) => {
            const target = wjcCore.closest(e.target, '.tile');
            if (target && target.parentElement === panel) {
                dragSource = target;
                wjcCore.addClass(dragSource, 'drag-source');
                const dt = e.dataTransfer;
                dt.effectAllowed = 'move';
                dt.setData('text', dragSource.innerHTML);
            }
        });
        panel.addEventListener('dragover', (e) => {
            if (dragSource) {
                let tile = wjcCore.closest(e.target, '.tile');
                if (tile === dragSource) {
                    tile = null;
                }
                if (dragSource && tile && tile !== dragSource) {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = 'move';
                }
                if (dropTarget !== tile) {
                    wjcCore.removeClass(dropTarget, 'drag-over');
                    dropTarget = tile;
                    wjcCore.addClass(dropTarget, 'drag-over');
                }
            }
        });
        panel.addEventListener('drop', (e) => {
            if (dragSource && dropTarget) {
                // finish drag/drop
                e.stopPropagation();
                e.stopImmediatePropagation();
                e.preventDefault();
                // re-order HTML elements (optional here, we're updating the state later)
                const srcIndex = getIndex(dragSource);
                const dstIndex = getIndex(dropTarget);
                const refChild = srcIndex > dstIndex ? dropTarget : dropTarget.nextElementSibling;
                dragSource.parentElement.insertBefore(dragSource, refChild);
                // focus and view on the tile that was dragged
                dragSource.focus();
                // update state
                let tiles = this.state.tiles.slice();
                tiles.splice(srcIndex, 1);
                tiles.splice(dstIndex, 0, this.state.tiles[srcIndex]);
                this.setState({ tiles: tiles }, () => this.saveDashboardsDesign());
            }
        });
        panel.addEventListener('dragend', () => {
            wjcCore.removeClass(dragSource, 'drag-source');
            wjcCore.removeClass(dropTarget, 'drag-over');
            dragSource = dropTarget = null;
        });
        function getIndex(e) {
            const p = e.parentElement;
            for (let i = 0; i < p.children.length; i++) {
                if (p.children[i] === e)
                    return i;
            }
            return -1;
        }
    }

    saveDashboardsDesign = () => {
        let storageName = 'dashboards_' + localStorage['useridHR'];
        localStorage[storageName] = JSON.stringify(this.state.tiles);
    }

    loadDashboardsDesign = () => {
        let storageName = 'dashboards_' + localStorage['useridHR'];
        if (localStorage[storageName])
            return JSON.parse(localStorage[storageName]);
        else
            return undefined;
    }

    componentDidMount = async () => {
        if (!Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }
        else {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(716)) {
                Util.goTo('/Access');
                return;
            }
        }
        let _isDesktop = this.updatePredicate();

        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        this.setState({
            sectionsList: sectionsList, departmentsList: departmentsList,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })
        //await this.getData();
        // enable tile drag/drop
        const panel = document.querySelector('.dashboard');
        this.enableItemReorder(panel);
    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    initializeChart = (flex) => {
        this.theChart = flex;
    };

    onResetDefault = () => {
        this.setState({ tiles: this.getTiles() }, () => this.saveDashboardsDesign());
    }

    render = () => {
        const renderBlankTile = (<div className="blank" style={{ height: '70vh' }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill={this.palette[0]}>
                <path d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H16L12,22L8,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M4,4V16H8.83L12,19.17L15.17,16H20V4H4M11,6H13V9H16V11H13V14H11V11H8V9H11V6Z" />
            </svg>
            <div>{$.strings.charts.emptyDashboards}</div>
            <br />
            <Button label={$.strings.dashboards.resetDefault} onClick={this.onResetDefault} />
        </div>);
        const renderTiles = this.state.tiles.map((item, index) => (
            <Tile header={item.name} onRemove={this.removeTile.bind(this)} index={index} key={item.key} />
        ))

        return <div ref="divMain" >
            {/* <ProgressSpinner loading={this.state.loading} /> */}
            {/* <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card> */}

            <div className="content">
                <div className="dashboard">
                    {this.state.tiles.length ? renderTiles : renderBlankTile}
                </div>
            </div>

            {/* <Card>
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} />
            </Card> */}
            {/* <Accordion multiple  > */}
            {/* <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="section"
                                innerRef={e => this.drpSections = e}
                                value={this.state.section}
                                options={this.state.sectionsList}
                                caption={$.strings.dashboards.section}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="department"
                                innerRef={e => this.drpSections = e}
                                value={this.state.department}
                                options={this.state.departmentsList}
                                caption={$.strings.dashboards.department}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                value={this.state.fromDate}
                                caption={$.strings.dashboards.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.dashboards.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Button label={$.strings.refresh} onClick={this.getData} />
                        </div>
                    </div>
                </AccordionTab> */}
            {/* <AccordionTab header={$.strings.dashboards.clockTrans}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataGrid
                                style={gridStyle}
                                ref={e => this.gridData = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="rowNumber"
                                defaultRowHeight={10}
                                exportFileName={$.strings.dashboards.clockTrans}
                                scheme={this.getSchemeClockTrans()}
                                dataSource={this.state.dataClockTrans}
                            />
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.dashboards.selfServiceReq}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataGrid
                                style={gridStyle}
                                ref={e => this.gridDataReq = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="rowNumber"
                                defaultRowHeight={10}
                                exportFileName={$.strings.dashboards.selfServiceReq}
                                scheme={this.getSchemeSelfServiceReq()}
                                dataSource={this.state.dataSelfServiceReq}
                            />
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.dashboards.loans}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataGrid
                                style={gridStyle}
                                ref={e => this.gridDataLoans = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="rowNumber"
                                defaultRowHeight={10}
                                exportFileName={$.strings.dashboards.loans}
                                scheme={this.getSchemeLoans()}
                                dataSource={this.state.dataLoans}
                            />
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.dashboards.salary}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataGrid
                                style={gridStyle}
                                ref={e => this.gridDataSalary = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="rowNumber"
                                defaultRowHeight={10}
                                exportFileName={$.strings.dashboards.salary}
                                scheme={this.getSchemeSalary()}
                                dataSource={this.state.dataSalary}
                            />
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.dashboards.nationalities}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataGrid
                                style={gridStyle}
                                ref={e => this.gridDataSalary = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="rowNumber"
                                defaultRowHeight={10}
                                exportFileName={$.strings.dashboards.nationalities}
                                scheme={this.getSchemeNationality()}
                                dataSource={this.state.dataNationality}
                            />
                        </div>
                    </div>
                </AccordionTab> */}
            {/* </Accordion> */}


            {/* <div className="p-grid">
                <div className="p-col-12 p-lg-4 p-sm-12">
                    <wjChart.FlexChart header={$.strings.dashboards.clockTrans} bindingX={"TransType"} bindingY={"Count"} selectionMode="Point"
                        initialized={this.initializeChart} itemsSource={this.state.dataClockTrans} palette={this.state.palette}>
                        <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
                        <wjChart.FlexChartSeries binding="EmpCount" name={$.strings.dashboards.empCount}></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries binding="Count" name={$.strings.dashboards.count}></wjChart.FlexChartSeries>
                    </wjChart.FlexChart>
                </div>
                <div className="p-col-12 p-lg-4 p-sm-12">
                    <wjChart.FlexChart header={$.strings.dashboards.selfServiceReq} bindingX={"TransType"} bindingY={"Count"} selectionMode="Point" initialized={this.initializeChart} itemsSource={this.state.dataSelfServiceReq} >
                        <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
                        <wjChart.FlexChartSeries binding="EmpCount" name={$.strings.dashboards.empCount}></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries binding="Count" name={$.strings.dashboards.count}></wjChart.FlexChartSeries>
                    </wjChart.FlexChart>
                </div>
                <div className="p-col-12 p-lg-4 p-sm-12">
                    <wjChart.FlexChart header={$.strings.dashboards.salary} bindingX={"Mon"} bindingY={"Year"} selectionMode="Point" initialized={this.initializeChart} itemsSource={this.state.dataSalary} >
                        <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
                        <wjChart.FlexChartSeries binding="Total" name={$.strings.dashboards.salary}></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries binding="Count" name={$.strings.dashboards.empCount}></wjChart.FlexChartSeries>
                    </wjChart.FlexChart>
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-lg-4 p-sm-12">
                    <wjChart.FlexChart header={$.strings.dashboards.loans} bindingX={"TransType"} bindingY={"Count"} selectionMode="Point"
                        initialized={this.initializeChart} itemsSource={this.state.dataLoans} palette={this.state.palette}>
                        <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
                        <wjChart.FlexChartSeries binding="Total" name={$.strings.dashboards.loans}></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries binding="EmpCount" name={$.strings.dashboards.count}></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries binding="Count" name={$.strings.dashboards.empCount}></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries binding="Paid" name={$.strings.dashboards.paid}></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries binding="UnPaid" name={$.strings.dashboards.unPaid}></wjChart.FlexChartSeries>

                    </wjChart.FlexChart>
                </div>
                <div className="p-col-12 p-lg-4 p-sm-12">
                    <wjChart.FlexChart header={$.strings.dashboards.nationalities} bindingX={"nationality"} bindingY={"Count"} selectionMode="Point" initialized={this.initializeChart} itemsSource={this.state.dataNationality} >
                        <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
                        <wjChart.FlexChartSeries binding="Count" name={$.strings.dashboards.count}></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries binding="Percent" name={$.strings.dashboards.percent}></wjChart.FlexChartSeries>
                    </wjChart.FlexChart>
                </div>
                <div className="p-col-12 p-lg-4 p-sm-12">
                    <wjChart.FlexChart header={$.strings.dashboards.employees} bindingX={"TransType"} bindingY={"Count"} selectionMode="Point"
                        initialized={this.initializeChart} itemsSource={this.state.dataEmployees} palette={this.state.palette}>
                        <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
                        <wjChart.FlexChartSeries binding="Count" name={$.strings.dashboards.count}></wjChart.FlexChartSeries>
                    </wjChart.FlexChart>
                </div>
            </div>
            <div className="p-grid">

            </div> */}
        </div >
    }
    basicData = () => {
        // if (!this.state.dataNationality) {
        //     return { }
        // }
        return {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'My First dataset',
                    backgroundColor: '#42A5F5',
                    data: [65, 59, 80, 81, 56, 55, 40]
                },
                {
                    label: 'My Second dataset',
                    backgroundColor: '#FFA726',
                    data: [28, 48, 40, 19, 86, 27, 90]
                }
            ]
        };
        // return {
        //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        //     datasets: [
        //         {
        //             label: 'My First dataset',
        //             backgroundColor: '#42A5F5',
        //             data: [65, 59, 80, 81, 56, 55, 40]
        //         },
        //         {
        //             label: 'My Second dataset',
        //             backgroundColor: '#FFA726',
        //             data: [28, 48, 40, 19, 86, 27, 90]
        //         }
        //     ]
        // };
    }
    getBasicOptions = () => {
        if (!this.state.dataNationality) {
            return {}
        }
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
        return basicOptions;
    }


    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }

    getData = async () => {
        this.setState({ loading: true });
        let dataClockTrans = [], dataSelfServiceReq = [], dataLoans = [], dataSalary = [], dataNationality = [], dataEmployees = [];
        let obj = {
            depCode: this.state.department && this.state.department.pos >= 0 ? this.state.department.pos : -1,
            sectionCode: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : -1,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }
        let res = await http.getJsonAxios(http.actions.Dashboards.urlGetDashboards, obj, 0);
        if (res.status === 200) {
            dataClockTrans = res.data.clockTransObject.list;
            dataSelfServiceReq = res.data.selfServiceReqObject.list;
            dataLoans = res.data.loansObject.list;
            dataSalary = res.data.salaryInfoObject.list;
            dataNationality = res.data.nationalityObject.list;
            dataEmployees = res.data.employeesObject.list;
        }

        this.setState({
            dataClockTrans: dataClockTrans, dataSelfServiceReq: dataSelfServiceReq,
            dataLoans: dataLoans, dataSalary: dataSalary, dataNationality: dataNationality,
            dataEmployees: dataEmployees, loading: false
        });
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    getSchemeClockTrans = () => {
        return {
            name: "clockTransDashboardScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 1,
            columns: [

                {
                    name: 'rowNumber', header: '##', width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'TransType', header: $.strings.dashboards.transType, width: "*"

                },
                {
                    name: 'Count', header: $.strings.dashboards.transCount, width: 150

                },
                {
                    name: 'EmpCount', header: $.strings.dashboards.empCount, width: 150

                },
                {
                    name: 'Total', header: $.strings.dashboards.daysTotal, width: 150

                }
            ]
        }
    }
    getSchemeSelfServiceReq = () => {
        return {
            name: "selfServiceReqDashboardScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 1,
            columns: [

                {
                    name: 'rowNumber', header: '##', width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'TransType', header: $.strings.dashboards.transType, width: "*"

                },
                {
                    name: 'Count', header: $.strings.dashboards.transCount, width: 150

                },
                {
                    name: 'EmpCount', header: $.strings.dashboards.empCount, width: 150

                },
                {
                    name: 'Total', header: $.strings.dashboards.daysTotal, width: 150

                }
            ]
        }
    }
    getSchemeLoans = () => {
        return {
            name: "loansDashboardScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 1,
            columns: [

                {
                    name: 'rowNumber', header: '##', width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'TransType', header: $.strings.dashboards.transType, width: "*"
                },
                {
                    name: 'Count', header: $.strings.dashboards.transCount, width: 150
                },
                {
                    name: 'EmpCount', header: $.strings.dashboards.empCount, width: 150
                },
                {
                    name: 'Total', header: $.strings.dashboards.daysTotal, width: 150
                },
                {
                    name: 'Paid', header: $.strings.dashboards.paid, width: 150
                },
                {
                    name: 'UnPaid', header: $.strings.dashboards.unPaid, width: 150
                }
            ]
        }
    }
    getSchemeSalary = () => {
        return {
            name: "salaryDashboardScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 1,
            columns: [

                {
                    name: 'rowNumber', header: '##', width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'Year', header: $.strings.dashboards.year, width: "*"
                },
                {
                    name: 'Mon', header: $.strings.dashboards.month, width: 150
                },
                {
                    name: 'Count', header: $.strings.dashboards.empCount, width: 150
                },
                {
                    name: 'Total', header: $.strings.dashboards.daysTotal, width: 150
                }
            ]
        }
    }
    getSchemeNationality = () => {
        return {
            name: "nationalityDashboardScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 1,
            columns: [

                {
                    name: 'rowNumber', header: '##', width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'nationality', header: $.strings.dashboards.nationality, width: "*"
                },
                {
                    name: 'Count', header: $.strings.dashboards.empCount, width: 150
                },
                {
                    name: 'Percent', header: $.strings.dashboards.percent, width: 150
                }
            ]
        }
    }
}