import React from "react";

export default class MainTemplate extends React.Component {

    render() {

        return (
            <>
                <span className="p-column-title">{this.props.header}</span>
                <span style={{ color: this.props.textColor ? this.props.textColor : '' }}>{this.props.data}</span>
            </>
        );
    }
}