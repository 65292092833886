import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util';
import DataGrid from '../../Components/DataGridView/DataGridView';
import ButtonsBarLookupList from "../../Components/ButtonsBarLookupList";
import Card from "../../Components/Card";
import Dropdown from "../../Components/Dropdown";
import MultiSelect from "../../Components/MultiSelect";
import Calendar from "../../Components/Calendar";
import InputTime from "../../Components/InputTime";
import { Accordion, AccordionTab } from "primereact/accordion";
import SiteMap from "../../Components/SiteMap";
import Checkbox from "../../Components/Checkbox";
import AdditionalFilters from "../AdditionalFilters/AdditionalFilters";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class OverTimeBalances extends React.Component {

    constructor(props) {
        super(props);
        this.nowDate = new Date();
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            pageInfo: this.setPageInfo(),
            sectionsList: [],
            employeesList: [],
            data: [],
            dataObject: {
                section: [],
                employees: [],
                fromDate: new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), 1),
                toDate: this.nowDate,
                displayReportInFractions: false,
                onlyWorkingStaff: true,
                additionalFiltersObj: undefined
            }
        }
    }
    componentDidMount = async () => {
        if (!Util.checkISHR() || !Util.checkRights(544)) {
            Util.goTo('/Access');
        }
        let _isDesktop = window.innerWidth > 1250

        let sectionsList = await this.getSections();
        let employeesList = await this.getEmployeesList();
        this.setState({ employeesList: employeesList, sectionsList: sectionsList, isDesktop: _isDesktop, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    handleMultiSelect = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const gridStyle = { minHeight: '40vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div style={{ direction: $.strings.dir }}>
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    <ButtonsBarLookupList onRefresh={e => this.onRefresh(false)} onExcelExport={this.excelExport} />
                    <Messages innerRef={(el) => this.messages = el} />
                    <AdditionalFilters ref={e => this.dlgAdditionalFilters = e} onSuccess={this.onAdditionalFiltersSuccess} />
                </Card>
                <div ref="divMain" >
                    <Accordion activeIndex={0}>
                        <AccordionTab header={$.strings.filterCaption}>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <MultiSelect
                                        id="section"
                                        innerRef={e => this.drpSection = e}
                                        value={this.state.dataObject.section}
                                        options={this.state.sectionsList}
                                        caption={$.strings.overTimeBalances.section}
                                        onChange={(e) => this.handleUserInput(e)}
                                        optionLabel="Name"
                                        display="chip"
                                        maxSelectedLabels="2"
                                        filter={true}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <MultiSelect
                                        id="employees"
                                        value={this.state.dataObject.employees}
                                        options={this.state.employeesList}
                                        caption={$.strings.overTimeBalances.employee}
                                        onChange={this.handleMultiSelect}
                                        optionLabel="name"
                                        display="chip"
                                        maxSelectedLabels="2"
                                        showSelectAll={false}
                                        filter
                                    />
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <Calendar
                                        id="fromDate"
                                        innerRef={(el) => this.dtpFromDate = el}
                                        value={this.state.dataObject.fromDate}
                                        caption={$.strings.overTimeBalances.fromDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <Calendar
                                        id="toDate"
                                        innerRef={(el) => this.dtpFromDate = el}
                                        value={this.state.dataObject.toDate}
                                        caption={$.strings.overTimeBalances.toDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                    <Checkbox id="onlyWorkingStaff" caption={$.strings.overTimeBalances.onlyWorkingStaff}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, onlyWorkingStaff: e.checked } })}
                                        checked={this.state.dataObject.onlyWorkingStaff} />
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                    <Checkbox id="displayReportInFractions" caption={$.strings.overTimeBalances.displayReportInFractions}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, displayReportInFractions: e.checked } })}
                                        checked={this.state.dataObject.displayReportInFractions} />
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                    <Button label={$.strings.additionalFilters.title} className={this.state.dataObject.additionalFiltersObj ? 'p-button-warning' : ''}
                                        onClick={e => this.dlgAdditionalFilters.show()} />
                                    <Button label="" icon="pi pi-filter-slash" visible={this.state.dataObject.additionalFiltersObj} tooltip={$.strings.additionalFilters.clearFilters}
                                        onClick={e => this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: undefined } })}
                                    />
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <DataGrid
                                    style={gridStyle}
                                    ref={e => this.gridData = e}
                                    isReport={true}
                                    theme="default-light"
                                    idProperty="RowNumber"
                                    exportFileName={this.state.pageInfo.title}
                                    scheme={this.props.reportType === "OverTimeBalance" ? this.getScheme() : this.getSchemeSpecialLeaves()}
                                    dataSource={this.state.data}
                                />
                            </div>
                        </div>
                    </Card>
                </div >
            </div>
        );
    }
    onAdditionalFiltersSuccess = (obj) => {
        if (obj) {
            this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: obj } })
        }
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.splice(0, 1);
            }
        }
        return dataTemp
    }
    getEmployeesList = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: !this.state.empCodeOrName || this.state.empCodeOrName.length <= 0 ? '' : this.state.empCodeOrName,
            isHr: Util.checkISHR(),
            getEmpStatus: this.state.getEmpStatus ? this.state.getEmpStatus : false,
            section: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            empStatus: this.state.empStatus && this.state.empStatus.id >= 0 ? this.state.empStatus.id : -1,
        }

        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;

        }
        return dataTemp
    }

    onRefresh = async (fromLoad) => {
        if (!this.state.dataObject.fromDate) {
            Util.showErrorMsg(this.messages, $.strings.overTimeBalances.fromDateError)
            return;
        }
        if (!this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.overTimeBalances.toDateError)
            return;
        }
        if (this.state.dataObject.fromDate > this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.overTimeBalances.dateError)
            return;
        }

        if (!fromLoad) {
            this.setState({ loading: true });
        }
        let data = []
        let generalFillter = {};

        if (this.state.dataObject.section) {
            let sectionsList = []
            for (let index = 0; index < this.state.dataObject.section.length; index++) {
                sectionsList.push(this.state.dataObject.section[index].Code)
            }
            generalFillter.SectionList = sectionsList;
        }
        if (this.state.dataObject.employees) {
            let empList = [];
            for (let index = 0; index < this.state.dataObject.employees.length; index++) {
                empList.push(this.state.dataObject.employees[index].code)
            }
            generalFillter.EmpList = empList;
        }
        Util.fillAditionalsFilters(this.state.dataObject.additionalFiltersObj, generalFillter);

        let obj = {
            FromDate: this.state.dataObject.fromDate,
            ToDate: this.state.dataObject.toDate,
            DisplayReportInFractions: this.state.dataObject.displayReportInFractions,
            OnlyWorkingStaff: this.state.dataObject.onlyWorkingStaff,
            GeneralFillter: generalFillter
        }
        let res;
        if (this.props.reportType === "OverTimeBalance") {
            res = await http.postMultipartAxios(http.actions.Reports.urlGetOverTimeBalanceReport, obj, null, null, 0);
        }
        else {
            res = await http.postMultipartAxios(http.actions.Reports.urlGetSpecialLeaveReport, obj, null, null, 0);
        }
        if (res && res.status === 200) {
            if (res.data && res.data.success)
                data = res.data.list;
        }
        if (!fromLoad) {
            this.setState({ data: data, loading: false });
        }
        else {
            return data;
        }
    }

    excelExport = () => {
        this.gridData.exportCSV();
    }

    getScheme = () => {
        return {
            name: "overTimeBalanceScheme_Table",
            filter: false,
            showFooter: true,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [

                { name: 'RowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt' },
                { name: 'EmpCode', header: $.strings.overTimeBalances.empCode, width: 120, },
                { name: 'EmpName', header: $.strings.overTimeBalances.empName, width: "*", minWidth: 120, },
                { name: 'StartBalance', header: $.strings.overTimeBalances.startBalance, width: 150, dataType: 'Object', customAggregate: true },
                { name: 'Balance', header: $.strings.overTimeBalances.balance, width: 150, dataType: 'Object', customAggregate: true },
                { name: 'NewTransactionBalance', header: $.strings.overTimeBalances.newTransactionBalance, width: 150, dataType: 'Object', customAggregate: true },
                { name: 'CurrentBalance', header: $.strings.overTimeBalances.currentBalance, width: 150, dataType: 'Object', customAggregate: true },
            ]
        }
    }
    getSchemeSpecialLeaves = () => {
        return {
            name: "specialLeaveBalanceScheme_Table",
            filter: false,
            showFooter: true,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [

                { name: 'RowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt' },
                { name: 'EmpCode', header: $.strings.specialLeavesBalances.empCode, width: 120, },
                { name: 'EmpName', header: $.strings.specialLeavesBalances.empName, width: "*", minWidth: 120, },
                { name: 'StartBalance', header: $.strings.specialLeavesBalances.startBalance, width: 150, dataType: 'Object', customAggregate: true },
                { name: 'Balance', header: $.strings.specialLeavesBalances.balance, width: 150, dataType: 'Object', customAggregate: true },
                { name: 'NewTransactionBalance', header: $.strings.specialLeavesBalances.discountFromBalance, width: 150, dataType: 'Object', customAggregate: true },
                { name: 'CurrentBalance', header: $.strings.specialLeavesBalances.currentBalance, width: 150, dataType: 'Object', customAggregate: true },
            ]
        }
    }
    setPageInfo = () => {
        let pageId
        let title = this.props.reportType === "OverTimeBalance" ? $.strings.overTimeBalances.title : $.strings.specialLeavesBalances.title

        return { pageId: pageId, title: title };
    };
}