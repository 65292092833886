import React from "react";
import Button from '../../Components/Button'
import Util from "../../Util";
import Dialog from '../../Components/Dialog';
import styles from './MyStaff.module.scss';
import ClockTransEdit from "../ClockTrans/ClockTransEdit";
import WebActivation from "../WebActivation/WebActivation";
import AttendanceTrans from "../FilesAndSettings/AttendanceTrans";
import ChangeEmpCode from "./ChangeEmpCode";

const $ = window.$;

export default class Operations extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            data: [],
            dataObject: {

            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <Dialog header={this.state.empName} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                style={{ width: this.state.isDesktop ? 'auto' : 'auto', direction: $.strings.dir }}
                modal onHide={this.onHide}>
                <ClockTransEdit ref={e => this.dlgClockTransEdit = e} />
                <WebActivation ref={e => this.dlgWebActivation = e} />
                <AttendanceTrans ref={e => this.dlgAttendnanceTrans = e} />
                <ChangeEmpCode ref={e => this.dlgChangeEmployeeCode = e} onSuccess={this.onChangeEmpCodeSuccess} />

                <div ref="divMain" >
                    {
                        (Util.checkISManager() || Util.checkISDelegated()) &&
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-3 p-sm-12" style={{ paddingTop: '15px' }}>
                                {
                                    (Util.checkISManager() || Util.checkISDelegated())&& Util.checkRights(652) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.attendanceReport} onClick={this.attendanceReport} />
                                        </div>
                                    </div>
                                }
                                {
                                    (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(658) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.vacBalances} onClick={this.vacBalances} />
                                        </div>
                                    </div>
                                }
                                {
                                    (Util.checkISManager() || Util.checkISDelegated())&& Util.checkRights(782) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.specialLeave} onClick={this.specialLeave} />
                                        </div>
                                    </div>
                                }
                                {
                                    (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(781) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.vacLeave} onClick={this.vacLeave} />
                                        </div>
                                    </div>
                                }
                                {
                                    (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(783) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.overTime} onClick={this.overTime} />
                                        </div>
                                    </div>
                                }
                                {
                                    (Util.checkISManager() || Util.checkISDelegated())&& Util.checkRights(784) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.memo} onClick={this.memo} />
                                        </div>
                                    </div>
                                }
                                {
                                    (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(786) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.workLeave} onClick={this.workLeave} />
                                        </div>
                                    </div>
                                }
                                {
                                    (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(785) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.noFingerPrint} onClick={this.noFingerPrint} />
                                        </div>
                                    </div>
                                }
                                {
                                    (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(659) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.empInfo} onClick={this.empInfo} />
                                        </div>
                                    </div>
                                }
                                {
                                    (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(662) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.salarySlip} onClick={this.salarySlip} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        Util.checkISHR() &&
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-3 p-sm-12" style={{ paddingTop: '15px' }}>
                                {
                                    Util.checkISHR() && Util.checkRights(715) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.vacBalances} onClick={this.vacBalances} />
                                        </div>
                                    </div>
                                }
                                {
                                    Util.checkISHR() && Util.checkRights(702) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.editClockTrans} onClick={this.editClockTrans} />
                                        </div>
                                    </div>
                                }
                                {
                                    Util.checkISHR() && Util.checkRights(700) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.insertTrans} onClick={this.addAttendanceTrans} />
                                        </div>
                                    </div>
                                }
                                {
                                    Util.checkISHR() && Util.checkRights(710) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.attendanceReport} onClick={this.attendanceReport} />
                                        </div>
                                    </div>
                                }
                                {
                                    Util.checkISHR() && Util.checkRights(736) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.salarySlip} onClick={this.salarySlip} />
                                        </div>
                                    </div>
                                }
                                {
                                    Util.checkISHR() && Util.checkRights(659) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.empInfo} onClick={this.empInfo} />
                                        </div>
                                    </div>
                                }
                                {
                                    Util.checkISHR() && Util.checkRights(782) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.myStaff.specialLeave} onClick={this.specialLeave} />
                                        </div>
                                    </div>
                                }
                                {
                                    Util.checkISHR() &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.webActivation.title} onClick={this.webActivation} />
                                        </div>
                                    </div>
                                }
                                {
                                    Util.checkISHR() && Util.checkRights(968) &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <Button className={styles.button} label={$.strings.changeEmpCode.title} onClick={this.changeEmployeeCode} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div >
            </Dialog >
        );
    }
    show = async (empCode, empName) => {
        this.setState({ visibleDialog: true, empCode: empCode, empName: empName })
    }
    onHide = () => {
        this.setState({ visibleDialog: false })
    }

    attendanceReport = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo('/Reports/AttendanceReport/' + tempCode);
    }
    vacBalances = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo('/Reports/VacBalance/' + tempCode);
    }
    editClockTrans = () => {
        this.dlgClockTransEdit.show(this.state.empCode, new Date(), 0);
    }
    addAttendanceTrans = async () => {
        await this.dlgAttendnanceTrans.show(0, this.state.empCode, this.state.empName)
    }
    specialLeave = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/SpecialLeave/" + tempCode + "/Add");
    }
    vacLeave = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/Vacations/" + tempCode + "/Add");
    }
    overTime = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/OverTime/" + tempCode + "/Add");
    }
    memo = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/Memos/" + tempCode + "/Add");
    }
    workLeave = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("../Requests/WorkLeave/" + tempCode + "/Add");
    }
    noFingerPrint = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/FingerPrint/" + tempCode + "/Add");
    }
    empInfo = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Reports/EmpInfo/" + tempCode);
    }
    salarySlip = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Reports/SalarySlip/" + tempCode);
    }
    webActivation = async () => {
        await this.dlgWebActivation.show(this.state.empCode);
    }
    changeEmployeeCode = async () => {
        await this.dlgChangeEmployeeCode.show(this.state.empCode, this.state.empName);
    }

    onChangeEmpCodeSuccess = async () => {
        if (this.props.onSuccess) {
            await this.props.onSuccess();
        }
        this.onHide();
    }
}