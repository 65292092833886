import React from "react";
import styles from './Vacations.module.scss';
import http from '../../Api/http';
import ProgressSpinner from '../../Components/ProgressSpinner';
import Util from "../../Util";
import Input from '../../Components/Input';
import Dropdown from '../../Components/Dropdown';
import Calendar from '../../Components/Calendar';
import InputTextArea from '../../Components/InputTextArea';
import Card from "../../Components/Card";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup';
import Messages from '../../Components/Messages';
import Vacations from "./Vacations";
import { FileUpload } from "primereact/fileupload";

const $ = window.$

export default class Vac extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            success: false,
            resultText: '',
            isDesktop: true,
            formErrors: { empCode: '', empName: '', transType1: '', transType2: '', transTime1: '', transTime2: '', notes: '' },
            language: localStorage.getItem("HRlang") || "ar",
            dataObject: {
                id: 0,
                notID: '',
                success: false,
                saveSuccess: false,
                resultText: '',
                empCode: undefined,
                empName: undefined,
                fromDate: undefined,
                toDate: undefined,
                vacType: undefined,
                paidVacType: undefined,
                notes: ''
            }
        };
    }

    componentDidMount = async () => {
        await this.loadLanguage();
        let _isDesktop = this.updatePredicate();

        this.vacTypesList = Util.getVacationsTypes();
        let paidVacTypePromise = this.getPaidVacationsTypes();
        let paidVacTypesList = await paidVacTypePromise;

        this.attachmentsRequiredInSickVacations = await Util.GetSettingByIdMailHandle(212);

        this.setState({
            paidVacTypesList: paidVacTypesList,
            dataObject: { ...this.state.dataObject, vacType: this.vacTypesList && this.vacTypesList.length > 0 ? this.vacTypesList[1] : undefined },
            isDesktop: _isDesktop, loading: false
        });

        await this.getVacDetails()
        //this.setState({ dataObject: { ...this.state.dataObject, success: true }, isDesktop: _isDesktop, loading: false })
    }

    loadLanguage = async lang => {
        $.strings = await import(`../../Languages/${this.state.language}.json`);
        $.pages = await import('../../pages.json')
        $.language = lang
    };

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    render() {
        let color = this.state.success ? 'green' : 'red';
        return (
            <div ref="divMain" style={{ minHeight: '70vh', margin: '5%' }} dir={$.strings.dir}>
                {this.state.dataObject.success && !this.state.dataObject.saveSuccess &&
                    < Card title={$.strings.vacations ? $.strings.vacations.attDetails : 'ادخال تفاصيل الدوام'}>
                        <ProgressSpinner loading={this.state.loading} />
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-md-12" >
                                <ButtonsBarLookup onSave={this.onSave} />
                            </div>
                        </div>
                        <div className="p-grid" style={{ width: '100%' }}>
                            <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                                <Messages innerRef={(el) => this.messages = el} />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtEmpCode = el}
                                    type="text"
                                    id="empCode"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.empCode}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.vacations ? $.strings.vacations.empCode : 'رقم الموظف'}
                                    readOnly={true}
                                    disabled
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtEmpName = el}
                                    type="text"
                                    id="empName"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.empName}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.vacations ? $.strings.vacations.empName : 'اسم الموظف'}
                                    readOnly={true}
                                    disabled
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-6 p-lg-6 p-sm-12">
                                <Calendar
                                    id="fromDate"
                                    innerRef={(el) => this.dtpFromDate = el}
                                    ref={(el) => this.dtpFromDate = el}
                                    value={this.state.dataObject.fromDate}
                                    caption={$.strings.vacations ? $.strings.vacations.fromDate : 'الى تاريخ'}
                                    readOnlyInput={true}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    disabled={true}
                                />
                            </div>
                            <div className="p-col-6 p-lg-6 p-sm-12">
                                <Calendar
                                    id="toDate"
                                    innerRef={(el) => this.dtpToDate = el}
                                    ref={(el) => this.dtpToDate = el}
                                    value={this.state.dataObject.toDate}
                                    caption={$.strings.vacations ? $.strings.vacations.toDate : 'الى تاريخ'}
                                    onChange={this.handleToDate}
                                    readOnlyInput={true}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Dropdown
                                    id="vacType"
                                    innerRef={e => this.drpVacType = e}
                                    value={this.state.dataObject.vacType || ''}
                                    options={this.vacTypesList}
                                    caption={$.strings.vacations ? $.strings.vacations.vacType : 'نوع الحركة'}
                                    onChange={this.handleUserInput}
                                    formErrors={this.state.formErrors}
                                    optionLabel="name"
                                    placeholder={$.strings.vacations ? $.strings.vacations.selectVacType : 'اختر نوع الحركة'}
                                    isRequired={true}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div >
                            {
                                this.state.dataObject.vacType && this.state.dataObject.vacType.id + '' === '8' &&
                                <div className="p-col-12 p-lg-6 p-sm-12">
                                    <Dropdown
                                        id="paidVacType"
                                        innerRef={e => this.drpPaidVacType = e}
                                        value={this.state.dataObject.paidVacType}
                                        options={this.state.paidVacTypesList}
                                        caption={$.strings.vacations ? $.strings.vacations.paidVacType : 'نوع الاجازة المدفوعة'}
                                        onChange={this.handleUserInput}
                                        formErrors={this.state.formErrors}
                                        optionLabel="name"
                                        placeholder={$.strings.vacType ? $.strings.vacType.selectPaidVacType : 'اختر نوع الاجازة المدفوعة'}
                                        isRequired={true}
                                        disabled={this.state.dataObject.id > 0 ? true : false}
                                    />
                                </div >
                            }
                        </div >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtAddress = el}
                                    type="text"
                                    id="address"
                                    maxLength="50"
                                    value={this.state.dataObject.address || ''}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.vacations ? $.strings.vacations.address : 'العنوان أثناء الاجازة'}
                                    isRequired={true}
                                    onChange={this.handleUserInput}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtPhone = el}
                                    type="text"
                                    id="phone"
                                    maxLength="70"
                                    value={this.state.dataObject.phone || ''}
                                    autofocus={true}
                                    caption={$.strings.vacations ? $.strings.vacations.phone : 'هاتف'}
                                    onChange={this.handleUserInput}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    style={{ width: '100%' }}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <InputTextArea
                                    innerRef={ref => this.txtNotes = ref}
                                    type="text"
                                    id="notes"
                                    maxLength="100"
                                    value={this.state.dataObject.notes}
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.notes : 'ملاحظات'}
                                    onChange={this.handleUserInput}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    style={{ backgroundColor: 'white' }}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <div >
                                    <label for="file-upload" className={styles.customFileUpload}>
                                        {$.strings.vacations.uploadFile}
                                    </label>
                                    {
                                        // <Input id="file-upload" type="file" accept="*/*" onChange={this.handleChange}
                                        //     disabled={this.state.dataObject.id > 0 ? true : false}
                                        // />
                                    }
                                    <FileUpload chooseLabel={$.strings.vacations.uploadFile} name="file-upload-prime" url=""
                                            onSelect={this.handleChangePrime} auto maxFileSize={5000000} disabled={this.state.dataObject.id > 0}
                                            invalidFileSizeMessageSummary={$.strings.fileSizeError}
                                            invalidFileSizeMessageDetail={""} />
                                </div>
                            </div>
                        </div>
                    </Card >
                }
                {
                    (!this.state.dataObject.success || this.state.dataObject.saveSuccess) &&
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12" style={{ textAlign: 'center' }}>
                            <span style={{ color: color, fontSize: '18px', fontWeight: 'bold' }}>{this.state.resultText}</span>
                        </div>
                    </div>
                }
            </div >
        );
    }

    handleChange = (event) => {
        if (event.target.files.length > 0) {
            let _uploadedFile = URL.createObjectURL(event.target.files[0])
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.target.files[0].size > 4 * 1024 * 1024) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                document.getElementById('file-upload').value = ''
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.target.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }

    handleChangePrime = (event) => {
        if (event.files.length > 0) {
            let _uploadedFile = event.files[0];
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.files[0].size > 5 * 1000 * 1000) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }
    
    setSubscriberImgSrc = () => {
        // if (this.state.dataObject.photo_id > 0 && this.state.dataObject.imgBytes !== null && this.state.dataObject.imgBytes.length > 0) {
        //     return `data:image/jpeg;base64,${this.state.dataObject.imgBytes}`
        // }
        // else {
        //     return this.state.dataObject.img
        // }
        alert($.strings.fileUploaded)
    }

    getPaidVacationsTypes = async () => {
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetPaidVacType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }

    getVacDetails = async () => {
        this.setState({ loading: true })
        let obj = {
            NotID: this.props.match.params.NotID,
            Date: this.props.match.params.Date,
            EmpCode: this.props.match.params.EmpCode
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetVacInfoFromEmail, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                let fromDate = new Date(2000, 1, 1);
                if (res.data.fromDate && res.data.fromDate.length > 0) {
                    let temp = new Date(res.data.fromDate)
                    if (temp.getFullYear() > 2000) {
                        fromDate = temp
                    }
                }
                this.setState({
                    dataObject: {
                        success: true, notID: res.data.notID, empCode: res.data.empCode, empName: res.data.empName,
                        fromDate: fromDate, toDate: fromDate
                    }, loading: false
                })
            }
            else {
                let resultText = $.strings.operationFailed;
                if (res.data.errorText) {
                    resultText = res.data.errorText;
                }
                this.setState({ loading: false, success: false, resultText: resultText });
            }
        }
        else {
            this.setState({ loading: false, success: false, resultText: $.strings.operationFailed })
        }
    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;
        let notId = this.state.dataObject.notID;
        if (!notId || notId === "" || notId === "0") {
            Util.showErrorMsg(this.messages, $.strings.vacations.vacNotError)
            return false;
        }
        let _empCode = this.state.dataObject.empCode
        fieldValidationErrors.empCode = _empCode ? '' : $.strings.requiredFiled;
        if (fieldValidationErrors.empCode && fieldValidationErrors.empCode.length > 0) {
            return false;
        }

        let _empName = this.state.dataObject.empName;
        fieldValidationErrors.empName = _empName ? '' : $.strings.requiredFiled;
        if (fieldValidationErrors.empName && fieldValidationErrors.empName.length > 0) {
            return false;
        }

        if (!this.state.dataObject.fromDate || this.state.dataObject.fromDate === "") {
            this.dtpFromDate.focusInput.focus()
            Util.showErrorMsg(this.messages, $.strings.vacations.fromDateError)
            return false;
        }
        if (!this.state.dataObject.toDate || this.state.dataObject.toDate === "") {
            this.dtpToDate.focusInput.focus()
            Util.showErrorMsg(this.messages, $.strings.vacations.toDateError)
            return false;

        }

        if (!this.state.dataObject.vacType) {
            this.drpVacType.focusInput.focus()
            Util.showErrorMsg(this.messages, $.strings.vacations.vacTypeError)
            return false;
        }

        if (this.state.dataObject.vacType && this.state.dataObject.vacType.id === 8 && !this.state.dataObject.paidVacType) {
            this.drpPaidVacType.focusInput.focus()
            Util.showErrorMsg(this.messages, $.strings.vacations.paidVacTypeError)
            return false;
        }

        if (this.state.dataObject.address === undefined || this.state.dataObject.address.trim() === "") {
            Util.showErrorMsg(this.messages, $.strings.vacations.addressError)
            this.txtAddress.focus()
            return false;
        }
        if (this.state.dataObject.phone === undefined || this.state.dataObject.phone.trim() === "") {
            Util.showErrorMsg(this.messages, $.strings.vacations.phoneError)
            this.txtPhone.focus()
            return false;
        }
        if (this.state.dataObject.notes === undefined || this.state.dataObject.notes.trim() === "") {
            Util.showErrorMsg(this.messages, $.strings.vacations.notesError)
            this.txtNotes.focus()
            return false;
        }

        if (this.state.dataObject.vacType && this.state.dataObject.vacType.id === 6 && this.attachmentsRequiredInSickVacations === "1") {
            fieldValidationErrors.vacType = $.strings.vacations.selectAFile;
            Util.showErrorMsg(this.messages, $.strings.vacations.selectAFile)
        }

        return true
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true })
        let files = [] // ملفات المرفقات
        let file = undefined

        let obj = {
            notID: this.state.dataObject.notID,
            empCode: this.state.dataObject.empCode,
            empName: this.state.dataObject.empName,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            leaveType: this.state.dataObject.vacType.id,
            paidLeaveType: this.state.dataObject.paidVacType && this.state.dataObject.paidVacType.id >= 0 ? this.state.dataObject.paidVacType.id : "-1",
            address: this.state.dataObject.address,
            phone: this.state.dataObject.phone,
            notes: this.state.dataObject.notes
        }

        let res = await http.postMultipartAxios(http.actions.HolidaysReq.urlPostSaveVacationFromEmail, obj, file, files, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                Util.showSuccessMsg(this.messages, $.strings.operationDone);
                this.setState({ dataObject: { ...this.state.dataObject, id: res.id, success: true, saveSuccess: true }, success: true, resultText: $.strings.operationDone, loading: false });
                return true;
            }
        }
        if (res.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return false
        }
    }
}
