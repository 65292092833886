import React from "react";
import styles from './Substitute.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import Calendar from '../../Components/Calendar'
import SiteMap from '../../Components/SiteMap';
import ScrollTop from '../ScrollButton';
import Editor from '../../Components/InputEditor';
import Checkbox from "../../Components/Checkbox";
import DataGridView from "../../Components/DataGridView/DataGridView";
import { Fieldset } from 'primereact/fieldset';
import RadioButton from "../../Components/RadioButton";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class NotificationsSettings extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.nowDate = new Date();

        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { notificationType: '', repeatedNotificationDays: '', notifyBefore: '' },
            deleteErrors: { deleteNote: '' },
            isDesktop: window.innerWidth > 1250,
            notificationsTypesList: [],
            data: [],
            dataObject: {
                id: 0,
                notificationType: undefined,
                notifyHR: false,
                notifyEmployee: false,
                notifyDirectManager: false,
                sendEmail: false,
                repeatedNotification: false,
                repeatedNotificationDM: false,
                repeatedNotificationHR: false,
                repeatedNotificationDays: undefined,
                notifyBefore: undefined
            }
        }
    }
    componentDidMount = async () => {
        console.log($.license)
        if (!$.license.Notifications_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }
        if (!Util.checkRights(735)) {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = window.innerWidth > 1250;
        let notificationsTypes = await this.getNotificationsTypes();
        let notificationsSettings = await this.getData();
        this.setState({
            data: notificationsSettings, notificationsTypesList: notificationsTypes,
            dataObject: { ...this.state.dataObject, notificationType: notificationsTypes && notificationsTypes.length > 0 ? notificationsTypes[0] : undefined },
            isDesktop: _isDesktop, loading: false
        });
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }
    handleNotificationType = (e) => {
        let typeId = e.target.value.ID;
        typeId = Util.encryptStringToHexa(typeId)
        this.setState({ dataObject: { ...this.state.dataObject, notificationType: e.target.value } }, async () => await this.getDataByType(typeId));
    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;

        let _notificationType = true;
        fieldValidationErrors.notificationType = '';
        if (!this.state.dataObject.notificationType || this.state.dataObject.notificationType.ID <= 0) {
            fieldValidationErrors.notificationType = $.strings.requiredFiled;
            _notificationType = false;
        }

        // let _details = true;
        // let objEditor = this.editor.editorElement.__quill;
        // let editorText = objEditor.getText(0);
        // if (!objEditor || !editorText || editorText.trim().length <= 0) {
        //     fieldValidationErrors.details = $.strings.requiredFiled;
        //     _details = false;
        // }


        let _repeatedNotificationDays = true;
        fieldValidationErrors.repeatedNotificationDays = "";
        if (!this.state.dataObject.repeatedNotificationDays) {
            fieldValidationErrors.repeatedNotificationDays = $.strings.requiredFiled;
            _repeatedNotificationDays = false;
        }

        let _notifyBefore = true;
        fieldValidationErrors.notifyBefore = "";
        if (!this.state.dataObject.notifyBefore) {
            fieldValidationErrors.notifyBefore = $.strings.requiredFiled;
            _notifyBefore = false;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            notificationTypeValid: _notificationType,
            //detailsValid: _details,
            repeatedNotificationDaysValid: _repeatedNotificationDays,
            notifyBeforeValid: _notifyBefore
        }, this.validateForm);

        return _notificationType && _repeatedNotificationDays && _notifyBefore;
    }
    validateForm() {
        let isFormValid = this.state.notificationTypeValid && this.state.repeatedNotificationDaysValid && this.state.notifyBeforeValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        let siteMapItems = [
            { label: $.strings.links.staticFilesAndSettings },
            {
                label: <div>
                    {$.strings.notificationsSettings.title + " "}
                </div>
            }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        const gridStyle = { minHeight: '45vh' }
        return (
            <div ref="divMain" className={styles.substituteSettings}>
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={this.resetFields}
                            onSave={this.onSave}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid" style={{ width: '100%' }}>
                    <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="notificationType"
                                value={this.state.dataObject.notificationType || ''}
                                options={this.state.notificationsTypesList}
                                caption={$.strings.notificationsSettings.notificationType}
                                onChange={this.handleNotificationType}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                optionLabel="Name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Fieldset legend={$.strings.notificationsSettings.notifyHR}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="notifyHR_Yes"
                                            value={$.strings.notificationsSettings.yes}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, notifyHR: true } })}
                                            checked={this.state.dataObject.notifyHR === true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="notifyHR_No"
                                            value={$.strings.notificationsSettings.no}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, notifyHR: false } })}
                                            checked={this.state.dataObject.notifyHR === false}
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Fieldset legend={$.strings.notificationsSettings.notifyEmployee}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="notifyEmployee_Yes"
                                            value={$.strings.notificationsSettings.yes}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, notifyEmployee: true } })}
                                            checked={this.state.dataObject.notifyEmployee === true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="notifyEmployee_No"
                                            value={$.strings.notificationsSettings.no}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, notifyEmployee: false } })}
                                            checked={this.state.dataObject.notifyEmployee === false}
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Fieldset legend={$.strings.notificationsSettings.notifyDirectManager}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="notifyDirectManager_Yes"
                                            value={$.strings.notificationsSettings.yes}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, notifyDirectManager: true } })}
                                            checked={this.state.dataObject.notifyDirectManager === true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="notifyDirectManager_No"
                                            value={$.strings.notificationsSettings.no}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, notifyDirectManager: false } })}
                                            checked={this.state.dataObject.notifyDirectManager === false}
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Fieldset legend={$.strings.notificationsSettings.sendEmail}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="sendEmail_Yes"
                                            value={$.strings.notificationsSettings.yes}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, sendEmail: true } })}
                                            checked={this.state.dataObject.sendEmail === true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="sendEmail_No"
                                            value={$.strings.notificationsSettings.no}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, sendEmail: false } })}
                                            checked={this.state.dataObject.sendEmail === false}
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Fieldset legend={$.strings.notificationsSettings.repeatedNotification}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="repeatedNotification_Yes"
                                            value={$.strings.notificationsSettings.yes}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, repeatedNotification: true } })}
                                            checked={this.state.dataObject.repeatedNotification === true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="repeatedNotification_No"
                                            value={$.strings.notificationsSettings.no}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, repeatedNotification: false } })}
                                            checked={this.state.dataObject.repeatedNotification === false}
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Fieldset legend={$.strings.notificationsSettings.repeatedNotificationHR}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="repeatedNotificationHR_Yes"
                                            value={$.strings.notificationsSettings.yes}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, repeatedNotificationHR: true } })}
                                            checked={this.state.dataObject.repeatedNotificationHR === true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="repeatedNotificationHR_No"
                                            value={$.strings.notificationsSettings.no}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, repeatedNotificationHR: false } })}
                                            checked={this.state.dataObject.repeatedNotificationHR === false}
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Fieldset legend={$.strings.notificationsSettings.repeatedNotificationDM}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="repeatedNotificationDM_Yes"
                                            value={$.strings.notificationsSettings.yes}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, repeatedNotificationDM: true } })}
                                            checked={this.state.dataObject.repeatedNotificationDM === true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="repeatedNotificationDM_No"
                                            value={$.strings.notificationsSettings.no}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, repeatedNotificationDM: false } })}
                                            checked={this.state.dataObject.repeatedNotificationDM === false}
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Fieldset legend={$.strings.notificationsSettings.repeatedNotificationDays} style={{ minHeight: '62px' }}>
                                <Input
                                    id="repeatedNotificationDays"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.repeatedNotificationDays || ''}
                                    autofocus={true}
                                    onChange={this.handleUserInput}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Fieldset legend={$.strings.notificationsSettings.notifyBefore} style={{ minHeight: '62px' }}>
                                <Input
                                    id="notifyBefore"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.notifyBefore || ''}
                                    autofocus={true}
                                    onChange={this.handleUserInput}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataGridView
                                style={gridStyle}
                                ref={e => this.gridData = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="rowNumber"
                                scheme={this.getScheme()}
                                dataSource={this.state.data}
                                exportFileName={$.strings.notificationsSettings.time}
                            />
                        </div>
                    </div>
                </Card>
                <ScrollTop />
            </div >
        );
    }

    getNotificationsTypes = async () => {
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.Notifications.urlGetNotificationsType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }

    getData = async (id) => {
        let dataTemp = []
        let obj = {
            id: id && id.length > 0 ? id : Util.encryptStringToHexa('')
        }
        let res = await http.getJsonAxios(http.actions.Notifications.urlGetNotificationsSettings, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDataByType = async (id) => {
        this.setState({ loading: true });
        let dataTemp = []
        let obj = {
            id: id && id.length > 0 ? id : Util.encryptStringToHexa('')
        }
        let res = await http.getJsonAxios(http.actions.Notifications.urlGetNotificationSettingsByNotTypeId, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp && dataTemp.length > 0)
                this.loadData(dataTemp[0]);
            else
                this.setState({ loading: false });
        }
        else {
            this.setState({ loading: false });
        }
    }
    loadData = (data) => {
        let notType = this.state.notificationsTypesList.find(element => element.ID + "" === data.NoteType + "");
        let notifyHR = true;
        if (data.NotifyHR + "" === "1")
            notifyHR = false;
        let notifyEmployee = true;
        if (data.NotifyEmp + "" === "1")
            notifyEmployee = false;
        let notifyDirectManager = true;
        if (data.NotifiyDirectManager + "" === "1")
            notifyDirectManager = false;
        let sendEmail = true;
        if (data.SendEmail + "" === "1")
            sendEmail = false;
        let repeatedNotification = true;
        if (data.FrequentNotification + "" === "1")
            repeatedNotification = false;
        let repeatedNotificationHR = true;
        if (data.FrequentHR + "" === "0")
            repeatedNotificationHR = false;
        let repeatedNotificationDM = true;
        if (data.FrequentDM + "" === "0")
            repeatedNotificationDM = false;

        let repeatedNotificationDays = data.FrequentDays;
        let notifyBefore = data.Days;

        this.setState({
            mode: 'view',
            dataObject: {
                ...this.state.dataObject,
                id: data.ID,
                notificationType: notType,
                notifyHR: notifyHR,
                notifyEmployee: notifyEmployee,
                notifyDirectManager: notifyDirectManager,
                sendEmail: sendEmail,
                repeatedNotification: repeatedNotification,
                repeatedNotificationHR: repeatedNotificationHR,
                repeatedNotificationDM: repeatedNotificationDM,
                repeatedNotificationDays: repeatedNotificationDays,
                notifyBefore: notifyBefore
            }, loading: false
        });
    }

    onSave = async () => {
        this.messages.clear();
        if (!this.validateField()) {
            return false;
        }

        this.setState({ loading: true })
        let res = undefined;
        if (this.state.dataObject.id === 0) {
            res = await this.saveData(false);
        }
        else {
            res = await this.saveData(true);
        }
        if (res.status === 200) {
            let data = [];
            if (res.data.success) {
                let msg = $.strings.operationDone;
                Util.showSuccessMsg(this.messages, msg);
                this.resetFields()
                data = await this.getData();
                this.hash = this.hashState();
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data.errorText && res.data.errorText.length > 0) {
                    msg = res.data.errorText;
                }
                Util.showErrorMsg(this.messages, msg);
            }
            this.setState({ data: data, loading: false });
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data.errorText && res.data.errorText.length > 0) {
                msg = res.data.errorText;
            }
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false });
        }
    }
    saveData = async (isUpdate) => {
        let obj = {
            notificationType: this.state.dataObject.notificationType.ID,
            yesHR: this.state.dataObject.notifyHR,
            yesEmp: this.state.dataObject.notifyEmployee,
            yesDirect: this.state.dataObject.notifyDirectManager,
            yesEmail: this.state.dataObject.sendEmail,
            yesFreq: this.state.dataObject.repeatedNotification,
            frequentDays: this.state.dataObject.repeatedNotificationDays,
            yesFreqHR: this.state.dataObject.repeatedNotificationHR,
            yesFreqDM: this.state.dataObject.repeatedNotificationDM,
            days: this.state.dataObject.notifyBefore,
            id: this.state.dataObject.id
        }

        let res = await http.getJsonAxios(http.actions.Notifications.urlPostSaveNotificationSettings, obj, null, null, 0);
        return res;
    }

    resetFields = () => {
        this.nowDate = new Date();
        this.setState({
            dataObject: {
                id: 0,
                notificationType: this.state.notificationsTypesList && this.state.notificationsTypesList.length > 0 ? this.state.notificationsTypesList[0] : undefined,
                notifyHR: false,
                notifyEmployee: false,
                notifyDirectManager: false,
                sendEmail: false,
                repeatedNotification: false,
                repeatedNotificationDM: false,
                repeatedNotificationHR: false,
                repeatedNotificationDays: undefined,
                notifyBefore: undefined
            },
            formErrors: { notificationType: '', repeatedNotificationDays: '', notifyBefore: '' },
        });
    }

    onEdit = async (e, ctx) => {
        this.setState({ loading: true })
        let id = Util.encryptStringToHexa(ctx.item.ID);
        let objData = await this.getData(id);
        if (objData && objData.length > 0) {
            objData = objData[0];
            this.loadData(objData);
        }
        this.setState({ loading: false })
    }
    getScheme = () => {
        return {
            name: "substituteScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                {
                    name: 'rowNumber', header: '#', width: 50, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'onEdit', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.edit,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEdit(e, ctx)
                },
                {
                    name: 'ID', header: $.strings.notificationsSettings.code, width: 50,
                },
                {
                    name: 'Name', header: $.strings.notificationsSettings.notificationType, width: this.state.isDesktop ? 250 : 290,
                },
                {
                    name: 'NotifyHR', header: $.strings.notificationsSettings.notifyHR, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'notifyHRText', header: $.strings.notificationsSettings.notifyHR, width: 135,
                },
                {
                    name: 'notifyEmpText', header: $.strings.notificationsSettings.notifyEmployee, width: 105,
                },
                {
                    name: 'NotifyEmp', header: $.strings.notificationsSettings.notifyEmployee, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'NotifiyDirectManagerWord', header: $.strings.notificationsSettings.notifyDirectManager, width: 145,
                },
                {
                    name: 'NotifiyDirectManager', header: $.strings.notificationsSettings.notifyDirectManager, visible: false,
                },
                {
                    name: 'Days', header: $.strings.notificationsSettings.notifyBefore, width: 120, visible: false,
                },
                {
                    name: 'NoteType', header: $.strings.notificationsSettings.notificationType, visible: false
                },
                {
                    name: 'SendEmailWord', header: $.strings.notificationsSettings.sendEmail, width: 135,
                },
                {
                    name: 'FrequentNotificationWord', header: $.strings.notificationsSettings.repeatedNotification, width: 90
                },
                {
                    name: 'FrequentNotification', header: $.strings.notificationsSettings.repeatedNotification, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'FrequentDays', header: $.strings.notificationsSettings.repeatedNotificationDays, width: 140
                },
                {
                    name: 'SendEmail', header: $.strings.notificationsSettings.sendEmail, width: 200, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'FrequentHRName', header: $.strings.notificationsSettings.repeatedNotificationHR, width: 170,
                },
                {
                    name: 'FrequentDMName', header: $.strings.notificationsSettings.repeatedNotificationDM, width: 175,
                }
            ]
        }
    }
}