import React from "react";
import styles from "./Checkbox.module.scss";
import { Checkbox as PrimeCheckBox } from "primereact/checkbox";
import Label from "../../Components/Label";

export default class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div style={{ width: '100%' }}>
                <PrimeCheckBox ref={this.props.innerRef} className={styles.checkbox} {...this.props}>
                </PrimeCheckBox>
                {
                    this.props.caption &&
                    <Label htmlFor={this.props.inputId} isRequired={this.props.isRequired}>
                        {this.props.caption}
                    </Label>
                }
            </div>
        );
    }
}
