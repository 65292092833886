import React from "react";
import styles from "./ImportEmployees.module.scss";
import Button from '../../../Components/Button';
import Dialog from '../../../Components/Dialog';
import Dropdown from "../../../Components/Dropdown";
import Util from "../../../Util";
import Messages from "../../../Components/Messages";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class MapExcelColumns extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.listOfControls = [];
        this.excelColumns = [];
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 800,
            columnsList: this.props.columnsList || [],
            excelColumns: this.props.excelColumns || [] // this.getExcelColumns()
        }
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.updatePredicate);


        this.setState({ loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.ok} icon="pi pi-check" className="p-button-success" onClick={this.onOk} />
                <Button label={$.strings.cancel} icon="pi pi-times" className="p-button-danger" onClick={this.onHide} />
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )

        return (
            <React.Fragment>
                <Dialog header={$.strings.importEmployees.columnsMapping} footer={footer} icons={myIcon} visible={true}
                    style={{ width: this.state.isDesktop ? '40vw' : '90vw', direction: $.strings.dir }}
                    modal onHide={this.onHide}>
                    <div ref="divMain" >
                        <Messages innerRef={(el) => this.messages = el} />
                        <div className={styles.flexContainer}>
                            {
                                this.drawColumnsMapping()
                            }
                        </div>
                    </div>
                </Dialog >
            </React.Fragment>
        );
    }

    drawColumnsMapping = () => {
        let listOfControls = [];

        try {
            for (let i = 0; i < this.state.columnsList.length; i++) {
                listOfControls.push(
                    <div key={this.state.columnsList[i].index} className={styles.flexQuarter}>
                        <Dropdown
                            id={this.state.columnsList[i].index}
                            optionLabel="name"
                            value={this.state.excelColumns.find(e => e.excelColumn + '' === this.state.columnsList[i].excelColumn + '')}
                            options={this.state.excelColumns}
                            caption={this.state.columnsList[i].header}
                            filter
                            filterBy={"name"}
                            isRequired={this.state.columnsList[i].isRequired}
                            onChange={e => this.handleDropDownChanges(e)}
                        />
                    </div>
                );
            }
        } catch {
        } finally {
        }

        return listOfControls;
    }

    getExcelColumns = () => {
        let columns = [];
        for (let i = 0; i < 26; i++) {
            const char = { name: String.fromCharCode(65 + i), index: i };
            columns.push(char);
        }
        for (let i = 0; i < 1; i++) {
            for (let j = 0; j < 26; j++) {
                const char1 = String.fromCharCode(65 + i);
                const char2 = String.fromCharCode(65 + j);
                const char = { name: char1 + char2, index: (i + j) }
                columns.push(char);
            };
        }

        return columns;
    }

    handleDropDownChanges = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        let _columnsList = this.state.columnsList;
        _columnsList = _columnsList.map((el) => (el.index + '' === name + '' ? { ...el, excelColumn: value.excelColumn, excelIndex: value.index } : el));


        this.setState({ columnsList: _columnsList });
    };

    onOk = () => {
        let inValid = this.state.columnsList.filter(e => e.isRequired && (!e.excelColumn || e.excelColumn.toString().trim() === ''))
        if (inValid && inValid.length > 0) {
            Util.showErrorMsg(this.messages, $.strings.importEmployees.fillRequiredFields)
            return;
        }
        if (this.props.onSuccess) {
            this.props.onSuccess(this.state.columnsList, this.props.excelFile);
        }
    }

    onHide = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
}