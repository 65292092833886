import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util';
import DataGrid from '../../Components/DataGridView/DataGridView';
import ButtonsBarLookupList from "../../Components/ButtonsBarLookupList";
import Card from "../../Components/Card";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class LoanDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            data: [],
            code: undefined,
            detailsObject: {
                code: undefined,
                empCode: undefined,
                empName: undefined,
                loanDate: undefined,
                startPayDate: undefined,
                loanAmount: undefined,
                paymentCount: undefined,
                rate: undefined,
                currency: undefined,
                paidAmount: undefined,
                remainingAmount: undefined,
                loanType: undefined
            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        //const gridStyle = { minHeight: this.state.filterShown ? '38vh' : '55vh' }
        return (
            <div style={{ direction: $.strings.dir }}>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={$.strings.loans.details} footer={footer} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '60vw' : '80vw', direction: $.strings.dir, background: '#f4f4f4' }}
                    modal onHide={this.onHide}>
                    <div ref="divMain" >
                        <Card>
                            <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onShowFilter={e => this.showFilters(e)} />
                        </Card>
                        <Card id="divFilter">
                            <div className="p-grid">
                                <div className="p-col-6 p-lg-1 p-sm-6">
                                    <Input
                                        id="code"
                                        value={this.state.detailsObject.code}
                                        caption={$.strings.loans.code}
                                    />
                                </div>
                                <div className="p-col-6 p-lg-1 p-sm-4">
                                    <Input
                                        id="empCode"
                                        value={this.state.detailsObject.empCode}
                                        caption={$.strings.loans.empCode}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-2 p-sm-12">
                                    <Input
                                        id="empName"
                                        value={this.state.detailsObject.empName}
                                        caption={$.strings.loans.empName}
                                    />
                                </div>
                                <div className="p-col-6 p-lg-2 p-sm-6">
                                    <Input
                                        id="loanDate"
                                        value={this.state.detailsObject.loanDate}
                                        caption={$.strings.loans.loanDate}
                                    />
                                </div>
                                <div className="p-col-6 p-lg-2 p-sm-6">
                                    <Input
                                        id="loanDate"
                                        value={this.state.detailsObject.startPayDate}
                                        caption={$.strings.loans.startPayDate}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-2 p-sm-12">
                                    <Input
                                        id="loanDate"
                                        value={this.state.detailsObject.loanAmount}
                                        caption={$.strings.loans.loanAmount}
                                    />
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-6 p-lg-1 p-sm-6">
                                    <Input
                                        id="loanDate"
                                        value={this.state.detailsObject.paymentCount}
                                        caption={$.strings.loans.paymentCount}
                                    />
                                </div>
                                <div className="p-col-6 p-lg-1 p-sm-1">
                                    <Input
                                        id="loanDate"
                                        value={this.state.detailsObject.rate}
                                        caption={$.strings.loans.rate}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-2 p-sm-6">
                                    <Input
                                        id="loanDate"
                                        value={this.state.detailsObject.currency}
                                        caption={$.strings.loans.currency}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-2 p-sm-6">
                                    <Input
                                        id="paidAmount"
                                        value={this.state.detailsObject.paidAmount}
                                        caption={$.strings.loans.paidAmount}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-2 p-sm-6">
                                    <Input
                                        id="remainingAmount"
                                        value={this.state.detailsObject.remainingAmount}
                                        caption={$.strings.loans.remainingAmount}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-2 p-sm-6">
                                    <Input
                                        id="loanType"
                                        value={this.state.detailsObject.loanType}
                                        caption={$.strings.loans.loanType}
                                    />
                                </div>
                            </div>
                        </Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <DataGrid
                                    // style={gridStyle}
                                    ref={e => this.gridData = e}
                                    isReport={true}
                                    theme="default-light"
                                    idProperty="serial"
                                    exportFileName={$.strings.loans.details}
                                    scheme={this.getScheme()}
                                    dataSource={this.state.data}
                                />
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div>
        );
    }

    getData = async () => {
        this.setState({ loading: true });
        let data = []
        let obj = {
            code: this.state.code
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetLoanDetails, obj, 0);
        if (res.status === 200) {
            data = res.data;
        }
        let detailsObject = {
            code: res.data.code, empCode: res.data.empCode, empName: res.data.empName, loanDate: res.data.loanDate,
            startPayDate: res.data.startPayDate, loanAmount: res.data.loanAmount, paymentCount: res.data.paymentCount,
            rate: res.data.rate, currency: res.data.currency, paidAmount: res.data.paidAmount, remainingAmount: res.data.remainingAmount,
            loanType: res.data.loanType
        }
        this.setState({ data: res.data.listOfLoanPayments, detailsObject: detailsObject, loading: false });
    }

    excelExport = () => {
        this.gridData.exportCSV();
    }

    showFilters = (event) => {
        try {
            if (event.target && event.target.className === "pi pi-angle-double-down") {
                document.getElementById('divFilter').style.display = '';
                event.target.className = 'pi pi-angle-double-up';
                this.setState({ filterShown: true })
            }
            else if (event.target && event.target.className === "pi pi-angle-double-up") {
                document.getElementById('divFilter').style.display = 'none';
                event.target.className = 'pi pi-angle-double-down';
                this.setState({ filterShown: false })
            }
        }
        catch {

        }
    }

    show = (code) => {
        this.setState({
            visibleDialog: true, data: [], detailsObject: [], code: code
        }, async () => { await this.getData() })
    }

    onHide = () => {
        this.setState({ data: [], visibleDialog: false })
    }

    getScheme = () => {
        return {
            name: "attendanceRptScheme_Table",
            filter: false,
            showFooter: true,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 6,
            columns: [

                {
                    name: 'ser', header: '##', width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'dueDate', header: $.strings.loans.dueDate, width: 130

                },
                {
                    name: 'paymentAmount', header: $.strings.loans.paymentAmount, width: 120, dataType: 'Number', aggregate: 'Sum'

                },
                {
                    name: 'payDate', header: $.strings.loans.payDate, width: 120

                },
                {
                    name: 'isPaid', header: $.strings.loans.isPaid, width: 110

                },
                {
                    name: 'fromSalary', header: $.strings.loans.fromSalary, width: 120

                },
                {
                    name: 'notes', header: $.strings.loans.notes, width: "2*",
                    //visible: this.state.data.filter(e => e.notes && e.notes.length).length > 0
                }
            ]
        }
    }

}