import React from "react";
import RadioButton from '../../../RadioButton';

const $ = window.$;

export default class StatusFilter extends React.Component {

    render() {

        return (
            <div className="p-grid">
                {
                    this.props.showAdd &&
                    <div className="p-lg-4 p-sm-4">
                        <RadioButton
                            inputId="rbAll" value={$.strings.all} name="Status" onChange={this.props.onChange}
                            checked={this.props.statusFilter === $.strings.all}
                        ></RadioButton>
                    </div>
                }
                <div className="p-lg-4 p-sm-4">
                    <RadioButton
                        inputId="rbActive" value={$.strings.active} name="Status" onChange={this.props.onChange}
                        checked={this.props.statusFilter === $.strings.active}
                    ></RadioButton>
                </div>
               { !this.props.showStopped &&
                <div className="p-lg-4 p-sm-4">
                    <RadioButton
                        inputId="rbInactive" value={$.strings.inactive} name="Status" onChange={this.props.onChange}
                        checked={this.props.statusFilter === $.strings.inactive}
                    ></RadioButton>
                </div>
              }
                 { this.props.showStopped &&
                <div className="p-lg-4 p-sm-4">
                    <RadioButton
                        inputId="rbStopped" value={$.strings.stopped} name="Status" onChange={this.props.onChange}
                        checked={this.props.statusFilter === $.strings.stopped}
                    ></RadioButton>
                </div>
              }
            </div>
        );
    }
}