import React from "react";
import http from "../../Api/http";
import Checkbox from "../../Components/Checkbox";

const $ = window.$;

export default class PrintVacations3 extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            dataObject: undefined,
            isDesktop: window.innerWidth > 800
        }
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    componentDidMount = async () => {
        document.addEventListener("resize", this.updatePredicate);
        let _dataObject = await this.getData();
        if (_dataObject) {
            this.setState({ dataObject: _dataObject, loading: false }, () => {
                setTimeout(() => {
                    this.doPrint();
                }, 10);
            })
        }
        else {
            this.setState({ loading: false })
        }
    }

    render() {

        return this.state.dataObject ?
            (
                <div id="divMain" style={{ direction: $.strings.dir, padding: '1.5em' }}>
                    <div className="p-grid">
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: 'center' }}>
                            <br />
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.companyName}</div>
                            <br />
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.companyAddress}</div>
                        </div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            <img src="../../assets/layout/images/printlogo.png" alt="logo" />
                        </div>

                    </div>
                    <hr />

                    <div className="p-grid">
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" >
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>ادارة الموارد البشرية</div>
                        </div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Human Resources Department</div>
                        </div>

                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">
                            {"التاريخ: " + this.state.dataObject.requestDate}
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">
                            <div style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '5px', textAlign: 'center', color: 'green' }}>
                                نموذج طلب اجازة
                                <br />
                                Request For Leave
                            </div>
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            {"Date: " + this.state.dataObject.requestDate}
                        </div>
                    </div>
                    <br />

                    {/* خاص بالموظف */}
                    <div className="p-grid" style={{ border: '1px solid gray', borderRadius: '10px' }}>
                        {/* الاسم */}
                        <div className="p-col-1 p-xl-1 p-lg-1 p-md-1 p-sm-1">الاسم</div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: 'center' }}>
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.empName}</span>
                        </div>
                        <div className="p-col-1 p-xl-1 p-lg-1 p-md-1 p-sm-1" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>Name</div>
                        {/* رقم الموظف */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">رقم الموظف</div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: 'center' }}>
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.empCode}</span>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>Emp. No</div>
                        {/* الوظيفة */}
                        <div className="p-col-1 p-xl-1 p-lg-1 p-md-1 p-sm-1">الوظيفة</div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: 'center' }}>
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.jobName}</span>
                        </div>
                        <div className="p-col-1 p-xl-1 p-lg-1 p-md-1 p-sm-1" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>Position</div>
                        {/* الفرع */}
                        <div className="p-col-1 p-xl-1 p-lg-1 p-md-1 p-sm-1">الفرع</div>
                        <div className="p-col-3 p-xl-3 p-lg-3 p-md-3 p-sm-3" style={{ textAlign: 'center' }}>
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.branchName}</span>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>Branch</div>
                        {/* القسم */}
                        <div className="p-col-1 p-xl-1 p-lg-1 p-md-1 p-sm-1">القسم</div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: 'center' }}>
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.sectionName}</span>
                        </div>
                        <div className="p-col-1 p-xl-1 p-lg-1 p-md-1 p-sm-1" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>Section</div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6"></div>
                    </div>
                    <br />

                    <div className="p-grid" style={{ border: '1px solid gray', borderRadius: '10px' }}>
                        {/* من تاريخ */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">من</div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" >
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.fromDate}</span>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>From</div>

                        {/* الى تاريخ */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">الى</div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" >
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.toDate}</span>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>To</div>

                        {/* عدد الأيام */}
                        <div className="p-col-2 p-xl-1 p-lg-1 p-md-1 p-sm-2">عدد الأيام</div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: 'center' }}>
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.daysNo}</span>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>Days Count</div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6"></div>

                        {/* تاريخ الاجازة الفعلي */}
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ fontWeight: 'bold' }}>تاريخ الاجازة الفعلي</div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right', fontWeight: 'bold' }}>
                            Vacation Actual Date
                        </div>

                        {/* من تاريخ */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">من</div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" >
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.actualFromDate}</span>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>From</div>

                        {/* الى تاريخ */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">الى</div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" >
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.actualToDate}</span>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>To</div>

                        {/* عدد الأيام */}
                        <div className="p-col-2 p-xl-1 p-lg-1 p-md-1 p-sm-2">عدد الأيام</div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: 'center' }}>
                            <span style={{ borderBottom: '1px dashed black', fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.actualDaysNo}</span>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>Days Count</div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6"></div>
                    </div>
                    <br />

                    <div className="p-grid" style={{ border: '1px solid gray', borderRadius: '10px' }}>
                        {/* نوع الاجازة */}
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ fontWeight: 'bold' }}>
                            <span style={{ borderBottom: '1px solid black' }}>نوع الإجازة</span>
                        </div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right', fontWeight: 'bold' }}>
                            <span style={{ borderBottom: '1px solid black' }}>Kind Of Leave</span>
                        </div>

                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ fontWeight: 'bold' }}>
                            <span>{this.state.dataObject.typeName}</span>
                        </div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right', fontWeight: 'bold' }}>
                            <span>{this.state.dataObject.typeNameEn}</span>
                        </div>

                        {/* ملاحظات خاصة بالاجازة */}
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">
                            <span>ملاحظات خاصة بالاجازة</span>
                        </div>
                        <div className="p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-8" style={{ fontWeight: 'bold' }}>
                            <span style={{ width: '90vw', borderBottom: '1px dashed black' }}>{this.state.dataObject.requestNote}</span>
                        </div>

                        {/* عنوان الاجازة مفصلا */}
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">
                            <span>عنوان الاجازة مفصلا</span>
                        </div>
                        <div className="p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-8" style={{ fontWeight: 'bold' }}>
                            <span style={{ width: '90vw', borderBottom: '1px dashed black' }}>{this.state.dataObject.address}</span>
                        </div>

                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <span style={{ fontSize: '16px', fontWeight: 'bold', color: 'green' }}>(ملاحظة: لن ينظر في طلب الاجازة ما لم يكن العنوان واضحا وصحيحا)</span>
                        </div>
                    </div>
                    <br />

                    <div className="p-grid" style={{ border: '1px solid gray', borderRadius: '10px' }}>
                        {/* كما أرغب في الخدمات التالية*/}
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6">
                            <span style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}>كما أرغب في الخدمات التالية</span>
                        </div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right', fontWeight: 'bold' }}>
                            <span style={{ borderBottom: '1px solid black' }}>
                                I Also Request the following services
                            </span>
                        </div>
                        {/* خروج وعودة للموظف */}
                        <div className="p-col-7 p-xl-7 p-lg-7 p-md-7 p-sm-7" style={{ textAlign: $.strings.dir === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
                            <div style={{ display: 'flex' }}>
                                <span style={{ fontWeight: 'bold' }}>خروج وعودة للموظف</span>
                                &nbsp;&nbsp;&nbsp;
                                <span><Checkbox id="chkExitRentryForEmployee" caption={""} checked={false} /></span>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ fontWeight: 'bold' }}>Exit/re-entry Visa  for Employee</span>
                            </div>
                        </div>
                        {/* تذكرة للموظف */}
                        <div className="p-col-5 p-xl-5 p-lg-5 p-md-5 p-sm-5" style={{ direction: 'ltr', fontWeight: 'bold' }}>
                            <div style={{ display: 'flex' }}>
                                <span style={{ fontWeight: 'bold' }}>Ticket  for Employee</span>
                                &nbsp;&nbsp;&nbsp;
                                <span><Checkbox id="chkTicketForEmployee" caption={""} checked={false} /></span>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ fontWeight: 'bold' }}>تذكرة للموظف</span>
                            </div>
                        </div>

                        {/* خروج وعودة للعائلة */}
                        <div className="p-col-7 p-xl-7 p-lg-7 p-md-7 p-sm-7" style={{ textAlign: $.strings.dir === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
                            <div style={{ display: 'flex' }}>
                                <span style={{ fontWeight: 'bold' }}>خروج وعودة للعائلة &nbsp;&nbsp;</span>
                                &nbsp;&nbsp;&nbsp;
                                <span><Checkbox id="chkExitRentryForFamily" caption={""} checked={false} /></span>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ fontWeight: 'bold' }}>Exit/re-entry Visa  for Family</span>
                            </div>
                        </div>
                        {/* تذكرة للعائلة */}
                        <div className="p-col-5 p-xl-5 p-lg-5 p-md-5 p-sm-5" style={{ direction: 'ltr', fontWeight: 'bold' }}>
                            <div style={{ display: 'flex' }}>
                                <span style={{ fontWeight: 'bold' }}>Ticket  for Family&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                &nbsp;&nbsp;&nbsp;
                                <span><Checkbox id="chkTicketForFamily" caption={""} checked={false} /></span>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ fontWeight: 'bold' }}>تذكرة للعائلة</span>
                            </div>
                        </div>
                    </div>
                    <br />

                    <div className="p-grid" style={{ border: '1px solid gray', borderRadius: '10px' }}>
                        {/* مرفوض */}
                        <div className="p-col-1 p-xl-1 p-lg-1 p-md-1 p-sm-1" ></div>
                        <div className="p-col-3 p-xl-3 p-lg-3 p-md-3 p-sm-3" >
                            <div style={{ display: 'flex' }}>
                                <span style={{ fontWeight: 'bold' }}>مرفوض</span>
                                &nbsp;&nbsp;&nbsp;
                                <span><Checkbox id="chkDenied" caption={""} checked={false} /></span>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ fontWeight: 'bold' }}>Denied</span>
                            </div>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" ></div>

                        {/* موافق */}
                        <div className="p-col-3 p-xl-3 p-lg-3 p-md-3 p-sm-3" ></div>
                        <div className="p-col-3 p-xl-3 p-lg-3 p-md-3 p-sm-3" style={{ textAlign: $.strings.dir === "rtl" ? 'left' : 'right' }}>
                            <div style={{ display: 'flex', alignItems: 'stretch' }}>
                                <span style={{ fontWeight: 'bold' }}>موافق</span>
                                &nbsp;&nbsp;&nbsp;
                                <span><Checkbox id="chkDenied" caption={""} checked={false} /></span>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ fontWeight: 'bold' }}>Approved</span>
                            </div>
                        </div>

                        {/* توقيع المدير المباشر */}
                        <div className="p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-8" >
                            <br />
                            <span style={{ fontWeight: 'bold', borderTop: '1px dashed black' }}>Dept. Manager توقيع المدير المباشر</span>
                        </div>

                        {/* توقيع الموظف */}
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" >
                            <br />
                            <span style={{ fontWeight: 'bold', borderTop: '1px dashed black' }}>Employee توقيع الموظف</span>
                        </div>

                        {/* اسم البديل */}
                        <div className="p-col-4 p-xl-3 p-lg-3 p-md-3 p-sm-3" >
                            <br />
                            <span style={{ fontWeight: 'bold' }}>(اسم البديل Substitute Name)</span>
                        </div>
                        {/* اسم البديل */}
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" >
                            <br />
                            {this.state.dataObject.altEmp && <span style={{ fontWeight: 'bold', borderBottom: '1px dotted black' }}>{this.state.dataObject.altEmp}</span>}
                            {
                                !this.state.dataObject.altEmp &&
                                <span style={{ fontWeight: 'bold', borderBottom: '1px dotted black' }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            }
                        </div>

                        {/* التوقيع */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: 'center' }}>
                            <br />
                            <span style={{ fontWeight: 'bold' }}>التوقيع Signature</span>
                        </div>
                        {/* التوقيع */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: 'center' }}>
                            <br />
                            <span style={{ fontWeight: 'bold' }}>-------------------</span>
                        </div>

                        <div className="p-col-5 p-xl-5 p-lg-5 p-md-5 p-sm-5" style={{ fontSize: '13px', fontWeight: 'bold', color: 'green' }}>
                            <span>ملاحظة: الموافقة أعلاه لا تعني إستحقاق الموظف للإجازة</span>
                        </div>
                        <div className="p-col-7 p-xl-7 p-lg-7 p-md-7 p-sm-7" style={{ textAlign: $.strings.dir === "rtl" ? 'left' : 'right', fontSize: '13px', fontWeight: 'bold', color: 'green' }}>
                            <span>Note: The above approval does not mean that he deserving the leave</span>
                        </div>
                    </div>
                    <br />

                    <div className="p-grid" style={{ border: '1px solid gray', borderRadius: '10px', backgroundColor: '#e8ffe8' }}>
                        {/* الموافقة والاعتماد */}
                        <div className="p-col-5 p-xl-5 p-lg-5 p-md-5 p-sm-5" style={{ fontWeight: 'bold' }}>
                            <span style={{ borderBottom: '1px solid black' }}>الموافقة والاعتماد</span>
                        </div>
                        <div className="p-col-7 p-xl-7 p-lg-7 p-md-7 p-sm-7" style={{ textAlign: $.strings.dir === "rtl" ? 'left' : 'right', fontWeight: 'bold' }}>
                            <span style={{ borderBottom: '1px solid black' }}>Authorization & Approval</span>
                        </div>

                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <br />
                        </div>

                        {/* التواقيع */}
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ fontWeight: 'bold' }}>
                            <span style={{ borderTop: '1px dashed black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;General Manager&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ fontWeight: 'bold' }}>
                            <span style={{ borderTop: '1px dashed black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assest General Manager&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            <span style={{ borderTop: '1px dashed black' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                HR Manager
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <br />
                        </div>
                    </div>

                </div >
            ) :
            (
                <div id="divMain" style={{ direction: $.strings.dir, padding: '2em' }}>
                    {$.strings.operationFailed}
                </div>
            )
    }

    doPrint = () => {
        setTimeout(() => {
            window.print();
        }, 10);
        window.onafterprint = this.onafterprint;

    }
    onafterprint = () => {
        window.close()
    }

    getData = async () => {
        let dataObject = undefined;
        const params = {
            id: this.props.match.params.id
        }

        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetPrintRequest, params, 0);

        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataObject = res.data.data;
            }
        }
        return dataObject;
    }
    //urlGetPrintRequest
}