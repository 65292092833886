import React from "react";
import { Link, withRouter } from "react-router-dom";
import Routes from "./Routes";
// import SideMenu from "./Components/SideMenu";
// import TopBar from "./Components/TopBar";
// import styles from "./App.scss";
import PrintPortal from "./Components/Printer/PrintPortal";
import printlogo from "./printlogo.png";
import Util from "./Util";
import { Switch, Route } from "react-router-dom";
import Login from "./Screens/Login";
import Access from "./Screens/Access";
import Error from './Screens/Error';
import Dialog from './Components/Dialog';
import Button from './Components/Button'
import SiteMap from './Components/SiteMap';
import Card from './Components/Card';

// START ROMA IMPORTS
import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppRightMenu } from './AppRightMenu';
import { AppConfig } from './AppConfig';
import styles from "./App.scss";
import "./App.css";
import http from './Api/http'
import { ProgressSpinner } from 'primereact/progressspinner';
import ChangePwd from "./Screens/ChangePwd";
import License from './License';
import MailHandle from './Screens/HandleRequests/MailHandle';
import FingerPrint from './Screens/FingerPrint/FP';
import Vacation from './Screens/Vacations/Vac';
import * as wjcCore from '@grapecity/wijmo';
import { util } from "prismjs";
import NewsTicker from './Screens/Ticker/NewsTicker';
import Ticker from './Screens/Ticker';
import icon from './news-icon.png'; // add images, please make sure they can be set as src
import './newsticker.css';
import SeeNews from "./Screens/News/SeeNews";
import CRM from "./Screens/CRM";
import PrintVacations from './Screens/Vacations/PrintVacations'
import PrintVacations2 from './Screens/Vacations/PrintVacations2'
import PrintVacations3 from './Screens/Vacations/PrintVacations3'
import LateSettings from "./Screens/SystemSettings/LateSettings";
import PrintOverTime from "./Screens/OverTime/PrintOverTime";
import PrintSpecialLeave from "./Screens/SpecialLeave/PrintSpecialLeave";
// END ROMA IMPORTS

const $ = window.$;

class App extends React.Component {
  constructor(props) {
    super(props);
    $.history = props.history;
    this.confirmResult = false
    this.state = {
      loading: true,
      language: localStorage.getItem("HRlang") || "ar",
      printable: null,
      printHeader: null,
      printFooter: null,

      layoutMode: localStorage.getItem("HR_layoutMode") ? localStorage.getItem("HR_layoutMode") : 'static',
      lightMenu: localStorage.getItem("HR_lightMenu") ? (localStorage.getItem("HR_lightMenu") === "true" ? true : false) : true,
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      isRTL: false,
      topbarColor: localStorage.getItem("HR_topbarColor") || 'layout-topbar-blue',
      inlineUser: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      rightPanelMenuActive: null,
      inlineUserMenuActive: false,
      menuActive: false,
      themeColor: localStorage.getItem("HR_themeColor") || 'blue',
      configDialogActive: false,
      menuItems: []
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
    this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.onRightMenuButtonClick = this.onRightMenuButtonClick.bind(this);
    this.onRightMenuClick = this.onRightMenuClick.bind(this);
    this.onProfileMenuClick = this.onProfileMenuClick.bind(this);
    this.changeMenuMode = this.changeMenuMode.bind(this);
    this.changeMenuColor = this.changeMenuColor.bind(this);
    this.changeProfileMode = this.changeProfileMode.bind(this);
    this.changeTopbarColor = this.changeTopbarColor.bind(this);
    this.changeTheme = this.changeTheme.bind(this);
    this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
    this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
    this.onConfigClick = this.onConfigClick.bind(this);
  }

  componentDidMount = async () => {

    //wjcCore.setLicenseKey('zeyad-khalid,443155184634773#B0cJ5LlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnpkdTJzcyoHRx4mNysybQRWMw4UZC5UMRt6Q7BjUYN5aL96VVJmWuVnTZpWaL3EZkZ7coJlTOp6NypWTnlWTMRzdPl7Y4YXN0l7T4hjVvM5MzhTVJJTMyIWYWN7MO9UeYRjd6VzdBVWYrpEdSJmZrMTOrFFOyd7dHljVzdEONFDdndkZH5WSK5WSBtSbBVUVop7LWFmZO9UOxEkTh96cEpXRwQXerBTNyVmVjBnaDZzKClVerZ6VxgjbQpVaLRGezVDMXVkUvd4amVzL7h6QBJkZyQneRVVQYlHT6Z5dLhjWolUOEFEUrB7QTRlTlZ6aQdUUEd7aUh4ctdWVYNFMT94NZxGTDNHaRN4MwUHOMNUQ0N6Y7hUZuZXW48EVMJGc4UzVBtEcmdFZX34S6EVelVVa6JzMCJVYah4QxFlTwtCZwI6ZJlFdmZFRsB7KplUdEpHSvRkViojITJCLiATM7UDOFF4MiojIIJCL8EDN7ITM7kzM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLikzM6UjNwACMzgDMxIDMyIiOiQncDJCLiQWasFGar5CZhlXZ0JiOiMXbEJCLi8CZ4xEIu26QgIXZ4VHct36QgYCIlJXY7RnZvNFIZCo8hJ7cJJiOiEmTDJCLiMzN7QzM6QDOxUTNxMDN4IiOiQWSiwSfdtlOicGbmJCLiIjdxIDMyIiOxIIZ');
    await this.loadLanguage(this.state.language);
    wjcCore.setLicenseKey(window.wijmoLicense.key);


    this.loadTheme();
    License();
    let _menuItems = this.createMenu();
    this.loadPrintLogo();


    this.setState({ isRTL: $.strings.dir === "rtl" ? true : false, menuItems: _menuItems, loading: false }, () =>
      setTimeout(async () => {
        if (this.appMenu) {
          await this.appMenu.setModel(this.congfigObj.getDisplayMenu(), this.state.menuItems, true)
        }
      }, 100)
    )

    this.setMainLogo()
  };



  setMainLogo = () => {
    const topbarLogoLink = document.getElementById('topbar-logo');
    let logoName = "logo-roma-white"
    if (this.state.topbarColor && this.state.topbarColor === 'layout-topbar-light')
      logoName = "logo-roma-white"
    if (topbarLogoLink)
      topbarLogoLink.src = '../../assets/layout/images/' + logoName + '.png';
  }


  render() {

    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/Access" component={Access} />
        <Route exact path="/License" component={Error} />
        <Route exact path='/MailHandle/:C?/:T?/:RT?/:WFTransID?/:WFStep?' component={MailHandle} />
        <Route exact path='/FingerPrint/:NotID?/:Date?/:EmpCode?' component={FingerPrint} />
        <Route exact path='/Vacation/:NotID?/:Date?/:EmpCode?' component={Vacation} />
        <Route exact path='/CRM/:uID?/:token?/:empCode?' component={CRM} />

        <Route exact path='/PrintVacations/:id?' component={PrintVacations} />
        <Route exact path='/PrintVacations2/:id?' component={PrintVacations2} />
        <Route exact path='/PrintVacations3/:id?' component={PrintVacations3} />
        <Route exact path='/PrintOverTime/:id?' component={PrintOverTime} />
        <Route exact path='/PrintSpecialLeave/:id?' component={PrintSpecialLeave} />

        <Route path="/" component={this.mainApp} />

      </Switch>
    );
  }

  mainApp = () => {

    if (this.state.loading) return <div>Loading..</div>;

    let printingStyle = this.state.printable ? { display: "none" } : null;

    if (
      (localStorage.getItem("useridHR") === undefined ||
        localStorage.getItem("useridHR") === null ||
        localStorage.getItem("useridHR").trim() === "")
    ) {
      Util.goTo("/login", "");
      return <div></div>;
    }

    License();

    const layoutClassName = classNames('layout-wrapper', {
      'layout-horizontal': this.state.layoutMode === 'horizontal',
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-slim': this.state.layoutMode === 'slim',
      'layout-menu-light': this.state.lightMenu === true,
      'layout-menu-dark': this.state.lightMenu === false,
      'layout-overlay-active': this.state.overlayMenuActive,
      'layout-mobile-active': this.state.staticMenuMobileActive,
      'layout-static-inactive': this.state.staticMenuDesktopInactive,
      'layout-rtl': this.state.isRTL
    }, this.state.topbarColor);

    return (
      <React.Fragment>
        <div className={layoutClassName} onClick={this.onDocumentClick} style={printingStyle}>
          <ConfirmDialog ref={e => this.changeLanguageDlg = e} onYes={e => this.changeOrientation(false, this.changeLanguageDlg.getEventDetails())} />
          <ConfirmDialog ref={e => this.changeMenuModeDlg = e} onYes={e => this.changeMenuMode(false, this.changeMenuModeDlg.getEventDetails())} />
          <ConfirmDialog ref={e => this.changeMenuColorDlg = e} onYes={e => this.changeMenuColor(false, this.changeMenuColorDlg.getEventDetails())} />
          <ConfirmDialog ref={e => this.changeProfileModeDlg = e} onYes={e => this.changeProfileMode(false, this.changeProfileModeDlg.getEventDetails())} />
          <ConfirmDialog ref={e => this.changeTopbarColorDlg = e} onYes={e => this.changeTopbarColor(false, this.changeTopbarColorDlg.getEventDetails())} />
          <ConfirmDialog ref={e => this.changeThemeDlg = e} onYes={e => this.changeTheme(false, this.changeThemeDlg.getEventDetails())} />
          <ConfirmDialog ref={e => this.changeDisplayMenuDlg = e} onYes={e => this.changeDisplayMenu(false, this.changeDisplayMenuDlg.getEventDetails())} />

          <AppTopbarWrapper ref={e => this.topBar = e} inlineUser={this.state.inlineUser} onRightMenuButtonClick={this.onRightMenuButtonClick}
            onMenuButtonClick={this.onMenuButtonClick} onLogOut={this.onLogOut} onChangePwd={this.changePwd} onEmployeeInfo={this.onEmployeeInfo} />

          <div className='layout-menu-container' onClick={this.onMenuClick}>
            <div className="menu-scroll-content">
              {
                this.state.inlineUser && <div className="layout-profile">
                  <button className="p-link layout-profile-button" onClick={this.onProfileMenuClick}>
                    <img src="../../assets/layout/images/avatar.png" alt="roma-layout" />
                    <span className="layout-profile-userinfo">
                      <span className="layout-profile-name">{localStorage.getItem("userNameHR")}</span>
                      <span className="layout-profile-role">{localStorage.getItem("roleHR")}</span>
                    </span>
                  </button>
                  <ul className={classNames("layout-profile-menu", { 'profile-menu-active': this.state.inlineUserMenuActive })}>
                    <li>
                      <button className="p-link">
                        <i className="pi pi-fw pi-user"></i><span>{$.strings.profile}</span>
                      </button>
                    </li>
                    <li>
                      <button className="p-link">
                        <i className="pi pi-fw pi-cog"></i><span>{$.strings.settings}</span>
                      </button>
                    </li>
                    <li>
                      <button className="p-link">
                        <i className="pi pi-fw pi-envelope"></i><span>{$.strings.messages}</span>
                      </button>
                    </li>
                    <li>
                      <button className="p-link">
                        <i className="pi pi-fw pi-bell"></i><span>{$.strings.notifications}</span>
                      </button>
                    </li>
                    <li>
                      <button className="p-link" onClick={this.onLogOut}>
                        <i className="pi pi-sign-out"></i>
                        <span>{$.strings.logOut}</span>
                      </button>
                    </li>
                  </ul>
                </div>
              }

              <AppMenuWrapper ref={e => this.appMenu = e} model={this.menu} layoutMode={this.state.layoutMode}
                onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick} />

            </div>
          </div>


          <AppRightMenuWrapper ref={e => this.rightMenuObj = e} onRightMenuClick={this.onRightMenuClick} />

          <div id="app" className="layout-main" dir={$.strings.dir}>
            <div className="layout-content" >
              {/* <SiteMapWrapper ref={e => this.siteMapOb = e} style={{ display: this.props.history.location.pathname + "" === "/" ? 'none' : '' }} /> */}
              <NewsWrapper />
              <ChangePwd ref={e => this.dlgChangePwd = e}></ChangePwd>
              <LateSettings ref={e => this.dlgLateSettings = e} />
              <Routes />
            </div>

            <AppConfigWrapper ref={e => this.congfigObj = e} layoutMode={this.state.layoutMode} lightMenu={this.state.lightMenu} inlineUser={this.state.inlineUser}
              isRTL={this.state.isRTL} themeColor={this.state.themeColor} topbarColor={this.state.topbarColor}
              changeMenuMode={this.changeMenuMode} changeMenuColor={this.changeMenuColor} onConfigClick={this.onConfigClick} changeProfileMode={this.changeProfileMode}
              changeOrientation={this.changeOrientation} changeTopbarColor={this.changeTopbarColor} changeTheme={this.changeTheme}
              changeDisplayMenu={this.changeDisplayMenu} />


          </div>

          <AppFooter style={printingStyle} />

          {/* <div className="layout-content-mask"></div> */}
        </div>
        <div className={this.state.theme} dir={$.strings.dir}>
          <PrintPortal
            ref={ref => ($.printPortal = ref)}
            printable={this.state.printable}
            header={this.state.printHeader}
            footer={this.state.printFooter}
            onPrint={(printable, header, footer) => {
              this.setState({
                printable: printable,
                printHeader: header,
                printFooter: footer
              });
            }}
            onEnd={() => {
              this.setState({
                printable: null,
                printFooter: null,
                printHeader: null
              });
            }}
          />
        </div>
      </React.Fragment >
    );
  };

  setTheme = theme => {
    localStorage.setItem("theme", theme);
    this.setState({ theme: theme });
  };

  loadLanguage = async lang => {

    $.strings = await import(`./Languages/${lang}.json`);
    $.pages = await import('./pages.json')
    $.language = lang

  };

  loadPrintLogo = () => {
    $.printLogo = (
      <img
        alt="logo"
        src={printlogo}
        style={{ width: "100%", height: "80px", marginTop: "10px" }}
        onLoad={$.finishResize}
      />
    );
  };

  changePassword = () => {
    alert("Change Password");
  };

  onSystemSettings = () => {
    Util.goTo("/settings", "");
  };
  onLogOut = () => {
    var result = window.confirm($.strings.logOutConfirm);
    if (result) {
      localStorage.removeItem("useridHR");
      localStorage.removeItem("isLoggedInHR")
      Util.goTo("/", "");
    }
  };
  changePwd = () => {
    this.dlgChangePwd.show()
  }

  onEmployeeInfo = () => {
    Util.goTo("../Reports/EmpInfo")
  }


  ///////////////////////// START ROMA FUNCTIONS /////////////////////////
  onDocumentClick(event) {
    // if (!this.topbarItemClick && (this.state.activeTopbarItem || this.state.topbarMenuActive)) {
    //   this.setState({
    //     activeTopbarItem: null,
    //     topbarMenuActive: false
    //   });
    // }
    if (!this.topBar.topbarItemClick) {
      this.topBar.setTopBarMenuActive(false)
    }

    // if (!this.rightMenuClick && this.state.rightPanelMenuActive) {
    //   this.setState({rightPanelMenuActive: false });
    // }
    if (!this.rightMenuObj.rightMenuClick) {
      this.rightMenuObj.setRightMenuActive(false)
    }

    // if (!this.configClick && this.state.configDialogActive) {
    //   this.setState({configDialogActive: false });
    // }
    if (!this.congfigObj.configClick) {
      this.congfigObj.setConfigActive(false)
    }

    if (!this.profileClick && this.isSlim() && !this.isMobile() && this.state.inlineUserMenuActive) {
      this.setState({ inlineUserMenuActive: false })
    }

    if (!this.menuClick && this.state.menuActive && (this.isHorizontal() || this.isSlim())) {
      if (this.isHorizontal() || this.isSlim()) {
        this.setState({
          menuActive: false
        })
      }

      if (this.state.overlayMenuActive || this.state.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.setState({ menuHoverActive: false });
      this.unblockBodyScroll();
    }

    //this.topbarItemClick = false;
    this.topBar.topbarItemClick = false;
    this.menuClick = false;
    //this.rightMenuClick = false;
    this.rightMenuObj.rightMenuClick = false;
    this.profileClick = false;
    //this.configClick = false;
    this.congfigObj.configClick = false;
  }
  onMenuButtonClick(event) {
    console.log(1)
    this.menuClick = true;
    this.topBar.setTopBarMenuActive(false)
    this.rightMenuObj.setRightMenuActive(false)
    // this.setState(({
    //   topbarMenuActive: false,
    //   rightPanelMenuActive: false
    // }));
    console.log(2)
    if (this.isOverlay()) {
      console.log(3)
      this.setState({
        overlayMenuActive: !this.state.overlayMenuActive
      });
    }
    console.log(4)
    if (this.isDesktop()) {
      console.log(5)
      this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
    }
    else {
      console.log(6)
      this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
      if (this.state.staticMenuMobileActive) {
        console.log(7)
        this.blockBodyScroll();
      } else {
        console.log(8)
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onConfigButtonClick(event) {
    this.congfigObj.configClick = true;
    //this..setConfigActive(this.congfigObj.isConfigActive())
    //this.setState({configDialogActive: !this.state.configDialogActive })
  }

  onConfigCloseClick() {
    this.setState({ configDialogActive: false })
  }

  onConfigClick() {
    this.congfigObj.configClick = true;
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onTopbarItemClick(event) {
    this.topbarItemClick = true;
    if (this.state.activeTopbarItem === event.item)
      this.setState({ activeTopbarItem: null });
    else
      this.setState({ activeTopbarItem: event.item });

    event.originalEvent.preventDefault();
  }
  onMenuClick(event) {
    this.menuClick = true;
  }

  blockBodyScroll() {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll() {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
        'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }
  onRightMenuButtonClick(event) {
    //this.rightMenuClick = true;
    this.rightMenuObj.rightMenuClick = true;
    //this.setState({rightPanelMenuActive: !this.state.rightPanelMenuActive });
    this.rightMenuObj.setRightMenuActive(!this.rightMenuObj.isRightMenuActive());
    this.topBar.setTopBarMenuActive(false)
    //this.hideOverlayMenu();

    event.preventDefault();
  }

  onRightMenuClick(event) {
    //this.rightMenuClick = true;
    this.rightMenuObj.rightMenuClick = true;
  }

  onProfileMenuClick(event) {
    this.profileClick = true;
    this.setState({ inlineUserMenuActive: !this.state.inlineUserMenuActive })
  }

  hideOverlayMenu() {
    this.setState({
      overlayMenuActive: false,
      staticMenuMobileActive: false
    })
  }
  onMenuItemClick(event) {
    if (!event.item.items) {
      this.hideOverlayMenu();
    }
    if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
      this.setState({
        menuActive: false
      })
    }
  }

  onRootMenuItemClick(event) {
    // this.setState({
    //   menuActive: !this.state.menuActive
    // });
    this.appMenu.setAppMenuActive(!this.appMenu.isAppMenuActive())
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 896;
  }

  isMobile() {
    return window.innerWidth <= 1025;
  }

  isStatic() {
    return this.state.layoutMode === 'static';
  }

  isOverlay() {
    return this.state.layoutMode === 'overlay';
  }

  isHorizontal() {
    return this.state.layoutMode === 'horizontal';
  }

  isSlim() {
    return this.state.layoutMode === 'slim';
  }

  changeMenuMode = async (showConfirm, event) => {
    if (this.state.layoutMode === event.menuMode) {
      return
    }
    if (showConfirm) {
      this.changeMenuModeDlg.setEventDetails(event)
      this.changeMenuModeDlg.show()
      return
    }
    // this.setState({
    //   layoutMode: event.menuMode,
    //   staticMenuDesktopInactive: false,
    //   overlayMenuActive: false
    // });
    // if (event.menuMode === 'slim' || event.menuMode === 'horizontal') {
    //   this.setState({
    //     inlineUser: false,
    //     inlineUserMenuActive: false
    //   })
    // }
    let obj = {
      empCode: localStorage.getItem("useridHR"),
      settingId: 1,
      settingName: 'Layout Mode',
      settingValue: event.menuMode
    }
    let res = await http.getJsonAxios(http.actions.Employee.urlGetSaveEmpSettings, obj, 0);
    if (res.status === 200 && res.data.success) {
      localStorage.setItem("HR_layoutMode", event.menuMode)
      window.location.reload();
    }
    else {
      if (res.data.errorText && res.data.errorText !== "") {
        alert(res.data.errorText);
      }
      else {
        alert($.strings.operationFailed)
      }
    }
  }

  changeDisplayMenu = async (showConfirm, event) => {
    if (this.state.displayMenu === event.displayMenu) {
      return
    }
    if (showConfirm) {
      // this.changeDisplayMenuDlg.setEventDetails(event)
      // this.changeDisplayMenuDlg.show()
      // return
    }

    if (this.appMenu) {
      this.appMenu.setModel(event.displayMenu, this.state.menuItems)
    }
    this.congfigObj.setDisplayMenu(event.displayMenu)
  }

  changeMenuColor = async (showConfirm, event) => {
    if (this.state.lightMenu === event.lightMenu) {
      return
    }
    if (showConfirm) {
      this.changeMenuColorDlg.setEventDetails(event)
      this.changeMenuColorDlg.show()
      return
    }
    //this.setState({ lightMenu: event.lightMenu })
    let obj = {
      empCode: localStorage.getItem("useridHR"),
      settingId: 2,
      settingName: 'Light Menu',
      settingValue: event.lightMenu
    }
    let res = await http.getJsonAxios(http.actions.Employee.urlGetSaveEmpSettings, obj, 0);
    if (res.status === 200 && res.data.success) {
      localStorage.setItem("HR_lightMenu", event.lightMenu)
      window.location.reload();
    }
    else {
      if (res.data.errorText && res.data.errorText !== "") {
        alert(res.data.errorText);
      }
      else {
        alert($.strings.operationFailed)
      }
    }
  }

  changeProfileMode(showConfirm, event) {
    if (this.state.inlineUser === event.inlineUser) {
      return
    }
    if (showConfirm) {
      this.changeProfileModeDlg.setEventDetails(event)
      this.changeProfileModeDlg.show()
      return
    }

    if (!event.inlineUser) {
      this.setState({
        inlineUser: event.inlineUser,
        inlineUserMenuActive: event.inlineUser
      })
    }
    else {
      if (!this.isHorizontal()) {
        this.setState({
          inlineUser: event.inlineUser
        })
      }
    }
  }

  changeOrientation = async (showConfirm, event) => {
    if (event.lang === this.state.language) {
      return
    }
    if (showConfirm) {
      this.changeLanguageDlg.setEventDetails(event)
      this.changeLanguageDlg.show()
      return
    }
    this.setState({ loading: true })
    let obj = {
      empCode: localStorage.getItem("useridHR"),
      userLang: event.lang === 'en' ? '2' : '1',
      isHR: Util.checkISHR()
    }
    let res = await http.getJsonAxios(http.actions.Employee.urlPostChangeEmployeeLanguage, obj, 0);
    if (res.status === 200) {
      localStorage.setItem("HRlang", event.lang);
      await this.loadLanguage(event.lang);
      let _menuItems = this.createMenu();
      this.setState({ menuItems: _menuItems }, () =>
        setTimeout(async () => {
          await this.appMenu.setModel(this.congfigObj.getDisplayMenu(), true)
        }, 100)
      )
      window.location.reload();
    }
    else {
      if (res.data.errorText && res.data.errorText !== "") {
        alert(res.data.errorText);
        this.setState({ loading: false })
      }
      else {
        alert($.strings.operationFailed)
        this.setState({ loading: false })
      }
    }
  }

  changeTopbarColor = async (showConfirm, event) => {
    if (this.state.topbarColor === event.topbarColor) {
      return
    }
    if (showConfirm) {
      this.changeTopbarColorDlg.setEventDetails(event)
      this.changeTopbarColorDlg.show()
      return
    }
    let obj = {
      empCode: localStorage.getItem("useridHR"),
      settingId: 3,
      settingName: 'topbar Color',
      settingValue: event.topbarColor
    }
    let res = await http.getJsonAxios(http.actions.Employee.urlGetSaveEmpSettings, obj, 0);
    if (res.status === 200 && res.data.success) {
      localStorage.setItem("HR_topbarColor", event.topbarColor);
      window.location.reload();
      // this.setState({ topbarColor: event.topbarColor, loading: false });
      // const topbarLogoLink = document.getElementById('topbar-logo');
      // topbarLogoLink.src = '../../assets/layout/images/' + event.logo + '.svg';
      // localStorage.setItem("HR_topbarColor", event.topbarColor);
    }
    else {
      if (res.data.errorText && res.data.errorText !== "") {
        alert(res.data.errorText);
      }
      else {
        alert($.strings.operationFailed)
      }
    }
  }

  changeTheme = async (showConfirm, event) => {
    if (this.state.themeColor === event.theme) {
      return
    }
    if (showConfirm) {
      this.changeThemeDlg.setEventDetails(event)
      this.changeThemeDlg.show()
      return
    }
    // this.setState({ themeColor: event.theme })
    // localStorage.setItem("HR_themeColor", event.theme)
    // this.changeStyleSheetUrl('layout-css', event.theme, 'layout');
    // this.changeStyleSheetUrl('theme-css', event.theme, 'theme');
    let obj = {
      empCode: localStorage.getItem("useridHR"),
      settingId: 4,
      settingName: 'theme Color',
      settingValue: event.theme
    }
    let res = await http.getJsonAxios(http.actions.Employee.urlGetSaveEmpSettings, obj, 0);
    if (res.status === 200 && res.data.success) {
      localStorage.setItem("HR_themeColor", event.theme)
      this.changeStyleSheetUrl('layout-css', event.theme, 'layout');
      this.changeStyleSheetUrl('theme-css', event.theme, 'theme');
      window.location.reload();
    }
    else {
      if (res.data.errorText && res.data.errorText !== "") {
        alert(res.data.errorText);
      }
      else {
        alert($.strings.operationFailed)
      }
    }
  }

  loadTheme() {
    this.changeStyleSheetUrl('layout-css', this.state.themeColor, 'layout');
    this.changeStyleSheetUrl('theme-css', this.state.themeColor, 'theme');
  }

  changeStyleSheetUrl(id, value, prefix) {
    let element = document.getElementById(id);
    let urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
    let newURL = urlTokens.join('/');

    this.replaceLink(element, newURL);
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
  }

  replaceLink(linkElement, href) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    }
    else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }

  createMenu = () => {
    let showShifts = false;
    if (Util.checkISHR()) {
      if (Util.checkRights(944) || Util.checkRights(949)) {
        showShifts = true;
      }
    }
    else if (Util.checkISManager()) {
      if (Util.checkRights(952)) {
        showShifts = true;
      }
    }
    let showUsersAccess = false;
    let menuList = [
      {
        label: $.strings.links.handleRequests.menu,
        icon: 'pi pi-check-square',
        to: '/HandleRequests', visible: true
      },
      {
        label: $.strings.handleNotifications.title,
        icon: 'pi pi-fw pi-bell',
        to: '/HandleNotifications',
        visible: $.license.Notifications_Module,
      },
      {
        label: $.strings.myStaff.title,
        icon: 'pi pi-fw pi-users',
        to: '/MyStaff',
        visible: ($.license.SelfService_Module && (Util.checkISManager() || Util.checkISDelegated())) || ($.license.Att_Module && Util.checkISHR()),
      },
      {
        label: $.strings.empDelegation.title,
        icon: 'pi pi-check',
        to: '/EmpDelegationList',
        visible: $.license.SelfService_Module && Util.checkRights(661) && (Util.checkISHR() || Util.checkISManager()),
      },
      {
        label: $.strings.links.requests.menu,
        open: "",
        icon: "pi pi-id-card",
        visible: !Util.checkISHR() ? true : false,
        items: [
          {
            label: Util.getRequestAliasById(1) || $.strings.links.requests.memo,
            to: "/Requests/Memos",
            icon: "",
            visible: $.license.SelfService_Module && Util.checkRights(607) ? true : false,
          },
          {
            label: Util.getRequestAliasById(2) || $.strings.links.requests.vacation,
            to: "/Requests/Vacations",
            icon: "",
            visible: $.license.SelfService_Module && (Util.checkRights(601) || Util.checkRights(602) || Util.checkRights(603) || Util.checkRights(604)) ? true : false,
          },
          {
            label: Util.getRequestAliasById(3) || $.strings.links.requests.specialLeave,
            to: "/Requests/SpecialLeave",
            icon: "",
            visible: $.license.SelfService_Module && Util.checkRights(605) ? true : false,
          },
          {
            label: Util.getRequestAliasById(4) || $.strings.links.requests.overTime,
            to: "/Requests/OverTime",
            icon: "",
            visible: $.license.SelfService_Module && Util.checkRights(606) ? true : false,
          },
          {
            label: Util.getRequestAliasById(5) || $.strings.links.requests.workLeave,
            to: "/Requests/WorkLeave",
            icon: "",
            visible: $.license.SelfService_Module && Util.checkRights(611) ? true : false,
          },
          {
            label: Util.getRequestAliasById(6) || $.strings.links.requests.noFingerPrint,
            to: "/Requests/FingerPrint",
            icon: "",
            visible: $.license.SelfService_Module && Util.checkRights(777) ? true : false,
          },
          {
            label: Util.getRequestAliasById(7) || $.strings.links.requests.vacationLessThanOneDay,
            to: "/Requests/VacLessDay",
            icon: "",
            visible: $.license.SelfService_Module && Util.checkRights(608) ? true : false,
          },
          {
            label: Util.getRequestAliasById(8) || $.strings.links.requests.expectedVacations,
            to: "/Requests/ExpectedVacations",
            icon: "",
            visible: $.license.SelfService_Module && Util.checkRights(615) ? true : false,
          }
        ]
      },
      {
        label: $.strings.links.staticFilesAndSettings,
        open: "",
        icon: "pi pi-file-o",
        visible: Util.checkISHR() ? true : false,
        items: [
          {
            label: $.strings.newsAdd.title,
            to: "/NewsAdd",
            icon: "pi pi-file-o",
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(703) ? true : false,
          },
          {
            label: $.strings.sendMessages.title,
            to: "/SendMessages",
            icon: "pi pi-file-o",
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(730) ? true : false,
          },
          {
            label: $.strings.substitute.title,
            to: "/Substitute",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(753) ? true : false,
          },
          {
            label: $.strings.notificationsSettings.title,
            to: "/NotificationsSettings",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.Notifications_Module && Util.checkRights(735) ? true : false,
          },
          {
            label: $.strings.systemSettings.title,
            to: "/SystemSettings",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.Att_Module ? true : false,
          },
          {
            label: $.strings.requestsAliases.title,
            to: "/Lookups/RequestsAliases",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.SelfService_Module ? true : false,
          },
          {
            label: $.strings.wfDefinition.title,
            to: "/WorkFlow",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(734) ? true : false,
          },
          {
            label: $.strings.lookups.branchesTitle,
            to: "/Lookups/Branches",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.departmentsTitle,
            to: "/Lookups/Departments",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.jobsTitle,
            to: "/Lookups/Jobs",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.empClassificationsTitle,
            to: "/Lookups/EmployeesClass",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.classificationTitle,
            to: "/Lookups/Classifications",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.sectionsClassTitle,
            to: "/Lookups/SectionsClass",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.shiftTransTypesTitle,
            to: "/Lookups/ShiftTransTypes",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.paidVacTypesTitle,
            to: "/Lookups/PaidVacTypes",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.clockTitle,
            to: "/Lookups/ClockList",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.internalMemosTypesTitle,
            to: "/Lookups/InternalMemosTypes",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.shiftPeriodsTypesTitle,
            to: "/Lookups/ShiftPeriodsTypes",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.lookups.documentsTitle,
            to: "/Lookups/Documents",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(539) ? true : false,
          },
          {
            label: $.strings.sections.title,
            to: "/Files/Sections",
            icon: "pi pi-cog",
            visible: Util.checkISHR() && $.license.HR_Module && Util.checkRights(167) ? true : false,
          },
          {
            label: $.strings.lateSettings.title,
            to: "",
            icon: "pi pi-cog",
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(532),
            command: e => { this.dlgLateSettings.show() }
          }
        ]
      },
      {
        label: $.strings.links.files,
        open: "",
        icon: "pi pi-info-circle",
        visible: false,
        items: [
          {
            label: $.strings.importEmployees.title,
            to: "/Files/ImportEmployees",
            icon: "pi pi-file-excel",
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(501),
          }
        ]
      },
      {
        label: $.strings.links.reports.menu,
        open: "",
        icon: "pi pi-info-circle",
        visible: true,
        items: [
          {
            label: $.strings.employeeInfo.title,
            to: "/Reports/EmpInfo",
            icon: "pi pi-info-circle",
            visible: !Util.checkISHR()
          },
          {
            label: $.strings.vacations.vacBalances,
            to: "/Reports/VacBalance",
            icon: "pi pi-info-circle",
            visible: !Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(715) ? true : false,
          },
          {
            label: $.strings.salarySlip.title,
            to: "/Reports/SalarySlip",
            icon: "pi pi-info-circle",
            visible: !Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(657) ? true : false,
          },
          {
            label: $.strings.links.reports.workDetails,
            to: "/Reports/WorkDetails",
            icon: "pi pi-info-circle",
            visible: !Util.checkISHR()
          },
          {
            label: $.strings.inOutTotalReport.title,
            to: '/Reports/InOutTotalReport',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(707) ? true : (!Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(651) ? true : false),
          },
          {
            label: $.strings.totalInOutTransReport.title,
            to: '/Reports/TotalInOutTransReport',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(708) ? true : (!Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(774) ? true : false),
          },
          {
            label: $.strings.attendanceReport.title,
            to: '/Reports/AttendanceReport',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(710) ? true : (!Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(652) ? true : false),
          },
          {
            label: $.strings.dailyAttendanceRpt.title,
            to: '/Reports/DailyAttendanceRpt',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(710) ? true : (!Util.checkISHR() && $.license.Att_Module && Util.checkRights(652) ? true : false),
          },
          {
            label: $.strings.absence.title,
            to: '/Reports/Absence',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(711) ? true : (!Util.checkISHR() && $.license.Att_Module && Util.checkRights(656) ? true : false),
          },
          {
            label: $.strings.clockTrans.reportTitle,
            to: '/Reports/ClockTransRpt',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(709) ? true : (!Util.checkISHR() && $.license.Att_Module && Util.checkRights(961) ? true : false),
          },
          {
            label: $.strings.workAndVacationsTransRpt.title,
            to: '/Reports/WorkAndVacationsTransRpt',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(712) ? true : (!Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(653) ? true : false),
          },
          {
            label: $.strings.requestsRpt.title,
            to: '/Reports/RequestsRpt',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(713) ? true : (!Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(655) ? true : false),
          },
          {
            label: $.strings.memosRpt.title,
            to: '/Reports/MemosRpt',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(962) ? true : (!Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(963) ? true : false),
          },
          {
            label: $.strings.expectedVacationsRpt.title,
            to: '/Reports/ExpectedVacationReport',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(940) ? true : (Util.checkISManager() && $.license.SelfService_Module && Util.checkRights(941) ? true : ($.license.SelfService_Module && Util.checkRights(942) ? true : false)),
          },
          {
            label: $.strings.noFingerPrintRpt.title,
            to: '/Reports/NoFingerPrintRpt',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(780) ? true : (!Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(778) ? true : false),
          },
          {
            label: $.strings.personalHolidayRpt.title,
            to: '/Reports/PersonalHolidayRpt',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(718) ? true : (!Util.checkISHR() && $.license.Att_Module && Util.checkRights(671) ? true : false),
          },
          {
            label: $.strings.generalHolidayRpt.title,
            to: '/Reports/GeneralHolidayRpt',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(719) ? true : (Util.checkISManager() && $.license.Att_Module && Util.checkRights(664) ? true : false),
          },
          {
            label: $.strings.endServicesBalance.title,
            to: '/Reports/EndServicesBalance',
            icon: 'pi pi-info-circle',
            visible: !Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(750) ? true : false,
          },
          {
            label: $.strings.loans.title,
            to: '/Reports/Loans',
            icon: 'pi pi-info-circle',
            visible: !Util.checkISHR() && Util.checkRights(749) ? true : false,
          },
          {
            label: $.strings.news.newsReportTitle,
            to: '/Reports/NewsRpt',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(714) ? true : (!Util.checkISHR() && $.license.Att_Module && Util.checkRights(654) ? true : false),
          },
          {
            label: $.strings.seenNews.title,
            to: '/Reports/SeenNews',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(723) ? true : false,
          },
          {
            label: $.strings.dashboards.title,
            to: '/Reports/Dashboards',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(716) ? true : false,
          },
          {
            label: $.strings.loginsReport.title,
            to: '/Reports/LoginsReport',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(928) ? true : false,
          },
          {
            label: $.strings.wfTransReport.title,
            to: '/Reports/WFTransReport',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(929) ? true : false,
          },
          {
            label: $.strings.orgChart.title,
            to: '/Reports/OrgChart',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(906) ? true : false
          },
          {
            label: $.strings.notificationsReport.title,
            to: '/Reports/NotificationsReport',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Notifications_Module && Util.checkRights(930) ? true : false
          },
          {
            label: $.strings.messagesReport.title,
            to: '/Reports/MessagesReport',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(931) ? true : false
          },
          {
            label: $.strings.monthlyEmployeeDelayRpt.title,
            to: '/Reports/MonthlyEmployeeDelays',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module ? true : false
          },
          {
            label: $.strings.reportsArchiving.title,
            to: '/Reports/ReportsArchiving',
            icon: 'pi pi-info-circle',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(561) ? true : false
          },
          {
            label: $.strings.overTimeBalances.title,
            to: '/Reports/OverTimeBalances',
            icon: 'pi pi-info-circle',
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(544) ? true : false
          },
          {
            label: $.strings.specialLeavesBalances.title,
            to: '/Reports/SpecialLeavesBalances',
            icon: 'pi pi-info-circle',
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(544) ? true : false
          },
          {
            label: $.strings.specialLeavesBalancesProcess.title,
            to: '/Reports/SpecialLeavesBalancesProcess',
            icon: 'pi pi-info-circle',
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(518) ? true : false
          },
          {
            label: $.strings.employeeTimeCommitRpt.title,
            to: '/Reports/ُEmployeeTimeCommitmentRpt',
            icon: 'pi pi-info-circle',
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(540) ? true : false
          },
          {
            label: $.strings.groupVacation.title,
            to: '/Reports/GroupVacation',
            icon: 'pi pi-info-circle',
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(516) ? true : false
          },
          {
            label: $.strings.salariesPerDay.title,
            to: '/Reports/SalariesPerDay',
            icon: 'pi pi-info-circle',
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(530) ? true : false
          },
          {
            label: $.strings.recalculateBalances.title,
            to: '/Reports/RecalculateBalances',
            icon: 'pi pi-info-circle',
            visible: $.license.Att_Module && Util.checkISHR() ? true : false
          },
          {
            label: $.strings.monthlySummaryReport.title,
            to: '/Reports/MonthlySummaryReport',
            icon: 'pi pi-info-circle',
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(556) ? true : false
          },
          // { تقرير الرواتب بالساعة
          //   label: $.strings.salariesPerDay.title,
          //   to: '/Reports/SalariesPerDay',
          //   icon: 'pi pi-info-circle',
          //   visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(529) ? true : false
          // }
        ]
      },
      showUsersAccess && {
        label: $.strings.users.usersRights,
        open: "",
        icon: "pi pi-users",
        visible: showUsersAccess,
        items: [
          {
            label: $.strings.users.title,
            to: '/UsersAndRight/Users',
            icon: 'pi pi-users',
            visible: true//Util.check() && $.license.Att_Module && Util.checkRights(944) ? true : false
          },
          {
            label: $.strings.roles.group_menu_caption,
            to: '/UsersAndRight/RolesList',
            icon: 'pi pi-users',
            visible: true//Util.check() && $.license.Att_Module && Util.checkRights(944) ? true : false
          },
          {
            label: $.strings.PermissionsUsersAccess.title,
            to: '/UsersAndRight/PermissionsUsersAccess',
            icon: 'pi pi-users',
            visible: true//Util.check() && $.license.Att_Module && Util.checkRights(944) ? true : false
          },
          {
            label: $.strings.PermissionsRolesAccess.title,
            to: '/UsersAndRight/PermissionsRolesAccess',
            icon: 'pi pi-users',
            visible: true//Util.check() && $.license.Att_Module && Util.checkRights(944) ? true : false
          }

        ]
      },
      showShifts && {
        label: $.strings.links.shifts.menu,
        open: "",
        icon: "pi pi-clock",
        visible: true,
        items: [
          {
            label: $.strings.periods.title,
            to: '/Shifts/Periods',
            icon: 'pi pi-clock',
            visible: Util.checkISHR() && $.license.Att_Module && Util.checkRights(944) ? true : false
          },
          {
            label: $.strings.shifts.title,
            to: '/Shifts/Shifts',
            icon: 'pi pi-clock',
            visible: $.license.Att_Module && ((Util.checkISHR() && Util.checkRights(949)) || (Util.checkISManager() && Util.checkRights(952))) ? true : false
          },
          {
            label: $.strings.shiftsWeeklyList.title,
            to: '/Shifts/ShiftsWeeklyList',
            icon: 'pi pi-clock',
            visible: (($.license.Att_Module && Util.checkISHR() && Util.checkRights(954)) || ($.license.SelfService_Module && Util.checkISManager() && Util.checkRights(951))) ? true : false
          },
          {
            label: $.strings.employeesDayShift.title,
            to: '/Shifts/EmployeesDayShift',
            icon: 'pi pi-clock',
            visible: (($.license.Att_Module && Util.checkISHR() && Util.checkRights(955)) || ($.license.SelfService_Module && Util.checkISManager() && Util.checkRights(953))) ? true : false
          },
          {
            label: $.strings.generalShiftsDistribute.title,
            to: '/Shifts/GeneralShiftsDistribute',
            icon: 'pi pi-clock',
            visible: $.license.SelfService_Module && Util.checkISHR() && Util.checkRights(964) ? true : false
          },
          {
            label: $.strings.periodShiftsDistribute.title,
            to: '/Shifts/PeriodShiftsDistribute',
            icon: 'pi pi-clock',
            visible: $.license.SelfService_Module && ((Util.checkISHR() && Util.checkRights(959)) || (Util.checkISManager() && Util.checkRights(958))) ? true : false
          },
          {
            label: $.strings.shiftsList.title,
            to: '/Shifts/ShiftsList',
            icon: 'pi pi-clock',
            visible: $.license.Att_Module && Util.checkISHR() && Util.checkRights(950) ? true : false
          }
        ]
      },
    ];

    return menuList;
  }

  ///////////////////////// END ROMA FUNCTIONS /////////////////////////


}
export default withRouter(App);

// App Config
class AppConfigWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      configDialogActive: false,
      displayMenu: ''
    }

  }

  render() {
    return <AppConfig layoutMode={this.props.layoutMode} lightMenu={this.props.lightMenu} inlineUser={this.props.inlineUser} isRTL={this.props.isRTL}
      displayMenu={this.state.displayMenu} themeColor={this.props.themeColor} topbarColor={this.props.topbarColor} changeMenuMode={this.props.changeMenuMode}
      changeMenuColor={this.props.changeMenuColor} onConfigButtonClick={this.onConfigButtonClick} onConfigCloseClick={this.onConfigCloseClick}
      configDialogActive={this.state.configDialogActive} onConfigClick={this.props.onConfigClick} changeProfileMode={this.props.changeProfileMode}
      changeOrientation={this.props.changeOrientation} changeTopbarColor={this.props.changeTopbarColor} changeTheme={this.props.changeTheme}
      changeDisplayMenu={this.props.changeDisplayMenu} />
  }

  onConfigCloseClick = () => {
    this.setState({ configDialogActive: false })
  }

  onConfigButtonClick = (event) => {
    this.configClick = true;
    this.setState({ configDialogActive: !this.state.configDialogActive })
  }

  isConfigActive = () => {
    return this.state.configDialogActive
  }

  setConfigActive = (isActive) => {
    if (this.configClick) {
      this.setState({ configDialogActive: true })
    }
    else {
      this.setState({ configDialogActive: isActive })
    }
  }

  setDisplayMenu(displayMenu) {
    this.setState({ displayMenu: displayMenu })
  }
  getDisplayMenu() {
    return this.state.displayMenu
  }
}

// App Favorites & Most Visited Menu
class AppRightMenuWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rightPanelMenuActive: false
    }
    this.rightMenuClick = false
  }

  render() {
    return <AppRightMenu rightPanelMenuActive={this.state.rightPanelMenuActive} onRightMenuClick={this.props.onRightMenuClick} />
  }

  isRightMenuActive = () => {
    return this.state.rightPanelMenuActive
  }

  setRightMenuActive = (isActive) => {
    this.setState({ rightPanelMenuActive: isActive })
  }
}

// App Top Bar
class AppTopbarWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      topbarMenuActive: false,
      activeTopbarItem: null
    }
    this.topbarItemClick = false
  }

  render() {
    return <AppTopbar topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem} inlineUser={this.props.inlineUser}
      onRightMenuButtonClick={this.props.onRightMenuButtonClick} onMenuButtonClick={this.props.onMenuButtonClick} onTopbarMenuButtonClick={this.props.onTopbarMenuButtonClick}
      onTopbarItemClick={this.onTopbarItemClick} onLogOut={this.props.onLogOut} onChangePwd={this.props.onChangePwd} onEmployeeInfo={this.props.onEmployeeInfo} />
  }

  onTopbarItemClick = (event) => {
    this.topbarItemClick = true;
    if (this.state.activeTopbarItem === event.item)
      this.setState({ activeTopbarItem: null });
    else
      this.setState({ activeTopbarItem: event.item });

    event.originalEvent.preventDefault();
  }

  isTopBarMenuActive = () => {
    return this.state.topbarMenuActive
  }

  setTopBarMenuActive = (isActive) => {
    if (!isActive) {
      this.setState({ topbarMenuActive: isActive, activeTopbarItem: null })
    }
    else {
      this.setState({ topbarMenuActive: isActive })
    }
  }

  getActiveTopbarItem = () => {
    return this.state.activeTopbarItem
  }
}

// App Menu
class AppMenuWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.layoutMode = this.props.layoutMode
    this.state = {
      menuActive: false,
      displayMenu: 'shamel',
      loading: false
    }
    this.menuClick = false
  }

  render() {
    return <div><ProgressSpinner style={{ display: this.state.loading ? 'block' : 'none', width: '50px', height: '50px' }} />
      <AppMenu loadnig={this.state.loading} model={this.state.model} onMenuItemClick={this.props.onMenuItemClick} onRootMenuItemClick={this.props.onRootMenuItemClick}
        layoutMode={this.props.layoutMode} active={this.state.menuActive} />
    </div>
  }

  setModel = async (displayMenu, listOfMenuItems, fromAppDidMount) => {

    if (!fromAppDidMount && this.state.displayMenu === displayMenu) {
      return
    }
    this.setState({ loading: true })
    let parentId = 631
    let moduleName = ''
    switch (displayMenu) {
      case "shamel":
        {
          parentId = 631
          moduleName = $.strings.shamelModule.shamel
          break
        }
      case "accounting":
        {
          parentId = 2
          moduleName = $.strings.shamelModule.accounting
          break
        }
      case "sales":
        {
          parentId = 4
          moduleName = $.strings.shamelModule.sales
          break
        }
      case "purchases":
        {
          parentId = 5
          moduleName = $.strings.shamelModule.purchases
          break
        }
      case "stores":
        {
          parentId = 7
          moduleName = $.strings.shamelModule.stores
          break
        }
      case "budgets":
        {
          parentId = 3
          moduleName = $.strings.shamelModule.budgets
          break
        }
      case "production":
        {
          parentId = 6
          moduleName = $.strings.shamelModule.production
          break
        }
      case "settings":
        {
          parentId = 8
          moduleName = $.strings.shamelModule.settings
          break
        }
      case "tools":
        {
          parentId = 9
          moduleName = $.strings.shamelModule.tools
          break
        }
      case "help":
        {
          parentId = 10
          moduleName = $.strings.shamelModule.help
          break
        }
      default: {
        break
      }
    }

    let menuItems = listOfMenuItems
    // if (listOfMenuItems && listOfMenuItems.length > 0) {
    //   listOfMenuItems.forEach(item => {
    //     if (item != null && item.id + "" === parentId + "") {
    //       this.transverse(item, menuItems, parentId, true);
    //     }
    //     else if (parentId === 631) {
    //       this.transverse(item, menuItems, parentId, false);
    //     }
    //   });
    // }

    if (menuItems) {
      //if (!Util.checkISHR())
      menuItems.splice(0, 0, { label: $.strings.links.home, icon: 'pi pi-fw pi-home', to: !Util.checkISHR() ? '/' : '/Reports/Dashboards', visible: true });
    }

    this.setState({ model: menuItems, displayMenu: displayMenu, loading: false })
  }

  transverse = (root, tree, targetId, isFirst) => {
    if (!isFirst)
      tree.push(root)
    /* this if() must come first otherwise fails if you want to stop before end */
    if (root.parent_id === targetId) {
      return tree;
    }

    if (root && root.hasOwnProperty("items") && root.items instanceof Array && root.items.length > 0)
      root.items.forEach(child => {
        this.transverse(child, tree, targetId, false);
      });
  }

  isRootMenuItemClick(event) {
    return this.state.menuActive;
  }

  isAppMenuActive = () => {
    return this.state.menuActive
  }

  setAppMenuActive = (isActive) => {
    this.setState({ menuActive: isActive })
  }
}

// siteMapWrapper
class SiteMapWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      home: { icon: 'pi pi-home', url: "/" }
    }
  }

  render() {
    return <Card {...this.props}>
      <SiteMap model={this.state.items} home={this.state.home} />
    </Card >
  }

  getSiteMapItems = () => {
    let siteMapItems = [
      //{ label: $.strings.links.home, command: e => alert("home") },
      // { label: $.strings.links.Files.stores, command: e => alert($.strings.links.Files.stores) },
      // {

      //   label: <div>
      //     {$.strings.links.Files.stores + " "}
      //   </div>,

      //   command: e => alert($.strings.links.Files.stores)
      // }
    ];
    return siteMapItems
  }
}

// Dialog Confirm
class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleDialog: false
    }
    this.eventDetails = null
  }

  render() {
    return <Dialog header={$.strings.appName} visible={this.state.visibleDialog} modal focusOnShow={false} autoFocus
      style={{ width: '350px' }} footer={this.renderFooter()} onHide={e => this.setState({ visibleDialog: false })}>
      <div className="confirmation-content" dir={$.strings.dir}>
        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
        {/* <span>Are you sure you want to proceed?</span> */}
        <span style={{ marginInlineStart: '5px' }}>{$.strings.appConfirm}</span>
      </div>
    </Dialog>
  }

  show = () => {
    this.setState({ visibleDialog: true })
  }

  onYes = () => {
    this.props.onYes()
    this.setState({ visibleDialog: false })
  }

  onCancel = () => {
    this.setState({ visibleDialog: false })
  }

  renderFooter = () => {
    return (
      <div style={{ textAlign: 'center' }} dir={$.strings.dir}>
        <Button ref={e => this.btnYes = e} label={$.strings.yes} icon="pi pi-check" onClick={() => this.onYes()} autoFocus={true} />
        <Button ref={e => this.btnNo = e} label={$.strings.no} icon="pi pi-times" onClick={() => this.onCancel()} className="p-button-secondary" />
      </div>
    );
  }

  setEventDetails = (event) => {
    this.setState({ eventDetails: event })
  }
  getEventDetails = () => {
    return this.state.eventDetails
  }
}

class NewsWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newsList: []
    }

  }

  componentDidMount() {
    let news = localStorage.getItem("newsHR") + "";
    if (news && news.length > 0) {
      this.setState({ newsList: JSON.parse(news) })
    }
  }

  render() {

    return (
      <div>
        {/* <Ticker>
          <FinancialTicker id="1" change={true} symbol="S&P 500" lastPrice="3372.2" percentage="0.38%" currentPrice="12.9" />
          <FinancialTicker id="2" change={true} symbol="AAPL" lastPrice="3372.2" percentage="0.38%" currentPrice="12.9" />
          <FinancialTicker id="3" change={true} symbol="GOOG" lastPrice="3372.2" percentage="0.38%" currentPrice="12.9" />
          <FinancialTicker id="4" change={false} symbol="S&P 500" lastPrice="3372.2" percentage="0.38%" currentPrice="12.9" />
          <FinancialTicker id="5" change={false} symbol="S&P 500" lastPrice="3372.2" percentage="0.38%" currentPrice="12.9" />
          <FinancialTicker id="6" change={false} symbol="S&P 500" lastPrice="3372.2" percentage="0.38%" currentPrice="12.9" />
        </Ticker> */}
        <SeeNews ref={e => this.dlgSeeNews = e} onSuccess={this.onSuccess} />
        <div className="newsticker">
          <Ticker isNewsTicker={true}>
            {
              this.state.newsList.map((item, index) => {
                return <NewsTicker icon={icon} title={item.newsTitle} onClick={e => this.showNews(item)}>
                </NewsTicker>
              })
            }
            {/* <NewsTicker id="1" icon={icon} title="Blue passports to be issued " />
          <NewsTicker id="2" icon={icon} title="Blue passports to be issued " />
          <NewsTicker id="3" icon={icon} title="Blue passports to be issued " />
          <NewsTicker id="4" icon={icon} title="Blue passports to be issued " />
          <NewsTicker id="5" icon={icon} title="Blue passports to be issued " />
          <NewsTicker id="6" icon={icon} title="Blue passports to be issued " /> */}
          </Ticker>
        </div>
      </div >
    )
  }
  showNews = (item) => {
    this.dlgSeeNews.show(item.newsID, item.DestinationID, item.newsTitle, item.newsDetails1)
  }
  onSuccess = (newsList) => {
    localStorage.setItem("newsHR", JSON.stringify(newsList));
    let news = localStorage.getItem("newsHR") + "";
    if (news && news.length > 0) {
      this.setState({ newsList: JSON.parse(news) })
    }
  }
}