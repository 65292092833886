import React from "react";
import styles from "./InputEditor.module.scss";
import Label from "../../Components/Label";
import { Editor } from 'primereact/editor';
import './Editor.css';

const $ = window.$;

export default class InputEditor extends React.Component {

    render() {
        return (
            <div >
                {
                    this.props.caption &&
                    <Label htmlFor={this.props.id} isRequired={this.props.isRequired} autoComplete="off" >
                        {this.props.caption}
                    </Label>
                }
                <Editor
                    value={this.props.value}
                    ref={this.props.innerRef}
                    style={{ height: '280px', pointerEvents: this.props.disabled ? "none" : '' }}
                    headerTemplate={this.props.headerTemplate ? this.props.headerTemplate : this.headerTemplate()}
                    {...this.props}
                />
                {
                    this.props.formErrors && this.props.formErrors[this.props.id] &&
                    <div className={styles.errorField} >{this.props.formErrors[this.props.id]}</div>
                }
            </div>
        );
    }

    headerTemplate = () => {
        return (<span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <span class="ql-formats">
                <select class="ql-align"></select>
                {/* <button class="ql-direction" value="rtl"></button> */}
            </span>
            <select className="ql-size" defaultValue="medium">
                <option value="small">Size 1</option>
                <option value="medium">Size 2</option>
                <option value="large">Size 3</option>
                <option value="huge">Size 4</option>
            </select>
            <select class="ql-color"></select>
            <select class="ql-background"></select>
            <span class="ql-formats">
                <button class="ql-list" value="bullet"></button>
            </span>
        </span>
        )
    }

}