import React from "react";
import Button from '../../Components/Button'
import http from "../../Api/http";
import Dialog from '../../Components/Dialog';
import DataTable from '../../Components/Datatable';
import Input from "../../Components/Input";
import { Editor } from 'primereact/editor';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class MessageDetails extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            data: undefined
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );
        const noTemplate = <span className="ql-formats"></span >;

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <Dialog header={$.strings.messagesReport.msgDetails} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir }}
                modal onHide={this.onHide}>
                <div ref="divMain" >
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <Editor
                                ref={e => this.editor = e}
                                style={{ height: '280px' }}
                                value={this.state.data}
                                headerTemplate={noTemplate}
                                onLoad={this.onLoad}
                            />
                        </div>
                    </div>
                </div >
            </Dialog >
        );
    }
    onLoad = () => {
        if (this.editor && this.editor.editorElement) {
            this.editor.editorElement.__quill.format('align', 'right');
        }
    }
    show = async (data) => {
        if (data && data.includes('\n')) {
            data = data.replace('\n', '<br>');
        }
        if (data && data.includes('\r\n')) {
            data = data.replace('\r\n', '<br>');
        }
        if (data && data.includes('\u0001')) {
            data = this.formatData(data);
        }
        this.setState({ visibleDialog: true, data: data });
    }

    onHide = () => {
        this.setState({ visibleDialog: false, data: undefined })
    }

    formatData = (dataStr) => {
        let data = dataStr.split('\u0001')
        let userName = "";
        let messageRec = "";
        let toMail = "";
        let subject = "";
        let attachment = "";
        for (let i = 1; i < data.length; i++) {
            if (data[i] !== "") {
                let obj = this.getTagData(data[i]);
                let tagID = obj.tagId;
                let tagValue = obj.tagValue;

                switch (tagID) {
                    case "UN":
                        {
                            userName = tagValue.trim() + "<br/>";
                            break;
                        }
                    case "TO":
                        {
                            toMail = tagValue.trim() + "<br/>";
                            break;
                        }
                    case "CC":
                        {
                            break;
                        }
                    case "MSG":
                        {
                            messageRec = tagValue.trim() + "<br/>";
                            break;
                        }
                    case "SJT":
                        {
                            subject = tagValue.trim() + "<br/>";
                            break;
                        }
                    case "ATTACH":
                        {
                            attachment = tagValue.trim() + "<br/>";
                            break;
                        }
                }
            }
        }
        if (messageRec.includes('<a href')) {
            messageRec = messageRec.split('<a href')[0];
        }
        return messageRec;
    }

    getTagData = (tag) => {
        try {
            let tagData = tag.split('\u0002');
            let tagID = tagData[0];
            return { tagId: tagID, tagValue: tagData[1] };
        }
        catch (e) {
            return tag;
        }
    }
}