import React from "react";
import MainTemplate from './MainTemplate';

const $ = window.$;

function getMainTemplate(rowData, columnCaption, fieldName, fieldName2, columnType) {
    let value = rowData[fieldName]
    if (fieldName2)
        value += ' / ' + rowData[fieldName2]
    let color = '';
    color = rowData.status && rowData.status !== 1 ? 'red' : '';
    if (columnType && columnType === "double") {
        value = value.toFixed($.settings.Common.decimalPlaces)
    }
    return <MainTemplate header={columnCaption} data={value} textColor={color} />
}

export default getMainTemplate;