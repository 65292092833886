import React from "react";
import styles from './Home.module.scss'
import Card from '../../Components/Card';
import SiteMap from '../../Components/SiteMap';
import DataTable from '../../Components/Datatable';
import Button from '../../Components/Button';
import Util from "../../Util";
import { Menu } from 'primereact/menu';
import ScrollTop from '../ScrollButton';
import http from '../../Api/http';
import ProgressSpinner from '../../Components/ProgressSpinner';
import Messages from '../../Components/Messages'
import WFTrans from '../WFTrans';
import { FaBold } from "react-icons/fa";
import WorkTime from "./WorkTime";
import mainTemplate from '../../Components/SpecialComponents/EditorsAndTemplate/MainTemplate/EditorFunctions';
import SearchEmployees from "../Employees/SearchEmployees";
import DataGrid from "../../Components/DataGridView/DataGridView";
import * as wjcCore from '@grapecity/wijmo';

const $ = window.$

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.menuItemsList = this.splitButtonItems();
    this.state = {
      loading: true,
      splitItems: this.menuItemsList.filter(element => element.visible === true),
      data: [],
      isDesktop: window.innerWidth > 1250
    };
    this.scheme = this.getScheme();
  }

  componentDidMount = async () => {
    if (!$.license.SelfService_Module) {
      Util.goTo('/License');
      return;
    }
    if (Util.checkISHR()) {
      Util.goTo('/License');
      return;
    }

    let _isDesktop = window.innerWidth > 1250;

    window.addEventListener("resize", this.updatePredicate);
    this.setState({ data: [], isDesktop: _isDesktop, loading: false })
    let dataPromise = this.getMyRequests()
    let temp = await dataPromise
    this.setState({ data: temp, isDesktop: _isDesktop, loading: false })
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 1250 });
  }

  render() {

    const messagesHeader = <Messages innerRef={(el) => this.messages = el} />;
    const tableHeader = (
      <div className="p-grid" style={{ width: '100%', direction: $.strings.dir === 'rtl' ? 'rtl' : 'ltr' }}>
        <Button label={$.strings.refresh} icon="" className="p-button-info" onClick={this.doRefresh}>
          &nbsp;&nbsp;
          <i className="pi pi-refresh" />
        </Button>
      </div>
    )
    const buttonsHeader =
      this.state.isDesktop
        ?
        (
          <div className="p-grid" style={{ width: '100%' }}>
            {Util.checkRights(607) && <Button label={Util.getRequestAliasById(1) || $.strings.links.requests.memo} onClick={e => Util.goTo('/Requests/Memos/')}></Button>}
            {
              (Util.checkRights(601) || Util.checkRights(602) || Util.checkRights(603) || Util.checkRights(604))
              && <Button label={Util.getRequestAliasById(2) || $.strings.links.requests.vacation} onClick={e => Util.goTo('/Requests/Vacations/')}></Button>
            }
            {
              Util.checkRights(605) && <Button label={Util.getRequestAliasById(3) || $.strings.links.requests.specialLeave} onClick={e => Util.goTo('/Requests/SpecialLeave/')}></Button>
            }
            {Util.checkRights(606) && <Button label={Util.getRequestAliasById(4) || $.strings.links.requests.overTime} onClick={e => Util.goTo('/Requests/OverTime/')}></Button>}
            {Util.checkRights(611) && <Button label={Util.getRequestAliasById(5) || $.strings.links.requests.workLeave} onClick={e => Util.goTo('/Requests/WorkLeave/')}></Button>}
            {Util.checkRights(777) && <Button label={Util.getRequestAliasById(6) || $.strings.links.requests.noFingerPrint} onClick={e => Util.goTo('/Requests/FingerPrint/')}></Button>}
            {Util.checkRights(608) && <Button label={Util.getRequestAliasById(7) || $.strings.links.requests.vacationLessThanOneDay} onClick={e => Util.goTo('/Requests/VacLessDay/')}></Button>}
            {Util.checkRights(615) && <Button label={Util.getRequestAliasById(8) || $.strings.links.requests.expectedVacations} onClick={e => Util.goTo('/Requests/ExpectedVacations/')}></Button>}
          </div>
        )
        :
        (
          <div>
            <Menu model={this.state.splitItems} popup={true} ref={ref => this.menu = ref} style={{ minWidth: '120px' }} />
            <Button onClick={this.showSplitButtons} style={{ top: '0px' }} label={$.strings.home.requests}>
              {/* <i className="pi pi-ellipsis-v"></i> */}
            </Button>
          </div>
        )
      ;

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

    let siteMapItems = [{ label: $.strings.links.home }];

    const siteMapHome = { icon: 'pi pi-home', url: "/" }

    let header = (<Button label={$.strings.searchEmployee.search} icon="" onClick={this.showSearch}>
      &nbsp;&nbsp;
      <i className="pi pi-search" />
    </Button>)

    const gridStyle = { minHeight: this.state.isDesktop ? '42vh' : '100vh' }

    return (

      <div ref="divMain" style={{ minHeight: '70vh' }}>
        < Card >
          <SiteMap model={siteMapItems} home={siteMapHome} />
        </Card >

        <div className="p-grid">
          <div className="p-col-12 p-lg-10 p-sm-12">
            <Card style={{ height: '80px', paddingTop: '20px', alignSelf: 'center' }}>
              {buttonsHeader}
            </Card>
          </div>
          <div className="p-col-12 p-lg-2 p-sm-12">
            <WorkTime></WorkTime>
          </div>
        </div>
        {messagesHeader}
        <Card>
          <div className="p-grid">
            <div className="p-col-12 p-lg-12 p-sm-12">
              <DataGrid
                style={gridStyle}
                ref={e => this.gridData = e}
                isReport={true}
                theme="default-light"
                idProperty="serial"
                scheme={this.getSchemeDataGrid()}
                dataSource={
                  new wjcCore.CollectionView(this.state.data, {
                      getError: undefined,
                      currentPosition: Util.setCurrentRow(this.grid),
                  })
              }
                exportFileName={$.strings.links.home}
              />

            </div>
          </div>
        </Card>
        <ScrollTop />
        <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>

      </div >
    );
  }

  doRefresh = async () => {
    this.setState({ loading: true })
    let dataPromise = this.getMyRequests()
    let temp = await dataPromise
    this.setState({ data: temp, loading: false })
  }

  getMyRequests = async () => {
    this.setState({ loading: true })
    let dataTemp = []
    let obj = {
      empCode: localStorage.getItem("useridHR")
    }
    let res = await http.postMultipartAxios(http.actions.MyRequests.urlGetMyRequests, obj, 0);
    if (res.status === 200) {
      dataTemp = res.data;
      if (dataTemp && dataTemp.length > 0) {
        dataTemp.forEach(function (obj) {
          obj.RequestDate = new Date(obj.RequestDate);
        });
      }
    }
    return dataTemp
  }

  getTodayShift = async () => {
    let dataTemp = []
    let obj = {
      empCode: localStorage.getItem("useridHR"),
      fromDate: new Date(),
      toDate: new Date()
    }
    let res = await http.getJsonAxios(http.actions.Shifts.urlGetShifts, obj, 0);
    if (res.status === 200) {
      dataTemp = res.data.list;
    }
    return dataTemp
  }

  showSplitButtons = (event) => {
    this.menu.toggle(event);
  }

  viewTemplate = (rowData, column) => {
    return <>
      <Button icon="pi pi-id-card" label=" " className="p-button-warning" tooltip={$.strings.transDetails} onClick={e => this.wfTransDetails(rowData)}>
      </Button>
      <Button icon="pi pi-eye" label=" " className="p-button-info" tooltip={$.strings.view} onClick={e => this.viewRequest(rowData)}>
      </Button>
      {
        rowData.StatusID + "" === "0"
        &&
        (<Button icon="pi pi-times" label=" " className="p-button-danger" tooltip={$.strings.deleteTrans} onClick={e => this.deleteRequest(rowData)}>
        </Button>
        )
      }
    </>
  }

  wfTransDetails = async (data) => {
    let id = Util.encryptStringToHexa(data.ReqID)
    await this.wfTransDlg.show(data.TypeID, id)
  }

  viewRequest = (data) => {
    let id = Util.encryptStringToHexa(data.ReqID);
    switch (data.TypeID + "") {
      case "1": {
        // طلبات الاجازات والمغادرات
        if (data.TransTypeNo + "" === "6" || data.TransTypeNo + "" === "7" || data.TransTypeNo + "" === "8" || data.TransTypeNo + "" === "9") {
          // اجازات
          if (data.PerType + "" == "0") {
            Util.goTo("../Requests/VacLessDay/" + id)
          }
          else {
            Util.goTo("../Requests/Vacations/" + id)
          }
        }
        else if (data.TransTypeNo + "" === "5") {
          // عمل اضافي
          Util.goTo("../Requests/OverTime/" + id)
        }
        else if (data.TransTypeNo + "" === "12") {
          //  مغادرة خاصة مع خصم
          Util.goTo("../Requests/SpecialLeave/" + id)
        }
        else if (data.TransTypeNo + "" === "2") {
          // مغادرة رسمية بدون خصم 
          Util.goTo("../Requests/WorkLeave/" + id)
        }
        break;
      }
      case "2": {
        // اخلاء الطرف
        break;
      }
      case "3": {
        // اقرار عودة
        break;
      }
      case "4": {
        // تذاكر السفر
        break;
      }
      case "5": {
        // الاجراءات الادارية
        break;
      }
      case "6": {
        // تصريح تنقل
        break;
      }
      case "7": {
        //المراسلات والمذكرات الادارية
        Util.goTo("../Requests/Memos/" + id)
        break;
      }
      case "8": {
        // الاجازات المقترحة
        Util.goTo("../Requests/ExpectedVacations/" + id)
        break;
      }
      case "9": {
        // التدريب
        break;
      }
      case "10": {
        // رحلة عمل
        break;
      }
      case "11": {
        // مخالصة رحلة عمل
        break;
      }
      case "12": {
        // عدم وجود بصمة
        Util.goTo("../Requests/FingerPrint/" + id)
        break;
      }
      default: {
        break;
      }
    }
  }

  deleteAbsentRequest = async (reqId) => {
    this.setState({ loading: true })
    let obj = {
      id: reqId
    }
    let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetDeleteHolidayReq, obj, 0);
    if (res.status === 200) {
      if (res.data.success) {
        Util.showSuccessMsg(this.messages, res.data.errorText)
        let dataPromise = this.getMyRequests()
        let temp = await dataPromise
        this.setState({ data: temp, loading: false })
      }
      else {
        Util.showErrorMsg(this.messages, res.data.errorText || $.strings.operationFailed)
        this.setState({ loading: false })
      }
    }
    else {
      if (res.data.errorText && res.data.errorText !== "") {
        Util.showErrorMsg(this.messages, res.data.errorText)
        this.setState({ loading: false })
      }
      else {
        Util.showErrorMsg(this.messages, $.strings.operationFailed)
        this.setState({ loading: false })
      }
    }
  }
  deleteMemo = async (memoId) => {
    this.setState({ loading: true })
    let obj = {
      memoId: memoId
    }
    let res = await http.getJsonAxios(http.actions.Memos.urlGetDeleteMemo, obj, 0);
    if (res.status === 200) {
      if (res.data.success) {
        Util.showSuccessMsg(this.messages, res.data.errorText)
        let dataPromise = this.getMyRequests()
        let temp = await dataPromise
        this.setState({ data: temp, loading: false })
      }
      else {
        Util.showErrorMsg(this.messages, res.data.errorText || $.strings.operationFailed)
        this.setState({ loading: false })
      }
    }
    else {
      if (res.data.errorText && res.data.errorText !== "") {
        Util.showErrorMsg(this.messages, res.data.errorText)
        this.setState({ loading: false })
      }
      else {
        Util.showErrorMsg(this.messages, $.strings.operationFailed)
        this.setState({ loading: false })
      }
    }
  }
  deleteFingerPrint = async (id) => {
    this.setState({ loading: true })
    let obj = {
      id: id
    }
    let res = await http.getJsonAxios(http.actions.FingerPrint.urlGetDeleteFingerPrint, obj, 0);
    if (res.status === 200) {
      if (res.data.success) {
        Util.showSuccessMsg(this.messages, res.data.errorText)
        let dataPromise = this.getMyRequests()
        let temp = await dataPromise
        this.setState({ data: temp, loading: false })
      }
      else {
        Util.showErrorMsg(this.messages, res.data.errorText || $.strings.operationFailed)
        this.setState({ loading: false })
      }
    }
    else {
      if (res.data.errorText && res.data.errorText !== "") {
        Util.showErrorMsg(this.messages, res.data.errorText)
        this.setState({ loading: false })
      }
      else {
        Util.showErrorMsg(this.messages, $.strings.operationFailed)
        this.setState({ loading: false })
      }
    }
  }
  deleteExpectedVacation = async (data) => {
    this.setState({ loading: true })
    let obj = {
      id: data.ReqID,
      empCode: localStorage.getItem("useridHR") + ""
    }
    let res = await http.getJsonAxios(http.actions.ExpectedVacation.urlGetDeleteExpectedVacation, obj, 0);
    if (res.status === 200) {
      if (res.data.success && res.data.success === true) {
        Util.showSuccessMsg(this.messages, res.data.errorText)
        let dataPromise = this.getMyRequests()
        let temp = await dataPromise
        this.setState({ data: temp, loading: false })
      }
      else {
        Util.showErrorMsg(this.messages, res.data.errorText || $.strings.operationFailed)
        this.setState({ loading: false })
      }
    }
    else {
      if (res.data.errorText && res.data.errorText !== "") {
        Util.showErrorMsg(this.messages, res.data.errorText)
        this.setState({ loading: false })
      }
      else {
        Util.showErrorMsg(this.messages, $.strings.operationFailed)
        this.setState({ loading: false })
      }
    }
  }

  deleteRequest = async (data) => {
    let id = Util.encryptStringToHexa(data.ReqID);
    if (!window.confirm($.strings.home.deleteConfirm)) {
      return;
    }
    switch (data.TypeID + "") {
      case "1": {
        this.deleteAbsentRequest(id);
        break;
      }
      case "2": {
        // اخلاء الطرف
        break;
      }
      case "3": {
        // اقرار عودة
        break;
      }
      case "4": {
        // تذاكر السفر
        break;
      }
      case "5": {
        // الاجراءات الادارية
        break;
      }
      case "6": {
        // تصريح تنقل
        break;
      }
      case "7": {
        //المراسلات والمذكرات الادارية
        this.deleteMemo(id);
        break;
      }
      case "8": {
        // الاجازات المقترحة
        this.deleteExpectedVacation(id)
        break;
      }
      case "9": {
        // التدريب
        break;
      }
      case "10": {
        // رحلة عمل
        break;
      }
      case "11": {
        // مخالصة رحلة عمل
        break;
      }
      case "12": {
        // عدم وجود بصمة
        this.deleteFingerPrint(id);
        break;
      }
      default: {
        break;
      }
    }
  }

  dateTemplate = (rowData, column) => {
    let date = new Date(rowData[column.field])
    return <>
      <span className="p-column-title">{column.header}</span>
      <span>{date.toLocaleDateString()}</span>
    </>
  }

  getScheme = () => {
    return {
      name: "myRequestsScheme_Table",
      filter: false,
      sigma: false,
      columns: [
        {
          position: 0,
          field: "serial",
          header: "##",
          filterable: false,
          width: "45px",
          sortable: false,
          body: e => mainTemplate(e, "##", "serial")
        },
        {
          position: 0,
          field: "ReqID",
          header: "ReqID",
          hidden: true,
          hiddenInColumnChooser: true
        },
        {
          position: 0,
          field: "TypeID",
          header: "TypeID",
          hidden: true,
          hiddenInColumnChooser: true
        },
        {
          position: 0,
          field: "PerType",
          header: "PerType",
          hidden: true,
          hiddenInColumnChooser: true
        },
        {
          position: 1,
          header: '',
          sortable: false,
          width: '150px',
          body: this.viewTemplate
        },
        {
          position: 2,
          field: "RequestDate",
          header: $.strings.home.requestDate,
          body: this.dateTemplate,
          sortable: false,
          dataType: 'Date',
          format: Util.gridDateFormat(),
          width: '100px'
        },
        {
          position: 3,
          field: "MainType",
          header: $.strings.home.mainRequest,
          body: e => mainTemplate(e, $.strings.home.mainRequest, "MainType"),
          sortable: false,
        },
        {
          position: 4,
          field: "TransType",
          header: $.strings.home.subType,
          sortable: false,
          body: e => mainTemplate(e, $.strings.home.subType, "TransType")
        },
        {
          position: 5,
          field: "Days",
          header: $.strings.home.daysNo,
          sortable: false,
          body: e => mainTemplate(e, $.strings.home.daysNo, "Days"),
          width: '85px'
        },
        {
          position: 6,
          field: "FromDate",
          header: $.strings.home.fromDate,
          sortable: false,
          body: e => mainTemplate(e, $.strings.home.fromDate, "FromDate")
        },
        {
          position: 7,
          field: "ToDate",
          header: $.strings.home.toDate,
          sortable: false,
          body: e => mainTemplate(e, $.strings.home.toDate, "ToDate")
        },
        {
          position: 8,
          field: "FromTime",
          header: $.strings.home.fromTime,
          sortable: false,
          width: '105px',
          body: e => mainTemplate(e, $.strings.home.fromTime, "FromTime"),
        },
        {
          position: 9,
          field: "ToTime",
          header: $.strings.home.toTime,
          filterable: false,
          sortable: false,
          width: '105px',
          body: e => mainTemplate(e, $.strings.home.toTime, "ToTime"),
        },
        {
          position: 10,
          field: "Note",
          columnKey: "11",
          header: $.strings.home.notes,
          sortable: false,
          filterable: false,
          body: e => mainTemplate(e, $.strings.home.notes, "Note"),
        },
        {
          position: 11,
          field: "Status",
          columnKey: "12",
          width: '105px',
          header: $.strings.home.status,
          sortable: false,
          filterable: false,
          body: e => mainTemplate(e, $.strings.home.status, "Status"),
        },
        {
          position: 11,
          field: "StatusID",
          columnKey: "13",
          hidden: true,
          hiddenInColumnChooser: true
        },
        {
          position: 12,
          field: "CurrentNode",
          columnKey: "14",
          header: $.strings.home.waitingApprove,
          sortable: false,
          filterable: false,
          body: e => mainTemplate(e, $.strings.home.waitingApprove, "CurrentNode"),
          hidden: !Util.hideBlankColumn(this.state.data, "CurrentNode")
        },
        {
          position: 11,
          field: "TransTypeNo",
          columnKey: "15",
          hidden: true,
          hiddenInColumnChooser: true
        },
        {
          position: 13,
          field: "HRNote",
          columnKey: "16",
          header: $.strings.home.hrNotes,
          sortable: false,
          filterable: false,
          body: e => mainTemplate(e, $.strings.home.hrNotes, "HRNote"),
          hidden: true,
          hiddenInColumnChooser: true
        }
      ]
    };
  };

  getSchemeDataGrid = () => {
    return {
      name: "myRequestsScheme_Table",
      filter: false,
      showFooter: true,
      sortable: false,
      allowGrouping: false,
      responsiveColumnIndex: 8,
      columns: [
        {
          name: "serial", header: "##", width: 45, dataType: 'Number', aggregate: 'Cnt'
        },
        {
          name: "ReqID", width: 45, visible: false, visibleInColumnChooser: false
        },
        {
          name: "TypeID", width: 45, visible: false, visibleInColumnChooser: false
        },
        {
          name: "PerType", width: 45, visible: false, visibleInColumnChooser: false
        },
        {
          name: 'btnWFTransDetails', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.transDetails,
          iconType: 'card', className: 'warning', onClick: (e, ctx) => this.wfTransDetails(ctx.item)
        },
        {
          name: 'btnViewRequest', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.view,
          iconType: 'eye', className: 'info', onClick: (e, ctx) => this.viewRequest(ctx.item, undefined)
        },
        {
          name: 'btnDeleteRequest', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.home.cancelRequest,
          iconType: 'delete', className: 'danger', onClick: (e, ctx) => this.deleteRequest(ctx.item)
        },
        {
          name: "RequestDate", header: $.strings.home.requestDate, width: 100, dataType: 'Date', format: Util.gridDateFormat()
        },
        {
          name: "MainType", header: $.strings.home.mainRequest, width: "*", minWidth: 150
        },
        {
          name: "TransType", header: $.strings.home.subType, width: "*", minWidth: 150
        },
        {
          name: "Days", header: $.strings.home.daysNo, width: 85
        },
        {
          name: "FromDate", header: $.strings.home.fromDate, width: 100
        },
        {
          name: "ToDate", header: $.strings.home.toDate, width: 100
        },
        {
          name: "FromTime", header: $.strings.home.fromTime, width: 100
        },
        {
          name: "ToTime", header: $.strings.home.toTime, width: 100
        },
        {
          name: "Status", header: $.strings.home.status, width: 100
        },
        {
          name: "Note", header: $.strings.home.notes, width: "*", minWidth: 150
        },
        {
          name: "StatusID", header: "StatusID", visible: false, visibleInColumnChooser: false
        },
        {
          name: "CurrentNode", header: $.strings.home.waitingApprove, width: "*", minWidth: 150,
          visible: Util.hideBlankColumn(this.state.data, "CurrentNode")
        },
        {
          name: "TransTypeNo", visible: false, visibleInColumnChooser: false
        },
        {
          name: "HRNote", header: $.strings.home.hrNotes, width: "*", minWidth: 150, visible: false, visibleInColumnChooser: false
        }
      ]
    };
  };

  splitButtonItems = () => {
    let items = [
      Util.checkRights(607) && {
        label:
          (
            <Button label={$.strings.links.requests.memo} onClick={e => Util.goTo('/Requests/Memos/')} className={styles.menuItem}></Button>
          ),
        icon: '',
        command: e => Util.goTo('/Requests/Memos/'),
        visible: true,
      },
      (Util.checkRights(601) || Util.checkRights(602) || Util.checkRights(603) || Util.checkRights(604)) && {
        label:
          (
            <Button label={$.strings.links.requests.vacation} onClick={e => Util.goTo('/Requests/Vacations/')} className={styles.menuItem}></Button>
          ),
        icon: '',
        command: e => Util.goTo('/Requests/Vacations/'),
        visible: true
      },
      Util.checkRights(605) && {
        label:
          (
            <Button label={$.strings.links.requests.specialLeave} onClick={e => Util.goTo('/Requests/SpecialLeave/')} className={styles.menuItem}></Button>
          ),
        icon: '',
        visible: true,
        command: e => Util.goTo('/Requests/SpecialLeave/')
      },
      Util.checkRights(606) && {
        label: (
          <Button label={$.strings.links.requests.overTime} onClick={e => Util.goTo('/Requests/OverTime/')} className={styles.menuItem}></Button>
        ),
        icon: '',
        visible: true,
        command: e => Util.goTo('/Requests/OverTime/')
      },
      Util.checkRights(611) && {
        label: (
          <Button label={$.strings.links.requests.workLeave} onClick={e => Util.goTo('/Requests/WorkLeave/')} className={styles.menuItem}></Button>
        ),
        icon: '',
        visible: true,
        command: e => Util.goTo('/Requests/WorkLeave/')
      },
      Util.checkRights(777) && {
        label: (
          <Button label={$.strings.links.requests.noFingerPrint} onClick={e => Util.goTo('/Requests/FingerPrint/')} className={styles.menuItem}></Button>
        ),
        icon: '',
        visible: true,
        command: e => Util.goTo('/Requests/FingerPrint/')
      },
      Util.checkRights(608) && {
        label: (
          <Button label={$.strings.links.requests.vacationLessThanOneDay} onClick={e => Util.goTo('/Requests/VacLessDay/')} className={styles.menuItem}></Button>
        ),
        icon: '',
        visible: true,
        command: e => Util.goTo('/Requests/VacLessDay/')
      }
    ];
    return items;
  }

}
