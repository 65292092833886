import React from "react";
import Card from '../../Components/Card';
import SiteMap from '../../Components/SiteMap';
import DataTable from '../../Components/Datatable';
import Button from '../../Components/Button';
import Util from "../../Util";
import ScrollTop from '../ScrollButton';
import http from '../../Api/http';
import Messages from '../../Components/Messages'
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ProgressSpinner from "../../Components/ProgressSpinner";
import NotificationsApproval from './NotificationsApproval';
import { Accordion, AccordionTab } from "primereact/accordion";

const $ = window.$

export default class HandleNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            mainType: undefined,
            isDesktop: true
        };
        this.scheme = this.getScheme();
    }

    componentDidMount = async () => {
        if (!$.license.Notifications_Module) {
            Util.goTo('/License');
            return;
        }
        let _isDesktop = this.updatePredicate();

        //this.setState({ data: [], isDesktop: _isDesktop, loading: false })
        let dataPromise = this.getNotifications(true);
        let statusPromise = this.getStatus();
        let typesPromise = this.getTypes();

        let data = await dataPromise
        let statusTemp = await statusPromise;
        let typesTemp = await typesPromise;

        let selectedStatus = undefined
        if (statusTemp && statusTemp.length > 0) {
            selectedStatus = statusTemp[0]
        }
        let selectedType = undefined
        if (typesTemp && typesTemp.length > 0) {
            selectedType = typesTemp[0]
        }

        this.setState({
            data: data || [], statusList: statusTemp, status: selectedStatus, notTypesList: typesTemp,
            notType: selectedType, isDesktop: _isDesktop, loading: false
        })
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    render() {

        let siteMapItems = [{ label: $.strings.handleNotifications.title }];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" style={{ minHeight: '70vh' }}>
                {/* <ProgressSpinner loading={this.state.loading} /> */}
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <Accordion multiple activeIndex={[0]}>
                    <AccordionTab header={$.strings.filterCaption}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Calendar
                                    id="fromDate"
                                    innerRef={(el) => this.dtpFromDate = el}
                                    value={this.state.fromDate}
                                    caption={$.strings.handleNotifications.fromDate}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Calendar
                                    id="toDate"
                                    innerRef={(el) => this.dtpToDate = el}
                                    value={this.state.toDate}
                                    caption={$.strings.handleNotifications.toDate}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Dropdown
                                    id="status"
                                    value={this.state.status}
                                    options={this.state.statusList}
                                    caption={$.strings.handleNotifications.status}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                    placeholder={$.strings.handleNotifications.selectStatus}
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Dropdown
                                    id="notType"
                                    value={this.state.notType}
                                    options={this.state.notTypesList}
                                    caption={$.strings.handleNotifications.notType}
                                    placeholder={$.strings.handleNotifications.selectNotType}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                    filter={true}

                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-4" style={{ alignSelf: 'flex-end' }}>
                                <Button onClick={e => this.getNotifications(false)} >
                                    {$.strings.handleNotifications.showData}
                                    &nbsp;&nbsp;
                                    <i className="pi pi-refresh" />
                                </Button>
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-4" style={{ alignSelf: 'flex-end' }}>
                                <Button onClick={this.excelExport} >
                                    {$.strings.handleNotifications.excelExport}
                                    &nbsp;&nbsp;
                                    <i className="pi pi-file-excel" />
                                </Button>
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-4" style={{ alignSelf: 'flex-end' }}>
                                <Button onClick={this.closeNotifications} >
                                    {$.strings.handleNotifications.closeNotifications}
                                    &nbsp;&nbsp;
                                    <i className="pi pi-check" />
                                </Button>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <Messages innerRef={(el) => this.messages = el} />
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataTable
                                isReport={false}
                                ref={ref => (this.tblData = ref)}
                                scrollHeight={this.state.isDesktop ? "38vh" : '45vh'}
                                scheme={this.scheme}
                                value={this.state.data}
                                exportFilename={$.strings.handleNotifications.title}
                                loading={this.state.loading}
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rows={10}
                                selectionMode="multiple"
                                selection={this.state.selectedRows}
                                onSelectionChange={e => this.setState({ selectedRows: e.value })}
                                showContextMenu={false}
                                responsive={true}
                            />

                        </div>
                    </div>
                </Card>
                <ScrollTop />
                <NotificationsApproval ref={e => this.dlgHandleNotification = e} type="done" onSuccess={e => this.getNotifications(false)} />
                <NotificationsApproval ref={e => this.dlgPostponeNotification = e} type="postpone" onSuccess={e => this.getNotifications(false)} />
                <NotificationsApproval ref={e => this.dlgEvaluationNotification = e} type="evaluation" onSuccess={e => this.getNotifications(false)} />
                <NotificationsApproval ref={e => this.dlgCancelAll = e} type="cancelAll" onSuccess={e => this.getNotifications(false)} />
                <NotificationsApproval ref={e => this.dlgCancel = e} type="cancel" onSuccess={e => this.getNotifications(false)} />
            </div >
        );
    }

    getNotifications = async (fromLoad) => {
        if (!fromLoad)
            this.setState({ loading: true });
        let dataTemp = []
        let obj = {
            status: this.state.status && this.state.status.ID > 0 ? this.state.status.ID : -1,
            notType: this.state.notType && this.state.notType.ID > 0 ? this.state.notType.ID : -1,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            isHR: Util.checkISHR(),
            userId: localStorage.getItem("useridHR"),
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.postMultipartAxios(http.actions.Notifications.urlGetNotifications, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        if (!fromLoad)
            this.setState({ data: dataTemp, loading: false }, () => this.tblData.clearSelection());
        else
            return dataTemp;
    }
    getStatus = async () => {
        let dataTemp = []
        let obj = {

        }
        let res = await http.getJsonAxios(http.actions.Notifications.urlGetStatus, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }
    getTypes = async () => {
        let dataTemp = []
        let obj = {

        }
        let res = await http.getJsonAxios(http.actions.Notifications.urlGetNotificationsTypes, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    excelExport = () => {
        this.tblData.excelExport()
    }

    closeNotifications = () => {
        let array = []

        if (this.tblData.state.selection && this.tblData.state.selection.length > 0) {
            for (let i = 0; i < this.tblData.state.selection.length; i++) {
                array.push(this.tblData.state.selection[i].ID)
            }
            if (array && array.length > 0) {
                this.dlgCancelAll.show(0, '', '', array);
            }
        }
    }

    showSplitButtons = (event) => {
        this.menu.toggle(event);
    }

    viewTemplate = (rowData, column) => {
        return <>
            <Button icon="pi pi-check" label=" " className="p-button-success" tooltip={$.strings.handleNotifications.done} onClick={e => this.btnDone(rowData)}>
            </Button>
            <Button icon="pi pi-clock" label=" " className="p-button-info" tooltip={$.strings.handleNotifications.postpone} onClick={e => this.btnPostpone(rowData)}>
            </Button>
            {
                Util.checkRights(898) && Util.checkISHR() &&
                <Button icon="pi pi-times" label=" " className="p-button-danger" tooltip={$.strings.handleNotifications.cancel} onClick={e => this.btnCancel(rowData)} />
            }
        </>
    }

    btnDone = async (data) => {
        this.dlgHandleNotification.show(data.ID)
    }
    btnPostpone = (data) => {
        this.dlgPostponeNotification.show(data.ID)
    }
    btnCancel = (data) => {
        this.dlgCancel.show(data.ID)
    }

    dateTemplate = (rowData, column) => {
        let date = rowData[column.field];
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{date}</span>
        </>
    }

    getScheme = () => {
        return {
            name: "handleNotificationsScheme_Table",
            filter: false,
            sigma: false,
            columns: [
                {
                    selectionMode: 'multiple',
                    width: '70px',
                },
                {
                    position: 0,
                    field: "ID",
                    width: '70px',
                    header: $.strings.handleNotifications.code,
                    //body: e => mainTemplate(e, $.strings.handleNotifications.code, "ID")
                },
                {
                    position: 6,
                    field: "notification",
                    header: $.strings.handleNotifications.notification,
                    hidden: true,
                    hiddenInColumnChooser: true
                },
                {
                    position: 2,
                    header: '',
                    sortable: false,
                    width: '150px',
                    body: this.viewTemplate
                },
                {
                    position: 4,
                    field: "NoteTypeName",
                    columnKey: "4",
                    header: $.strings.handleNotifications.notType,
                    sortable: false,
                    filterable: false,
                },
                {
                    position: 5,
                    field: "EmpName",
                    columnKey: "5",
                    header: $.strings.handleNotifications.empName,
                    sortable: false,
                    filterable: false,
                    //body: e => mainTemplate(e, $.strings.handleNotifications.empName, "EmpName")
                },
                {
                    position: 7,
                    field: "Note",
                    header: $.strings.handleNotifications.notes,
                    sortable: false,
                    //body: e => mainTemplate(e, $.strings.handleNotifications.notes, "Note")
                },
                {
                    position: 9,
                    field: "status",
                    header: $.strings.handleNotifications.status,
                    sortable: false,
                    width: '100px',
                    //body: e => mainTemplate(e, $.strings.handleNotifications.status, "status")
                },
                {
                    position: 12,
                    field: "TragetDate",
                    header: $.strings.handleNotifications.targetDate,
                    body: this.dateTemplate,
                    sortable: false,
                    width: '110px'
                },
                {
                    position: 10,
                    field: "StatusNo",
                    columnKey: "10",
                    hidden: true,
                    hiddenInColumnChooser: true
                },
                {
                    position: 11,
                    field: "NoteType",
                    columnKey: "11",
                    hidden: true,
                    hiddenInColumnChooser: true
                },
                {
                    position: 13,
                    field: "code",
                    columnKey: "13",
                    hidden: true,
                    hiddenInColumnChooser: true
                },
                {
                    position: 3,
                    field: "InsDate",
                    body: this.dateTemplate,
                    header: $.strings.handleNotifications.insDate,
                    sortable: false,
                    width: '110px'
                },
                {
                    position: 8,
                    field: "ProeccessingNote",
                    header: $.strings.handleNotifications.processNote,
                    sortable: false,
                    //body: e => mainTemplate(e, $.strings.handleNotifications.processNote, "ProeccessingNote")
                }
            ]
        };
    };

}
