import React from "react";
import DataGridView from '../../Components/DataGridView/DataGridView';
import Http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import { FaFlag } from 'react-icons/fa'
import Messages from '../../Components/Messages';
import Util from '../../Util'
import Button from '../../Components/Button';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import SCC from "../ScreenCommonCode";
import { Accordion, AccordionTab } from "primereact/accordion";
import Input from "../../Components/Input";
import Dropdown from "../../Components/Dropdown";
import Checkbox from "../../Components/Checkbox";
import ButtonsBarLookupList from "../../Components/ButtonsBarLookupList";
import LateSettings from "../SystemSettings/LateSettings";
import MultiSelect from "../../Components/MultiSelect";
import Calendar from "../../Components/Calendar";
import AdditionalFilters from "../AdditionalFilters/AdditionalFilters";
import * as wjcCore from '@grapecity/wijmo';
import InputTime from "../../Components/InputTime";

const $ = window.$;

export default class GroupVacation extends React.Component {
    constructor(props) {
        super(props);
        this.nowDate = new Date();
        this.nowDate.setHours(0, 0, 0, 0);
        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            firstExecution: false,
            sectionsList: [],
            employeesList: [],
            transTypesList: [],
            subTypeList: [],
            data: [],
            dataObject: {
                section: [],
                employees: [],
                fromDate: this.nowDate,
                toDate: this.nowDate,
                fromTime: "00:00",
                toTime: "00:00",
                transType: undefined,
                subType: undefined,
                note: "",
                checkHaveTrans: false,
                additionalFiltersObj: undefined
            },
            isDesktop: true
        };
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR() || !Util.checkRights(516)) {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = window.innerWidth > 1250
        let sectionsPromise = this.getSections();
        let employeesPromise = this.getEmployeesList();
        let transTypesListPromise = this.getTransTypes();
        let subTypeListPromise = this.getSubTransTypes();

        let sectionsList = await sectionsPromise;
        let employeesList = await employeesPromise;
        let transTypesList = await transTypesListPromise;
        let subTypeList = await subTypeListPromise;

        let dataTemp = [];
        this.setState({
            sectionsList: sectionsList, employeesList: employeesList,
            transTypesList: transTypesList, subTypeList: subTypeList,
            data: dataTemp, loading: false, isDesktop: _isDesktop,
            dataObject: { ...this.state.dataObject, transType: transTypesList && transTypesList.length > 0 ? transTypesList.find(e => e.pos === 8) : undefined }
        });
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    handleMultiSelect = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    render() {
        const gridStyle = { minHeight: '40vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div ref={e => this.divMain = e} >
                <ProgressSpinner loading={this.state.loading} />
                {/* <LateSettings ref={e => this.dlgLateSettings = e} /> */}
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    <ButtonsBarLookupList
                        onRefresh={e => this.onRefresh(false)}
                        onExcelExport={this.excelExport} />

                    <Messages innerRef={(el) => this.messages = el} />
                    <LateSettings ref={e => this.dlgLateSettings = e} />
                    <AdditionalFilters ref={e => this.dlgAdditionalFilters = e} onSuccess={this.onAdditionalFiltersSuccess} />
                </Card>
                <Accordion multiple >
                    <AccordionTab header={$.strings.filterCaption}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <MultiSelect
                                    id="section"
                                    innerRef={e => this.drpSection = e}
                                    value={this.state.dataObject.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.groupVacation.section}
                                    onChange={(e) => this.handleUserInput(e)}
                                    optionLabel="Name"
                                    display="chip"
                                    maxSelectedLabels="2"
                                    filter={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <MultiSelect
                                    id="employees"
                                    value={this.state.dataObject.employees}
                                    options={this.state.employeesList}
                                    caption={$.strings.groupVacation.employee}
                                    onChange={this.handleMultiSelect}
                                    optionLabel="name"
                                    display="chip"
                                    maxSelectedLabels="2"
                                    showSelectAll={false}
                                    filter
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Calendar
                                    id="fromDate"
                                    innerRef={(el) => this.dtpFromDate = el}
                                    value={this.state.dataObject.fromDate}
                                    caption={$.strings.groupVacation.fromDate}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Calendar
                                    id="toDate"
                                    value={this.state.dataObject.toDate}
                                    caption={$.strings.groupVacation.toDate}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-6 p-md-6 p-sm-12">
                                <InputTime
                                    id="fromTime"
                                    value={this.state.dataObject.fromTime}
                                    caption={$.strings.groupVacation.fromTime}
                                    valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, fromTime: e.value } })}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-6 p-md-6 p-sm-12">
                                <InputTime
                                    id="toTime"
                                    value={this.state.dataObject.toTime}
                                    caption={$.strings.groupVacation.toTime}
                                    valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, toTime: e.value } })}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                <Button label={$.strings.additionalFilters.title} className={this.state.dataObject.additionalFiltersObj ? 'p-button-warning' : ''}
                                    onClick={e => this.dlgAdditionalFilters.show(this.state.dataObject.additionalFiltersObj)} />
                                <Button label="" icon="pi pi-filter-slash" visible={this.state.dataObject.additionalFiltersObj} tooltip={$.strings.additionalFilters.clearFilters}
                                    onClick={e => this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: undefined } })}
                                />
                            </div>
                        </div>

                    </AccordionTab>
                    <AccordionTab header={$.strings.saveData}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Dropdown
                                    id="transType"
                                    value={this.state.dataObject.transType}
                                    options={this.state.transTypesList}
                                    caption={$.strings.groupVacation.transType}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    filter
                                />
                            </div>
                            {
                                this.state.dataObject.transType && this.state.dataObject.transType.pos === 8 &&
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <Dropdown
                                        id="subType"
                                        value={this.state.dataObject.subType}
                                        options={this.state.subTypeList}
                                        caption={$.strings.groupVacation.subType}
                                        onChange={this.handleUserInput}
                                        optionLabel="name"
                                        filter
                                    />
                                </div>
                            }
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Input
                                    id="note"
                                    value={this.state.dataObject.note}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.groupVacation.note}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                <Checkbox id="checkHaveTrans" caption={$.strings.groupVacation.checkHaveTrans}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, checkHaveTrans: e.checked } })}
                                    checked={this.state.dataObject.checkHaveTrans} />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Button label={$.strings.firstExecution} icon="pi pi-check" onClick={this.onFirstExecution}
                                    disabled={!this.state.data || this.state.data.length <= 0} />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Button label={$.strings.saveData} icon="pi pi-save" className="p-button-success" onClick={this.onSaveData}
                                    disabled={!this.state.data || this.state.data.length <= 0 || !this.state.firstExecution} />
                            </div>
                        </div>
                    </AccordionTab>

                </Accordion>
                <Card>
                    <DataGridView
                        style={gridStyle}
                        ref={e => this.gridData = e}
                        isReport={false}
                        scheme={this.getScheme()}
                        exportFileName={this.state.pageInfo.title}
                        dataSource={this.state.data}
                        defaultRowHeight={45}
                    />
                </Card>
            </div >
        );
    }
    getError(item, propName, parsing = false) {
        switch (propName) {
            default: {
                break;
            }
        }
        return null;
    }
    onFirstExecution = () => {
        let dataTemp = this.state.data;
        if (dataTemp) {
            let data = dataTemp.filter(e => e.Selected === true);

            this.setState({ data: data, firstExecution: true });
        }
    }
    onSaveData = async () => {
        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.groupVacation.dataError)
            return;
        }
        if (!this.state.dataObject.transType) {
            Util.showErrorMsg(this.messages, $.strings.groupVacation.transTypeError)
            return;
        }
        if (this.state.dataObject.transType && this.state.dataObject.transType.pos === 8 &&
            (this.state.dataObject.subType === undefined || this.state.dataObject.subType === null)) {
            Util.showErrorMsg(this.messages, $.strings.groupVacation.subTypeError)
            return;
        }
        this.setState({ loading: true });
        let success = false;
        let dataTemp = [];
        const params = {
            FromDate: this.state.data[0].FromDate,
            ToDate: this.state.data[0].ToDate,
            FromTime: this.state.data[0].FromTime,
            ToTime: this.state.data[0].ToTime,
            CheckHaveTrans: this.state.dataObject.checkHaveTrans,
            dt_result: this.state.data,
            UserId: localStorage.getItem("useridHR") + "",
            TransType: this.state.dataObject.transType.pos,
            SubTransType: this.state.dataObject.subType && this.state.dataObject.subType.pos > 0 ? this.state.dataObject.subType : -1
        }

        let res = await Http.postMultipartAxios(Http.actions.Reports.urlPostSaveGroupVacation, params, this.state.pageInfo.pageId);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                success = res.data;
                dataTemp = res.data.dt_SpecialLeave;
                if (success) {
                    Util.showSuccessMsg(this.messages);
                }
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data.errorText && res.data.errorText.length > 0) {
                    msg = res.data.errorText;
                    Util.showErrorMsg(this.messages, msg, false);
                }
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data.errorText && res.data.errorText.length > 0) {
                msg = res.data.errorText;
                Util.showErrorMsg(this.messages, msg, false);
            }
        }
        this.setState({ data: dataTemp, loading: false })
    }

    setPageInfo = () => {
        let pageId
        let title = $.strings.groupVacation.title

        return { pageId: pageId, title: title };
    };

    onAdditionalFiltersSuccess = (obj) => {
        if (obj) {

            this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: obj } })
        }
    }

    onClose = async (e) => {
        this.onRefresh();
        this.setState({ dlgSearchVisible: false });
    };


    btnRefresh = e => {
        this.setState({ data: this.onRefresh(false) });
    };

    excelExport = () => {
        this.gridData.exportCSV();
    }

    onRefresh = async (fromLoad) => {
        let dataTemp = [];
        if (!this.state.dataObject.fromDate) {
            Util.showErrorMsg(this.messages, $.strings.groupVacation.fromDateError)
            return;
        }
        if (!this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.groupVacation.toDateError)
            return;
        }
        if (this.state.dataObject.fromDate > this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.groupVacation.dateError)
            return;
        }

        this.setState({ loading: true, firstExecution: false, data: [] });

        let generalFillter = {};

        if (this.state.dataObject.section) {
            let sectionsList = []
            for (let index = 0; index < this.state.dataObject.section.length; index++) {
                sectionsList.push(this.state.dataObject.section[index].Code)
            }
            generalFillter.SectionList = sectionsList;
        }
        if (this.state.dataObject.employees) {
            let empList = [];
            for (let index = 0; index < this.state.dataObject.employees.length; index++) {
                empList.push(this.state.dataObject.employees[index].code)
            }
            generalFillter.EmpList = empList;
        }
        Util.fillAditionalsFilters(this.state.dataObject.additionalFiltersObj, generalFillter);

        const params = {
            FromDate: this.state.dataObject.fromDate,
            ToDate: this.state.dataObject.toDate,
            FromTime: this.state.dataObject.fromTime,
            ToTime: this.state.dataObject.toTime,
            GeneralFillter: generalFillter,
            ScreenNotes: this.state.dataObject.note
        }

        let res = await Http.postMultipartAxios(Http.actions.Reports.urlPostGroupVacationReport, params, this.state.pageInfo.pageId);

        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.dtReturn;
                if (dataTemp && dataTemp.length > 0) {
                    dataTemp.forEach(element => {
                        element.Selected = true;
                        element.FromDate = this.state.dataObject.fromDate;
                        element.ToDate = this.state.dataObject.toDate;
                        element.FromTime = this.state.dataObject.fromTime;
                        element.ToTime = this.state.dataObject.toTime;
                    });
                }
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data && res.data.message)
                    msg = res.data.message;
                Util.showErrorMsg(this.messages, msg);
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.message)
                msg = res.data.message;
            Util.showErrorMsg(this.messages, msg);
        }
        if (fromLoad) {
            return dataTemp;
        }
        else {
            this.setState({ data: dataTemp, loading: false })
        }
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.splice(0, 1);
            }
        }
        return dataTemp
    }
    getEmployeesList = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: !this.state.empCodeOrName || this.state.empCodeOrName.length <= 0 ? '' : this.state.empCodeOrName,
            isHr: Util.checkISHR(),
            getEmpStatus: this.state.getEmpStatus ? this.state.getEmpStatus : false,
            section: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            empStatus: this.state.empStatus && this.state.empStatus.id >= 0 ? this.state.empStatus.id : -1,
        }

        let res = await Http.getJsonAxios(Http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;

        }
        return dataTemp
    }

    getTransTypes = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 22
        }
        let res = await Http.getJsonAxios(Http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }
    getSubTransTypes = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await Http.getJsonAxios(Http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp;
    }

    getScheme = () => {
        let scheme = {
            name: "monthlyEmployeeDelay_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                { name: 'RowNumber', header: '#', width: 60, dataType: 'Number', aggregate: 'Cnt', isReadOnly: true },
                {
                    name: 'Selected', header: $.strings.groupVacation.select, width: 80,
                    visible: this.state.isDesktop, visibleInColumnChooser: false, isReadOnly: false
                },
                { name: 'Code', header: $.strings.groupVacation.empCode, width: 120, isReadOnly: true },
                { name: 'Name', header: $.strings.groupVacation.empName, width: '*', minWidth: 150, isReadOnly: true },
                { name: 'FromDate', header: $.strings.groupVacation.fromDate, width: 120, isReadOnly: true, dataType: 'Date', format: Util.gridDateFormat() },
                { name: 'ToDate', header: $.strings.groupVacation.toDate, width: 120, isReadOnly: true, dataType: 'Date', format: Util.gridDateFormat() },
                { name: 'FromTime', header: $.strings.groupVacation.fromTime, width: 100, visible: this.state.dataObject.fromTime + "" !== "00:00", isReadOnly: true },
                { name: 'ToTime', header: $.strings.groupVacation.toTime, width: 100, visible: this.state.dataObject.toTime + "" !== "00:00", isReadOnly: true },
                { name: 'Period', header: $.strings.groupVacation.period, width: 100, isReadOnly: true },
                { name: 'VacationBalance', header: $.strings.groupVacation.vacationBalance, width: 160, isReadOnly: true },
                { name: 'OverTimeBalance', header: $.strings.groupVacation.overTimeBalance, width: 160, isReadOnly: true },
                { name: 'LeaveBalance', header: $.strings.groupVacation.leaveBalance, width: 130, isReadOnly: true },
                { name: 'ScreenNotes', header: $.strings.groupVacation.note, width: 150, isReadOnly: true },
                { name: 'Notes', header: $.strings.groupVacation.executeNote, width: 150, isReadOnly: true }
            ]
        }
        return scheme;
    }
}