import React from "react";
import DataGrid from '../../../Components/DataGridView/DataGridView';
import ScrollTop from '../../ScrollButton';
import Card from "../../../Components/Card";
import SiteMap from "../../../Components/SiteMap";
import ProgressSpinner from "../../../Components/ProgressSpinner";
import Util from "../../../Util";
import Input from '../../../Components/Input';
import SearchEmployees from '../../Employees/SearchEmployees';
import Dropdown from "../../../Components/Dropdown";
import Calendar from "../../../Components/Calendar";
import ButtonsBarLookupList from '../../../Components/ButtonsBarLookupList';
import http from '../../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Print from "../../../Print";

const $ = window.$;
const _ = require("lodash");
export default class ShiftsWeeklyList extends React.Component {
    constructor(props) {
        super(props);

        this.nowDate = new Date();
        this.state = {
            isDesktop: true,
            fromCode: '',
            toCode: '',
            fromDate: new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate()),
            toDate: new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), this.nowDate.getDate() + 7),
            time: 32000,
            classificationsList: [],
            classification: undefined,
            section: undefined,
            branch: undefined,
            department: undefined,
            columns: [],
            data: []
        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(954)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(951)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = window.innerWidth > 1250;


        let classPromise = this.getClassifications();
        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();


        let classificationsList = await classPromise;
        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        this.setState({
            classificationsList: classificationsList, classification: classificationsList && classificationsList.length > 0 ? classificationsList[0] : undefined,
            branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }
    handleFromDateInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        let toDate = new Date(value)
        toDate.setDate(toDate.getDate() + 6);

        this.setState({ [name]: value, toDate: toDate });
    }
    handleToDateInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        let fromDate = new Date(value)
        fromDate.setDate(fromDate.getDate() - 6);

        this.setState({ [name]: value, fromDate: fromDate });
    }

    render = () => {
        const gridStyle = { minHeight: '42vh' }
        let siteMapItems = [
            { label: $.strings.links.shifts.menu },
            { label: $.strings.shiftsWeeklyList.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.onPrint} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.shiftsWeeklyList.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                                onBlur={this.onFromCodeBlure}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.shiftsWeeklyList.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.shiftsWeeklyList.fromDate}
                                onChange={this.handleFromDateInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.shiftsWeeklyList.toDate}
                                onChange={this.handleToDateInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="section"
                                innerRef={e => this.drpSections = e}
                                value={this.state.section}
                                options={this.state.sectionsList}
                                caption={$.strings.shiftsWeeklyList.section}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                                filter
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="department"
                                innerRef={e => this.drpSections = e}
                                value={this.state.department}
                                options={this.state.departmentsList}
                                caption={$.strings.shiftsWeeklyList.department}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                filter
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="branch"
                                innerRef={e => this.drpSections = e}
                                value={this.state.branch}
                                options={this.state.branchesList}
                                caption={$.strings.shiftsWeeklyList.branch}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                                filter
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-12">
                            <Dropdown
                                id="classification"
                                innerRef={e => this.drpShifts = e}
                                value={this.state.classification}
                                options={this.state.classificationsList}
                                caption={$.strings.shiftsWeeklyList.classification}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                filter
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <DataGrid
                    ref={e => this.gridData = e}
                    isReport={true}
                    theme="default-light"
                    idProperty="serial"
                    style={gridStyle}
                    scheme={this.getScheme()}
                    //columnGroupsList={this.state.isDesktop ? this.getScheme().columns : undefined}
                    exportFileName={$.strings.shiftsWeeklyList.title}
                    dataSource={this.state.data}
                />
            </Card>
            <ScrollTop />
        </div>
    }

    onFromCodeBlure = () => {
        if (!this.state.toCode || this.state.toCode.length <= 0)
            this.setState({ toCode: this.state.fromCode });
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }

    getClassifications = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 30
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }
    getTransTypes = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 26
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp)
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
        }
        return dataTemp;
    }
    getStatus = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            withAll: true
        }
        let res = await http.getJsonAxios(http.actions.RequestsStatus.urlGetRequestsStatus, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = [], columnsTemp = [];
        let obj = {
            fromCode: this.state.fromCode && this.state.fromCode.length > 0 ? this.state.fromCode : '00000',
            toCode: this.state.toCode && this.state.toCode.length > 0 ? this.state.toCode : 'ZZZZZ',
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            classId: this.state.classification ? parseInt(this.state.classification.pos) : -1,
            sectionId: this.state.section ? parseInt(this.state.section.ID) : -1,
            depId: this.state.department ? parseInt(this.state.department.pos) : -1,
            branchId: this.state.branch ? parseInt(this.state.branch.Pos) : -1,
            isDirector: Util.checkISManager(),
            userId: parseInt(localStorage.getItem("useridHR") + ""),
            isDelegated: false,
        }
        let res = await http.getJsonAxios(http.actions.Shifts.urlGetShiftsWeeklyList, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                columnsTemp = res.data.jsonObjectColumns;
                dataTemp = res.data.dataObject;
            }
        }

        // if (!this.state.isDesktop) {

        //     dataTemp = this.reDesignDataForMobile(dataTemp);
        // }

        this.setState({ data: dataTemp, columns: columnsTemp, loading: false }, () => this.printFilters = this.state);
    }

    reDesignDataForMobile = (data) => {
        let dataTemp = _.cloneDeep(data);
        let objKeys = Object.keys(data[0]);
        for (let key = 0; key < objKeys.length; key++) {
            if (objKeys[key] !== "#" && objKeys[key] !== "Code" && objKeys[key] !== "EmpName") {
                if (objKeys[key].includes('_G')) {
                    let col = objKeys[key].replace('_G', '');
                    dataTemp.forEach(element => {
                        element[col] = element[col + 'FromTime'] + ' - ' + element[col + 'ToTime'] + '<br/>' + element[col + 'Classification']
                    });
                }
            }
        }
        return dataTemp
    }

    excelExport = () => {
        this.gridData.exportCSV();
    }

    onPrint = () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.shiftsWeeklyList.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];

        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";
            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.shiftsWeeklyList.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.shiftsWeeklyList.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.shiftsWeeklyList.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.shiftsWeeklyList.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.section) {
                dateFilter = $.strings.shiftsWeeklyList.section + ": " + this.printFilters.section.Name;
            }
            if (this.printFilters.department) {
                dateFilter = $.strings.shiftsWeeklyList.department + ": " + this.printFilters.department.name;
            }
            if (this.printFilters.branch) {
                dateFilter = $.strings.shiftsWeeklyList.branch + ": " + this.printFilters.branch.name;
            }
            if (this.printFilters.classification) {
                dateFilter = $.strings.shiftsWeeklyList.classification + ": " + this.printFilters.classification.name;
            }
        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };


    getScheme = () => {
        let scheme = {
            name: "shiftsWeeklyListScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                { name: 'ser', header: '##', width: 70, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1.5' },
                { name: 'Code', header: $.strings.shiftsWeeklyList.empCode, width: 120, columnWidth: '2' },
                { name: 'EmpName', header: $.strings.shiftsWeeklyList.empName, width: 150, columnWidth: '*' },
                { name: 'Name', header: 'Name', width: '*', visible: false, visibleInColumnChooser: false, columnWidth: '2' },
            ]
        }
        if (this.state.data && this.state.data.length > 0) {
            // Grouping for Desktop
            let objKeys = Object.keys(this.state.data[0]);
            // if (this.state.isDesktop) {
            //     for (let key = 0; key < objKeys.length; key++) {
            //         if (objKeys[key] !== "#" && objKeys[key] !== "Code" && objKeys[key] !== "EmpName") {
            //             let col = {};
            //             if (objKeys[key].includes('_G')) {
            //                 let colHeader = objKeys[key]
            //                 colHeader = colHeader.replace('_G', '');
            //                 col = {
            //                     header: colHeader, align: 'center', columns: [
            //                         { name: colHeader + 'FromTime', header: $.strings.shiftsWeeklyList.from, width: 90, sortable: false, columnWidth: '2' },
            //                         { name: colHeader + 'ToTime', header: $.strings.shiftsWeeklyList.to, width: 90, sortable: false, columnWidth: '2' },
            //                         { name: colHeader + 'Classification', header: $.strings.shiftsWeeklyList.class, width: 90, sortable: false, columnWidth: '2' },
            //                     ]
            //                 }
            //                 if (!scheme.columns.includes(col))
            //                     scheme.columns.push(col);
            //             }
            //         }
            //     }
            // }
            // else {
            // Without Grouping for Mobile
            for (let key = 0; key < objKeys.length; key++) {
                if (objKeys[key] !== "ser" && objKeys[key] !== "Code" && objKeys[key] !== "EmpName") {
                    //if (objKeys[key].includes('_G')) {
                    let colHeader = objKeys[key]
                    //colHeader = colHeader.replace('_G', '');
                    colHeader = colHeader.replace('_', '\r\n');
                    colHeader = colHeader.replaceAll('_', '-');
                    let col = { name: objKeys[key], header: colHeader, width: 150, columnWidth: '3' }
                    if (!scheme.columns.includes(col))
                        scheme.columns.push(col);
                    //}
                }
            }
            //}
        }
        return scheme;
    }
}