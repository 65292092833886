import React, { Component } from "react";
import Button from "../../Components/Button";
import Dialog from "../../Components/Dialog";
import Util from "../../Util";
import Input from "../../Components/Input";


const $ = window.$;

export default class VacDaysSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsErrors: {},
            visibleDialog: false,
            id: undefined,
            count: undefined,
            value: undefined
        };
    }

    componentDidMount() {
        let _isDesktop = window.innerWidth > 1250;
        this.setState({ isDesktop: _isDesktop });
    }

    render() {
        const buttonsBar = (
            <div className={`${"p-grid"}`} >
                <div className="p-col-3 p-lg-6 p-sm-2">
                </div>
                <div className="p-col-3 p-lg-1 p-sm-1">
                    <Button tooltip={$.strings.saveAndClose} icon="" className="p-button-success" onClick={e => this.onSaveThenClose(e)}>
                        <i className="pi pi-check" />
                    </Button>
                </div>
                <div className="p-col-3 p-lg-1 p-sm-1">
                    <Button tooltip={$.strings.cancel} icon="" className="p-button-danger" onClick={e => this.onHide(e, true)}>
                        <i className="pi pi-times" />
                    </Button>
                </div>
                <div className="p-col-3 p-lg-6 p-sm-2">
                </div>
            </div >
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div style={{ direction: $.strings.dir }}>
                <Dialog header={this.props.vacType === "annual" ? $.strings.systemSettings.annualVacations : $.strings.systemSettings.sickVacations}
                    icons={myIcon} visible={this.state.visibleDialog} footer={buttonsBar}
                    style={{ width: this.state.isDesktop ? '25vw' : '80vw', direction: $.strings.dir }} modal
                    onHide={this.onHide}>
                    <Input
                        keyfilter="pnum"
                        id="count"
                        value={this.state.count}
                        caption={this.props.vacType === "annual" ? $.strings.systemSettings.yearsCount : $.strings.systemSettings.dueDays}
                        onChange={(e) => this.setState({ count: e.target.value })}
                        isRequired={true}
                        showError={this.state.showErrors}
                        errorDescription={this.state.fieldsErrors.count}
                    />
                    <Input
                        keyfilter="pnum"
                        id="value"
                        value={this.state.value}
                        caption={this.props.vacType === "annual" ? $.strings.systemSettings.annualDaysCount : $.strings.systemSettings.discountPercent}
                        onChange={(e) => this.setState({ value: e.target.value })}
                        isRequired={true}
                        showError={this.state.showErrors}
                        errorDescription={this.state.fieldsErrors.value}
                    />
                </Dialog>
            </div>
        );
    }

    onHide = (e, checkChanging) => {
        this.setState({
            visibleDialog: false,
            id: undefined, count: undefined, value: undefined,
            fieldsErrors: {}
        })
    }
    onShow = (id, count, value) => {
        this.setState({ id: id, count: count, value: value, visibleDialog: true })
    }
    validateField = () => {
        let result = true;
        let fieldsValidationErrors = this.state.fieldsErrors;

        fieldsValidationErrors.count = "";
        if (!this.state.count || this.state.count < 0) {
            fieldsValidationErrors.count = $.strings.requiredFiled;
            result = false;
        }

        fieldsValidationErrors.value = "";
        if (!this.state.value || this.state.value < 0) {
            fieldsValidationErrors.value = $.strings.requiredFiled;
            result = false;
        }

        return result;
    }
    onSaveThenClose = async (e) => {
        //waiting progress
        this.setState({ loading: true });

        //validate
        if (!this.validateField()) {
            this.setState({ loading: false, showErrors: true });
            return false;
        }

        if (this.props.onSuccess) {
            this.props.onSuccess(this.state.id, this.state.count, this.state.value);
            this.onHide(e, false);
        }
    }
}
