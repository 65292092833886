import React from "react";
import { DataTable as PrimeDataTable } from "primereact/datatable";
import { ContextMenu } from "primereact/contextmenu";
import styles from "./DataTable.module.scss";
import respClass from "./responsive.scss";
import { FaSave, FaColumns, FaFilter, FaBars, FaTable, FaEraser } from 'react-icons/fa'
import { OverlayPanel } from "primereact/overlaypanel";
import { ListBox } from "primereact/listbox";
import SigmaPanel from "./SigmaPanel";
import * as schemeHelper from "./SchemeHelper";
import * as tableHelper from "./DataTableHelper";
//import ResportsDesigner from '../SpecialComponents/ReportsDesigner';

const $ = window.$;

export default class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scheme: schemeHelper.create(props.scheme),
            selection: undefined,
        };

        let loadedScheme = this.loadScheme();
        if (loadedScheme) {
            this.state.scheme = schemeHelper.merge(this.state.scheme, loadedScheme);
        }
        this.sigmaPanels = {};
        this.visibleData = props.value;
    }

    componentDidMount() {
        //rerender again to calculate sigma, because setting filter initially does not trigger rendering group footer
        let columns = this.getVisibleColumns();
        let columnsWithFilter = columns.filter(
            c => c.filter && c.filter.length > 0
        );
        if (
            columnsWithFilter &&
            this.visibleData.length === this.props.value.length
        )
            this.table.filter();

        this.setDragListeners();

    }

    componentWillReceiveProps(props) {
        this.visibleData = props.value;
        //this.setState({ scheme: schemeHelper.create(props.scheme) });
    }

    componentDidUpdate(prevProps) {
    }

    render = () => {
        let scheme = this.props.scheme;//this.state.scheme;
        let props = this.props;
        let contextMenuItems = this.getContextMenu();
        let groupField = this.getGroupField();
        let sigmaPanels = (scheme.sigma && this.getSigmaPanels(scheme)) || [];
        let filters = tableHelper.getFilters(scheme);

        let tempData = this.visibleData
        if (filters && Object.keys(filters).length > 0) {
            tempData = this.filteredData
        }
        else if (props.globalFilter && props.globalFilter.length > 0) {
            tempData = this.filteredData
        }
        else if (props.fullValue) {
            tempData = props.fullValue
        }


        let columns = tableHelper.getColumns(
            scheme,
            tempData,
            groupField,
            this.onSortClick,
            this.onSigmaClick,
            this.props.isReport,
            this.props.responsive
        );
        let multiSort = tableHelper.getMultiSort(scheme, props);
        let dtClassName = props.responsive ? "p-datatable-responsive" : props.className
        return (
            <React.Fragment>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <ContextMenu
                        ref={ref => (this.contextMenu = ref)}
                        model={contextMenuItems}
                        className={styles.fixContextMenu}
                    />
                </div>
                {
                    /*
                    this.props.isReport === false ? null : <ResportsDesigner ref={ref => this.resportsDesigner = ref} reportInfo={this.props.reportInfo} />
                    */
                }
                <OverlayPanel
                    ref={ref => (this.columnChooser = ref)}
                    dismissable={true}
                    showCloseIcon={true}
                >
                    <ListBox
                        multiple={true}
                        optionLabel="header"
                        dataKey="field"
                        value={this.getVisibleColumns()}
                        options={scheme.columns.filter(c => c.field !== groupField && !c.hiddenInColumnChooser)}
                        onChange={this.onColumnToggle}
                        listStyle={{ maxHeight: '40vh' }}
                    />
                </OverlayPanel>

                {sigmaPanels}

                <div
                    ref={ref => (this.tableContainer = ref)}
                    style={{ minHeight: "100%" }}
                    onContextMenu={this.props.showContextMenu === false ? null : el => this.contextMenu.show(el)}
                >
                    {
                        scheme.group
                            ?
                            (
                                <div
                                    className={styles.groupingRow}
                                    onDrop={this.groupBy}
                                    onDragOver={this.allowDrop}
                                >
                                    {this.getGroupField() ? (
                                        <div className={styles.groupDiv}>
                                            <span
                                                onClick={this.toggleGroupFieldSort}
                                                className={`pi pi-fw ${scheme.groupSortOrder === -1
                                                    ? "pi pi-sort-down"
                                                    : "pi pi-sort-up"
                                                    }`}
                                            ></span>
                                            <span onClick={this.toggleGroupFieldSort}>
                                                {this.getGroupFieldHeader()}
                                            </span>
                                            <button
                                                onClick={this.removeGroupColumn}
                                                className={styles.groupButton}
                                            >
                                                <span className="pi pi-fw pi-times"></span>
                                            </button>
                                        </div>
                                    ) : (
                                        <span style={{ marginTop: '10px', verticalAlign: 'middle' }}>{$.strings.groupingRow}</span>
                                    )}
                                </div>
                            )
                            : null
                    }
                    <PrimeDataTable
                        ref={ref => (this.table = ref)}
                        id={scheme.id}
                        value={props.value || []}
                        fullValue={props.fullValue}
                        header={props.header}
                        footer={props.footer}
                        style={props.style}
                        className={`${dtClassName} ${"p-datatable-gridlines"}`}
                        tableStyle={props.tableStyle}
                        tableClassName={props.tableClassName}
                        paginator={props.paginator}
                        paginatorPosition={props.paginatorPosition}
                        alwaysShowPaginator={props.alwaysShowPaginator}
                        paginatorTemplate={props.paginatorTemplate}
                        paginatorLeft={props.paginatorLeft}
                        paginatorRight={props.paginatorRight}
                        pageLinkSize={props.pageLinkSize}
                        rowsPerPageOptions={props.rowsPerPageOptions}
                        first={props.first}
                        sortField={props.sortField || scheme.sortField}
                        sortOrder={props.sortOrder || scheme.sortOrder}
                        multiSortMeta={multiSort.multiSortMeta}
                        sortMode={multiSort.sortMode}
                        defaultSortOrder={props.defaultSortOrder}
                        emptyMessage={props.emptyMessage || $.strings.emptyRowMessage}
                        selectionMode={
                            props.selectionMode || tableHelper.defaultValues.selectionMode
                        }
                        selection={this.state.selection}
                        contextMenuSelection={props.contextMenuSelection}
                        compareSelectionBy={
                            props.compareSelectionBy ||
                            tableHelper.defaultValues.compareSelectionBy
                        }
                        dataKey={props.dataKey}
                        metaKeySelection={props.metaKeySelection}
                        headerColumnGroup={props.headerColumnGroup}
                        footerColumnGroup={props.footerColumnGroup}
                        frozenHeaderColumnGroup={props.frozenHeaderColumnGroup}
                        frozenFooterColumnGroup={props.frozenFooterColumnGroup}
                        expandedRows={props.expandedRows}
                        responsive={props.responsive}
                        table-layout="auto"
                        responsiveLayout="stack"
                        breakpoint="600px"
                        resizableColumns={props.resizableColumns === false ? false : true}
                        columnResizeMode={
                            props.columnResizeMode ||
                            tableHelper.defaultValues.columnResizeMode
                        }
                        reorderableColumns={
                            props.reorderableColumns === false ? false : true
                        }
                        filters={filters}
                        globalFilter={props.globalFilter}
                        scrollable={props.scrollable === false ? false : true}
                        scrollHeight={props.scrollHeight}
                        virtualScroll={props.virtualScroll}
                        virtualScrollDelay={props.virtualScrollDelay}
                        virtualRowHeight={props.virtualScroll ? props.virtualRowHeight || 25 : props.virtualRowHeight}

                        onVirtualScroll={this.onVirtualScroll}
                        rows={props.virtualScroll ? props.rows || 20 : props.rows}
                        totalRecords={props.virtualScroll ? (props.fullValue || props.value).length : props.totalRecords}
                        lazy={props.virtualScroll ? true : props.lazy}

                        frozenWidth={props.frozenWidth}
                        frozenValue={props.frozenValue}
                        csvSeparator={props.csvSeparator}
                        exportFilename={props.exportFilename || this.state.scheme.name}
                        rowGroupMode={
                            groupField
                                ? props.rowGroupMode || tableHelper.defaultValues.rowGroupMode
                                : (scheme.mergeField && 'rowspan')
                        }
                        autoLayout={props.autoLayout}
                        loading={props.loading}
                        loadingIcon={props.loadingIcon}
                        groupField={groupField || props.mergeField}
                        onSelectionChange={this.onSelectionChange}
                        onContextMenuSelectionChange={props.onContextMenuSelectionChange}
                        rowExpansionTemplate={props.rowExpansionTemplate}
                        onRowToggle={props.onRowToggle}
                        rowClassName={props.rowClassName}
                        rowGroupHeaderTemplate={
                            groupField
                                ? props.rowGroupHeaderTemplate || this.rowGroupHeaderTemplate
                                : undefined
                        }
                        rowGroupFooterTemplate={
                            groupField
                                ? props.rowGroupFooterTemplate || this.rowGroupFooterTemplate
                                : undefined
                        }
                        onColumnResizeEnd={this.onColumnResizeEnd}
                        onSort={this.onSort}
                        onPage={props.onPage}
                        onFilter={this.onFilter}
                        onRowClick={props.onRowClick}
                        onRowDoubleClick={props.onRowDoubleClick}
                        onRowSelect={props.onRowSelect}
                        onRowUnselect={props.onRowUnselect}
                        onRowExpand={props.onRowExpand}
                        onRowCollapse={props.onRowCollapse}
                        onContextMenu={this.props.showContextMenu === false ? null : el => this.contextMenu.show(el.originalEvent)}
                        onColReorder={this.onColReorder}
                        onRowReorder={props.onRowReorder}
                        onValueChange={this.onTableValueChange}
                        removableSort
                    >
                        {columns}
                    </PrimeDataTable>
                </div>
            </React.Fragment >
        );
    };

    loadChunk = (index, length) => {
        let chunk = [];
        for (let i = 0; i < length; i++) {
            if (i + index >= this.props.fullValue.length)
                return chunk
            let obj = { ...this.props.fullValue[i + index] }
            chunk.push(obj)
        }
        return chunk;
    }

    onVirtualScroll = (event) => {
        setTimeout(() => {
            let dataObj = this.loadChunk(event.first, event.rows)
            this.props.onVirtualScroll(
                event,
                dataObj
            )
        }, 50);
    }


    getScheme = () => schemeHelper.create(this.state.scheme);

    getVisibleData = () => this.visibleData || this.props.value || [];

    setDragListeners = () => {
        let columns = this.getVisibleColumns();
        //let headers = [...this.table.el.querySelectorAll("tr th")];
        let headers = [...this.table.container.querySelectorAll("tr th")];
        if (headers) {
            for (let i = 0; i < headers.length; i++) {
                headers[i].ondragstart = e =>
                    e.dataTransfer.setData("column", columns[i].field);
            }
        }
    };

    setActiveCellEditor = (rowIndex, columnName) => {
        if (this.visibleData && this.visibleData.length > 0) {
            let columns = this.getVisibleColumns();
            let colIndex = columns.findIndex(e => e.field === columnName)
            let cells = [...this.table.container.querySelectorAll("td")];
            let pos = (rowIndex * columns.length) + colIndex
            if (pos >= 0) {
                cells[pos].click()
                cells[pos].focus()
            }
        }
    }


    onSortClick = (e, column) => {

        this.table.onSort({ originalEvent: e, sortField: column.field });
    }
    onSigmaClick = (e, column) => {
        this.sigmaPanels[column.field].show(e.currentTarget);
    }
    rowGroupHeaderTemplate = (firstRowInGroup, index) => {
        let groupField = this.getGroupField();

        if (index === 0) {
            this.groupedData = this.visibleData.reduce((a, b) => {
                let groupValue = b[groupField];
                a[groupValue] = a[groupValue] || [];
                a[groupValue].push(b);
                return a;
            }, {});
        }



        let groupColumn = this.state.scheme.columns.find(
            c => c.field === groupField
        );
        return (
            <div className={styles.collapsRows} onClick={this.expandCollapsRows}>
                {`${groupColumn.header}: ${firstRowInGroup[groupColumn.field] === null || firstRowInGroup[groupColumn.field] === undefined ? '' : firstRowInGroup[groupColumn.field]}`}
            </div>
        );
    };

    rowGroupFooterTemplate = (lastRowInGroup, index) => {
        let columns = this.getVisibleColumns().sort(
            (a, b) => a.position - b.position
        );
        if (columns.filter(c => c.sigma).length === 0) return null;

        let groupField = this.getGroupField();
        let groupValue = lastRowInGroup[groupField];
        let data = this.groupedData[groupValue];

        let footer = columns.map(c => {
            let sigma = c.sigma;
            let key = groupValue + c.field;
            if (!sigma) return <td key={key}></td>;
            return (
                <td key={key}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}
                    >
                        {sigma.avg && (
                            <span>
                                {$.strings.sigmaOptions.average}: {tableHelper.getAvg(c, data)}
                            </span>
                        )}
                        {sigma.count && (
                            <span>
                                {$.strings.sigmaOptions.count}: {tableHelper.getCount(c, data)}
                            </span>
                        )}
                        {sigma.max && (
                            <span>
                                {$.strings.sigmaOptions.maximum}: {tableHelper.getMax(c, data)}
                            </span>
                        )}
                        {sigma.min && (
                            <span>
                                {$.strings.sigmaOptions.minimum}: {tableHelper.getMin(c, data)}
                            </span>
                        )}
                        {sigma.sum && (
                            <span>
                                {$.strings.sigmaOptions.sum}: {tableHelper.getSum(c, data)}
                            </span>
                        )}
                    </div>
                </td>
            );
        });
        return footer;
    };

    getGroupField = () => this.props.groupField || this.state.scheme.groupField;

    getGroupFieldHeader = () =>
        this.state.scheme.columns.find(
            c => c.field === this.state.scheme.groupField
        ).header;

    getVisibleColumns = () =>
        this.state.scheme.columns.filter(
            c => !c.hidden && c.field !== this.getGroupField()
        );

    getSigmaPanels = scheme => {
        return scheme.columns.map(column => {
            return (
                <OverlayPanel
                    key={"sigma-" + column.field}
                    ref={ref => (this.sigmaPanels[column.field] = ref)}
                    dismissable={false}
                >
                    <SigmaPanel
                        options={column.sigma}
                        onCancel={() => this.sigmaPanels[column.field].hide()}
                        onSave={options => {
                            column.sigma = options;
                            this.sigmaPanels[column.field].hide();
                            this.setState({ scheme: this.state.scheme });
                        }}
                    />
                </OverlayPanel>
            );
        });
    };

    onSelectionChange = e => {
        this.setState({ selection: e.value });
        if (this.props.onSelectionChange) this.props.onSelectionChange(e);
    };

    clearSelection = () => {
        this.setState({ selection: undefined });
    }

    onTableValueChange = data => {
        this.visibleData = data;
        this.filteredData = data;
        this.setState({ scheme: this.state.scheme });
        if (this.props.onValueChange) this.props.onValueChange(data);
    };

    onColumnResizeEnd = e => {
        let { field } = e.column;
        let width = e.element.scrollWidth;
        let column = this.state.scheme.columns.find(c => c.field === field);
        column.width = width + "px";
    };

    onSort = e => {
        let scheme = this.state.scheme;
        scheme.sortOrder = e.sortOrder;
        scheme.sortField = e.sortField;
        this.setState({ scheme: scheme });
    };

    onColReorder = e => {
        let visibleColumns = this.getVisibleColumns().reduce((a, b) => {
            a[b.field] = b;
            return a;
        }, {});
        //e.columns.forEach((c, i) => (visibleColumns[c.props.field].position = i));
        this.setState({ scheme: this.state.scheme });
    };

    onFilter = f => {
        let filter = f.filters;
        this.state.scheme.columns.forEach(c => {
            if (filter[c.field]) {
                c.filter = filter[c.field].value;
                c.filterMatchMode = filter[c.field].matchMode;
            } else {
                c.filter = "";
                c.filterMatchMode = undefined;
            }
        });
        this.setState({ scheme: this.state.scheme });
        if (this.props.onFilter) this.props.onFilter(f);
    };

    openColumnChooser = e => {
        this.columnChooser.show(
            e.originalEvent.currentTarget.parentElement.previousSibling
        );
    };

    onColumnToggle = event => {
        let visibleColumns = event.value;
        //dont hide all columns
        if (visibleColumns.length === 0) return;
        let columns = this.state.scheme.columns;
        columns.forEach(column => {
            column.hidden = visibleColumns.find(c => c.field === column.field)
                ? false
                : true;
        });
        this.setState({ scheme: this.state.scheme });
    };

    openResportsDesigner = (e) => {
        this.resportsDesigner.show()
    }

    allowDrop = event => {
        event.preventDefault();
    };

    groupBy = event => {
        this.expandAllRows();
        event.preventDefault();
        var data = event.dataTransfer.getData("column");
        if (data) {
            this.setState({ scheme: { ...this.state.scheme, groupField: data } });
        }
    };

    removeGroupColumn = () => {
        this.expandAllRows();
        this.setState({ scheme: { ...this.state.scheme, groupField: undefined } });
    };

    toggleGroupFieldSort = () => {
        let sortOrder = this.state.scheme.groupSortOrder;
        sortOrder = sortOrder === 1 ? -1 : 1;

        this.setState({
            scheme: { ...this.state.scheme, groupSortOrder: sortOrder }
        });
    };

    toggleFilters = () => {
        this.setState({
            scheme: { ...this.state.scheme, filter: !this.state.scheme.filter }
        });
    };

    toggleGroup = () => {
        this.expandAllRows();
        this.setState({
            scheme: { ...this.state.scheme, group: !this.state.scheme.group }
        });
    };

    toggleSigma = () => {
        this.setState({
            scheme: { ...this.state.scheme, sigma: !this.state.scheme.sigma }
        });
    };

    saveScheme = () => {
        let { scheme } = this.state;
        localStorage.setItem(scheme.name, schemeHelper.toJson(scheme));
    };

    resetScheme = () => {
        let { scheme } = this.state;
        localStorage.removeItem(scheme.name);
        this.setState({ scheme: schemeHelper.create(this.props.scheme) });
    };

    loadScheme = () => {
        let { scheme } = this.state;
        let schemejson = localStorage.getItem(scheme.name);
        let loadedScheme = null;
        if (schemejson) {
            loadedScheme = JSON.parse(schemejson);
        }
        return loadedScheme;
    };

    expandCollapsRows = event => {
        let currentRow = event.target;
        if (currentRow.className === styles.collapsRows) {
            currentRow.className = styles.expandRows;
            currentRow = currentRow.closest(".p-rowgroup-header");
            let nextRow = currentRow.nextSibling;
            while (
                nextRow &&
                nextRow.className &&
                !nextRow.className.includes("p-rowgroup-header")
            ) {
                nextRow.classList.add(styles.hiddenRow);
                nextRow = nextRow.nextSibling;
            }
        } else if (currentRow.className === styles.expandRows) {
            currentRow.className = styles.collapsRows;
            currentRow = currentRow.closest(".p-rowgroup-header");
            let nextOne = currentRow.nextSibling;
            while (
                nextOne &&
                nextOne.className &&
                nextOne.className.includes(styles.hiddenRow)
            ) {
                nextOne.classList.remove(styles.hiddenRow);
                nextOne = nextOne.nextSibling;
            }
        }
    };

    collapseAllRows = event => {
        let exapanded = this.table.container.querySelectorAll(
            "." + styles.collapsRows
        );
        exapanded.forEach(g => {
            g.className = styles.expandRows;
            let start = g.parentElement.parentElement.parentElement;
            let nextRow = start.nextSibling;
            while (
                nextRow &&
                nextRow.className &&
                !nextRow.className.includes("p-rowgroup-header")
            ) {
                nextRow.classList.add(styles.hiddenRow);
                nextRow = nextRow.nextSibling;
            }
        });
    };

    expandAllRows = event => {
        let collapsed = this.table.container.querySelectorAll(
            "." + styles.expandRows
        );
        collapsed.forEach(g => {
            g.className = styles.collapsRows;
            let start = g.parentElement.parentElement.parentElement;
            let nextRow = start.nextSibling;
            while (
                nextRow &&
                nextRow.className &&
                !nextRow.className.includes("p-rowgroup-header")
            ) {
                nextRow.classList.remove(styles.hiddenRow);
                nextRow = nextRow.nextSibling;
            }
        });
    };

    excelExport = () => {
        if (this.state.scheme != null) {
            this.table.exportCSV();
        }
    };

    getContextMenu = () => {
        return [
            {
                label: <span><i className="fa fa-sigma" style={{ fontWeight: "bold", fontSize: '16px', fontStyle: 'normal', marginInlineStart: '2px' }} /> {$.strings.sigma}</span>,
                icon: "",
                command: this.toggleSigma
            },
            {
                label: <span><FaFilter /> {$.strings.filtering}</span>,
                command: this.toggleFilters
            },
            {
                label: <span><FaBars /> {$.strings.grouping}</span>,
                command: this.toggleGroup
            },
            {
                separator: true
            },
            {
                label: <span><FaColumns /> {$.strings.columnsChooser}</span>,
                command: this.openColumnChooser
            },
            {
                label: <span><FaSave /> {$.strings.saveDesign}</span>,
                command: () => alert($.strings.saveDesign),
                className: this.props.isReport === false ? styles.hiddenMenuItem : styles.visibleMenuItem
            },
            {
                label: <span><FaTable /> {$.strings.designDialog}</span>,
                command: this.openResportsDesigner,
                className: this.props.isReport === false ? styles.hiddenMenuItem : styles.visibleMenuItem
            },
            {
                separator: true
            },
            {
                label: $.strings.expandAll,
                icon: "fa fa-plus",
                command: this.expandAllRows,
                className: !this.state.scheme.groupField ? styles.hiddenMenuItem : styles.visibleMenuItem
            },
            {
                label: $.strings.collapseAll,
                icon: "fa fa-minus",
                command: this.collapseAllRows,
                className: !this.state.scheme.groupField ? styles.hiddenMenuItem : styles.visibleMenuItem
            },
            {
                separator: true,
                className: !this.state.scheme.groupField ? styles.hiddenMenuItem : styles.visibleMenuItem
            },
            /*{
                      label: $.strings.showData,
                      icon: 'fa fa-search',
                      command: this.props.showData
                  },
                  {
                      label: $.strings.resetFilters,
                      icon: 'fa fa-eraser',
                      
                  },*/
            // {
            //   label: $.strings.excelExport,
            //   icon: "fa fa-file-excel",
            //   command: this.excelExport
            // },
            // {
            //   label: $.strings.print,
            //   icon: "fa fa-print",
            //   command: this.props.printPreview
            // },
            // {
            //     separator: true
            // },
            {
                label: <span><FaEraser /> {$.strings.resetDesign}</span>,
                command: this.resetScheme
            },
            // {
            //     label: $.strings.saveDesign,
            //     icon: "fa fa-save",
            //     command: this.saveScheme
            // }            
        ];
    };
}
