import React from "react";
import styles from "./Calendar.module.scss";
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import Label from '../Label'

export default class Calendar extends React.Component {

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.value !== this.props.value) {
            if (this.calender && this.calender.updateInputfield) {
                this.calender.updateInputfield(this.props.value)
            }
        }
    }

    render() {
        let currentYear = (new Date()).getFullYear();
        let yearRange = (1900) + ":" + (currentYear + 10);
        return (
            <div style={{ direction: this.props.timeOnly ? 'ltr' : '' }}>
                {
                    this.props.caption && <Label htmlFor={this.props.htmlFor} isRequired={this.props.isRequired} autoComplete="off">
                        {this.props.caption}

                    </Label>
                }
                <PrimeCalendar id={this.props.id} minDate={new Date(1900, 0, 1)} ref={ref => this.calender = ref} showIcon={this.props.showIcon}
                    className={styles.calendar} {...this.props} showButtonBar={true} monthNavigator={true} yearNavigator={true} yearRange={yearRange}
                    tooltipOptions={{ position: 'bottom' }} dateFormat="dd/mm/yy">
                    {this.props.children}
                </PrimeCalendar >
                {
                    this.props.formErrors && this.props.formErrors[this.props.id] &&
                    <div className={styles.errorField} >{this.props.formErrors[this.props.id]}</div>
                }
            </div>
        );
    }
}
