import React from "react";
import ScrollTop from '../ScrollButton';
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import Checkbox from '../../Components/Checkbox';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import ClockTransEdit from "../ClockTrans/ClockTransEdit";
import { Accordion, AccordionTab } from 'primereact/accordion';
import AttendanceTrans from "../FilesAndSettings/AttendanceTrans";
import InputTime from "../../Components/InputTime/InputTime";
import Messages from "../../Components/Messages";
import Print from "../../Print";

const $ = window.$;

export default class Absence extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDesktop: window.innerWidth > 1250,
            fromCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            toCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            fromDate: new Date(),
            toDate: new Date(),
            time: new Date(),
            excludeWhoHasVacationReq: false,
            showHowHasVacation: false,
            shift: undefined,
            section: undefined,
            branch: undefined,
            department: undefined,
            filterShown: true,
            data: []

        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(711)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(656)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();

        let shiftsPromise = this.getShifts();
        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();

        let shiftsList = await shiftsPromise;
        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        this.setState({
            shiftsList: shiftsList, branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            shift: shiftsList && shiftsList.length > 0 ? shiftsList[0] : undefined,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.absence.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <AttendanceTrans ref={e => this.dlgAttendnanceTrans = e} onSuccess={async e => await this.getData()} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.absence.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.absence.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.absence.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.absence.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-lg-1 p-sm-6">
                            <InputTime
                                id="time"
                                value={this.state.time}
                                caption={$.strings.absence.time}
                                valueChanged={e => this.setState({ time: e.value })}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showHowHasVacation" caption={$.strings.absence.showHowHasVacation}
                                onChange={e => this.setState({ showHowHasVacation: e.checked })} checked={this.state.showHowHasVacation} />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="excludeWhoHasVacationReq" caption={$.strings.absence.excludeWhoHasVacationReq}
                                onChange={e => this.setState({ excludeWhoHasVacationReq: e.checked })} checked={this.state.excludeWhoHasVacationReq} />
                        </div>
                    </div>
                    {
                        Util.checkISHR() &&
                        <div className="p-grid">
                            <div className="p-col-6 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="shift"
                                    innerRef={e => this.drpShifts = e}
                                    value={this.state.shift}
                                    options={this.state.shiftsList}
                                    caption={$.strings.absence.shift}
                                    onChange={this.handleUserInput}
                                    optionLabel="ShiftName"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="section"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.absence.section}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="department"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.department}
                                    options={this.state.departmentsList}
                                    caption={$.strings.absence.department}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="branch"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.branch}
                                    options={this.state.branchesList}
                                    caption={$.strings.absence.branch}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                        </div>
                    }
                </AccordionTab>
            </Accordion>
            <Card>
                <DataGrid
                    //licenseKey="..."
                    ref={e => this.gridData = e}
                    isReport={true}
                    exportFileName={$.strings.absence.title}
                    theme="default-light"
                    idProperty="serial"
                    style={gridStyle}
                    scheme={this.getScheme()}
                    dataSource={this.state.data}
                />
            </Card>
            <ClockTransEdit ref={e => this.dlgClockTransEdit = e} onSuccess={this.onClockTransEditSuccess} />
            <ScrollTop />
        </div>
    }

    onClockTransEditSuccess = async () => {
        await this.getData()
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }

    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            have_abs: this.state.showHowHasVacation,
            have_VacationRequest: this.state.excludeWhoHasVacationReq,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            fromCode: this.state.fromCode && this.state.fromCode.length > 0 ? this.state.fromCode : '00000',
            toCode: this.state.toCode && this.state.toCode.length > 0 ? this.state.toCode : 'ZZZZZ',
            userId: localStorage.getItem("useridHR") + "",
            selectedSection: this.state.section ? this.state.section.Code : "-1",
            isHr: Util.checkISHR(),
            selectedShifts: this.state.shift && this.state.shift.ID >= 0 ? this.state.shift.ID : -1,
            selectedDepartment: this.state.department && this.state.department.pos > 0 ? this.state.department.pos : "-1",
            selectedBranchValue: this.state.branch && this.state.branch.Pos > 0 ? this.state.branch.Pos : "-1",
            empCode: localStorage.getItem("useridHR") + ""
        }
        let res = await http.getJsonAxios(http.actions.Absence.urlGetAbsenceReport, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.forEach(element => {
                    element.Date = new Date(element.Date)
                });
            }
        }

        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.absence.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";

            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.absence.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.absence.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.absence.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.absence.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter);

            if (this.printFilters.time)
                listOfFilters.push($.strings.absence.time + ": " + this.printFilters.time);

            if (this.printFilters.showHowHasVacation)
                listOfFilters.push($.strings.absence.showHowHasVacation + ": " + (this.printFilters.showHowHasVacation ? $.strings.yes : $.strings.no))

            if (this.printFilters.excludeWhoHasVacationReq)
                listOfFilters.push($.strings.absence.excludeWhoHasVacationReq + ": " + (this.printFilters.excludeWhoHasVacationReq ? $.strings.yes : $.strings.no))

            if (Util.checkISHR()) {
                if (this.printFilters.shift) {
                    listOfFilters.push($.strings.absence.shift + ": " + this.printFilters.shift.ShiftName);
                }
                if (this.printFilters.section) {
                    listOfFilters.push($.strings.absence.section + ": " + this.printFilters.section.Name);
                }
                if (this.printFilters.branch) {
                    listOfFilters.push($.strings.absence.branch + ": " + this.printFilters.branch.Name);
                }
                if (this.printFilters.department) {
                    listOfFilters.push($.strings.absence.department + ": " + this.printFilters.department.name);
                }

            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    buttonClick = (e, ctx) => {
        this.dlgClockTransEdit.show(ctx.item.No, ctx.item.Date, 0);
    }
    buttonNewClick = (e, ctx) => {
        //alert("اضافة حركة دوام");
        this.dlgAttendnanceTrans.show(0, ctx.item.No, ctx.item.Name, ctx.item.Date, ctx.item.Date);
    }
    getScheme = () => {
        return {
            name: "absenceRptScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 6,
            columns: [
                { name: 'RowNumber', header: '##', width: 80, type: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                {
                    name: 'btnButtonClick', header: ' ', width: 60, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.edit,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.buttonClick(e, ctx)
                },
                {
                    name: 'btnButtonNewClick', header: ' ', width: 60, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.new,
                    iconType: 'add', className: 'success', onClick: (e, ctx) => this.buttonNewClick(e, ctx)
                },
                {
                    name: 'Date', header: $.strings.absence.date, width: 120, dataType: 'Date', columnWidth: '3', format: Util.gridDateFormat()
                },
                {
                    name: 'Day', header: $.strings.absence.day, width: 120, columnWidth: '2'
                },
                {
                    name: 'No', header: $.strings.absence.empCode, width: 120, columnWidth: '2'
                },
                {
                    name: 'Name', header: $.strings.absence.empName, width: "2*", columnWidth: '*'
                },
                {
                    name: 'Section', header: $.strings.absence.section, width: "2*", columnWidth: '*'
                },
                {
                    name: 'Note', header: $.strings.absence.notes, width: "2*", columnWidth: '*'
                }
            ]
        }
    }
}