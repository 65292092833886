import React from "react";
import Button from '../../../Button'
import { FaBarcode, FaTrashAlt, FaSearch,FaBars } from 'react-icons/fa'

export default class ButtonTemplate extends React.Component {

    render() {

        return (
            <>
                {/* <span className="p-column-title">&nbsp;</span> */}
                <Button className={this.props.iconType === "delete" ? 'p-button-danger' : ''} onClick={this.props.toDoFunction}>
                    {
                        this.setButtonIcon()
                    }
                </Button>
            </>
        );
    }

    setButtonIcon = () => {
        switch (this.props.iconType) {
            case "search": {
                return <FaSearch />;//"pi pi-search";
            }
            case "delete": {
                return <FaTrashAlt />;//"pi pi-trash"
            }
            case "barcode": {
                return <FaBarcode style={{ height: '100%', width: '100%' }} />;
            }
            case "menu":{
                return  <FaBars style={{ height: '100%', width: '100%' }} />;
            }
            default: {
                break
            }
        }
    }

    onClick = () => {
        switch (this.props.actionType) {
            case "search": {
                this.props.toDoFunction();
                break;
            }
            case "delete": {
                this.props.toDoFunction();
                break;
            }
            case "menu": {
                this.props.toDoFunction();
                break;
            }
            default: {
                break
            }
        }
    }
}