import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import Util from '../Util';
import http from '../Api/http';
import Messages from '../Components/Messages';
import ProgressSpinner from '../Components/ProgressSpinner';
import MCPwd from './MCPwd';
import SelectButton from "../Components/SelectButton";

const $ = window.$

export default class Login extends Component {

	constructor(props) {
		super(props);
		$.history = props.history;
		let l = localStorage.getItem("HRlang");
		this.state = {
			loading: true,
			language: "en",//l || "en",
			checked: localStorage.getItem("rememberMeHR") && localStorage.getItem("rememberMeHR") + "" === "true" ? true : false,
			userName: localStorage.getItem("rememberMeUserNameHR") || '',
			userPwd: '',
			loginType: 'Employee Portal'
		};
	}

	componentDidMount = async () => {
		localStorage.setItem("tabModeHR", "Accordion");
		if (localStorage.getItem("rememberMeHR")) {
			this.txtPassword.focus();
		}
		await this.loadLanguage(this.state.language);
		this.setState({
			isRTL: $.strings.dir === "rtl" ? true : false, loginType: localStorage.getItem("loginTypeHR") ? localStorage.getItem("loginTypeHR") + "" : 'Employee Portal',
			loading: false
		});
	}

	loadLanguage = async lang => {
		$.strings = await import(`../Languages/${lang}.json`);
		$.pages = await import('../pages.json')
	};

	render() {

		const loginOptions = ["Employee Portal", "HR Portal"];
		return <div id="divLogin" className="login-body" dir='ltr'>
			<ProgressSpinner loading={this.state.loading} />
			<div className="card login-panel ui-fluid" dir='ltr'>
				<div className="login-panel-content">
					<div className="p-grid">
						<div className="p-col-12 p-sm-6 p-md-10 logo-container">
							<img src="../../assets/layout/images/logo.png" alt="HR Self Service" />
							<span className="guest-sign-in">Welcome, please use the form to Sign-in to HR Self Service</span>
						</div>
						<div className="p-grid" style={{ width: '100%' }}>
							<div className="p-col-12 p-sm-12 p-md-12" style={{ width: '100%' }}>
								<Messages innerRef={(el) => this.messages = el} />
							</div>
						</div>
						<div className="p-col-12 p-sm-6 p-md-10">
							<SelectButton value={this.state.loginType} options={loginOptions} onChange={(e) => this.setState({ loginType: e.value })}
								tooltip="Choose login type (Employee Portal or HR Portal)" tooltipOptions={{ position: 'top' }} unselectable={false} />
						</div>
						<div className="p-col-12 username-container">
							<label>User name</label>
							<div className="login-input">
								<InputText value={this.state.userName} id="input" type="text" ref={ref => this.txtUserName = ref}
									onChange={e => this.setState({ userName: e.target.value })} style={{ width: '100%', textTransform: 'uppercase' }} autoComplete="off" />
							</div>
						</div>
						<div className="p-col-12 password-container">
							<label>Password</label>
							<div className="login-input">
								<InputText value={this.state.userPwd} type="password" maxLength="16" ref={ref => this.txtPassword = ref}
									style={{ width: '100%' }} feedback={false} autoComplete="off"
									onChange={e => { this.setState({ userPwd: e.target.value }) }} onKeyDown={this.onKeyDown} />
							</div>
						</div>
						<div className="p-col-12 p-sm-6 p-md-6 rememberme-container">
							<Checkbox checked={this.state.checked} onChange={e => this.setState({ checked: e.checked })} />
							<label>Remember Me</label>
						</div>
						<div className="p-col-12 p-sm-12 p-md-12">
							<Button label="Login" icon="" onClick={this.onLoginClick} style={{ minHeight: '30px', minWidth: '120px' }}>
								&nbsp;&nbsp;
								<i className="pi pi-sign-in" />
							</Button>
						</div>
					</div>
				</div>
			</div>
			<MCPwd ref={e => this.mChangePwd = e}></MCPwd>
		</div >
	}

	onKeyDown = async (e) => {
		if (e.keyCode === 13)
			await this.onLoginClick()
	}

	onLoginClick = async () => {
		if (this.state.loginType === "Employee Portal") {
			this.login();
		}
		else if (this.state.loginType === "HR Portal") {
			this.loginHR();
		}
		else {
			Util.showErrorMsg(this.messages, "Please select login type (Employee or HR Portal)")
		}
	}

	login = async () => {
		localStorage.setItem("isLoggedInHR", '');
		localStorage.setItem("newsHR", '');
		this.messages.clear();
		if (this.state.userName === "") {
			Util.showErrorMsg(this.messages, 'Please enter the user name');
			this.txtUserName.focus();
			return;
		}
		else if (this.state.userPwd === "") {
			Util.showErrorMsg(this.messages, 'Please enter the password');
			this.txtPassword.focus();
			return;
		}
		this.setState({ loading: true })
		let obj = {
			username: this.state.userName,
			userPassword: Util.encryptStringToHexa(this.state.userPwd),
			company_id: 1
		}

		let res = await http.postMultipartAxios(http.actions.Login.doLogin, obj, undefined, undefined, 0);
		if (res.status !== 200) {
			if (res.data.errorText && res.data.errorText !== "") {
				Util.showErrorMsg(this.messages, res.data.errorText)
			}
			else {
				Util.showErrorMsg(this.messages, 'Login failed, please try again')
			}
			this.setState({ loading: false })
			return;
		}

		if (res.data.success) {
			localStorage.setItem("loginTypeHR", "Employee Portal");
			localStorage.setItem("ISHR", "0");
			localStorage.setItem("tabModeHR", "Accordion");
			localStorage.setItem("rememberMeHR", this.state.checked);
			if (this.state.checked) {
				localStorage.setItem("rememberMeUserNameHR", this.state.userName)
			}
			else {
				localStorage.removeItem("rememberMeUserNameHR")
			}
			localStorage.setItem("useridHR", res.data.empCode);
			localStorage.setItem("dmCode", res.data.dmCode);
			localStorage.setItem("dmEmployees", JSON.stringify(res.data.dmEmployees))

			localStorage.setItem("userNameHR", res.data.empName);
			localStorage.setItem("userNameEnHR", res.data.empNameEn);
			localStorage.setItem("mcPWDHR", res.data.MCPWD);
			localStorage.setItem("tabModeHR", "Accordion");
			localStorage.setItem("roleHR", res.data.jobName);
			localStorage.setItem("roleEnHR", res.data.jobNameEn)
			localStorage.setItem("empLanguageHR", res.data.empLanguage);
			localStorage.setItem("rightsHR", JSON.stringify(res.data.rightsList));

			localStorage.setItem("settingsListHR", JSON.stringify(res.data.settingsList));
			localStorage.setItem("newsHR", JSON.stringify(res.data.newsList));

			localStorage.setItem("licenseModulesHR", JSON.stringify(res.data.licenseModules));
			localStorage.setItem("userImageHR", res.data.empImage);
			localStorage.setItem("isLoggedInHR", res.data.token)
			localStorage.setItem("codeLen", res.data.codeLen)
			if (res.data.isManager) {
				localStorage.setItem("isManagerHR", "true");
				localStorage.setItem("isEmp", "false");
			}
			else {
				localStorage.setItem("isManagerHR", "false");
				localStorage.setItem("isEmp", "true");
			}
			if (res.data.isDelegated) {
				localStorage.setItem("isDelegatedHR", "true");
			}
			else {
				localStorage.setItem("isDelegatedHR", "false");
			}

			localStorage.setItem("notCount", res.data.notCount);
			// Emp Settings
			let settings = res.data.empSettingsObj ? res.data.empSettingsObj.list : []
			Util.loadEmpSettings(settings);

			if (res.data.empLanguage === 1) {
				localStorage.setItem("HRlang", "ar");
			}
			else if (res.data.empLanguage === 2) {
				localStorage.setItem("HRlang", "en");
			}
			if (localStorage.getItem("mcPWDHR") + "" === "true") {
				this.setState({ userPwd: "", loading: false },
					() => setTimeout(this.mChangePwd.show(), 0));
				return
			}

			$.showSessionExpired = true;
			$.requestsAliases = res.data.requestsAliasesList;
			$.utcOffset = res.data.utcOffset;

			if (Util.checkISManager()) {
				Util.goTo("/HandleRequests", "");
			}
			else {
				Util.goTo("/", "");
			}
		}
		else {
			if (res.data.errorText && res.data.errorText !== "") {
				this.setState({ userPwd: "", loading: false })
				Util.showErrorMsg(this.messages, res.data.errorText)
			}
			else {
				this.setState({ userPwd: "", loading: false })
				Util.showErrorMsg(this.messages, 'Login failed, please try again')
			}
		}
	}

	loginHR = async () => {
		try {
			localStorage.setItem("isLoggedInHR", '');
			localStorage.setItem("newsHR", '');
			this.messages.clear();
			if (this.state.userName === "") {
				Util.showErrorMsg(this.messages, 'Please enter the user name');
				this.txtUserName.focus();
				return;
			}
			else if (this.state.userPwd === "") {
				Util.showErrorMsg(this.messages, 'Please enter the password');
				this.txtPassword.focus();
				return;
			}
			this.setState({ loading: true })
			let obj = {
				username: this.state.userName,
				userPassword: Util.encryptStringToHexa(this.state.userPwd),
				company_id: 1
			}

			let res = await http.postMultipartAxios(http.actions.Login.doLoginHR, obj, undefined, undefined, 0);
			if (res.status !== 200) {
				if (res.data.errorText && res.data.errorText !== "") {
					Util.showErrorMsg(this.messages, res.data.errorText)
				}
				else {
					Util.showErrorMsg(this.messages, 'Login failed, please try again')
				}
				this.setState({ loading: false })
				return;
			}
			if (res.data.success) {
				localStorage.setItem("loginTypeHR", "HR Portal");
				localStorage.setItem("ISHR", "1");
				localStorage.setItem("tabModeHR", "Accordion");
				localStorage.setItem("rememberMeHR", this.state.checked);
				if (this.state.checked) {
					localStorage.setItem("rememberMeUserNameHR", this.state.userName)
				}
				else {
					localStorage.removeItem("rememberMeUserNameHR")
				}
				
				localStorage.setItem("useridHR", res.data.UserCode);
				localStorage.setItem("userNameHR", res.data.UserName);
				localStorage.setItem("userNameEnHR", res.data.UserName);
				localStorage.setItem("mcPWDHR", res.data.MCPWD);
				localStorage.setItem("tabModeHR", "Accordion");
				localStorage.setItem("roleHR", res.data.jobName);
				localStorage.setItem("roleEnHR", res.data.jobNameEn)
				//localStorage.setItem("empLanguageHR", res.data.empLanguage);
				localStorage.setItem("empLanguageHR", "1");
				localStorage.setItem("rightsHR", JSON.stringify(res.data.rightsList));
				localStorage.setItem("settingsListHR", JSON.stringify(res.data.settingsList));
				localStorage.setItem("licenseModulesHR", JSON.stringify(res.data.licenseModules));
				localStorage.setItem("newsHR", '');

				localStorage.setItem("userImageHR", res.data.empImage);
				localStorage.setItem("isLoggedInHR", res.data.token)

				localStorage.setItem("isManagerHR", "false");
				localStorage.setItem("isEmp", "false");

				localStorage.setItem("notCount", res.data.notCount);
				localStorage.setItem("codeLen", res.data.codeLen)
				// Emp Settings
				let settings = res.data.empSettingsObj ? res.data.empSettingsObj.list : []
				Util.loadEmpSettings(settings);
				localStorage.setItem("HRlang", "ar");
				if (res.data.empLanguage === 1) {
					localStorage.setItem("HRlang", "ar");
				}
				else if (res.data.empLanguage === 2) {
					localStorage.setItem("HRlang", "en");
				}
				if (localStorage.getItem("mcPWDHR") + "" === "true") {
					this.setState({ userPwd: "", loading: false },
						() => setTimeout(this.mChangePwd.show(), 0));
					return
				}
				$.showSessionExpired = true;
				$.requestsAliases = res.data.requestsAliasesList;
				$.utcOffset = res.data.utcOffset;
				Util.goTo("/HandleRequests", "");
			}
			else {
				if (res.data.errorText && res.data.errorText !== "") {
					this.setState({ userPwd: "", loading: false })
					Util.showErrorMsg(this.messages, res.data.errorText)
				}
				else {
					this.setState({ userPwd: "", loading: false })
					Util.showErrorMsg(this.messages, 'Login failed, please try again')
				}
			}
		}
		catch (e) {
			alert(e);
		}
	}
}