import React from "react";
import Input from "../../../Components/Input";
import Messages from '../../../Components/Messages';
import Http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import Util from '../../../Util';
import Card from "../../../Components/Card";
import Dialog from "../../../Components/Dialog";

import SCC from '../../ScreenCommonCode'
import RadioButton from "../../../Components/RadioButton/index";
import { Fieldset } from 'primereact/fieldset';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class GeneralAdd extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            formErrors: {},
            deleteErrors: { deleteNote: '' },
            dataObject: {
                ID: this.props.Data.ID || 0,
                Name: this.props.Data.Name || '',
                EName: this.props.Data.EName || '',
            }
        }
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250
        this.setState({ loading: false, isDesktop: _isDesktop });
        this.hash = Util.hashState(this.state.dataObject);
    }

    onNameBlur = (e) => {
        if (!this.state.dataObject.EName)
            this.setState({ dataObject: { ...this.state.dataObject, EName: e.target.value } }, e.target.selectAll);
    }

    validateFields() {
        let result = true;
        let fieldsValidationErrors = this.state.formErrors;

        fieldsValidationErrors.Name = "";
        if (!this.state.dataObject.Name || this.state.dataObject.Name <= 0) {
            fieldsValidationErrors.Name = $.strings.requiredFiled;
            result = false;
        }
        fieldsValidationErrors.EName = "";
        if (!this.state.dataObject.EName || this.state.dataObject.EName <= 0) {
            fieldsValidationErrors.EName = $.strings.requiredFiled;
            result = false;
        }

        return result;
    }

    render() {

        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={this.state.pageInfo.title} onHide={this.onClose} modal
                    visible={true} style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir }}>

                    {SCC.getScreenHeader_Buttons(this, true)}
                    <ProgressSpinner loading={this.state.loading} />
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <Messages innerRef={(el) => this.messages = el} />
                        </div>
                    </div>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtName = el}
                                    type="text"
                                    id="Name"
                                    maxLength="50"
                                    value={this.state.dataObject.Name}
                                    autofocus={true}
                                    caption={$.strings.requestsAliases.Name}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    onBlur={this.onNameBlur}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    type="text"
                                    id="EName"
                                    maxLength="50"
                                    value={this.state.dataObject.EName || ''}
                                    caption={$.strings.requestsAliases.eName}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                    </Card>
                </Dialog>
            </div>
        );
    }

    // onSave = async (e) => {
    //     this.save(e, true, false);
    // };

    onSaveThenClose = async (e) => {
        this.save(e, true, true);
    };

    save = async (e, fromSave, closeAfterSave) => {
        //waiting progress
        this.setState({ loading: true });

        //validate
        if (!this.validateFields()) {
            this.setState({ loading: false, showErrors: true });
            return false;
        }

        let isSucceeded = true;
        let result = await this.sendData();
        if (result.status === 200) {
            if (result.data.success) {
                this.doRefreshRequired = true;
                if (closeAfterSave) {
                    if (this.props.onSuccess) {
                        this.props.onSuccess(true);
                    }
                    this.setState({ loading: false });
                } else {
                    Util.showSuccessMsg(this.messages);
                    if (fromSave) {
                        this.resetFields();
                    }
                    this.hash = Util.hashState(this.state.dataObject);
                }
            }
            else {
                isSucceeded = false;
                let msg = $.strings.operationFailed;
                if (result.data.errorText && result.data.errorText.length > 0) {
                    msg = result.data.errorText;
                    Util.showErrorMsg(this.messages, msg, false);
                }
            }
        } else {
            isSucceeded = false;
            let msg = $.strings.operationFailed;
            if (result.errorText && result.errorText.length > 0) {
                msg = result.errorText;
                Util.showErrorMsg(this.messages, msg, false);
            }
        }
        this.setState({ loading: false });
        return isSucceeded;
    };

    sendData = async () => {
        let obj = {
            ID: this.state.dataObject.ID,
            Name: this.state.dataObject.Name,
            EName: this.state.dataObject.EName,
        }

        let result
        let res = await Http.getJsonAxios(Http.actions.RequestsAliases.urlSaveRequestsAliases, obj, 0);
        result = res;
        return result;
    };

    resetFields = () => {
        this.setState({
            dataObject: {
                ID: 0,
                Name: '',
                EName: '',
            }
        }, () => { this.txtName.focus(); this.hash = Util.hashState(this.state.dataObject); });
    }

    // onNew = (e, checkChanging) => {
    //     if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
    //         Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
    //             async () => await this.onSave(e, false),
    //             () => this.onNew(e, false)
    //         );
    //         return;
    //     }
    //     this.messages.clear();
    //     this.resetFields()
    // }

    onClose = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
                async () => await this.onSaveThenClose(e, false),
                () => this.props.onClose(this.doRefreshRequired)
            );
            return;
        }
        if (this.props.onClose) {
            this.props.onClose(this.doRefreshRequired);
        }
    }

    navigate = async (navigationType, checkChanging, specificId) => {
        //check fields changing
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged,
                'pi pi-question-circle',
                (e) => this.save(e, false, false),
                (e) => {
                    switch (navigationType) {
                        case Util.navigationEnum.PREVIOUS:
                            this.onPrevious(e, false);
                            break;
                        case Util.navigationEnum.NEXT:
                            this.onNext(e, false);
                            break;
                        case Util.navigationEnum.FIRST:
                            this.onFirst(e, false);
                            break;
                        case Util.navigationEnum.LAST:
                            this.onLast(e, false);
                            break;
                        default: {
                            break;
                        }
                    }
                });
            return;
        }
        this.setState({ loading: true });
        //determind the id
        let id = 0;
        if (
            navigationType === Util.navigationEnum.PREVIOUS ||
            navigationType === Util.navigationEnum.NEXT
        ) {
            if (this.state.dataObject.pos)
                id = this.state.dataObject.pos;
            else {
                if (navigationType === Util.navigationEnum.PREVIOUS)
                    navigationType = Util.navigationEnum.FIRST;
                else navigationType = Util.navigationEnum.LAST;
            }
        }
        if (navigationType === Util.navigationEnum.LAST) id = Util.integ.maxValue;
        if (navigationType === Util.navigationEnum.GETIT) id = specificId;

        let param = {
            type: parseInt(this.props.lookupType),
            pos: id,
        };

        let url = "";
        switch (navigationType) {
            case Util.navigationEnum.PREVIOUS:
                //url = Http.actions.MultiFl.urlGetPrevious;
                break;
            case Util.navigationEnum.NEXT:
                //url = Http.actions.Tasks.urlGetNext;
                break;
            case Util.navigationEnum.FIRST:
                //url = Http.actions.Tasks.urlGetFirst;
                break;
            case Util.navigationEnum.LAST:
                //url = Http.actions.Tasks.urlGetLast;
                break;
            case Util.navigationEnum.GETIT:
            default: {
                url = Http.actions.MultiFl.urlGetMultiFlByTypePos;
                break;
            }
        }

        let res = await Http.getJsonAxios(url, param, 0);
        let result = false;
        if (res.status === 200) {
            if (res.data.success) {
                result = true;
                if (res.data.multiFlList === null) {
                    if (navigationType === Util.navigationEnum.PREVIOUS)
                        Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
                    if (navigationType === Util.navigationEnum.NEXT)
                        Util.showInfoMsg(this.messages, $.strings.recordsEnds);
                    this.setState({ loading: false });
                } else {

                    let obj;
                    if (res.data.dataObject && res.data.dataObject.length > 0) {
                        obj = res.data.dataObject[0];
                    }
                    this.display(obj);
                }
            }
        }
        if (!result) {
            Util.showInfoMsg(this.messages, $.strings.operationFailed);
            this.setState({ loading: false });
        }
    }
    display = (data) => {
        if (!data) {
            return;
        }
        if (this.messages)
            this.messages.clear();

        this.setState(
            {
                dataObject: {
                    ...this.state.dataObject,
                    ID: data.ID,
                    Name: data.Name,
                    EName: data.EName,
                },
                formErrors: {},
                visibleDialog: true,
                loading: false
            }, () => {
                this.hash = Util.hashState(this.state.dataObject)
                setTimeout(() => {
                    this.txtName.focus()
                }, 10);
            }
        );
    };

    setPageInfo = () => {
        let pageId
        let title = $.strings.requestsAliases.title;

        return { pageId: pageId, title: title };
    };
}