import React from "react";
import styles from "./ButtonsBarLookups.module.scss";
import Button from "../Button";
import { Menu } from "primereact/menu";
import { FaTrash, FaRegPlusSquare, FaFlag, FaRegClock } from "react-icons/fa";

const $ = window.$;

export default class ButtonsBarLookups extends React.Component {
  constructor(props) {
    super(props);
    this.menuItemsList = this.splitButtonItems();
    this.menuItemsListDot = this.splitButtonItemsDot();
    this.state = {
      passedItemsList: this.props.itemsList,
      splitItems: this.menuItemsList.filter(
        (element) => element.visible === true
      ),
      splitItemsDot: this.menuItemsListDot.filter((element) => element.visible === true),
      isDesktop: window.innerWidth > 1250
    };
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 1250 });
  };

  render() {
    return this.state.isDesktop ? (
      <div className={`p-grid ${styles.buttonBarLookup}`}>
        <div className="p-col-8 p-lg-8 p-md-8">
          {(this.props.permissions.new.visible && !this.props.onNew
            ? false
            : true) && (
              <Button
                onClick={this.props.onNew}
                label={$.strings.new}
                tooltip={$.strings.new}
                disabled={this.props.permissions.new.disabled}
              >
                &nbsp;&nbsp;
                <i className="pi pi-plus" />
              </Button>
            )}

          {this.props.onAddSub && (
            <Button
              onClick={this.props.onAddSub}
              tooltip={this.props.onAddSubTooltip}
              style={{ marginLeft: "100px" }}
            >
              <FaRegPlusSquare />
            </Button>
          )}
          {(this.props.permissions.edit.visible && !this.props.onEdit
            ? false
            : true) && (
              <Button
                onClick={this.props.onEdit}
                tooltip={$.strings.edit}
                disabled={this.props.permissions.edit.disabled}
                icon={"pi pi-pencil"}
              ></Button>
            )}
          {(this.props.permissions.print.visible && !this.props.onPrint
            ? false
            : true) && (
              <Button
                onClick={this.props.onPrint}
                tooltip={$.strings.print}
                disabled={this.props.permissions.print.disabled}
                icon={"pi pi-print"}
              ></Button>
            )}
          {(this.props.permissions.excelExport.visible &&
            !this.props.onExcelExport
            ? false
            : true) && (
              <Button
                onClick={this.props.onExcelExport}
                tooltip={$.strings.excelExport}
                disabled={this.props.permissions.excelExport.disabled}
                icon={"pi pi-file-excel"}
              ></Button>
            )}
          {(this.props.permissions.refresh.visible && !this.props.onRefresh
            ? false
            : true) && (
              <Button
                onClick={this.props.onRefresh}
                tooltip={$.strings.refresh}
                disabled={this.props.permissions.refresh.disabled}
                label={$.strings.refresh}
              >
                &nbsp;&nbsp;
                <i className="pi pi-sync" />
              </Button>
            )}

          {(this.props.permissions.previous.visible && !this.props.onPrevious
            ? false
            : true) && (
              <Button
                onClick={this.props.onPrevious}
                tooltip={$.strings.previous}
                disabled={this.props.permissions.previous.disabled}
                icon={
                  $.strings.dir === "rtl"
                    ? "pi pi-angle-right"
                    : "pi pi-angle-left"
                }
              ></Button>
            )}
          {(this.props.permissions.next.visible && !this.props.onNext
            ? false
            : true) && (
              <Button
                onClick={this.props.onNext}
                tooltip={$.strings.next}
                disabled={this.props.permissions.next.disabled}
                icon={
                  $.strings.dir === "rtl"
                    ? "pi pi-angle-left"
                    : "pi pi-angle-right"
                }
              ></Button>
            )}

          {((this.props.permissions.first.visible && !this.props.onFirst
            ? false
            : true) ||
            (this.props.permissions.last.visible && !this.props.onLast
              ? false
              : true) ||
            (this.props.permissions.clone.visible && !this.props.onClone
              ? false
              : true) ||
            (this.props.permissions.activate.visible && !this.props.onActivate
              ? false
              : true) ||
            (this.props.permissions.deactivate.visible &&
              !this.props.onDeactivate
              ? false
              : true) ||
            (this.props.permissions.delete.visible && !this.props.onDelete
              ? false
              : true) ||
            (!this.props.onLogFile ? false : true)) && (
              <div style={{ position: "relative", display: "inline-block" }}>
                <Button
                  onClick={this.showSplitButtonsDot}
                  tooltip={$.strings.more}
                  icon="pi pi-bars"
                >
                  {/* <FaBars /> */}
                </Button>
                <Menu
                  model={this.splitButtonItemsDot().filter(e => e.visible === true)}//this.state.splitItemsDot}
                  popup={true}
                  ref={(ref) => (this.menuDot = ref)}
                  className={styles.fixMenuButton}
                />
              </div>
            )}
          {(this.props.permissions.saveWithoutClose.visible && this.props.onSave
            ? true
            : false) && (
              <Button
                onClick={this.props.onSave}
                tooltip={$.strings.save}
                innerRef={(ref) => (this.btnSave = ref)}
                disabled={this.props.permissions.saveWithoutClose.disabled}
                icon="pi pi-save"
                id="btnSave"
                className="p-button-success"
              >
                {/* <FaSave /> */}
              </Button>
            )}
        </div>
        <div className={` ${"p-lg-4 p-md-4"} ${styles.operationButtons}`} style={{ textAlign: $.strings.dir === "rtl" ? 'left' : 'right' }}>
          {/* <div className="p-toolbar-group-left"> */}
          {(this.props.permissions.save.visible && !this.props.onSaveThenClose
            ? false
            : true) && (
              <Button
                onClick={this.props.onSaveThenClose}
                tooltip={$.strings.saveAndClose}
                icon="pi pi-check"
                className="p-button-success"
              >
                {/* <FaCheck /> */}
              </Button>
            )}
          {this.props.onClose && (
            <Button
              onClick={this.props.onClose}
              tooltip={$.strings.close}
              icon="pi pi-times"
              className="p-button-danger"
            >
              {/* <FaTimes style={{ color: 'red' }} /> */}
            </Button>
          )}
        </div>
      </div>
    ) : (
      <div>
        <Menu
          model={this.state.splitItems}
          popup={true}
          ref={(ref) => (this.menu = ref)}
        />
        {((this.props.permissions.saveWithoutClose.visible && !this.props.onSave
          ? false
          : true) ||
          (this.props.permissions.new.visible && !this.props.onNew
            ? false
            : true) ||
          (this.props.permissions.delete.visible && !this.props.onDelete
            ? false
            : true) ||
          (this.props.permissions.edit.visible && !this.props.onEdit
            ? false
            : true) ||
          (this.props.permissions.print.visible && !this.props.onPrint
            ? false
            : true) ||
          (this.props.permissions.excelExport.visible &&
            !this.props.onExcelExport
            ? false
            : true) ||
          (this.props.permissions.refresh.visible && !this.props.onRefresh
            ? false
            : true) ||
          (this.props.permissions.previous.visible && !this.props.onPrevious
            ? false
            : true) ||
          (this.props.permissions.next.visible && !this.props.onNext
            ? false
            : true) ||
          (this.props.permissions.save.visible && !this.props.onSaveThenClose
            ? false
            : true) ||
          (this.props.permissions.first.visible && !this.props.onFirst
            ? false
            : true) ||
          (this.props.permissions.last.visible && !this.props.onLast
            ? false
            : true) ||
          (this.props.permissions.clone.visible && !this.props.onClone
            ? false
            : true)) && (
            <Button onClick={this.showSplitButtons} style={{ top: "0px" }}>
              {/* <FaEllipsisV style={{ top: '0px' }} /> */}
              <i className="pi pi-ellipsis-v"></i>
            </Button>
          )}
      </div>
    );
  }

  showSplitButtons = (event) => {
    this.menu.toggle(event);
  };

  showSplitButtonsDot = (event) => {
    this.menuDot.toggle(event);
  };

  splitButtonItemsDot = () => {

    let items = [
      {
        label: (<Button label={$.strings.first} icon={$.strings.dir === "rtl" ? "pi pi-angle-double-right" : "pi p-angle-double-left"}
          tooltip={$.strings.firstTooltip} className={styles.menuItem} />
        ),
        command: this.props.onFirst,
        visible: !this.props.onFirst ? false : true,
        //disabled: this.props.permissions.first.disabled,
      },
      {
        label: (<Button label={$.strings.end} icon={$.strings.dir === "rtl" ? "pi pi-angle-double-left" : "pi pi-angle-double-right"}
          tooltip={$.strings.endTooltip} className={styles.menuItem} />
        ),
        command: !this.props.permissions.last.disabled ? this.props.onLast : "",
        visible: !this.props.onLast ? false : true,
        //  disabled: this.props.permissions.last.disabled,
      },
      {
        separator: true,
        visible:
          this.props.permissions.first.visible ||
          this.props.permissions.last.visible,
      },
      {
        label: (<Button label={$.strings.clone} icon="pi pi-copy" tooltip={$.strings.cloneTooltip} className={styles.menuItem} />),
        command: this.props.onClone,
        visible: !this.props.onClone ? false : true,
        //disabled: this.props.permissions.clone.disabled,
      },
      {
        separator: true,
        visible: this.props.permissions.clone.visible,
      },
      {
        label: (<Button tooltip={$.strings.deactivateTooltip} className={styles.menuItem}          >
          <FaFlag style={{ color: "red" }} />
          <span style={{ width: "100%", textAlign: "center" }}>
            {$.strings.deactivate}
          </span>
        </Button>
        ),
        command: this.props.onDeactivate,
        visible: this.props.onDeactivate ? true : false,
        //disabled: this.props.permissions.deactivate.disabled,
      },
      {
        label: (<Button tooltip={$.strings.activateTooltip} className={styles.menuItem}          >
          <FaFlag style={{ color: "limegreen" }} />
          <span style={{ width: "100%", textAlign: "center" }}>
            {$.strings.activate}
          </span>
        </Button>
        ),
        command: this.props.onActivate,
        visible: this.props.onActivate ? true : false,
        //disabled: this.props.permissions.activate.disabled,
      },
      {
        label: (<Button tooltip={$.strings.deleteTooltip} className={`${styles.menuItem} ${"p-button-danger"}`}          >
          <FaTrash />
          <span style={{ width: "100%", textAlign: "center" }}>
            {$.strings.delete}
          </span>
        </Button>
        ),
        command: this.props.onDelete,
        visible: this.props.onDelete ? true : false,
        //disabled: this.props.permissions.delete.disabled,
      },
      {
        separator: true,
        visible: this.props.onDeactivate || this.props.onActivate || this.props.onDelete,
      },
      {
        label: (<Button tooltip={$.strings.logFileTooltip} className={styles.menuItem}          >
          <FaRegClock />
          <span style={{ width: "100%", textAlign: "center" }}>
            {$.strings.logFile}
          </span>
        </Button>
        ),
        command: this.props.onLogFile,
        visible: !this.props.onLogFile ? false : true,
      },
    ];

    // add dynamic buttons from any pages
    if (this.props.itemsList && this.props.itemsList.length > 0) {
      items.push({ separator: true, visible: true });
      let tempItems = this.props.itemsList;

      tempItems.map((element) => {
        items.push({
          label: (
            <Button
              label={element.label}
              tooltip={element.tooltip}
              className={styles.menuItem}
            />
          ),
          command: element.command,
          visible: element.visible,
        });
      });
    }
    return items;
  };

  splitButtonItems = () => {
    let items = [
      {
        label: $.strings.edit,
        icon: "fa fa-pencil",
        command: !this.props.permissions.edit.disabled ? this.props.onEdit : "",
        visible: this.props.permissions.edit.visible,
        disabled: this.props.permissions.edit.disabled,
      },
      {
        label: $.strings.print,
        icon: "fa fa-print",
        command: !this.props.permissions.print.disabled
          ? this.props.onPrint
          : "",
        visible: this.props.permissions.print.visible,
        disabled: this.props.permissions.print.disabled,
      },
      {
        label: $.strings.excelExport,
        icon: "fa fa-file-excel",
        command: !this.props.permissions.excelExport.disabled
          ? this.props.onSave
          : "",
        visible: this.props.permissions.excelExport.visible,
        disabled: this.props.permissions.excelExport.disabled,
      },
      {
        label: $.strings.refresh,
        icon: "fa fa-refresh",
        command: !this.props.permissions.refresh.disabled
          ? this.props.onRefresh
          : "",
        visible: this.props.permissions.refresh.visible,
        disabled: this.props.permissions.refresh.disabled,
      },
      {
        label: $.strings.previous,
        icon:
          $.strings.dir === "rtl" ? "fa fa-angle-right" : "fa fa-angle-left",
        command: !this.props.permissions.previous.disabled
          ? this.props.onPrevious
          : "",
        visible: this.props.permissions.previous.visible,
        disabled: this.props.permissions.previous.disabled,
      },
      {
        label: $.strings.save,
        icon: "fa fa-save",
        command: !this.props.permissions.saveWithoutClose.disabled
          ? this.props.onSave
          : "",
        visible: this.props.permissions.saveWithoutClose.visible,
        disabled: this.props.permissions.saveWithoutClose.disabled,
      },
      {
        label: $.strings.clone,
        icon: "fa fa-copy",
        command: !this.props.permissions.clone.disabled
          ? this.props.onClone
          : "",
        visible: this.props.permissions.clone.visible,
        disabled: this.props.permissions.clone.disabled,
      },
      {
        label: $.strings.new,
        icon: "fa fa-plus",
        command: !this.props.permissions.new.disabled ? this.props.onNew : "",
        visible: this.props.permissions.new.visible,
        disabled: this.props.permissions.new.disabled,
      },
      {
        label: $.strings.next,
        icon:
          $.strings.dir === "rtl" ? "fa fa-angle-left" : "fa fa-angle-right",
        command: !this.props.permissions.next.disabled ? this.props.onNext : "",
        visible: this.props.permissions.next.visible,
        disabled: this.props.permissions.next.disabled,
      },
      {
        label: $.strings.delete,
        icon: "fa fa-trash-alt",
        className: styles.delete,
        command: !this.props.permissions.delete.disabled
          ? this.props.onDelete
          : "",
        visible: this.props.permissions.delete.visible,
        disabled: this.props.permissions.delete.disabled,
      },
      {
        label: $.strings.saveAndClose,
        icon: "fa fa-check",
        command: !this.props.permissions.save.disabled
          ? this.props.onSaveThenClose
          : "",
        visible: this.props.permissions.save.visible,
        disabled: this.props.permissions.save.disabled,
      },
      {
        label: $.strings.close,
        icon: "fa fa-times",
        command: this.props.onClose,
        className: styles.delete,
        visible: true,
      },
    ];
    return items;
  };

  setFocus = (buttonName) => {
    switch (buttonName.toLowerCase()) {
      case "save": {
        this.btnSave.focus();
        document.getElementById("btnSave").focus();
        break;
      }
      default: {
        break;
      }
    }
  };
}

ButtonsBarLookups.defaultProps = {
  permissions: {
    saveWithoutClose: {
      visible: true,
      disabled: false,
    },
    new: {
      visible: true,
      disabled: false,
    },
    delete: {
      visible: true,
      disabled: false,
    },
    previous: {
      visible: true,
      disabled: false,
    },
    edit: {
      visible: true,
      disabled: false,
    },
    print: {
      visible: true,
      disabled: false,
    },
    excelExport: {
      visible: true,
      disabled: false,
    },
    refresh: {
      visible: true,
      disabled: false,
    },
    next: {
      visible: true,
      disabled: false,
    },
    save: {
      visible: true,
      disabled: false,
    },
    first: {
      visible: true,
      disabled: false,
    },
    last: {
      visible: true,
      disabled: false,
    },
    clone: {
      visible: true,
      disabled: false,
    },
    deactivate: {
      visible: true,
      disabled: false,
    },
    activate: {
      visible: true,
      disabled: false,
    },
  },
};
