import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util';
import DataGrid from '../../Components/DataGridView/DataGridView';
import ButtonsBarLookupList from "../../Components/ButtonsBarLookupList";
import Card from "../../Components/Card";
import Dropdown from "../../Components/Dropdown";
import MultiSelect from "../../Components/MultiSelect";
import Calendar from "../../Components/Calendar";
import InputTime from "../../Components/InputTime";
import { Accordion, AccordionTab } from "primereact/accordion";
import SiteMap from "../../Components/SiteMap";
import Print from "../../Print";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class ReportsArchiving extends React.Component {

    constructor(props) {
        super(props);
        this.nowDate = new Date();
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            pageInfo: this.setPageInfo(),
            reportsList: [],
            usersList: [],
            data: [],
            dataObject: {
                reports: [],
                users: [],
                fromDate: new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), 1),
                toDate: this.nowDate,
                fromTime: '00:00',
                toTime: '00:00'
            }
        }
    }
    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250

        let reportsList = await this.getReportsList();
        let usersList = await this.getUsersList();
        this.setState({ reportsList: reportsList, usersList: usersList, isDesktop: _isDesktop, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    handleMultiSelect = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const gridStyle = { minHeight: '40vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div style={{ direction: $.strings.dir }}>
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    <ButtonsBarLookupList onRefresh={e => this.onRefresh(false)} onExcelExport={this.excelExport} onPrint={this.print} />
                    <Messages innerRef={(el) => this.messages = el} />
                </Card>
                <div ref="divMain" >
                    <Accordion activeIndex={0}>
                        <AccordionTab header={$.strings.filterCaption}>
                            <div className="p-grid">
                                <div className="p-col-6 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <MultiSelect
                                        id="reports"
                                        value={this.state.dataObject.reports}
                                        options={this.state.reportsList}
                                        caption={$.strings.reportsArchiving.transOn}
                                        onChange={(e) => this.handleMultiSelect(e)}
                                        showSelectAll={false}
                                        optionLabel="Name"
                                        display="chip"
                                        maxSelectedLabels="2"
                                        filter={true}
                                    />
                                </div>
                                <div className="p-col-6 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <MultiSelect
                                        id="users"
                                        value={this.state.dataObject.users}
                                        options={this.state.usersList}
                                        caption={$.strings.reportsArchiving.userName}
                                        onChange={(e) => this.handleMultiSelect(e)}
                                        showSelectAll={false}
                                        optionLabel="Name"
                                        display="chip"
                                        maxSelectedLabels="2"
                                        filter={true}
                                    />
                                </div>
                                <div className="p-col-6 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <Calendar
                                        id="fromDate"
                                        value={this.state.dataObject.fromDate}
                                        caption={$.strings.reportsArchiving.fromDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="p-col-6 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <Calendar
                                        id="toDate"
                                        value={this.state.dataObject.toDate}
                                        caption={$.strings.reportsArchiving.toDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="p-col-6 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <InputTime
                                        id="fromTime"
                                        value={this.state.dataObject.fromTime}
                                        caption={$.strings.reportsArchiving.fromTime}
                                        valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, fromTime: e.value } })}
                                    />
                                </div>
                                <div className="p-col-6 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                    <InputTime
                                        id="toTime"
                                        value={this.state.dataObject.toTime}
                                        caption={$.strings.reportsArchiving.toTime}
                                        valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, toTime: e.value } })}
                                    />
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <DataGrid
                                    style={gridStyle}
                                    ref={e => this.gridData = e}
                                    isReport={true}
                                    theme="default-light"
                                    idProperty="RowNumber"
                                    exportFileName={$.strings.reportsArchiving.title}
                                    scheme={this.getScheme()}
                                    dataSource={this.state.data}
                                />
                            </div>
                        </div>
                    </Card>
                </div >
            </div>
        );
    }

    getReportsList = async () => {
        let data = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.Reports.urlGetReportList, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                data = res.data.list;
            }
        }

        return data;
    }
    getUsersList = async () => {
        let data = []
        let obj = {
            userId: -1
        }
        let res = await http.getJsonAxios(http.actions.Users.urlGetUsers, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                data = res.data.list;
            }
        }
        return data;
    }

    onRefresh = async (fromLoad) => {
        if (!this.state.dataObject.fromDate) {
            Util.showErrorMsg(this.messages, $.strings.reportsArchiving.fromDateError)
            return;
        }
        if (!this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.reportsArchiving.toDateError)
            return;
        }
        if (this.state.dataObject.fromDate > this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.reportsArchiving.dateError)
            return;
        }

        if (!fromLoad) {
            this.setState({ loading: true });
        }
        let data = []
        let reportList = []
        if (this.state.dataObject.reports) {
            for (let i = 0; i < this.state.dataObject.reports.length; i++) {
                reportList.push(this.state.dataObject.reports[i].ID);

            }
        }
        let usersList = [];
        if (this.state.dataObject.users) {
            for (let i = 0; i < this.state.dataObject.users.length; i++) {
                usersList.push(this.state.dataObject.users[i].ID);

            }
        }
        let obj = {
            ReportList: reportList,
            UserId: localStorage.getItem("useridHR"),
            UsersList: usersList,
            FromDate: this.state.dataObject.fromDate,
            ToDate: this.state.dataObject.toDate,
            FromTime: this.state.dataObject.fromTime,
            ToTime: this.state.dataObject.toTime
        }
        let res = await http.postMultipartAxios(http.actions.Reports.urlGetRepLogFile, obj, null, null, 0);
        if (res.status === 200) {
            if (res.data && res.data.success)
                data = res.data.list;
            if (data) {
                data.forEach(element => {
                    element.RepDate = new Date(element.RepDate);
                });
            }
        }
        if (!fromLoad) {
            this.setState({ data: data, loading: false }, () => this.printFilters = this.state.dataObject);
        }
        else {
            return data;
        }
    }

    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject(this.state.pageInfo.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let dateFilter = "";

            if (this.printFilters.reports && this.printFilters.reports.length === 1) {
                let reportsFilter = "";
                reportsFilter += " " + $.strings.reportsArchiving.transOn + ": ";
                for (let index = 0; index < this.printFilters.reports.length; index++) {
                    let name = this.printFilters.reports[index].Name;
                    if (index > 0)
                        reportsFilter += ", ";
                    reportsFilter += " " + name;
                }
                if (reportsFilter !== "")
                    listOfFilters.push(reportsFilter);
            }

            if (this.printFilters.users && this.printFilters.users.length === 1) {
                let usersFilter = "";
                usersFilter += " " + $.strings.reportsArchiving.userName + ": ";
                for (let index = 0; index < this.printFilters.users.length; index++) {
                    let name = this.printFilters.users[index].Name;
                    if (index > 0)
                        usersFilter += ", ";
                    usersFilter += " " + name;
                }
                if (usersFilter !== "")
                    listOfFilters.push(usersFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.reportsArchiving.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.reportsArchiving.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            // Time Filter
            if (this.printFilters.fromTime) {
                dateFilter = $.strings.reportsArchiving.fromTime + ": " + this.printFilters.fromTime;
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.reportsArchiving.fromTime + ": ";
                dateFilter += this.printFilters.toTime;
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)
        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    getScheme = () => {
        return {
            name: "reportsArchivingScheme_Table",
            filter: false,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [

                { name: 'RowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                {
                    name: 'ReportId', header: 'ReportId', width: 130, visible: false, visibleInColumnChooser: false
                },
                { name: 'UserId', header: 'UserId', width: 120, visible: false, visibleInColumnChooser: false },
                { name: 'ReportListName', header: $.strings.reportsArchiving.report, width: "*", minWidth: 120, columnWidth: '*' },
                {
                    name: 'ReportListEName', header: $.strings.reportsArchiving.notes, width: "*", minWidth: 120,
                    visible: false, visibleInColumnChooser: false, columnWidth: '*'
                },
                { name: 'UserName', header: $.strings.reportsArchiving.userName, width: "*", minWidth: 120, columnWidth: '*' },
                { name: 'RepDate', header: $.strings.reportsArchiving.date, width: 100, columnWidth: '3', dataType: 'Date', format: Util.gridDateFormat(), },
                { name: 'RepTime', header: $.strings.reportsArchiving.time, width: 100, columnWidth: '3' },
                { name: 'Note', header: $.strings.reportsArchiving.notes, width: "*", minWidth: 150, columnWidth: '3' },
                { name: 'FromCode', header: $.strings.reportsArchiving.fromCode, width: 120, columnWidth: '2' },
                { name: 'ToCode', header: $.strings.reportsArchiving.toCode, width: 110, columnWidth: '2' },
                { name: 'KeyName', header: $.strings.reportsArchiving.name, width: 150, columnWidth: '2' },
                { name: 'RecordCount', header: $.strings.reportsArchiving.recordsCount, width: 100, columnWidth: '2' },
                { name: 'Prog', header: $.strings.reportsArchiving.system, width: 120, columnWidth: '2' }
            ]
        }
    }
    setPageInfo = () => {
        let pageId
        let title = $.strings.reportsArchiving.title

        return { pageId: pageId, title: title };
    };
}