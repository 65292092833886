import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util';
import DataGrid from '../../Components/DataGridView/DataGridView';
import ButtonsBarLookupList from "../../Components/ButtonsBarLookupList";
import Card from "../../Components/Card";
import MultiSelect from "../../Components/MultiSelect";
import Calendar from "../../Components/Calendar";
import { Accordion, AccordionTab } from "primereact/accordion";
import SiteMap from "../../Components/SiteMap";
import Checkbox from "../../Components/Checkbox";
import AdditionalFilters from "../AdditionalFilters/AdditionalFilters";
import EquationDialog from "./EquationDialog";
import Dropdown from "../../Components/Dropdown";
import Print from "../../Print";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class EmployeeTimeCommitRpt extends React.Component {

    constructor(props) {
        super(props);
        this.nowDate = new Date();
        this.reportsList = [
            { id: 189, name: $.strings.employeeTimeCommitRpt.percentOfWorkCommitment },
            { id: 190, name: $.strings.employeeTimeCommitRpt.percentOfEmployeeTime }
        ]
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            pageInfo: this.setPageInfo(),
            sectionsList: [],
            employeesList: [],
            data: [],
            dataObject: {
                employees: [],
                section: [],
                fromDate: new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), 1),
                toDate: this.nowDate,
                considerAllowedPeriods: true,
                report: this.reportsList[0],
                additionalFiltersObj: undefined
            }
        }
    }
    componentDidMount = async () => {
        if (!Util.checkISHR() || !Util.checkRights(540)) {
            Util.goTo('/Access');
        }
        let _isDesktop = window.innerWidth > 1250

        let sectionsList = await this.getSections();
        let employeesList = await this.getEmployeesList();
        this.setState({ sectionsList: sectionsList, employeesList: employeesList, isDesktop: _isDesktop, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    handleMultiSelect = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    render() {

        const gridStyle = { minHeight: '40vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div style={{ direction: $.strings.dir }}>
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    <ButtonsBarLookupList onRefresh={e => this.onRefresh(false)} onExcelExport={this.excelExport} onPrint={this.print} />
                    <Messages innerRef={(el) => this.messages = el} />
                    <AdditionalFilters ref={e => this.dlgAdditionalFilters = e} onSuccess={this.onAdditionalFiltersSuccess} />
                </Card>
                <div ref="divMain" >
                    <Accordion activeIndex={0}>
                        <AccordionTab header={$.strings.filterCaption}>
                            <div className="p-grid">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                        <Dropdown
                                            id="report"
                                            value={this.state.dataObject.report}
                                            options={this.reportsList}
                                            caption={$.strings.employeeTimeCommitRpt.report}
                                            onChange={this.handleUserInput}
                                            optionLabel="name"
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                        <Calendar
                                            id="fromDate"
                                            innerRef={(el) => this.dtpFromDate = el}
                                            value={this.state.dataObject.fromDate}
                                            caption={$.strings.employeeTimeCommitRpt.fromDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                        <Calendar
                                            id="toDate"
                                            innerRef={(el) => this.dtpFromDate = el}
                                            value={this.state.dataObject.toDate}
                                            caption={$.strings.employeeTimeCommitRpt.toDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                        <MultiSelect
                                            id="section"
                                            innerRef={e => this.drpSection = e}
                                            value={this.state.dataObject.section}
                                            options={this.state.sectionsList}
                                            caption={$.strings.employeeTimeCommitRpt.section}
                                            onChange={(e) => this.handleUserInput(e)}
                                            optionLabel="Name"
                                            display="chip"
                                            maxSelectedLabels="2"
                                            showSelectAll={false}
                                            filter={true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                        <MultiSelect
                                            id="employees"
                                            value={this.state.dataObject.employees}
                                            options={this.state.employeesList}
                                            caption={$.strings.employeeTimeCommitRpt.employee}
                                            onChange={this.handleMultiSelect}
                                            optionLabel="name"
                                            display="chip"
                                            maxSelectedLabels="2"
                                            showSelectAll={false}
                                            filter
                                        />
                                    </div>
                                    <div className="p-col-6 p-xl-3 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                        <Checkbox id="considerAllowedPeriods" caption={$.strings.employeeTimeCommitRpt.considerAllowedPeriods}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, considerAllowedPeriods: e.checked } })}
                                            checked={this.state.dataObject.considerAllowedPeriods} />
                                    </div>
                                    <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                        <Button label={$.strings.additionalFilters.title} className={this.state.dataObject.additionalFiltersObj ? 'p-button-warning' : ''}
                                            onClick={e => this.dlgAdditionalFilters.show(this.state.dataObject.additionalFiltersObj)} />
                                        <Button label="" icon="pi pi-filter-slash" visible={this.state.dataObject.additionalFiltersObj} tooltip={$.strings.additionalFilters.clearFilters}
                                            onClick={e => this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: undefined } })}
                                        />
                                    </div>
                                    {
                                        Util.checkRights(541) &&
                                        <div className="p-col-6 p-xl-2 p-lg-2 p-md-4 p-sm-6" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                            <Button label={$.strings.employeeTimeCommitRpt.equationDialog} className="p-button-info"
                                                onClick={e => this.setState({ visibleEquationDialog: true })}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <DataGrid
                                    style={gridStyle}
                                    ref={e => this.gridData = e}
                                    isReport={true}
                                    theme="default-light"
                                    idProperty="RowNumber"
                                    exportFileName={$.strings.employeeTimeCommitRpt.title}
                                    scheme={this.getScheme()}
                                    dataSource={this.state.data}
                                />
                            </div>
                        </div>
                    </Card>
                </div >
                {
                    this.state.visibleEquationDialog && Util.checkRights(541) &&
                    <EquationDialog
                        equationId={this.state.dataObject.report.id}
                        onSuccess={e => this.setState({ visibleEquationDialog: false })}
                        onClose={e => this.setState({ visibleEquationDialog: false })}
                    />
                }
            </div>
        );
    }

    onAdditionalFiltersSuccess = (obj) => {
        if (obj) {
            this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: obj } })
        }
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.splice(0, 1);
            }
        }
        return dataTemp
    }
    getEmployeesList = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: !this.state.empCodeOrName || this.state.empCodeOrName.length <= 0 ? '' : this.state.empCodeOrName,
            isHr: Util.checkISHR(),
            getEmpStatus: this.state.getEmpStatus ? this.state.getEmpStatus : false,
            section: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            empStatus: this.state.empStatus && this.state.empStatus.id >= 0 ? this.state.empStatus.id : -1,
        }

        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;

        }
        return dataTemp
    }

    onRefresh = async (fromLoad) => {
        if (!this.state.dataObject.fromDate) {
            Util.showErrorMsg(this.messages, $.strings.employeeTimeCommitRpt.fromDateError)
            return;
        }
        if (!this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.employeeTimeCommitRpt.toDateError)
            return;
        }
        if (this.state.dataObject.fromDate > this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.employeeTimeCommitRpt.dateError)
            return;
        }

        if (!fromLoad) {
            this.setState({ loading: true });
        }
        let generalFillter = {}
        if (this.state.dataObject.section) {
            let sectionsList = []
            for (let index = 0; index < this.state.dataObject.section.length; index++) {
                sectionsList.push(this.state.dataObject.section[index].Code)
            }
            generalFillter.SectionList = sectionsList;
        }
        if (this.state.dataObject.employees) {
            let empList = [];
            for (let index = 0; index < this.state.dataObject.employees.length; index++) {
                empList.push(this.state.dataObject.employees[index].code)
            }
            generalFillter.EmpList = empList;
        }
        Util.fillAditionalsFilters(this.state.dataObject.additionalFiltersObj, generalFillter);

        let data = []
        let obj = {
            UserId: localStorage.getItem("useridHR"),
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            chkTakeAllowancePeriod: this.state.dataObject.considerAllowedPeriods,
            reportTypeSettingId: this.state.dataObject.report.id,
            generalFillter: generalFillter
        }

        let res = await http.postMultipartAxios(http.actions.Reports.urlGetEmployeeWorkHoursPercent, obj, null, null, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                data = res.data.list;
            }
        }
        if (!fromLoad) {
            this.setState({ data: data, loading: false }, () => this.printFilters = this.state.dataObject);
        }
        else {
            return data;
        }
    }

    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject(this.printFilters.report.name, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let dateFilter = "";

            if (this.printFilters.report) {
                listOfFilters.push($.strings.employeeTimeCommitRpt.report + ": " + this.printFilters.report.name);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.employeeTimeCommitRpt.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.employeeTimeCommitRpt.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.section && this.printFilters.section.length === 1) {
                let sectionFilter = "";
                sectionFilter += " " + $.strings.employeeTimeCommitRpt.section + ": ";
                for (let index = 0; index < this.printFilters.section.length; index++) {
                    let name = this.printFilters.section[index].Name;
                    if (index > 0)
                        sectionFilter += ", ";
                    sectionFilter += " " + name;
                }
                if (sectionFilter !== "")
                    listOfFilters.push(sectionFilter);
            }

            if (this.printFilters.employees && this.printFilters.employees.length === 1) {
                let employeesFilter = "";
                employeesFilter += " " + $.strings.employeeTimeCommitRpt.employee + ": ";
                for (let index = 0; index < this.printFilters.employees.length; index++) {
                    let name = this.printFilters.employees[index].name;
                    if (index > 0)
                        employeesFilter += ", ";
                    employeesFilter += " " + name;
                }
                if (employeesFilter !== "")
                    listOfFilters.push(employeesFilter);
            }

            if (this.printFilters.considerAllowedPeriods) {
                listOfFilters.push($.strings.employeeTimeCommitRpt.considerAllowedPeriods + ": " + (this.printFilters.considerAllowedPeriods ? $.strings.yes : $.strings.no))
            }


        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    getScheme = () => {
        return {
            name: "employeeTimeCommitRptScheme_Table",
            filter: false,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [

                { name: 'RowNumber', header: '##', width: 100, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'EmpCode', header: $.strings.employeeTimeCommitRpt.empCode, width: 150, columnWidth: '3' },
                { name: 'EmpName', header: $.strings.employeeTimeCommitRpt.empName, width: "*", minWidth: 120, columnWidth: '*' },
                { name: 'Percent', header: $.strings.employeeTimeCommitRpt.percent, width: 150, columnWidth: '3' }
            ]
        }
    }
    setPageInfo = () => {
        let pageId
        let title = $.strings.employeeTimeCommitRpt.title

        return { pageId: pageId, title: title };
    };
}