import React from "react";
import Button from '../../../Components/Button'
import Messages from '../../../Components/Messages'
import http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import Dialog from '../../../Components/Dialog';
import Util from '../../../Util'
import Input from "../../../Components/Input";
import Dropdown from "../../../Components/Dropdown";
import { Fieldset } from "primereact/fieldset";
import InputTime from "../../../Components/InputTime";
import DataGridView from "../../../Components/DataGridView/DataGridView";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class PeriodsSearch extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            data: [],
            classificationsList: [],
            dataObject: {
                classification: undefined,
                fromTime: this.nowDate,
                toTime: this.nowDate,
            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;

        this.setState({ classificationsList: [], isDesktop: _isDesktop, loading: false })
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    handleFromTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, fromTime: e.value } })
    }
    handleToTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, toTime: e.value } })
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.ok} icon="" className="p-button-success" onClick={this.onAccept}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );
        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        const gridStyle = { minHeight: '40vh' }

        return (
            <div>
                <Dialog header={$.strings.periods.titleSearch} footer={!this.state.isViewOnly ? footer : undefined}
                    icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '40vw' : '80vw', direction: $.strings.dir }}
                    modal onHide={this.onHide}>
                    <ProgressSpinner loading={this.state.loading} />
                    <div className="p-grid" style={{ width: '100%' }}>
                        <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                            <Messages innerRef={(el) => this.messages = el} />
                        </div>
                    </div>
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-3 p-sm-12">
                                <Dropdown
                                    id="classification"
                                    innerRef={e => this.drpClassification = e}
                                    value={this.state.dataObject.classification}
                                    options={this.state.classificationsList}
                                    caption={$.strings.periods.classification}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            {
                                this.state.isDesktop && <div className="p-col-12 p-lg-2 p-sm-12"></div>
                            }
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <InputTime
                                    id="fromTime"
                                    ref={e => this.dtpFromTime = e}
                                    value={this.state.dataObject.fromTime}
                                    caption={$.strings.periods.from}
                                    valueChanged={this.handleFromTime}
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <InputTime
                                    id="toTime"
                                    ref={e => this.dtpToTime = e}
                                    value={this.state.dataObject.toTime}
                                    caption={$.strings.periods.to}
                                    valueChanged={this.handleToTime}
                                />
                            </div>
                            <div className="p-col-1 p-lg-1 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Button icon="pi pi-search" onClick={e => this.getData(false)} />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <DataGridView
                                    ref={e => this.gridData = e}
                                    style={gridStyle}
                                    isReport={true}
                                    showContextMenu={false}
                                    theme="default-light"
                                    idProperty="id"
                                    scheme={this.getScheme()}
                                    defaultRowHeight={45}
                                    dataSource={this.state.data}
                                />
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div>
        );
    }
    show = async () => {
        let obj = undefined;
        let classList = await this.getClassifications();
        let data = await this.getData(true)
        this.setState({
            visibleDialog: true, formErrors: {}, loading: false, data: data,
            dataObject: {
                ...this.state.dataObject,
                classification: classList && classList.length > 0 ? classList[0] : undefined,
                fromTime: obj ? obj.fromTime : '00:00',
                toTime: obj ? obj.toTime : '00:00',
            }, classificationsList: classList || []
        });
    }

    onHide = () => {
        this.setState({ data: [], visibleDialog: false, formErrors: {} })
    }

    getData = async (fromLoad) => {
        if (!fromLoad)
            this.setState({ loading: true });
        let dataTemp = [];
        let obj = {
            classPos: this.state.dataObject.classification && this.state.dataObject.classification.pos >= 0 ? this.state.dataObject.classification.pos : "",
            fromTime: this.state.dataObject.fromTime ? this.state.dataObject.fromTime : '',
            totime: this.state.dataObject.toTime ? this.state.dataObject.toTime : '',
            periodPos: ""
        }

        let res = await http.getJsonAxios(http.actions.Periods.urlGetPeriodsList, obj, 0);
        if (res.status === 200) {
            if (res.data)
                dataTemp = res.data.list;
        }
        if (!fromLoad)
            this.setState({ data: dataTemp, loading: false })
        else
            return dataTemp
    }

    getClassifications = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 30
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp;
    }

    onAccept = () => {
        let selectedPeriod = this.gridData.getCurrentRowItem();
        if (selectedPeriod) {
            if (this.props.onSuccess)
                this.props.onSuccess(selectedPeriod)
            this.setState({ visibleDialog: false })
        }
        else {
            Util.showErrorMsg(this.messages, $.strings.selectRow)
        }
    }

    buttonSelect = (e, ctx) => {
        if (ctx.item) {
            if (this.props.onSuccess)
                this.props.onSuccess(ctx.item)
            this.setState({ visibleDialog: false })
        }
        else {
            Util.showErrorMsg(this.messages, $.strings.selectRow)
        }
    }
    getScheme = () => {
        return {
            name: "clockTransReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                { name: 'rowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt' },
                { name: 'code', header: $.strings.periods.code, width: 100, visible: false, },
                {
                    name: 'buttonView', header: ' ', width: 50, buttonBody: 'button', align: 'center',
                    title: $.strings.view, iconType: 'search', className: 'info', onClick: (e, ctx) => this.buttonSelect(e, ctx),
                    visible: Util.checkISHR(), visibleInColumnChooser: Util.checkISHR()
                },
                { name: 'classification', header: $.strings.periods.classification, width: this.state.isDesktop ? 200 : "*", visible: false },
                {
                    name: 'fromTime', header: $.strings.periods.from, width: 100,
                },
                {
                    name: 'toTime', header: $.strings.periods.to, width: 100,
                },
                {
                    name: 'fromRest1', header: $.strings.periods.from, width: 100,
                },
                {
                    name: 'toRest1', header: $.strings.periods.to, width: 100,
                },
                {
                    name: 'period', header: $.strings.periods.period, width: 100
                },
                {
                    name: 'restPeriod', header: $.strings.periods.rest, width: 100,
                }
            ]
        }
    }

}