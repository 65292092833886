import React from "react";
import Input from "../../Components/Password";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class ChangePwd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { oldPwd: '', newPwd: '', confirmPwd: '' },
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            empCode: localStorage.getItem("useridHR"),
            oldPwd: '',
            newPwd: '',
            confirmPwd: ''
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _oldPwd = this.state.oldPwd;
        fieldValidationErrors.oldPwd = _oldPwd ? '' : $.strings.requiredFiled;

        let _newPwd = this.state.newPwd;
        fieldValidationErrors.newPwd = _newPwd ? '' : $.strings.requiredFiled;

        let _confirmPwd = this.state.confirmPwd;
        fieldValidationErrors.confirmPwd = _confirmPwd ? '' : $.strings.requiredFiled;

        if (_confirmPwd + "" !== _newPwd + "") {
            _confirmPwd = undefined
            fieldValidationErrors.confirmPwd = $.strings.confirmPwdError
        }
        this.setState({
            formErrors: fieldValidationErrors,
            oldPwdValid: _oldPwd,
            newPwdValid: _newPwd,
            confirmPwdValid: _confirmPwd,
        }, this.validateForm);

        return _oldPwd && _newPwd && _confirmPwd;
    }
    validateForm() {
        let isFormValid = this.state.oldPwdValid && this.state.newPwdValid && this.state.confirmPwdValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.save} icon="" className="p-button-success" onClick={this.onSave}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={$.strings.changePassword} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '20vw' : '80vw', direction: $.strings.dir }}
                    modal onHide={this.onHide}>
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtOldPwd = el}
                                    type="text"
                                    id="oldPwd"
                                    inputId="oldPwd"
                                    maxLength="16"
                                    autofocus={true}
                                    value={this.state.oldPwd || ''}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.oldPwd}
                                    isrequired={true}
                                    formErrors={this.state.formErrors}
                                    feedback={false}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtNewPwd = el}
                                    type="text"
                                    id="newPwd"
                                    inputId="newPwd"
                                    maxLength="16"
                                    value={this.state.newPwd || ''}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.newPwd}
                                    isrequired={true}
                                    formErrors={this.state.formErrors}
                                    feedback={false}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtConfirmPwd = el}
                                    type="text"
                                    id="confirmPwd"
                                    inputId="confirmPwd"
                                    maxLength="16"
                                    value={this.state.confirmPwd || ''}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.confirmNewPwd}
                                    isrequired={true}
                                    formErrors={this.state.formErrors}
                                    feedback={false}
                                />
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div>
        );
    }
    show = () => {

        this.setState({
            visibleDialog: true, oldPwd: '', newPwd: '', confirmPwd: '',
            formErrors: { oldPwd: '', newPwd: '', confirmPwd: '' },
        }, () => setTimeout(() => { this.txtOldPwd.container.firstChild.focus() }, 10))
    }

    onHide = () => {
        this.setState({ oldPwd: '', newPwd: '', confirmPwd: '', visibleDialog: false, formErrors: { oldPwd: '', newPwd: '', confirmPwd: '' } })
    }

    onSave = async () => {
        if (Util.checkISHR()) {
            await this.changePasswordHR();
        }
        else {
            await this.changePassword();
        }
    }

    changePassword = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.setState({ loading: true })

        let obj = {
            empCode: localStorage.getItem("useridHR"),
            oldPwd: this.state.oldPwd,
            newPwd: this.state.newPwd
        }

        let saveResult
        let res = await http.getJsonAxios(http.actions.Employee.urlPostChangeEmployeePassword, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    alert(res.data.errorText);
                else
                    alert($.strings.operationDone);
                this.setState({ loading: false, visibleDialog: false })
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return saveResult = false
        }
    }

    changePasswordHR = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.setState({ loading: true })

        let obj = {
            userId: localStorage.getItem("useridHR"),
            oldPassword: this.state.oldPwd,
            newPassword: Util.encryptStringToHexa(this.state.newPwd),
            confirmPassword: Util.encryptStringToHexa(this.state.confirmPwd)
        }

        let saveResult
        let res = await http.postMultipartAxios(http.actions.Employee.urlPostChangePasswordHR, obj, null, null, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    alert(res.data.errorText);
                else
                    alert($.strings.operationDone);
                this.setState({ loading: false, visibleDialog: false })
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return saveResult = false
        }
    }
}