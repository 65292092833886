import React from "react";
import styles from './ImagePicker.module.scss';
import Label from "../Label";
import itemLogo from '../../itemImage.png'
import Input from '../Input';
import Button from '../Button';
import ImageViewer from './ImageViewer';

const $ = window.$;

export default class ImagePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = async () => {

    }

    render() {

        return (
            <div style={{ width: '100vw' }}>
                {
                    this.props.caption && <Label htmlFor={this.props.htmlFor} isRequired={this.props.isRequired}>
                        {this.props.caption}
                    </Label>
                }
                <div style={{ width: '100vw' }}>
                    <img alt={this.props.alt} src={this.setImageSource()} title={this.props.title} className={styles.imgCss} />
                    <Button tooltip={$.strings.systemSettings.uploadImageTooltip} onClick={this.onUploadImage} >
                        <i className="pi pi-file" />
                        <Input id={"file-upload-" + this.props.id} type="file" accept="image/*" onChange={e => this.props.onSelectImage(this.props.id, e)} />
                    </Button>
                    <Button tooltip={$.strings.systemSettings.viewImageTooltip} onClick={this.viewImage}>
                        <i className="pi pi-images" />
                    </Button>
                    {
                        this.props.onRemoveImage &&

                        <Button className="p-button-danger" tooltip={$.strings.systemSettings.clearImageTooltip} onClick={e => this.props.onRemoveImage(this.props.id)}>
                            <i className="pi pi-trash" />
                        </Button>
                    }
                </div>
                <ImageViewer ref={e => this.dlgImageViewer = e} />
            </div>
        );
    }

    onUploadImage = () => {
        document.getElementById('file-upload-' + this.props.id).click();
    }

    viewImage = () => {
        let img = undefined;
        if (this.props.sourceBytes) {
            img = `data:image/png;base64,${this.props.sourceBytes}`;
        }
        else if (this.props.source)
            img = this.props.source;
        else
            img = itemLogo
        this.dlgImageViewer.show(img);
    }

    setImageSource = () => {
        if (this.props.sourceBytes) {
            return `data:image/png;base64,${this.props.sourceBytes}`;
        }
        else if (this.props.source) {
            return this.props.source;
        }
        else
            return itemLogo
    }
}