import React from "react";
import { withRouter } from "react-router-dom";
import Print from "./Print";

class ReportsPrinting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        console.log("start ReportsPrinting");
        console.log("call printReportDynamic");
        this.printReportDynamic();
        console.log("end ReportsPrinting");
    }

    render() {
        return (
            <div id="viewer">
            </div>
        )
    }

    printReportDynamic = () => {
        console.log("call printReportByDataBand");
        Print.printReportByDataBand(false);
        console.log("end printReportByDataBand");
    }

}

export default withRouter(ReportsPrinting);