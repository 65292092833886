import React from "react";
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import SiteMap from '../../Components/SiteMap';
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import ScrollTop from '../ScrollButton';
import WFTrans from '../WFTrans';
import Attachments from '../Attachments';
import Calendar from '../../Components/Calendar';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class EmpDelegations extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.statusList = Util.getDelegationStatus()
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { employee: '', delegatedEmployee: '', fromDate: '', toDate: '' },
            deleteErrors: { deleteNote: '' },
            isDesktop: window.innerWidth > 1250,
            dataObject: {
                id: 0,
                insDate: new Date(),
                employee: undefined,
                delegatedEmployee: undefined,
                fromDate: new Date(),
                toDate: new Date(),
                status: this.statusList[0]
            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(661)) { // تفويض الموظفين
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = this.updatePredicate();
        let empListPromise = this.getEmployeeList();

        let employeesList = await empListPromise;

        let empCode = this.props.match.params.empCode || localStorage.getItem("useridHR") + "";
        let employee = employeesList.filter(emp => emp.Code + "" === empCode + "");
        if (employee && employee.length > 0) {
            employee = employee[0]
        }
        this.setState({ dataObject: { ...this.state.dataObject, employee: employee }, employeesList: employeesList, isDesktop: _isDesktop, loading: false })

        if (this.props.match.params.id) {
            let data = await this.showDelegationInfo(this.props.match.params.id)
            this.loadData(data)
        }
        setTimeout(() => { this.drpDelegatedEmployee.focusInput.focus() }, 10);
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _employee = this.state.dataObject.employee
        fieldValidationErrors.employee = _employee ? '' : $.strings.requiredFiled;

        let _delegatedEmployee = this.state.dataObject.delegatedEmployee;
        fieldValidationErrors.delegatedEmployee = _delegatedEmployee ? '' : $.strings.requiredFiled;

        let _fromDate = this.state.dataObject.fromDate;
        fieldValidationErrors.fromDate = _fromDate ? '' : $.strings.requiredFiled;

        let _toDate = this.state.dataObject.toDate;
        fieldValidationErrors.toDate = _toDate ? '' : $.strings.requiredFiled;

        if (_employee === _delegatedEmployee) {
            fieldValidationErrors.delegatedEmployee = $.strings.empDelegation.delegatedEmployeeError;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            employeeValid: _employee,
            delegatedEmployeeValid: _delegatedEmployee,
            fromDateValid: _fromDate,
            toDateValid: _toDate,
        }, this.validateForm);

        return _employee && _delegatedEmployee && _fromDate && _toDate;
    }
    validateForm() {
        let isFormValid = this.state.employeeValid && this.state.delegatedEmployeeValid && this.state.fromDateValid && this.state.toDateValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        let siteMapItems = [
            { label: $.strings.empDelegation.title, url: "../../EmpDelegationList", },
            {
                label: <div>
                    {$.strings.empDelegation.title + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/", command: e => alert($.strings.links.Files.branches) }

        return (
            <div ref="divMain">
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12">
                            <SiteMap model={siteMapItems} home={siteMapHome} />
                        </div>
                    </div>
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={this.resetFields}
                            onSave={this.onSave}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid">
                    <div className="p-lg-12 p-sm-12">
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnPrevVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onPrevious(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNextVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNext(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnFirstVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onFirst(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnLastVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onLast(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnDeleteVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onDelete(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloneVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onClone(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloseVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.btnClose(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />

                <DeleteNoteDialog ref={ref => this.deleteNoteDlg = ref} visible={this.state.dlgDeleteNoteVisible} deleteErrors={this.state.deleteErrors} appendTo={this.props.parent}
                    onYes={this.btnDeleteYes} onNo={this.DeleteCancel} deleteNote='' />
                <div className='p-grid'>
                    <div className="p-lg-6 p-sm-12"></div>
                    <div className="p-lg-6 p-sm-12">

                    </div>
                </div>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtCode = el}
                                type="text"
                                id="id"
                                keyfilter="pnum"
                                value={this.state.dataObject.id || ''}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.empDelegation.code}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Calendar
                                id="insDate"
                                innerRef={(el) => this.dtpInsDate = el}
                                value={this.state.dataObject.insDate}
                                caption={$.strings.empDelegation.insDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-5 p-sm-12">
                            <Dropdown
                                id="employee"
                                innerRef={e => this.drpEmployees = e}
                                value={this.state.dataObject.employee}
                                options={this.state.employeesList}
                                showClear={true}
                                caption={$.strings.empDelegation.employee}
                                onChange={this.handleUserInput}
                                formErrors={this.state.formErrors}
                                optionLabel="Name"
                                placeholder={$.strings.empDelegation.selectEmployee}
                                filter={true}
                                filterBy="Code,Name"
                                filterMatchMode="contains"
                                isRequired={true}
                                disabled={!Util.checkISHR()}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-5 p-sm-12">
                            <Dropdown
                                id="delegatedEmployee"
                                innerRef={e => this.drpDelegatedEmployee = e}
                                value={this.state.dataObject.delegatedEmployee}
                                options={this.state.employeesList}
                                showClear={true}
                                caption={$.strings.empDelegation.delegatedEmployee}
                                onChange={this.handleUserInput}
                                formErrors={this.state.formErrors}
                                optionLabel="Name"
                                placeholder={$.strings.empDelegation.selectEmployee}
                                filter={true}
                                filterBy="Code,Name"
                                filterMatchMode="contains"
                                isRequired={true}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-5 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.dataObject.fromDate}
                                caption={$.strings.empDelegation.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-5 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.dataObject.toDate}
                                caption={$.strings.empDelegation.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-5 p-sm-12">
                            <Dropdown
                                id="status"
                                innerRef={e => this.drpStatus = e}
                                value={this.state.dataObject.status}
                                options={this.statusList}
                                caption={$.strings.empDelegation.status}
                                onChange={this.handleUserInput}
                                formErrors={this.state.formErrors}
                                optionLabel="name"
                                placeholder={$.strings.memos.selectMemoType}
                                isRequired={true}
                            />
                        </div>
                    </div>
                </Card>
                <ScrollTop />
                <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
                <Attachments ref={e => this.dlgAttachments = e}></Attachments>
            </div >
        );
    }

    getEmployeeList = async () => {

        let dataTemp = []
        let obj = {
            directManager: "",
            isHR: Util.checkISHR()
        }
        let res = await http.getJsonAxios(http.actions.EmpDelegation.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployessObjList;
        }
        return dataTemp;

    }
    showDelegationInfo = async (id) => {
        let dataTemp = []
        let obj = {
            code: id
        }
        let res = await http.getJsonAxios(http.actions.EmpDelegation.urlGetDelegate, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }
    loadData = (data) => {
        let _isDesktop = this.updatePredicate();
        if (!data.success)
            return;

        let statusId = data.Status;
        if (statusId === 0) {
            let nowDate = new Date();
            if (nowDate > new Date(data.ToDate)) {
                statusId = 1;
            }
        }
        let Status = this.statusList.filter(element => element.id + "" === statusId + "")
        if (Status && Status.length > 0) {
            Status = Status[0]
        }


        let employee = this.state.employeesList.filter(e => e.Code === data.EmpCode);
        if (employee && employee.length > 0) {
            employee = employee[0];
        }

        let delegatedEmployee = this.state.employeesList.filter(e => e.Code === data.DelegateEmp);
        if (delegatedEmployee && delegatedEmployee.length > 0) {
            delegatedEmployee = delegatedEmployee[0];
        }

        this.setState({
            mode: 'view',
            isDesktop: _isDesktop,
            dataObject: {
                id: data.Serial,
                insDate: new Date(data.InsertDate),
                employee: employee,
                delegatedEmployee: delegatedEmployee,
                fromDate: new Date(data.FromDate),
                toDate: new Date(data.ToDate),
                status: Status
            }
            , loading: false
        })
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true })

        let obj = {
            serial: !this.state.dataObject.id ? 0 : this.state.dataObject.id,
            delegateEmp: this.state.dataObject.delegatedEmployee.Code,
            insertDate: this.state.dataObject.insDate,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            empCode: this.state.dataObject.employee.Code,
            status: this.state.dataObject.status.id
        }

        let saveResult
        let res = await http.getJsonAxios(http.actions.EmpDelegation.urlGetSaveDelegate, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return saveResult = false
        }
    }

    resetFields = () => {
        this.setState({
            dataObject: {
                ...this.state.dataObject,
                id: 0,
                insDate: new Date(),
                employee: undefined,
                delegatedEmployee: undefined,
                fromDate: new Date(),
                toDate: new Date(),
                status: this.statusList[0]
            },
            formErrors: { employee: '', delegatedEmployee: '', fromDate: '', toDate: '' },
        }, () => Util.goTo("/EmpDelegation/Delegate"))
    }
}