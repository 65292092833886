import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import ScrollTop from '../ScrollButton';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Messages from "../../Components/Messages";
import Checkbox from '../../Components/Checkbox';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import Print from "../../Print";

const $ = window.$;

export default class PersonalHolidayRpt extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDesktop: window.innerWidth > 1250,
            empCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            empName: !Util.checkISHR() ? localStorage.getItem("userNameHR") : '',
            yearVacation: '',
            otherPaidVacation: '',
            annualBalance: '',
            sickVacation: '',
            initialBalance: '',
            vacationWithoutPaid: '',
            takenVacations: '',
            currentBalance: '',
            endYearBalance: '',
            _annualVacation: true,
            _paidVacation: false,
            _sickVacation: false,
            _unpaidVacation: false,
            year: new Date().getFullYear(),
            data: []

        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(718)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(671)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();

        let yearsList = this.getYearsList();
        this.setState({
            yearsList: yearsList, year: yearsList && yearsList.length > 0 ? yearsList[yearsList.length - 1] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '40vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.personalHolidayRpt.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card>
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} style={{ paddingBottom: '10px' }} />
            </Card>
            <Card id="divFilters">
                <div className="p-grid">
                    <div className="p-col-6 p-lg-1 p-sm-6">
                        <Input
                            innerRef={(el) => this.txtFromCode = el}
                            type="text"
                            id="empCode"
                            value={this.state.empCode}
                            autofocus={true}
                            autocomplete="off"
                            onChange={this.handleUserInput}
                            caption={$.strings.personalHolidayRpt.empCode}
                            withgroup={Util.checkISHR() || Util.checkISManager()}
                            btn1icon="search"
                            btn1event={this.getFromCode}
                            disabled={true}
                        />
                    </div>
                    <div className="p-col-6 p-lg-2 p-sm-6">
                        <Input
                            innerRef={(el) => this.txtToCode = el}
                            type="text"
                            id="empName"
                            value={this.state.empName}
                            autofocus={true}
                            autocomplete="off"
                            onChange={this.handleUserInput}
                            caption={$.strings.personalHolidayRpt.empName}
                            disabled
                        />
                    </div>
                    <div className="p-col-6 p-lg-1 p-sm-6">
                        <Dropdown
                            id="year"
                            value={this.state.year}
                            options={this.state.yearsList}
                            caption={$.strings.personalHolidayRpt.year}
                            onChange={this.handleUserInput}
                            optionLabel="name"
                        />
                    </div>
                    <div className="p-col-6 p-lg-2 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                        <Checkbox id="_annualVacation" caption={$.strings.personalHolidayRpt.yearVacation}
                            onChange={e => this.setState({ _annualVacation: e.checked })} checked={this.state._annualVacation} />
                    </div>
                    <div className="p-col-6 p-lg-2 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                        <Checkbox id="_paidVacation" caption={$.strings.personalHolidayRpt.otherPaidVacation}
                            onChange={e => this.setState({ _paidVacation: e.checked })} checked={this.state._paidVacation} />
                    </div>
                    <div className="p-col-6 p-lg-2 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                        <Checkbox id="_sickVacation" caption={$.strings.personalHolidayRpt.sickVacation}
                            onChange={e => this.setState({ _sickVacation: e.checked })} checked={this.state._sickVacation} />
                    </div>
                    <div className="p-col-6 p-lg-2 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                        <Checkbox id="_unpaidVacation" caption={$.strings.personalHolidayRpt.vacationWithoutPaid}
                            onChange={e => this.setState({ _unpaidVacation: e.checked })} checked={this.state._unpaidVacation} />
                    </div>
                </div>

            </Card>
            <div className="p-grid">
                <div className="p-col-12 p-lg-8 p-sm-12">
                    <Card>
                        <DataGrid
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            style={gridStyle}
                            scheme={this.getScheme()}
                            exportFileName={$.strings.personalHolidayRpt.title}
                            dataSource={this.state.data}
                        />
                    </Card>
                </div>
                <div className="p-col-12 p-lg-4 p-sm-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <Card title={$.strings.personalHolidayRpt.yearVacation}>
                                <div className="p-col-6 p-lg-12 p-sm-6">
                                    {$.strings.personalHolidayRpt.annualBalance + ' ' + this.state.annualBalance}
                                </div>
                                <div className="p-col-6 p-lg-12 p-sm-6">
                                    {$.strings.personalHolidayRpt.initialBalance + ' ' + this.state.initialBalance}
                                </div>
                                <div className="p-col-6 p-lg-12 p-sm-6">
                                    {$.strings.personalHolidayRpt.takenVacations + ' ' + this.state.takenVacations}
                                </div>
                                <div className="p-col-6 p-lg-12 p-sm-6">
                                    {$.strings.personalHolidayRpt.currentBalance + ' ' + this.state.currentBalance}
                                </div>
                                <div className="p-col-6 p-lg-6 p-sm-6">
                                    {$.strings.personalHolidayRpt.endYearBalance + ' ' + this.state.endYearBalance}
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <Card title={$.strings.personalHolidayRpt.otherPaidVacation}>
                                <div className="p-col-6 p-lg-6 p-sm-6">
                                    {$.strings.personalHolidayRpt.otherPaidVacation + ' ' + this.state.otherPaidVacation}
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <Card title={$.strings.personalHolidayRpt.sickVacation}>
                                <div className="p-col-6 p-lg-6 p-sm-6">
                                    {$.strings.personalHolidayRpt.sickVacation + ' ' + this.state.sickVacation}
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <Card title={$.strings.personalHolidayRpt.vacationWithoutPaid}>
                                <div className="p-col-6 p-lg-6 p-sm-6">
                                    {$.strings.personalHolidayRpt.vacationWithoutPaid + ' ' + this.state.vacationWithoutPaid}
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollTop />
        </div>
    }

    getYearsList = () => {
        let yearsList = [];
        let currentYear = new Date().getFullYear();
        for (let i = currentYear - 5; i <= currentYear; i++) {
            yearsList.push({ id: i, name: i })
        }
        return yearsList;
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ empCode: obj.code, empName: obj.name })
        }
    }

    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }
    getTransTypes = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 26
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp)
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
        }
        return dataTemp;
    }
    getStatus = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            withAll: true
        }
        let res = await http.getJsonAxios(http.actions.RequestsStatus.urlGetRequestsStatus, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }

    getData = async () => {
        if (!this.state.empCode || this.state.empCode.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.personalHolidayRpt.selectEmployee)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let otherData = []
        let obj = {
            chkPaidVac: this.state._paidVacation,
            chkSikVac: this.state._sickVacation,
            chkUnpaidVac: this.state._unpaidVacation,
            chkYearlyVac: this.state._annualVacation,
            empCode: this.state.empCode && this.state.empCode.length > 0 ? this.state.empCode : '00000',
            year: this.state.year.id,
            userId: localStorage.getItem("useridHR") + "",
            isHr: Util.checkISHR(),
            isDirectManger: Util.checkISManager()
        }
        let res = await http.getJsonAxios(http.actions.HolidayPersonalRpt.urlGetHolidayPersonalRpt, obj, 0);
        
        if (res.status === 200) {
            dataTemp = res.data.list;
            otherData = res.data.displayVacationBalance;
            if (dataTemp) {
                dataTemp.forEach(element => {
                    element.Period = parseInt(element.Period);
                });
            }
        }

        this.setState({
            data: dataTemp,
            yearVacation: otherData && otherData.lblYearlyPeriodBalance ? otherData.lblYearlyPeriodBalance : '',
            otherPaidVacation: otherData && otherData.lblOtherVacPaid ? otherData.lblOtherVacPaid : '',
            annualBalance: otherData && otherData.lblYearlyPeriodBalance ? otherData.lblYearlyPeriodBalance : '',
            sickVacation: otherData && otherData.lblSikVac ? otherData.lblSikVac : '',
            initialBalance: otherData && otherData.lblInitialBalance ? otherData.lblInitialBalance : '',
            vacationWithoutPaid: otherData && otherData.lblUnpaidVac ? otherData.lblUnpaidVac : '',
            takenVacations: otherData && otherData.lblTakenLeaves ? otherData.lblTakenLeaves : '',
            currentBalance: otherData && otherData.lblCurrentBalance ? otherData.lblCurrentBalance : '',
            endYearBalance: otherData && otherData.lblEndYearBalance ? otherData.lblEndYearBalance : '',
            loading: false
        }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.personalHolidayRpt.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            listOfFilters.push($.strings.personalHolidayRpt.empName + ": " + this.printFilters.empName);

            if (this.printFilters.year) {
                listOfFilters.push($.strings.personalHolidayRpt.year + ": " + this.printFilters.year.id);
            }

            if (this.printFilters._annualVacation) {
                listOfFilters.push($.strings.personalHolidayRpt.yearVacation + ": " + (this.printFilters._annualVacation ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters._paidVacation) {
                listOfFilters.push($.strings.personalHolidayRpt.otherPaidVacation + ": " + (this.printFilters._paidVacation ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters._sickVacation) {
                listOfFilters.push($.strings.personalHolidayRpt.sickVacation + ": " + (this.printFilters._sickVacation ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters._unpaidVacation) {
                listOfFilters.push($.strings.personalHolidayRpt.vacationWithoutPaid + ": " + (this.printFilters._unpaidVacation ? $.strings.yes : $.strings.no));
            }
        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };


    getScheme = () => {
        return {
            name: "personalHolidaysRptScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 7,
            columns: [
                { name: 'RowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                {
                    name: 'Refrence', header: $.strings.personalHolidayRpt.code, width: 90, columnWidth: '2'
                },
                {
                    name: 'From', header: $.strings.personalHolidayRpt.from, width: 110, columnWidth: '3'
                },
                {
                    name: 'To', header: $.strings.personalHolidayRpt.to, width: 110, columnWidth: '3'
                },
                {
                    name: 'Date', header: $.strings.personalHolidayRpt.date, width: 100, columnWidth: '3'
                },
                {
                    name: 'Period', header: $.strings.personalHolidayRpt.period, width: 100, dataType: 'Number', aggregate: 'Sum', columnWidth: '2'
                },
                {
                    name: 'TransType', header: $.strings.personalHolidayRpt.vacType, width: 180, columnWidth: '*'
                },
                {
                    name: 'Note', header: $.strings.personalHolidayRpt.notes, width: 280, columnWidth: '*'
                }
            ]
        }
    }
}