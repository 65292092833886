import React from "react";
import Input from "../../Components/Input";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import SiteMap from '../../Components/SiteMap';
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import ScrollTop from '../ScrollButton';
import WFTrans from '../WFTrans';
import Attachments from '../Attachments';
import Calendar from '../../Components/Calendar';
import Button from "../../Components/Button";
import DataTable from "../../Components/Datatable";
import SearchEmployees from '../Employees/SearchEmployees';
import mainTemplate from '../../Components/SpecialComponents/EditorsAndTemplate/MainTemplate/EditorFunctions';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class EmpDelegationsList extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.statusList = Util.getDelegationStatus(true)
        this.state = {
            loading: true,
            mode: 'new',
            deleteErrors: { deleteNote: '' },
            isDesktop: window.innerWidth > 1250,
            data: [],
            employee: undefined,//localStorage.getItem("useridHR") + "",
            delegatedEmployee: undefined,
            fromDate: undefined,
            toDate: undefined,
            insDate: undefined,
            status: this.statusList[0]
        }
        this.scheme = this.getScheme()
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(661)) { // تفويض الموظفين
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = this.updatePredicate();
        let empListPromise = this.getEmployeeList();

        let employeesList = await empListPromise;

        this.setState({ employeesList: employeesList, delegatedEmployee: employeesList[0], isDesktop: _isDesktop, loading: false })

        setTimeout(() => { }, 10);
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    render() {
        let siteMapItems = [
            { label: $.strings.empDelegation.title },
            {
                label: <div>
                    {$.strings.empDelegation.title + " "}
                </div>
            }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/", command: e => alert($.strings.links.Files.branches) }

        return (
            <div ref="divMain">
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12">
                            <SiteMap model={siteMapItems} home={siteMapHome} />
                        </div>
                    </div>
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookupList onNew={this.btnNewClick} onEdit={this.onRowDoubleClick}
                            onRefresh={e => this.getData()} /*onPrint={this.print}*/ onExcelExport={this.onExcelExport} />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
                <div className="p-grid">
                    <div className="p-lg-12 p-sm-12">
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnPrevVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onPrevious(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNextVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNext(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnFirstVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onFirst(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnLastVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onLast(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnDeleteVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onDelete(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloneVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onClone(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloseVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.btnClose(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />

                <DeleteNoteDialog ref={ref => this.deleteNoteDlg = ref} visible={this.state.dlgDeleteNoteVisible} deleteErrors={this.state.deleteErrors} appendTo={this.props.parent}
                    onYes={this.btnDeleteYes} onNo={this.DeleteCancel} deleteNote='' />
                <div className='p-grid'>
                    <div className="p-lg-6 p-sm-12"></div>
                    <div className="p-lg-6 p-sm-12">

                    </div>
                </div>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="delegatedEmployee"
                                innerRef={e => this.drpDelegatedEmployee = e}
                                value={this.state.delegatedEmployee}
                                options={this.state.employeesList}
                                caption={$.strings.empDelegation.delegatedEmployee}
                                onChange={this.handleUserInput}
                                formErrors={this.state.formErrors}
                                optionLabel="Name"
                                placeholder={$.strings.empDelegation.selectEmployee}
                                filter={true}
                                filterBy="Code,Name"
                                filterMatchMode="contains"
                            />
                        </div>
                        <div className="p-col-10 p-lg-1 p-sm-10">
                            <Input
                                innerRef={e => this.txtEmpCode = e}
                                id="employee"
                                onChange={this.handleUserInput}
                                value={this.state.employee}
                                autocomplete="off"
                                caption={$.strings.empDelegation.employee}
                            />
                        </div>
                        {
                            Util.checkISHR() && <div className="p-col-1 p-lg-1 p-sm-1" style={{ alignSelf: 'flex-end' }}>
                                <Button
                                    tooltip={$.strings.empDelegation.searchEmployee}
                                    icon="pi pi-search"
                                    onClick={this.getFromCode}
                                />

                            </div>
                        }
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.empDelegation.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                            />
                        </div>
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.empDelegation.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                            />
                        </div>
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Calendar
                                id="insDate"
                                innerRef={(el) => this.dtpInsDate = el}
                                value={this.state.insDate}
                                caption={$.strings.empDelegation.insDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                            />
                        </div>
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Dropdown
                                id="status"
                                innerRef={e => this.drpStatus = e}
                                value={this.state.status}
                                options={this.statusList}
                                caption={$.strings.empDelegation.status}
                                onChange={this.handleUserInput}
                                formErrors={this.state.formErrors}
                                optionLabel="name"
                                placeholder={$.strings.memos.selectMemoType}
                            />
                        </div>
                    </div>
                </Card>
                <Card>
                    <DataTable
                        isReport={false}
                        ref={ref => (this.tblData = ref)}
                        scrollHeight={this.state.isDesktop ? "38vh" : ''}
                        scheme={this.scheme}
                        value={this.state.data}
                        exportFilename={$.strings.empDelegation.title}
                        //loading={this.state.loading}
                        showContextMenu={false}
                        responsive={true}
                        onRowDoubleClick={this.onRowDoubleClick}
                    />
                </Card>
                <ScrollTop />
                <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
                <Attachments ref={e => this.dlgAttachments = e}></Attachments>
            </div >
        );
    }
    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ employee: obj.code })
        }
    }

    btnNewClick = e => {
        Util.goTo("/EmpDelegation/Delegate")
    };

    onRowDoubleClick = async (e) => {
        if (!this.tblData.state.selection) {
            Util.showErrorMsg(this.messages, $.strings.empDelegation.selectDelegation);
            return;
        }
        let id = this.tblData.state.selection.ID;
        id = Util.encryptStringToHexa(id);
        Util.goTo("/EmpDelegation/Delegate/" + id);
    };

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            DelegatedEmp: this.state.delegatedEmployee && this.state.delegatedEmployee.Code > 0 ? this.state.delegatedEmployee : "",
            status: this.state.status ? this.state.status.id : 0,
            fromDate: this.state.fromDate ? this.state.fromDate : new Date(2000, 1, 1),
            toDate: this.state.toDate ? this.state.toDate : new Date(2000, 1, 1),
            insertDate: this.state.insertDate ? this.state.insertDate : new Date(2000, 1, 1),
            isHr: Util.checkISHR(),
            empCode: this.state.employee && this.state.employee.length > 0 ? this.state.employee : '',
            userId: localStorage.getItem("useridHR") + ""
        }

        let res = await http.getJsonAxios(http.actions.EmpDelegation.urlGetDelegationsList, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.GetEmpDelegationList;
        }
        this.setState({ data: dataTemp, loading: false })
    }

    onExcelExport = () => {
        this.tblData.excelExport();
    }

    getEmployeeList = async () => {
        let dataTemp = []
        let obj = {
            directManager: "",
            isHR: Util.checkISHR()
        }
        let res = await http.getJsonAxios(http.actions.EmpDelegation.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployessObjList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { Code: -1, Name: $.strings.all })
            }
        }
        return dataTemp;
    }

    getScheme = () => {
        return {
            name: "myRequestsScheme_Table",
            filter: false,
            sigma: false,
            columns: [
                {
                    position: 0,
                    field: "RowNumber",
                    header: "##",
                    filterable: false,
                    width: "150px",
                    sortable: false,
                    body: e => mainTemplate(e, "##", "RowNumber")
                },
                {
                    position: 1,
                    field: "ID",
                    header: $.strings.empDelegation.code,
                    filterable: false,
                    width: "150px",
                    sortable: false,
                    body: e => mainTemplate(e, $.strings.empDelegation.Code, "ID")
                },
                {
                    position: 2,
                    field: "InsertDate",
                    header: $.strings.empDelegation.insDate,
                    //body: this.dateTemplate,
                    sortable: false,
                    width: '150px'
                },
                {
                    position: 3,
                    field: "EmpName",
                    header: $.strings.empDelegation.employee,
                    body: e => mainTemplate(e, $.strings.empDelegation.employee, "EmpName"),
                    sortable: false,
                },
                {
                    position: 4,
                    field: "DelegatedEmp",
                    header: $.strings.empDelegation.delegatedEmployee,
                    body: e => mainTemplate(e, $.strings.empDelegation.delegatedEmployee, "DelegatedEmp"),
                    sortable: false,
                },
                {
                    position: 5,
                    field: "FromDate",
                    header: $.strings.empDelegation.fromDate,
                    //body: this.dateTemplate,
                    sortable: false,
                    width: '150px'
                },
                {
                    position: 6,
                    field: "ToDate",
                    header: $.strings.empDelegation.toDate,
                    //body: this.dateTemplate,
                    sortable: false,
                    width: '150px'
                },
                {
                    position: 7,
                    field: "Status",
                    header: $.strings.empDelegation.status,
                    sortable: false,
                    width: '150px',
                    body: e => mainTemplate(e, $.strings.empDelegation.status, "Status")
                }
            ]
        };
    };

}