import React from "react";
import Button from '../../../Components/Button'
import Messages from '../../../Components/Messages'
import http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import Dialog from '../../../Components/Dialog';
import Util from '../../../Util'
import Input from "../../../Components/Input";
import Dropdown from "../../../Components/Dropdown";
import { Fieldset } from "primereact/fieldset";
import InputTime from "../../../Components/InputTime";
import PeriodsSearch from "../Periods/PeriodsSearch";
import Card from "../../../Components/Card";
import ButtonsBarLookup from "../../../Components/ButtonsBarLookup";
import SCC from "../../ScreenCommonCode";
import Checkbox from "../../../Components/Checkbox";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class ShiftDistribute extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.nowDate = new Date();
        this.nowDate.setHours(0, 0, 0);
        this.refreshRequired = false;
        this.state = {
            loading: true,
            formErrors: {},
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            dataObject: {
                id: -1,
                fromTime: this.nowDate,
                toTime: this.nowDate,
                fromRest: this.nowDate,
                toRest: this.nowDate,
                restPeriod: this.nowDate,
                period: this.nowDate,
                beforeIn: this.nowDate,
                afterIn: this.nowDate,
                beforeOut: this.nowDate,
                afterOut: this.nowDate,
                saturday: false,
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false
            }
        }
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state))
        return newHash
    }
    
    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.shifts.distributePeriod} icon="" className="p-button-success" onClick={this.onSave}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <Dialog header={$.strings.shifts.distributePeriod} footer={footer}
                    icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir }}
                    modal onHide={e => this.onHide(e, true)}>
                    <ProgressSpinner loading={this.state.loading} />
                    <PeriodsSearch ref={e => this.dlgPeriodsSearch = e} onSuccess={this.onSearchSuccess} />
                    <Messages innerRef={(el) => this.messages = el} />

                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-10 p-lg-6 p-sm-10">
                                <Input
                                    innerRef={(el) => this.txtCode = el}
                                    id="id"
                                    value={this.state.dataObject.id === -1 ? '' : this.state.dataObject.id}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.shifts.code}
                                    onChange={e => SCC.handleUserInput(this, e)}
                                    onBlur={async e => await this.getData()}
                                />
                            </div>
                            <div className="p-col-2 p-lg-1 p-sm-2" style={{ alignSelf: 'flex-end' }}>
                                <Button icon="pi pi-search" onClick={e => this.dlgPeriodsSearch.show()} />
                            </div>
                        </div>
                        <Fieldset legend={$.strings.shifts.period}>
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="fromTime"
                                        ref={e => this.dtpFromTime = e}
                                        value={this.state.dataObject.fromTime}
                                        caption={$.strings.shifts.from}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="toTime"
                                        ref={e => this.dtpToTime = e}
                                        value={this.state.dataObject.toTime}
                                        caption={$.strings.shifts.to}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="period"
                                        value={this.state.dataObject.period}
                                        caption={$.strings.shifts.period}
                                        isDisabled={true}
                                        isReadOnly={true}
                                        style={{ color: 'black', fontWeight: 'bold', fontSize: '13px' }}
                                    />
                                </div>
                            </div>
                        </Fieldset>
                        <br />
                        <Fieldset legend={$.strings.shifts.restCaption}>
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="fromRest"
                                        ref={e => this.dtpFromRestTime = e}
                                        value={this.state.dataObject.fromRest}
                                        caption={$.strings.shifts.from}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="toRest"
                                        ref={e => this.dtpToRestTime = e}
                                        value={this.state.dataObject.toRest}
                                        caption={$.strings.shifts.to}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="restPeriod"
                                        ref={e => this.dtpRestPeriod = e}
                                        value={this.state.dataObject.restPeriod}
                                        caption={$.strings.shifts.restPeriod}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                        </Fieldset>
                        <br />
                        <Fieldset legend={$.strings.shifts.rest}>
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-3 p-sm-12">
                                    <InputTime
                                        id="beforeIn"
                                        value={this.state.dataObject.beforeIn}
                                        caption={$.strings.shifts.ablimit1}
                                        valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, beforeIn: e.value } })}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-3 p-sm-12">
                                    <InputTime
                                        id="afterIn"
                                        value={this.state.dataObject.afterIn}
                                        caption={$.strings.shifts.ablimit2}
                                        valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, afterIn: e.value } })}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-3 p-sm-12">
                                    <InputTime
                                        id="beforeOut"
                                        value={this.state.dataObject.beforeOut}
                                        caption={$.strings.shifts.ablimit3}
                                        valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, beforeOut: e.value } })}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-3 p-sm-12">
                                    <InputTime
                                        id="afterOut"
                                        value={this.state.dataObject.afterOut}
                                        caption={$.strings.shifts.ablimit4}
                                        valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, afterOut: e.value } })}
                                    />
                                </div>
                            </div>
                        </Fieldset>
                        <br />
                        <Fieldset legend={$.strings.shifts.distributeDays}>
                            <div className="p-grid">
                                <div className="p-col-2 p-lg-2 p-sm-2">
                                    <Checkbox id="saturday" caption={$.strings.weekDays.saturday} checked={this.state.dataObject.saturday}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, saturday: e.checked } })} />
                                </div>
                                <div className="p-col-2 p-lg-2 p-sm-2">
                                    <Checkbox id="sunday" caption={$.strings.weekDays.sunday} checked={this.state.dataObject.sunday}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, sunday: e.checked } })} />
                                </div>
                                <div className="p-col-2 p-lg-2 p-sm-2">
                                    <Checkbox id="monday" caption={$.strings.weekDays.monday} checked={this.state.dataObject.monday}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, monday: e.checked } })} />
                                </div>
                                <div className="p-col-2 p-lg-2 p-sm-2">
                                    <Checkbox id="tuesday" caption={$.strings.weekDays.tuesday} checked={this.state.dataObject.tuesday}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, tuesday: e.checked } })} />
                                </div>
                                <div className="p-col-2 p-lg-2 p-sm-2">
                                    <Checkbox id="wednesday" caption={$.strings.weekDays.wednesday} checked={this.state.dataObject.wednesday}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, wednesday: e.checked } })} />
                                </div>
                                <div className="p-col-2 p-lg-2 p-sm-2">
                                    <Checkbox id="thursday" caption={$.strings.weekDays.thursday} checked={this.state.dataObject.thursday}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, thursday: e.checked } })} />
                                </div>
                                <div className="p-col-2 p-lg-2 p-sm-2">
                                    <Checkbox id="friday" caption={$.strings.weekDays.friday} checked={this.state.dataObject.friday}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, friday: e.checked } })} />
                                </div>
                            </div>
                        </Fieldset>
                    </div >
                </Dialog >
            </div>
        );
    }

    checkAccessByMode = () => {
        if (this.state.dataObject.id >= 0) // update
        {
            return Util.checkRights(946);
        }
        else // insert
        {
            return Util.checkRights(945);
        }
    }

    onSearchSuccess = async (selectedPeriod) => {
        await this.getData(selectedPeriod.code)
    }

    show = async () => {

        this.setState({
            visibleDialog: true, formErrors: {}, loading: false,
            dataObject: {
                ...this.state.dataObject,
            },
        }, () => setTimeout(() => { this.txtCode.focus(); this.hash = Util.hashState(this.state.dataObject) }, 10));
    }

    onHide = () => {
        this.setState({
            visibleDialog: false, formErrors: {},
            dataObject: {
                id: -1,
                fromTime: this.nowDate,
                toTime: this.nowDate,
                fromRest: this.nowDate,
                toRest: this.nowDate,
                restPeriod: this.nowDate,
                period: this.nowDate,
                beforeIn: this.nowDate,
                afterIn: this.nowDate,
                beforeOut: this.nowDate,
                afterOut: this.nowDate,
                saturday: false,
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false
            }
        })
    }

    getData = async (pos) => {
        if (!pos && this.state.dataObject.id < 0) {
            this.resetFields();
            return;
        }
        this.setState({ loading: true });
        let objPeriod = undefined;
        let obj = {
            classPos: "",
            fromTime: "",
            totime: "",
            periodPos: pos || this.state.dataObject.id
        }

        let res = await http.getJsonAxios(http.actions.Periods.urlGetPeriodsList, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                let dataTemp = res.data.list;
                if (dataTemp && dataTemp.length > 0) {
                    objPeriod = dataTemp[0];
                    this.setState({
                        dataObject: {
                            ...this.state.dataObject, id: objPeriod ? objPeriod.code : -1,
                            fromTime: objPeriod ? objPeriod.fromTime : '00:00', toTime: objPeriod ? objPeriod.toTime : '00:00',
                            fromRest: objPeriod ? objPeriod.fromRest1 : '00:00', toRest: objPeriod ? objPeriod.toRest1 : '00:00',
                            restPeriod: objPeriod ? objPeriod.restPeriod : '00:00', period: objPeriod ? objPeriod.period : '00:00'
                        }, loading: false
                    })
                }
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data && res.data.errorText)
                    msg = res.data.errorText;
                Util.showErrorMsg(this.messages, msg)
                this.resetFields();
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.errorText)
                msg = res.data.errorText;
            this.resetFields();
            Util.showErrorMsg(this.messages, msg)
        }
    }

    onSave = async (e) => {
        if (this.state.dataObject.id < 0) {
            Util.showErrorMsg(this.messages, $.strings.shifts.periodError)
            return;
        }
        if (!this.state.dataObject.saturday && !this.state.dataObject.sunday && !this.state.dataObject.monday &&
            !this.state.dataObject.tuesday && !this.state.dataObject.wednesday && !this.state.dataObject.thursday &&
            !this.state.dataObject.friday) {
            Util.showErrorMsg(this.messages, $.strings.shifts.daysError)
            return;
        }
        if (this.props.onSuccess) {
            this.props.onSuccess(this.state.dataObject);
            this.setState({
                visibleDialog: false, dataObject: {
                    id: -1,
                    fromTime: this.nowDate,
                    toTime: this.nowDate,
                    fromRest: this.nowDate,
                    toRest: this.nowDate,
                    restPeriod: this.nowDate,
                    period: this.nowDate,
                    beforeIn: this.nowDate,
                    afterIn: this.nowDate,
                    beforeOut: this.nowDate,
                    afterOut: this.nowDate,
                    saturday: false,
                    sunday: false,
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false
                }
            })
        }
    };


    resetFields = () => {
        this.setState({
            dataObject: {
                ...this.state.dataObject,
                id: -1,
                fromTime: this.nowDate,
                toTime: this.nowDate,
                fromRest: this.nowDate,
                toRest: this.nowDate,
                restPeriod: this.nowDate,
                period: this.nowDate,
                beforeIn: this.nowDate,
                afterIn: this.nowDate,
                beforeOut: this.nowDate,
                afterOut: this.nowDate,
                saturday: false,
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false
            }, loading: false, formErrors: {},
        }, () => this.hash = Util.hashState(this.state.dataObject))
    }
}