import React from "react";
import Button from '../../Components/Button'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import DataTable from '../../Components/Datatable';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class Attachments extends React.Component {
    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            data: [],
            dataObject: {

            }
        }
        this.scheme = this.getScheme()
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <Dialog header={$.strings.attachments.title} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                style={{ width: this.state.isDesktop?'60vw':'90vw', direction: $.strings.dir }} modal onHide={this.onHide}>
                <div ref="divMain" >
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataTable
                                isReport={false}
                                ref={ref => (this.tblData = ref)}
                                scrollHeight="45vh"
                                scheme={this.scheme}
                                value={this.state.data}
                                fullData={this.state.dataObject}
                                loading={this.state.loading}
                                showContextMenu={false}
                                responsive={true}
                            />
                        </div>
                    </div>
                </div >
            </Dialog >
        );
    }
    show = async (typeID, transID) => {
        this.setState({ visibleDialog: true, data: [] }, () => { this.getData(typeID, transID) })
    }

    getData = async (typeID, transID) => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            typeId: typeID,
            transId: transID
        }
        let res = await http.getJsonAxios(http.actions.Attachments.urlGetAttachments, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        this.setState({ data: dataTemp, loading: false })
    }

    onHide = () => {
        this.setState({ visibleDialog: false })
    }

    viewTemplate = (rowData, column) => {
        return <>
            <Button icon="pi pi-paperclip" label=" " tooltip={$.strings.attachments.viewAttachment} onClick={e => this.viewAttachment(rowData)}>
            </Button>
        </>
    }
    viewAttachment = async (data) => {
        let dataTemp
        this.setState({ loading: true })
        const params = {
            typeId: data.TypeID,
            attName: data.AttachmnetName,
            originName: data.OriginalName
        };
        let res = await http.getJsonAxios(http.actions.Attachments.urlGetAttachmentFile, params, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        if (!dataTemp || dataTemp === null || dataTemp.length <= 0) {
            this.setState({ loading: false })
            return
        }

        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = "data:application/octet-stream;base64," + dataTemp
        downloadLink.target = '_blank';
        downloadLink.setAttribute('download', data.OriginalName)
        downloadLink.download = data.OriginalName;
        downloadLink.click();
        this.setState({ loading: false })
    }
    mainTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData[column.field]}</span>
        </>
    }

    getScheme = () => {
        return {
            name: "attachmentsScheme_Table",
            filter: false,
            sigma: false,
            columns: [
                {
                    position: 0,
                    field: "serial",
                    header: "##",
                    filterable: false,
                    width: "45px",
                    sortable: false,
                    body: this.mainTemplate,
                },
                {
                    position: 1,
                    header: '',
                    sortable: false,
                    width: '40px',
                    body: this.viewTemplate
                },
                {
                    position: 2,
                    field: "ID",
                    header: $.strings.attachments.ID,
                    width: "90px",
                    hidden: true,
                    hiddenInColumnChooser: true,
                    body: this.mainTemplate
                },
                {
                    position: 3,
                    field: "OriginalName",
                    width: '200px',
                    header: $.strings.attachments.originalName,
                    body: this.mainTemplate
                },
                {
                    position: 4,
                    field: "AttachmnetName",
                    width: '200px',
                    header: $.strings.attachments.attachmnetName,
                    hidden: true,
                    hiddenInColumnChooser: true,
                    body: this.mainTemplate
                },
                {
                    position: 5,
                    field: "EmpName",
                    width: '200px',
                    header: $.strings.attachments.userName,
                    body: this.mainTemplate
                },
                {
                    position: 6,
                    field: "TypeID",
                    hidden: true,
                    hiddenInColumnChooser: true
                }
            ]
        };
    };
}