import React from "react";
import styles from './OverTime.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import Card from '../../Components/Card'
import InputTextArea from '../../Components/InputTextArea';
import Calendar from '../../Components/Calendar'
import SiteMap from '../../Components/SiteMap';
import ScrollTop from '../ScrollButton';
import WFTrans from '../WFTrans';
import InputTime from "../../Components/InputTime/InputTime";
import { FileUpload } from "primereact/fileupload";
import Attachments from "../Attachments";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class OverTime extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: this.props.match.params.id ? 'edit' : 'new',
            formErrors: { empCode: '', empName: '', memoType: '', notes: '' },
            deleteErrors: { deleteNote: '' },
            isDesktop: window.innerWidth > 1250,
            dataObject: {
                id: 0,
                empCode: this.props.match.params.empCode || localStorage.getItem("useridHR") || '',
                empName: Util.getEmployeeInfo() || '',
                transDate: new Date(),
                fromTime: new Date(),
                toTime: Util.getCurrentTimePlusHour(new Date()),
                period: '01:00',
                notes: '',
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: 0,
                status: 1
            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(606)) {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = this.updatePredicate();

        if (!this.props.match.params.id) {
            let empCode = this.props.match.params.empCode || localStorage.getItem("useridHR") + "";
            let empName = Util.getEmployeeInfo() || ''
            if (empCode !== localStorage.getItem("useridHR") + "") {
                let obj = await this.getEmployeeInfo(empCode);
                empCode = obj.Code;
                empName = obj.Name;
            }
            this.setState({ dataObject: { ...this.state.dataObject, empCode: empCode, empName: empName }, isDesktop: _isDesktop, loading: false })
        }
        else {
            if (this.props.match.params.id) {
                let data = await this.showOverTimeInfo(this.props.match.params.id)
                this.loadData(data)
            }
        }

        setTimeout(() => { this.txtNotes.focus() }, 10);
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    handleFromTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, fromTime: e.value } }, () => this.calculateHoursNo())
    }
    setToTime = () => {
        if (this.state.dataObject.toTime <= this.state.dataObject.fromTime) {
            let toTimeTemp = Util.getCurrentTimePlusHour(this.state.dataObject.fromTime)
            this.setState({ dataObject: { ...this.state.dataObject, toTime: toTimeTemp } }, () => this.calculateHoursNo())
        }
        else {
            this.calculateHoursNo()
        }
    }
    handleToTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, toTime: e.value } }, () => this.calculateHoursNo())
    }
    setFromTime = () => {
        if (this.state.dataObject.toTime <= this.state.dataObject.fromTime) {
            let fromTimeTemp = Util.getCurrentTimeMinusHour(this.state.dataObject.toTime)
            this.setState({ dataObject: { ...this.state.dataObject, fromTime: fromTimeTemp } }, () => this.calculateHoursNo())
        }
        else {
            this.calculateHoursNo()
        }
    }

    calculateHoursNo = () => {
        try {
            let fromDate = this.state.dataObject.fromTime;
            let toDate = this.state.dataObject.toTime;

            var hoursNo = ((new Date(1, 1, 1, toDate.getHours(), toDate.getMinutes(), 0) -
                new Date(1, 1, 1, fromDate.getHours(), fromDate.getMinutes(), 0)) / 60000);
            let obj = Util.getNumberByTimeFormat(hoursNo);
            let hours = obj.hours + "";
            if (hours.length === 1)
                hours = "0" + hours;

            let minutes = obj.minutes + "";
            if (minutes.length === 1)
                minutes = "0" + minutes;

            let result = hours + ":" + minutes;


            this.setState({ dataObject: { ...this.state.dataObject, period: result } })
        }
        catch (e) {

        }
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _empCode = this.state.dataObject.empCode
        fieldValidationErrors._empCode = _empCode ? '' : $.strings.requiredFiled;

        let _empName = this.state.dataObject.empName;
        fieldValidationErrors.empName = _empName ? '' : $.strings.requiredFiled;

        let _transDate = this.state.dataObject.transDate
        fieldValidationErrors.transDate = _transDate ? '' : $.strings.requiredFiled;

        let _fromTime = this.state.dataObject.fromTime
        fieldValidationErrors.fromTime = _fromTime ? '' : $.strings.requiredFiled;

        let _toTime = this.state.dataObject.toTime
        fieldValidationErrors.toTime = _toTime ? '' : $.strings.requiredFiled;

        let _notes = this.state.dataObject.notes;
        fieldValidationErrors.notes = _notes ? '' : $.strings.requiredFiled;

        if (this.state.dataObject.period.includes('-')) {
            Util.showErrorMsg(this.messages, $.strings.overTime.timeError)
            return false;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            empCodeValid: _empCode,
            empNameValid: _empName,
            transDateValid: _transDate,
            fromTimeValid: _fromTime,
            toTimeValid: _toTime,
            notesValid: _notes

        }, this.validateForm);

        return _empCode && _empName && _transDate && _fromTime && _toTime && _notes;
    }
    validateForm() {
        let isFormValid = this.state.empCodeValid && this.state.empNameValid && this.state.transDateValid
            && this.state.fromTimeValid && this.state.toTimeValid && this.state.notesValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {

        let siteMapItems = [
            { label: $.strings.links.requests.menu },
            {
                label: <div>
                    {$.strings.links.requests.overTime + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];

        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" className={styles.addOverTime}>
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={!this.props.match.params.id ? this.resetFields : undefined}
                            onSave={!this.props.match.params.id ? e => this.onSave() : undefined}
                            onTransDetails={this.props.match.params.id ? this.wfTransDetails : undefined}
                            onShowAttachment={this.props.match.params.id && this.state.dataObject.file_id > 0 ? this.onShowAttachment : undefined}
                            onPrint={this.props.match.params.id ? e => this.onPrint(e) : undefined}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid">
                    <div className="p-lg-12 p-sm-12">
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>

                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnPrevVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onPrevious(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNextVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNext(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnFirstVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onFirst(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnLastVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onLast(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnDeleteVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onDelete(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloneVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onClone(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloseVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.btnClose(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />

                <DeleteNoteDialog ref={ref => this.deleteNoteDlg = ref} visible={this.state.dlgDeleteNoteVisible} deleteErrors={this.state.deleteErrors} appendTo={this.props.parent}
                    onYes={this.btnDeleteYes} onNo={this.DeleteCancel} deleteNote='' />
                <div className='p-grid'>
                    <div className="p-lg-6 p-sm-12"></div>
                    <div className="p-lg-6 p-sm-12">

                    </div>
                </div>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtCode = el}
                                type="text"
                                id="id"
                                keyfilter="pnum"
                                value={this.state.dataObject.id || ''}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.overTime.code}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-lg-4 p-sm-12"></div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpCode = el}
                                type="text"
                                id="empCode"
                                keyfilter="pnum"
                                maxLength="4"
                                value={this.state.dataObject.empCode || ''}
                                autofocus={true}
                                autocomplete="off"
                                caption={$.strings.overTime.empCode}
                                isRequired={true}
                                onChange={this.handleUserInput}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-4 p-lg-8 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpName = el}
                                type="text"
                                id="empName"
                                maxLength="70"
                                value={this.state.dataObject.empName || ''}
                                autofocus={true}
                                caption={$.strings.overTime.empName}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                readOnly={true}
                                disabled
                                style={{ width: '100%', color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <Calendar
                                id="transDate"
                                innerRef={(el) => this.dtpTransDate = el}
                                value={this.state.dataObject.transDate}
                                caption={$.strings.overTime.transDate}
                                onChange={this.handleUserInput}
                                tooltip={$.strings.overTime.transDate}
                                readOnlyInput={true}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <InputTime
                                id="fromTime"
                                ref={e => this.dtpTransTime = e}
                                value={this.state.dataObject.fromTime}
                                caption={$.strings.overTime.fromTime}
                                valueChanged={this.handleFromTime}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <InputTime
                                id="toTime"
                                ref={e => this.dtpTransTime = e}
                                value={this.state.dataObject.toTime}
                                caption={$.strings.overTime.toTime}
                                valueChanged={this.handleToTime}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                        <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtPeriod = el}
                                type="text"
                                id="period"
                                keyfilter="pnum"
                                value={this.state.dataObject.period || ''}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.overTime.period}
                                readOnly={true}
                                disabled
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                            <InputTextArea
                                innerRef={ref => this.txtNotes = ref}
                                type="text"
                                id="notes"
                                maxLength="100"
                                value={this.state.dataObject.notes}
                                caption={$.strings.overTime.notes}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                            <div >
                                <label for="file-upload" className={styles.customFileUpload}>
                                    {$.strings.overTime.uploadFile}
                                </label>
                                <FileUpload chooseLabel={$.strings.vacations.uploadFile} name="file-upload-prime" url=""
                                    onSelect={this.handleChangePrime} auto maxFileSize={5000000} disabled={this.state.dataObject.id > 0}
                                    invalidFileSizeMessageSummary={$.strings.fileSizeError}
                                    invalidFileSizeMessageDetail={""} />
                            </div>
                        </div>
                    </div>
                </Card >
                <ScrollTop />
                <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
                <Attachments ref={e => this.dlgAttachments = e}></Attachments>
            </div >
        );
    }

    handleChange = (event) => {
        if (event.target.files.length > 0) {
            let _uploadedFile = URL.createObjectURL(event.target.files[0])
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.target.files[0].size > 4 * 1024 * 1024) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                document.getElementById('file-upload').value = ''
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.target.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }

    handleChangePrime = (event) => {
        if (event.files.length > 0) {
            let _uploadedFile = event.files[0];
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.files[0].size > 5 * 1000 * 1000) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }

    setSubscriberImgSrc = () => {

        alert($.strings.fileUploaded)
    }

    getEmployeeInfo = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode,
            isHr: Util.checkISHR(),
            userId: localStorage.getItem('useridHR')
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeInfo, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployeeInfoObj;
        }
        return dataTemp
    }

    showOverTimeInfo = async (id) => {
        let dataTemp = []
        let obj = {
            id: id
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetHolidayReq, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    loadData = (data) => {
        if (!Util.checkISHR() && !Util.checkCanViewRequest(data.empNo)) {
            Util.goTo('/Access');
            return;
        }

        let fromDate = new Date(data.fromDate);
        let fromTime = new Date(data.FromTime);
        let toTime = new Date(data.toTime);

        this.setState({
            mode: 'view',
            isDesktop: this.updatePredicate(),
            dataObject: {
                ...this.state.dataObject,
                id: data.serial,
                empCode: data.empNo,
                empName: data.empName,
                transDate: fromDate,
                fromTime: fromTime.getFullYear() + "" === "1" ? '' : fromTime,
                toTime: toTime.getFullYear() + "" === "1" ? '' : toTime,
                period: data.totaltime,
                notes: data.notes,
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: data.AttachValue
            }
            , loading: false
        })
    }

    wfTransDetails = async () => {
        let id = Util.encryptStringToHexa(this.state.dataObject.id);
        await this.wfTransDlg.show(1, id)
    }

    onShowAttachment = async () => {
        await this.dlgAttachments.show(1, this.state.dataObject.id)
    }

    onPrint = async () => {
        Util.goToNewTab('../../../PrintOverTime/' + this.props.match.params.id);
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true })
        let files = [] // ملفات المرفقات
        let file = this.state.dataObject.uploadedFile2

        let obj = {
            serial: !this.state.dataObject.id ? 0 : this.state.dataObject.id,
            empcode: this.state.dataObject.empCode,
            FromTime: this.state.dataObject.fromTime,
            toTime: this.state.dataObject.toTime ? this.state.dataObject.toTime : '',
            notes: this.state.dataObject.notes,
            period: this.state.dataObject.period ? this.state.dataObject.period.id : 0,
            transType: 5,
            transTypeText: $.strings.overTime.title,
            alternativeEmp: '',
            alternativeEmp2: '',
            address: '',
            phone: '',
            empNo: this.state.dataObject.empCode,
            fromDate: this.state.dataObject.transDate,
            toDate: this.state.dataObject.transDate,
            userCode: localStorage.getItem("useridHR"),
            checkVacationRequestPeriod: false
        }

        let res = await http.postMultipartAxios(http.actions.HolidaysReq.urlPostSaveOverTime, obj, file, files, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
                return true
            }
        }
        if (res.data.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return false
        }
    }

    resetFields = () => {
        this.setState({
            dataObject: {
                ...this.state.dataObject,
                id: 0,
                transDate: new Date(),
                fromTime: new Date(),
                toTime: Util.getCurrentTimePlusHour(new Date()),
                period: '01:00',
                notes: '',
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: 0,
                status: 1
            },
            formErrors: { empCode: '', empName: '', memoType: '', notes: '' },
        })
    }
}