import React from "react";
import styles from "./Accordion.module.scss";
import {Accordion as PrimeAccordion } from 'primereact/accordion';

export default class Accordion extends React.Component {
    render() {
        return (
            <PrimeAccordion  ref={this.props.innerRef} className={styles.accordion} {...this.props} >
                {this.props.children}
            </PrimeAccordion>
        );
    }
}