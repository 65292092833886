import '@grapecity/wijmo.styles/wijmo.css';
import './Requests.css';
import * as React from 'react';
import * as wijmo from '@grapecity/wijmo';
import * as wjChart from '@grapecity/wijmo.react.chart';
import * as wjCharts from '@grapecity/wijmo.chart';
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation';
import http from '../../../../Api/http'
import ProgressSpinner from '../../../ProgressSpinner';

const $ = window.$

export default class Requests extends React.Component {
    constructor(props) {
        super(props);
        this.palette = ['rgba(227,88,85,1)', 'rgba(208,192,65,1)', 'rgba(166,114,166,1)',
            'rgba(181,72,54,1)', 'rgba(96, 163, 234,1)', 'rgba(137,194,53,1)', 'rgba(227,119,164,1)',
            'rgba(166,137,49,1)', 'rgba(227,88,85,1)', 'rgba(104,112,106,1)'
        ];
        this.state = {
            loading: true,
            isDesktop: true,
            dataRequests: []
        }
    }

    _updateChartHeaderRequests = (group) => {
        let item = group.items[0], path = '', headers = [];
        //
        for (let i = 0; i <= group.level; i++) {

            let prop = this.state.dataRequests.groupDescriptions[i].propertyName;
            let hdr = wijmo.format('<a href="#{path}">{prop}</a>: {value}',
                {
                    path: path,
                    prop: $.strings.charts[prop],
                    value: item[prop]
                }
            );
            headers.push(hdr);
            path = '/' + item[prop];
        }
        //
        if (this.headerRequests) {
            this.headerRequests.innerHTML = headers.length > 0
                ? /*$.strings.charts.requestsTitle + ' ' +*/ headers.join(', ')
                : ''//$.strings.charts.requestsTitle;
        }
    }
    initializeRequestsChart = (flex) => {
        this.theChartRequests = flex;
        this.headerRequests = document.querySelector('#requestsHeader');
        if (this.headerRequests) {
            this.headerRequests.addEventListener('click', (e) => this._onHeaderClickRequests(e))
        }
    };
    requestsSelectionChanged = () => {
        if (this.theChartRequests.selection) {
            let point = this.theChartRequests.selection.collectionView.currentItem;
            if (point && point.group && !point.group.isBottomLevel) {
                this.showGroupRequests(point.group, true, this.theChartRequests.selection);
            }
        }
    };

    _onHeaderClickRequests = (e) => {
        if (e.target instanceof HTMLAnchorElement) {
            e.preventDefault();

            // get the link path
            let path = e.target.href;
            path = decodeURI(path);
            path = path.substr(path.lastIndexOf('#') + 1);
            let paths = path.split('/');

            // find the group that matches the path
            let src = this.state.dataRequests;

            for (let i = 1; i < paths.length; i++) {
                for (let j = 0; j < src.groups.length; j++) {
                    let group = src.groups[j];
                    if (group.name === paths[i]) {
                        src = group;
                        break;
                    }
                }
            }
            // show the selected group
            this.showGroupRequests(src);
        }
    };
    showGroupRequests = async (group, isDrillDown, selected) => {
        // update titles
        this._updateChartHeaderRequests(group);
        var level = 'level' in group ? group.level + 1 : 0;
        //this.theChartRequests.axisX.title = $.strings.charts[wijmo.toHeaderCase(this.state.dataRequests.groupDescriptions[level].propertyName)];
        //
        // update the series color (use a different one for each level)
        var palette = this.theChartRequests.palette || wjCharts.Palettes.standard;
        this.theChartRequests.series[0].style = {
            fill: palette[level],
            stroke: palette[level]
        };
        //
        // update data
        let data = this.getGroupData(group, isDrillDown, selected);
        this.theChartRequests.itemsSource = data;
        this.theChartRequests.selection = null;
    };



    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;
        let data = await this.getData();

        this.setState({
            dataRequests: data, groupedViewDataRequests: this.getGroupData(data), isDesktop: _isDesktop,
            palette: this.palette, loading: false
        });
    }
    render() {

        // return <div className="container-fluid">
        //     <ProgressSpinner loading={this.state.loading} />
        //     <h4 id="requestsHeader">{$.strings.charts.requestsTitle}</h4>
        //     <wjChart.FlexChart bindingX="name" selectionMode="Point" itemsSource={this.state.groupedViewDataRequests} selectionChanged={this.requestsSelectionChanged}
        //         initialized={this.initializeRequestsChart} palette={this.state.palette}>
        //         <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        //         <wjChart.FlexChartSeries binding="Type" name={$.strings.charts.requestsCount} ></wjChart.FlexChartSeries>

        //         <wjChart.FlexChartAxis wjProperty="axisX" title=""></wjChart.FlexChartAxis>
        //         <wjChart.FlexChartAxis wjProperty="axisY" title="" ></wjChart.FlexChartAxis>
        //         <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
        //     </wjChart.FlexChart>

        // </div>;

        return (
            <>
                <span id="requestsHeader">{$.strings.charts.requestsTitle}</span>
                <wjChart.FlexChart bindingX="name" selectionMode="Point" itemsSource={this.state.groupedViewDataRequests} selectionChanged={this.requestsSelectionChanged}
                    initialized={this.initializeRequestsChart} palette={this.state.palette}>
                    <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
                    <wjChart.FlexChartSeries binding="Type" name={$.strings.charts.requestsCount} ></wjChart.FlexChartSeries>

                    <wjChart.FlexChartAxis wjProperty="axisX" title=""></wjChart.FlexChartAxis>
                    <wjChart.FlexChartAxis wjProperty="axisY" title="" ></wjChart.FlexChartAxis>
                    <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
                </wjChart.FlexChart>
            </>
        )
    }

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR"),
            requestType: '-2',
            transSubType: ''
        }
        let res = await http.getJsonAxios(http.actions.Dashboards.urlGetEmployeesRequests, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.rightList;
            if (dataTemp) {
                dataTemp.forEach(element => {

                });
            }
        }

        return new wijmo.CollectionView(dataTemp, {
            groupDescriptions: ['TypeName', 'SectionName', 'EmpName']
        });
    }

    getGroupData = (group, isDrillDown, selected) => {
        // get items for this group, aggregate by sales
        let arr = [];
        if (isDrillDown) {
            group.groups.forEach(function (g) {
                if (selected && selected._binding === "Type") {
                    arr.push({
                        name: g.name,
                        Type: g.getAggregate(wijmo.Aggregate.Cnt, 'Type'),
                        TypeCount: g.getAggregate(wijmo.Aggregate.Cnt, 'Type'),
                        SectionName: g.getAggregate(wijmo.Aggregate.Cnt, 'SectionName'),
                        group: g
                    });
                }
                else if (selected && selected._binding === "SectionName") {
                    arr.push({
                        name: g.name,
                        Type: g.getAggregate(wijmo.Aggregate.Cnt, 'Type'),
                        TypeCount: g.getAggregate(wijmo.Aggregate.Cnt, 'EmpName'),
                        SectionName: g.getAggregate(wijmo.Aggregate.Cnt, 'SectionName'),
                        group: g
                    });
                }
                else {
                    if (g.items && g.items.length > 0 && g.items[0].Late > 0) {
                        arr.push({
                            name: g.name,
                            Type: g.getAggregate(wijmo.Aggregate.Cnt, 'Type'),
                            TypeCount: g.getAggregate(wijmo.Aggregate.Cnt, 'Type'),
                            SectionName: g.getAggregate(wijmo.Aggregate.Cnt, 'SectionName'),
                            group: g
                        });
                    }
                }
            });
        }
        else {
            group.groups.forEach(function (g) {
                arr.push({
                    name: g.name,
                    Type: g.getAggregate(wijmo.Aggregate.Cnt, 'Type'),
                    TypeCount: g.getAggregate(wijmo.Aggregate.Cnt, 'Type'),
                    SectionName: g.getAggregate(wijmo.Aggregate.Cnt, 'SectionName'),
                    group: g
                });
            });
        }

        // return a new collection view sorted by sales
        return new wijmo.CollectionView(arr, {
            sortDescriptions: [
                new wijmo.SortDescription('Type', false),
            ]
        });
    }
}