export function toJson(schemeObject) {
    let obj = {}
    obj.name = schemeObject.name
    obj.filter = schemeObject.filter
    obj.sigma = schemeObject.sigma
    obj.group = schemeObject.group
    obj.groupField = schemeObject.groupField
    obj.groupSortOrder = schemeObject.groupSortOrder
    obj.sortField = schemeObject.sortField
    obj.sortOrder = schemeObject.sortOrder
    obj.columns = schemeObject.columns.map(c => ({
        field: c.field,
        filter: c.filter,
        filterMatchMode: c.filterMatchMode,
        position: c.position,
        width: c.width,
        hidden: c.hidden,
        hiddenInColumnChooser: c.hiddenInColumnChooser,
        sigma: c.sigma,
    }));

    return JSON.stringify(obj)
}

export function create(defaultScheme) {
    let obj = { ...defaultScheme }
    obj.columns = defaultScheme.columns.map(c => ({ ...c }))
    return obj
}

/**
 * 
 * @param defaultScheme scheme that you want to change
 * @param scheme your saved scheme
 * 
 */
export function merge(defaultScheme, scheme) {
    let columnsRef = defaultScheme.columns
    defaultScheme = { ...defaultScheme, ...scheme }
    let schemeColumns = scheme.columns.reduce((a, b) => {
        a[b.field] = b
        return a
    }, {})
    columnsRef.forEach(c => {
        let schemeColumn = schemeColumns[c.field]
        if (schemeColumn) {
            c.filter = schemeColumn.filter
            c.filterMatchMode = schemeColumn.filterMatchMode
            c.position = schemeColumn.position
            c.width = schemeColumn.width
            c.hidden = schemeColumn.hidden
            c.hiddenInColumnChooser = schemeColumn.hiddenInColumnChooser
            c.sigma = schemeColumn.sigma
        }
    })
    defaultScheme.columns = columnsRef
    return defaultScheme
}