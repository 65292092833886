import React from "react";
import styles from "./ButtonsBarLookupList.module.scss";
import Button from "../Button"
import { Menu } from 'primereact/menu';
import { FaPen, FaPrint, FaPlus, FaRedo, FaRegFileExcel, FaEllipsisV } from 'react-icons/fa'

const $ = window.$;

export default class ButtonsBarLookupList extends React.Component {
    constructor(props) {
        super(props);
        this.menuItemsList = this.splitButtonItems();
        this.state = {
            splitItems: this.menuItemsList.filter(element => element.visible === true),
            isDesktop: window.innerWidth > 1250
        };
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 1250 });
    }

    render() {

        return (
            this.state.isDesktop ?
                (<div className={`p-grid ${styles.buttonBar}`}>
                    <div className="p-col-2 p-md-5" style={{ width: '100%' }}>
                        {
                            this.props.onNew &&
                            <Button label={$.strings.new} onClick={this.props.onNew} tooltip={$.strings.new}>
                                &nbsp;&nbsp;
                                <i className="pi pi-plus" />
                            </Button>
                        }
                        {(this.props.permissions.refresh.visible && !this.props.onRefresh ? false : true) && <Button onClick={this.props.onRefresh} tooltip={$.strings.refresh}
                            label={$.strings.refresh} disabled={this.props.permissions.refresh.disabled} >
                            &nbsp;&nbsp;
                            <i className="pi pi-refresh" />
                        </Button>
                        }
                        {this.props.onPrint && <Button onClick={this.props.onPrint} tooltip={$.strings.print}
                            label={$.strings.print} disabled={this.props.permissions.print.disabled} >
                            &nbsp;&nbsp;
                            <i className="pi pi-print" />
                        </Button>
                        }
                        {(this.props.permissions.excelExport.visible && !this.props.onExcelExport ? false : true) && <Button onClick={this.props.onExcelExport}
                            tooltip={$.strings.excelExport} label={$.strings.excelExport} disabled={this.props.permissions.excelExport.disabled} >
                            &nbsp;&nbsp;
                            <i className="pi pi-file-excel" />
                        </Button>
                        }
                        {this.props.onShowFilter && <Button onClick={this.props.onShowFilter} tooltip={$.strings.showFilters}
                            className={styles.filterButton} style={{ float: $.strings.dir === "rtl" ? 'left' : 'right' }}>
                            <i className="pi pi-angle-double-up" />
                        </Button>
                        }
                        {
                            this.props.onSave
                            &&
                            <Button label={$.strings.save} onClick={this.props.onSave} tooltip={$.strings.save} innerRef={ref => this.btnSave = ref}
                                id="btnSave" className="p-button-success">
                                &nbsp;&nbsp;
                                <i className="pi pi-save" />
                            </Button>
                        }
                    </div>


                </div>)
                :
                (
                    <div className={`p-grid ${styles.buttonBar}`}>
                        <div className="p-col-2 p-md-5" style={{ width: '100%' }}>
                            {(this.props.permissions.refresh.visible && !this.props.onRefresh ? false : true) && <Button onClick={this.props.onRefresh} tooltip={$.strings.refresh}
                                label={$.strings.refresh} disabled={this.props.permissions.refresh.disabled} >
                                &nbsp;&nbsp;
                                <i className="pi pi-refresh" />
                            </Button>
                            }
                            <Menu model={this.splitButtonItems().filter(element => element.visible === true)} popup={true} ref={ref => this.menu = ref} />
                            {
                                this.state.splitItems && this.state.splitItems.length > 0 &&
                                <Button onClick={this.showSplitButtons}>
                                    <FaEllipsisV />
                                </Button>
                            }
                            {
                                this.props.onShowFilter && <Button onClick={this.props.onShowFilter} tooltip={$.strings.showFilters}
                                    className={styles.filterButton}>
                                    <i className="pi pi-angle-double-up" />
                                </Button>
                            }
                        </div>
                    </div>
                )
        );
    }
    showSplitButtons = (event) => {
        this.menu.toggle(event);
    }

    // splitButtonItems = () => {
    //     let items = [
    //         // {
    //         //     label: (<Button label={$.strings.refresh} onClick={this.props.onRefresh} tooltip={$.strings.excelExport} innerRef={ref => this.btnRefresh = ref}
    //         //         id="btnExcelExport" className={`${""} ${styles.menuItem}`}>

    //         //         <i className="pi pi-refresh" />
    //         //     </Button>),
    //         //     command: !this.props.permissions.refresh.disabled ? this.props.onRefresh : '',
    //         //     visible: (this.props.permissions.refresh.visible && !this.props.onRefresh ? false : true),
    //         //     disabled: this.props.permissions.refresh.disabled
    //         // },
    //         {
    //             label: (<span><FaPrint /> {$.strings.print}</span>),
    //             command: !this.props.permissions.print.disabled ? this.props.onPrint : '',
    //             visible: (this.props.permissions.print.visible && !this.props.onPrint ? false : true),
    //             disabled: this.props.permissions.print.disabled
    //         },
    //         {
    //             label: (<Button label={$.strings.excelExport} onClick={this.props.onExcelExport} tooltip={$.strings.excelExport} innerRef={ref => this.btnExcelExport = ref}
    //                 id="btnExcelExport" className={`${""} ${styles.menuItem}`}>

    //                 <i className="pi pi-file-excel" />
    //             </Button>),
    //             command: !this.props.permissions.excelExport.disabled ? this.props.onExcelExport : '',
    //             visible: (this.props.permissions.excelExport.visible && !this.props.onExcelExport ? false : true),
    //             disabled: this.props.permissions.excelExport.disabled
    //         }
    //     ];
    //     return items;
    // }

    splitButtonItems = () => {
        let items = [
            {
                label:
                    (
                        <Button label={$.strings.new} onClick={this.props.onNew} tooltip={$.strings.new} className={styles.menuItem}>
                            <i className="pi pi-plus" />
                        </Button>
                    ),
                icon: '',
                command: this.props.onNew,
                visible: this.props.onNew ? true : false,
            }, {
                label:
                    (
                        <Button label={$.strings.save} onClick={this.props.onNew} tooltip={$.strings.save}
                            className={`${"p-button-success"} ${styles.menuItem}`}>
                            <i className="pi pi-save" />
                        </Button>
                    ),
                icon: '',
                command: this.props.onSave,
                visible: this.props.onSave ? true : false,
            },
        ];
        return items;
    }
}

ButtonsBarLookupList.defaultProps = {
    permissions: {
        print: {
            visible: true,
            disabled: false
        },
        refresh: {
            visible: true,
            disabled: false
        },
        excelExport: {
            visible: true,
            disabled: false
        }
    }
}
