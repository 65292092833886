import React from "react";
import http from "../../Api/http";
import Checkbox from "../../Components/Checkbox";

const $ = window.$;


export default class PrintVacations2 extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            dataObject: undefined,
            isDesktop: window.innerWidth > 800
        }
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    componentDidMount = async () => {
        document.addEventListener("resize", this.updatePredicate);
        let _dataObject = await this.getData();
        if (_dataObject) {
            this.setState({ dataObject: _dataObject, loading: false }, () => {
                setTimeout(() => {
                    this.doPrint();
                }, 10);
            })
        }
        else {
            this.setState({ loading: false })
        }
    }

    render() {

        return this.state.dataObject ?
            (
                <div id="divMain" style={{ direction: $.strings.dir, padding: '2em' }}>
                    <div className="p-grid">
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: 'center' }}>
                            <br />
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.companyName}</div>
                            <br />
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.companyAddress}</div>
                        </div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            <img src="../../assets/layout/images/printlogo.png" alt="logo" />
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <hr />
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <div style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '5px', textAlign: 'center' }}>{$.strings.printVacation.vacationRequest}</div>
                            <br />
                        </div>
                    </div>

                    {/* خاص بالموظف */}
                    <div className="p-grid" style={{ border: '1px solid gray', fontSize: '16px', fontWeight: 'bold' }}>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ background: 'lightgray' }}>
                            {$.strings.printVacation.specialForEmployee}
                        </div>

                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            {$.strings.printVacation.empCode + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.empCode}</span>
                        </div>
                        <div className="p-col-7 p-xl-7 p-lg-7 p-md-7 p-sm-7">
                            {$.strings.printVacation.empName + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.empName}</span>
                        </div>
                        <div className="p-col-5 p-xl-5 p-lg-5 p-md-5 p-sm-5" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            {$.strings.printVacation.section + ": "}
                            <span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.sectionName}</span>
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            {$.strings.printVacation.altEmp + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.altEmp}</span>
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" >
                            {$.strings.printVacation.address + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.address}</span>
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            {$.strings.printVacation.phone + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.phone}</span>
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            {$.strings.printVacation.vacType + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.typeName}</span>
                        </div>
                        <div className="p-col-3 p-xl-3 p-lg-3 p-md-3 p-sm-3">
                            {$.strings.printVacation.fromDay + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.fromDay}</span>
                        </div>
                        <div className="p-col-3 p-xl-3 p-lg-3 p-md-3 p-sm-3">
                            {$.strings.printVacation.inDate + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.fromDate}</span>
                        </div>
                        <div className="p-col-3 p-xl-3 p-lg-3 p-md-3 p-sm-3">
                            {$.strings.printVacation.toDay + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.toDay}</span>
                        </div>
                        <div className="p-col-3 p-xl-3 p-lg-3 p-md-3 p-sm-3" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            {$.strings.printVacation.inDate + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.toDate}</span>
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">
                            {$.strings.printVacation.daysCount + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.daysNo + " / " + $.strings.printVacation.day}</span>
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">
                            {$.strings.printVacation.workStart + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.backDay}</span>
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            {$.strings.printVacation.inDate + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.backDate}</span>
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            {$.strings.printVacation.vacNotes + ": "}<span style={{ borderBottom: '1px dashed black' }}>{this.state.dataObject.requestNote}</span>
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            {$.strings.printVacation.empSign + ": "}
                        </div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6">
                            <span >-------------------------------------</span>
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            {$.strings.printVacation.date + ": "}
                            {
                                this.state.dataObject.requestDate &&
                                <span style={{ borderBottom: '1px dashed black' }}>
                                    {this.state.dataObject.requestDate}
                                </span>
                            }
                            {
                                !this.state.dataObject.requestDate &&
                                <span style={{ borderBottom: '1px dashed black' }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            }
                        </div>
                    </div>
                    <br />
                    <br />

                    {/* خاص بالادارة */}
                    <div className="p-grid" style={{ border: '1px solid gray', fontSize: '16px', fontWeight: 'bold' }}>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ background: 'lightgray' }}>
                            {$.strings.printVacation.specialForAdministration}
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Checkbox id="lblAccept" caption={$.strings.printVacation.accept} checked={false} />
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Checkbox InputId="lblReject" caption={$.strings.printVacation.reject} checked={false} />
                        </div>
                        <div className="p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-8">
                            <br />
                            -------------------------------------------------------------------------
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">
                            {$.strings.printVacation.managerSign}
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">

                            ------------------------------------
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            {$.strings.printVacation.date + ": "}<span style={{ borderBottom: '1px dashed black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </div>
                    </div>
                    <br />
                    <br />

                    {/* المتابعة */}
                    <div className="p-grid" style={{ border: '1px solid gray', fontSize: '16px', fontWeight: 'bold' }}>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ background: 'lightgray' }}>
                            {$.strings.printVacation.followUp}
                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Checkbox id="lblAccept" caption={$.strings.printVacation.accept + ": "} checked={false} />

                        </div>
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Checkbox id="lblAccept" caption={$.strings.printVacation.reject + ": "} checked={false} />
                        </div>
                        <div className="p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-8" >
                            <br />
                            -------------------------------------------------------------------------
                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">

                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">

                        </div>
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            {$.strings.printVacation.date + ": "}<span style={{ borderBottom: '1px dashed black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </div>
                    </div>
                </div >
            ) :
            (
                <div id="divMain" style={{ direction: $.strings.dir, padding: '2em', textAlign: 'center' }}>
                    <h1>{$.strings.operationFailed}</h1>
                </div>
            )
    }

    doPrint = () => {
        setTimeout(() => {
            window.print();
        }, 10);
        window.onafterprint = this.onafterprint;

    }
    onafterprint = () => {
        window.close()
    }

    getData = async () => {
        let dataObject = undefined;
        const params = {
            id: this.props.match.params.id
        }

        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetPrintRequest, params, 0);

        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataObject = res.data.data;
            }
        }
        return dataObject;
    }
    //urlGetPrintRequest
}