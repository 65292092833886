import React from "react";
import styles from "./InputTime.module.scss";
import Label from "../../Components/Label";
import * as wjInput from '@grapecity/wijmo.react.input';

export default class InputTime extends React.Component {

    render() {

        return (
            <div className={this.props.containerStyle} style={{ width: this.props.width ? this.props.width : '' }}>
                {this.props.caption && <Label htmlFor={this.props.id} isRequired={this.props.isRequired} autoComplete="off">
                    {this.props.caption}
                </Label>
                }
                <wjInput.InputTime
                    format="HH:mm"
                    min="0:00"
                    max="23:59"
                    step={1}
                    isEditable={true}
                    // valueChanged={e => this.setState({ fromTime: e.value })}
                    style={{ width: '100%' }}
                    {...this.props}
                >
                </wjInput.InputTime>
                {
                    this.props.showError && this.props.errorDescription && this.props.errorDescription.length > 0 &&
                    <div className={styles.errorField} >{this.props.errorDescription}</div>
                }
                {
                    this.props.formErrors && this.props.formErrors[this.props.id] &&
                    <div className={styles.errorField} >{this.props.formErrors[this.props.id]}</div>
                }
            </div>
        );
    }
}

InputTime.defaultProps = {
    showError: false,
    errorDescription: ''
}