import React from "react";
import styles from "./Dialog.module.scss";
import { Dialog as PrimeDialog} from 'primereact/dialog';

export default class Dialog extends React.Component {

    render() {
        return (
            <PrimeDialog ref={this.props.innerRef} className={styles.dialog} {...this.props} focusOnShow={this.props.focusOnShow || false}>
                {this.props.children}
            </PrimeDialog>
        );
    }
}