import * as React from 'react';
//import './styles.css';
// import '../styles.css';

// interface NewsTickerProps {
//     id: number;
//     title: string;
//     url: string;
//     meta: string;
//     icon?: string;
//     iconAlt?: string;
// }

//const NewsTicker: React.FunctionComponent<NewsTickerProps> = (props) => {
export default class NewsTicker extends React.Component {
    render() {
        return (
            <div className={`${"newsticker"} ${"tickerItem"}`} onClick={this.props.onClick}>
                <div className={'icon'}><img src={this.props.icon} alt={this.props.iconAlt} /></div>
                <div className={'newsWrapper'}>
                    <div className={'title'}>{this.props.title}</div>
                    <small className={'meta'}>{this.props.meta}</small>
                </div>
            </div >
        )
    }
}

//export default NewsTicker;