import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import styles from './SigmaPanel.module.scss'

const $ = window.$;

export default class SigmaPanel extends React.Component {

    constructor(props) {
        super(props)
        let options = props.options || {};
        this.state = {
            avg: options.avg,
            count: options.count,
            max: options.max,
            min: options.min,
            sum: options.sum
        }
        this.savedState = { ...this.state }
    }

    render() {
        return (
            <div >
                <Checkbox value="Average" className={styles.checkbox} onChange={this.onChange} checked={this.state.avg} />
                <span className={styles.checkboxLabel}>{$.strings.sigmaOptions.average}</span>
                <br />
                <Checkbox value="Count" className={styles.checkbox} onChange={this.onChange} checked={this.state.count} />
                <span className={styles.checkboxLabel}>{$.strings.sigmaOptions.count}</span>
                <br />
                <Checkbox value="Maximum" className={styles.checkbox} onChange={this.onChange} checked={this.state.max} />
                <span className={styles.checkboxLabel}>{$.strings.sigmaOptions.maximum}</span>
                <br />
                <Checkbox value="Minimum" className={styles.checkbox} onChange={this.onChange} checked={this.state.min} />
                <span className={styles.checkboxLabel}>{$.strings.sigmaOptions.minimum}</span>
                <br />
                <Checkbox value="Sum" className={styles.checkbox} onChange={this.onChange} checked={this.state.sum} />
                <span className={styles.checkboxLabel}>{$.strings.sigmaOptions.sum}</span>
                <br />
                <hr></hr>
                <div className={styles.buttons}>
                    <Button label={$.strings.save} onClick={this.onSave} />
                    <Button label={$.strings.cancel} onClick={this.onCancel} className="p-button-secondary" />
                </div>
            </div>
        )
    }

    undoChanges = () => {
        this.setState(this.savedState)
    }

    onChange = (e) => {
        switch (e.value) {
            case "Average":
                this.setState({ avg: e.checked })
                break;
            case "Count":
                this.setState({ count: e.checked })
                break;
            case "Maximum":
                this.setState({ max: e.checked })
                break;
            case "Minimum":
                this.setState({ min: e.checked })
                break;
            case "Sum":
                this.setState({ sum: e.checked })
                break;
            default:
                break;
        }
    }

    onSave = () => {

        this.savedState = { ...this.state }
        if (this.props.onSave) {
            let options = this.state
            if (options.avg || options.count || options.max || options.min || options.sum)
                this.props.onSave(options)
            else
                this.props.onSave(undefined)
        }
    }

    onCancel = () => {
        this.undoChanges();
        if (this.props.onCancel)
            this.props.onCancel();
    }
}