import React from "react";
import styles from "./MessageBox.module.scss";
import Dialog from "../Dialog";
import Button from "../Button"
import { FaRegCheckCircle, FaRegTimesCircle, FaTimes } from 'react-icons/fa'
const $ = window.$;

export default class MessageBox extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        let footer = (
            <div style={{ textAlign: 'center' }}>
                {
                    this.props.onYes && <Button ref={r => this.btnYes = r} icon="pi pi-check-circle" onClick={this.onYesClick} tooltip={$.strings.yes} >
                        {/* <FaRegCheckCircle /> */}
                    </Button>
                }
                {
                    this.props.toDo && <Button ref={r => this.btnNo = r} icon="pi pi-times-circle" className="p-button-secondary" onClick={this.props.toDo} tooltip={$.strings.no}>
                    </Button>
                }
                {
                    this.props.onCancel && <Button ref={r => this.btnCancel = r} icon="pi pi-times" className="p-button-danger" onClick={this.props.onCancel} tooltip={$.strings.cancel}>
                    </Button>
                }

            </div>
        )
        let headerAlign = $.strings.dir === "rtl" ? 'right' : 'left'
        let header = (
            <div style={{ textAlign: headerAlign }}>
                {$.strings.appName}
            </div>
        )

        return (
            <Dialog visible={this.props.showMsg}
                ref={this.props.innerRef}
                className={styles.dialogMessageBox}
                showHeader={true} header={header}
                footer={footer} modal={true} draggable={true}
                closeOnEscape={true} style={{ width: '400px' }}
                contentStyle={{ minHeight: "50px" }}
                blockScroll={true} autoFocus={true}
                closable={false} onHide={this.props.onCancel} {...this.props}>
                {this.props.messageText || $.strings.dataChanged}
            </Dialog>
        );
    }

    onYesClick = async () => {
        let res = await this.props.onYes()
        if (res) {
            if (this.props.toDo) {
                this.props.toDo()
            }
        }
    }
}