import React from 'react';
import styles from './Users.module.scss';
import DataGrid from '../../../Components/DataGridView/DataGridView';
import ButtonsBarLookupList from '../../../Components/ButtonsBarLookupList';
import Input from '../../../Components/Input';
import Http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import { FaFlag } from 'react-icons/fa';
import Messages from '../../../Components/Messages';
import Util from '../../../Util';

import Card from '../../../Components/Card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import StatusFilter from '../../../Components/SpecialComponents/EditorsAndTemplate/StatusFilter/StatusFilter';
import Print from '../../../Print';
import ResetPassword from './ResetPassword';
import Button from '../../../Components/Button';
const $ = window.$;

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.pageInfo = this.setPageInfo();
    this.doHotKeys = true;
    this.state = {
      loading: true,
      pageInfo: this.pageInfo,
      data: [],
      selectedRows: {},
      activeIndex: 0,
      id: 0,
      StatusFilter: $.strings.active,
      globalFilter: '',
      isDesktop: window.innerWidth > 1250,
      isDesktopStatus: window.innerWidth > 400,
      isDesktopScreenAdd: window.innerWidth > 750,
    };
  }
  componentDidMount = async () => {
    if (!Util.checkISHR()) {
      Util.goTo('/Access');
      return;
    }
    if (!Util.checkIsMainUser()) {
      Util.goTo('/Access');
      return;
    }
    let dataTemp = await this.getData(true);
    this.setState({ data: dataTemp, loading: false });
    window.addEventListener('resize', this.updatePredicate);
    document.addEventListener('keydown', this.onPageKeyDown);
  };
  popupHasCalled = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
  };
  popupHasClosed = () => {
    document.addEventListener('keydown', this.onPageKeyDown);
  };
  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 1250, isDesktopStatus: window.innerWidth > 400, isDesktopScreenAdd: window.innerWidth > 750 });
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
    document.removeEventListener('keydown', this.onPageKeyDown);
  }
  onPageKeyDown = async (e) => {
    if (!this.doHotKeys) return;
    this.doHotKeys = false;
    if (e.ctrlKey && e.keyCode === Util.keyboardKeys.F5) return;
    switch (e.keyCode) {
      case Util.keyboardKeys.F5: {
        e.preventDefault();
        await this.getData(false);
        break;
      }
      case Util.keyboardKeys.F6: {
        e.preventDefault();
        this.btnNewClick(e);
        break;
      }
      default: {
      }
    }
    this.doHotKeys = true;
  };

  render() {
    const gridStyle = { minHeight: this.state.activeIndex === null || this.state.activeIndex === undefined ? '55vh' : '45vh' };
    let header = (
      <div style={{ width: '50%' }}>
        <Input
          innerRef={(el) => (this.txtFilter = el)}
          type="search"
          onInput={(e) => this.setState({ globalFilter: e.target.value })}
          placeholder={$.strings.globalFilter}
          size="50"
        />
      </div>
    );
    return (
      <div ref={(e) => (this.divMain = e)} className={styles.users} style={{ minHeight: '80vh' }}>
        <ProgressSpinner loading={this.state.loading} />
        <div className="p-grid">
          <h2 className={styles.title}>{this.state.pageInfo.title}</h2>
        </div>
        <Card>
          {/*Buttons*/}
          <ButtonsBarLookupList
            onNew={this.btnNewClick}
            onEdit={this.onEdit}
            onRefresh={(e) => this.getData(false)}
            onPrint={this.print}
            onExcelExport={this.onExcelExport}
            pageId={this.state.pageInfo.pageId}
            onErase={this.onErase}
          ></ButtonsBarLookupList>
          <Messages innerRef={(el) => (this.messages = el)} />
        </Card>
        <Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
          <AccordionTab header={$.strings.fillterCaption}>
            {/*filters*/}
            <div className="p-grid">
              <div className="p-col-12 p-xl-12 p-lg-12 p-lg-12 p-sm-12">
                <Messages innerRef={(el) => (this.messages = el)} />
              </div>
            </div>
            <div className="p-grid" id="divFilters">
              <div className="p-lg-8 p-sm-12"></div>
              <div className="p-col-12 p-xl-4 p-lg-4 p-lg-6 p-sm-8">
                <div className="p-grid">
                  <div className="p-lg-12 p-sm-12">
                    <label>{$.strings.status}</label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ alignSelf: 'p-align-end' }}>
                    <StatusFilter showAll={true} showStopped ={true} statusFilter={this.state.StatusFilter} onChange={this.onStatusFilterChanged} />
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        <Card>
          <div className="p-grid">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
              <DataGrid
                style={gridStyle}
                ref={(e) => (this.tblData = e)}
                exportFileName={this.state.pageInfo.title}
                isReport={true}
                theme="default-light"
                idProperty="ser"
                scheme={this.getScheme()}
                dataSource={this.state.data}
                onRowDoubleClick={(e) => this.onRowDoubleClick(e)}
                pageId={this.state.pageInfo.pageId}
              />
             
            </div>
          </div>

          {this.state.dlgResetPasswordVisible && (
                <ResetPassword
                  ref={(ref) => (this.dlgResetPassword = ref)}
                  onSuccess={this.onResetPasswordSuccess}
                  onClose={this.onResetPasswordClose}
                  selectedUserId={this.state.selectedUserId}
                />
              )}
        </Card>
      </div>
    );
  }
  onErase = async () => {
    this.setState({ StatusFilter: $.strings.active }, async () => {
      if (this.tblData) this.tblData.clearFilters();
      await this.getData(false);
    });
  };
  onStatusFilterChanged = (e) => {
    this.setState({ StatusFilter: e.value }, () => this.getData());
  };
  showFilters = (event) => {
    if (event.target.className === 'pi pi-angle-double-down') {
      document.getElementById('divFilters').style.display = '';
      event.target.className = 'pi pi-angle-double-up';
    } else if (event.target.className === 'pi pi-angle-double-up') {
      document.getElementById('divFilters').style.display = 'none';
      event.target.className = 'pi pi-angle-double-down';
    }
  };

  setPageInfo = () => {
    let title;
    title = $.strings.users.title;

    return { title: title };
  };

  onClose = (e) => {
    this.setState({ dlgSearchVisible: false });
  };

  onEdit = () => {
    let selectedRowvalue = this.tblData.getCurrentRowItem();
    if (selectedRowvalue) {
      Util.goTo('UsersAdd/' + selectedRowvalue.id);
    } else Util.showInfoMsg(this.messages, $.strings.selectRow);
  };
  onRowDoubleClick = () => {
    let selectedRowvalue = this.tblData.getCurrentRowItem();
    if (selectedRowvalue) {
      Util.goToNewTab('UsersAdd/' + selectedRowvalue.id);
    } else Util.showInfoMsg(this.messages, $.strings.selectRow);
  };

  getDataForSelection = async (id) => {
    let dataTemp;
    this.setState({ loading: true });
    const params = {
      status: Util.getStatusId(this.state.StatusFilter),
      id: id,
    };

    let res = await Http.getJsonAxios(Http.actions.Users.urlGetUsers, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data._users;
    }
    return dataTemp;
  };

  print = async () => {
    if (!this.state.data || this.state.data.length <= 0) {
      Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
      return;
  }

    let obj = Print.getReportPrintObject(this.state.pageInfo.title, this.tblData, this.state.data);
    if (this.printFilters) {
      if (this.printFilters.StatusFilter) {
        obj.headers.firstHeader.leftText += ' ' + $.strings.status + ': ' + this.printFilters.StatusFilter;
      }
    }
    Print.doPrintReport(obj);
  };


  onExcelExport = async () => {

      this.tblData.excelExport();
    
  };


  getPrintHeader = () => {
    var rightFilter = '';
    var leftFilter = '';
    rightFilter = $.strings.status + ': ' + this.state.StatusFilter;

    var generalSettings = localStorage.getItem('generalSettings');
    if (generalSettings) generalSettings = JSON.parse(generalSettings);

    if (!generalSettings || generalSettings.headerType === 'Image') {
      return (
        <React.Fragment>
          {$.getPrintLogo()}
          <h2 className={styles.title} style={{ textAlign: 'center' }}>
            {this.state.pageInfo.title}
          </h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
            }}
          >
            <span>{rightFilter}</span>
            <span>{leftFilter}</span>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              justifyItems: 'center',
              height: '80px',
            }}
            className={styles.title}
          >
            <h3 style={{ textAlign: 'right' }}>{generalSettings.companyName}</h3>
            <h3 style={{ textAlign: 'right' }}>{generalSettings.companyAddress}</h3>
          </div>
          <hr />
          <h2 className={styles.title} style={{ textAlign: 'center' }}>
            {this.state.pageInfo.title}
          </h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
            }}
          >
            <span>{rightFilter}</span>
            <span>{leftFilter}</span>
          </div>
        </React.Fragment>
      );
    }
  };

  getData = async (fromLoad) => {
    let dataTemp = [];
    if (!fromLoad) this.setState({ loading: true });
    const params = {
      userId:0,
      status: Util.getStatusId(this.state.StatusFilter),
    };

    let res = await Http.getJsonAxios(Http.actions.Users.urlGetUsersInfo, params,0);
    if (res.status === 200) {
      dataTemp = res.data.list;
    }
    if (fromLoad) return dataTemp;
    else {
      this.setState({ data: dataTemp, loading: false });
    }
  };
  activeUser = async (userId) => {
    let dataTemp = true;
    const params = {
      userId:userId,
    };

    let res = await Http.getJsonAxios(Http.actions.Users.urlActiveUser, params,0);
    if (res.status === 200) {
      dataTemp = res.data.success;
    }
    if(!dataTemp)
    {
      Util.showErrorMsg(this.messages,$.strings.operationFailed)
    }
    else
    {
      await this.getData(false);
    }
  };
  btnNewClick = (e) => {
    Util.goTo('/UsersAndRight/UsersAdd/');
  };

  onAddClose = (doRefreh) => {
    if (doRefreh) this.getData(false);
    this.setState({ dlgAddVisible: false });
  };

  onAddSuccess = (result) => {
    if (result) {
      this.setState({ dlgAddVisible: false });
      this.getData(false);
    }
  };

  statusTemplate(rowData, column) {
    let statusColor = rowData.status === 1 ? 'limegreen' : 'red';
    return (
      <>
        <span className="p-column-title">{column.header}</span>
        <span style={{ color: statusColor, fontWeight: 'bold' }}>
          {Util.getStatusName(rowData['status'])} <FaFlag />
        </span>
      </>
    );
  }

  statusIsLockTemplate(rowData) {
    if(rowData.is_locked ===0){
      return (
        <Button icon="pi pi-lock-open" className="p-button-success" tooltip={$.strings.users.userActive} />
  );
}
else {
  return (
    <Button icon="pi pi-lock" className="p-button-danger" tooltip={$.strings.users.userStopped} onClick={this.btn_is_locked_status(rowData)}/>
);
}
  }
  getStatusName(is_locked) {
    switch (is_locked) {
      case 0: {
        return $.strings.active;
      }
      case 1: {
        return $.strings.stopped;
      }
      default: {
        return '';
      }
    }
  }
  resetPassword = async (rowData) => {
    let userId = rowData.id;
    this.popupHasCalled();
    this.setState({ dlgResetPasswordVisible: true, selectedUserId: userId });
  };
  btn_is_locked_status =async (rowData) =>
  {
    let userId = rowData.id;
    await this.activeUser(userId);
  }
  onResetPasswordSuccess = () => {
    this.popupHasClosed();
    this.setState({ dlgResetPasswordVisible: false });
  };
  onResetPasswordClose = () => {
    this.popupHasClosed();
    this.setState({ dlgResetPasswordVisible: false });
  };
  getScheme = () => {
    return {
      name: 'user_tbl',
      filter: true,
      sigma: false,
      scrollable: true,
      showFooter: true,
      showFilter: true,
      responsiveColumnIndex: 4,
      columns: [
        {
          name: 'rowNumber',
          header: '#',
          width: 70,
          columnWidth: '1',
          dataType: 'Number',
          aggregate: 'Cnt',
        },
        {
          name: 'btnResetPass',
          header: ' ',
          width: 50,
          buttonBody: 'button',
          align: 'center',
          title: $.strings.users.resetPassword,
          iconType: 'key',
          className: 'info',
          isReadOnly: true,
          onClick: (e, ctx) => this.resetPassword(ctx.item),
        },
        {
          name: 'id',
          header: 'id',
          visible: false,
          visibleInColumnChooser: false,
        },
        {
          name: 'code',
          header: $.strings.users.code,
          width: 200,
          columnWidth: '2',
        },
        {
          name: 'name',
          header: $.strings.users.name ,
          visible: true,
          visibleInColumnChooser: true,
          width: '*',
          minWidth: 150,
          columnWidth: '*',
        },
        {
          name: 'admin_access',
          header: $.strings.users.admin_access,
          width: 200,
          columnWidth: '2',
          visibleInColumnChooser: true,
        },
        {
          name: 'job',
          header: $.strings.users.job,
          width: 200,
          columnWidth: '2',
          visibleInColumnChooser: true,
        },
        {
          name: 'last_date',
          header: $.strings.users.last_date,
          width: 200,
          columnWidth: '3',
          visibleInColumnChooser: true,
        },
        {
          name: 'is_locked',
          header: $.strings.users.is_locked,
          width: 200,
          columnWidth: '2',
          visible: false,
          visibleInColumnChooser: false,
        },
        // {
        //   name: 'btn_is_locked_status',
        //   header: $.strings.users.is_locked,
        //   width: 70,
        //   buttonBody: 'button',
        //   align: 'center',
        //   title: "",
        //   iconType: "un_lock",
        //   className: 'info',
        //   isReadOnly: true,
        // //  onClick: (e, ctx) => this.btn_is_locked_status(ctx.item),
        // },
        {
          name: 'btn_is_locked_status',
          columnWidth: '2',
          header: $.strings.users.is_locked,
          body: (e) => this.statusIsLockTemplate(e.item),
          width: 70,
        },
        {
          name: 'status',
          header: $.strings.users.status,
          width: 200,
          columnWidth: '2',
          visible: true,
          visibleInColumnChooser: true,
        },
        {
          name: 'email',
          header: $.strings.users.email,
          width: 200,
          columnWidth: '2',
          visible: true,
          visibleInColumnChooser: true,
        },
        {
          name: 'phone',
          header: $.strings.users.phone,
          width: 200,
          columnWidth: '2',
          visible: true,
          visibleInColumnChooser: true,
        },
        {
          name: 'mobile',
          header: $.strings.users.mobile,
          width: 200,
          columnWidth: '2',
          visible: true,
          visibleInColumnChooser: true,
        }
      ],
    };
  };
}
