import React from "react";
import styles from "./Messages.module.scss";
import { Messages as PrimeMessages } from 'primereact/messages';
//import { Growl as PrimeGrowl } from 'primereact/growl';

const $ = window.$;
export default class Messages extends React.Component {

    render() {
        return (
            <PrimeMessages ref={this.props.innerRef} className={styles.messageBar} {...this.props}>
            </PrimeMessages>
            //<PrimeGrowl ref={this.props.innerRef} className={styles.messageBar} {...this.props} position="top-left"/>
        )
    }
}