import React from "react";
import Input from "../../../Components/Input";
import ButtonsBarLookup from '../../../Components/ButtonsBarLookup'
import Messages from '../../../Components/Messages';
import Http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import Util from '../../../Util';
import Card from "../../../Components/Card";
import Dialog from "../../../Components/Dialog";
import SectionsTree from "./SectionsTree";
import SCC from '../../ScreenCommonCode'
import Dropdown from "../../../Components/Dropdown";
import Checkbox from "../../../Components/Checkbox";
import SectionsSearch from "./SectionsSearch";
import SearchEmployees from "../../Employees/SearchEmployees";
import AlertBox from "../../../Components/AlertBox";

const $ = window.$;

export default class SectionsAdd extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            formErrors: {},
            deleteErrors: { deleteNote: '' },
            dataTree: [],
            selectedNodeKey: undefined,
            administrationsList: [],
            classificationsList: [],
            vacationActionsList: [
                { id: 0, name: $.strings.select },
                { id: 1, name: $.strings.sections.allowedWithNotification },
                { id: 2, name: $.strings.sections.notAllowed }
            ],
            dataObject: {
                pos: -1,
                code: '',
                name: '',
                eName: '',
                fatherCode: '',
                fatherName: '',
                managerCode: '',
                managerName: '',
                classification: undefined,
                department: -1,
                administration: '',
                isAdministration: false,
                employeesCount: 0,
                actualEmployeesCount: '',
                vacationEmpMax: 0,
                vacationAction: undefined
            }
        }
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250
        if (!$.license.HR_Module) {
            Util.goTo('/License');
            return;
        }

        this.setState({ dataTree: [], classificationsList: [], loading: false, isDesktop: _isDesktop });
    }

    onNameBlur = (e) => {
        if (!this.state.dataObject.eName)
            this.setState({ dataObject: { ...this.state.dataObject, eName: e.target.value } }, e.target.selectAll);
    }

    popupHasCalled = () => {
        document.removeEventListener('keydown', this.onPageKeyDown);
    }
    popupHasClosed = () => {
        document.addEventListener('keydown', this.onPageKeyDown);
    }

    validateFields() {
        let result = true;
        let fieldsValidationErrors = this.state.formErrors;

        fieldsValidationErrors.code = "";
        if (!this.state.dataObject.code || this.state.dataObject.code <= 0) {
            fieldsValidationErrors.code = $.strings.requiredFiled;
            result = false;
        }

        fieldsValidationErrors.name = "";
        if (!this.state.dataObject.name || this.state.dataObject.name <= 0) {
            fieldsValidationErrors.name = $.strings.requiredFiled;
            result = false;
        }
        fieldsValidationErrors.eName = "";
        if (!this.state.dataObject.eName || this.state.dataObject.eName <= 0) {
            fieldsValidationErrors.eName = $.strings.requiredFiled;
            result = false;
        }

        fieldsValidationErrors.vacationEmpMax = "";
        if (this.state.dataObject.vacationEmpMax === undefined || parseInt(this.state.dataObject.vacationEmpMax) < 0) {
            fieldsValidationErrors.vacationEmpMax = $.strings.requiredFiled;
            result = false;
        }

        fieldsValidationErrors.vacationAction = "";
        if (!this.state.dataObject.vacationAction || this.state.dataObject.vacationAction.id <= 0) {
            fieldsValidationErrors.vacationAction = $.strings.requiredFiled;
            result = false;
        }

        return result;
    }

    render() {

        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={this.state.pageInfo.title} onHide={this.onClose} modal
                    visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '50vw' : '80vw', direction: $.strings.dir }}>
                    <SectionsSearch ref={e => this.dlgSectionsSearch = e} onSuccess={this.onSearchSuccess} />
                    <SectionsSearch ref={e => this.dlgSectionsSearch2 = e} onSuccess={this.onSearchSuccess2} />
                    <SearchEmployees ref={e => this.dlgSearchEmployees = e} onSuccess={this.onSearchEmployeeSuccess} />

                    {
                        this.state.saveBoxVisible &&
                        <AlertBox ref={e => this.saveBox = e} header={$.strings.appName} defaultButton={Util.defaultButton.No} />
                    }

                    {/* {SCC.getScreenHeader_Buttons(this, true)} */}
                    <Card>
                        <ButtonsBarLookup
                            onNew={Util.checkRights(164) ? e => this.onNew(e, true) : undefined}
                            onDelete={Util.checkRights(166) ? e => this.onDelete(e, true) : undefined}
                            onClose={e => this.onClose(e, true)}
                            onSave={this.checkAccessByMode() ? this.onSave : undefined}
                            onPrevious={Util.checkRights(167) ? e => this.onPrevious(e, true) : undefined}
                            onNext={Util.checkRights(167) ? e => this.onNext(e, true) : undefined}
                            onFirst={Util.checkRights(167) ? e => this.onFirst(e, true) : undefined}
                            onLast={Util.checkRights(167) ? e => this.onLast(e, true) : undefined}
                            onSaveThenClose={this.checkAccessByMode() ? e => this.onSaveThenClose(e) : undefined} />
                        <Messages innerRef={(el) => this.messages = el} />
                    </Card>
                    <ProgressSpinner loading={this.state.loading} />

                    <div className="p-grid">
                        {
                            this.state.isDesktop &&
                            <div className="p-col-12 p-lg-4 p-sm-12">
                                <SectionsTree value={this.state.dataTree} itemClicked={this.onItemClicked} />
                            </div>
                        }
                        <div className="p-col-12 p-lg-8 p-sm-12">
                            <Card>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Input
                                            innerRef={(el) => this.txtCode = el}
                                            id="code"
                                            maxLength="50"
                                            value={this.state.dataObject.code}
                                            autofocus={true}
                                            caption={$.strings.sections.code}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                            disabled
                                            withgroup={true}
                                            btn1event={e => this.onSectionsSearch(e, true)}
                                            btn1icon="search"
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <Input
                                            innerRef={e => this.txtName = e}
                                            id="name"
                                            maxLength="50"
                                            value={this.state.dataObject.name}
                                            autofocus={true}
                                            caption={$.strings.sections.name}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            onBlur={this.onNameBlur}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <Input
                                            id="eName"
                                            maxLength="50"
                                            value={this.state.dataObject.eName || ''}
                                            caption={$.strings.sections.eName}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Input
                                            id="fatherCode"
                                            maxLength="50"
                                            value={this.state.dataObject.fatherCode}
                                            autofocus={true}
                                            caption={$.strings.sections.relatedTo}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            withgroup={true}
                                            btn1event={e => this.onSectionsSearch2(e, true)}
                                            btn1icon="search"
                                            onBlur={e => this.getFather()}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-8 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                        <Input
                                            id="fatherName"
                                            maxLength="50"
                                            value={this.state.dataObject.fatherName}
                                            autofocus={true}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Input
                                            id="managerCode"
                                            maxLength="50"
                                            value={this.state.dataObject.managerCode}
                                            autofocus={true}
                                            caption={$.strings.sections.manager}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            withgroup={true}
                                            btn1event={e => this.onEmployeesSearch()}
                                            btn1icon="search"
                                            onBlur={e => this.getManager()}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-8 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                        <Input
                                            id="managerName"
                                            maxLength="50"
                                            value={this.state.dataObject.managerName}
                                            autofocus={true}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Dropdown
                                            id="classification"
                                            innerRef={e => this.drpClassification = e}
                                            value={this.state.dataObject.classification}
                                            options={this.state.classificationsList}
                                            caption={$.strings.sections.classification}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            optionLabel="name"
                                            filter={true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                        <Checkbox id="isAdministration" caption={$.strings.sections.isAdministration}
                                            onChange={(e) => SCC.handleUserCheckbox(this, e)}
                                            checked={this.state.dataObject.isAdministration} />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <Input
                                            id="administration"
                                            maxLength="50"
                                            value={this.state.dataObject.administration}
                                            autofocus={true}
                                            caption={$.strings.sections.administration}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <Input
                                            id="employeesCount"
                                            keyfilter="pint"
                                            maxLength="50"
                                            value={this.state.dataObject.employeesCount}
                                            caption={$.strings.sections.employeesCount}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <Input
                                            id="actualEmployeesCount"
                                            keyfilter="pint"
                                            maxLength="50"
                                            value={this.state.dataObject.actualEmployeesCount}
                                            caption={$.strings.sections.actualEmployeesCount}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <Input
                                            id="vacationEmpMax"
                                            keyfilter="pint"
                                            maxLength="50"
                                            value={this.state.dataObject.vacationEmpMax}
                                            caption={$.strings.sections.vacationEmpMax}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <Dropdown
                                            id="vacationAction"
                                            value={this.state.dataObject.vacationAction}
                                            options={this.state.vacationActionsList}
                                            caption={$.strings.sections.vacationAction}
                                            onChange={(e) => SCC.handleUserInput(this, e)}
                                            optionLabel="name"
                                            filter={true}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }

    checkAccessByMode = () => {
        if (this.state.dataObject.pos >= 0) // update
        {
            return Util.checkRights(165);
        }
        else // insert
        {
            return Util.checkRights(164);
        }
    }

    onItemClicked = (obj) => {
        if (obj && obj.selectedNode) {
            this.navigate(Util.navigationEnum.GETIT, true, obj.selectedNode.dataItem.key)
        }
    }
    onSectionsSearch = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            // Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
            //     async () => { await this.onSave(e, false); this.onSectionsSearch(e, false) },
            //     () => this.onSectionsSearch(e, false)
            // );
            // return;
            this.popupHasCalled();
            this.setState({ saveBoxVisible: true }, () => {
                this.saveBox.show(
                    async () => {
                        let res = await this.onSave(e);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                        return res;
                    },
                    () => {
                        this.onSectionsSearch(e, false);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                    },
                    () => {
                        this.setState({ saveBoxVisible: false });
                        this.popupHasClosed();
                    },
                    $.strings.dataChanged
                );
            })
        }
        this.dlgSectionsSearch.show();
    }
    onSearchSuccess = async (obj) => {
        if (obj) {
            await this.navigate(Util.navigationEnum.GETIT, false, obj.depCode);
        }
    }
    onSectionsSearch2 = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            // Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
            //     async () => { await this.onSave(e, false); this.onSectionsSearch2(e, false) },
            //     () => this.onSectionsSearch(e, false)
            // );
            // return;
            this.popupHasCalled();
            this.setState({ saveBoxVisible: true }, () => {
                this.saveBox.show(
                    async () => {
                        let res = await this.onSave(e);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                        return res;
                    },
                    () => {
                        this.onSectionsSearch2(e, false);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                    },
                    () => {
                        this.setState({ saveBoxVisible: false });
                        this.popupHasClosed();
                    },
                    $.strings.dataChanged
                );
            })
            return;
        }
        this.dlgSectionsSearch2.show();
    }
    onSearchSuccess2 = async (obj) => {
        if (obj) {
            await this.getFather(obj.depCode);
        }
    }
    onEmployeesSearch = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            // Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
            //     async () => { await this.onSave(e, false); this.onEmployeesSearch(e, false) },
            //     () => this.onEmployeesSearch(e, false)
            // );
            this.popupHasCalled();
            this.setState({ saveBoxVisible: true }, () => {
                this.saveBox.show(
                    async () => {
                        let res = await this.onSave(e);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                        return res;
                    },
                    () => {
                        this.onEmployeesSearch(e, false);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                    },
                    () => {
                        this.setState({ saveBoxVisible: false });
                        this.popupHasClosed();
                    },
                    $.strings.dataChanged
                );
            })
            return;
        }
        this.dlgSearchEmployees.show();
    }
    onSearchEmployeeSuccess = (obj) => {
        if (obj) {
            this.setState({ dataObject: { ...this.state.dataObject, managerCode: obj.code, managerName: obj.name } });
        }
    }

    onSave = async (e) => {
        if (this.state.dataObject.pos <= 0 && !Util.checkRights(164)) {
            Util.showErrorMsg(this.messages, $.strings.sections.insertRightsError + " (164) ")
            return;
        }
        if (this.state.dataObject.pos > 0 && !Util.checkRights(165)) {
            Util.showErrorMsg(this.messages, $.strings.sections.editRightsError + " (165) ")
            return;
        }
        this.save(e, true, false);
    };

    onSaveThenClose = async (e) => {
        if (this.state.dataObject.pos < 0 && !Util.checkRights(164)) {
            Util.showErrorMsg(this.messages, $.strings.sections.insertRightsError + " (165) ")
            return;
        }
        if (this.state.dataObject.pos >= 0 && !Util.checkRights(165)) {
            Util.showErrorMsg(this.messages, $.strings.sections.editRightsError + " (165) ")
            return;
        }

        this.save(e, true, true);
    };

    save = async (e, fromSave, closeAfterSave) => {
        //waiting progress
        this.setState({ loading: true });

        //validate
        if (!this.validateFields()) {
            this.setState({ loading: false, showErrors: true });
            return false;
        }

        let isSucceeded = true;
        let result = await this.sendData();

        if (result.status === 200) {
            if (result.data.success) {
                if (closeAfterSave) {
                    if (this.props.onAddSuccess) {
                        this.props.onAddSuccess(true);
                    }
                    this.setState({ visibleDialog: false, loading: false });
                } else {
                    Util.showSuccessMsg(this.messages);
                    if (fromSave) {
                        this.needRefresh = true;
                        this.resetFields();
                    }
                    this.hash = Util.hashState(this.state.dataObject);
                }
            }
            else {
                isSucceeded = false;
                let msg = $.strings.operationFailed;
                if (result.data.errorText && result.data.errorText.length > 0) {
                    msg = result.data.errorText;
                    Util.showErrorMsg(this.messages, msg, false);
                }
            }
        } else {
            isSucceeded = false;
            let msg = $.strings.operationFailed;
            if (result.errorText && result.errorText.length > 0) {
                msg = result.errorText;
                Util.showErrorMsg(this.messages, msg, false);
            }
        }
        this.setState({ loading: false });
        return isSucceeded;
    };

    sendData = async () => {
        let obj = {
            DepCode: this.state.dataObject.code,
            Pos: this.state.dataObject.pos,
            Father: this.state.dataObject.fatherCode,
            DepName: this.state.dataObject.name,
            EName: this.state.dataObject.eName,
            Department: this.state.dataObject.department,
            Classification: this.state.dataObject.classification && this.state.dataObject.classification.pos >= 0 ? this.state.dataObject.classification.pos : -1,
            Users: -1,
            DirectManager: this.state.dataObject.managerCode,
            RequiredEmployees: this.state.dataObject.employeesCount,
            CurrentEmployees: this.state.dataObject.actualEmployeesCount,
            isDepartment: this.state.dataObject.isAdministration,
            VacationEmpMax: this.state.dataObject.vacationEmpMax ? this.state.dataObject.vacationEmpMax : 0,
            VacationAction: this.state.dataObject.vacationAction ? this.state.dataObject.vacationAction.id : 0
        }

        let result
        let res = await Http.postMultipartAxios(Http.actions.Sections.urlPostSaveSection, obj, 0);
        result = res;
        return result;
    };

    onDelete = async (e) => {
        if (this.state.dataObject.pos === undefined || this.state.dataObject.pos < 0) {
            this.setState({ loading: false })
            return false
        }
        Util.confirmDialog($.strings.lookups.deleteConfirm, 'pi pi-question-circle',
            async () => await this.doDelete(e),
            () => { }
        );
        return false
    }

    doDelete = async (e) => {
        this.setState({ loading: true })
        let obj = {
            code: this.state.dataObject.code,
            userId: localStorage.getItem('useridHR') + "",
            isHr: true,
        }

        let res = await Http.getJsonAxios(Http.actions.Sections.urlDeleteSection, obj, 0);

        if (res.status === 200 && res.data.success) {
            Util.showSuccessMsg(this.messages)
            this.resetFields()
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.errorText)
                msg = res.data.errorText;

            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false })
        }
    }

    resetFields = async () => {
        let maxCode = await this.getMaxCode(true);


        this.setState({
            dataObject: {
                pos: -1,
                code: maxCode,
                name: '',
                eName: '',
                fatherCode: '',
                fatherName: '',
                managerCode: '',
                managerName: '',
                classification: undefined,
                isAdministration: false,
                administration: '',
                employeesCount: 0,
                actualEmployeesCount: '',
                vacationEmpMax: 0,
                vacationAction: undefined
            }
        }, () => { this.txtName.focus(); this.hash = Util.hashState(this.state.dataObject); });
    }

    getMaxCode = async (fromLoad) => {
        let code = '';
        if (!fromLoad)
            this.setState({ loading: true });
        const params = {

        };

        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetMaxCode, params, 0);
        if (res.status === 200) {
            code = res.data;
        }
        if (fromLoad)
            return code;
        else {
            this.setState({ dataObject: { ...this.state.dataObject, code: code }, loading: false })
        }
    };

    getManager = async () => {
        if (!this.state.dataObject.managerCode || this.state.dataObject.managerCode.length < 0) {
            this.setState({ dataObject: { ...this.state.dataObject, managerCode: '', managerName: '' }, loading: false });
            return;
        }
        this.setState({ loading: true });
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            isHr: Util.checkISHR(),
            empCode: Util.encryptStringToHexa(this.state.dataObject.managerCode)
        }
        let res = await Http.getJsonAxios(Http.actions.Employee.urlGetEmployeeInfo, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployeeInfoObj;
            if (dataTemp) {
                this.setState({ dataObject: { ...this.state.dataObject, managerCode: dataTemp.Code, managerName: dataTemp.Name }, loading: false });
                return;
            }

            else {
                Util.showInfoMsg(this.messages, $.strings.sections.managerNotFound)
                this.setState({
                    dataObject: {
                        ...this.state.dataObject, managerCode: '', managerName: ''
                    }, loading: false
                })
            }
        }
        else {
            this.setState({ loading: false });
        }
    }

    getFather = async (code) => {
        if (!this.state.dataObject.fatherCode || this.state.dataObject.fatherCode.length <= 0) {
            return;
        }
        this.setState({ loading: true });
        const params = {
            pos: -1,
            depCode: code ? code : this.state.dataObject.fatherCode,
            getDepChild: false,
            classification: -1
        };

        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSectionsList, params, 0);
        if (res.status === 200) {
            if (res.data.list && res.data.list.length > 0) {
                let father = res.data.list[0];
                if (father.isDepartment + "" === "1") {
                    this.setState({
                        dataObject: {
                            ...this.state.dataObject, fatherCode: father.depCode,
                            fatherName: father.depName, department: father.pos, administration: father.depName
                        }, loading: false
                    })
                }
                else {
                    this.setState({
                        dataObject: {
                            ...this.state.dataObject, fatherCode: father.depCode,
                            fatherName: father.depName, department: father.department, administration: father.departmentName
                        }, loading: false
                    })
                }
            }
            else {
                Util.showInfoMsg(this.messages, $.strings.sections.fatherNotFound)
                this.setState({
                    dataObject: {
                        ...this.state.dataObject, fatherCode: '',
                        fatherName: '', department: -1, administration: ''
                    }, loading: false
                })
            }
        }
        else {
            this.setState({ loading: false })
        }
    }

    show = async (code) => {
        let dataTree = await this.getSectionsTree();
        let classificationsList = await this.getClassifications();
        if (code == null || code === undefined || code.length <= 0) {
            // insert
            let maxCode = await this.getMaxCode(true);
            this.setState({
                visibleDialog: true,
                dataTree: dataTree,
                classificationsList: classificationsList,
                loading: false,
                formErrors: {},
                dataObject: {
                    pos: -1,
                    code: maxCode,
                    name: '',
                    eName: '',
                    fatherCode: '',
                    fatherName: '',
                    managerCode: '',
                    managerName: '',
                    classification: undefined,
                    isAdministration: false,
                    administration: '',
                    employeesCount: 0,
                    actualEmployeesCount: ''
                }
            }, () => {
                this.hash = Util.hashState(this.state.dataObject)
                setTimeout(() => {
                    this.txtName.focus()
                }, 10);
            })
        }
        else {
            // update
            this.setState({
                formErrors: {}, visibleDialog: true, dataTree: dataTree,
                classificationsList: classificationsList, loading: false
            },
                async () => await this.navigate(Util.navigationEnum.GETIT, false, code));
        }
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp && dataTemp.length > 0) {
                dataTemp.splice(0, 1);
            }
        }
        return dataTemp
    }
    getClassifications = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 23
        }
        let res = await Http.getJsonAxios(Http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }

    getSectionsTree = async () => {
        //this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await Http.getJsonAxios(Http.actions.Sections.urlGetSectionsTree, obj, 0);

        if (res.status === 200) {
            dataTemp = res.data.listTree;
        }
        return dataTemp
    }

    onNew = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            // Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
            //     async () => await this.onSave(e, false),
            //     () => this.onNew(e, false)
            // );
            this.popupHasCalled();
            this.setState({ saveBoxVisible: true }, () => {
                this.saveBox.show(
                    async () => {
                        let res = await this.onSave(e);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                        return res;
                    },
                    () => {
                        this.onNew(e, false);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                    },
                    () => {
                        this.setState({ saveBoxVisible: false });
                        this.popupHasClosed();
                    },
                    $.strings.dataChanged
                );
            })
            return;
        }
        this.messages.clear();
        this.resetFields()
    }

    onClose = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            // Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
            //     async () => await this.onSaveThenClose(e, false),
            //     () => this.onClose(e, false)
            // );
            this.popupHasCalled();
            this.setState({ saveBoxVisible: true }, () => {
                this.saveBox.show(
                    async () => {
                        let res = await this.onSave(e);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                        return res;
                    },
                    () => {
                        this.onClose(e, false);
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                    },
                    () => {
                        this.setState({ saveBoxVisible: false });
                        this.popupHasClosed();
                    },
                    $.strings.dataChanged
                );
            })
            return;
        }

        let doRefresh = this.needRefresh
        if (this.props.onAddClose) {
            this.props.onAddClose(doRefresh);
        }
        this.setState({ visibleDialog: false, loading: false }, () => this.hash = Util.hashState(this.state.dataObject))
    }

    onFirst = (e, checkChanging) => {
        this.navigate(Util.navigationEnum.FIRST, checkChanging, '');
    }

    onPrevious = (e, checkChanging) => {
        this.navigate(Util.navigationEnum.PREVIOUS, checkChanging, '');
    }

    onNext = (e, checkChanging) => {
        this.navigate(Util.navigationEnum.NEXT, checkChanging, '');
    }

    onLast = (e, checkChanging) => {
        this.navigate(Util.navigationEnum.LAST, checkChanging, '');
    }

    navigate = async (navigationType, checkChanging, specificCode) => {
        //check fields changing        
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            this.popupHasCalled();
            this.setState({ saveBoxVisible: true }, () => {
                this.saveBox.show(
                    async () => {
                        let res = await this.onSave();
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                        return res;
                    },
                    () => {
                        this.popupHasClosed();
                        this.setState({ saveBoxVisible: false });
                        switch (navigationType) {
                            case Util.navigationEnum.PREVIOUS:
                                this.onPrevious(undefined, false);
                                break;
                            case Util.navigationEnum.NEXT:
                                this.onNext(undefined, false);
                                break;
                            case Util.navigationEnum.FIRST:
                                this.onFirst(undefined, false);
                                break;
                            case Util.navigationEnum.LAST:
                                this.onLast(undefined, false);
                                break;
                            default: {
                                break;
                            }
                        }
                    },
                    () => {
                        this.setState({ saveBoxVisible: false });
                        this.popupHasClosed();
                    },
                    $.strings.dataChanged
                );
            })
            return;
        }
        this.setState({ loading: true });
        //determind the id
        let code = 0;
        if (
            navigationType === Util.navigationEnum.PREVIOUS ||
            navigationType === Util.navigationEnum.NEXT
        ) {
            if (this.state.dataObject.code)
                code = this.state.dataObject.code;
            else {
                if (navigationType === Util.navigationEnum.PREVIOUS)
                    navigationType = Util.navigationEnum.FIRST;
                else
                    navigationType = Util.navigationEnum.LAST;
            }
        }
        if (navigationType === Util.navigationEnum.LAST) code = 'ZZZZZZ';
        if (navigationType === Util.navigationEnum.GETIT) code = specificCode;

        let param = {
            pos: -1,
            depCode: code,
            getDepChild: false,
            classification: -1
        };

        let url = "";
        switch (navigationType) {
            case Util.navigationEnum.PREVIOUS:
                url = Http.actions.Sections.urlGetSectionPrev;
                break;
            case Util.navigationEnum.NEXT:
                url = Http.actions.Sections.urlGetSectionNext;
                break;
            case Util.navigationEnum.FIRST:
                url = Http.actions.Sections.urlGetSectionFirst;
                break;
            case Util.navigationEnum.LAST:
                url = Http.actions.Sections.urlGetSectionLast;
                break;
            case Util.navigationEnum.GETIT:
            default: {
                url = Http.actions.Sections.urlGetSectionsList;
                break;
            }
        }

        let res = await Http.getJsonAxios(url, param, 0);
        let result = false;
        if (res.status === 200) {
            if (res.data.success) {
                result = true;
                if (res.data.list === null) {
                    if (navigationType === Util.navigationEnum.PREVIOUS)
                        Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
                    if (navigationType === Util.navigationEnum.NEXT)
                        Util.showInfoMsg(this.messages, $.strings.recordsEnds);
                    this.setState({ loading: false });
                } else {

                    let obj;
                    if (res.data.list && res.data.list.length > 0) {
                        obj = res.data.list[0];
                    }
                    this.display(obj);
                }
            }
        }
        if (!result) {
            Util.showInfoMsg(this.messages, $.strings.operationFailed);
            this.setState({ loading: false });
        }
    }
    display = (data) => {
        if (!data) {
            return;
        }

        if (this.messages)
            this.messages.clear();
        this.setState(
            {
                dataObject: {
                    ...this.state.dataObject,
                    pos: data.pos,
                    code: data.depCode,
                    name: data.depName,
                    eName: data.ename,
                    fatherCode: data.fatherCode,
                    fatherName: data.fatherName,
                    managerCode: data.directMangerCode,
                    managerName: data.directMangerName,
                    classification: data.classification,
                    isAdministration: data.isDepartment + "" === "1" ? true : false,
                    administration: '',
                    employeesCount: data.requiredEmployees,
                    actualEmployeesCount: data.currentEmployees,
                    vacationEmpMax: data.VacationEmpMax,
                    vacationAction: this.state.vacationActionsList.find(e => e.id + "" === data.VacationAction + "")
                },
                formErrors: {},
                visibleDialog: true,
                loading: false
            }, () => {
                this.hash = Util.hashState(this.state.dataObject)
                setTimeout(() => {
                    this.txtName.focus()
                }, 10);
            }
        );
    };

    setPageInfo = () => {
        let pageId
        let title = $.strings.sections.title;

        return { pageId: pageId, title: title };
    };

    onNodeSelect = (e) => {
        this.setState({ selectedNode: e.node })
    }
}