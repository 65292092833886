import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './Screens/Home/Home';
import Memos from './Screens/Memos/Memos';
import SpecialLeave from './Screens/SpecialLeave';
import OverTime from './Screens/OverTime';
import Vacations from './Screens/Vacations';
import FingerPrint from './Screens/FingerPrint';
import WorkLeave from './Screens/WorkLeave';
import WorkDetails from './Screens/WorkDetails/WorkDetails';
import VacLessDay from './Screens/VacLessDay';
import ExpectedVacations from './Screens/ExpectedVacation/ExpectedVacations';
import HandleRequests from './Screens/HandleRequests/HandleRequests';
import HandleNotifications from './Screens/HandleRequests/HandleNotifications';

import EmployeeInfo from './Screens/Employees/EmployeeInfo';
import MyStaff from './Screens/Employees/MyٍStaff';
import VacBalance from './Screens/Vacations/VacBalances';
import EmpDelegation from './Screens/EmpDelegations/EmpDelegations';
import EmpDelegationList from './Screens/EmpDelegations/EmpDelegationsList';
import SalarySlip from './Screens/SalarySlip/SalarySlip';
import AttReport from './Screens/AttReports/AttReport';
import InOutTotalReport from './Screens/AttReports/InOutTotalReport';
import TotalInOutTransReport from './Screens/AttReports/TotalInOutTransReport';
import AttendanceReport from './Screens/AttReports/AttendanceReport';
import WorkAndVacationsTransRpt from './Screens/AttReports/WorkAndVacationsTransRpt';
import RequestsRpt from './Screens/AttReports/RequestsRpt';
import Absence from './Screens/AttReports/Absence';
import MemosReport from './Screens/AttReports/MemosReport';
import PersonalHolidayRpt from './Screens/AttReports/PersonalHolidayRpt';
import GeneralHolidayRpt from './Screens/AttReports/GeneralHolidayRpt';
import DailyAttendanceRpt from './Screens/AttReports/DailyAttendanceRpt';
import NoFingerPrintRpt from './Screens/AttReports/NoFingerPrintRpt';
import MailHandle from './Screens/HandleRequests/MailHandle';
import Util from './Util';
import EndServicesBalance from './Screens/Reports/EndServicesBalance';
import Loans from './Screens/Reports/Loans';
import ClockTransRpt from './Screens/ClockTrans/ClockTransRpt';
import NewsRpt from './Screens/News/NewsReport';
import Dashboards from './Screens/Reports/Dashboards';
import SeenNews from './Screens/News/SeenNews';
import LoginsReport from './Screens/Reports/LoginsReports';
import WFTransReport from './Screens/WFTrans/WFTransReport';
import ExpectedVacationRpt from './Screens/AttReports/ExpectedVacationRpt';
import OrgChart from './Screens/Reports/OrgChart';
import MessagesReport from './Screens/Reports/MessagesReport';
import NewsAdd from './Screens/News/News';
import SendMessages from './Screens/SendMessages/SendMessages';
import Substitute from './Screens/FilesAndSettings/Substitute';
import NotificationsSettings from './Screens/FilesAndSettings/NotificationsSettings';
import SystemSettings from './Screens/SystemSettings/SystemSettings';
import Workflow from './Screens/FilesAndSettings/Workflow';
import GeneralLookups from './Screens/Lookups/GeneralLookups/GeneralLookups';
import Sections from './Screens/FilesAndSettings/Sections/Sections';
import Periods from './Screens/Shifts/Periods/Periods';
import Shifts from './Screens/Shifts/Shifts/Shifts';
import ShiftsWeeklyList from './Screens/Shifts/ShiftsWeeklyList/ShiftsWeeklyList';
import EmployeesDayShift from './Screens/Shifts/EmployeesDayShift/EmployeesDayShift';
import ShiftsList from './Screens/Shifts/ShiftsList/ShiftsList';
import GeneralShiftsDistribute from './Screens/Shifts/Shifts/GeneralShiftsDistribute';
import PeriodShiftsDistribute from './Screens/Shifts/Shifts/PeriodShiftsDistribute';
import MonthlyEmployeeDelayRpt from './Screens/AttReports/MonthlyEmployeeDelayRpt';
import ReportsArchiving from './Screens/Reports/ReportsArchiving';
import OverTimeBalances from './Screens/Reports/OverTimeBalances';
import SpecialLeavesBalancesProcess from './Screens/Reports/SpecialLeavesBalancesProcess';
import EmployeeTimeCommitRpt from './Screens/Reports/EmployeeTimeCommitRpt';
import GroupVacation from './Screens/Reports/GroupVacation';
import SalariesPerDay from './Screens/Reports/SalariesPerDay';
import RecalculateBalances from './Screens/Reports/RecalculateBalances';
import MonthlySummaryReport from './Screens/Reports/MonthlySummaryReport';
import ReportsPrinting from './ReportsPrinting';
import NotificationsReport from './Screens/Reports/NotificationsReport';
import Users from './Screens/UsersAndAccessRights/Users/Users';
import UsersAdd from './Screens/UsersAndAccessRights/Users/UsersAdd';
import RolesList from './Screens/UsersAndAccessRights/Groups/RolesList';
import PermissionsRolesAccess from './Screens/UsersAndAccessRights/PermissionsRolesAccess/PermissionsRolesAccess';
import PermissionsUsersAccess from './Screens/UsersAndAccessRights/PermissionsUsersAccess/PermissionsUsersAccess';
import RequestsAliases from './Screens/Lookups/RequestsAliases/RequestsAliases';
import ImportEmployees from './Screens/FilesAndSettings/ImportEmployees/ImportEmployees';

export default class Routes extends React.Component {
    render() {

        return (
            <Switch>
                <Route exact path='/' component={Util.checkISHR() ? HandleRequests : Home} />

                <Route exact path='/Requests/Memos/:id?' component={Memos} />
                <Route exact path='/Requests/Memos/:empCode?/:empName?' component={Memos} />
                <Route exact path='/Requests/SpecialLeave/:id?' component={SpecialLeave} />
                <Route exact path='/Requests/SpecialLeave/:empCode?/:empName?' component={SpecialLeave} />
                <Route exact path='/Requests/OverTime/:id?' component={OverTime} />
                <Route exact path='/Requests/OverTime/:empCode?/:empName?' component={OverTime} />
                <Route exact path='/Requests/Vacations/:id?' component={Vacations} />
                <Route exact path='/Requests/Vacations/:empCode?/:empName?' component={Vacations} />
                <Route exact path='/Requests/VacLessDay/:id?' component={VacLessDay} />
                <Route exact path='/Requests/FingerPrint/:id?' component={FingerPrint} />
                <Route exact path='/Requests/FingerPrint/:empCode?/:empName?' component={FingerPrint} />
                <Route exact path='/Requests/WorkLeave/:id?' component={WorkLeave} />
                <Route exact path='/Requests/WorkLeave/:empCode?/:empName?' component={WorkLeave} />
                <Route exact path='/Requests/ExpectedVacations/:id?' component={ExpectedVacations} />


                <Route exact path='/HandleRequests' component={HandleRequests} />
                <Route exact path='/HandleNotifications' component={HandleNotifications} />
                <Route exact path='/MyStaff' component={MyStaff} />
                <Route exact path='/EmpDelegationList' component={EmpDelegationList} />
                <Route exact path='/EmpDelegation/Delegate/:id?' component={EmpDelegation} />

                <Route exact path='/Reports/WorkDetails' component={WorkDetails} />
                <Route exact path='/Reports/EmpInfo/:id?' component={EmployeeInfo} />
                <Route exact path='/Reports/VacBalance/:id?' component={VacBalance} />
                <Route exact path='/Reports/SalarySlip/:id?' component={SalarySlip} />

                <Route exact path='/Reports/AttReport' component={AttReport} />
                <Route exact path='/Reports/InOutTotalReport' component={InOutTotalReport} />
                <Route exact path='/Reports/TotalInOutTransReport' component={TotalInOutTransReport} />
                <Route exact path='/Reports/AttendanceReport/:id?' component={AttendanceReport} />
                <Route exact path='/Reports/WorkAndVacationsTransRpt' component={WorkAndVacationsTransRpt} />
                <Route exact path='/Reports/RequestsRpt' component={RequestsRpt} />
                <Route exact path='/Reports/Absence' component={Absence} />
                <Route exact path='/Reports/MemosRpt' component={MemosReport} />
                <Route exact path='/Reports/PersonalHolidayRpt' component={PersonalHolidayRpt} />
                <Route exact path='/Reports/GeneralHolidayRpt' component={GeneralHolidayRpt} />
                <Route exact path='/Reports/DailyAttendanceRpt' component={DailyAttendanceRpt} />
                <Route exact path='/Reports/NoFingerPrintRpt' component={NoFingerPrintRpt} />
                <Route exact path='/Reports/EndServicesBalance' component={EndServicesBalance} />
                <Route exact path='/Reports/Loans' component={Loans} />
                <Route exact path='/Reports/ClockTransRpt/:id?' component={ClockTransRpt} />
                <Route exact path='/Reports/NewsRpt' component={NewsRpt} />
                <Route exact path='/Reports/SeenNews' component={SeenNews} />
                <Route exact path='/Reports/Dashboards' component={Dashboards} />
                <Route exact path='/Reports/LoginsReport' component={LoginsReport} />
                <Route exact path='/Reports/WFTransReport' component={WFTransReport} />
                <Route exact path='/Reports/ExpectedVacationReport' component={ExpectedVacationRpt} />
                <Route exact path='/Reports/OrgChart' component={OrgChart} />
                <Route exact path='/Reports/NotificationsReport' component={NotificationsReport} />
                <Route exact path='/Reports/MessagesReport' component={MessagesReport} />
                <Route exact path='/Reports/MonthlyEmployeeDelays' component={MonthlyEmployeeDelayRpt} />
                <Route exact path='/Reports/ReportsArchiving' component={ReportsArchiving} />
                <Route exact path='/Reports/OverTimeBalances' component={() => <OverTimeBalances reportType="OverTimeBalance" />} />
                <Route exact path='/Reports/SpecialLeavesBalances' component={() => <OverTimeBalances reportType="SpecialLeavesBalance" />} />
                <Route exact path='/Reports/SpecialLeavesBalancesProcess' component={SpecialLeavesBalancesProcess} />
                <Route exact path='/Reports/ُEmployeeTimeCommitmentRpt' component={EmployeeTimeCommitRpt} />
                <Route exact path='/Reports/GroupVacation' component={GroupVacation} />
                <Route exact path='/Reports/SalariesPerDay' component={SalariesPerDay} />
                <Route exact path='/Reports/RecalculateBalances' component={RecalculateBalances} />
                <Route exact path='/Reports/MonthlySummaryReport' component={MonthlySummaryReport} />

                <Route exact path='/NewsAdd/:id?' component={NewsAdd} />
                <Route exact path='/SendMessages' component={SendMessages} />
                <Route exact path='/Substitute' component={Substitute} />
                <Route exact path='/NotificationsSettings' component={NotificationsSettings} />
                <Route exact path='/SystemSettings' component={SystemSettings} />
                <Route exact path='/WorkFlow' component={Workflow} />

                <Route exact path='/Lookups/Branches' component={() => <GeneralLookups lookupType="7" />} />
                <Route exact path='/Lookups/Departments' component={() => <GeneralLookups lookupType="8" />} />
                <Route exact path='/Lookups/Jobs' component={() => <GeneralLookups lookupType="3" />} />
                <Route exact path='/Lookups/EmployeesClass' component={() => <GeneralLookups lookupType="12" />} />
                <Route exact path='/Lookups/Classifications' component={() => <GeneralLookups lookupType="25" />} />
                <Route exact path='/Lookups/SectionsClass' component={() => <GeneralLookups lookupType="23" />} />
                <Route exact path='/Lookups/ShiftTransTypes' component={() => <GeneralLookups lookupType="22" />} />
                <Route exact path='/Lookups/PaidVacTypes' component={() => <GeneralLookups lookupType="4" />} />
                <Route exact path='/Lookups/ClockList' component={() => <GeneralLookups lookupType="11" />} />
                <Route exact path='/Lookups/InternalMemosTypes' component={() => <GeneralLookups lookupType="26" />} />
                <Route exact path='/Lookups/ShiftPeriodsTypes' component={() => <GeneralLookups lookupType="30" />} />
                <Route exact path='/Lookups/Documents' component={() => <GeneralLookups lookupType="29" />} />

                <Route exact path='/Files/Sections' component={Sections} />

                <Route exact path='/Shifts/Periods' component={Periods} />
                <Route exact path='/Shifts/Shifts' component={Shifts} />
                <Route exact path='/Shifts/ShiftsWeeklyList' component={ShiftsWeeklyList} />
                <Route exact path='/Shifts/EmployeesDayShift' component={EmployeesDayShift} />
                <Route exact path='/Shifts/ShiftsList' component={ShiftsList} />
                <Route exact path='/Shifts/GeneralShiftsDistribute' component={GeneralShiftsDistribute} />
                <Route exact path='/Shifts/PeriodShiftsDistribute' component={PeriodShiftsDistribute} />

                <Route exact path='/Reports/ReportsPrinting' component={ReportsPrinting} />
                <Route exact path='/Lookups/RequestsAliases' component={RequestsAliases} />

                <Route exact path='/UsersAndRight/Users' component={Users} />
                <Route exact path='/UsersAndRight/UsersAdd/:id?' component={UsersAdd} />
                <Route exact path='/UsersAndRight/RolesList' component={RolesList} />
                <Route exact path='/UsersAndRight/PermissionsRolesAccess/:id?' component={PermissionsRolesAccess} />
                <Route exact path='/UsersAndRight/PermissionsUsersAccess/:id?' component={PermissionsUsersAccess} />
                <Route exact path='/Files/ImportEmployees' component={ImportEmployees} />
            </Switch>
        )
    }
}