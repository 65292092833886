import React from 'react';
import styles from './PermissionsUsersAccess.module.scss';
import ButtonsBarLookup from '../../../Components/ButtonsBarLookup';
import Input from '../../../Components/Input';
import RadioButton from '../../../Components/RadioButton';
import http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import Messages from '../../../Components/Messages';
import Util from '../../../Util';
import Button from '../../../Components/Button';
import Card from '../../../Components/Card';
import Dropdown from '../../../Components/Dropdown';
//import GroupsSearch from '../../UsersAndAccessRights/PermissionsListGroups/GroupsSearch';
import UsersSearch from '../../UsersAndAccessRights/Users/UsersSearch';
import MultiSelect from '../../../Components/MultiSelect';
import * as wjcCore from '@grapecity/wijmo';
import DataGrid from '../../../Components/DataGridView/DataGridView';
import EditRights from '../EditRights';
import AlertBox from '../../../Components/AlertBox';
import Print from '../../../Print';

const $ = window.$;

export default class PermissionsUsersAccess extends React.Component {
  constructor(props) {
    super(props);
    this.doHotKeys = true;
    this.systemList = Util.getSystemOptions();
    this.copyTypeList = this.getCopyType();
    this.hash = null;
    this.state = {
      loading: true,
      pageInfo: this.setPageInfo(),
      systemList: this.systemList,
      mainRoleList: this.mainRoleList,
      copyTypeList: this.copyTypeList,
      system: undefined,
      data: [],
      visibleData: [],
      dataAccessCategoties: [],
      selectedRows: {},
      id: 0,
      statusFilter: $.strings.all,
      localFilter: '',
      userId: -1,
      row_number:-1,
      userCopyId: -1,
      isDesktop: true,
    };
  }

  componentDidMount = async () => {
    if (!Util.checkISHR()) {
      Util.goTo('/Access');
      return;
    }
    if (!Util.checkIsMainUser()) {
      Util.goTo('/Access');
      return;
    }
    let _isDesktop = this.updatePredicate();
    let mainRolesListPromise = this.GetCAccessGroups();
    let dataMainRoles = await mainRolesListPromise;
    let roleOptionPromise = this.getRolesOptionList();
    let dataRoleOptions = await roleOptionPromise;
    let roleValue = dataRoleOptions && dataRoleOptions.length > 0 ? dataRoleOptions[0] : undefined;
    if (this.props.match.params.id && this.props.match.params.id > 0) {
      roleValue = dataRoleOptions.find((e) => e.id + '' === this.props.match.params.id + '');
    }
    this.setState(
      {
        isDesktop: _isDesktop,
        loading: false,
        mainRoleList: dataMainRoles,
        mainRole: dataMainRoles && dataMainRoles.length > 0 ? dataMainRoles[0] : undefined,
        roleOptionList: dataRoleOptions,
        roleOption: roleValue,
      }
    );
    await this.getUsers();
    if (this.props.match.params.id) {
      this.setState({ userName: '', userId: this.props.match.params.id, usersSearchVisible: false }, () => {
        this.getUserRole();
        this.getData(false);
      });
    }
  };

  onPageKeyDown = async (e) => {
    if (e.ctrlKey && e.keyCode === Util.keyboardKeys.F5) return;
    if (!this.doHotKeys) return;
    this.doHotKeys = false;
    switch (e.keyCode) {
      case Util.keyboardKeys.F3: {
        e.preventDefault();
        if (!this.state.fromArchive) {
          await this.onSave(false);
        }
        break;
      }
      case Util.keyboardKeys.F5: {
        e.preventDefault();
        await this.getData(false);
        break;
      }
      default: {
      }
    }
    this.doHotKeys = true;
  };
  GetCAccessGroups = async () => {
    let dataTemp = [];
    const params = {
      id: -1,
    };

    let res = await http.getJsonAxios(http.actions.Roles.urlGetCAccessGroups, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
      dataTemp.splice(0, 0, {
        id: -1,
        name: $.strings.all,
        name_lang2: $.strings.all,
      });
    }
    return dataTemp;
  };
  getRolesOptionList = async () => {
    let dataTemp;
    const params = {
      id :-1
    };
    let res = await http.getJsonAxios(http.actions.Roles.urlGetRoles, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
    }
    return dataTemp;
  };
  getSubRole = async (mainRole) => {
    let dataTemp;
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    if (mainRole.id === -1) {
      this.setState({ otherRoles: undefined, otherRolesList: [] });
      return;
    }
    const params = {
      id: mainRole.id,
    };
    let res = await http.getJsonAxios(http.actions.Roles.urlGetSubRoles, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
      this.setState(
        {
          otherRolesList: dataTemp,
          otherRoles: dataTemp && dataTemp.length > 0 ? dataTemp[0] : undefined,
        },
        () => {
          Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
        }
      );
    }
  };
  searchUsers = (e, checkChanging) => {
    this.setState({ usersSearchVisible: true });
  };
  onUsersSearchOk = (obj) => {
    let selectedUser = obj.Name;
    this.setState({ userName: selectedUser, userId: obj.ID,row_number:obj.RowNumber, usersSearchVisible: false }, () => {
      this.getUserRole();
     this.getData(false);
    });
  };
  onUsersSearchClose = () => {
    this.setState({ usersSearchVisible: false });
  };

  getUserRole = async () => {
    let dataTemp = [];
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    const params = {
      userId: this.state.userId,
    };
    let res = await http.getJsonAxios(http.actions.Users.urlGetUserGroups, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
    }
    let rolesNames = dataTemp.map((e) => e.role_name).join(', ');
    if (rolesNames === ', ') rolesNames = '';
    this.setState({ userRole: rolesNames }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
  };

  searchCopyUsers = (e) => {
    //this.dlgUsersCopySearch.show();
    this.setState({ usersCopySearchVisible: true });
  };
  onUsersCopySearchOk = (obj) => {
    let selectedUser = obj.Name;
    this.setState({ userNameCopyFrom: selectedUser, userCopyId: obj.ID, usersCopySearchVisible: false });
  };

  onUsersCopySearchClose = () => {
    this.setState({ usersCopySearchVisible: false });
  };

  searchMainRole = (e) => {
    //this.dlgMainRole.show();
    this.setState({ mainRoleVisible: true, mainRoleId: undefined });
  };
  searchOtherRole = (e) => {
    let mainId = !this.state.mainRole ? 0 : this.state.mainRole.id;
    if (mainId && mainId > 0) {
      //this.dlgOtherRole.show(mainId);
      this.setState({ mainRoleId: mainId, otherRoleVisible: true });
    }
  };
  onMainRoleSearchOk = (obj) => {
    let selectedValue = this.state.mainRoleList.filter((element) => element.id === obj.id);
    if (selectedValue && selectedValue.length > 0) {
      selectedValue = selectedValue[0];
    }
    this.setState({ mainRole: selectedValue });
  };
  onMainRoleSearchClose = () => {
    this.setState({ mainRoleVisible: false });
  };

  onOtherRoleSearchOk = (obj) => {
    let selectedValue = this.state.otherRolesList.filter((element) => element.id === obj.id);
    if (selectedValue && selectedValue.length > 0) {
      selectedValue = selectedValue[0];
    }
    this.setState({ otherRole: selectedValue });
  };
  onOtherRoleSearchClose = () => {
    this.setState({ otherRoleVisible: false });
  };
  handleMainRole = (e) => {
    const value = e.target.value;
    this.setState({ mainRole: value }, () => this.getSubRole(value));
  };

  updatePredicate = () => {
    return window.innerWidth > 1250;
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  onSearchLocalData = (fillter) => {
    if (fillter && fillter.length > 0) {
      let temp = this.state.data;
      if (temp && temp.length > 0) {
        let newData = temp.filter(
          (e) => e.access_name.includes(fillter.trim()) || e.keywords.includes(fillter.trim()) || e.access_name_lang2.includes(fillter.trim())
        );
        this.setState({ visibleData: newData });
      }
    } else {
      this.setState({ visibleData: this.state.data });
    }
  };

  handleUserInputAccessRights = (id, is_granted, role_is_granted) => {
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;
    let _data = this.state.data;
    _data = _data.map((el) =>
      el.access_id + '' === id + ''
        ? {
          ...el,
          is_granted: is_granted,
          is_granted_name: is_granted ? $.strings.PermissionsRolesAccess.yes : $.strings.PermissionsRolesAccess.no,
          role_is_granted: role_is_granted,
          role_is_granted_name: role_is_granted ? $.strings.PermissionsRolesAccess.yes : $.strings.PermissionsRolesAccess.no,
        }
        : el
    );
    this.setState({ data: _data, editRightsVisible: false }, () => {
      Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      this.popupHasClosed();
    });
  };

  handleUserInputAccessRightsClose = () => {
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;
    this.setState({ editRightsVisible: false, id: undefined, is_granted: undefined, role_is_granted: undefined }, () => {
      Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      this.popupHasClosed();
    });
  };

  render() {
    const gridStyle = { minHeight: '80vh' };

    return (
      <div ref={(e) => (this.divMain = e)} className={styles.PermissionsUsersAccess}>
        <ProgressSpinner loading={this.state.loading} />
        <div className="p-grid">
          <h2 className={styles.title}>{this.state.pageInfo.title}</h2>
        </div>
        {/* {this.state.mainRoleVisible && (
          <GroupsSearch ref={(ref) => (this.dlgMainRole = ref)} onSearchSuccess={this.onMainRoleSearchOk} onClose={this.onMainRoleSearchClose} />
        )}
        {this.state.otherRoleVisible && (
          <GroupsSearch ref={(ref) => (this.dlgOtherRole = ref)} onSearchSuccess={this.onOtherRoleSearchOk} onClose={this.onOtherRoleSearchClose} />
        )} */}
        {this.state.usersSearchVisible && (
          <UsersSearch ref={(ref) => (this.dlgUsersSearch = ref)} onSearchSuccess={this.onUsersSearchOk} onClose={this.onUsersSearchClose} />
        )}
        {this.state.usersCopySearchVisible && (
          <UsersSearch
            ref={(ref) => (this.dlgUsersCopySearch = ref)}
            onSearchSuccess={this.onUsersCopySearchOk}
            onClose={this.onUsersCopySearchClose}
          />
        )} 

        {this.state.saveBoxVisible && <AlertBox ref={(e) => (this.saveBox = e)} header={$.strings.appName} defaultButton={Util.defaultButton.No} />}

        <Card>
          {/*Buttons*/}
          <div className="p-grid">
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
              <ButtonsBarLookup
                onSave={(e) => this.onSave(e, true)}
                onPrevious={(e) => this.onPrevious(e, true)}
                onNext={(e) => this.onNext(e, true)}
                onRefresh={(e) => this.onRefresh(e, true)}
                onPrint={(e) => this.onPrint(e, true)}
                onExcelExport={(e) => this.onExcelExport(e, true)}
                pageId={this.state.pageInfo.pageId}
                parent={this}
              />
              <Messages innerRef={(el) => (this.messages = el)} />
            </div>
          </div>
        </Card>

        {this.state.editRightsVisible && (
          <EditRights
            ref={(e) => (this.dlgEditRights = e)}
            id={this.state.id}
            is_granted={this.state.is_granted}
            role_is_granted={this.state.role_is_granted}
            with_granted={this.state.with_granted}
            onSuccess={this.handleUserInputAccessRights}
            onClose={this.handleUserInputAccessRightsClose}
          />
        )} 

        <div className="p-grid">
          <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
            <Card>
              {/* User Card */}
              <div className="p-grid">
                <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                  <Input
                    innerRef={(el) => (this.txtUserName = el)}
                    type="text"
                    id="userName"
                    maxLength="100"
                    value={this.state.userName || ''}
                    autofocus={true}
                    onChange={this.handleUserInput}
                    onBlur={this.onNameBlur}
                    isRequired={false}
                    disabled={true}
                    caption={$.strings.PermissionsUsersAccess.userName}
                    formErrors={this.state.formErrors}
                    withgroup={true}
                    btn1icon="search"
                    btn1event={(e) => this.searchUsers(e, true)}
                  />
                </div>
                <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                  <Input
                    innerRef={(el) => (this.txtUserRole = el)}
                    type="text"
                    id="userRole"
                    maxLength="100"
                    value={this.state.userRole || ''}
                    autofocus={true}
                    onChange={this.handleUserInput}
                    onBlur={this.onNameBlur}
                    isRequired={false}
                    disabled={true}
                    caption={$.strings.PermissionsUsersAccess.userRole}
                    formErrors={this.state.formErrors}
                  />
                </div>
              </div>
            </Card>
            <Card>
              {/* Copy From User */}
              <div className="p-grid">
                <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                  <Input
                    innerRef={(el) => (this.txtUserNameCopyFrom = el)}
                    type="text"
                    id="userNameCopyFrom"
                    maxLength="100"
                    value={this.state.userNameCopyFrom || ''}
                    autofocus={true}
                    onChange={this.handleUserInput}
                    onBlur={this.onNameBlur}
                    isRequired={false}
                    disabled={true}
                    caption={$.strings.PermissionsUsersAccess.userNameCopyFrom}
                    formErrors={this.state.formErrors}
                    withgroup={true}
                    btn1icon="search"
                    btn1event={this.searchCopyUsers}
                  />
                </div>
                <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
                  <Dropdown
                    id="copyType"
                    value={this.state.copyType}
                    options={this.state.copyTypeList}
                    onChange={this.handleUserInput}
                    caption={$.strings.PermissionsUsersAccess.copyType}
                    optionLabel="name"
                    optionLabelLang2="name"
                  />
                </div>
                <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '2px' }}>
                  <Button label={$.strings.PermissionsUsersAccess.copyAccess} onClick={this.onCopy} />
                </div>
              </div>
            </Card>
            <Card>
              <div className="p-grid">
                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                  {/*المجموعة الرئيسية*/}
                  <Dropdown
                    id="mainRole"
                    value={this.state.mainRole}
                    options={this.state.mainRoleList}
                    onChange={this.handleMainRole}
                    caption={$.strings.PermissionsUsersAccess.mainRole}
                    optionLabel="name"
                    optionLabelLang2="name_lang2"
                    filter
                    filterBy="name,name_lang2"
                  // withgroup={true}
                  // btn1icon="search"
                  // btn1event={this.searchMainRole}
                  />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                  {/*المجموعة الفرعية*/}
                  <Dropdown
                    id="otherRoles"
                    value={this.state.otherRoles}
                    options={this.state.otherRolesList}
                    onChange={this.handleUserInput}
                    caption={$.strings.PermissionsUsersAccess.subRole}
                    optionLabel="name"
                    optionLabelLang2="name_lang2"
                    filter
                    filterBy="name,name_lang2"
                  // withgroup={true}
                  // btn1icon="search"
                  // btn1event={this.searchOtherRole}
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12">
            <Card header={<h2>{$.strings.PermissionsRolesAccess.rightsList}</h2>} style={{ minHeight: '654px' }}>
              <div className="p-grid">
                <div className="p-col-12 p-xl-10 p-lg-12 p-md-12 p-sm-12">
                  {/* Status */}
                  <div className="p-grid">
                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                      <label>{$.strings.PermissionsUsersAccess.status}</label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-12 p-xl-4 p-lg-4 p-md-12 p-sm-12">
                      <RadioButton
                        inputId="rbAll"
                        value={$.strings.all}
                        name="Status"
                        onChange={(e) => this.setState({ statusFilter: e.value }, () => this.getData())}
                        checked={this.state.statusFilter === $.strings.all}
                      ></RadioButton>
                    </div>
                    <div className="p-col-12 p-xl-4 p-lg-4 p-md-12 p-sm-12">
                      <RadioButton
                        inputId="rbInactive"
                        value={$.strings.PermissionsUsersAccess.inactive}
                        name="Status"
                        onChange={(e) => this.setState({ statusFilter: e.value }, () => this.getData())}
                        checked={this.state.statusFilter === $.strings.PermissionsUsersAccess.inactive}
                      />
                    </div>
                    <div className="p-col-12 p-xl-4 p-lg-4 p-md-12 p-sm-12">
                      <RadioButton
                        inputId="rbActive"
                        value={$.strings.PermissionsUsersAccess.active}
                        name="Status"
                        onChange={(e) => this.setState({ statusFilter: e.value }, () => this.getData())}
                        checked={this.state.statusFilter === $.strings.PermissionsUsersAccess.active}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-3 p-xl-1 p-lg-1 p-md-1 p-sm-1" style={{ alignSelf: 'center' }}>
                  <Button
                    tooltip={$.strings.PermissionsUsersAccess.activeAll}
                    icon="pi pi-check-square"
                    className="p-button-success"
                    onClick={this.onActiveAll}
                  />
                </div>
                <div className="p-col-3 p-xl-1 p-lg-1 p-md-1 p-sm-1" style={{ alignSelf: 'center' }}>
                  <Button
                    tooltip={$.strings.PermissionsUsersAccess.inActiveAll}
                    icon="pi pi-times-circle"
                    className="p-button-danger"
                    onClick={this.onInActiveAll}
                  />
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                  <DataGrid
                    style={gridStyle}
                    ref={(e) => (this.grid = e)}
                    exportFileName={this.state.pageInfo.title}
                    isReport={false}
                    theme="default-light"
                    idProperty="ser"
                    scheme={this.getScheme()}
                    dataSource={new wjcCore.CollectionView(this.state.data, { getError: this.getError.bind(this) })}
                    pageId={this.state.pageInfo.pageId}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  getError = (item, propName, parsing = false) => {
    switch (propName) {
      default: {
        break;
      }
    }
    return null;
  };

  popupHasCalled = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
  };
  popupHasClosed = () => {
    document.addEventListener('keydown', this.onPageKeyDown);
  };

  onCopy = async () => {
    if (this.state.userCopyId===undefined || this.state.userId===undefined) {
      Util.showErrorMsg(this.messages, $.strings.PermissionsUsersAccess.vaidateCopyMessage);
      return;
    }
    if( this.state.copyType ===undefined)
    {
      Util.showErrorMsg(this.messages, $.strings.PermissionsUsersAccess.action_type_empty);
      return;
    }
    this.setState({ loading: true });
    let result = false;
    const params = {
      to_user_id: this.state.userId,
      from_user_id: this.state.userCopyId,
      copy_type: this.state.copyType.id,
    };
    let res = await http.getJsonAxios(http.actions.Users.urlCopyUsersAccess, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      Util.showSuccessMsg(this.messages, $.strings.PermissionsUsersAccess.copyResult);
      result = true;
    } else if (res.status === 400) {
      Util.showErrorMsg(this.messages, res.message.message);
    } else {
      Util.showErrorMsg(this.messages);
    }
    this.setState({ loading: false }, () => this.getData());
    return result;
  };
  onActiveAll = () => {
    if (this.state.userId===undefined || this.state.userId <= -1) {
      Util.showErrorMsg(this.messages, $.strings.PermissionsUsersAccess.userISEmpty);
      return;
    }

    let tempData = this.state.data;
    if (tempData) {
      tempData.forEach((row) => (row.is_granted = true));
      this.setState({ data: tempData });
    }
  };
  onInActiveAll = () => {
    if (this.state.userId===undefined || this.state.userId <= -1) {
      Util.showErrorMsg(this.messages, $.strings.PermissionsUsersAccess.userISEmpty);
      return;
    }

    let tempData = this.state.data;
    if (tempData) {
      tempData.forEach((row) => (row.is_granted = false));
      this.setState({ data: tempData });
    }
  };
  onRefresh = (e) => {
    this.getData(false);
  };
  setAccessCategoties = (e) => {
    this.setState({ accessCategoties: e.target.value });
  };
  classTypeItemTemplate(option) {
    return <span>{option.name ? option.name : option.caption}</span>;
  }

  onClose = async (e) => {
    this.getData();
    this.setState({ dlgSearchVisible: false });
  };

  doPrint = async () => {
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    this.setState({ loading: true }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    let result = false;
    const params = {
      status: this.getStatusId(),
    };

    let res = await http.getJsonAxios(http.actions.AccessList.urlGetSavePrintLogLookups, params, this.state.pageInfo.pageId);
    if (res.status === 200) result = true;
    this.setState({ loading: false }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    return result;
  };

  onExcelExport = async () => {
      this.grid.excelExport();
    
  };

  doExcelExport = async () => {
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    this.setState({ loading: true }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    let result = false;
    const params = {
      status: this.getStatusId(),
    };
    let res = await http.getJsonAxios(http.actions.AccessList.urlGetSaveExportToExcelLogLookups, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
    }
    this.setState({ loading: false }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    return result;
  };

  onIsGrantedChanged = (rd, props, value, fieldName) => {
    let tempData = this.state.data;
    if (tempData) {
      rd[fieldName] = value;
      //tempData[props.rowIndex][fieldName] = value;
      this.setState({ data: tempData });
    }
  };
  onRoleIsGrantedChanged = (rowIndex, value) => {
    let tempData = this.state.data;
    if (tempData) {
      tempData[rowIndex].role_is_granted = value;
      this.setState({ data: tempData });
    }
  };

  onDisabledChanged = () => { };

  onEditClick = (e, ctx) => {
    //this.dlgEditRights.show(ctx.item.access_id, ctx.item.is_granted, ctx.item.role_is_granted, true);
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;
    this.popupHasCalled();
    this.setState(
      { editRightsVisible: true, id: ctx.item.access_id, is_granted: ctx.item.is_granted, role_is_granted: ctx.item.role_is_granted },
      () => {
        Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
      }
    );
  };
  getScheme = () => {
    return {
      name: 'PermissionsUsersAccessScheme_Table_' + this.props.lookupType,
      filter: true,
      showFooter: true,
      showFilter: true,
      responsiveColumnIndex: 3,
      columns: [
        { name: 'ser', header: '##', width: 55, dataType: 'Number', aggregate: 'Cnt', isReadOnly: true, columnWidth: '2' },
        {
          name: 'btnEdit',
          header: ' ',
          width: 50,
          buttonBody: 'button',
          align: 'center',
          title: ' ',
          iconType: 'edit',
          className: 'warning',
          onClick: (e, ctx) => this.onEditClick(e, ctx),
        },
        { name: 'access_id', header: $.strings.PermissionsUsersAccess.access_id, isReadOnly: true, columnWidth: '2'       ,
         width: 120, },
        {
          name: 'access_name',
          header: $.strings.PermissionsUsersAccess.access_name,
          visible: true,
          visibleInColumnChooser: true,
          width: '*',
          minWidth: 150,
          isReadOnly: true,
          columnWidth: '*',
        },
        {
          name: 'is_granted',
          header: $.strings.PermissionsUsersAccess.is_granted,
          isReadOnly: false,
          visible: this.state.isDesktop,
          visibleInColumnChooser: this.state.isDesktop,
          columnWidth: '2',
          width: 80,
        },
        {
          name: 'is_granted_name',
          header: $.strings.PermissionsUsersAccess.is_granted,
          columnWidth: '2',
          isReadOnly: false,
          width: 80,
          visible: !this.state.isDesktop,
          visibleInColumnChooser: !this.state.isDesktop,
        },
        {
          name: 'role_is_granted',
          header: $.strings.PermissionsUsersAccess.role_is_granted,
          columnWidth: '3',
          isReadOnly: true,
          width: 120,
          visible: this.state.isDesktop,
          visibleInColumnChooser: this.state.isDesktop,
        },
      ],
    };
  };
  validateField() {
    if (this.state.userId === 0) {
      Util.showErrorMsg(this.messages, $.strings.PermissionsUsersAccess.userISEmpty);
      return false;
    }
    return true;
  }
  onSave = async () => {
    if (!this.validateField()) {
      this.setState({ loading: false });
      return;
    }

    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    this.setState({ loading: true }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    let success = false;
    let result = await this.saveData();
    if (result.status === 200) {
      Util.showSuccessMsg(this.messages, $.strings.showSuccessMsg);
      success = true;
    } else if (result.status === 400) {
      Util.showErrorMsg(this.messages, result.message.message);
      success = false;
    } else {
      Util.showErrorMsg(this.messages);
      success = false;
    }
    this.setState({ loading: false }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    return success;
  };
  saveData = async () => {
    let accessList = [];
    if (this.state.data) {
      let temp = this.state.data;
      for (let i = 0; i < temp.length; i++) {
        accessList.push({
          access_id: temp[i].access_id,      
          is_granted : temp[i].is_granted+"" ==="true"?1:0
        });
      }
    }
    let obj = {
      list: accessList,
      selected_user_id: this.state.userId,
    };
    let res = await http.postMultipartAxios(http.actions.Users.urlSaveUsersAccess, obj, this.state.pageInfo.pageId);
    return res;
  };
  getStatusId = () => {
    switch (this.state.statusFilter) {
      case $.strings.PermissionsUsersAccess.active: {
        return 1;
      }
      case $.strings.PermissionsUsersAccess.inactive: {
        return 0;
      }
      default: {
        return -1;
      }
    }
  };
  handleUserInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    this.setState({ [name]: value });
  };
  getData = async (fromLoad) => {
    let rowIndex = this.grid.flex.selection.row;
    let colIndex = this.grid.flex.selection.col;

    let dataTemp = [];
    if (this.state.userId === -1) {
      this.setState({ data: dataTemp, loading: false }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
      Util.showErrorMsg(this.messages, $.strings.PermissionsUsersAccess.userISEmpty);
      return dataTemp;
    }

    if (!fromLoad) {
      this.setState({ loading: true }, () => Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false));
    }
    const params = {
      user_id: this.state.userId,
      group_id:this.state.mainRole ? this.state.mainRole.id:-1,
      sub_group_id: this.state.otherRoles ? this.state.otherRoles.id : -1,
      is_granted: this.getStatusId(),
    };
    let res = await http.postMultipartAxios(http.actions.Users.urlGetUserAccess, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
      dataTemp.forEach((e) => {
        e.is_granted_name = e.is_granted ? $.strings.PermissionsRolesAccess.yes : $.strings.PermissionsRolesAccess.no;
        e.role_is_granted_name = e.role_is_granted ? $.strings.PermissionsRolesAccess.yes : $.strings.PermissionsRolesAccess.no;
      });
    }
    if (fromLoad) return dataTemp;
    else {
      this.setState({ visibleData: dataTemp, data: dataTemp, loading: false }, () => {
        Util.gridColumnStartEditing(this.grid, rowIndex, colIndex, false);
        this.printFilters = this.state;
      });
    }
  };

  setPageInfo = () => {
    let pageId;
    let title;

    pageId =0;
    title = $.strings.PermissionsUsersAccess.title;

    return { pageId: pageId, title: title };
  };
  getCopyType = () => {
    let values = [
      { id: 1, name: $.strings.PermissionsUsersAccess.TypeOption1, name_lang2: $.strings.PermissionsUsersAccess.TypeOption1 },
      { id: 2, name: $.strings.PermissionsUsersAccess.TypeOption2, name_lang2: $.strings.PermissionsUsersAccess.TypeOption2 },
      { id: 3, name: $.strings.PermissionsUsersAccess.TypeOption3, name_lang2: $.strings.PermissionsUsersAccess.TypeOption3 },
    ];
    return values;
  };

  onNext = async (e, checkChanging) => {
    let selectedRowNumber =this.state.row_number;
    if(this.state.userId ===-1) selectedRowNumber=this.state.usersList.length;
     let objRowNumber = this.state.usersList.find(e=>e.RowNumber+"" ===selectedRowNumber+"");
     if(objRowNumber !==undefined)
     {
       let index = parseInt(objRowNumber.RowNumber);
       if(this.state.userId > -1) index= index+1;
 
       objRowNumber = this.state.usersList.find(e=>e.RowNumber+"" ===index+"");
       console.log(objRowNumber)
       if(objRowNumber !==undefined)
       {
         this.setState({userName: objRowNumber.Name, userId: objRowNumber.ID,row_number:objRowNumber.RowNumber},async()=>
         {
           await this.getData(false);
           await this.getUserRole();
         });
  
       }
       else{
  Util.showInfoMsg(this.messages,$.strings.recordsEnds);
       }
     }
   };
 
   onPrevious = async (e, checkChanging) => {
     let selectedRowNumber =this.state.row_number;

     if(this.state.userId ===-1) selectedRowNumber=1;
     let objRowNumber = this.state.usersList.find(e=>e.RowNumber+"" ===selectedRowNumber+"");
     if(objRowNumber !==undefined)
     {
       let index = parseInt(objRowNumber.RowNumber);
       if(this.state.userId > -1) index= index-1;
 
       objRowNumber = this.state.usersList.find(e=>e.RowNumber+"" ===index+"");
       if(objRowNumber !==undefined)
       {
         this.setState({userName: objRowNumber.Name, userId: objRowNumber.ID,row_number:objRowNumber.RowNumber},async()=>
         {
           await this.getData(false);
           await this.getUserRole();
         });
  
       }
       else{
          Util.showInfoMsg(this.messages,$.strings.recordsBeginning);
       }
     }
   };
   getUsers = async () => {
    let dataTemp = [];
    const params = {
      userId :-1,
      status: 0
    };
    let res = await http.getJsonAxios(http.actions.Users.urlGetUsers, params, this.state.pageInfo.pageId);

    if (res.status === 200) {
      dataTemp = res.data.list;
    }
    this.setState({ usersList: dataTemp });

  };
  onPrint = async () => {
    if (!this.state.data || this.state.data.length <= 0) {
      Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint);
      return;
    }


    let obj = Print.getReportPrintObject(this.state.pageInfo.title, this.grid, this.state.data);

    let listOfFilters = [];
    if (this.printFilters) {
      if (this.printFilters.userName) {
        listOfFilters.push($.strings.PermissionsUsersAccess.userName + ': ' + this.printFilters.userName);
      }
      if (this.printFilters.system) {
        let name = Util.getNameByUserLanguage(this.printFilters.system.name, this.printFilters.system.name_lang2);
        listOfFilters.push($.strings.PermissionsRolesAccess.system + ': ' + name);
      }
      if (this.printFilters.model) {
        let name = Util.getNameByUserLanguage(this.printFilters.model.description, this.printFilters.model.description);
        listOfFilters.push($.strings.PermissionsRolesAccess.model + ': ' + name);
      }

      if (this.printFilters.mainRole) {
        let name = Util.getNameByUserLanguage(this.printFilters.mainRole.name, this.printFilters.mainRole.name_lang2);
        listOfFilters.push($.strings.PermissionsRolesAccess.mainRole + ': ' + name);
      }
      if (this.printFilters.otherRoles) {
        let name = Util.getNameByUserLanguage(this.printFilters.otherRoles.name, this.printFilters.otherRoles.name_lang2);
        listOfFilters.push($.strings.PermissionsRolesAccess.subRole + ': ' + name);
      }
      if (this.printFilters.accessCategoties) {
        let categorisFilter = '';
        categorisFilter += ' ' + $.strings.PermissionsRolesAccess.accessCategoties + ': ';
        for (let index = 0; index < this.printFilters.accessCategoties.length; index++) {
          let name = Util.getNameByUserLanguage(this.printFilters.accessCategoties[index].name, this.printFilters.accessCategoties[index].name_lang2);
          if (index > 0) categorisFilter += ', ';
          categorisFilter += ' ' + name;
        }
        if (categorisFilter !== '') listOfFilters.push(categorisFilter);
      }

      listOfFilters.push($.strings.PermissionsRolesAccess.status + ': ' + this.state.statusFilter);
    }

    obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers);
    Print.doPrintReport(obj);
  };
}
