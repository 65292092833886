import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util';
import DataGrid from '../../Components/DataGridView/DataGridView';
import ButtonsBarLookupList from "../../Components/ButtonsBarLookupList";
import Card from "../../Components/Card";
import Dropdown from "../../Components/Dropdown";
import MultiSelect from "../../Components/MultiSelect";
import Calendar from "../../Components/Calendar";
import InputTime from "../../Components/InputTime";
import { Accordion, AccordionTab } from 'primereact/accordion';
import SiteMap from "../../Components/SiteMap";
import Checkbox from "../../Components/Checkbox";
import AdditionalFilters from "../AdditionalFilters/AdditionalFilters";
import Print from "../../Print";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class MonthlySummaryReport extends React.Component {

    constructor(props) {
        super(props);
        this.nowDate = new Date();
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            firstExecution: false,
            pageInfo: this.setPageInfo(),
            sectionsList: [],
            employeesList: [],
            data: [],
            dataObject: {
                section: [],
                employees: [],
                fromDate: new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), 1),
                toDate: new Date(this.nowDate.getFullYear(), this.nowDate.getMonth() + 1, 0),
                showLateTrans: false,
                showJobTotal: false,
                showSymboleInColumns: false,
                ShowAttendanceTime: false,
                schoolModel: false,
                additionalFiltersObj: undefined
            }
        }
    }
    componentDidMount = async () => {
        if (!Util.checkISHR()) {
            Util.goTo('/Access');
        }
        if (!Util.checkRights(556)) {
            Util.goTo('/Access');
        }
        let _isDesktop = window.innerWidth > 1250

        let sectionsList = await this.getSections();
        let employeesList = await this.getEmployeesList();
        this.setState({ employeesList: employeesList, sectionsList: sectionsList, isDesktop: _isDesktop, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    handleMultiSelect = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const gridStyle = { minHeight: '40vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div style={{ direction: $.strings.dir }}>
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />

                    <ButtonsBarLookupList onRefresh={e => this.onRefresh(false)} onExcelExport={this.excelExport} onPrint={this.onPrint} />

                    <Messages innerRef={(el) => this.messages = el} />
                </Card>
                <div ref="divMain" >
                    <AdditionalFilters ref={e => this.dlgAdditionalFilters = e} onSuccess={this.onAdditionalFiltersSuccess} />
                    <Accordion multiple activeIndex={[0]}>
                        <AccordionTab header={$.strings.filterCaption}>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <MultiSelect
                                        id="section"
                                        innerRef={e => this.drpSection = e}
                                        value={this.state.dataObject.section}
                                        options={this.state.sectionsList}
                                        caption={$.strings.monthlySummaryReport.section}
                                        onChange={(e) => this.handleUserInput(e)}
                                        optionLabel="Name"
                                        display="chip"
                                        maxSelectedLabels="2"
                                        filter={true}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <MultiSelect
                                        id="employees"
                                        value={this.state.dataObject.employees}
                                        options={this.state.employeesList}
                                        caption={$.strings.monthlySummaryReport.employee}
                                        onChange={this.handleMultiSelect}
                                        optionLabel="name"
                                        display="chip"
                                        maxSelectedLabels="2"
                                        showSelectAll={false}
                                        filter
                                    />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Calendar
                                        id="fromDate"
                                        value={this.state.dataObject.fromDate}
                                        caption={$.strings.monthlySummaryReport.fromDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Calendar
                                        id="toDate"
                                        value={this.state.dataObject.toDate}
                                        caption={$.strings.monthlySummaryReport.toDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Checkbox id="showLateTrans" caption={$.strings.monthlySummaryReport.showLateTrans}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showLateTrans: e.checked } })}
                                        checked={this.state.dataObject.showLateTrans} />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Checkbox id="showJobTotal" caption={$.strings.monthlySummaryReport.showJobTotal}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showJobTotal: e.checked } })}
                                        checked={this.state.dataObject.showJobTotal} />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Checkbox id="showSymboleInColumns" caption={$.strings.monthlySummaryReport.showSymboleInColumns}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showSymboleInColumns: e.checked } })}
                                        checked={this.state.dataObject.showSymboleInColumns} />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Checkbox id="ShowAttendanceTime" caption={$.strings.monthlySummaryReport.ShowAttendanceTime}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, ShowAttendanceTime: e.checked } })}
                                        checked={this.state.dataObject.ShowAttendanceTime} />
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                    <Checkbox id="schoolModel" caption={$.strings.monthlySummaryReport.schoolModel}
                                        onChange={e => this.setState({ dataObject: { ...this.state.dataObject, schoolModel: e.checked } })}
                                        checked={this.state.dataObject.schoolModel} />
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                    <Button label={$.strings.additionalFilters.title} className={this.state.dataObject.additionalFiltersObj ? 'p-button-warning' : ''}
                                        onClick={e => this.dlgAdditionalFilters.show()} />
                                    <Button label="" icon="pi pi-filter-slash" visible={this.state.dataObject.additionalFiltersObj}
                                        tooltip={$.strings.additionalFilters.clearFilters}
                                        onClick={e => this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: undefined } })}
                                    />
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <DataGrid
                                    style={gridStyle}
                                    ref={e => this.gridData = e}
                                    isReport={true}
                                    theme="default-light"
                                    idProperty="RowNumber"
                                    exportFileName={this.state.pageInfo.title}
                                    scheme={this.getScheme()}
                                    dataSource={this.state.data}
                                />
                            </div>
                        </div>
                    </Card>
                    {
                        this.state.dataObject.showSymboleInColumns &&
                        <Card>
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-12 p-sm-12">
                                    <DataGrid
                                        style={gridStyle}
                                        ref={e => this.gridDataSymbols = e}
                                        isReport={true}
                                        theme="default-light"
                                        idProperty="RowNumber"
                                        exportFileName={this.state.pageInfo.title}
                                        scheme={this.getSymbolScheme()}
                                        dataSource={this.state.data}
                                    />
                                </div>
                            </div>
                        </Card>
                    }
                </div >
            </div>
        );
    }

    onAdditionalFiltersSuccess = (obj) => {
        if (obj) {
            this.setState({ dataObject: { ...this.state.dataObject, additionalFiltersObj: obj } })
        }
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.splice(0, 1);
            }
        }
        return dataTemp
    }

    getEmployeesList = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: !this.state.empCodeOrName || this.state.empCodeOrName.length <= 0 ? '' : this.state.empCodeOrName,
            isHr: Util.checkISHR(),
            getEmpStatus: this.state.getEmpStatus ? this.state.getEmpStatus : false,
            section: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            empStatus: this.state.empStatus && this.state.empStatus.id >= 0 ? this.state.empStatus.id : -1,
        }

        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;

        }
        return dataTemp
    }

    onRefresh = async (fromLoad) => {
        if (!this.state.dataObject.fromDate) {
            Util.showErrorMsg(this.messages, $.strings.monthlySummaryReport.fromDateError)
            return;
        }
        if (!this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.monthlySummaryReport.toDateError)
            return;
        }
        if (this.state.dataObject.fromDate > this.state.dataObject.toDate) {
            Util.showErrorMsg(this.messages, $.strings.monthlySummaryReport.dateError)
            return;
        }

        if (this.state.dataObject.fromDate.getMonth() !== this.state.dataObject.toDate.getMonth() ||
            this.state.dataObject.fromDate.getFullYear() !== this.state.dataObject.toDate.getFullYear()) {
            Util.showErrorMsg(this.messages, $.strings.monthlySummaryReport.dateRangeError)
            return;
        }

        if (!fromLoad) {
            this.setState({ loading: true, firstExecution: false });
        }
        this.messages.clear();

        let data = []
        let generalFillter = {};

        if (this.state.dataObject.section) {
            let sectionsList = []
            for (let index = 0; index < this.state.dataObject.section.length; index++) {
                sectionsList.push(this.state.dataObject.section[index].Code)
            }
            generalFillter.SectionList = sectionsList;
        }
        if (this.state.dataObject.employees) {
            let empList = [];
            for (let index = 0; index < this.state.dataObject.employees.length; index++) {
                empList.push(this.state.dataObject.employees[index].code)
            }
            generalFillter.EmpList = empList;
        }
        Util.fillAditionalsFilters(this.state.dataObject.additionalFiltersObj, generalFillter);

        let obj = {
            FromDate: this.state.dataObject.fromDate,
            ToDate: this.state.dataObject.toDate,
            CheckLateTrans: this.state.dataObject.showLateTrans,
            CheckTotalJob: this.state.dataObject.showJobTotal,
            CheckSymbolColumns: this.state.dataObject.showSymboleInColumns,
            CheckComeTime: this.state.dataObject.ShowAttendanceTime,
            GeneralFillter: generalFillter
        }
        let res = await http.postMultipartAxios(http.actions.Reports.urlPostMonthlySummaryReport, obj, null, null, 0);
        if (res && res.status === 200) {
            if (res.data && res.data.success)
                data = res.data;
        }
        if (!fromLoad) {
            this.setState({ data: data, loading: false }, () => this.printFilters = this.state.dataObject);
        }
        else {
            return data;
        }
    }

    excelExport = () => {
        this.gridData.exportCSV();
    }

    onPrint = () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject(this.state.pageInfo.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let dateFilter = "";

            if (this.printFilters.section) {
                let sectionFilter = "";
                sectionFilter += " " + $.strings.monthlySummaryReport.section + ": ";
                for (let index = 0; index < this.printFilters.section.length; index++) {
                    let name = this.printFilters.section[index].Name;
                    if (index > 0)
                        sectionFilter += ", ";
                    sectionFilter += " " + name;
                }
                if (sectionFilter !== "")
                    listOfFilters.push(sectionFilter);
            }

            if (this.printFilters.employees) {
                let employeeFilter = "";
                employeeFilter += " " + $.strings.monthlySummaryReport.employee + ": ";
                for (let index = 0; index < this.printFilters.employees.length; index++) {
                    let name = this.printFilters.employees[index].Name;
                    if (index > 0)
                        employeeFilter += ", ";
                    employeeFilter += " " + name;
                }
                if (employeeFilter !== "")
                    listOfFilters.push(employeeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.monthlySummaryReport.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.monthlySummaryReport.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.showLateTrans) {
                listOfFilters.push($.strings.monthlySummaryReport.showLateTrans + ": " + (this.printFilters.showLateTrans ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters.showJobTotal) {
                listOfFilters.push($.strings.monthlySummaryReport.showJobTotal + ": " + (this.printFilters.showJobTotal ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters.showSymboleInColumns) {
                listOfFilters.push($.strings.monthlySummaryReport.showSymboleInColumns + ": " + (this.printFilters.showSymboleInColumns ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters.ShowAttendanceTime) {
                listOfFilters.push($.strings.monthlySummaryReport.ShowAttendanceTime + ": " + (this.printFilters.ShowAttendanceTime ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters.schoolModel) {
                listOfFilters.push($.strings.monthlySummaryReport.schoolModel + ": " + (this.printFilters.schoolModel ? $.strings.yes : $.strings.no));
            }
            if (this.printFilters.schoolModel) {
                listOfFilters.push($.strings.monthlySummaryReport.schoolModel + ": " + (this.printFilters.schoolModel ? $.strings.yes : $.strings.no));
            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    getScheme = () => {
        let scheme = {
            name: "recalculateBalancesScheme_Table",
            filter: false,
            showFooter: true,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [

                { name: 'RowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'Code', header: $.strings.monthlySummaryReport.empCode, width: 120, columnWidth: '2' },
                { name: 'Name', header: $.strings.monthlySummaryReport.empName, width: "*", minWidth: 120, columnWidth: '*' },
                { name: 'SectionName', header: $.strings.monthlySummaryReport.section, width: "*", minWidth: 120, visible: false, columnWidth: '*' },
                { name: 'ManagmentPos', header: $.strings.monthlySummaryReport.department, width: "*", minWidth: 120, visible: false, columnWidth: '3' },
                { name: 'BranchPos', header: $.strings.monthlySummaryReport.branch, width: "*", minWidth: 120, visible: false, columnWidth: '3' },
                { name: 'JobPos', header: $.strings.monthlySummaryReport.job, width: "*", minWidth: 120, visible: this.state.dataObject.schoolModel, columnWidth: '3' },

            ]
        }

        if (this.state.data && this.state.data.length > 0) {
            let objKeys = Object.keys(this.state.data[0]);
            for (let key = 0; key < objKeys.length; key++) {
                if (objKeys[key] !== "RowNumber" && objKeys[key] !== "Code" && objKeys[key] !== "Name" && objKeys[key] !== "SectionName" &&
                    objKeys[key] !== "ManagmentPos" && objKeys[key] !== "BranchPos" && objKeys[key] !== "JobPos") {
                    let col = { name: objKeys[key], header: objKeys[key], width: 60, maxWidth: 60, filterType: 'None', columnWidth: '2' }
                    if (!scheme.columns.includes(col))
                        scheme.columns.push(col);
                }
            }
        }

        return scheme;
    }

    getSymbolScheme = () => {
        let scheme = {
            name: "recalculateBalancesScheme_Table",
            filter: false,
            showFooter: true,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [
                { name: 'RowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt' },
                { name: 'symbol', header: $.strings.monthlySummaryReport.empCode, width: 120, },
                { name: 'TransType', header: $.strings.monthlySummaryReport.empName, width: "*", minWidth: 120, },
            ]
        }
        return scheme
    }

    setPageInfo = () => {
        let pageId
        let title = $.strings.monthlySummaryReport.title;

        return { pageId: pageId, title: title };
    };
}