import '@grapecity/wijmo.styles/wijmo.css';

import * as React from 'react';
import * as wijmo from '@grapecity/wijmo';
import * as wjChart from '@grapecity/wijmo.react.chart';
import * as wjCharts from '@grapecity/wijmo.chart';
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation';
import http from '../../../../Api/http'
import ProgressSpinner from '../../../ProgressSpinner';
import Absence from '../Absence';
import Requests from '../Requests/Requests';
import NoSignIn from '../NoSignIn/NoSignIn';
import NoExit from '../NoExit/NoExit';
import Late from '../Late';

const $ = window.$

export default class Tile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isDesktop: true,
        }
    }
    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;
        this.setState({ isDesktop: _isDesktop, loading: false });
    }
    render() {
        return <div className="tile" draggable={true}>
            <div className="tile-container">
                <div className="tile-header">{this.setHeaderText()}</div>
                <div className="buttons">
                    <div className="button" title="Close Tile" onClick={() => this.props.onRemove(this.props.index)}>
                        <svg width="24" height="24" viewBox="0 0 24 24">
                            <path d="M12.71,12l4.64-4.65a.49.49,0,1,0-.7-.7L12,11.29,7.35,6.65a.49.49,0,0,0-.7.7L11.29,12,6.65,16.65a.48.48,0,0,0,0,.7.48.48,0,0,0,.7,0L12,12.71l4.65,4.64a.48.48,0,0,0,.7,0,.48.48,0,0,0,0-.7Z" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="tile-content">
                {this.props.header === "Late" && <Late />}
                {this.props.header === "Absence" && <Absence />}
                {this.props.header === "Requests" && <Requests />}
                {this.props.header === "NoSignIn" && <NoSignIn />}
                {this.props.header === "NoExit" && <NoExit />}
            </div>
        </div>
    }
    setHeaderText = () => {
        let headerText = "";
        switch (this.props.header) {
            case "Late": {
                headerText = $.strings.charts.lateTile;
                break;
            }
            case "Absence": {
                headerText = $.strings.charts.absenceTitle;
                break;
            }
            case "Requests": {
                headerText = $.strings.charts.requestsTitle;
                break;
            }
            case "NoSignIn": {
                headerText = $.strings.charts.NoSignInTile;
                break;
            }
            case "NoExit": {
                headerText = $.strings.charts.NoExitTile;
                break;
            }
            default: {
                break;
            }
        }
        return headerText;
    }
}