import React from "react";
import styles from "./ImportEmployees.module.scss";
import Card from '../../../Components/Card';
import SiteMap from '../../../Components/SiteMap';
import Button from '../../../Components/Button';
import Util from "../../../Util";
import ScrollTop from '../../ScrollButton';
import http from '../../../Api/http';
import Messages from '../../../Components/Messages'
import ProgressSpinner from '../../../Components/ProgressSpinner';
import DataGrid from "../../../Components/DataGridView/DataGridView";
import { Accordion, AccordionTab } from 'primereact/accordion';
import Input from "../../../Components/Input";
import * as wjcXlsx from '@grapecity/wijmo.xlsx';
import Checkbox from "../../../Components/Checkbox";
import MapExcelColumns from "./MapExcelColumns";
import * as wjcCore from '@grapecity/wijmo';

const $ = window.$

export default class ImportEmployees extends React.Component {
    constructor(props) {
        super(props);
        this.columnsList = [];
        this.state = {
            loading: true,
            data: [],
            excelColumns: [],
            changeEmployeeDataInRawateb: false,
            insert_static_file: false,
            containsTitleLine: true,
            firstExecuteEnabled: false,
            executeEnabled: false,
            isDesktop: window.innerWidth > 1250
        };
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(501)) {
            Util.goTo('/Access');
            return;
        }
        this.columnsList = this.getColumnsList();
        window.addEventListener("resize", this.updatePredicate);
        this.setState({ loading: false });
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 1250 });
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    popupHasCalled = () => {
        document.removeEventListener('keydown', this.onPageKeyDown);
    };
    popupHasClosed = () => {
        document.addEventListener('keydown', this.onPageKeyDown);
    };

    render() {
        const gridStyle = { minHeight: this.state.isDesktop ? '42vh' : '100vh' }

        let siteMapItems = [
            { label: $.strings.links.files },
            { label: $.strings.importEmployees.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" style={{ minHeight: '70vh' }}>
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    {
                        this.state.MapExcelColumnsVisible &&
                        <MapExcelColumns
                            columnsList={this.columnsList}
                            excelColumns={this.state.excelColumns}
                            excelFile={this.state.file}
                            onSuccess={this.onMapSuccess}
                            onClose={this.onMapClose}
                        />
                    }
                </Card>
                <Accordion activeIndex={0}>
                    <AccordionTab header={$.strings.filterCaption} >
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-2 p-lg-2 p-md-6 p-sm-12">
                                <Button label={$.strings.importEmployees.selectFile} onClick={this.onSelectFile} className={styles.menuItem} />
                                <Input id="importFile" type="file" onChange={(e) => this.onFileChanged(e)} ref={(e) => (this.fileInput = e)}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12" />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                <Checkbox id="changeEmployeeDataInRawateb" caption={$.strings.importEmployees.changeEmployeeDataInRawateb}
                                    onChange={e => this.setState({ changeEmployeeDataInRawateb: e.checked })} checked={this.state.changeEmployeeDataInRawateb} />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-4 p-md-6 p-sm-12">
                                <Checkbox id="insert_static_file" caption={$.strings.importEmployees.staticFileInserted}
                                    onChange={e => this.setState({ insert_static_file: e.checked })} checked={this.state.insert_static_file} />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                <Checkbox id="containsTitleLine" caption={$.strings.importEmployees.containsTitleLine}
                                    onChange={e => this.setState({ containsTitleLine: e.checked })} checked={this.state.containsTitleLine} />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-2 p-lg-2 p-md-6 p-sm-12">
                                <Button label={$.strings.importEmployees.firstExecute} onClick={this.onFirstExecute} className={styles.menuItem}
                                    disabled={!this.state.firstExecuteEnabled}
                                />
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-2 p-md-6 p-sm-12">
                                <Button label={$.strings.importEmployees.firstExecute} onClick={this.onExecute}
                                    disabled={!this.state.executeEnabled} className={`${styles.menuItem} ${"p-button-success"}`}
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <Messages innerRef={(el) => this.messages = el} />
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <DataGrid
                                style={gridStyle}
                                ref={e => this.grid = e}
                                isReport={false}
                                theme="default-light"
                                idProperty="serial"
                                scheme={this.getScheme()}
                                dataSource={
                                    new wjcCore.CollectionView(this.state.data, {
                                        getError: undefined,
                                        currentPosition: Util.setCurrentRow(this.grid),
                                    })
                                }
                                showContextMenu={true}
                                exportFileName={$.strings.news.NewsReportTitle}
                            />
                        </div>
                    </div>
                </Card>
                <ScrollTop />
            </div >
        );
    }

    onFirstExecute = () => {
        let data = this.state.data.filter(e => e.selected === true);
        let index = 1;
        data.forEach(element => {
            element.rowNumber = index;
            index++;
        });
        let executeEnabled = false;
        if (data && data.length > 0)
            executeEnabled = true;
        this.setState({ data: data, executeEnabled: executeEnabled });
    }

    onExecute = () => {
        let data = this.state.data.filter(e => e.selected === true);
        let index = 1;
        data.forEach(element => {
            element.rowNumber = index;
            index++;
        });
        this.setState({ data: data });
    }

    onSelectFile = () => {
        let fileInput = document.getElementById('importFile');
        fileInput.click();
    }

    onFileChanged = async (e) => {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];
            e.target.value = null;

            this.loadGridExcelColumns(this.grid.flex, file);
        }
    };
    loadGridExcelColumns = async (grid, file) => {
        this.setState({ loading: true, firstExecuteEnabled: false, executeEnabled: false });
        let reader = new FileReader();
        let self = this;
        reader.onload = function (e) {
            // create workbook instance and load excel file in the workbook
            let _workbook = new wjcXlsx.Workbook();
            _workbook.loadAsync(reader.result, async (workbook) => {
                let sheet = workbook.sheets[0];
                let excelColumns = getExcelColumns(self, sheet);
                if (!self.columnsList)
                    self.columnsList = self.getColumnsList();
                self.columnsList.forEach(element => {
                    let found = excelColumns.find(e => e.name === element.header);
                    let index = excelColumns.findIndex(e => e.name === element.header);
                    if (found) {
                        element.excelIndex = index;
                        element.excelColumn = found.excelColumn;
                    }
                });
                self.popupHasCalled();
                self.setState({ loading: false, MapExcelColumnsVisible: true, excelColumns: excelColumns, file: file });
            });
        };
        reader.readAsDataURL(file);
    };

    loadGrid = async (grid, file, columnsList) => {
        this.setState({ loading: true });
        let reader = new FileReader();
        let self = this;
        reader.onload = function (e) {
            // create workbook instance and load excel file in the workbook
            let _workbook = new wjcXlsx.Workbook();
            _workbook.loadAsync(reader.result, async (workbook) => {
                let sheet = workbook.sheets[0];
                let objResult = getDataFromSheet(self, sheet, columnsList);
                let newData = objResult.data;
                if (newData) {
                    let errorFound = newData.filter((e) => e.import_note && e.import_note.toString().trim().length > 0);
                    if (errorFound && errorFound.length > 0) {
                        self.setState({ data: newData, loading: false });
                        Util.showErrorMsg(self.messages, $.strings.importEmployees.importExcelErrors);
                        return;
                    }
                }
                let insert_static_file = !self.insert_static_file ? true : false;
                let dataTemp = await self.handleImportedData(newData, insert_static_file);
                let selectedFound = dataTemp.filter((e) => e.selected === true);
                let firstExecuteEnabled = false;
                if (selectedFound && selectedFound.length > 0) {
                    firstExecuteEnabled = true;
                }
                self.setState({ data: dataTemp, loading: false, file: file, firstExecuteEnabled: firstExecuteEnabled });
            });
        };
        reader.readAsDataURL(file);
    };


    getColumnsList = () => {
        return [
            { name: "code", header: $.strings.importEmployees.empCode, index: 0, excelIndex: -1, excelColumn: '', isRequired: true },
            { name: "name", header: $.strings.importEmployees.name, index: 1, excelIndex: -1, excelColumn: '', isRequired: true },
            { name: "ename", header: $.strings.importEmployees.ename, index: 2, excelIndex: -1, excelColumn: '', isRequired: true },
            { name: "idNo", header: $.strings.importEmployees.idNo, index: 3, excelIndex: -1, excelColumn: '' },
            { name: "card", header: $.strings.importEmployees.card, index: 4, excelIndex: -1, excelColumn: '' },
            { name: "period", header: $.strings.importEmployees.period, index: 5, excelIndex: -1, excelColumn: '' },
            { name: "shift", header: $.strings.importEmployees.shift, index: 6, excelIndex: -1, excelColumn: '' },
            { name: "section_code", header: $.strings.importEmployees.section, index: 7, excelIndex: -1, excelColumn: '', isRequired: true },
            { name: "co_dep", header: $.strings.importEmployees.department, index: 8, excelIndex: -1, excelColumn: '' },
            { name: "co_branch", header: $.strings.importEmployees.branch, index: 9, excelIndex: -1, excelColumn: '' },
            { name: "city", header: $.strings.importEmployees.city, index: 10, excelIndex: -1, excelColumn: '' },
            { name: "town", header: $.strings.importEmployees.town, index: 11, excelIndex: -1, excelColumn: '' },
            { name: "phone", header: $.strings.importEmployees.phone, index: 12, excelIndex: -1, excelColumn: '' },
            { name: "mobile1", header: $.strings.importEmployees.mobile, index: 13, excelIndex: -1, excelColumn: '' },
            { name: "email", header: $.strings.importEmployees.email, index: 14, excelIndex: -1, excelColumn: '' },
            { name: "sex", header: $.strings.importEmployees.sex, index: 15, excelIndex: -1, excelColumn: '' },
            { name: "d_app", header: $.strings.importEmployees.d_app, index: 16, excelIndex: -1, excelColumn: '' },
            { name: "d_fin", header: $.strings.importEmployees.d_fin, index: 17, excelIndex: -1, excelColumn: '' },
            { name: "direct_manager", header: $.strings.importEmployees.direct_manager, index: 18, excelIndex: -1, excelColumn: '' },
            { name: "classification", header: $.strings.importEmployees.classification, index: 19, excelIndex: -1, excelColumn: '' },
            { name: "other_classification", header: $.strings.importEmployees.other_classification, index: 20, excelIndex: -1, excelColumn: '' },
            { name: "Job_code", header: $.strings.importEmployees.Job_code, index: 21, excelIndex: -1, excelColumn: '' },
        ]
    }

    onMapSuccess = (columnsList, file) => {
        this.popupHasClosed();
        this.setState({ MapExcelColumnsVisible: false });

        this.loadGrid(this.grid.flex, file, columnsList);
    }

    onMapClose = () => {
        this.popupHasClosed();
        this.setState({ MapExcelColumnsVisible: false });
    }

    handleImportedData = async (data, insert_static_file) => {
        let dataTemp = [];
        this.setState({ loading: true });
        let params = {
            list: data,
            insert_static_file: insert_static_file
        };
        let res = await http.postMultipartAxios(http.actions.Employee.urlPostHandleImportEmployeeFromExcel, params, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.list;
                if (dataTemp) {
                    // let selectedFound = dataTemp.filter((e) => e.selected === true);
                    // if (selectedFound && selectedFound.length > 0) {
                    //     Util.showInfoMsg(this.messages, $.strings.customerItemsPrice.saveInfo);
                    // }
                }
            } else {
                dataTemp = data;
            }
        } else {
            dataTemp = data;
        }

        return dataTemp;
    };

    getScheme = () => {
        let scheme = {
            name: "importEmployees_Table",
            filter: true,
            showFooter: true,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                { name: 'rowNumber', header: '##', width: 70, dataType: 'Number', aggregate: 'Cnt', isReadOnly: true },
                { name: 'selected', header: $.strings.importEmployees.select, width: 70 },
                { name: "code", header: $.strings.importEmployees.empCode, width: 120, isReadOnly: true },
                { name: "name", header: $.strings.importEmployees.name, width: '*', minWidth: 180, isReadOnly: true },
                { name: "ename", header: $.strings.importEmployees.ename, width: '*', minWidth: 180, isReadOnly: true },
                { name: "idNo", header: $.strings.importEmployees.idNo, width: 120, isReadOnly: true },
                { name: "card", header: $.strings.importEmployees.card, width: 120, isReadOnly: true },
                { name: "period", header: $.strings.importEmployees.period, width: 100, isReadOnly: true },
                { name: "shift", header: $.strings.importEmployees.shift, width: 100, isReadOnly: true },
                { name: "section_code", header: $.strings.importEmployees.section, width: 100, isReadOnly: true },
                { name: "co_dep", header: $.strings.importEmployees.department, width: 100, isReadOnly: true },
                { name: "co_branch", header: $.strings.importEmployees.branch, width: 100, isReadOnly: true },
                { name: "city", header: $.strings.importEmployees.city, width: 100, isReadOnly: true },
                { name: "town", header: $.strings.importEmployees.town, width: 100, isReadOnly: true },
                { name: "phone", header: $.strings.importEmployees.phone, width: 100, isReadOnly: true },
                { name: "mobile1", header: $.strings.importEmployees.mobile, width: 100, isReadOnly: true },
                { name: "email", header: $.strings.importEmployees.email, width: 100, isReadOnly: true },
                { name: "sex", header: $.strings.importEmployees.sex, width: 100, isReadOnly: true },
                { name: "d_app", header: $.strings.importEmployees.d_app, width: 100, isReadOnly: true },
                { name: "d_fin", header: $.strings.importEmployees.d_fin, width: 100, isReadOnly: true },
                { name: "direct_manager", header: $.strings.importEmployees.direct_manager, width: 100, isReadOnly: true },
                { name: "classification", header: $.strings.importEmployees.classification, width: 100, isReadOnly: true },
                { name: "other_classification", header: $.strings.importEmployees.other_classification, width: 100, isReadOnly: true },
                { name: "Job_code", header: $.strings.importEmployees.Job_code, width: 100, isReadOnly: true },
                { name: "import_note", header: $.strings.importEmployees.import_note, width: 100, isReadOnly: true },

            ]
        }
        scheme = Util.handleGridColumns(this.grid, scheme);
        return scheme;
    }

}

function getDataFromSheet(self, worksheet, columnsList) {
    let data = [];
    let rows = worksheet.rows;

    for (let i = 0; i < rows.length; i++) {
        let item = {};
        if (self.state.containsTitleLine && i === 0) {
            continue;
        }
        rows[i].cells.forEach((cell, index) => {
            item.rowNumber = i; // متسلسل
            item.selected = false;

            columnsList.forEach(function (col, index) {
                if (col.excelColumn && col.excelColumn.toString().trim().length > 0 && col.excelIndex >= 0) {
                    item[col.name] = rows[i].cells[col.excelIndex] ? rows[i].cells[col.excelIndex].value : '';
                }
            });
        });
        data.push(item);
    }
    return { data: data };
}

function getExcelColumns(self, worksheet) {
    let excelColumns = [];
    let rows = worksheet.rows;

    let item = {};
    let cellIndex = 0;
    rows[0].cells.forEach((cell, index) => {
        item = {};
        item.name = cell.value ? cell.value : ''; // اسم العمود
        item.index = cellIndex; // الرقم
        item.excelColumn = String.fromCharCode(cellIndex + 65);
        excelColumns.push(item);
        cellIndex++;
    });
    return excelColumns;
}