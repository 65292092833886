import "react-app-polyfill/ie9";
import React from 'react';
import ReactDOM from 'react-dom';

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./index.scss";
import "normalize.css";
import "./Global";
import "./polyfill";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "./primeflex.scss";
import 'primereact/resources/primereact.css';

import AppWrapper from './AppWrapper';
import { HashRouter } from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'prismjs/themes/prism-coy.css';

// START ROMA IMPORTS
import 'core-js/stable';

ReactDOM.render(
  <Router>
    <AppWrapper/>
  </Router>,
  document.getElementById('root')
);