import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Util from './Util'
import { Badge } from 'primereact/badge';
import Button from './Components/Button'

const $ = window.$;
var pjson = require('../package.json');

export class AppTopbar extends Component {

    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        onRightMenuButtonClick: null,
        topbarMenuActive: false,
        activeTopbarItem: null,
        inlineUser: null,
        onThemeChange: null
    }

    static propTypes = {
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        onRightMenuButtonClick: PropTypes.func.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string,
        inlineUser: PropTypes.bool,
        onThemeChange: PropTypes.func
    }

    constructor() {
        super();
        this.state = {};
    }

    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
        //alert('notification clicked')
    }

    render() {

        return <div className="layout-topbar">
            <div style={{ padding: '12px 20px' }}>
                {/* <button className="p-link layout-right-panel-button layout-topbar-icon" onClick={this.props.onRightMenuButtonClick}>
                    <i className="pi pi-ellipsis-v"></i>
                </button> */}

                <button className="p-link layout-menu-button layout-topbar-icon" onClick={this.props.onMenuButtonClick}>
                    <i className="pi pi-bars"></i>
                </button>

                <button className="p-link layout-topbar-logo" onClick={() => window.location = "/"}>
                    <div>
                        {/* <img id="topbar-logo" src="../../assets/layout/images/logo-roma-white.svg" alt="HR Self Service" style={{ width: '40px', height: '35px' }} /> */}
                        <img id="topbar-logo" src="../../assets/layout/images/logo-roma-white.png" alt="HR Self Service" style={{ width: '35px', height: '35px' }} />
                    </div>
                    <span className="version-no">
                        {pjson.version}
                    </span>
                </button>

                {/* <span className="layout-topbar-search">
                    <i className="pi pi-search"></i>
                    <input type="text" placeholder={$.strings.searchTerm} />
                </span> */}

                <ul className="topbar-menu">
                    <li className={classNames('user-profile', { 'active-topmenuitem fadeInDown': this.props.activeTopbarItem === 'profile' })}>
                        {!this.props.inlineUser && <button className="p-link" onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
                            {
                                localStorage.getItem("userImageHR") && localStorage.getItem("userImageHR").length > 0
                                    ?
                                    <img src={`data:image/jpeg;base64,${localStorage.getItem("userImageHR")}`} alt="user-pic" style={{ borderRadius: '50%' }} />
                                    :
                                    <img src="../../assets/layout/images/avatar.png" alt="user-pic" />
                            }
                            <div className="layout-profile-userinfo">
                                <span className="layout-profile-name">{localStorage.getItem("empLanguageHR") + "" === '1' ? localStorage.getItem("userNameHR") : localStorage.getItem("userNameEnHR")}</span>
                                <span className="layout-profile-role">{localStorage.getItem("empLanguageHR") + "" === '1' ? localStorage.getItem("roleHR") : localStorage.getItem("roleEnHR")}</span>
                            </div>
                        </button>}

                        <ul className="fadeInDown">
                            {/* <li role="menuitem">
                                <button className="p-link">
                                    <i className="pi pi-fw pi-user"></i>
                                    <span>{$.strings.profile}</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link">
                                    <i className="pi pi-fw pi-cog"></i>
                                    <span>{$.strings.settings}</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link">
                                    <i className="pi pi-fw pi-envelope">
                                    </i>
                                    <span>{$.strings.messages}</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link">
                                    <i className="pi pi-fw pi-bell"></i>
                                    <span>{$.strings.notifications}</span>
                                </button>
                            </li> */}
                            {
                                !Util.checkISHR() && <li role="menuitem">
                                    <button className="p-link" onClick={this.props.onEmployeeInfo} >
                                        <i className="pi pi-user"></i>
                                        &nbsp;&nbsp;{$.strings.employeeInfo.title}
                                    </button>
                                </li>
                            }
                            <li role="menuitem">
                                <button className="p-link" onClick={this.props.onChangePwd} >
                                    <i className="pi pi-key"></i>
                                    &nbsp;&nbsp;{$.strings.changePassword}
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link" onClick={this.props.onLogOut} style={{ color: 'red' }}>
                                    <i className="pi pi-sign-out"></i>
                                    &nbsp;&nbsp;{$.strings.logOut}
                                </button>
                            </li>
                        </ul>
                    </li>

                    <li className={classNames({ 'active-topmenuitem fadeInDown': this.props.activeTopbarItem === 'settings' })}>
                        <button className="p-link layout-topbar-icon" onClick={e => Util.goTo('../HandleNotifications')} >
                            <i className="topbar-icon pi pi-fw pi-bell p-overlay-badge" >
                                <Badge value={localStorage.getItem("notCount")} severity="danger" style={{ fontSize: '1.5rem', color: 'red', fontWeight: 'bold' }} />
                            </i>
                        </button>
                        {/*<ul className="fadeInDown">
                            <li role="menuitem">
                                <button className="p-link">
                                    <img src="../../assets/layout/images/avatar-1.png" alt="roma-react" />
                                    <div className="topbar-menu-info">
                                        <span className="topbar-menu-name">Bithika Abhedananda</span>
                                        <span className="topbar-menu-role">User interface review is done.</span>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link">
                                    <img src="../../assets/layout/images/avatar-2.png" alt="roma-react" />
                                    <div className="topbar-menu-info">
                                        <span className="topbar-menu-name">Dai Jiang</span>
                                        <span className="topbar-menu-role">Uh, we have sort of a problem here.</span>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link">
                                    <img src="../../assets/layout/images/avatar-3.png" alt="roma-react" />
                                    <div className="topbar-menu-info">
                                        <span className="topbar-menu-name">Karlien Nijhuis</span>
                                        <span className="topbar-menu-role">You apparently didn’t put the thing</span>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link">
                                    <img src="../../assets/layout/images/avatar-4.png" alt="roma-react" />
                                    <div className="topbar-menu-info">
                                        <span className="topbar-menu-name">Tom Chun</span>
                                        <span className="topbar-menu-role">Please check the files</span>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link">
                                    <img src="../../assets/layout/images/avatar-5.png" alt="roma-react" />
                                    <div className="topbar-menu-info">
                                        <span className="topbar-menu-name">Maria Trofimova</span>
                                        <span className="topbar-menu-role">Meeting reports attached.</span>
                                    </div>
                                </button>
                            </li> 
                        </ul>*/}
                    </li>
                </ul>
            </div>
        </div >;
    }
}
