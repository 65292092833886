import React from "react";
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import ScrollTop from '../ScrollButton';
import { OrganizationChart } from 'primereact/organizationchart';
import './OrganizationChartDemo.css';

const $ = window.$;

export default class OrgChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDesktop: window.innerWidth > 1250,
            data: []
        };
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(906)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
            return;
        }
        
        await this.getData();
        let _isDesktop = this.updatePredicate();
        this.setState({ isDesktop: _isDesktop, loading: false })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    render = () => {
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../MyStaff' },
            { label: $.strings.orgChart.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card style={{ overflowX: 'scroll', minHeight: '550px' }}>
                <div className={`${"p-grid"} ${"organizationchart-demo"}`}>
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        {this.state.data && this.state.data.length > 0 && <OrganizationChart value={this.state.data} />}
                    </div>
                </div>
            </Card>
            <ScrollTop />
        </div >
    }

    nodeTemplate = (node) => {
        if (node && node.type === "person") {
            return (
                <div>
                    <div className="node-header">{node.label}</div>
                    <div className="node-content">
                        <img alt={"1"} src='../../assets/layout/images/avatar-2.png' style={{ width: '32px' }} />
                        <div>{node.data ? node.data.name : ''}</div>
                    </div>
                </div>
            );
        }

        if (node && node.type === "department") {
            return node.label;
        }
    }


    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }

        let res = await http.getJsonAxios(http.actions.OrgChart.urlGetSectionsAndFather, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.listTree;
        }
        
        this.setState({ data: dataTemp, loading: false });
    }
}