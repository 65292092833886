import React from "react";
import styles from './Memos.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import SiteMap from '../../Components/SiteMap';
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import InputTextArea from '../../Components/InputTextArea';
import ScrollTop from '../ScrollButton';
import WFTrans from '../WFTrans';
import Attachments from '../Attachments';
import { FileUpload } from "primereact/fileupload";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class Memos extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { empCode: '', empName: '', memoType: '', notes: '' },
            deleteErrors: { deleteNote: '' },
            memoTypesList: [],
            isDesktop: window.innerWidth > 1250,
            dataObject: {
                id: 0,
                empCode: this.props.match.params.empCode || localStorage.getItem("useridHR") || '',
                empName: Util.getEmployeeInfo() || '',
                memoType: '',
                notes: '',
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: 0,
                status: 1
            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(607)) {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = this.updatePredicate();

        let memosTypes = await this.getMemosTypes()


        if (!this.props.match.params.id) {
            let empCode = this.props.match.params.empCode || localStorage.getItem("useridHR") + "";
            let empName = Util.getEmployeeInfo() || ''
            if (empCode !== localStorage.getItem("useridHR") + "") {
                let obj = await this.getEmployeeInfo(empCode);
                empCode = obj.Code;
                empName = obj.Name;
            }
            this.setState({ dataObject: { ...this.state.dataObject, empCode: empCode, empName: empName }, isDesktop: _isDesktop, memoTypesList: memosTypes, loading: false })
        }
        else
            this.setState({ isDesktop: _isDesktop, memoTypesList: memosTypes })

        if (this.props.match.params.id) {
            let data = await this.showMemoInfo(this.props.match.params.id)
            this.loadData(data)
        }
        setTimeout(() => {
            if (this.drpMemoType) {
                this.drpMemoType.focusInput.focus()
            }
        }, 10);
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _empCode = this.state.dataObject.empCode
        fieldValidationErrors._empCode = _empCode ? '' : $.strings.requiredFiled;

        let _empName = this.state.dataObject.empName;
        fieldValidationErrors.empName = _empName ? '' : $.strings.requiredFiled;

        let _memoType = this.state.dataObject.memoType;
        fieldValidationErrors.memoType = _memoType ? '' : $.strings.requiredFiled;

        let _notes = this.state.dataObject.notes;
        fieldValidationErrors.notes = _notes ? '' : $.strings.requiredFiled;

        this.setState({
            formErrors: fieldValidationErrors,
            empCodeValid: _empCode,
            empNameValid: _empName,
            memoTypeValid: _memoType,
            notesValid: _notes,
        }, this.validateForm);

        return _empCode && _empName && _memoType && _notes;
    }
    validateForm() {
        let isFormValid = this.state.empCodeValid && this.state.empNameValid && this.state.memoTypeValid && this.state.notesValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {

        let siteMapItems = [
            { label: $.strings.links.requests.menu },
            {
                label: <div>
                    {$.strings.links.requests.memo + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];

        const siteMapHome = { icon: 'pi pi-home', url: "/", command: e => alert($.strings.links.Files.branches) }


        return (
            <div ref="divMain" className={styles.addMemo}>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12">
                            <SiteMap model={siteMapItems} home={siteMapHome} />
                        </div>
                    </div>
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={this.state.dataObject.id <= 0 ? this.resetFields : undefined}
                            onSave={this.state.dataObject.id <= 0 ? this.onSave : undefined}
                            onTransDetails={this.state.dataObject.id > 0 ? this.wfTransDetails : undefined}
                            onShowAttachment={this.state.dataObject.id > 0 && this.state.dataObject.file_id > 0 ? this.onShowAttachment : undefined}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid">
                    <div className="p-lg-12 p-sm-12">
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>

                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnPrevVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onPrevious(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNextVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNext(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnFirstVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onFirst(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnLastVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onLast(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnDeleteVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onDelete(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloneVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onClone(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloseVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.btnClose(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />

                <DeleteNoteDialog ref={ref => this.deleteNoteDlg = ref} visible={this.state.dlgDeleteNoteVisible} deleteErrors={this.state.deleteErrors} appendTo={this.props.parent}
                    onYes={this.btnDeleteYes} onNo={this.DeleteCancel} deleteNote='' />
                
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtCode = el}
                                type="text"
                                id="id"
                                keyfilter="pnum"
                                value={this.state.dataObject.id || ''}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.memos.code}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-lg-4 p-sm-12"></div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpCode = el}
                                type="text"
                                id="empCode"
                                keyfilter="pnum"
                                maxLength="4"
                                value={this.state.dataObject.empCode || ''}
                                autofocus={true}
                                autocomplete="off"
                                caption={$.strings.memos.empCode}
                                isRequired={true}
                                onChange={this.handleUserInput}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-4 p-lg-6 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpName = el}
                                type="text"
                                id="name"
                                maxLength="70"
                                value={this.state.dataObject.empName || ''}
                                autofocus={true}
                                caption={$.strings.memos.empName}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                readOnly={true}
                                disabled
                                style={{ width: '100%', color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                            <Dropdown
                                id="memoType"
                                innerRef={e => this.drpMemoType = e}
                                value={this.state.dataObject.memoType || ''}
                                options={this.state.memoTypesList}
                                showClear={true}
                                caption={$.strings.memos.memoType}
                                onChange={this.handleUserInput}
                                formErrors={this.state.formErrors}
                                optionLabel={localStorage.getItem("HRlang") === "ar" ? "name" : "ename"}                                
                                placeholder={$.strings.memos.selectMemoType}
                                filter={true}
                                filterBy="pos,name"
                                filterMatchMode="contains"
                                isRequired={true}
                                tooltip={$.strings.memos.memoType}
                                tooltipOptions={{ position: 'top' }}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-4 p-lg-4 p-md-12 p-sm-12">
                            <InputTextArea
                                innerRef={ref => this.txtNotes = ref}
                                type="text"
                                id="notes"
                                maxLength="100"
                                value={this.state.dataObject.notes}
                                caption={$.strings.memos.notes}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-4 p-lg-4 p-md-12 p-sm-12">
                            <div >
                                <label for="file-upload" className={styles.customFileUpload}>
                                    {$.strings.memos.uploadFile}
                                </label>
                                <FileUpload chooseLabel={$.strings.vacations.uploadFile} name="file-upload-prime" url=""
                                    onSelect={this.handleChangePrime} auto maxFileSize={5000000} disabled={this.state.dataObject.id > 0}
                                    invalidFileSizeMessageSummary={$.strings.fileSizeError}
                                    invalidFileSizeMessageDetail={""} />
                            </div>
                        </div>
                    </div>
                </Card>
                <ScrollTop />
                <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
                <Attachments ref={e => this.dlgAttachments = e}></Attachments>
            </div >
        );
    }

    getMemosTypes = async () => {
        let dataTemp = []
        let obj = {
            type: 26
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }

    getEmployeeInfo = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode,
            isHr: Util.checkISHR(),
            userId: localStorage.getItem('useridHR')
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeInfo, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployeeInfoObj;
        }
        return dataTemp
    }

    showMemoInfo = async (memoId) => {
        let dataTemp = []
        let obj = {
            userCode: 0,
            empId: localStorage.getItem('useridHR'),
            VchCode: memoId
        }
        let res = await http.getJsonAxios(http.actions.Memos.urlGetMemos, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    loadData = (data) => {
        let _isDesktop = this.updatePredicate();
        let memoType = this.state.memoTypesList.filter(element => element.pos + "" === data.memoType + "")
        if (memoType && memoType.length > 0)
            memoType = memoType[0]

        this.setState({
            mode: 'view',
            isDesktop: _isDesktop,
            dataObject: {
                ...this.state.dataObject,
                id: data.memoId,
                empCode: data.empCode,
                empName: data.empName,
                memoType: memoType,
                notes: data.memoNote,
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: data.memoValue
            }
            , loading: false
        })
    }

    handleChange = (event) => {
        if (event.target.files.length > 0) {
            let _uploadedFile = URL.createObjectURL(event.target.files[0])
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.target.files[0].size > 4 * 1024 * 1024) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                document.getElementById('file-upload').value = ''
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.target.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }

    handleChangePrime = (event) => {
        if (event.files.length > 0) {
            let _uploadedFile = event.files[0];
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.files[0].size > 5 * 1000 * 1000) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }

    setSubscriberImgSrc = () => {
        
        alert($.strings.fileUploaded)
    }

    wfTransDetails = async () => {
        let id = Util.encryptStringToHexa(this.state.dataObject.id);
        await this.wfTransDlg.show(7, id)
    }
    onShowAttachment = async () => {
        await this.dlgAttachments.show(7, this.state.dataObject.id)
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true })
        let files = [] // ملفات المرفقات
        let file = this.state.dataObject.uploadedFile2

        let obj = {
            serial: !this.state.dataObject.id ? 0 : this.state.dataObject.id,
            empcode: this.state.dataObject.empCode,
            memoType: this.state.dataObject.memoType.pos,
            note: this.state.dataObject.notes,
            userCode: localStorage.getItem("useridHR") + ""
        }

        let res = await http.postMultipartAxios(http.actions.Memos.urlPostSaveMemos, obj, file, files, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
                return true
            }
        }
        if (res.data.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return false
        }
    }

    resetFields = () => {
        this.setState({
            dataObject: {
                ...this.state.dataObject,
                id: 0,
                memoType: '',
                notes: '',
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: 0,
                status: 1
            },
            formErrors: { empCode: '', empName: '', memoType: '', notes: '' },
        }
            // , () => document.getElementById('file-upload').value = ''
        )
    }
}