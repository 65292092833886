import React from "react";
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import Calendar from '../../Components/Calendar';
import DataGrid from '../../Components/DataGridView/DataGridView';
import LoanDetails from "./LoanDetails";

const $ = window.$;

export default class Loans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            transTypeList: [],
            statusList: [],
            empCode: localStorage.getItem("useridHR"),
            empName: localStorage.getItem("userNameHR"),
            data: [],
            fromDate: new Date(2015, 0, 1),//new Date(new Date().getFullYear(), 0, 1),
            toDate: new Date()
        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(749)) {
                Util.goTo('/Access');
                return;
            }
        }
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.loans.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card>
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onShowFilter={e => this.showFilters(e)} />
            </Card>
            <Card id="divFilters">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                        <Input
                            id="empName"
                            value={this.state.empName}
                            caption={$.strings.loans.employee} />
                    </div>
                    <div className="p-col-6 p-lg-1 p-sm-12">
                        <Calendar
                            id="fromDate"
                            value={this.state.fromDate}
                            caption={$.strings.loans.fromDate}
                            onChange={this.handleUserInput}
                            readOnlyInput={true}
                            readOnly={true}
                        />
                    </div>
                    <div className="p-col-6 p-lg-1 p-sm-12">
                        <Calendar
                            id="toDate"
                            innerRef={(el) => this.dtpToDate = el}
                            value={this.state.toDate}
                            caption={$.strings.loans.toDate}
                            onChange={this.handleUserInput}
                            readOnlyInput={true}
                            readOnly={true}
                        />
                    </div>
                </div>
            </Card>
            <div className="p-grid">
                <div className="p-col-12 p-lg-12 p-sm-12">
                    <DataGrid
                        style={gridStyle}
                        ref={e => this.gridData = e}
                        isReport={true}
                        theme="default-light"
                        idProperty="serial"
                        exportFileName={$.strings.loans.title}
                        scheme={this.getScheme()}
                        dataSource={this.state.data}
                    />
                </div>
                <LoanDetails ref={e => this.dlgLoanDetails = e} />
            </div>

        </div >
    }

    getData = async () => {
        this.setState({ loading: true });
        let data = []
        let obj = {
            empCode: this.state.empCode,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetLoans, obj, 0);
        if (res.status === 200) {
            data = res.data;
        }

        this.setState({ data: data, loading: false });
    }

    excelExport = () => {
        this.gridData.exportCSV();
    }

    showFilters = (event) => {
        try {
            if (event.target && event.target.className === "pi pi-angle-double-down") {
                document.getElementById('divFilters').style.display = '';
                event.target.className = 'pi pi-angle-double-up';
                this.setState({ filterShown: true })
            }
            else if (event.target && event.target.className === "pi pi-angle-double-up") {
                document.getElementById('divFilters').style.display = 'none';
                event.target.className = 'pi pi-angle-double-down';
                this.setState({ filterShown: false })
            }
        }
        catch {

        }
    }
    buttonClick = (e, ctx) => {
        this.dlgLoanDetails.show(ctx.item.code);
    }
    getScheme = () => {
        return {
            name: "attendanceRptScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 4,
            columns: [

                {
                    name: 'ser', header: '##', width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'btn', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.view,
                    iconType: 'search', className: 'info', onClick: (e, ctx) => this.buttonClick(e, ctx)
                },
                {
                    name: 'code', header: $.strings.loans.code, width: 100

                },
                {
                    name: 'empCode', header: $.strings.loans.empCode, width: 120

                },
                {
                    name: 'empName', header: $.strings.loans.empName, width: "2*"

                },
                {
                    name: 'loanDate', header: $.strings.loans.loanDate, width: 110

                },
                {
                    name: 'loanAmount', header: $.strings.loans.loanAmount, width: 120, dataType: 'Number', aggregate: 'Sum'

                },
                {
                    name: 'paymentCount', header: $.strings.loans.paymentCount, width: 120

                },
                {
                    name: 'paidAmount', header: $.strings.loans.paidAmount, width: 120, dataType: 'Number', aggregate: 'Sum'
                },
                {
                    name: 'remainingAmount', header: $.strings.loans.remainingAmount, width: 120, dataType: 'Number', aggregate: 'Sum'
                },
                {
                    name: 'loanType', header: $.strings.loans.loanType, width: 120,
                    visible: this.state.data.filter(e => e.loanType && e.loanType.length).length > 0
                },
                {
                    name: 'loanClass', header: $.strings.loans.loanClass, width: 120,
                    visible: this.state.data.filter(e => e.loanClass && e.loanClass.length).length > 0
                },
                {
                    name: 'notes', header: $.strings.loans.notes, width: "2*",
                    //visible: this.state.data.filter(e => e.notes && e.notes.length).length > 0
                }
            ]
        }
    }
}