import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import DataGridView from "../../Components/DataGridView/DataGridView";
import Messages from "../../Components/Messages";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class EquationDialog extends React.Component {

    constructor(props) {
        super(props);
        this.emptyObj = {

        }
        this.state = {
            loading: true,
            isDesktop: true,
            pageInfo: this.setPageInfo(),
            empCode: localStorage.getItem("useridHR"),
            data: [
                { RowNumber: 0, description: $.strings.equationDialog.requiredWorkHours, shortcut: '[Req]' },
                { RowNumber: 1, description: $.strings.equationDialog.morningLate, shortcut: '[Late]' },
                { RowNumber: 2, description: $.strings.equationDialog.specialLeave, shortcut: '[Leave]' },
                { RowNumber: 3, description: $.strings.equationDialog.leaveBeforeWorkEnd, shortcut: '[BefOut]' },
                { RowNumber: 4, description: $.strings.equationDialog.totalWorkHours, shortcut: '[ToHour]' },
                { RowNumber: 5, description: $.strings.equationDialog.totalDuringWorkHours, shortcut: '[ToInReq]' },
                { RowNumber: 6, description: $.strings.equationDialog.absenceW, shortcut: '[AbsW]' },
                { RowNumber: 7, description: $.strings.equationDialog.absenceWO, shortcut: '[AbsWO]' },
                { RowNumber: 8, description: $.strings.equationDialog.annualVacations, shortcut: '[Annual]' },
                { RowNumber: 9, description: $.strings.equationDialog.sickVacation, shortcut: '[Sick]' },
                { RowNumber: 10, description: $.strings.equationDialog.paidVacations, shortcut: '[VacWO]' }
            ],
            equation: '',
            dataObject: this.emptyObj
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();
        let equation = await Util.GetSettingById(this.props.equationId);
        this.setState({ isDesktop: _isDesktop, equation: equation, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    render() {
        const gridStyle = { minHeight: '60vh' }

        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.ok} icon="" className="p-button-success" onClick={async () => await this.onSave()}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <Dialog header={this.state.pageInfo.title} footer={footer} icons={myIcon} visible={true}
                    style={{ width: this.state.isDesktop ? '40vw' : '80vw', direction: $.strings.dir }}
                    modal blockScroll onHide={this.onHide} >
                    <div ref="divMain" >
                        <ProgressSpinner loading={this.state.loading} />
                        <Messages innerRef={(el) => this.messages = el} />
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                <Input
                                    id="equation"
                                    value={this.state.equation}
                                    onChange={this.handleUserInput}
                                    caption={$.strings.equationDialog.title}
                                    style={{ direction: 'ltr' }}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                <DataGridView
                                    style={gridStyle}
                                    ref={e => this.gridData = e}
                                    isReport={true}
                                    showContextMenu={false}
                                    theme="default-light"
                                    idProperty="RowNumber"
                                    exportFileName={$.strings.employeeTimeCommitRpt.title}
                                    scheme={this.getScheme()}
                                    dataSource={this.state.data}
                                    defaultRowHeight={45}
                                />
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div>
        );
    }

    onHide = () => {
        if (this.props.onClose())
            this.props.onClose();
    }

    onSave = async () => {
        this.setState({ loading: true })
        let params = {
            pos: parseInt(this.props.equationId),
            value: this.state.equation + "",
            userId: localStorage.getItem('useridHR'),
        }

        let res = await http.postMultipartAxios(http.actions.Settings.urlUpdateSettings, params, this.state.pageInfo.pageId);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                Util.showSuccessMsg(this.messages);
                // if (this.props.onSuccess) {
                //     this.props.onSuccess();
                // }
                this.setState({ loading: false })
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data && res.data.message)
                    msg = res.data.message;
                Util.showErrorMsg(this.messages, msg);
                this.setState({ loading: false })
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.message)
                msg = res.data.message;
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false })
        }
    }

    btnAdd = (e, ctx) => {
        let equation = this.state.equation;
        equation += ctx.item.shortcut;
        this.setState({ equation: equation })
    }

    getScheme = () => {
        return {
            name: "equationDialogScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [

                { name: 'RowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt' },
                {
                    name: 'btnAdd', header: ' ', width: 60, visible: Util.checkISHR(), buttonBody: 'button', align: 'center',
                    title: $.strings.add, iconType: 'add', className: 'success', onClick: (e, ctx) => this.btnAdd(e, ctx)
                },
                { name: 'description', header: $.strings.equationDialog.description, width: '*', minWidth: 120 },
                { name: 'shortcut', header: $.strings.equationDialog.shortcut, width: 120 }
            ]
        }
    }

    setPageInfo = () => {
        let pageId
        let title = $.strings.equationDialog.title

        return { pageId: pageId, title: title };
    };
}