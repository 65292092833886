import React from "react";
import styles from './Workflow.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import Calendar from '../../Components/Calendar'
import SiteMap from '../../Components/SiteMap';
import ScrollTop from '../ScrollButton';
import Editor from '../../Components/InputEditor';
import Checkbox from "../../Components/Checkbox";
import DataGridView from "../../Components/DataGridView/DataGridView";
import { Fieldset } from 'primereact/fieldset';
import RadioButton from "../../Components/RadioButton";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class Workflow extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.nowDate = new Date();
        this.actionsList = [
            { id: "0", name: $.strings.wfDefinition.none },
            { id: "1", name: $.strings.wfDefinition.approve },
            { id: "2", name: $.strings.wfDefinition.reject },
            { id: "3", name: $.strings.wfDefinition.transferToDM }
        ]
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { type: '', repeatedNotificationDays: '', notifyBefore: '' },
            deleteErrors: { deleteNote: '' },
            isDesktop: window.innerWidth > 1250,
            wfTypesList: [],
            wfRolesList: [],
            subTypesList: [],
            data: [],
            finalAction: false,
            dataObject: {
                id: 0,
                type: undefined,
                role: undefined,
                steps: 0,
                period: 0,
                lateDays: 0,
                action: this.actionsList[0],
                sameBranch: false,
                sameDepartment: false,
                viewOnly: false,
                sectionManagerOnly: false,
                finalStepAction: false
            }
        }
    }
    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }
        if (!Util.checkRights(734)) {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = window.innerWidth > 1250;
        let wfTypes = await this.getWFTypes();
        let wfRoles = await this.getWFRoles();

        this.setState({
            data: [], wfTypesList: wfTypes, wfRolesList: wfRoles,
            dataObject: {
                ...this.state.dataObject, type: wfTypes && wfTypes.length > 0 ? wfTypes[0] : undefined,
                role: wfRoles && wfRoles.length > 0 ? wfRoles[0] : undefined
            },
            isDesktop: _isDesktop, loading: false
        });
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }
    handleType = (e) => {
        let typeId = e.target.value.ID;
        this.setState({ dataObject: { ...this.state.dataObject, type: e.target.value } },
            async () => {
                await this.fillComboByType(typeId);
            }
        );
    }
    handleSubType = (e) => {
        let subTypeId = e.target.value.ID;
        this.setState({ dataObject: { ...this.state.dataObject, subType: e.target.value }, data: [] },
            async () => {
                if (subTypeId && subTypeId > 0) {
                    await this.getData()
                }
            }
        );

    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;

        let _type = true;
        fieldValidationErrors.type = '';
        if (!this.state.dataObject.type || this.state.dataObject.type.ID <= 0) {
            fieldValidationErrors.type = $.strings.requiredFiled;
            _type = false;
        }
        fieldValidationErrors.role = '';
        if (!this.state.dataObject.role) {
            fieldValidationErrors.role = $.strings.requiredFiled;
            _type = false;
        }
        let _subType = true;
        if (this.state.subTypesList && this.state.subTypesList.length > 0) {

            fieldValidationErrors.subType = '';
            if (!this.state.dataObject.subType || this.state.dataObject.subType.ID < 0) {
                fieldValidationErrors.subType = $.strings.requiredFiled;
                _subType = false;
            }
        }
        if (this.state.dataObject.viewOnly && this.state.dataObject.finalStepAction) {
            Util.showErrorMsg(this.messages, $.strings.wfDefinition.lastStepActionError);
            return false;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            typeValid: _type,
            subTypeValid: _subType
        }, this.validateForm);

        return _type && _subType
    }
    validateForm() {
        let isFormValid = this.state.typeValid && this.state.subTypeValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        let siteMapItems = [
            { label: $.strings.links.staticFilesAndSettings },
            {
                label: <div>
                    {$.strings.wfDefinition.title + " "}
                </div>
            }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        const gridStyle = { minHeight: '45vh' }
        return (
            <div ref="divMain" className={styles.substituteSettings}>
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={this.resetFields}
                            onSave={this.onSave}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid" style={{ width: '100%' }}>
                    <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Input
                                id="id"
                                keyfilter="pnum"
                                value={this.state.dataObject.id || ''}
                                caption={$.strings.wfDefinition.id}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="type"
                                value={this.state.dataObject.type || ''}
                                options={this.state.wfTypesList}
                                caption={$.strings.wfDefinition.type}
                                onChange={this.handleType}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                optionLabel="Name"
                                filter
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="subType"
                                value={this.state.dataObject.subType || ''}
                                options={this.state.subTypesList}
                                caption={$.strings.wfDefinition.subType}
                                onChange={this.handleSubType}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                optionLabel="Name"
                                filter
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="role"
                                value={this.state.dataObject.role || ''}
                                options={this.state.wfRolesList}
                                caption={$.strings.wfDefinition.role}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                optionLabel="Name"
                                filter
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Input
                                id="steps"
                                keyfilter="pnum"
                                value={this.state.dataObject.steps}
                                caption={$.strings.wfDefinition.steps}
                                min={0}
                                onChange={this.handleUserInput}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Input
                                id="period"
                                keyfilter="pnum"
                                value={this.state.dataObject.period}
                                caption={$.strings.wfDefinition.period}
                                min={0}
                                onChange={this.handleUserInput}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Input
                                id="lateDays"
                                keyfilter="pnum"
                                value={this.state.dataObject.lateDays}
                                caption={$.strings.wfDefinition.lateDays}
                                min={0}
                                onChange={this.handleUserInput}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="action"
                                value={this.state.dataObject.action}
                                options={this.actionsList}
                                caption={$.strings.wfDefinition.action}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                optionLabel="name"
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox value="sameBranch" caption={$.strings.wfDefinition.sameBranch}
                                checked={this.state.dataObject.sameBranch}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, sameBranch: e.checked } })} />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox value="sameDepartment" caption={$.strings.wfDefinition.sameDepartment}
                                checked={this.state.dataObject.sameDepartment}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, sameDepartment: e.checked } })} />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox value="viewOnly" caption={$.strings.wfDefinition.viewOnly}
                                checked={this.state.dataObject.viewOnly}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, viewOnly: e.checked } })}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox value="sectionManagerOnly" caption={$.strings.wfDefinition.sectionManagerOnly}
                                checked={this.state.dataObject.sectionManagerOnly}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, sectionManagerOnly: e.checked } })}
                            />
                        </div>
                        {
                            this.state.finalAction &&
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Fieldset legend={$.strings.wfDefinition.finalStepAction}>
                                    <div className="p-grid" >
                                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                            <RadioButton
                                                inputId="rbYes" value={$.strings.wfDefinition.yes} name="finalStepAction" checked={this.state.dataObject.finalStepAction}
                                                onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, finalStepAction: true } })}

                                            ></RadioButton>
                                        </div>
                                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                            <RadioButton
                                                inputId="rbNo" value={$.strings.wfDefinition.no} name="finalStepAction" checked={!this.state.dataObject.finalStepAction}
                                                onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, finalStepAction: false } })}

                                            ></RadioButton>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                        }
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataGridView
                                style={gridStyle}
                                ref={e => this.gridData = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="rowNumber"
                                scheme={this.getScheme()}
                                dataSource={this.state.data}
                                exportFileName={$.strings.wfDefinition.time}
                            />
                        </div>
                    </div>
                </Card >
                <ScrollTop />
            </div >
        );
    }

    getWFTypes = async () => {
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetAllWFTypes, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getWFRoles = async () => {
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetRoles, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }

    getData = async (id) => {
        this.setState({ loading: true });
        let dataTemp = []
        let obj = {
            type: this.state.dataObject.type ? this.state.dataObject.type.ID : 0,
            subType: this.state.dataObject.subType ? this.state.dataObject.subType.ID : 0,
        }
        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetWorkFlowData, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        this.setState({ data: dataTemp, loading: false });
    }
    fillComboByType = async (typeId) => {
        this.setState({ loading: true });
        let dataTemp = []
        switch (typeId + "") {
            case "1":
                {
                    dataTemp = await this.fillCmbSubTypes(22);
                    this.setState({
                        data: [], subTypesList: dataTemp, loading: false, finalAction: true,
                        dataObject: {
                            ...this.state.dataObject,
                            subType: dataTemp && dataTemp.length > 0 ? dataTemp[0] : undefined
                        }
                    });
                    break;
                }
            case "2":
                {
                    this.setState({
                        data: [], finalAction: false, subTypesList: [], dataObject: {
                            ...this.state.dataObject, subType: undefined
                        }
                    }, async () => await this.getData());
                    break;
                }
            case "3":
                {
                    this.setState({
                        data: [], finalAction: false, subTypesList: [], dataObject: {
                            ...this.state.dataObject, subType: undefined
                        }
                    }, async () => await this.getData());
                    break;
                }
            case "4":
                {
                    this.setState({
                        data: [], finalAction: false, subTypesList: [], dataObject: {
                            ...this.state.dataObject, subType: undefined
                        }
                    }, async () => await this.getData());
                    break;
                }
            case "5":
                {
                    dataTemp = await this.fillCmbTypeWithEmpChanges();
                    this.setState({
                        data: [], subTypesList: dataTemp, finalAction: false, loading: false,
                        dataObject: {
                            ...this.state.dataObject,
                            subType: dataTemp && dataTemp.length > 0 ? dataTemp[0] : undefined
                        }
                    });
                    break;
                }
            case "6":
                {
                    this.setState({
                        data: [], finalAction: false, subTypesList: [], dataObject: {
                            ...this.state.dataObject, subType: undefined
                        }
                    }, async () => await this.getData());
                    break;
                }
            case "7":
                {
                    dataTemp = await this.fillCmbSubTypes(26);
                    this.setState({
                        data: [], subTypesList: dataTemp, finalAction: false, loading: false,
                        dataObject: {
                            ...this.state.dataObject,
                            subType: dataTemp && dataTemp.length > 0 ? dataTemp[0] : undefined
                        }
                    });
                    break;
                }
            case "8":
                {
                    this.setState({
                        data: [], finalAction: false, subTypesList: [], dataObject: {
                            ...this.state.dataObject, subType: undefined
                        }
                    }, async () => await this.getData());
                    break;
                }
            case "9":
                {
                    this.setState({
                        data: [], finalAction: false, subTypesList: [], dataObject: {
                            ...this.state.dataObject, subType: undefined
                        }
                    }, async () => await this.getData());
                    break;
                }
            case "10":
                {
                    this.setState({
                        data: [], finalAction: false, subTypesList: [], dataObject: {
                            ...this.state.dataObject, subType: undefined
                        }
                    }, async () => await this.getData());
                    break;
                }
            case "11":
                {
                    this.setState({
                        data: [], finalAction: false, subTypesList: [], dataObject: {
                            ...this.state.dataObject, subType: undefined
                        }
                    }, async () => await this.getData());
                    break;
                }
            case "12":
                {
                    dataTemp = await this.fillCmbTransType_FingerPrint();
                    this.setState({
                        data: [], subTypesList: dataTemp, finalAction: false, loading: false,
                        dataObject: {
                            ...this.state.dataObject,
                            subType: dataTemp && dataTemp.length > 0 ? dataTemp[0] : undefined
                        }
                    });
                    break;
                }
            default: {
                this.setState({
                    data: [], finalAction: false, subTypesList: [], subType: undefined, loading: false,
                    dataObject: {
                        ...this.state.dataObject, subType: undefined
                    }
                });
                break;
            }
        }
    }
    fillCmbTransType_FingerPrint = () => {
        return [
            { ID: "-2", Name: " --- " },
            { ID: "1", Name: $.strings.fingerPrint.in },
            { ID: "2", Name: $.strings.fingerPrint.outJob },
            { ID: "3", Name: $.strings.fingerPrint.inJob },
            { ID: "4", Name: $.strings.fingerPrint.out },
        ]
    }
    fillCmbSubTypes = async (typeId) => {
        let dataTemp = []
        let obj = {
            type: typeId,
        }
        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetFillCmbSubTyps, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    fillCmbTypeWithEmpChanges = async (typeId) => {
        let dataTemp = []
        let obj = {
            type: typeId,
        }
        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetEmpsChangesTypes, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    loadData = (data) => {
        let notType = this.state.wfTypesList.find(element => element.ID + "" === data.NoteType + "");
        let notifyHR = true;
        if (data.NotifyHR === "1")
            notifyHR = false;
        let notifyEmployee = true;
        if (data.NotifyEmp === "1")
            notifyEmployee = false;
        let notifyDirectManager = true;
        if (data.NotifiyDirectManager === "1")
            notifyDirectManager = false;
        let sendEmail = true;
        if (data.SendEmail === "1")
            sendEmail = false;
        let repeatedNotification = true;
        if (data.FrequentNotification === "1")
            repeatedNotification = false;
        let repeatedNotificationHR = true;
        if (data.FrequentHR === "0")
            repeatedNotificationHR = false;
        let repeatedNotificationDM = true;
        if (data.FrequentDM === "0")
            repeatedNotificationDM = false;

        let repeatedNotificationDays = data.FrequentDays;
        let notifyBefore = data.Days;

        this.setState({
            mode: 'view',
            dataObject: {
                ...this.state.dataObject,
                id: data.ID,
                type: notType,
                notifyHR: notifyHR,
                notifyEmployee: notifyEmployee,
                notifyDirectManager: notifyDirectManager,
                sendEmail: sendEmail,
                repeatedNotification: repeatedNotification,
                repeatedNotificationHR: repeatedNotificationHR,
                repeatedNotificationDM: repeatedNotificationDM,
                repeatedNotificationDays: repeatedNotificationDays,
                notifyBefore: notifyBefore
            }, loading: false
        });
    }

    onSave = async () => {
        this.messages.clear();
        if (!this.validateField()) {
            return false;
        }

        this.setState({ loading: true })
        let res = undefined;
        if (this.state.dataObject.id === 0) {
            res = await this.saveData(false);
        }
        else {
            res = await this.saveData(true);
        }
        if (res.status === 200) {
            if (res.data.success) {
                let msg = $.strings.operationDone;
                Util.showSuccessMsg(this.messages, msg);
                this.resetFields()
                await this.getData();
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data.message && res.data.message.length > 0) {
                    msg = res.data.message;
                }
                Util.showErrorMsg(this.messages, msg);
            }
            this.setState({ loading: false });
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data.errorText && res.data.errorText.length > 0) {
                msg = res.data.errorText;
            }
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false });
        }
    }
    saveData = async (isUpdate) => {
        let obj = {
            period: this.state.dataObject.period,
            steps: this.state.dataObject.steps,
            roleType: this.state.dataObject.role.ID,
            subType: this.state.dataObject.subType && this.state.dataObject.subType.ID > 0 ? this.state.dataObject.subType.ID : 0,
            type: this.state.dataObject.type && this.state.dataObject.type.ID >= 0 ? this.state.dataObject.type.ID : 0,
            viewOnly: this.state.dataObject.viewOnly,
            sameBranch: this.state.dataObject.sameBranch,
            sameDepartment: this.state.dataObject.sameDepartment,
            isDepManager: this.state.dataObject.sectionManagerOnly,
            lateDaysCount: this.state.dataObject.lateDays,
            lateAction: this.state.dataObject.action && this.state.dataObject.action.id > 0 ? this.state.dataObject.action.id : 0,
            yesLastAction: this.state.dataObject.finalStepAction,
            ID: this.state.dataObject.id
        }

        let res = await http.getJsonAxios(http.actions.WFTrans.urlSaveWorkFlow, obj, null, null, 0);
        return res;
    }

    resetFields = () => {
        this.nowDate = new Date();
        this.setState({
            dataObject: {
                ...this.state.dataObject,
                id: 0,
                steps: 0,
                period: 0,
                lateDays: 0,
                action: this.actionsList[0],
                sameBranch: false,
                sameDepartment: false,
                viewOnly: false,
                sectionManagerOnly: false,
                finalStepAction: false
            },
            formErrors: { type: '', repeatedNotificationDays: '', notifyBefore: '' },
        });
    }

    onDelete = async (e, ctx) => {
        if (!window.confirm($.strings.wfDefinition.confirmDelete)) {
            return;
        }
        this.setState({ loading: true });
        let obj = {
            id: ctx.item.ID
        }
        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetDeleteWFStep, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                Util.showSuccessMsg(this.messages, $.strings.operationDone);
                await this.getData();
            }
            else {
                Util.showErrorMsg(this.messages, $.strings.operationFailed);
                this.setState({ loading: false });
            }
        }
        else {
            this.setState({ loading: false });
        }
    }
    onEdit = async (e, ctx) => {

        let objData = {
            id: ctx.item.ID,
            type: this.state.wfTypesList.find(e => e.ID + "" === ctx.item.TypeID + ""),
            subType: this.state.subTypesList.find(e => e.ID + "" === ctx.item.SubTypeID + ""),
            role: this.state.wfRolesList.find(e => e.ID + "" === ctx.item.RoleID + ""),
            steps: ctx.item.Steps,
            period: ctx.item.Period,
            lateDays: ctx.item.LateDaysCount,
            action: this.actionsList.find(e => e.id + "" === ctx.item.LateAction + ""),
            sameBranch: ctx.item.SameBranchVal,
            sameDepartment: ctx.item.SameDepartmentVal,
            viewOnly: ctx.item.ViewOnlyVal,
            sectionManagerOnly: ctx.item.IsDepManagerVal,
            finalStepAction: ctx.item.LastStepAction + "" === "0" ? false : true
        };
        this.setState({ dataObject: objData, loading: false });
    }
    getScheme = () => {
        return {
            name: "substituteScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 4,
            columns: [
                {
                    name: 'rowNumber', header: '#', width: 50, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'onDelete', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.delete,
                    iconType: 'delete', className: 'danget', onClick: (e, ctx) => this.onDelete(e, ctx)
                },
                {
                    name: 'onEdit', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.edit,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEdit(e, ctx)
                },
                {
                    name: 'ID', header: $.strings.wfDefinition.id, width: 50, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'TypeName', header: $.strings.wfDefinition.type, width: this.state.isDesktop ? 200 : 290,
                },
                {
                    name: 'SubTypeName', header: $.strings.wfDefinition.subType, width: 150
                },
                {
                    name: 'RoleName', header: $.strings.wfDefinition.role, width: 135,
                },
                {
                    name: 'Steps', header: $.strings.wfDefinition.steps, width: 100,
                },
                {
                    name: 'Period', header: $.strings.wfDefinition.period, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'RoleID', header: $.strings.wfDefinition.role, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'SubTypeID', header: $.strings.wfDefinition.subType, width: 120, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'TypeID', header: $.strings.wfDefinition.type, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'SameBranch', header: $.strings.wfDefinition.sameBranch, width: 110,
                },
                {
                    name: 'ViewOnly', header: $.strings.wfDefinition.viewOnly, width: 110
                },
                {
                    name: 'SameDepartment', header: $.strings.wfDefinition.sameDepartment, width: 110,
                },
                {
                    name: 'IsDepManager', header: $.strings.wfDefinition.sectionManagerOnly, width: 120
                },
                {
                    name: 'LateDaysCount', header: $.strings.wfDefinition.lateDays, width: 110
                },
                {
                    name: 'LateAction', header: $.strings.wfDefinition.action, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'LateActionName', header: $.strings.wfDefinition.action, width: 120,
                },
                {
                    name: 'LastStepAction', header: $.strings.wfDefinition.finalStepAction, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'LastStepActionName', header: $.strings.wfDefinition.finalStepAction, width: 130,
                }
            ]
        }
    }
}