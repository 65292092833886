import React from "react";
import Button from '../../Components/Button'
import Dialog from '../../Components/Dialog';
import VacEmpDetails from './VacEmpDetails';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class DMVacDecision extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            empCode: '',
            fromDate: '',
            toDate: '',
            msg: '',
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.yes} icon="" className="p-button-success" onClick={this.onYes}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
                <Button label={$.strings.no} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
                <Button label={$.strings.vacations.details} icon="" className="p-button-info" onClick={this.onDetails}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <Dialog header={$.strings.wfTrans.title} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                style={{ width: this.state.isDesktop ? '30vw' : '70vw', direction: $.strings.dir }}
                modal onHide={this.onHide}>
                <div ref="divMain" >
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            {this.state.msg}
                        </div>
                    </div>
                </div >

            </Dialog >
        );
    }
    show = async (msg) => {
        this.setState({
            visibleDialog: true, msg: msg
        })
    }

    onDetails = () => {
        if (this.props.onSuccess) {
            this.onHide();
            this.props.onSuccess("details");
        }
    }
    onYes = async () => {
        if (this.props.onSuccess) {
            this.onHide();
            await this.props.onSuccess("yes");
        }
    }

    onHide = () => {
        this.setState({ visibleDialog: false })
    }

}