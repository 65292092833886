import aesjs from 'aes-js';
import http from './Api/http';
import { confirmDialog } from 'primereact/confirmdialog';

const $ = window.$;
const stringHash = require("@sindresorhus/string-hash");


/*
Date.prototype.toJSON = function () {
    var timezoneOffsetInHours = -(this.getTimezoneOffset() / 60); //UTC minus local time

    timezoneOffsetInHours = $.utcOffset;

    var sign = timezoneOffsetInHours >= 0 ? '+' : '-';
    var leadingZero = (Math.abs(timezoneOffsetInHours) < 10) ? '0' : '';

    //It's a bit unfortunate that we need to construct a new Date instance 
    //(we don't want _this_ Date instance to be modified)
    var correctedDate = new Date(this.getFullYear(), this.getMonth(),
        this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds(),
        this.getMilliseconds());
    correctedDate.setHours(this.getHours() + timezoneOffsetInHours);
    var iso = correctedDate.toISOString().replace('Z', '');

    //return iso + sign + leadingZero + Math.abs(timezoneOffsetInHours).toString() + ':00';
    let value = iso + sign + leadingZero + Math.abs(timezoneOffsetInHours).toString() + ':00';

    return value;
}*/

const exportDefault = {
    navigationEnum: {
        FIRST: 1,
        LAST: 2,
        NEXT: 3,
        PREVIOUS: 4,
        GETIT: 5,
    },
    integ: {
        maxValue: 9999,
    },
    groupBy: (array, key, value) => {
        return array.reduce(function (prev, cur) {
            (prev[cur[key]] = prev[cur[key]] || []).push(cur[value]);
            return prev;
        }, {});
    },
    goTo: (path, state) => {
        window.$.history.push(path, state)
    },
    goToNewTab: (path, state) => {
        window.open(path, "_blank")
    },
    showInfoMsg(msgObj, msgText) {
        try {
            if (!msgText)
                msgText = $.strings.operationDone
            if (msgObj) {
                msgObj.replace({ severity: 'info', summary: '', detail: msgText, sticky: true });
            }
            else
                msgObj.show({ severity: 'info', summary: '', detail: msgText, sticky: true });
        }
        catch (e) {

        }
    },
    showErrorMsg(msgObj, msgText) {
        try {
            if (!msgText)
                msgText = $.strings.operationFailed
            if (msgObj) {
                msgObj.replace({ severity: 'error', summary: '', detail: msgText, sticky: true });
            }
            else
                msgObj.show({ severity: 'error', summary: '', detail: msgText, sticky: true });
        }
        catch (e) {

        }
    },
    showSuccessMsg(msgObj, msgText) {
        try {
            if (!msgText)
                msgText = $.strings.operationDone
            if (msgObj) {
                msgObj.replace({ severity: 'success', summary: '', detail: msgText, sticky: false });
            }
            else
                msgObj.show({ severity: 'success', summary: '', detail: msgText, sticky: false });
        }
        catch (e) {

        }
    },
    showWarningMsg(msgObj, msgText) {
        try {
            if (!msgText)
                msgText = $.strings.operationDone
            if (msgObj) {
                msgObj.replace({ severity: 'warn', summary: '', detail: msgText, sticky: true });
            }
            else
                msgObj.show({ severity: 'warn', summary: '', detail: msgText, sticky: true });
        }
        catch (e) {

        }
    },
    getCurrentDate(separator = '') {
        if (!separator || separator.length === 0)
            separator = "/"
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        let d = new Date(year, month - 1, date, 0, 0, 0, 0)
        return d.toLocaleDateString()
    },
    getCurrentDateTime(separator = '') {
        if (!separator || separator.length === 0)
            separator = "/"
        let newDate = new Date()
        let date = newDate.getDate()
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()
        let hours = newDate.getHours()
        let minutes = newDate.getMinutes()
        let seconds = newDate.getSeconds()

        //return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}${' '}${hours}${':'}${minutes}${':'}${seconds} `
        let d = new Date(year, month - 1, date, hours, minutes, seconds, 0)
        return d.toLocaleString()
    },
    getDifferentCurrTransList() {
        return [
            { name: $.strings.chartOfAccount.differentCurrTransVal1, code: 1 },
            { name: $.strings.chartOfAccount.differentCurrTransVal2, code: 2 },
            { name: $.strings.chartOfAccount.differentCurrTransVal3, code: 3 }
        ];
    },
    getCheckStatusName(checkStatusId) {
        switch (checkStatusId) {
            case 1: {
                return $.strings.checks.checkStatus1
            }
            case 2: {
                return $.strings.checks.checkStatus2
            }
            case 3: {
                return $.strings.checks.checkStatus3
            }
            case 4: {
                return $.strings.checks.checkStatus4
            }
            default: {
                return ''
            }
        }
    },
    getInactiveStatusColor() {
        return 'crimson'
    },
    getWeekDaysList() {
        return [
            { id: 1, name: $.strings.weekDays.saturday },
            { id: 2, name: $.strings.weekDays.sunday },
            { id: 3, name: $.strings.weekDays.monday },
            { id: 4, name: $.strings.weekDays.tuesday },
            { id: 5, name: $.strings.weekDays.wednesday },
            { id: 6, name: $.strings.weekDays.thursday },
            { id: 7, name: $.strings.weekDays.friday },
        ]
    },
    getWeekDayName(dayNo) {

        switch (dayNo) {
            case 6: {
                return $.strings.weekDays.saturday
            }
            case 0: {
                return $.strings.weekDays.sunday
            }
            case 1: {
                return $.strings.weekDays.monday
            }
            case 2: {
                return $.strings.weekDays.tuesday
            }
            case 3: {
                return $.strings.weekDays.wednesday
            }
            case 4: {
                return $.strings.weekDays.thursday
            }
            case 5: {
                return $.strings.weekDays.friday
            }
            default: {
                return ''
            }
        }
    },
    getVacationsTypes() {
        return [
            { id: 6, name: $.strings.vacations.sickVac },
            { id: 7, name: $.strings.vacations.annualVac },
            { id: 8, name: $.strings.vacations.paidVac },
            { id: 9, name: $.strings.vacations.unpaidVac },
        ]
    },
    getInOutTypes() {
        return [
            { id: 0, name: '---' },
            { id: 1, name: $.strings.fingerPrint.in },
            { id: 2, name: $.strings.fingerPrint.outJob },
            { id: 3, name: $.strings.fingerPrint.inJob },
            { id: 4, name: $.strings.fingerPrint.out },
        ]
    },
    getPeriodList() {
        return [
            { id: 0, name: $.strings.workLeave.lessThanDay },
            { id: 1, name: $.strings.workLeave.oneDay },
            { id: 2, name: $.strings.workLeave.severalDays },
            { id: 3, name: $.strings.workLeave.daysAndHours },
        ]
    },
    getCurrentTimePlusHour(date) {
        let day = date.getDay();
        let month = date.getMonth();
        let year = date.getFullYear();
        let hour = date.getHours();
        hour += 1;
        let minutes = date.getMinutes();

        return new Date(year, month, day, hour, minutes, 0);
    },
    getCurrentTimeMinusHour(date) {
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        let hour = date.getHours();
        hour -= 1;
        let minutes = date.getMinutes();

        return new Date(year, month, day, hour, minutes, 0);
    },
    getNumberByTimeFormat(value) {
        let Hours = 0;
        let val2 = 0;
        if (value % 60 === 0) {
            Hours = value / 60;
        }
        if (value % 60 >= 1) {
            Hours += parseInt((value / 60), 10);
            val2 = (value % 60);
        }
        else {
        }
        return { hours: Hours, minutes: val2 }
    },
    getEmployeeInfo() {
        if (localStorage.getItem("empLanguageHR") + '' === '2') {
            return localStorage.getItem("userNameEnHR") + "";
        }
        return localStorage.getItem("userNameHR") + "";
    },
    checkRights: (rightID) => {
        let rights = JSON.parse(localStorage.getItem("rightsHR"));
        if (!rights)
            return false;
        let hasRights = rights.filter(e => e.AccessID === rightID);
        return hasRights && hasRights.length > 0;
    },
    loadEmpSettings(empSettings) {
        // default settings
        localStorage.setItem("HR_layoutMode", 'static');
        localStorage.setItem("HR_lightMenu", true);
        localStorage.setItem("HR_topbarColor", localStorage.getItem('HR_topbarColor') || 'layout-topbar-blue');
        localStorage.setItem("HR_themeColor", localStorage.getItem('HR_themeColor') || 'blue');

        if (empSettings && empSettings.length > 0) {
            // 0 HR User Language
            let hrLanguage = empSettings.filter(element => element.SettingID === 0);
            if (hrLanguage && hrLanguage.length > 0) {
                localStorage.setItem("HRlang", "ar");
                if (hrLanguage[0].empLanguage === 1) {
                    localStorage.setItem("HRlang", "ar");
                }
                else if (hrLanguage[0].empLanguage === 2) {
                    localStorage.setItem("HRlang", "en");
                }
            }
            // 1 layout Mode
            let layoutMode = empSettings.filter(element => element.SettingID === 1);
            if (layoutMode && layoutMode.length > 0) {
                localStorage.setItem("HR_layoutMode", layoutMode[0].SettingValue);
            }
            // 2 light Menu
            let lightMenu = empSettings.filter(element => element.SettingID === 2);
            if (lightMenu && lightMenu.length > 0) {
                localStorage.setItem("HR_lightMenu", lightMenu[0].SettingValue);
            }
            // 3 topBar Color
            let topBarColor = empSettings.filter(element => element.SettingID === 3);
            if (topBarColor && topBarColor.length > 0) {
                localStorage.setItem("HR_topbarColor", topBarColor[0].SettingValue);
            }
            // 4 theme Color
            let themeColor = empSettings.filter(element => element.SettingID === 4);
            if (themeColor && themeColor.length > 0) {
                localStorage.setItem("HR_themeColor", themeColor[0].SettingValue);
            }
        }
    },
    encryptStringToHexa(txt) {
        txt = txt + '';
        const m = txt.length % 16;
        const rest = 16 - m;

        if (m + 1 == 16) {
            txt += '|';
        }
        for (let y = m + 1; y < 16; y++) {
            if (y === m + 1) txt += '|';
            txt += ';';
        }
        // An example 128-bit key
        var key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

        // The initialization vector (must be 16 bytes)
        var iv = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];

        // Convert text to bytes (text must be a multiple of 16 bytes)
        var text = txt;
        var textBytes = aesjs.utils.utf8.toBytes(text);

        var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);

        var encryptedBytes = aesCbc.encrypt(textBytes);

        // To print or store the binary data, you may convert it to hex
        var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
        // "104fb073f9a131f2cab49184bb864ca2"

        // When ready to decrypt the hex string, convert it back to bytes
        encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);
        return encryptedHex;
    },
    checkLicenseModules(moduleName) {
        let modules = JSON.parse(localStorage.getItem("licenseModulesHR"));
        // if (!modules)
        //     return false;
        // let hasRights = modules.filter(e => e.AccessID === rightID);
        // return hasRights && hasRights.length > 0;
    },
    checkISHR() {
        return localStorage.getItem("ISHR") && (localStorage.getItem("ISHR") + "" === "1" || localStorage.getItem("ISHR") + "" === "true") ? true : false
    },
    checkISManager() {
        return localStorage.getItem("isManagerHR") && (localStorage.getItem("isManagerHR") + "" === "1" || localStorage.getItem("isManagerHR") + "" === "true") ? true : false
    },
    getDelegationStatus(withAll) {
        if (withAll) {
            return [
                { id: -1, name: $.strings.all },
                { id: 0, name: $.strings.active },
                { id: 1, name: $.strings.inactive }
            ]
        }
        return [
            { id: 0, name: $.strings.active },
            { id: 1, name: $.strings.inactive }
        ]
    },
    hideBlankColumn(data, columnName) {
        let result = data.filter(e => e[columnName] && e[columnName].length > 0).length > 0
        return result;
    },
    getNewsTarget() {
        return [
            { id: -1, name: $.strings.all },
            { id: 0, name: $.strings.news.employee },
            { id: 1, name: $.strings.news.job },
            { id: 2, name: $.strings.news.sectionsManagers },
            { id: 3, name: $.strings.news.section }
        ]
    },
    getNewsStatus() {
        return [
            { id: 2, name: $.strings.all },
            { id: 0, name: $.strings.news.active },
            { id: 1, name: $.strings.news.inactive }
        ]
    },
    validateEmail(email) {
        if (email && email !== "null" && email.length > 0) {
            var re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test((email + ""));
        }
        return true
    },
    async GetSettingById(settingId) {
        let obj = {
            id: settingId
        }
        let res = await http.getJsonAxios(http.actions.Settings.urlGetSettingsById, obj, 0);
        if (res.status === 200) {
            return res.data;
        }
        return "";
    },
    async GetSettingByIdMailHandle(settingId) {
        let obj = {
            id: settingId
        }
        let res = await http.getJsonAxios(http.actions.Settings.urlGetSettingsByIdMailHandle, obj, 0);
        if (res.status === 200) {
            return res.data;
        }
        return "";
    },
    getCodeLen() {
        return localStorage.getItem("codeLen") ? parseInt(localStorage.getItem("codeLen") + "") : 5;
    },
    calculateDaysNo(fromDate, toDate) {
        let daysNo = 0;
        try {
            const _fromDate = Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0);
            const _toDate = Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 0, 0, 0);
            let temp = 1000 * 3600 * 24;
            daysNo = Math.floor((_toDate - _fromDate) / temp);
            if (daysNo < 0)
                daysNo = daysNo * -1;
            daysNo += 1;
        }
        catch (e) {
            alert(e)
        }
        return daysNo;
    },
    logOut() {
        localStorage.removeItem("useridHR");
        localStorage.removeItem("HRlang");
        localStorage.removeItem("isLoggedInHR")
        this.goTo("/", "");
    },
    hashState: (dataObject) => {
        let newHash = stringHash(JSON.stringify(dataObject));
        return newHash;
    },
    confirmDialog: (message, icon, onAccept, onReject, acceptClassName = 'p-button-success', rejectClassName = 'p-button-danger', footer = undefined) => {
        confirmDialog({
            message: message,
            header: $.strings.appName,
            icon: icon,
            acceptClassName: acceptClassName,
            rejectClassName: rejectClassName,
            acceptLabel: $.strings.yes,
            rejectLabel: $.strings.no,
            accept: () => onAccept(),
            reject: () => onReject(),
            footer: footer
        });
    },
    checkCanViewRequest(requestOwner) {
        let listOfEmployees = [];
        let dmEmployees = JSON.parse(localStorage.getItem("dmEmployees"));
        if (dmEmployees)
            listOfEmployees = dmEmployees;

        if (requestOwner !== localStorage.getItem("useridHR") + "" && !listOfEmployees.includes(requestOwner)) {
            return false
        }

        return true;
    },
    compareCode(fromCode, toCode) {
        if (fromCode && toCode) {
            if (fromCode > toCode) {
                return false;
            }
        }
        return true;
    },
    getGeneralShiftPos(shiftCode) {
        let shiftId = shiftCode.replace("XXX", "");
        return parseInt(shiftId);
    },
    showInfoToast(toastObj, summary, msgText) {
        try {
            if (!msgText)
                msgText = $.strings.operationDone
            toastObj.show({ severity: 'info', summary: summary, detail: msgText, life: 3000 });
        }
        catch (e) {
        }
    },
    showErrorToast(toastObj, msgText) {
        try {
            if (!msgText)
                msgText = $.strings.operationFailed
            toastObj.show({ severity: 'error', summary: $.strings.operationFailed, detail: msgText, life: 3000 });
        }
        catch (e) {
        }
    },
    showSuccessToast(toastObj, msgText) {
        try {
            if (!msgText)
                msgText = $.strings.operationDone
            toastObj.show({ severity: 'success', summary: $.strings.operationDone, detail: msgText, life: 3000 });
        }
        catch (e) {
        }
    },
    showWarningToast(toastObj, msgText) {
        try {
            if (!msgText)
                msgText = $.strings.operationDone
            toastObj.show({ severity: 'warning', summary: $.strings.operationDone, detail: msgText, life: 3000 });
        }
        catch (e) {
        }
    },
    fillAditionalsFilters(additionalFiltersObj, globalFilters) {
        if (additionalFiltersObj) {
            // Branches
            if (additionalFiltersObj.branch) {
                let BranchesList = [];
                for (let index = 0; index < additionalFiltersObj.branch.length; index++) {
                    BranchesList.push(additionalFiltersObj.branch[index].pos)
                }
                globalFilters.BranchesList = BranchesList;
            }
            // Departments
            if (additionalFiltersObj.department) {
                let DepartmentsList = [];
                for (let index = 0; index < additionalFiltersObj.department.length; index++) {
                    DepartmentsList.push(additionalFiltersObj.department[index].pos)
                }
                globalFilters.DepartmentsList = DepartmentsList;
            }
            // Classifications List
            if (additionalFiltersObj.classification) {
                let ClassificationsList = [];
                for (let index = 0; index < additionalFiltersObj.classification.length; index++) {
                    ClassificationsList.push(additionalFiltersObj.classification[index].pos)
                }
                globalFilters.ClassificationsList = ClassificationsList;
            }
            // Other Classifications List
            if (additionalFiltersObj.classification2) {
                let OtherClassificationsList = [];
                for (let index = 0; index < additionalFiltersObj.classification2.length; index++) {
                    OtherClassificationsList.push(additionalFiltersObj.classification2[index].pos)
                }
                globalFilters.OtherClassificationsList = OtherClassificationsList;
            }
            // Jobs List
            if (additionalFiltersObj.job) {
                let JobsList = [];
                for (let index = 0; index < additionalFiltersObj.job.length; index++) {
                    JobsList.push(additionalFiltersObj.job[index].pos)
                }
                globalFilters.JobsList = JobsList;
            }
            // Qualifications List
            if (additionalFiltersObj.qualification) {
                let QualificationsList = [];
                for (let index = 0; index < additionalFiltersObj.qualification.length; index++) {
                    QualificationsList.push(additionalFiltersObj.qualification[index].pos)
                }
                globalFilters.QualificationsList = QualificationsList;
            }
            // Shifts List
            if (additionalFiltersObj.shift) {
                let ShiftsList = [];
                for (let index = 0; index < additionalFiltersObj.shift.length; index++) {
                    ShiftsList.push(additionalFiltersObj.shift[index].pos)
                }
                globalFilters.ShiftsList = ShiftsList;
            }
            // DirectManger
            if (additionalFiltersObj.directManager) {
                globalFilters.DirectManger = additionalFiltersObj.directManager.code;
            }
            // Gender
            if (additionalFiltersObj.gender) {
                globalFilters.Sex = additionalFiltersObj.gender.id;
            }
            else {
                globalFilters.Sex = -1;
            }
        }
        else {
            globalFilters.Sex = -1;
        }
    },
    defaultButton: {
        Yes: 1,
        No: 2,
        Cancel: 3,
    },
    getDateFormat(date) {
        if (date instanceof Date)
            ;
        else {
            date = new Date(date)
        }
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let d = new Date(year, month - 1, day, 0, 0, 0, 0);
        return d.toLocaleDateString("en-Us");
    },
    getSettingById: (settingId) => {
        let settingsList = JSON.parse(localStorage.getItem("settingsListHR"));
        if (!settingsList)
            return "";
        let settingValue = settingsList.find(e => e.Pos + "" === settingId + "");
        if (settingValue)
            return settingValue.Name + "";

        return ""
    },
    getNameByUserLanguage(name, nameLang2) {
        if ($.strings.dir === "rtl") {
            if (name) return name;
            else return nameLang2;
        } else {
            if (nameLang2) return nameLang2;
            else return name;
        }
    },
    async GetSettingByIdImage() {
        let obj = {
            id: 0
        }
        let res = await http.getJsonAxios(http.actions.Settings.urlGetSettingsByIdImage, obj, 0);
        if (res.status === 200) {
            return res.data;
        }
        return "";
    },
    base64toBlob(base64Data, contentType) {
        contentType = contentType || "";
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    },
    getStatusId(statusName) {
        switch (statusName) {
            case $.strings.active: {
                return 0;
            }
            case $.strings.stopped: {
                return 1;
            }
            default: {
                return -1;
            }
        }
    },
    getUserTypesList() {
        return [
            { id: 0, name: $.strings.users.normalUser },
            // { id: 2, name: $.strings.users.superAdminUser },
            { id: 1, name: $.strings.users.adminUser },
        ];
    },
    checkIsSuperAdmin() {
        //return false;
        return true;
    },
    checkEmailFormat(email) {
        let expression =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let _regExp = new RegExp(expression);
        if (_regExp.test(email)) {
            return true;
        }
        return false;
    },
    gridColumnStartEditing(grid, rowIndex, colIndex, startEdit) {
        try {
            if (rowIndex === -1) rowIndex = 0;
            grid.focus();
            grid.select(rowIndex, colIndex);
            grid.scrollIntoView(rowIndex, colIndex);
            if (startEdit) {
                grid.startEditing(true, rowIndex, colIndex, true);
            }
        } catch (e) { }
    },
    keyboardKeys: {
        Enter: 13,
        Esc: 27,
        F3: 114,
        F5: 116,
        F6: 117,
        F10: 121,
        Equal: 187,
        Tab: 9,
        MinusRight: 109,
        MinusLeft: 189,
        deleteChar: 8,
        delete: 46,
    },
    getSystemOptions() {
        let values = [
            { id: -1, name: $.strings.all, name_lang2: $.strings.all },
            { id: 1, name: $.strings.PermissionsListGroups.systemOption1, name_lang2: $.strings.PermissionsListGroups.systemOption1 },
            { id: 2, name: $.strings.PermissionsListGroups.systemOption2, name_lang2: $.strings.PermissionsListGroups.systemOption2 },
            { id: 3, name: $.strings.PermissionsListGroups.systemOption3, name_lang2: $.strings.PermissionsListGroups.systemOption3 },
        ];
        return values;
    },
    getSystemOptionsWithOutAll() {
        let values = [
            // { id: -1, name: $.strings.all, name_lang2: $.strings.all },
            { id: 1, name: $.strings.PermissionsListGroups.systemOption1, name_lang2: $.strings.PermissionsListGroups.systemOption1 },
            { id: 2, name: $.strings.PermissionsListGroups.systemOption2, name_lang2: $.strings.PermissionsListGroups.systemOption2 },
            { id: 3, name: $.strings.PermissionsListGroups.systemOption3, name_lang2: $.strings.PermissionsListGroups.systemOption3 },
        ];
        return values;
    },
    checkISDelegated() {
        return localStorage.getItem("isDelegatedHR") && (localStorage.getItem("isDelegatedHR") + "" === "1" || localStorage.getItem("isDelegatedHR") + "" === "true") ? true : false
    },
    gridDateFormat() {
        return "dd/MM/yyyy";
    },
    getRequestAliasById(id) {
        try {
            let aliases = $.requestsAliases || {};
            if (aliases) {
                let obj = aliases.find(e => e.ID !== undefined && e.ID + "" === id + "");
                if (obj) {
                    let name = this.getNameByUserLanguage(obj.Name, obj.EName);
                    return name;
                }
            }
        }
        catch { }
        return undefined;
    },
    setCurrentRow(grid) {
        let index = 0;
        try {
            if (grid && grid.flex) {
                index = grid.flex.selection.row;
            }
            if (index < 0) {
                index = 0;
            }
        } catch (e) { }
        return index;
    },
    checkIsMainUser() {
        return localStorage.getItem("useridHR") + "" === "1" ? true : false;
    },
    handleGridColumns(grid, scheme) {
        let gridColumnList = [];
        if (grid) {
            gridColumnList = grid.flex.columns;
        }

        scheme.columns.forEach((element) => {
            let objColInGrid = gridColumnList.find((c) => c.name === element.name);
            if (objColInGrid) {
                element.visible = objColInGrid.visible || (grid.state.narrow && element.visible);
            }
        });

        return scheme;
    },
}
export default exportDefault;