import React from "react";
import styles from "./MultiSelect.module.scss";
import { MultiSelect as PrimeMultiSelect } from 'primereact/multiselect';
import Label from "../Label";

const $ = window.$;
export default class MultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            floatLabel: false
        };
    };
    render() {

        const panelClassName = $.strings.dir === "rtl" ? styles.panelClassRight : styles.panelClassLeft;

        return (
            <div>
                {this.props.caption && <Label htmlFor={this.props.htmlFor} isRequired={this.props.isrequired}>
                    {this.props.caption}

                </Label>
                }
                <PrimeMultiSelect id="float-input" ref={this.props.innerRef} title={this.props.tooltip} className={`${styles.multiselect} ${this.props.innerClass || ''}`}
                    {...this.props} tooltipOptions={{ position: 'bottom' }} panelClassName={panelClassName}>
                    {this.props.children}
                </PrimeMultiSelect>
                {
                    this.props.formErrors && this.props.formErrors[this.props.id] &&
                    <div className={styles.errorField} >{this.props.formErrors[this.props.id]}</div>
                }
            </div>
        );
    }

    onFocus = () => {
        this.setState({ floatLabel: true });
    }

    onBlur = () => {
        this.setState({ floatLabel: false });
        //this.props.isRequired && !this.props.value ? this.setState({ showValidationField: true }) : this.setState({ showValidationField: false });
    }
}