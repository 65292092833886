import React from 'react'
import { Column } from 'primereact/column'
import styles from './DataTable.module.scss'

const $ = window.$

export function getMultiSort(scheme, props) {
    let sortMode = scheme.groupField ? "multiple" : "single";
    let sortField = props.sortField || scheme.sortField;
    let sortOrder = props.sortOrder || scheme.sortOrder;
    let multiSortMeta = []
    if (scheme.groupField)
        multiSortMeta.push({ field: scheme.groupField, order: scheme.groupSortOrder || defaultValues.sortOrder })
    if (sortField) {
        multiSortMeta.push({ field: sortField, order: sortOrder })
    }
    return {
        multiSortMeta: scheme.groupField ? multiSortMeta : undefined,
        sortMode: sortMode,
    }
}

export function getFilters(scheme) {
    return scheme.columns.reduce((a, b) => {
        if (b.filter)
            a[b.field] = { value: b.filter, matchMode: b.filterMatchMode }
        return a;
    }, {})
}

export function getColumns(scheme, data, groupField, onSort, onSigma, isReport, isResponsive) {
    let columns = scheme.columns.sort((a, b) => a.position - b.position)
    let result = []
    columns.forEach((column, index) => {
        //dont add groupBy column
        if (scheme.groupField && column.field === scheme.groupField)
            return;
        if (column.hidden)
            return;

        let c = <Column
            key={column.key || index}
            columnKey={column.columnKey}
            field={column.field}
            sortField={column.sortField}
            header={getHeader(column, scheme.sigma, onSort, onSigma)}
            csvHeader={column.header}
            body={column.body}
            loadingBody={loadingText}
            footer={getFooter(data, column, groupField)}
            sortable={isReport ? (column.sortable === false ? false : true) : false}
            sortFunction={column.sortFunction}
            filter={scheme.filter && (column.filterable === false ? false : true)}
            filterMatchMode={column.filterMatchMode || defaultValues.filterMatchMode}
            filterType={column.filterType}
            filterPlaceholder={column.filterPlaceholder}
            filterMaxLength={column.filterMaxLength}
            filterElement={column.filterElement}
            filterFunction={column.filterFunction}
            style={{ ...column.style, width: column.width }}
            className={column.className}
            headerStyle={column.headerStyle}
            headerClassName={column.headerClassName}
            bodyStyle={column.bodyStyle}
            bodyClassName={column.bodyClassName || styles.cell}
            footerStyle={column.footerStyle}
            footerClassName={column.footerClassName || styles.footer}
            expander={column.expander}
            frozen={column.frozen}
            selectionMode={column.selectionMode}
            colSpan={column.colSpan}
            rowSpan={column.rowSpan}
            editor={column.editor}
            editorValidator={column.editorValidator}
            rowReorder={column.rowReorder}
            rowReorderIcon={column.rowReorderIcon}
        />
        result.push(c);
    })

    return result;
}


function loadingText() {
    return <span className="loading-text"></span>;
}

function getHeader(column, sigma, onSortClick, onSigmaClick) {
    return (

        <div style={{ display: 'inline' }}>
            <span className={styles.header}
                onClick={e => onSortClick(e, column)}
            >
                {column.header}
            </span>
            {
                sigma
                    ?
                    <span className={styles.sigmaIcon} onClick={e => onSigmaClick(e, column)}>Σ</span>
                    :
                    null
            }
        </div>
    )
}

function getFooter(data, column, groupField) {
    if (groupField || !column.sigma)
        return null;
    let sigma = column.sigma;

    return (
        <div className={styles.footer} style={{ display: "flex", flexDirection: "column" }}>
            {sigma.avg && <span>{$.strings.sigmaOptions.average}: {getAvg(column, data)}</span>}
            {sigma.count && <span>{$.strings.sigmaOptions.count}: {getCount(column, data)}</span>}
            {sigma.max && <span>{$.strings.sigmaOptions.maximum}: {getMax(column, data)}</span>}
            {sigma.min && <span>{$.strings.sigmaOptions.minimum}: {getMin(column, data)}</span>}
            {sigma.sum && <span>{$.strings.sigmaOptions.sum}: {getSum(column, data)}</span>}
        </div>
    )
}

export function getAvg(column, data) { return getSum(column, data) / getCount(column, data) }
export function getMax(column, data) { return data.reduce((a, b) => a[column.field] > b[column.field] ? a : b)[column.field] }
export function getMin(column, data) { return data.reduce((a, b) => a[column.field] < b[column.field] ? a : b)[column.field] }
export function getSum(column, data) {
    if (column.dataType && column.dataType === "Time") {
        let total = { hours: 0, minutes: 0 };
        total = { hours: 0, minutes: 0 };
        data.forEach(function (item) {
            let value = item[column.field] || '0:0'
            var parts = value.split(":");
            var temp;
            temp = (+parts[1] + total.minutes);
            total.minutes = temp % 60;
            total.hours = (+parts[0] + total.hours) + (temp - total.minutes) / 60;
        });
        let transTime = zeroPad(total.hours) + ":" + zeroPad(total.minutes);

        return transTime;
    }
    else {
        return data.reduce((a, b) => +a + +b[column.field], 0)
    }
}
export function getCount(column, data) { return data.length }
export function zeroPad(num) {
    var str = num.toString();
    if (str.length < 2) {
        str = "0" + str;
    }
    return str;
}
export const defaultValues = {
    filterMatchMode: "contains",
    selectionMode: "single",
    compareSelectionBy: "equals",
    columnResizeMode: "fit",
    rowGroupMode: "subheader",
    sortOrder: 1,
}