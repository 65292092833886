import React from "react"
//import styles from "./DeleteNoteDialog.module.scss"
import InputTextArea from "../InputTextArea"
import Input from "../Input"
import Dialog from '../Dialog'
import Button from "../Button"
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa'
import Checkbox from "../Checkbox"

const $ = window.$;

export default class DeleteNoteDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteNote: ''
        }
    }

    componentDidMount() {

    }

    render() {
        let deleteNoteFooter = (
            <div className="p-grid" style={{ textAlign: 'center' }}>
                <div className="p-lg-2 p-md-2">
                </div>
                <div className="p-lg-8 p-md-8">
                    <Button innerRef={ref => this.btnYes = ref} icon="pi pi-check-circle" onClick={this.onYes} tooltip={$.strings.yes}>
                    </Button>
                    <Button innerRef={ref => this.btnNo = ref} icon="pi pi-times" className="p-button-secondary" onClick={this.props.onNo} tooltip={$.strings.no}>
                    </Button>
                </div>
                <div className="p-lg-2 p-md-2">
                </div>
            </div>
        )

        let deleteNoteHeader = (
            <div>
                {$.strings.appName}
            </div>
        )

        return (
            <Dialog
                header={deleteNoteHeader}
                footer={deleteNoteFooter}
                visible={this.props.visible}

                modal={true}
                draggable={true}
                closeOnEscape={false}
                style={{ maxWidth: "25%" }}
                blockScroll={true}
                onHide={this.props.onNo}
                autoFocus={true}
                closable={false}
                {...this.props}
                focusOnShow={false}
            >
                <div className="p-grid">
                    <div className="p-col-12 p-md-12">
                        {this.props.deleteNote && this.props.deleteNote.length > 0 ? this.props.deleteNote : $.strings.confirmDelete}
                    </div>
                </div>
                {
                    this.props.hideNotes ? null :
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12">
                                <InputTextArea
                                    innerRef={ref => this.txtDeleteNote = ref}
                                    type="text"
                                    id="deleteNote"
                                    autoFocus={true}
                                    maxLength="100"
                                    value={this.state.deleteNote}
                                    caption={$.strings.note}
                                    onChange={this.setDeleteNote}
                                    isRequired={true}
                                    formErrors={this.props.deleteErrors}
                                />
                            </div>
                        </div>
                }
                {
                    this.props.checkBoxCaption &&
                    (
                        <Checkbox
                            checked={this.state.checked}
                            caption={this.props.checkBoxCaption}
                            onChange={e => this.setState({ checked: e.checked })}
                        ></Checkbox>
                    )
                }
            </Dialog>

        )
    }

    setFocus = () => {
        setTimeout(() => { this.txtDeleteNote.focus() }, 10);
    }

    setNoBottomFocus = () => {
        this.btnNo.focus()
    }

    setDeleteNote = (e) => {
        this.setState({ deleteNote: e.target.value })
    }

    onYes = () => {
        this.props.onYes(this.state.deleteNote, this.state.checked)
    }
}