import React from "react";
import Util from '../../Util';
import http from '../../Api/http';
import Card from '../../Components/Card';

const $ = window.$

export default class WorkTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            fromTime: '',
            toTime: '',
            empAttend: false,
            empLeaveType: '',
            actualIN: '',
            actualOUT: '',
            isDesktop: true
        };
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        let shiftPrmoise = this.getTodayShift()
        let shift = await shiftPrmoise

        let _fromTime = ''
        let _toTime = ''
        let _empAttend = false
        let _empLeaveType = ''
        let _actualIN = ''
        let _actualOUT = ''
        if (shift && shift.length > 0) {
            _fromTime = $.strings.home.fromHour + ' ' + shift[0].workFrom;
            _toTime = $.strings.home.toHour + ' ' + shift[0].workTo;
            _empAttend = shift[0].empStatus
            _empLeaveType = shift[0].empLeaveType
            _actualIN = $.strings.home.checkIN + ' ' + (shift[0].actualIN ? shift[0].actualIN : '')
            _actualOUT = $.strings.home.checkOUT + ' ' + (shift[0].actualOUT ? shift[0].actualOUT : '')
        }
        else {
            _empLeaveType = $.strings.home.noShift;
        }

        this.setState({
            fromTime: _fromTime, toTime: _toTime, empAttend: _empAttend, empLeaveType: _empLeaveType,
            actualIN: _actualIN, actualOUT: _actualOUT, isDesktop: _isDesktop, loading: false
        })
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    render() {

        let cardFooter = <span style={{ color: 'orange', cursor: 'pointer' }} onClick={this.showWorkTimeDetails}>
            {$.strings.home.workDetails}
        </span>;

        return (
            <div ref="divMain" >
                {
                    this.state.loading
                        ?
                        <i className="pi pi-spin pi-spinner" style={{ textAlign: 'center', 'fontSize': '2em' }} />
                        :
                        <Card title="" showFooter={false} style={{ fontWeight: 'bold', height: '80px' }}>
                            <div className="p-grid">
                                <div className="p-col-10 p-xl-10 p-lg-10 p-md-10 p-sm-10">
                                    {
                                        this.state.empAttend &&
                                        <>
                                            <div className="p-grid">
                                                <div className="p-col-6 p-xp-6 p-lg-6 p-md-6 p-sm-6">
                                                    {this.state.fromTime}
                                                </div>
                                                <div className="p-col-6 p-xp-6 p-lg-6 p-md-6 p-sm-6">
                                                    {this.state.toTime}
                                                </div>
                                            </div>
                                            <div className="p-grid">
                                                <div className="p-col-6 p-xp-6 p-lg-6 p-md-6 p-sm-6" style={{ color: 'green' }}>
                                                    {this.state.actualIN}
                                                </div>
                                                <div className="p-col-6 p-xp-6 p-lg-6 p-md-6 p-sm-6" style={{ color: 'red' }}>
                                                    {this.state.actualOUT}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        !this.state.empAttend && this.state.empLeaveType && this.state.empLeaveType.length > 0 &&
                                        < div className="p-grid">
                                            <div className="p-col-12 p-lg-12 p-sm-12" style={{ color: 'blue' }}>
                                                {this.state.empLeaveType}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <i className="pi pi-book" title={$.strings.home.workDetails} onClick={this.showWorkTimeDetails} style={{ textAlign: 'center', 'fontSize': '2em', color: 'Orange', cursor: 'pointer' }} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Card>
                }
            </div>
        )
    }

    getTodayShift = async () => {
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR"),
            fromDate: new Date(),
            toDate: new Date()
        }
        let res = await http.getJsonAxios(http.actions.Shifts.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }

    showWorkTimeDetails = () => {
        Util.goTo("../Reports/WorkDetails")
    }
}