import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import ScrollTop from '../ScrollButton';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import WFTrans from '../WFTrans';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Messages from "../../Components/Messages";
import Print from "../../Print";

const $ = window.$;

export default class MemosReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDesktop: window.innerWidth > 1250,

            fromCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            toCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',

            fromDate: new Date(),
            toDate: new Date(),
            time: 32000,

            shift: undefined,
            section: undefined,
            branch: undefined,
            department: undefined,
            filterShown: true,
            data: []

        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(962)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(963)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();

        let transTypeListPromise = this.getTransTypes();
        let statusListPromise = this.getStatus();
        let shiftsPromise = this.getShifts();
        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();

        let transTypesList = await transTypeListPromise;
        let statusList = await statusListPromise;
        let shiftsList = await shiftsPromise;
        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        this.setState({
            transTypesList: transTypesList, transType: transTypesList && transTypesList.length > 0 ? transTypesList[0] : undefined,
            statusList: statusList, status: statusList && statusList.length > 0 ? statusList[0] : undefined,
            shiftsList: shiftsList, branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            shift: shiftsList && shiftsList.length > 0 ? shiftsList[0] : undefined,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.memosRpt.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.memosRpt.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.memosRpt.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.memosRpt.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.memosRpt.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-6">
                            <Dropdown
                                id="transType"
                                innerRef={e => this.drpTransType = e}
                                value={this.state.transType}
                                options={this.state.transTypesList}
                                caption={$.strings.memosRpt.transType}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-6">
                            <Dropdown
                                id="status"
                                value={this.state.status}
                                options={this.state.statusList}
                                caption={$.strings.memosRpt.status}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                            />
                        </div>
                    </div>
                    {
                        Util.checkISHR() &&
                        <div className="p-grid">
                            <div className="p-col-6 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="shift"
                                    innerRef={e => this.drpShifts = e}
                                    value={this.state.shift}
                                    options={this.state.shiftsList}
                                    caption={$.strings.memosRpt.shift}
                                    onChange={this.handleUserInput}
                                    optionLabel="ShiftName"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="section"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.memosRpt.section}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="department"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.department}
                                    options={this.state.departmentsList}
                                    caption={$.strings.memosRpt.department}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="branch"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.branch}
                                    options={this.state.branchesList}
                                    caption={$.strings.memosRpt.branch}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                        </div>
                    }
                </AccordionTab>
            </Accordion>
            <Card>
                <DataGrid
                    ref={e => this.gridData = e}
                    isReport={true}
                    theme="default-light"
                    idProperty="serial"
                    style={gridStyle}
                    scheme={this.getScheme()}
                    exportFileName={$.strings.memosRpt.title}
                    dataSource={this.state.data}
                />
            </Card>

            <ScrollTop />
        </div>
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }

    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }
    getTransTypes = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 26
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp)
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
        }
        return dataTemp;
    }
    getStatus = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            withAll: true
        }
        let res = await http.getJsonAxios(http.actions.RequestsStatus.urlGetRequestsStatus, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            isHr: Util.checkISHR(),
            fromNo: this.state.fromCode && this.state.fromCode.length > 0 ? this.state.fromCode : '00000',
            toNo: this.state.toCode && this.state.toCode.length > 0 ? this.state.toCode : 'ZZZZZ',
            empCode: localStorage.getItem("useridHR") + "",
            userId: localStorage.getItem("useridHR") + "",
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            selectedDepartment: this.state.department && this.state.department.pos >= 0 ? this.state.department.pos : "-1",
            selectedBranchValue: this.state.branch && this.state.branch.Pos >= 0 ? this.state.branch.Pos : "-1",
            selectedStatus: this.state.status && this.state.status.ID >= 0 ? this.state.status.ID : "-1",
            transType: this.state.transType.pos
        }
        let res = await http.getJsonAxios(http.actions.Memos.urlGetFillEmployeeMemos, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }

        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.memosRpt.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";

            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.memosRpt.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.memosRpt.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.memosRpt.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.memosRpt.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter);

            if (this.printFilters.transType) {
                let name = this.printFilters.transType.name
                listOfFilters.push($.strings.memosRpt.transType + ": " + name);
            }

            if (this.printFilters.status) {
                let name = this.printFilters.status.Name
                listOfFilters.push($.strings.memosRpt.status + ": " + name);
            }

            if (this.printFilters.section) {
                let name = this.printFilters.shift.ShiftName
                listOfFilters.push($.strings.memosRpt.shift + ": " + name);
            }

            if (this.printFilters.section) {
                let name = this.printFilters.section.Name
                listOfFilters.push($.strings.memosRpt.section + ": " + name);
            }

            if (this.printFilters.department) {
                let name = this.printFilters.department.name
                listOfFilters.push($.strings.memosRpt.department + ": " + name);
            }

            if (this.printFilters.branch) {
                let name = this.printFilters.branch.Name
                listOfFilters.push($.strings.memosRpt.branch + ": " + name);
            }
        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    btnWFTransDetails = async (e, ctx) => {
        if (!ctx.item.MemoNo || ctx.item.MemoNo.length <= 0)
            return;
        let id = Util.encryptStringToHexa(ctx.item.MemoNo)
        await this.wfTransDlg.show('7', id)
    }
    btnViewRequest = (e, ctx) => {
        if (!ctx.item.MemoNo || ctx.item.MemoNo.length <= 0)
            return;
        let id = Util.encryptStringToHexa(ctx.item.MemoNo)
        Util.goTo("../Requests/Memos/" + id)
    }
    getScheme = () => {
        return {
            name: "memosReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 5,
            columns: [
                {
                    name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1'
                },
                {
                    name: 'btnWFTransDetails', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.transDetails,
                    iconType: 'card', className: 'warning', onClick: (e, ctx) => this.btnWFTransDetails(e, ctx)
                },
                {
                    name: 'btnViewRequest', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.view,
                    iconType: 'view', className: 'info', onClick: (e, ctx) => this.btnViewRequest(e, ctx)
                },
                {
                    name: 'MemoNo', visible: false, visibleInColumnChooser: false, columnWidth: '2'
                },
                {
                    name: 'EmpCode', header: $.strings.memosRpt.empCode, width: 120, columnWidth: '2'
                },
                {
                    name: 'EmpName', header: $.strings.memosRpt.empName, width: "*", columnWidth: '*'
                },
                {
                    name: 'MemoDate', header: $.strings.memosRpt.memoDate, width: 120, columnWidth: '3'
                },
                {
                    name: 'MemoType', header: $.strings.memosRpt.memoType, width: "*", columnWidth: '3'
                },
                {
                    name: 'MemoStatus', header: $.strings.memosRpt.memoStatus, width: 120, columnWidth: '2'
                },
                {
                    name: 'Note', header: $.strings.memosRpt.memoNote, width: "*", columnWidth: '*'
                },
                {
                    name: 'AdminNote', header: $.strings.memosRpt.managerNote, width: "*", columnWidth: '*'
                },
                {
                    name: 'HrNote', header: $.strings.memosRpt.hrNote, width: "*", columnWidth: '*'
                }
            ]
        }
    }
}