import React from "react";
import styles from "./Spinner.module.scss";
import { InputNumber  as PrimeSpinner } from 'primereact/inputnumber';
import Label from '../../Components/Label'
export default class Spinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            floatLabel: false
        };
    };
    render() {

        return (
            <div>
                {
                    this.props.caption && <Label htmlFor={this.props.htmlFor} isRequired={this.props.isRequired}>
                        {this.props.caption}

                    </Label>
                }
                <PrimeSpinner ref={this.props.innerRef} className={styles.spinner} {...this.props} >
                    {this.props.children}
                </PrimeSpinner>
                {
                    this.props.formErrors && this.props.formErrors[this.props.id] &&
                    <div className={styles.errorField} >{this.props.formErrors[this.props.id]}</div>
                    // <Message severity="error" text={this.props.formErrors[this.props.id]} style={{ width: '100vw' }} />
                }
            </div>
        );
    }

    onFocus = () => {
        this.setState({ floatLabel: true });
    }

    onBlur = () => {
        this.setState({ floatLabel: false });
    }

    setFloatLabelStyle = () => {
        if (this.props.value && this.state.floatLabel)
            return styles.floatLabelWithColorStyle;
        else if (this.props.value)
            return styles.floatLabelStyle;
        else if (this.state.floatLabel)
            return styles.floatLabelWithColorStyle;
        else return styles.label;
    }

}