import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import mainTemplate from '../../Components/SpecialComponents/EditorsAndTemplate/MainTemplate/EditorFunctions';
import DataTable from "../../Components/Datatable";
import ProgressSpinner from "../../Components/ProgressSpinner";

const $ = window.$;

export default class SearchEmployees extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            filterText: '',
            data: []
        }
        this.scheme = this.getScheme()
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ data: [], isDesktop: _isDesktop, loading: false })
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }
    render() {
        const footer = (
            <div className="p-grid" style={{ textAlign: 'center' }}>
                <div className="p-col-6 p-lg-6 p-sm-6" style={{ textAlign: 'center' }}>
                    <Button label={$.strings.searchEmployee.ok} icon="" onClick={this.onRowDoubleClick}>
                        &nbsp;&nbsp;
                        <i className="pi pi-check" />
                    </Button>
                </div>
                <div className="p-col-6 p-lg-6 p-sm-6" style={{ textAlign: 'center' }}>
                    <Button label={$.strings.searchEmployee.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                        &nbsp;&nbsp;
                        <i className="pi pi-times" />
                    </Button>
                </div>
            </div >
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <Dialog
                    header={$.strings.searchEmployee.title}
                    footer={footer} icons={myIcon}
                    visible={this.state.visibleDialog}
                    contentStyle={{ minHeight: '600px' }}
                    style={{ width: this.state.isDesktop ? '30vw' : '70vw', direction: $.strings.dir }}
                    modal
                    onHide={this.onHide}
                >
                    <Messages innerRef={(el) => this.messages = el} />
                    <ProgressSpinner loading={this.state.loading} />
                    <div className="p-grid">
                        <div className="p-col-11 p-lg-8 p-sm-10">
                            <Input
                                innerRef={e => this.txtName = e}
                                autoFocus
                                id="filterText"
                                onChange={this.handleUserInput}
                                value={this.state.filterText}
                                autocomplete="off"
                                caption={$.strings.searchEmployee.empCodeOrName}
                                onKeyDown={this.onKeyDown}
                                withgroup={true}
                                btn1event={e => this.onSearch(false)}
                                btn1icon="search"
                            />
                        </div>

                    </div>
                    <DataTable
                        isReport={false}
                        ref={ref => (this.tblData = ref)}
                        scrollHeight={this.state.isDesktop ? "38vh" : ''}
                        scheme={this.scheme}
                        value={this.state.data}
                        // loading={this.state.loading}
                        showContextMenu={false}
                        responsive={true}
                        onRowDoubleClick={this.onRowDoubleClick}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                    />
                </Dialog >
            </div >
        );
    }
    onRowDoubleClick = () => {
        if (!this.tblData.state.selection) {
            Util.showErrorMsg(this.messages, $.strings.searchEmployee.selectAnEmployee);
            return;
        }
        this.props.onSuccess(this.tblData.state.selection);
        this.setState({ visibleDialog: false, data: [], filterText: '' })
    }

    show = async () => {
        this.setState({ filterText: '', data: [], visibleDialog: true }, async () => { await this.onSearch(false) });
    }

    onHide = () => {
        this.setState({ visibleDialog: false, filterText: '' })
    }

    onKeyDown = async (e) => {
        if (e.keyCode === 13)
            await this.onSearch(false)
    }

    onSearch = async (fromLoad) => {
        if (!fromLoad) {
            this.setState({ loading: true });
        }
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: this.state.filterText || '',
            isHr: Util.checkISHR(),
            getEmpStatus: false,
            section: -1,
            empStatus: -1
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;
        }
        if (!fromLoad) {
            this.setState({ data: dataTemp, loading: false })
        }
        else {
            return dataTemp;
        }
    }

    getScheme = () => {
        return {
            name: "searchEmployeesScheme_Table",
            filter: false,
            sigma: false,
            autoLayout: true,
            columns: [
                {
                    position: 0,
                    field: "rowNumber",
                    header: "##",
                    filterable: false,
                    width: "70px",
                    sortable: false,
                    body: e => mainTemplate(e, "##", "rowNumber")
                },
                {
                    position: 0,
                    field: "code",
                    header: $.strings.searchEmployee.empCode,
                    width: 100,
                    filterable: false,
                    sortable: false,
                    body: e => mainTemplate(e, $.strings.searchEmployee.empCode, "code")
                },
                {
                    position: 0,
                    field: "name",
                    header: $.strings.searchEmployee.empName,
                    filterable: false,
                    sortable: false,
                    body: e => mainTemplate(e, $.strings.searchEmployee.empName, "name")
                }
            ]
        };
    };
}