import React from 'react';
import styles from './Roles.module.scss';
import Input from '../../../Components/Input';
import ButtonsBarLookup from '../../../Components/ButtonsBarLookup';
import Messages from '../../../Components/Messages';
import Http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import DeleteNoteDialog from '../../../Components/DeleteNoteDialog';
import Util from '../../../Util';
import Card from '../../../Components/Card';
import AlertBox from '../../../Components/AlertBox';
import { Toast } from 'primereact/toast';

const $ = window.$;

export default class RolesAdd extends React.Component {
  constructor(props) {
    super(props);
    this.hash = null;
    this.doHotKeys = true;
    this.dlgOnNew = false;
    this.refreshQueryRequired = false;
    this.state = {
      loading: false,
      mode: this.props.editModeId && this.props.editModeId > 0 ? 'edit' : 'new',
      pageInfo: this.setPageInfo(),
      formErrors: { name: '', otherName: '' },
      deleteErrors: { deleteNote: '' },
      activeIndex: 0,
      fromArchive: false,
      dataObject: {
        id: -1,
        name: '',
        otherName: '',
        notes: '',
        dataUsers: [],
        status: 1,
      },
      isDesktop: window.innerWidth > 800,
    };
  }
  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 800 });
  };
  componentDidMount = async() => {
    if (!Util.checkISHR()) {
      Util.goTo('/Access');
      return;
    }
    if (!Util.checkIsMainUser()) {
      Util.goTo('/Access');
      return;
    }
    document.addEventListener('keydown', this.onPageKeyDown);
    document.addEventListener('resize', this.updatePredicate);
    if(this.props.editModeId !== undefined && this.props.editModeId >-1)
    {
      await this.getData(this.props.editModeId);
    }
    else this.onNew(null,false);
  };
  getData = async (id) => {
    const params = {
      id:id,
    };

    let res = await Http.getJsonAxios(Http.actions.Roles.urlGetRoles, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
     let dataTemp = res.data.list;
      await this.loadData(dataTemp);
    }
  };
  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
    document.removeEventListener('resize', this.updatePredicate);
  };

  onPageKeyDown = async (e) => {
    if (!this.doHotKeys) return;
    this.doHotKeys = false;
    switch (e.keyCode) {
      case Util.keyboardKeys.F3: {
        e.preventDefault();
        if (!this.state.fromArchive) {
          await this.onSaveWithoutClose(e, true);
        }
        break;
      }
      case Util.keyboardKeys.F6: {
        e.preventDefault();
        this.onNew(e, true);
        break;
      }
      case Util.keyboardKeys.Esc: {
        e.preventDefault();
        this.btnClose(e, true);
        break;
      }
      default: {
      }
    }
    this.doHotKeys = true;
  };

  handleUserInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
  };

  onNameBlur = (e) => {
    if (!this.state.dataObject.otherName) this.setState({ dataObject: { ...this.state.dataObject, otherName: e.target.value } }, e.target.selectAll);
  };

  validateField() {
    let fieldValidationErrors = this.state.formErrors;

    let _name = this.state.dataObject.name;
    fieldValidationErrors.name = _name ? '' : $.strings.requiredFiled;

    let _otherName = this.state.dataObject.otherName;
    fieldValidationErrors.otherName = _otherName ? '' : $.strings.requiredFiled;

    this.setState(
      {
        formErrors: fieldValidationErrors,
        nameValid: _name,
        otherNameValid: _otherName,
      },
      this.validateForm
    );
    return _name && _otherName;
  }
  validateForm() {
    let isFormValid = this.state.codeValid && this.state.nameValid && this.state.otherNameValid;
    this.setState({ formValid: isFormValid });
    return isFormValid;
  }

  render() {

    let screenHeader = (
      <div className={`${'p-grid'} ${styles.titleBar}`}>
        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ verticalAlign: 'middle' }}>
          <h2 className={styles.addRoleTitle}>
            {this.state.pageInfo.title + ' '}{' '}
            {this.state.mode === 'new' ? <span style={{ color: 'red' }}> {' (' + $.strings.newMode + ')'} </span> : ''}
          </h2>
        </div>
      </div>
    );

    return (
      <div ref="divMain" className={styles.RolesAdd}>
        {screenHeader}
        <div className="p-grid">
          <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
            <ButtonsBarLookup
              onNew={(e) => this.onNew(e, true)}
              onDelete={(e) => this.onDelete(e, true)}
              onClose={(e) => this.btnClose(e, true)}
              onSave={this.onSave}
              onPrevious={(e) => this.onPrevious(e, true)}
              onNext={(e) => this.onNext(e, true)}
              onFirst={(e) => this.onFirst(e, true)}
              onLast={(e) => this.onLast(e, true)}
              onClone={(e) => this.onClone(e, true)}
              onSaveWithoutClose={(e) => this.onSaveWithoutClose(e, true)}
              onDeactivate={(e) => this.onDeactivate(e, true, true)}
              onActivate={this.onActivate}
              onLogFile={(e) => this.onLogFile(e, true)}
              recordId={this.state.dataObject.id}
              fromArchive={this.state.fromArchive}
              pageId={this.state.pageInfo.pageId}
              parent={this}
            />
            <Messages innerRef={(el) => (this.messages = el)} />
            <Toast
              ref={(el) => (this.toast = el)}
              position={$.strings.dir === 'rtl' ? 'top-left' : 'top-right'}
              style={{ top: 100, whiteSpace: 'pre-line' }}
            />
          </div>
        </div>
        <ProgressSpinner loading={this.state.loading} />
        {this.state.confirmBoxVisible && (
          <AlertBox ref={(e) => (this.confirmBox = e)} header={$.strings.appName} defaultButton={Util.defaultButton.No} />
        )}

        {this.state.dlgDeleteNoteVisible && (
          <DeleteNoteDialog
            ref={(ref) => (this.deleteNoteDlg = ref)}
            visible={this.state.dlgDeleteNoteVisible}
            deleteErrors={this.state.deleteErrors}
            appendTo={this.props.parent}
            onYes={this.btnDeleteYes}
            onNo={this.DeleteCancel}
            deleteNote=""
          />
        )}
        <Card>
          <div className="p-grid">
            <div className="p-col-11 p-xl-11 p-lg-11 p-md-11 p-sm-11">
              <Input
                innerRef={(el) => (this.txtName = el)}
                type="text"
                id="name"
                maxLength="70"
                value={this.state.dataObject.name || ''}
                //autoFocus={true}
                caption={$.strings.roles.name }
                onChange={this.handleUserInput}
                onBlur={this.onNameBlur}
                isRequired={true}
                formErrors={this.state.formErrors}
                tooltip={$.strings.roles.name_tool_tip}
              />
            </div>
            </div>
        </Card>
      </div>
    );
  }

  popupHasCalled = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
  };
  popupHasClosed = () => {
    document.addEventListener('keydown', this.onPageKeyDown);
  };
  onCancel = () => {
    this.dlgOnNew = false;
    this.setState({});
  };

  onSave = async () => {
    this.setState({ loading: true });
    if (!this.validateField()) {
      this.setState({ loading: false });
      return;
    }
    let result = await this.saveData();
    if (result.status === 200) {
      this.refreshQueryRequired = true;
      this.setState({ loading: false });
      this.props.onAddSuccess(true);
    } else if (result.status === 400) {
      Util.showErrorMsg(this.messages, result.message.message);
    } else {
      Util.showErrorMsg(this.messages);
    }
    this.setState({ loading: false });
  };

  onSaveWithoutClose = async (e, fromSave) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    if (!this.validateField()) {
      this.setState({ loading: false });
      return false;
    }

    let saveDone = false;
    let result = await this.saveData();
    if (result.status === 200) {
      Util.showSuccessMsg(this.messages);
      if (fromSave) this.resetFields();
      this.setState({ loading: false });
      this.refreshQueryRequired = true;
      saveDone = true;
    } else if (result.status === 400) {
      Util.showErrorMsg(this.messages, result.message.message);
      saveDone = false;
    } else {
      Util.showErrorMsg(this.messages);
      this.setState({ loading: false });
      saveDone = false;
    }
    this.setState({ loading: false });
    return saveDone;
  };
  onDelete = async (e, checkChanging) => {
    this.btnDeleteYes();
  };

  DeleteCancel = () => {
    this.setState({
      dlgDeleteNoteVisible: false,
      dlgDeactivateVisible: false,
      loading: false,
      deleteErrors: { deleteNote: '' },
    });
  };

  btnDeleteYes = async (deleteNote) => {
    this.setState({ loading: true });
    let obj = {
      id: this.state.dataObject.id,
    };
    let result = 500;
    console.log(obj)
    let res = await Http.getJsonAxios(Http.actions.Roles.urlDeleteRole, obj, this.state.pageInfo.pageId);
    result = res.status;
    if (result === 200) {
      if (res.data && res.data.success) {
        Util.showSuccessMsg(this.messages);
        this.resetFields();
        this.refreshQueryRequired = true;
        this.setState(
          {
            loading: false,
            dlgDeleteNoteVisible: false,
          },
        );
        return true;
      } else {
        let msg = $.strings.operationFailed;
        if (res.data && res.data.message) msg = res.data.message;
        Util.showErrorMsg(this.messages, msg);
        this.setState({ loading: false, dlgDeleteNoteVisible: false });
        return false;
      }
    } else {
      let msg = $.strings.operationFailed;
      if (res.data && res.data.message) msg = res.data.message;
      Util.showErrorMsg(this.messages, msg);
      this.setState({ loading: false, dlgDeleteNoteVisible: false });
      return false;
    }
  };

  btnDeleteNo = () => {
    this.setState({
      dlgDeleteNoteVisible: false,
      deleteErrors: { deleteNote: '' },
    });
  };

  onPrevious = async (e, checkChanging) => {
    if (this.state.dataObject.id === 0) {
      Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
      return;
    }
    this.setState({ loading: true });
    let id = -1;
    id = this.state.dataObject.id;
    let param = {
      id: id,
      navigation_enum_value:4
    };
    let result = false;
    let obj = [];

    let res = await Http.getJsonAxios(Http.actions.Roles.urlGetRoleNavigation, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
      obj = res.data.list;
      if (obj.id === id) Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
    } else {
      let msg = $.strings.operationFailed;
      if (res.message && res.message.length > 0) {
        msg = res.message;
      }
      Util.showErrorMsg(this.messages, msg);
    }
    if (result) this.loadData(obj);
    else this.setState({ loading: false });
  };

  onNext = async (e, checkChanging) => {
    if (this.state.dataObject.id === -1) {
      Util.showInfoMsg(this.messages, $.strings.recordsEnds);
      return;
    }
    this.setState({ loading: true });
    let id = -1;
    id = this.state.dataObject ? this.state.dataObject.id:-1;
    let obj = [];
    let param = {
      id: id,
      navigation_enum_value:3
    };
    let result = false;

    let res = await Http.getJsonAxios(Http.actions.Roles.urlGetRoleNavigation, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
      obj = res.data.list;
      if (!obj || obj.id === id) Util.showInfoMsg(this.messages, $.strings.recordsEnds);
    } else {
      let msg = $.strings.operationFailed;
      if (res.message && res.message.length > 0) {
        msg = res.message;
      }
      Util.showErrorMsg(this.messages, msg);
    }
    if (result) this.loadData(obj);
    else this.setState({ loading: false });
  };

  onFirst = async (e, checkChanging) => {
    this.setState({ loading: true });
    let obj = [];
    let id = -1;
    if (this.state.dataObject.id) id = this.state.dataObject.id;
    let param = {
      id: -1,
      navigation_enum_value:1
    };
    let result = false;

    let res = await Http.getJsonAxios(Http.actions.Roles.urlGetRoleNavigation, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
      obj = res.data.list;
      if (obj.id === id) Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
    } else {
      let msg = $.strings.operationFailed;
      if (res.message && res.message.length > 0) {
        msg = res.message;
      }
      Util.showErrorMsg(this.messages, msg);
    }
    if (result) this.loadData(obj);
    else this.setState({ loading: false });
  };

  onLast = async (e, checkChanging) => {

    this.setState({ loading: true });
    let obj = [];
    let id = -1;
    if (this.state.dataObject.id) id = this.state.dataObject.id;
    let param = {
      id: -1,
      navigation_enum_value:2
    };
    let result = false;

    let res = await Http.getJsonAxios(Http.actions.Roles.urlGetRoleNavigation, param, this.state.pageInfo.pageId);
    if (res.status === 200) {
      result = true;
      obj = res.data.list;
      if (obj.id === id) Util.showInfoMsg(this.messages, $.strings.recordsEnds);
    } else {
      let msg = $.strings.operationFailed;
      if (res.message && res.message.length > 0) {
        msg = res.message;
      }
      Util.showErrorMsg(this.messages, msg);
    }
    if (result) this.loadData(obj);
    else this.setState({ loading: false });
  };
  resetFields = () => {
    this.dlgOnNew = false;
    this.setState(
      {
        dataObject: {
          id: -1,
          name: '',
          otherName: '',
          notes: '',
          status: 1,
        },
        formErrors: { name: '', otherName: '' },
        deleteErrors: { deleteNote: '' },
        mode: 'new',
        fromArchive: false,
      },
      () => {
        this.txtName.focus();
  
      }
    );
  };

  saveData = async () => {

    let obj = {
      id: !this.state.dataObject.id ? -1 : this.state.dataObject.id,
      name: this.state.dataObject.name
    };

    let saveResult;

    let res = await Http.getJsonAxios(Http.actions.Roles.urlPostSaveRoles, obj, this.state.pageInfo.pageId);
    saveResult = res;

    return saveResult;
  };

  loadData = async (rowData, loadFromArchive) => {
    let obj = rowData;
    if (rowData && Array.isArray(rowData) && rowData.length > 0) {
      obj = rowData[0];
    }
    if (!obj) {
      this.setState({ loading: false });
      return;
    }
    //    let dataReturn  =  await this.getData(obj.id);
    this.setState(
      {
        dataObject: {
          id: obj.id,
          name: obj.name,
          otherName: obj.name_lang2,
          notes: obj.notes,
          status: obj.status,
          dataUsers: obj.permissionRoleUserList,
        },
        fromArchive: loadFromArchive,
        loading: false,
        mode: 'edit',
      },
      () => this.doOnLoad()
    );
  };
  doOnLoad = () => {
    this.txtName.focus();
  };

  showNew = () => {
    this.refreshQueryRequired = false;
  };

  onNew = (e, checkChanging) => {

    if (this.messages) this.messages.clear();
    this.resetFields();
  };

  showFromLog = (obj) => {
    if (obj) {
      this.loadData(obj, true);
    }
  };

  logFileDialogClose = () => {
    this.popupHasClosed();
    this.setState({ logFileDialogVisible: false });
  };

  btnClose = (e, checkChanging) => {
    let doRefresh = this.refreshQueryRequired; //checkChanging ? false : true; // if fields changed (Yes, or no) do refresh, else nothing
    if (this.props.onAddClose) this.props.onAddClose(doRefresh);
  };
  setPageInfo = () => {
    let pageId = 0;
    let title = $.strings.roles.title;
    return { pageId: pageId, title: title };
  };

}
