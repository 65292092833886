import React from "react";
import Card from "../../../Components/Card";
import * as wjNav from '@grapecity/wijmo.react.nav';
import * as wjInput from '@grapecity/wijmo.react.input';

const $ = window.$;

export default class SectionsTree extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isDesktop: true,
            autoCompleteData: this.getSearchList(this.props.value, null, null)
        };
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250
        this.setState({ loading: false, isDesktop: _isDesktop });
    }

    render() {

        return (
            <div ref={e => this.divMain = e} style={{ maxHeight: '450px', overflowY: 'scroll' }}>
                <Card>
                    <wjInput.AutoComplete itemsSource={this.state.autoCompleteData} selectedIndex={-1} displayMemberPath="path"
                        searchMemberPath="keywords" selectedIndexChanged={this.onSelectedIndexChanged.bind(this)} />

                    <wjNav.TreeView itemsSource={this.props.value} initialized={this.initTreeView.bind(this)}
                        displayMemberPath="label" childItemsPath="children" autoCollapse={true}
                        itemClicked={this.props.itemClicked} {...this.props} />
                </Card>
            </div >
        );
    }

    initTreeView(ctl) {
        this._wjTreeViewControl = ctl;
    }
    onSelectedIndexChanged(s) {
        if (s.selectedItem && this._wjTreeViewControl) {
            this._wjTreeViewControl.selectedItem = s.selectedItem.item;
        }
    }
    getSearchList(items, searchList, path) {
        // set defaults
        if (searchList == null)
            searchList = [];
        if (path == null)
            path = '';
        // add items and sub-items
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            searchList.push({
                item: item,
                path: path + item.label,
                keywords: item.label
            });
            if (item.children) {
                this.getSearchList(item.children, searchList, path + item.label + ' / ');
            }
        }
        return searchList;
    }
}