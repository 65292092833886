import React from "react";
import Button from '../../Components/Button'
import http from "../../Api/http";
import Dialog from '../../Components/Dialog';
import DataTable from '../../Components/Datatable';
import Input from "../../Components/Input";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class WFStepDetails extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            approvalDate: undefined,
            RequestNote: undefined,
            WFNotes: undefined
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <Dialog header={$.strings.wfTransReport.additionalDetails} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir }}
                modal onHide={this.onHide}>
                <div ref="divMain" >
                    <div className="p-grid">
                        <div className="p-col-4 p-lg-3 p-sm-12">
                            {$.strings.wfTransReport.approvalDate}
                        </div>
                        <div className="p-col-8 p-lg-9 p-sm-12">
                            {this.state.approvalDate}
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-5 p-lg-3 p-sm-12">
                            {$.strings.wfTransReport.approvalNotes}
                        </div>
                        <div className="p-col-7 p-lg-9 p-sm-12">
                            {this.state.WFNotes}
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-5 p-lg-3 p-sm-12">
                            {$.strings.wfTransReport.requestNotes}
                        </div>
                        <div className="p-col-7 p-lg-9 p-sm-12">
                            {this.state.RequestNote}
                        </div>
                    </div>
                </div >
            </Dialog >
        );
    }
    show = async (approvalDate, RequestNote, WFNotes) => {
        this.setState({
            visibleDialog: true, approvalDate: approvalDate, RequestNote: RequestNote, WFNotes: WFNotes
        })
    }

    onHide = () => {
        this.setState({ visibleDialog: false, approvalDate: undefined, RequestNote: undefined, WFNotes: undefined })
    }
}