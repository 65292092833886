import React from "react";
import http from "../../Api/http";
import Checkbox from "../../Components/Checkbox";

const $ = window.$;

export default class PrintSpecialLeave extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            dataObject: undefined,
            isDesktop: window.innerWidth > 800
        }
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    componentDidMount = async () => {
        document.addEventListener("resize", this.updatePredicate);
        let _dataObject = await this.getData();
        if (_dataObject) {
            this.setState({ dataObject: _dataObject, loading: false }, () => {
                setTimeout(() => {
                    this.doPrint();
                }, 10);
            })
        }
        else {
            this.setState({ loading: false })
        }
    }

    render() {

        return this.state.dataObject ?
            (
                <div id="divMain" style={{ direction: $.strings.dir, padding: '2em' }}>
                    <div className="p-grid">
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: 'center' }}>
                            <br />
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.companyName}</div>
                            <br />
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{this.state.dataObject.companyAddress}</div>
                        </div>
                        <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right' }}>
                            <img src="../../assets/layout/images/printlogo.png" alt="logo" />
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <div style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '5px', textAlign: 'center' }}>{$.strings.printVacation.specialLeaveRequest}</div>
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <hr />
                        </div>



                    </div>
                    <div className="p-grid" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {/* الموظف */}
                        <div className="p-col-7 p-xl-7 p-lg-7 p-md-7 p-sm-7">
                            {$.strings.printVacation.empName + ": "}<span>{this.state.dataObject.empName}</span>
                        </div>

                        {/* القسم */}
                        <div className="p-col-5 p-xl-5 p-lg-5 p-md-5 p-sm-5" >
                            {$.strings.printVacation.section + ": "}<span>{this.state.dataObject.sectionName}</span>
                        </div>

                        {/* تاريخ المغادرة */}
                        <div className="p-col-7 p-xl-7 p-lg-7 p-md-7 p-sm-7">
                            {$.strings.printVacation.specialLeaveDate + ": "}<span>{this.state.dataObject.fromDate}</span>
                        </div>

                        {/* اليوم */}
                        <div className="p-col-5 p-xl-5 p-lg-5 p-md-5 p-sm-5" >
                            {$.strings.printVacation.theDay + ": "}<span >{this.state.dataObject.requestDay}</span>
                        </div>

                        {/* سبب المغادرة */}
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <span>{$.strings.printVacation.specialLeaveReason + ": "}</span>
                            <span>{this.state.dataObject.requestNote}</span>
                        </div>
                    </div>
                    <br />

                    {/* ساعات المغادرة المطلوبة */}
                    <div className="p-grid" style={{ border: '1px solid gray', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ background: 'lightgray' }}>
                            {$.strings.printVacation.requiredLeaveHours}
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
                                <tr>
                                    <td colSpan={"2"} style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>{$.strings.printVacation.from}</td>
                                    <td colSpan={"2"} style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>{$.strings.printVacation.to}</td>
                                    <td colSpan={"2"} style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>{$.strings.printVacation.hoursCount}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>{$.strings.printVacation.minute}</td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>{$.strings.printVacation.hour}</td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>{$.strings.printVacation.minute}</td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>{$.strings.printVacation.hour}</td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>{$.strings.printVacation.minute}</td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>{$.strings.printVacation.hour}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>
                                        {
                                            this.state.dataObject.fromTime ? this.state.dataObject.fromTime.split(':')[1] : '00'
                                        }
                                    </td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>
                                        {
                                            this.state.dataObject.fromTime ? this.state.dataObject.fromTime.split(':')[0] : '00'
                                        }
                                    </td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>
                                        {
                                            this.state.dataObject.fromTime ? this.state.dataObject.toTime.split(':')[1] : '00'
                                        }
                                    </td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>
                                        {
                                            this.state.dataObject.fromTime ? this.state.dataObject.toTime.split(':')[0] : '00'
                                        }
                                    </td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>
                                        {
                                            this.state.dataObject.totalTime ? this.state.dataObject.totalTime.split(':')[1] : '00'
                                        }
                                    </td>
                                    <td style={{ border: '1px solid black', borderCollapse: 'collapse', padding: '5px' }}>
                                        {
                                            this.state.dataObject.totalTime ? this.state.dataObject.totalTime.split(':')[0] : '00'
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" >
                            <hr />
                        </div>

                        {/* توقيع الموظف */}
                        <div className="p-col-5 p-xl-5 p-lg-5 p-md-5 p-sm-5">
                            {$.strings.printVacation.empSign + ": "}
                            <span style={{ borderBottom: '1px dashed black' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>

                        {/* توقيع مدير الدائرة / رئيس القسم */}
                        <div className="p-col-7 p-xl-7 p-lg-7 p-md-7 p-sm-7">
                            {$.strings.printVacation.managerSign + ": "}
                            <span style={{ borderBottom: '1px dashed black' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>

                    </div>
                    <br />

                    {/* شؤون الموظفين */}
                    <div className="p-grid" style={{ border: '1px solid gray', fontSize: '16px', fontWeight: 'bold' }}>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ background: 'lightgray' }}>
                            {$.strings.printVacation.hr}
                        </div>

                        {/* موافق */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Checkbox id="lblAccept" caption={$.strings.printVacation.accept} checked={false} />
                        </div>

                        {/* غير موافق */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Checkbox InputId="lblReject" caption={$.strings.printVacation.reject} checked={false} />
                        </div>
                        <div className="p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-8">
                            <br />
                            ----------------------------------------------------------------------
                        </div>
                        {/* توقيع مسؤول شؤون الموظفين */}
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            {$.strings.printVacation.hrSign + ": "}
                            <span style={{ borderBottom: '1px dashed black' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>
                    </div>
                    <br />

                    {/* المتابعة */}
                    <div className="p-grid" style={{ border: '1px solid gray', fontSize: '16px', fontWeight: 'bold' }}>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ background: 'lightgray' }}>
                            {$.strings.printVacation.followUp}
                        </div>

                        {/* تم مغادرة */}
                        <div className="p-col-1 p-xl-1 p-lg-1 p-md-1 p-sm-1">
                            <Checkbox id="chkLeave" caption={""} checked={false} />
                        </div>
                        <div className="p-col-11 p-xl-11 p-lg-11 p-md-11 p-sm-11" >
                            <div style={{ display: 'flex' }}>
                                <span style={{ fontWeight: 'bold' }}>{$.strings.printVacation.leaveDone}</span>
                                &nbsp; &nbsp;
                                <table style={{ border: '1px solid black', borderCollapse: 'collapse', textAlign: 'center' }}>
                                    <tr>
                                        <td style={{ border: '1px solid black', borderCollapse: 'collapse' }}>{$.strings.printVacation.minutes}</td>
                                        <td style={{ border: '1px solid black', borderCollapse: 'collapse' }}>{$.strings.printVacation.hours}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                    </tr>
                                </table>
                                &nbsp; &nbsp;
                                <span style={{ fontWeight: 'bold' }}>{$.strings.printVacation.actualLeaveNote}</span>
                                &nbsp; &nbsp;
                                <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ marginInlineStart: '5px' }}>
                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{$.strings.printVacation.actualLeaveNoteText}</span>
                        </div>

                        {/* توقيع مسؤول شؤون الموظفين */}
                        <div className="p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-8" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            {$.strings.printVacation.hrSign + ": "}
                            <span style={{ borderBottom: '1px dashed black' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>

                        {/* التاريخ */}
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right', fontSize: '16px', fontWeight: 'bold' }}>
                            {$.strings.printVacation.date + ": "}
                            <span style={{ borderBottom: '1px dashed black' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>
                    </div>
                    <br />

                    {/* الحالات التي تجاوز الموظف فيها رصيد المغادرات المسموح */}
                    <div className="p-grid" style={{ border: '1px solid gray', fontSize: '16px', fontWeight: 'bold' }}>
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12" style={{ background: 'lightgray' }}>
                            {$.strings.printVacation.allowedLeavesCountExceeded}
                        </div>

                        {/* موافق */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Checkbox id="lblAccept" caption={$.strings.printVacation.accept} checked={false} />
                        </div>

                        {/* غير موافق */}
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Checkbox InputId="lblReject" caption={$.strings.printVacation.reject} checked={false} />
                        </div>
                        <div className="p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-8">
                            <br />
                            ----------------------------------------------------------------------
                        </div>

                        {/* ملاحظات رئيس القسم المالي والإداري */}
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            {$.strings.printVacation.financialManagerNote}&nbsp;
                            ----------------------------------------------------------------------
                        </div>

                        {/* توقيع رئيس القسم المالي والإداري */}
                        <div className="p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-8" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            {$.strings.printVacation.financialManagerSign + ": "}
                            <span style={{ borderBottom: '1px dashed black' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>

                        {/* التاريخ */}
                        <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4" style={{ textAlign: $.strings.dir === 'rtl' ? 'left' : 'right', fontSize: '16px', fontWeight: 'bold' }}>
                            {$.strings.printVacation.date + ": "}
                            <span style={{ borderBottom: '1px dashed black' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"/"}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </div>
                    </div>
                </div>
            ) :
            (
                <div id="divMain" style={{ direction: $.strings.dir, padding: '2em' }}>
                    {$.strings.operationFailed}
                </div>
            )
    }

    doPrint = () => {
        setTimeout(() => {
            window.print();
        }, 10);
        window.onafterprint = this.onafterprint;

    }
    onafterprint = () => {
        window.close();
    }

    getData = async () => {
        let dataObject = undefined;
        const params = {
            id: this.props.match.params.id
        }

        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetPrintRequest, params, 0);

        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataObject = res.data.data;
            }
        }
        return dataObject;
    }
    //urlGetPrintRequest
}