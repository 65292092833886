import React from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ScrollTop from '../../ScrollButton';
import Card from "../../../Components/Card";
import SiteMap from "../../../Components/SiteMap";
import ProgressSpinner from "../../../Components/ProgressSpinner";
import Util from "../../../Util";
import Input from '../../../Components/Input';
import SearchEmployees from '../../Employees/SearchEmployees';
import Dropdown from "../../../Components/Dropdown";
import Calendar from "../../../Components/Calendar";
import ButtonsBarLookupList from '../../../Components/ButtonsBarLookupList';
import http from '../../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import PeriodsSearch from "../Periods/PeriodsSearch";
import styled from 'styled-components';
import Button from "../../../Components/Button";
import { padding } from "aes-js";
import { async } from "regenerator-runtime";
import Messages from "../../../Components/Messages";

const $ = window.$;

export default class EmployeesDayShift extends React.Component {
    constructor(props) {
        super(props);
        this.listOfQueues = [];
        this.nowDate = new Date();
        this.state = {
            isDesktop: true,
            fromCode: '',
            toCode: '',
            time: 32000,
            classificationsList: [],
            classification: undefined,
            section: undefined,
            branch: undefined,
            department: undefined,
            columns: [],
            data: [],
            date: new Date(), //new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), 1),
            objPeriod: undefined,
            periodClass: undefined,
            selectedPeriodPos: undefined,
            empCode: undefined
        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(955)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(953)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = window.innerWidth > 1250;


        let classPromise = this.getClassifications();
        let periodClassPromise = this.getPeriodClass();
        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();


        let classificationsList = await classPromise;
        let periodClassList = await periodClassPromise;
        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        let dataList = await this.getData(true);

        this.setState({
            classificationsList: classificationsList, classification: classificationsList && classificationsList.length > 0 ? classificationsList[0] : undefined,
            periodClassList: periodClassList, periodClass: periodClassList && periodClassList.length > 0 ? periodClassList[0] : undefined,
            branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            data: dataList, isDesktop: _isDesktop, loading: false
        })

    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }
    handleDateInput = (e) => {
        this.setState({ date: e.target.value }, async () => await this.getData(false))
    }



    render = () => {

        const Container = styled.div`
        margin: 8px;
        border: 1px solid lightgrey;
        background-color: white;
        border-radius: 2px;
        width: 180px;
        min-height: 550px;
        display: flex;
        flex-direction: column;
        margin-top:20px;
        `;
        const Title = styled.h3`
        padding: 10px;
        background-color:#ebecf0;
        margin:0px;
        text-align:center
        `;
        const TaskList = styled.div`        
        padding: 8px;
        transition: background-color 0.2s ease;
        background-color: ${props => (props.isDraggingOver ? '#e6fcff'/*'skyblue'*/ : 'inherit')};
        flex-grow: 1;
        min-height: 60px;
        max-height:550px;
        overflow-y:scroll;
        `;

        const Handle = styled.div`
        width: 20px;
        height: 20px;
        background-color: orange;
        border-radius: 4px;
        margin-right: 8px;
        `;
        const imgStyle = {
            width: "25px", height: "25px",
            borderRadius: "4px", marginRight: "8px"
        }
        const buttonStyle = {
            width: "25px", height: "25px",
            borderRadius: "4px", marginRight: "8px",
            color: 'orange', padding: 15
        }

        let siteMapItems = [
            { label: $.strings.links.shifts.menu },
            { label: $.strings.employeesDayShift.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <PeriodsSearch ref={e => this.dlgSearchPeriods = e} onSuccess={this.searchPeriod} />
            <PeriodsSearch ref={e => this.dlgSearchPeriodsForEdit = e} onSuccess={this.searchPeriodForEdit} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={e => this.getData(false)} onExcelExport={this.excelExport} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[-1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.employeesDayShift.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                                onBlur={this.onFromCodeBlure}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.employeesDayShift.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="classification"
                                value={this.state.classification}
                                options={this.state.classificationsList}
                                caption={$.strings.employeesDayShift.classification}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                filter
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="department"
                                innerRef={e => this.drpSections = e}
                                value={this.state.department}
                                options={this.state.departmentsList}
                                caption={$.strings.employeesDayShift.department}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                filter
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="branch"
                                innerRef={e => this.drpSections = e}
                                value={this.state.branch}
                                options={this.state.branchesList}
                                caption={$.strings.employeesDayShift.branch}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                                filter
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="section"
                                innerRef={e => this.drpSections = e}
                                value={this.state.section}
                                options={this.state.sectionsList}
                                caption={$.strings.employeesDayShift.section}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                                filter
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-12">
                            <Calendar
                                id="date"
                                value={this.state.date}
                                caption={$.strings.employeesDayShift.date}
                                onChange={this.handleDateInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-6">
                            <Input
                                id="period"
                                value={
                                    this.state.objPeriod ?
                                        this.state.objPeriod.code + ' / ' + this.state.objPeriod.fromTime + ' - ' + this.state.objPeriod.toTime
                                        :
                                        ''
                                }
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.employeesDayShift.period}
                                with2buttons={true}
                                btn1icon="search"
                                btn1event={this.getPeriod}
                                btn2icon="clear"
                                btn2event={this.clearPeriod}
                                disabled={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-12">
                            <Dropdown
                                id="periodClass"
                                innerRef={e => this.drpShifts = e}
                                value={this.state.periodClass}
                                options={this.state.periodClassList}
                                caption={$.strings.employeesDayShift.periodClass}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                filter
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <div className="p-grid" style={{ width: '100%', marginTop: this.state.selectedTeamId + "" === "0" ? '5px' : '-15px' }}>
                <DragDropContext onDragStart={this.onDragStart}
                    onDragUpdate={this.onDragUpdate}
                    onDragEnd={this.onDragEnd} >
                    {
                        this.listOfQueues.map((el, ind) =>
                        (
                            <Droppable key={ind} droppableId={`${ind}`}>
                                {(provided, snapshot) => (
                                    <Container
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        isDragging={ind === 4 ? null : snapshot.isDragging}
                                        style={this.getListStyle()}
                                    >
                                        <Title>{el && el.periods + "" === "255" ? $.strings.employeesDayShift.holiday : el.workfrom + " - " + el.workto}</Title>
                                        <TaskList isDraggingOver={snapshot.isDraggingOver} >
                                            {

                                                el.empList && el.empList.map((item, index) =>
                                                (
                                                    <Draggable
                                                        key={item.code + "_" + item.name}
                                                        draggableId={item.code + "_" + item.name}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef}
                                                                {...provided.draggableProps} {...provided.dragHandleProps}
                                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                className={`${"p-grid"}`}
                                                            >
                                                                {/* <Handle {...provided.dragHandleProps} /> */}
                                                                {/* <img alt="task type" src={this.setTypeImage(item.type)} {...provided.dragHandleProps}
                                                                    style={imgStyle} title={Util.getTaskTypeName(item.type)} /> */}
                                                                {/* <img alt="priority" src={this.setPriorityImage(item.priority_id)} {...provided.dragHandleProps}
                                                                    style={imgStyle} title={Util.getPriorityName(item.priority_id)} /> */}
                                                                {
                                                                    <>
                                                                        {
                                                                            ((Util.checkISHR() && Util.checkRights(949)) ||
                                                                                (Util.checkISManager() && Util.checkRights(952))
                                                                            )
                                                                            &&
                                                                            <Button className="p-button-warning" icon="pi pi-pencil" style={buttonStyle}
                                                                                onClick={(e) => this.editPeriod(e, item)} tooltip={$.strings.edit}
                                                                                tooltipOptions={{ position: 'bottom' }} />
                                                                        }
                                                                        <span style={{ marginTop: '5px' }}>&nbsp; {+ item.code}</span>
                                                                    </>
                                                                }
                                                                <div className="p-col-12 p-lg-12 p-sm-12">
                                                                    {
                                                                        item.name
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                        </TaskList>
                                        {provided.placeholder}
                                    </Container>
                                )}
                            </Droppable>
                        )
                        )}
                </DragDropContext>
            </div >
            <ScrollTop />
        </div>
    }

    onFromCodeBlure = () => {
        if (!this.state.toCode || this.state.toCode.length <= 0)
            this.setState({ toCode: this.state.fromCode });
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }
    getPeriod = async () => {
        await this.dlgSearchPeriods.show();
    }
    searchPeriod = (obj) => {
        if (obj) {
            this.setState({ objPeriod: obj })
        }
    }
    clearPeriod = () => {
        this.setState({ objPeriod: undefined })
    }
    editPeriod = (e, objEmp) => {
        this.setState({ empCode: objEmp.code }, () => this.dlgSearchPeriodsForEdit.show())
    }
    searchPeriodForEdit = async (obj) => {
        if (obj) {
            //alert("Call Update For Period: " + obj.code + "\r\n on Employee: " + this.state.empCode + '\r\n on date: ' + new Date(this.state.date).toLocaleDateString());
            await this.saveData(obj.code, this.state.empCode);
        }
    }
    getClassifications = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 12
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }
    getPeriodClass = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 30
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }
    getTransTypes = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 26
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp)
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
        }
        return dataTemp;
    }
    getStatus = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            withAll: true
        }
        let res = await http.getJsonAxios(http.actions.RequestsStatus.urlGetRequestsStatus, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }

    getData = async (fromLoad) => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        let dataTemp = [];
        let obj = {

            shiftsDate: new Date(this.state.date),
            periodPos: this.state.objPeriod ? this.state.objPeriod.code : -1,
            periodType: this.state.periodClass ? this.state.periodClass.pos : -1,
            fromEmpCode: this.state.fromCode ? this.state.fromCode : '',
            toEmpCode: this.state.toCode ? this.state.toCode : '',
            sectionCode: this.state.section ? this.state.section.ID : -1,
            co_dep: this.state.department ? this.state.department.pos : -1,
            co_branch: this.state.branch ? this.state.branch.Pos : -1,
            classification: this.state.classification ? this.state.classification.pos : -1,
            isHR: Util.checkISHR(),
            isDirectManager: Util.checkISManager()
        }

        let res = await http.getJsonAxios(http.actions.Shifts.urlGetShiftsForEmployeeForOneDay, obj, 0);

        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.periodsList;
                if (dataTemp) {
                    dataTemp.sort((a, b) => (a.workfrom > b.workfrom) ? 1 : ((b.workto > a.workto) ? -1 : 0))
                }
            }
        }

        this.setArrayValues(dataTemp);
        if (!fromLoad)
            this.setState({ data: dataTemp, empCode: undefined, selectedPeriodPos: undefined, loading: false });
        else
            return dataTemp;
    }

    saveData = async (period, empCode) => {
        this.setState({ loading: true })
        let dataTemp = [];
        let obj = {
            empCode: empCode,
            shiftsDate: new Date(this.state.date),
            periods: period,
            periodPos: this.state.objPeriod ? this.state.objPeriod.code : -1,
            periodType: this.state.periodClass ? this.state.periodClass.pos : -1,
            fromEmpCode: this.state.fromCode ? this.state.fromCode : '',
            toEmpCode: this.state.toCode ? this.state.toCode : '',
            sectionCode: this.state.section ? this.state.section.ID : -1,
            co_dep: this.state.department ? this.state.department.pos : -1,
            co_branch: this.state.branch ? this.state.branch.Pos : -1,
            classification: this.state.classification ? this.state.classification.pos : -1,
            isHR: Util.checkISHR(),
            isDirectManager: Util.checkISManager()
        }
        let res = await http.getJsonAxios(http.actions.Shifts.urlGetSaveEmpShiftForDay, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.list.periodsList;
                if (dataTemp) {
                    dataTemp.sort((a, b) => (a.workfrom > b.workfrom) ? 1 : ((b.workto > a.workto) ? -1 : 0))
                }
            }
        }

        this.setArrayValues(dataTemp);
        this.setState({ data: dataTemp, empCode: undefined, selectedPeriodPos: undefined, loading: false });
    }

    setArrayValues = (data) => {
        if (this.listOfQueues) {
            this.listOfQueues = [];
            this.listOfQueues = data
        }
    }

    getListStyle = (isDraggingOver) => {
        return {
            background: isDraggingOver ? "lightblue" : "",
            padding: grid,
            width: this.state.isDesktop ? 300 : '100%'
        }
    }

    onDragStart = () => {
        document.body.style.color = 'orange';
        document.body.style.transition = 'background-color 0.2s ease';
    }

    onDragUpdate = update => {
        const { source } = update.source;
        if (update.destination && update.destination !== null) {
            const { destination } = update.destination;
            const opacity = destination && destination !== null
                ? destination.index / Object.keys(this.state.data[source.droppableId]).length
                : 0;
            document.body.style.backgroundColor = `rgba(153, 141, 217, ${opacity})`
        }
    }

    onDragEnd = async (result) => {
        document.body.style.color = 'inherit';
        document.body.style.backgroundColor = 'inherit';
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        if (sInd === 4) {
            return;
        }
        //this.messages.clear();
        if (sInd === dInd) {
        } else {
            let objPeriodFrom = this.listOfQueues[sInd];
            if (!objPeriodFrom || objPeriodFrom == null) {
                return;
            }
            let empCode = objPeriodFrom.empList[source.index].code;
            let objPeriodTo = this.listOfQueues[dInd];
            if (!objPeriodTo || objPeriodTo == null) {
                return;
            }
            let periodPos = objPeriodTo.periods
            await this.saveData(periodPos, empCode);
        }
    }
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#ffffff",

    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};