window.$ = {}
window.$.version = '0.0.1'
window.$.versiondate = '2018-10-22'
window.$.strings = {}
window.$.history = {}
window.$.printLogo = undefined
window.$.printPortal = undefined
window.$.printResizeRequests = []
window.$.authentication = {}
window.$.pages = {}
window.$.systemSettings = {}
window.$.license = {}
window.$.showSessionExpired = true;
window.$.utcOffset = 2;
window.$.requestsAliases = {}
window.$.requestResize = function (obj) {
    window.$.printResizeRequests.push(obj)
}
window.$.finishResize = function () {
    window.$.printResizeRequests.pop();
    if (window.$.printPortal) //should not be null, but just incase
        window.$.printPortal.printInternal()
}
window.$.getPrintLogo = function () {
    window.$.requestResize("logo");
    return window.$.printLogo;
}

window.$.minimumSqlDate = '1000-01-01 00:00:00'
window.$.maximumSqlDate = '9999-12-31 23:59:59'
window.$.format = {
    datetime: 'YYYY-MM-DD hh:mm A',
    date: 'YYYY-MM-DD',
    time: 'hh:mm a',
    shortTime: 'HH:mm',
    fullDateTime: 'YYYY-MM-DD HH:mm:ss',
}

