import React from "react";
import styles from './News.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import Calendar from '../../Components/Calendar'
import SiteMap from '../../Components/SiteMap';
import ScrollTop from '../ScrollButton';
import Editor from '../../Components/InputEditor';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class News extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.nowDate = new Date();
        this.targetList = [
            { id: -1, name: $.strings.newsAdd.select },
            { id: 0, name: $.strings.newsAdd.employee },
            { id: 1, name: $.strings.newsAdd.job },
            { id: 2, name: $.strings.newsAdd.sectionsManagers },
            { id: 3, name: $.strings.newsAdd.section },
        ];
        this.statusList = [
            { id: 0, name: $.strings.newsAdd.active },
            { id: 1, name: $.strings.newsAdd.finished }
        ]
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { target: '', employee: '', section: '', job: '', title: '', details: '', endDate: '', status: '' },
            deleteErrors: { deleteNote: '' },
            periodList: Util.getPeriodList(),
            isDesktop: window.innerWidth > 1250,
            employeesList: [],
            jobsList: [],
            sectionsList: [],
            dataObject: {
                id: 0,
                requestDate: this.nowDate,
                target: this.targetList[0],
                title: undefined,
                details: undefined,
                employee: undefined,
                job: undefined,
                section: undefined,
                endDate: this.nowDate,
                status: this.statusList[0]
            }
        }
    }
    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = window.innerWidth > 1250;
        let employees = await this.getEmployees();
        let jobs = await this.getJobs();
        let sections = await this.getSections();

        this.setState({
            employeesList: employees, jobsList: jobs, sectionsList: sections,
            isDesktop: _isDesktop, loading: false
        });
        if (!this.props.match.params.id) {
            if (!Util.checkRights(703)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (this.props.match.params.id) {
                if (!Util.checkRights(704)) {
                    Util.goTo('/Access');
                    return;
                }
                let data = await this.getData(this.props.match.params.id)
                this.loadData(data)
            }
        }
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }
    handleTarget = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, target: e.target.value } });
    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;

        let _target = true;
        fieldValidationErrors.target = '';
        if (!this.state.dataObject.target || this.state.dataObject.target.id < 0) {
            fieldValidationErrors.target = $.strings.requiredFiled;
            _target = false;
        }

        let _employee = true;
        if ((this.state.dataObject.target && this.state.dataObject.target.id === 0) &&
            (!this.state.dataObject.employee || this.state.dataObject.employee.length <= 0)) {
            fieldValidationErrors.employee = $.strings.requiredFiled;
            _employee = false;
        }

        let _job = true;
        if ((this.state.dataObject.target && this.state.dataObject.target.id === 1) &&
            (!this.state.dataObject.job || this.state.dataObject.job.id <= 0)) {
            fieldValidationErrors.job = $.strings.requiredFiled;
        }

        let _section = true;
        if ((this.state.dataObject.target && this.state.dataObject.target.id === 3) &&
            (!this.state.dataObject.section || this.state.dataObject.section.length <= 0)) {
            fieldValidationErrors.section = $.strings.requiredFiled;
            _section = false;
        }

        let _title = true;
        if (!this.state.dataObject.title || this.state.dataObject.title.length <= 0) {
            fieldValidationErrors.title = $.strings.requiredFiled;
            _title = false;
        }

        let _details = true;
        let objEditor = this.editor.editorElement.__quill;
        let editorText = objEditor.getText(0);
        if (!objEditor || !editorText || editorText.trim().length <= 0) {
            fieldValidationErrors.details = $.strings.requiredFiled;
            _details = false;
        }

        let _endDate = true;
        if (!this.state.dataObject.endDate || (this.state.dataObject.endDate + "").length <= 0) {
            fieldValidationErrors.endDate = $.strings.requiredFiled;
            _endDate = false;
        }

        let _status = true;
        if (!this.state.dataObject.status || this.state.dataObject.status.id < 0) {
            fieldValidationErrors.status = $.strings.requiredFiled;
            _status = false;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            targetValid: _target,
            employeeValid: _employee,
            jobValid: _job,
            sectionValid: _section,
            titleValid: _title,
            detailsValid: _details,
            endDateValid: _endDate,
            statusValid: _status
        }, this.validateForm);

        return _target && _employee && _job && _section && _title && _details && _endDate && _status;
    }
    validateForm() {
        let isFormValid = this.state.targetValid && this.state.employeeValid && this.state.jobValid
            && this.state.sectionValid && this.state.titleValid && this.state.detailsValid && this.state.endDateValid && this.state.statusValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        let siteMapItems = [
            { label: $.strings.links.staticFilesAndSettings },
            {
                label: <div>
                    {$.strings.newsAdd.title + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" className={styles.addWorkLeave}>
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={this.resetFields}
                            onSave={this.onSave}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid" style={{ width: '100%' }}>
                    <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtCode = el}
                                type="text"
                                id="id"
                                keyfilter="pnum"
                                value={this.state.dataObject.id || ''}
                                autofocus={true}
                                autocomplete="off"
                                caption={$.strings.newsAdd.code}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Calendar
                                id="requestDate"
                                value={this.state.dataObject.requestDate}
                                caption={$.strings.newsAdd.requestDate}
                                tooltip={$.strings.newsAdd.requestDate}
                                readOnlyInput={true}
                                readOnly={true}
                                disabled
                            />
                        </div>
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Calendar
                                id="requestDate"
                                value={this.state.dataObject.requestDate}
                                caption={$.strings.newsAdd.requestDate}
                                tooltip={$.strings.newsAdd.requestDate}
                                readOnlyInput={true}
                                readOnly={true}
                                //timeOnly
                                disabled
                            />                            
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="target"
                                value={this.state.dataObject.target || ''}
                                options={this.targetList}
                                caption={$.strings.newsAdd.target}
                                onChange={this.handleTarget}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                optionLabel="name"
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                        {   // employee
                            this.state.dataObject.target && this.state.dataObject.target.id === 0 &&
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="employee"
                                    value={this.state.dataObject.employee || ''}
                                    options={this.state.employeesList}
                                    caption={$.strings.newsAdd.employee}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    filter
                                    filterBy="name"
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                        }
                        {   // job
                            this.state.dataObject.target && this.state.dataObject.target.id === 1 &&
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="job"
                                    value={this.state.dataObject.job || ''}
                                    options={this.state.jobsList}
                                    caption={$.strings.newsAdd.job}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    filter
                                    filterBy="name"
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                        }
                        {   // Section
                            this.state.dataObject.target && this.state.dataObject.target.id === 3 &&
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="section"
                                    value={this.state.dataObject.section || ''}
                                    options={this.state.sectionsList}
                                    caption={$.strings.newsAdd.section}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                    filter
                                    filterBy="Name"
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                        }
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Input
                                id="title"
                                value={this.state.dataObject.title || ''}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                caption={$.strings.newsAdd.subject}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Editor
                                innerRef={e => this.editor = e}
                                value={this.state.dataObject.details}
                                onTextChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, details: e.htmlValue } })}
                                //onLoad={this.onLoad}
                                isRequired={true}
                                caption={$.strings.newsAdd.details}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Calendar
                                id="endDate"
                                value={this.state.dataObject.endDate}
                                caption={$.strings.newsAdd.endDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Dropdown
                                id="status"
                                value={this.state.dataObject.status || ''}
                                options={this.statusList}
                                caption={$.strings.newsAdd.status}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                </Card>
                <ScrollTop />
            </div >
        );
    }

    onLoad = () => {
        // if (this.state.dataObject.id <= 0) {
        //     if (this.editor && this.editor.editorElement) {
        //         this.editor.editorElement.__quill.format('align', 'right');
        //     }
        // }
    }

    getEmployees = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: '',
            isHr: Util.checkISHR(),
            getEmpStatus: false,
            section: -1,
            empStatus: -1
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;
        }
        return dataTemp;
    }
    getJobs = async () => {
        let dataTemp = []
        let obj = {
            type: 3
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp;
    }
    getSections = async () => {
        let dataTemp = []
        let obj = {
            type: 3
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }

    getData = async (id) => {
        let dataTemp = []
        let obj = {
            id: id
        }
        let res = await http.getJsonAxios(http.actions.News.urlGetNewsById, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp && dataTemp.length > 0) {
                dataTemp = dataTemp[0];
            }
        }
        return dataTemp
    }
    loadData = (data) => {
        let target = this.targetList.find(element => element.id + "" === data.Target + "");
        let status = this.statusList.find(element => element.id + "" === data.Status + "");
        let employee = undefined, job = undefined, section = undefined;
        if (data.Target + "" === "0") {
            employee = this.state.employeesList.find(element => element.code + "" === data.EmpCode + "");
        }
        else if (data.Target + "" === "1") {
            job = this.state.jobsList.find(element => element.pos + "" === data.EmpCode + "");
        }
        else if (data.Target + "" === "3") {
            section = this.state.sectionsList.find(element => element.ID + "" === data.EmpCode + "");
        }
        let date = new Date(data.NewsDate);
        let expieryDate = new Date(data.ExpieryDate);

        this.setState({
            mode: 'view',
            dataObject: {
                ...this.state.dataObject,
                id: data.No,
                requestDate: date,
                target: target,
                title: data.NewsAddress,
                details: data.NewsDetails,
                employee: employee,
                job: job,
                section: section,
                endDate: expieryDate,
                status: status
            }, loading: false
        });
    }

    onSave = async () => {
        this.messages.clear();
        if (!this.validateField()) {
            return false;
        }

        this.setState({ loading: true })
        let res = undefined;
        if (this.state.dataObject.id === 0) {
            res = await this.saveData(false);
        }
        else {
            res = await this.saveData(true);
        }
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.message)
                    Util.showSuccessMsg(this.messages, res.data.message);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
            }
        }
        else {
            if (res.data.message !== "") {
                Util.showErrorMsg(this.messages, res.data.message);
                this.setState({ loading: false })
            }
            else {
                Util.showErrorMsg(this.messages);
                this.setState({ loading: false })
            }
        }
    }
    saveData = async (isUpdate) => {
        let obj = {
            destination: this.state.dataObject.target.id,
            empCode: this.state.dataObject.target.id === 0 ? this.state.dataObject.employee.code : '',
            job: this.state.dataObject.target.id === 1 ? this.state.dataObject.job.pos : '',
            section: this.state.dataObject.target.id === 3 ? this.state.dataObject.section.ID : '',
            title: this.state.dataObject.title,
            details: this.state.dataObject.details,
            date: this.state.dataObject.requestDate,
            time: this.state.dataObject.requestDate,
            expireDate: this.state.dataObject.endDate,
            status: this.state.dataObject.status.id,
            //serial: this.state.dataObject.id,
        }

        let res;
        if (!isUpdate) {
            res = await http.postMultipartAxios(http.actions.News.urlSaveNews, obj, null, null, 0);
        }
        else {
            obj.serial = this.state.dataObject.id
            res = await http.postMultipartAxios(http.actions.News.urlUpdateNews, obj, null, null, 0);
        }
        return res;
    }

    resetFields = () => {
        this.nowDate = new Date();
        this.setState({
            dataObject: {
                id: 0,
                requestDate: this.nowDate,
                target: this.targetList[0],
                title: undefined,
                details: undefined,
                employee: undefined,
                job: undefined,
                section: undefined,
                endDate: this.nowDate,
                status: this.statusList[0]
            },
            formErrors: { target: '', employee: '', section: '', job: '', title: '', details: '', endDate: '', status: '' },
        });
    }
}