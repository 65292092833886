import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import Card from '../../Components/Card';
import SiteMap from "../../Components/SiteMap";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class VacBalances extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            empCode: this.props.match.params.id || localStorage.getItem("useridHR") || '',
            currentBalance: '',
            yearBalance: '',
            overTimeBalance: ''
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(715)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(715)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();

        let vacationBalancesPromise = this.getVacationsBalances(this.props.match.params.id);
        let vacationBalances = await vacationBalancesPromise;
        
        let overTimeBalancesPromise = this.getOverTimeBalance(this.props.match.params.id);
        let overTimeBalances = await overTimeBalancesPromise;

        this.setState({
            empCode: vacationBalances.empCode || '', empName: vacationBalances.empName, yearBalance: vacationBalances.yearlyBalance,
            currentBalance: vacationBalances.currentBalance, isDesktop: _isDesktop, loading: false,
            overTimeBalance: overTimeBalances.overTimeBalance + ''
        })
    }

    render() {
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            {
                label: <div>
                    {$.strings.vacations.vacBalances + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (
            <div ref="divMain" >
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <Card title={$.strings.vacations.vacBalances}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                type="text"
                                id="empCode"
                                maxLength="70"
                                value={this.state.empCode}
                                caption={$.strings.vacations.empCode}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                            />
                        </div>

                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                type="text"
                                id="empName"
                                maxLength="70"
                                value={this.state.empName}
                                caption={$.strings.vacations.empName}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtCurrentBalance = el}
                                type="text"
                                id="currentBalance"
                                maxLength="70"
                                value={this.state.currentBalance === 'null' ? '' : this.state.currentBalance}
                                caption={$.strings.vacations.currentBalance}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtYearBalance = el}
                                type="text"
                                id="yearBalance"
                                maxLength="70"
                                value={this.state.yearBalance === 'null' ? '' : this.state.yearBalance}
                                caption={$.strings.vacations.yearBalance}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtOverTimeBalance = el}
                                type="text"
                                id="overTimeBalance"
                                maxLength="70"
                                value={this.state.overTimeBalance === 'null' ? '' : this.state.overTimeBalance}
                                caption={$.strings.vacations.overTimeBalance}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                            />
                        </div>
                    </div>
                </Card>

            </div>
        );
    }

    getVacationsBalances = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode && empCode.length > 0 ? empCode : this.state.empCode,
            isEncrypted: !empCode ? false : true
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetVacationBalances, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }
    getOverTimeBalance = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode && empCode.length > 0 ? empCode : this.state.empCode,
            isEncrypted: !empCode ? false : true
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetOverTimeBalances, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

}