import React from "react";
import styles from "./ButtonsBarLookup.module.scss";
import Button from "../Button"
import { Menu } from 'primereact/menu';
import { FaTrash, FaAngleRight, FaAngleLeft, FaCheck, FaTimes, FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa'

const $ = window.$;

export default class ButtonsBarLookup extends React.Component {
    constructor(props) {
        super(props);
        this.menuItemsList = this.splitButtonItems();
        this.state = {
            splitItems: this.menuItemsList.filter(element => element.visible === true),
            isDesktop: window.innerWidth > 450
        };

    }

    componentDidMount() {
        window.addEventListener("resize", this.updatePredicate)
        this.setState({ isDesktop: window.innerWidth > 450 });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate)
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    render() {
        return this.state.isDesktop ? (
            <div className={`p-grid ${styles.buttonBarLookup}`} style={{ width: '100%' }}>
                <div className="p-col-7 p-xl-7 p-lg-7 p-md-7 p-sm-7">
                    {
                        (this.props.newVisible || this.props.onNew)
                        &&
                        <Button icon="pi pi-plus" onClick={this.props.onNew} tooltip={$.strings.new}
                            iconPos={$.strings.dir === 'rtl' ? 'right' : 'left'} />
                    }
                    {
                        this.props.onRefresh &&
                        <Button icon="pi pi-sync" onClick={this.props.onRefresh} tooltip={$.strings.refresh}
                            iconPos={$.strings.dir === 'rtl' ? 'right' : 'left'} />
                    }
                    {
                        this.props.onSave
                        &&
                        <Button icon="pi pi-save" onClick={this.props.onSave} tooltip={$.strings.save} innerRef={ref => this.btnSave = ref}
                            id="btnSave" className="p-button-success" iconPos={$.strings.dir === 'rtl' ? 'right' : 'left'} />
                    }
                    {
                        this.props.onPrevious &&
                        <Button onClick={this.props.onPrevious} tooltip={$.strings.previous} >
                            {$.strings.dir === "rtl" ? (<FaAngleRight style={{ fontStyle: "bold", fontSize: "18px" }} />)
                                : (<FaAngleLeft style={{ fontStyle: "bold", fontSize: "18px" }} />)}
                        </Button>
                    }
                    {
                        this.props.onNext &&
                        <Button onClick={this.props.onNext} tooltip={$.strings.next} >
                            {$.strings.dir === "rtl" ? (<FaAngleLeft style={{ fontStyle: "bold", fontSize: "18px" }} />)
                                : (<FaAngleRight style={{ fontStyle: "bold", fontSize: "18px" }} />
                                )}
                        </Button>
                    }
                    {
                        (this.props.onDelete || this.props.onFirst || this.props.onLast) &&
                        <div style={{ position: "relative", display: "inline-block" }}>
                            <Button onClick={this.showSplitButtonsDot} tooltip={$.strings.more} icon="pi pi-bars" />
                            <Menu model={this.splitButtonItemsDot().filter((element) => element.visible === true)} popup={true} ref={(ref) => (this.menuDot = ref)} />
                        </div>
                    }
                    {
                        this.props.onTransDetails
                        &&
                        <Button icon="pi pi-id-card" onClick={this.props.onTransDetails} tooltip={$.strings.transDetails}
                            innerRef={ref => this.btnTransDetails = ref}
                            id="btnTransDetails" iconPos={$.strings.dir === 'rtl' ? 'right' : 'left'} />
                    }
                    {
                        this.props.onPrint &&
                        <Button icon="pi pi-print" onClick={this.props.onPrint} tooltip={$.strings.print} innerRef={ref => this.btnPrint = ref}
                            id="btnPrint" iconPos={$.strings.dir === 'rtl' ? 'right' : 'left'} />
                    }
                    {( !this.props.onExcelExport ? false : true) && <Button onClick={this.props.onExcelExport}
                    tooltip={$.strings.excelExport} label={$.strings.excelExport} >
                     &nbsp;&nbsp;
                      <i className="pi pi-file-excel" />
                        </Button>
                    }
                    {
                        this.props.onShowAttachment &&
                        <Button icon="pi pi-paperclip" onClick={this.props.onShowAttachment} tooltip={$.strings.showAttachment} innerRef={ref => this.btnShowAttachment = ref}
                            id="btnShowAttachment" iconPos={$.strings.dir === 'rtl' ? 'left' : 'right'} />
                    }
                </div>
                <div className="p-col-5 p-xl-5 p-lg-5 p-md-5 p-sm-5" style={{ textAlign: $.strings.dir === "rtl" ? "left" : "right" }}>
                    {
                        this.props.onSaveThenClose &&
                        <Button onClick={this.props.onSaveThenClose} tooltip={$.strings.saveAndClose} icon="pi pi-check" className="p-button-success" />
                    }
                    {
                        this.props.onClose &&
                        <Button onClick={this.props.onClose} tooltip={$.strings.close} icon="pi pi-times" className="p-button-danger" />
                    }
                </div>
            </div >
        )
            :
            (
                <div className={`p-grid ${styles.buttonBarLookup}`} style={{ direction: $.strings.dir }}>
                    {
                        this.props.onNew &&
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Button icon="pi pi-plus" onClick={this.props.onNew} tooltip={$.strings.new} />
                        </div>
                    }
                    {
                        this.props.onSave &&
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                            <Button icon="pi pi-save" onClick={this.props.onSave} tooltip={$.strings.save} innerRef={ref => this.btnSave = ref}
                                id="btnSave" className="p-button-success" />
                        </div>
                    }
                    <div className="p-col-4 p-xl-2 p-lg-2 p-md-2 p-sm-2">
                        {/* <Menu model={this.splitButtonItems().filter(element => element.visible === true)} popup={true} ref={ref => this.menu = ref} />
                    {
                        this.splitButtonItems().filter(element => element.visible === true).length > 0 &&
                        <Button onClick={this.showSplitButtons} style={{ top: '0px' }}>
                            <i className="pi pi-ellipsis-v"></i>
                        </Button>
                    } */}
                    </div>
                    {
                        this.props.onSave &&
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === "rtl" ? 'left' : 'right' }}>
                            <Button
                                onClick={this.props.onSave}
                                tooltip={$.strings.saveAndClose}
                                icon="pi pi-check"
                                className="p-button-success"
                            ></Button>
                        </div>
                    }
                    {
                        this.props.onClose &&
                        <div className="p-col-2 p-xl-2 p-lg-2 p-md-2 p-sm-2" style={{ textAlign: $.strings.dir === "rtl" ? 'left' : 'right' }}>
                            <Button
                                onClick={this.props.onClose}
                                tooltip={$.strings.close}
                                icon="pi pi-times"
                                className="p-button-danger"
                            ></Button>
                        </div>
                    }
                </div>
            )
    }

    showSplitButtons = (event) => {
        this.menu.toggle(event);
    }

    showSplitButtonsDot = (event) => {
        this.menuDot.toggle(event);
    }

    splitButtonItems = () => {
        let items = [
            {
                label:
                    (
                        <Button label={$.strings.refresh} onClick={this.props.onRefresh} tooltip={$.strings.refresh}
                            className={styles.menuItem} >
                            <i className="pi pi-plus" />
                        </Button>
                    ),
                icon: '',
                //command: e => this.props.onRefresh(),
                visible: this.props.onRefresh ? true : false
            },
            {
                label:
                    (
                        <Button label={$.strings.transDetails} onClick={this.props.onTransDetails} tooltip={$.strings.transDetails}
                            innerRef={ref => this.btnTransDetails = ref} id="btnTransDetails" className={styles.menuItem}>
                            &nbsp;&nbsp;
                            <i className="pi pi-id-card" />
                        </Button>
                    ),
                icon: '',
                visible: this.props.onTransDetails ? true : false,
                //command: e => this.props.onTransDetails(),
            },
            {
                label:
                    (<Button label={$.strings.showAttachment} onClick={this.props.onShowAttachment} tooltip={$.strings.showAttachment}
                        innerRef={ref => this.btnShowAttachment = ref} id="btnShowAttachment" className={styles.menuItem}>
                        <i className="pi pi-eye" />
                    </Button>
                    ),
                icon: '',
                visible: this.props.onShowAttachment ? true : false,
                //command: e => this.props.onShowAttachment(),
            },
            {
                label: (
                    <Button label={$.strings.previous} onClick={this.props.onPrevious} tooltip={$.strings.previous}
                        className={`${styles.menuItem}`}>
                        {$.strings.dir === "rtl" ? (<FaAngleRight style={{ fontStyle: "bold", fontSize: "18px" }} />)
                            : (<FaAngleLeft style={{ fontStyle: "bold", fontSize: "18px" }} />)}
                    </Button>),
                icon: '',
                visible: this.props.onPrevious ? true : false,
                //command: e => this.props.onPrevious()
            },
            {
                label: (
                    <Button label={$.strings.next} onClick={this.props.onNext} tooltip={$.strings.next} className={`${styles.menuItem}`}>
                        {$.strings.dir === "rtl" ? (<FaAngleLeft style={{ fontStyle: "bold", fontSize: "18px" }} />)
                            : (<FaAngleRight style={{ fontStyle: "bold", fontSize: "18px" }} />
                            )}
                    </Button>
                ),
                icon: '',
                visible: this.props.onNext ? true : false,
                //command: e => this.props.onNext()
            }
        ];
        this.splitButtonItemsDot().map(e => items.push(e));
        items.push({
            label: (
                <Button label={$.strings.saveAndClose} onClick={this.props.onSaveThenClose} tooltip={$.strings.saveAndClose}
                    className={`${"p-button-success"} ${styles.menuItem}`}>
                    <FaCheck />
                </Button>
            ),
            icon: '',
            visible: this.props.onSaveThenClose ? true : false,
            //command: e => this.props.onSaveThenClose()
        });
        items.push({
            label: (
                <Button label={$.strings.close} onClick={this.props.onClose} tooltip={$.strings.close}
                    className={`${"p-button-danger"} ${styles.menuItem}`}>
                    <FaTimes />
                </Button>
            ),
            icon: '',
            visible: this.props.onClose ? true : false,
            //command: e => this.props.onClose()
        })
        return items;
    }

    setFocus = (buttonName) => {
        switch (buttonName.toLowerCase()) {
            case "save": {
                this.btnSave.focus()
                document.getElementById("btnSave").focus()
                break
            }
            default: {
                break
            }
        }
    }

    splitButtonItemsDot = () => {
        let items = [
            {
                label: (
                    <Button label={$.strings.first} tooltip={$.strings.firstTooltip} className={styles.menuItem} onClick={this.props.onFirst}>
                        {
                            $.strings.dir === "rtl" ?
                                (<FaAngleDoubleRight style={{ fontStyle: "bold", fontSize: "18px" }} />)

                                : (<FaAngleDoubleLeft style={{ fontStyle: "bold", fontSize: "18px" }} />)
                        }
                    </Button>
                ),
                //command: e => this.props.onFirst(),
                visible: this.props.onFirst ? true : false
            },
            {
                label: (
                    <Button label={$.strings.end} tooltip={$.strings.endTooltip} className={styles.menuItem} onClick={this.props.onLast}>
                        {
                            $.strings.dir === "rtl" ?
                                (<FaAngleDoubleLeft style={{ fontStyle: "bold", fontSize: "18px" }} />)

                                : (<FaAngleDoubleRight style={{ fontStyle: "bold", fontSize: "18px" }} />)
                        }
                    </Button>
                ),
                //command: e => this.props.onLast(),
                visible: this.props.onLast ? true : false
            },
            {
                separator: true,
                visible: this.props.onFirst || this.props.onLast,
            },
            {
                label: (
                    <Button
                        tooltip={$.strings.deleteTooltip}
                        className={`${styles.menuItem} ${"p-button-danger"}`}
                        onClick={this.props.onDelete}
                    >
                        <FaTrash />
                        <span style={{ width: "100%", textAlign: "center" }}>
                            {$.strings.delete}
                        </span>
                    </Button>
                ),
                //command: e => this.props.onDelete(),
                visible: this.props.onDelete ? true : false,
            },
            {
                separator: true,
                visible: this.props.onDelete,
            }
        ];

        // add dynamic buttons from any pages
        if (this.props.itemsList && this.props.itemsList.length > 0) {
            items.push({ separator: true, visible: true });
            let tempItems = this.props.itemsList;
            tempItems.map((element) => {
                items.push({
                    label: (
                        <Button
                            label={element.label}
                            tooltip={element.tooltip}
                            className={styles.menuItem}
                            onClick={element.command}
                        />
                    ),
                    //command: e => element.command(),
                    visible: element.visible,
                });
            });
        }
        return items;
    };
}

ButtonsBarLookup.defaultProps = {
    permissions: {
        saveWithoutClose: {
            visible: true,
            disabled: false
        },
        new: {
            visible: true,
            disabled: false
        }
    }
}