import React, { Component } from 'react';
import { Button } from "primereact/button";
import Util from '../Util';

const $ = window.$
export default class Error extends Component {

	constructor(props) {
		super(props);
		$.history = props.history;
		let l = localStorage.getItem("HRlang");
		let domain = localStorage.getItem("domain");
		this.state = {
			language: l || "en",
			domain: domain || "",
			checked: false
		};
	}

	componentDidMount = async () => {
		await this.loadLanguage(this.state.language);
		this.setState({ isRTL: $.strings.dir === "rtl" ? true : false, loading: false });
	}

	loadLanguage = async lang => {
		$.strings = await import(`../Languages/${lang}.json`);
		$.pages = await import('../pages.json')
	};

	render() {
		return <div className="exception-body error" dir={$.strings.dir}>
			<div className="exception-panel">
				<div className="exception-content">
					<img src="../../assets/layout/images/pages/icon-error.svg" alt="roma" />
					<h1>{$.strings.errorOccured}</h1>
					<p>{$.strings.somethingWentWrong}</p>

					<Button icon="pi pi-home" className="p-button-danger" label={this.setButtonMainText()} onClick={this.onButtonClick}
						style={{ minHeight: '40px', width: '80px' }} />
				</div>
			</div>
		</div>
	}

	setButtonMainText = () => {
		return $.strings.links && $.strings.links.home ? $.strings.links.home : ' '
	}

	onButtonClick = () => {
		if (Util.checkISHR()) {
			window.location = "/HandleRequests";
		}
		else {
			window.location = "/";
		}
	}
}