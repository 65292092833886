import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import ScrollTop from '../ScrollButton';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import Checkbox from '../../Components/Checkbox';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import ClockTransEdit from "../ClockTrans/ClockTransEdit";
import { Accordion, AccordionTab } from 'primereact/accordion';
import Messages from "../../Components/Messages";
import Print from "../../Print";

const $ = window.$;

export default class InOutTotalReport extends React.Component {
    constructor(props) {
        super(props);
        this.transTypeList = [
            { id: 1, name: $.strings.inOutTotalReport.allTrans },
            { id: 0, name: $.strings.inOutTotalReport.firstInLastOut }
        ]
        this.state = {
            isDesktop: window.innerWidth > 1250,
            transTypeList: this.transTypeList,
            fromCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            toCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            transType: this.transTypeList[0],
            fromDate: new Date(),
            toDate: new Date(),
            showDepartmentColumn: false,
            noOutOnly: false,
            noInOnly: false,
            lateOnly: false,
            outBeforePeriodEnd: false,
            editedTransOnly: false,
            filterShown: true,
            data: [],

        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(707)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(651)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();

        let shiftsPromise = this.getShifts();
        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();

        let shiftsList = await shiftsPromise;
        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        this.setState({
            shiftsList: shiftsList, branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            shift: shiftsList && shiftsList.length > 0 ? shiftsList[0] : undefined,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.inOutTotalReport.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.inOutTotalReport.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.inOutTotalReport.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.inOutTotalReport.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.inOutTotalReport.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Dropdown
                                id="transType"
                                innerRef={e => this.drpTransType = e}
                                value={this.state.transType}
                                options={this.state.transTypeList}
                                caption={$.strings.inOutTotalReport.transType}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showDepartmentColumn" caption={$.strings.inOutTotalReport.showDepartmentColumn}
                                onChange={e => this.setState({ showDepartmentColumn: e.checked })} checked={this.state.showDepartmentColumn} />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="noOutOnly" caption={$.strings.inOutTotalReport.noOutOnly}
                                onChange={e => this.setState({ noOutOnly: e.checked })} checked={this.state.noOutOnly} />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="noInOnly" caption={$.strings.inOutTotalReport.noInOnly}
                                onChange={e => this.setState({ noInOnly: e.checked })} checked={this.state.noInOnly} />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Checkbox id="lateOnly" caption={$.strings.inOutTotalReport.lateOnly}
                                onChange={e => this.setState({ lateOnly: e.checked })} checked={this.state.lateOnly} />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Checkbox id="outBeforePeriodEnd" caption={$.strings.inOutTotalReport.outBeforePeriodEnd}
                                onChange={e => this.setState({ outBeforePeriodEnd: e.checked })} checked={this.state.outBeforePeriodEnd} />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                            <Checkbox id="editedTransOnly" caption={$.strings.inOutTotalReport.editedTransOnly}
                                onChange={e => this.setState({ editedTransOnly: e.checked })} checked={this.state.editedTransOnly} />
                        </div>
                    </div>
                    {
                        Util.checkISHR() &&
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="shift"
                                    innerRef={e => this.drpShifts = e}
                                    value={this.state.shift}
                                    options={this.state.shiftsList}
                                    caption={$.strings.inOutTotalReport.shift}
                                    onChange={this.handleUserInput}
                                    optionLabel="ShiftName"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="section"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.inOutTotalReport.section}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="branch"
                                    value={this.state.branch}
                                    options={this.state.branchesList}
                                    caption={$.strings.inOutTotalReport.branch}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="department"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.department}
                                    options={this.state.departmentsList}
                                    caption={$.strings.totalInOutTransReport.department}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                        </div>
                    }
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                        <DataGrid
                            style={gridStyle}
                            ref={e => this.gridData = e}
                            exportFileName={$.strings.inOutTotalReport.title}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.getScheme()}
                            dataSource={this.state.data}
                        />
                    </div>
                </div>
            </Card>

            <ClockTransEdit ref={e => this.dlgClockTransEdit = e} onSuccess={this.onClockTransEditSuccess} />
            <ScrollTop />
        </div>
    }

    onClockTransEditSuccess = async () => {
        await this.getData()
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }

    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            sort: '0',
            chkWhichNotSignIn: this.state.noInOnly,
            chkWhichNotExit: this.state.noOutOnly,
            chkWhichLateOnly: this.state.lateOnly,
            chkGoBeforeEndTime: this.state.outBeforePeriodEnd,
            empCode: localStorage.getItem("useridHR") + "",
            chkEditedTransOnly: this.state.editedTransOnly,
            userId: localStorage.getItem("useridHR") + "",
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            empCodeFrom: this.state.fromCode && this.state.fromCode.length > 0 ? this.state.fromCode : '00000',
            empCodeTo: this.state.toCode && this.state.toCode.length > 0 ? this.state.toCode : 'ZZZZZ',
            sectionValue: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : 0,
            selectedBranchValue: this.state.branch && this.state.branch.Pos >= 0 ? this.state.branch.Pos : "-1",
            selectedDepartment: "-1",
            DDL_shift: this.state.shift.Code,
            allTrans: this.state.transType.id
        }

        let res = await http.getJsonAxios(http.actions.AttReports.urlGetEntryAndExitReport, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.forEach(element => {
                    element.Date = new Date(element.Date);
                });
            }
        }

        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.inOutTotalReport.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";

            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.inOutTotalReport.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.inOutTotalReport.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.inOutTotalReport.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.inOutTotalReport.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter);

            if (this.printFilters.transType) {
                let name = this.printFilters.transType.name
                listOfFilters.push($.strings.inOutTotalReport.transType + ": " + name);
            }

            if (this.printFilters.noOutOnly)
                listOfFilters.push($.strings.inOutTotalReport.noOutOnly + ": " + (this.printFilters.noOutOnly ? $.strings.yes : $.strings.no))
            if (this.printFilters.noInOnly)
                listOfFilters.push($.strings.inOutTotalReport.noInOnly + ": " + (this.printFilters.noInOnly ? $.strings.yes : $.strings.no))
            if (this.printFilters.lateOnly)
                listOfFilters.push($.strings.inOutTotalReport.lateOnly + ": " + (this.printFilters.lateOnly ? $.strings.yes : $.strings.no))
            if (this.printFilters.outBeforePeriodEnd)
                listOfFilters.push($.strings.inOutTotalReport.outBeforePeriodEnd + ": " + (this.printFilters.outBeforePeriodEnd ? $.strings.yes : $.strings.no))
            if (this.printFilters.editedTransOnly)
                listOfFilters.push($.strings.inOutTotalReport.editedTransOnly + ": " + (this.printFilters.editedTransOnly ? $.strings.yes : $.strings.no))

            if (Util.checkISHR()) {
                if (this.printFilters.shift) {
                    listOfFilters.push($.strings.inOutTotalReport.shift + ": " + this.printFilters.shift.ShiftName);
                }
                if (this.printFilters.section) {
                    listOfFilters.push($.strings.inOutTotalReport.section + ": " + this.printFilters.section.Name);
                }
                if (this.printFilters.branch) {
                    listOfFilters.push($.strings.inOutTotalReport.branch + ": " + this.printFilters.branch.Name);
                }
                if (this.printFilters.department) {
                    listOfFilters.push($.strings.totalInOutTransReport.department + ": " + this.printFilters.department.name);
                }

            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    buttonClick = (e, ctx) => {
        this.dlgClockTransEdit.show(ctx.item.EmpCode, ctx.item.Date, 0);
    }
    getScheme = () => {
        let list = {
            name: "inOutTotalReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                {
                    name: 'rowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1'
                },
                {
                    name: 'btnButtonClick', header: ' ', width: 60, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.view,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.buttonClick(e, ctx)
                },
                {
                    name: 'EmpCode', header: $.strings.inOutTotalReport.empCode, width: 120, columnWidth: '2'

                },
                {
                    name: 'EmpName', header: $.strings.inOutTotalReport.empName, width: "2*", columnWidth: '*', minWidth: 150

                },
                {
                    name: 'SectionName', header: $.strings.inOutTotalReport.section, width: 150, columnWidth: '*'

                },
                {
                    name: 'DepartmentName', header: $.strings.inOutTotalReport.department, width: 150,
                    visible: this.state.showDepartmentColumn, columnWidth: '2'

                },
                {
                    name: 'Date', header: $.strings.inOutTotalReport.date, width: 100, columnWidth: '2.5', dataType: 'Date',
                    format: Util.gridDateFormat()

                },
                {
                    name: 'Day', header: $.strings.inOutTotalReport.day, width: 100, columnWidth: '2'

                },
                {
                    name: 'Time1', header: $.strings.inOutTotalReport.time1, width: 100, columnWidth: '2'

                },
                {
                    name: 'Time2', header: $.strings.inOutTotalReport.time2, width: 100, columnWidth: '2'

                },
                {
                    name: 'Time3', header: $.strings.inOutTotalReport.time3, width: 100,
                    visible: this.state.data.filter(e => e.Time3 && e.Time3.length).length > 0, columnWidth: '2'

                },
                {
                    name: 'Time4', header: $.strings.inOutTotalReport.time4, width: 100,
                    visible: this.state.data.filter(e => e.Time4 && e.Time4.length > 0).length > 0, columnWidth: '2'
                },
                {
                    name: 'Time5', header: $.strings.inOutTotalReport.time5, width: 100,
                    visible: this.state.data.filter(e => e.Time5 && e.Time5.length > 0).length > 0, columnWidth: '2'

                },
                {
                    name: 'Time6', header: $.strings.inOutTotalReport.time6, width: 100,
                    visible: this.state.data.filter(e => e.Time6 && e.Time6.length > 0).length > 0, columnWidth: '2'
                },
                {
                    name: 'Time7', header: $.strings.inOutTotalReport.time7, width: 100,
                    visible: this.state.data.filter(e => e.Time7 && e.Time7.length > 0).length > 0, columnWidth: '2'
                },
                {
                    name: 'Time8', header: $.strings.inOutTotalReport.time8, width: 100,
                    visible: this.state.data.filter(e => e.Time8 && e.Time8.length > 0).length > 0, columnWidth: '2'
                },
                {
                    name: 'Time9', header: $.strings.inOutTotalReport.time9, width: 100,
                    visible: this.state.data.filter(e => e.Time9 && e.Time9.length > 0).length > 0, columnWidth: '2'
                },
                {
                    name: 'Time10', header: $.strings.inOutTotalReport.time10, width: 100,
                    visible: this.state.data.filter(e => e.Time10 && e.Time10.length > 0).length > 0, columnWidth: '2'
                }
            ]
        }
        return list;
    }
}