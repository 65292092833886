import React, { Component } from "react";
import Button from "../../Components/Button";
import Dialog from "../../Components/Dialog";
import Util from "../../Util";
import Input from "../../Components/Input";


const $ = window.$;

export default class AddPerson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsErrors: { name: '', mobile: '', email: '' },
            visibleDialog: false,
            name: undefined,
            mobile: undefined,
            email: undefined
        };
    }

    componentDidMount() {
        let _isDesktop = window.innerWidth > 1250;
        this.setState({ isDesktop: _isDesktop });
    }

    render() {
        const buttonsBar = (
            <div className={`${"p-grid"}`} >
                <div className="p-col-3 p-lg-5 p-sm-5">
                </div>
                <div className="p-col-3 p-lg-1 p-sm-1">
                    <Button tooltip={$.strings.saveAndClose} icon="" className="p-button-success" onClick={e => this.onSaveThenClose(e)}>
                        <i className="pi pi-check" />
                    </Button>
                </div>
                <div className="p-col-3 p-lg-1 p-sm-1">
                    <Button tooltip={$.strings.cancel} icon="" className="p-button-danger" onClick={e => this.onHide(e, true)}>
                        <i className="pi pi-times" />
                    </Button>
                </div>
                <div className="p-col-3 p-lg-5 p-sm-5">
                </div>
            </div >
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div style={{ direction: $.strings.dir }}>
                <Dialog header={$.strings.sendMessages.addPerson}
                    icons={myIcon} visible={this.state.visibleDialog} footer={buttonsBar}
                    style={{ width: this.state.isDesktop ? '25vw' : '80vw', direction: $.strings.dir }} modal
                    onHide={this.onHide}>
                    <Input
                        id="name"
                        innerRef={e => this.txtName = e}
                        value={this.state.name}
                        caption={$.strings.sendMessages.name}
                        onChange={(e) => this.setState({ name: e.target.value })}
                        isRequired={true}
                        formErrors={this.state.fieldsErrors}
                    />
                    <Input
                        id="mobile"
                        value={this.state.mobile}
                        caption={$.strings.sendMessages.mobile}
                        onChange={(e) => this.setState({ mobile: e.target.value })}
                        isRequired={true}
                        formErrors={this.state.fieldsErrors}
                    />
                    <Input
                        id="email"
                        value={this.state.email}
                        caption={$.strings.sendMessages.email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                        isRequired={true}
                        formErrors={this.state.fieldsErrors}
                    />
                </Dialog>
            </div>
        );
    }

    onHide = (e, checkChanging) => {
        this.setState({
            visibleDialog: false,
            name: undefined, mobile: undefined, email: undefined,
            fieldsErrors: { name: '', mobile: '', email: '' }
        })
    }
    onShow = () => {
        this.setState({
            visibleDialog: true, name: undefined, mobile: undefined, email: undefined,
            fieldsErrors: { name: '', mobile: '', email: '' }
        }, () => setTimeout(() => { this.txtName.focus() }, 10))
    }
    validateField = () => {
        let fieldsValidationErrors = this.state.fieldsErrors;

        let _name = true;
        fieldsValidationErrors.name = "";
        if (!this.state.name || this.state.name.length <= 0) {
            fieldsValidationErrors.name = $.strings.requiredFiled;
            _name = false;
        }

        let _mobile = true;
        fieldsValidationErrors.mobile = "";
        if (!this.state.mobile || this.state.mobile.length <= 0) {
            fieldsValidationErrors.mobile = $.strings.requiredFiled;
            _mobile = false;
        }

        let _email = true;
        fieldsValidationErrors.email = "";
        if (!this.state.email || this.state.email.length <= 0) {
            fieldsValidationErrors.email = $.strings.requiredFiled;
            _email = false;
        }

        if (!Util.validateEmail(this.state.email)) {
            fieldsValidationErrors.email = $.strings.sendMessages.emailError;
            _email = false;
        }
        
        this.setState({
            fieldsErrors: fieldsValidationErrors,
            nameValid: _name,
            mobileValid: _mobile,
            emailValid: _email
        }, this.validateForm);

        return _name && _mobile && _email;
    }
    validateForm() {
        let isFormValid = this.state.nameValid && this.state.mobileValid && this.state.emailValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    onSaveThenClose = async (e) => {
        //waiting progress
        this.setState({ loading: true });

        //validate
        if (!this.validateField()) {
            this.setState({ loading: false, showErrors: true });
            return false;
        }

        if (this.props.onAddSuccess) {
            this.props.onAddSuccess(this.state.name, this.state.mobile, this.state.email);
            this.onHide(e, false);
        }
    }
}