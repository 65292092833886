import React from "react";
import styles from './Shifts.module.scss'
import Input from "../../../Components/Input";
import ButtonsBarLookup from '../../../Components/ButtonsBarLookup'
import Messages from '../../../Components/Messages'
import http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import MessageBox from '../../../Components/MessageBox'
import DeleteNoteDialog from '../../../Components/DeleteNoteDialog'
import Util from '../../../Util'
import Button from '../../../Components/Button';
import Dropdown from '../../../Components/Dropdown';
import Card from '../../../Components/Card'
import Calendar from '../../../Components/Calendar'
import SiteMap from '../../../Components/SiteMap';
import ScrollTop from '../../ScrollButton';
import DataGridView from "../../../Components/DataGridView/DataGridView";
import ShiftsSearch from "./ShiftsSearch";
import { Accordion, AccordionTab } from "primereact/accordion";
import PeriodsSearch from "../Periods/PeriodsSearch";
import MultiSelect from "../../../Components/MultiSelect";
import Checkbox from "../../../Components/Checkbox";
import RadioButton from "../../../Components/RadioButton";
import InputTime from "../../../Components/InputTime";

const $ = window.$;

export default class PeriodShiftsDistribute extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.nowDate = new Date();
        this.nowDate.setHours(0, 0, 0);
        this.fromDate = new Date();
        this.toDate = new Date(new Date().getFullYear(), 11, 31);
        this.state = {
            loading: true,
            formErrors: {},
            isDesktop: true,
            currShiftActiveIndex: -1,
            data: [],
            dataEmployees: [],
            section: undefined,
            sectionsList: [],
            department: undefined,
            departmentsList: [],
            branch: undefined,
            branchesList: [],
            objPeriod: undefined,
            objPeriodDis: undefined,
            employees: [],
            employeesList: [],
            shift: undefined,
            shiftsList: [],
            period: this.nowDate,
            holidayChecked: false,
            periodChecked: true,
            insertUpdate: 3,
            fromDate: this.fromDate,
            toDate: this.toDate,

            beforeIn: this.nowDate,
            beforeInChecked: true,
            afterIn: this.nowDate,
            afterInChecked: true,
            beforeOut: this.nowDate,
            beforeOutChecked: true,
            afterOut: this.nowDate,
            afterOutChecked: true,
            saturday: false,
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false
        }
    }


    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(959)) {
                Util.goTo('/Access');
                return;
            }
        }
        else if (Util.checkISManager()) {
            if (!Util.checkRights(958)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
            return;
        }



        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();
        let branchesPromise = this.getBranches();

        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;
        let branchesList = await branchesPromise;

        let employeesListPromise = this.getEmployeesList();
        let shiftsPromise = this.getShifts();

        let employeesList = await employeesListPromise;
        let shiftsList = await shiftsPromise;

        let _isDesktop = window.innerWidth > 1250;
        this.setState({
            sectionsList: sectionsList, section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            departmentsList: departmentsList, department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            branchesList: branchesList, branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            employeesList: employeesList, shiftsList: shiftsList, shift: undefined,
            isDesktop: _isDesktop, loading: false
        });
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    handleMultiSelect = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    validateField() {

        let result = true;
        let fieldValidationErrors = this.state.formErrors;

        if (!this.state.dataEmployees || this.state.dataEmployees.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.noEmployeesSelected);
            return result = false;
        }


        if (!this.state.periodChecked && !this.state.beforeInChecked && !this.state.afterInChecked && !this.state.beforeOutChecked && !this.state.afterOutChecked) {
            Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.selectDistributeOptions);
            return result = false;
        }

        if (!this.state.saturday && !this.state.sunday && !this.state.monday && !this.state.tuesday &&
            !this.state.wednesday && !this.state.thursday && !this.state.friday) {
            Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.selectPeriodDaysError);
            return result = false;
        }

        if (this.state.periodChecked && !this.state.objPeriodDis) {
            Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.periodDisError);
            return result = false;
        }

        if (this.state.insertUpdate === 2 || this.state.insertUpdate === 1) {
            if (!this.state.periodChecked || !this.state.objPeriodDis) {
                Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.periodDisError);
                return result = false;
            }
        }

        fieldValidationErrors.fromDate = "";
        if (!this.state.fromDate || this.state.fromDate.toString().length <= 0) {
            fieldValidationErrors.fromDate = $.strings.requiredFiled;
            return result = false;
        }

        fieldValidationErrors.toDate = "";
        if (!this.state.toDate || this.state.toDate.toString().length <= 0) {
            fieldValidationErrors.toDate = $.strings.requiredFiled;
            return result = false;
        }

        this.setState({ formErrors: fieldValidationErrors });
        return result;
    }


    render() {

        let siteMapItems = [
            { label: $.strings.links.shifts.menu },
            {
                label: <div> {$.strings.periodShiftsDistribute.title} </div>
            }
        ];

        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain">
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-md-12" >
                            <SiteMap model={siteMapItems} home={siteMapHome} />
                            <ButtonsBarLookup onNew={e => this.resetFields()} onSave={e => this.onSave()}
                                onRefresh={async (e) => await this.getEmployeesData()} />
                            <Messages innerRef={(el) => this.messages = el} />
                        </div>
                    </div>
                </Card>

                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnPrevVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onPrevious(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNextVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNext(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnFirstVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onFirst(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnLastVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onLast(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnDeleteVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onDelete(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloneVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onClone(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloseVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.btnClose(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />

                <DeleteNoteDialog ref={ref => this.deleteNoteDlg = ref} visible={this.state.dlgDeleteNoteVisible} deleteErrors={this.state.deleteErrors} appendTo={this.props.parent}
                    onYes={this.btnDeleteYes} onNo={this.DeleteCancel} deleteNote='' />

                <ShiftsSearch ref={e => this.dlgSearchShifts = e} onSuccess={this.onSearchShiftsSuccess} />
                <PeriodsSearch ref={e => this.dlgSearchPeriods = e} onSuccess={this.searchPeriod} />
                <PeriodsSearch ref={e => this.dlgSearchPeriodsDis = e} onSuccess={this.searchPeriodDis} />

                <div className="p-grid">
                    <div className="p-col-12 p-lg-8 p-sm-12">
                        <Accordion multiple activeIndex={[0, 1, 2]}>
                            <AccordionTab header={$.strings.periodShiftsDistribute.employeesFilter}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-2 p-sm-12">
                                        <Dropdown
                                            id="section"
                                            innerRef={e => this.drpSections = e}
                                            value={this.state.section}
                                            options={this.state.sectionsList}
                                            caption={$.strings.periodShiftsDistribute.section}
                                            onChange={this.handleUserInput}
                                            optionLabel="Name"
                                            filter
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-2 p-sm-12">
                                        <Dropdown
                                            id="department"
                                            value={this.state.department}
                                            options={this.state.departmentsList}
                                            caption={$.strings.periodShiftsDistribute.department}
                                            onChange={this.handleUserInput}
                                            optionLabel="name"
                                            filter
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-2 p-sm-12">
                                        <Dropdown
                                            id="branch"
                                            innerRef={e => this.drpSections = e}
                                            value={this.state.branch}
                                            options={this.state.branchesList}
                                            caption={$.strings.periodShiftsDistribute.branch}
                                            onChange={this.handleUserInput}
                                            optionLabel="Name"
                                            filter
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-2 p-sm-12">
                                        <Dropdown
                                            id="shift"
                                            innerRef={e => this.drpSections = e}
                                            value={this.state.shift}
                                            options={this.state.shiftsList}
                                            caption={$.strings.periodShiftsDistribute.shift}
                                            onChange={this.handleUserInput}
                                            optionLabel="ShiftName"
                                            placeholder={$.strings.select}
                                            filter
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <MultiSelect
                                            id="employees"
                                            value={this.state.employees}
                                            options={this.state.employeesList}
                                            caption={$.strings.periodShiftsDistribute.employees}
                                            onChange={this.handleMultiSelect}
                                            optionLabel="name"
                                            display="chip"
                                            maxSelectedLabels="2"
                                            filter
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Input
                                            id="period"
                                            value={
                                                this.state.objPeriod ?
                                                    this.state.objPeriod.code + ' / ' + this.state.objPeriod.fromTime + ' - ' + this.state.objPeriod.toTime
                                                    :
                                                    ''
                                            }
                                            onChange={this.handleUserInput}
                                            caption={$.strings.periodShiftsDistribute.period}
                                            placeholder={$.strings.periodShiftsDistribute.periodHint}
                                            with2buttons={true}
                                            btn1icon="search"
                                            btn1event={this.getPeriod}
                                            btn2icon="clear"
                                            btn2event={this.clearPeriod}
                                            disabled={true}
                                        />
                                    </div>
                                    {/* <div className="p-col-6 p-lg-2 p-sm-6" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                        <Button label={$.strings.periodShiftsDistribute.getEmployees} onClick={async e => await this.getEmployeesData()} >
                                            &nbsp;&nbsp;
                                            <i className="pi pi-sync" />
                                        </Button>
                                    </div> */}
                                </div>
                            </AccordionTab>
                            <AccordionTab header={$.strings.periodShiftsDistribute.distributeOptions}>
                                <div className="p-grid">
                                    <div className="p-col-6 p-lg-2 p-sm-6">
                                        <Calendar
                                            id="fromDate"
                                            innerRef={(el) => this.dtpFromDate = el}
                                            value={this.state.fromDate}
                                            caption={$.strings.periodShiftsDistribute.fromDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="p-col-6 p-lg-2 p-sm-6">
                                        <Calendar
                                            id="toDate"
                                            innerRef={(el) => this.dtpToDate = el}
                                            value={this.state.toDate}
                                            caption={$.strings.periodShiftsDistribute.toDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'nowrap' }}>

                                    <Checkbox id="saturday" caption={$.strings.weekDays.saturday} checked={this.state.saturday}
                                        onChange={e => this.setState({ saturday: e.checked })} />

                                    <Checkbox id="sunday" caption={$.strings.weekDays.sunday} checked={this.state.sunday}
                                        onChange={e => this.setState({ sunday: e.checked })} />

                                    <Checkbox id="monday" caption={$.strings.weekDays.monday} checked={this.state.monday}
                                        onChange={e => this.setState({ monday: e.checked })} />

                                    <Checkbox id="tuesday" caption={$.strings.weekDays.tuesday} checked={this.state.tuesday}
                                        onChange={e => this.setState({ tuesday: e.checked })} />

                                    <Checkbox id="wednesday" caption={$.strings.weekDays.wednesday} checked={this.state.wednesday}
                                        onChange={e => this.setState({ wednesday: e.checked })} />

                                    <Checkbox id="thursday" caption={$.strings.weekDays.thursday} checked={this.state.thursday}
                                        onChange={e => this.setState({ thursday: e.checked })} />

                                    <Checkbox id="friday" caption={$.strings.weekDays.friday} checked={this.state.friday}
                                        onChange={e => this.setState({ friday: e.checked })} />
                                </div>
                            </AccordionTab>
                            <AccordionTab header={$.strings.periodShiftsDistribute.requiredPeriod}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                        <Checkbox id="holidayChecked" caption={$.strings.periodShiftsDistribute.holiday}
                                            onChange={e => this.setState({
                                                holidayChecked: e.checked, periodChecked: e.checked ? false : true,
                                                objPeriodDis: e.checked ? undefined : this.state.objPeriodDis
                                            })}
                                            checked={this.state.holidayChecked} />
                                    </div>
                                    <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                        <Checkbox id="periodChecked" caption={$.strings.periodShiftsDistribute.period}
                                            onChange={e => this.setState({ periodChecked: e.checked, holidayChecked: e.checked ? false : true })}
                                            checked={this.state.periodChecked} />
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Input
                                            id="period"
                                            value={
                                                this.state.objPeriodDis ?
                                                    this.state.objPeriodDis.code + ' / ' + this.state.objPeriodDis.fromTime + ' - ' + this.state.objPeriodDis.toTime
                                                    :
                                                    ''
                                            }
                                            onChange={this.handleUserInput}
                                            placeholder={$.strings.periodShiftsDistribute.periodHint}
                                            with2buttons={true}
                                            btn1icon="search"
                                            btn1event={this.getPeriodDis}
                                            btn2icon="clear"
                                            btn2event={this.clearPeriodDis}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-6 p-lg-2 p-sm-12">
                                        <div className={styles.timesRow}>
                                            <InputTime id="beforeIn" value={this.state.beforeIn} className={styles.time}
                                                valueChanged={e => this.setState({ beforeIn: e.value })} caption={$.strings.periodShiftsDistribute.beforeIn} />
                                            &nbsp;
                                            <div style={{ position: "relative", display: "inline-block" }}>
                                                <Checkbox id="beforeInChecked" className={styles.inputContainerStyle}
                                                    onChange={e => this.setState({ beforeInChecked: e.checked })} checked={this.state.beforeInChecked} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-6 p-lg-2 p-sm-12">
                                        <div className={styles.timesRow}>
                                            <InputTime id="afterIn" value={this.state.afterIn} className={styles.time}
                                                valueChanged={e => this.setState({ afterIn: e.value })} caption={$.strings.periodShiftsDistribute.afterIn} />
                                            &nbsp;
                                            <div style={{ position: "relative", display: "inline-block" }}>
                                                <Checkbox id="afterInChecked" className={styles.inputContainerStyle}
                                                    onChange={e => this.setState({ afterInChecked: e.checked })} checked={this.state.afterInChecked} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-6 p-lg-2 p-sm-12">
                                        <div className={styles.timesRow}>
                                            <InputTime id="beforeOut" value={this.state.beforeOut} className={styles.time}
                                                valueChanged={e => this.setState({ beforeOut: e.value })} caption={$.strings.periodShiftsDistribute.beforeOut} />
                                            &nbsp;
                                            <div style={{ position: "relative", display: "inline-block" }}>
                                                <Checkbox id="beforeOutChecked" className={styles.inputContainerStyle}
                                                    onChange={e => this.setState({ beforeOutChecked: e.checked })} checked={this.state.beforeOutChecked} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-6 p-lg-2 p-sm-12">
                                        <div className={styles.timesRow}>
                                            <InputTime id="afterOut" value={this.state.afterOut} className={styles.time}
                                                valueChanged={e => this.setState({ afterOut: e.value })} caption={$.strings.periodShiftsDistribute.afterOut} />
                                            &nbsp;
                                            <div style={{ position: "relative", display: "inline-block" }}>
                                                <Checkbox id="afterOutChecked" className={styles.inputContainerStyle}
                                                    onChange={e => this.setState({ afterOutChecked: e.checked })} checked={this.state.afterOutChecked} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-4 p-lg-2 p-sm-4">
                                        <RadioButton
                                            inputId="insertUpdate"
                                            value={$.strings.periodShiftsDistribute.insertUpdate}
                                            name="saveType" onChange={(e) => this.setState({ insertUpdate: 3 })}
                                            checked={this.state.insertUpdate === 3}
                                        />
                                    </div>
                                    <div className="p-col-4 p-lg-2 p-sm-4">
                                        <RadioButton
                                            inputId="insert"
                                            value={$.strings.periodShiftsDistribute.insert}
                                            name="saveType" onChange={(e) => this.setState({ insertUpdate: 1 })}
                                            checked={this.state.insertUpdate === 1}
                                        />
                                    </div>
                                    <div className="p-col-4 p-lg-2 p-sm-4">
                                        <RadioButton
                                            inputId="update"
                                            value={$.strings.periodShiftsDistribute.update}
                                            name="saveType" onChange={(e) => this.setState({ insertUpdate: 2 })}
                                            checked={this.state.insertUpdate === 2}
                                        />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div className="p-col-12 p-lg-4 p-sm-12">
                        <DataGridView
                            // style={{ minHeight: this.state.isDesktop ? '40vh' : '100vh', height: this.state.isDesktop ? '40vh' : '100vh' }}
                            ref={e => this.gridEmployees = e}
                            isReport={true}
                            showContextMenu={false}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.getScheme()}
                            exportFileName={$.strings.periodShiftsDistribute.title}
                            dataSource={this.state.dataEmployees}
                            defaultRowHeight={45}
                        />
                    </div>
                </div>
                <Accordion activeIndex={this.state.currShiftActiveIndex}>
                    <AccordionTab header={$.strings.periodShiftsDistribute.employeeShift}>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <DataGridView
                                    // style={{ minHeight: this.state.isDesktop ? '40vh' : '100vh', height: this.state.isDesktop ? '40vh' : '100vh' }}
                                    ref={e => this.gridData = e}
                                    isReport={true}
                                    showContextMenu={false}
                                    theme="default-light"
                                    idProperty="serial"
                                    scheme={this.getShiftScheme()}
                                    exportFileName={$.strings.periodShiftsDistribute.title}
                                    dataSource={this.state.data}
                                    defaultRowHeight={45}
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <ScrollTop />
            </div >
        );
    }


    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true });
        let empCodeList = [];
        if (this.state.dataEmployees) {
            for (let index = 0; index < this.state.dataEmployees.length; index++) {
                empCodeList.push(this.state.dataEmployees[index].code);
            }
        }
        let listOfDays = [];
        if (this.state.saturday)
            listOfDays.push(7);
        if (this.state.sunday)
            listOfDays.push(1);
        if (this.state.monday)
            listOfDays.push(2);
        if (this.state.tuesday)
            listOfDays.push(3);
        if (this.state.wednesday)
            listOfDays.push(4);
        if (this.state.thursday)
            listOfDays.push(5);
        if (this.state.friday)
            listOfDays.push(6);


        let obj = {
            actionType: this.state.insertUpdate,
            listOfDays: listOfDays,
            periodIdStr: this.state.objPeriodDis ? parseInt(this.state.objPeriodDis.code) + 1 : '',
            periodCheck: this.state.periodChecked,
            beforeCheckIn: this.state.beforeInChecked,
            beforeCheckInTime: this.state.beforeIn,
            afterCheckIn: this.state.afterInChecked,
            afterCheckInTime: this.state.afterIn,
            beforeCheckOut: this.state.beforeOutChecked,
            beforeCheckOutTime: this.state.beforeOut,
            afterCheckOut: this.state.afterOutChecked,
            afterCheckOutTime: this.state.afterOut,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            empCodeList: empCodeList,
            holidayChecked: this.state.holidayChecked
        }
        let res = await http.postMultipartAxios(http.actions.Shifts.urlPostSaveShiftDistributeDM, obj, null, null, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.setState({ loading: false })
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data && res.data.errorText)
                    msg = res.data.errorText;
                Util.showErrorMsg(this.messages, msg);
                this.setState({ loading: false })
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.errorText)
                msg = res.data.errorText;
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false })
        }
        this.setState({ loading: false })
    }


    resetFields = () => {
        this.setState({
            data: [], formErrors: {},
            currShiftActiveIndex: -1,
            disableDistributePeriod: true,
            dataEmployees: [],
            section: this.state.sectionsList && this.state.sectionsList.length > 0 ? this.state.sectionsList[0] : undefined,
            department: this.state.departmentsList && this.state.departmentsList.length > 0 ? this.state.departmentsList[0] : undefined,
            branch: this.state.branchesList && this.state.branchesList.length > 0 ? this.state.branchesList[0] : undefined,
            objPeriod: undefined,
            objPeriodDis: undefined,
            employees: [],
            //employeesList: [],
            shift: this.state.shiftsList && this.state.shiftsList.length > 0 ? this.state.shiftsList[0] : undefined,
            period: this.nowDate,
            holidayChecked: false,
            periodChecked: true,
            insertUpdate: 3,
            fromDate: this.fromDate,
            toDate: this.toDate,
            beforeIn: this.nowDate,
            beforeInChecked: true,
            afterIn: this.nowDate,
            afterInChecked: true,
            beforeOut: this.nowDate,
            beforeOutChecked: true,
            afterOut: this.nowDate,
            afterOutChecked: true,
            saturday: false,
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false
        })
    }

    btnViewEmployeeShift = async (e, ctx) => {
        if (!ctx.item || !ctx.item.code || ctx.item.code.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.employeeError)
            return;
        }
        if (!this.state.fromDate || !this.state.toDate) {
            Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.dateError)
            return;
        }
        let fromDate = new Date(this.state.fromDate);
        let toDate = new Date(this.state.toDate);
        if (fromDate > toDate) {
            Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.dateCompareError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = [];
        let obj = {
            userId: localStorage.getItem('useridHR'),
            code: ctx.item.code,
            fromDate: fromDate,
            toDate: toDate,
            empShift: true,
            isDirector: Util.checkISManager()
        }
        let msg = $.strings.operationFailed;
        let res = await http.getJsonAxios(http.actions.Shifts.urlGetShift, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.list;
                if (dataTemp) {
                    dataTemp.forEach(element => {
                        element.date = this.state.isDesktop ? new Date(element.date) : new Date(element.date);
                    });
                }
            }
            else {
                if (res.data && res.data.errorText)
                    msg = res.data.errorText;
                Util.showErrorMsg(this.messages, msg);
            }
        }
        else {
            if (res.data && res.data.errorText)
                msg = res.data.errorText;
            Util.showErrorMsg(this.messages, msg);
        }
        this.setState({ data: dataTemp, currShiftActiveIndex: 0, loading: false })
    }

    getScheme = () => {
        return {
            name: "periodShiftsDestributeScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [
                {
                    name: 'btn', header: ' ', width: 60, buttonBody: 'button', align: 'center', title: $.strings.periodShiftsDistribute.viewShift,
                    iconType: 'search', className: 'info', onClick: (e, ctx) => this.btnViewEmployeeShift(e, ctx)
                },
                { name: 'code', header: $.strings.periodShiftsDistribute.empCode, width: 100, dataType: 'Number', aggregate: 'Cnt', sortable: false },
                { name: 'name', header: $.strings.periodShiftsDistribute.empName, width: '*', sortable: false },

            ]
        }
    }
    getShiftScheme = () => {
        return {
            name: "periodShiftsScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 1,
            columns: [
                { name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', sortable: false },
                {
                    name: 'date', header: $.strings.periodShiftsDistribute.date, dataType: 'Date', format: Util.gridDateFormat(),
                    width: this.state.isDesktop ? 150 : '*', sortable: false
                },
                { name: 'day', header: $.strings.periodShiftsDistribute.day, width: 150, sortable: false },
                {
                    name: 'period', header: $.strings.periodShiftsDistribute.period, width: 150, visible: false,
                    visibleInColumnChooser: false, sortable: false
                },
                { name: 'fromTime', header: $.strings.periodShiftsDistribute.from, width: 150, sortable: false },
                { name: 'toTime', header: $.strings.periodShiftsDistribute.to, width: 150, sortable: false },
                { name: 'ablimit1', header: $.strings.periodShiftsDistribute.ablimit1, width: 150, sortable: false },
                { name: 'ablimit2', header: $.strings.periodShiftsDistribute.ablimit2, width: 150, sortable: false },
                { name: 'ablimit3', header: $.strings.periodShiftsDistribute.ablimit3, width: 150, sortable: false },
                { name: 'ablimit4', header: $.strings.periodShiftsDistribute.ablimit4, width: 150, sortable: false }
            ]
        }
    }

    ////////////////////////
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getPeriod = async () => {
        await this.dlgSearchPeriods.show();
    }
    searchPeriod = (obj) => {
        if (obj) {
            this.setState({ objPeriod: obj })
        }
    }
    clearPeriod = () => {
        this.setState({ objPeriod: undefined })
    }
    getPeriodDis = async () => {
        await this.dlgSearchPeriodsDis.show();
    }
    searchPeriodDis = (obj) => {
        if (obj) {
            this.setState({ objPeriodDis: obj })
        }
    }
    clearPeriodDis = () => {
        this.setState({ objPeriodDis: undefined })
    }
    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.splice(0, 1);
            }
        }
        return dataTemp;
    }
    getEmployeesList = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: !this.state.empCodeOrName || this.state.empCodeOrName.length <= 0 ? '' : this.state.empCodeOrName,
            isHr: Util.checkISHR(),
            getEmpStatus: this.state.getEmpStatus ? this.state.getEmpStatus : false,
            section: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            empStatus: this.state.empStatus && this.state.empStatus.id >= 0 ? this.state.empStatus.id : -1,
        }

        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;
            // if (dataTemp) {
            //     dataTemp.splice(0, 0, { code: "-1", name: $.strings.all, ename: $.strings.all })
            // }
        }
        return dataTemp
    }
    getEmployeesData = async () => {
        if (!this.state.fromDate || !this.state.toDate) {
            Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.dateError)
            return;
        }
        let fromDate = new Date(this.state.fromDate);
        let toDate = new Date(this.state.toDate);
        if (fromDate > toDate) {
            Util.showErrorMsg(this.messages, $.strings.periodShiftsDistribute.dateCompareError)
            return;
        }
        this.setState({ loading: false })
        let dataTemp = [], empCodesList = [];
        if (this.state.employees) {
            let allSelected = this.state.employees.find(emp => emp.code + "" === "-1");
            if (allSelected) {
                empCodesList.push("-1");
            }
            else {
                for (let index = 0; index < this.state.employees.length; index++) {
                    empCodesList.push(this.state.employees[index].code);
                }
            }
        }

        let obj = {
            empCodesList: empCodesList,
            isHr: Util.checkISHR(),
            userId: localStorage.getItem('useridHR'),
            isDirector: Util.checkISManager(),
            sectionId: this.state.section ? this.state.section.ID : -1,
            departmentId: this.state.department ? this.state.department.pos : -1,
            branchId: this.state.branch ? this.state.branch.Pos : -1,
            shiftId: this.state.shift ? Util.getGeneralShiftPos(this.state.shift.Code) : -1,
            periodId: this.state.objPeriod && this.state.objPeriod.pos ? this.state.objPeriod.pos : -1,
        }

        let msg = $.strings.operationFailed;
        let res = await http.postMultipartAxios(http.actions.Shifts.urlGetEmployeesDistributeDM, obj, null, null, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.list;
            }
            else {
                if (res.data && res.data.errorText)
                    msg = res.data.errorText;
                Util.showErrorMsg(this.messages, msg);
            }
        }
        this.setState({ dataEmployees: dataTemp, loading: false })
    }
}