import React from "react";
import Button from '../../Components/Button'
import http from "../../Api/http";
import Dialog from '../../Components/Dialog';
import DataTable from '../../Components/Datatable';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class VacEmpDetails extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 1250,
            isVisible: false,
            data: [],
            dataObject: {

            }
        }
        this.scheme = this.getScheme()
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;
        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <Dialog header={$.strings.vacations.vacEmpDetailsTitle} footer={footer} icons={myIcon} visible={this.state.isVisible}
                style={{ width: this.state.isDesktop ? '50vw' : '80vw', direction: $.strings.dir }}
                modal onHide={this.onHide}>
                <div ref="divMain" >
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataTable
                                isReport={false}
                                ref={ref => (this.tblData = ref)}
                                scrollHeight="45vh"
                                style={{ direction: $.strings.dir }}
                                scheme={this.scheme}
                                value={this.state.data}
                                fullData={this.state.dataObject}
                                loading={this.state.loading}
                                showContextMenu={false}
                                responsive={true}
                            />
                        </div>
                    </div>
                </div >
            </Dialog >
        );
    }
    show = async (empCode, fromDate, toDate) => {
        this.setState({ isVisible: true, data: [] }, async () => { await this.getData(empCode, fromDate, toDate) })
    }

    getData = async (empCode, fromDate, toDate) => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: empCode,
            fromDate: fromDate,
            toDate: toDate
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetVacationEmpMaxDetails, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.dataObject;
            }
        }
        this.setState({ data: dataTemp, loading: false })
    }

    onHide = () => {
        this.setState({ isVisible: false })
    }

    mainTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData[column.field]}</span>
        </>
    }

    getScheme = () => {
        return {
            name: "vacEmpDetailsScheme_Table",
            filter: false,
            sigma: false,
            columns: [
                {
                    position: 0,
                    field: "rowNumber",
                    header: "##",
                    filterable: false,
                    width: "45px",
                    sortable: false,
                    body: this.mainTemplate,
                },
                {
                    position: 0,
                    field: "type",
                    header: $.strings.vacations.type,
                    width: "100px",
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "empCode",
                    header: $.strings.vacations.empCode,
                    width: "100px",
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "empName",
                    width: '150px',
                    header: $.strings.vacations.empName,
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "fromDate",
                    width: '120px',
                    header: $.strings.vacations.fromDate,
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "toDate",
                    width: '120px',
                    header: $.strings.vacations.toDate,
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "days",
                    width: '90px',
                    header: $.strings.vacations.daysNo,
                    body: this.mainTemplate
                },
                {
                    position: 0,
                    field: "reqStatus",
                    width: '100px',
                    header: $.strings.vacations.status,
                    body: this.mainTemplate
                }
            ]
        };
    };
}