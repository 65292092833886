import React from "react";
import http from '../../Api/http';
import ProgressSpinner from '../../Components/ProgressSpinner';
import Util from "../../Util";
import Input from '../../Components/Input';
import Dropdown from '../../Components/Dropdown';
import Calendar from '../../Components/Calendar';
import InputTextArea from '../../Components/InputTextArea';
import Card from "../../Components/Card";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup';
import Messages from '../../Components/Messages';
import InputTime from "../../Components/InputTime/InputTime";

const $ = window.$

export default class FP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            success: false,
            resultText: '',
            isDesktop: true,
            formErrors: { empCode: '', empName: '', transType1: '', transType2: '', transTime1: '', transTime2: '', notes: '' },
            language: localStorage.getItem("HRlang") || "ar",
            transTypesList: [],
            dataObject: {
                id: 0,
                notID: '',
                success: false,
                saveSuccess: false,
                resultText: '',
                empCode: undefined,
                empName: undefined,
                transType1: undefined,
                transType2: undefined,
                transTime1: undefined,
                transTime2: undefined,
                notes: ''
            }
        };
    }

    componentDidMount = async () => {
        await this.loadLanguage();
        let _isDesktop = this.updatePredicate();

        if (!this.props.match.params.NotID || !this.props.match.params.Date || !this.props.match.params.EmpCode) {
            Util.goTo("../../Access");
        }
        this.setState({ transTypesList: Util.getInOutTypes(), isDesktop: _isDesktop, loading: false })
        let resultPromise = this.getFPDetails()
        let temp = await resultPromise
        this.setState({ data: temp, isDesktop: _isDesktop, loading: false })
    }

    loadLanguage = async lang => {
        $.strings = await import(`../../Languages/${this.state.language}.json`);
        $.pages = await import('../../pages.json')
        $.language = lang
    };

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    render() {
        let color = this.state.success ? 'green' : 'red';
        return (
            <div ref="divMain" style={{ minHeight: '70vh', margin: '5%' }} dir={$.strings.dir}>
                {this.state.dataObject.success && !this.state.dataObject.saveSuccess &&
                    < Card title={$.strings.fingerPrint ? $.strings.fingerPrint.attDetails : 'ادخال تفاصيل الدوام'}>
                        <ProgressSpinner loading={this.state.loading} />
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-md-12" >
                                <ButtonsBarLookup onSave={this.onSave} />
                            </div>
                        </div>
                        <div className="p-grid" style={{ width: '100%' }}>
                            <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                                <Messages innerRef={(el) => this.messages = el} />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtEmpCode = el}
                                    type="text"
                                    id="empCode"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.empCode}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.empCode : 'رقم الموظف'}
                                    readOnly={true}
                                    disabled
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtEmpName = el}
                                    type="text"
                                    id="empName"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.empName}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.empName : 'اسم الموظف'}
                                    readOnly={true}
                                    disabled
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    type="text"
                                    id="transDate"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.transDate}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.transDate : 'التاريخ'}
                                    readOnly={true}
                                    disabled
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-6 p-lg-6 p-sm-12">
                                <Dropdown
                                    id="transType1"
                                    innerRef={e => this.drpTransType1 = e}
                                    value={this.state.dataObject.transType1 || ''}
                                    options={this.state.transTypesList}
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.transType : 'نوع الحركة'}
                                    onChange={this.handleUserInput}
                                    formErrors={this.state.formErrors}
                                    optionLabel="name"
                                    placeholder={$.strings.fingerPrint ? $.strings.fingerPrint.selectTransType : 'اختر نوع الحركة'}
                                    isRequired={true}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div >
                            <div className="p-col-6 p-lg-6 p-sm-12">
                                {/* <Calendar
                                    id="transTime1"
                                    ref={e => this.dtpTransTime = e}
                                    value={this.state.dataObject.transTime1}
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.transTime : 'وقت الحركة'}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    timeOnly
                                    hourFormat="24"
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                /> */}
                                <InputTime
                                    id="transTime1"
                                    ref={e => this.dtpTransTime = e}
                                    value={this.state.dataObject.transTime1}
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.transTime : 'وقت الحركة'}
                                    valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, transTime1: e.value } })}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                        </div >
                        <div className="p-grid">
                            <div className="p-col-6 p-lg-6 p-sm-12">
                                <Dropdown
                                    id="transType2"
                                    innerRef={e => this.drpTransType1 = e}
                                    value={this.state.dataObject.transType2 || ''}
                                    options={this.state.transTypesList}
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.transType : 'نوع الحركة'}
                                    onChange={this.handleUserInput}
                                    formErrors={this.state.formErrors}
                                    optionLabel="name"
                                    placeholder={$.strings.fingerPrint ? $.strings.fingerPrint.selectTransType : 'اختر نوع الحركة'}
                                    isRequired={false}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div >
                            <div className="p-col-6 p-lg-6 p-sm-12">
                                {/* <Calendar
                                    id="transTime2"
                                    ref={e => this.dtpTransTime = e}
                                    value={this.state.dataObject.transTime2}
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.transTime : 'وقت الحركة'}
                                    onChange={this.handleUserInput}
                                    readOnlyInput={true}
                                    timeOnly
                                    hourFormat="24"
                                    isRequired={false}
                                    formErrors={this.state.formErrors}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                /> */}
                                <InputTime
                                    id="transTime2"
                                    ref={e => this.dtpTransTime2 = e}
                                    value={this.state.dataObject.transTime2}
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.transTime : 'وقت الحركة'}
                                    valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, transTime2: e.value } })}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                        </div >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <InputTextArea
                                    innerRef={ref => this.txtNotes = ref}
                                    type="text"
                                    id="notes"
                                    maxLength="100"
                                    value={this.state.dataObject.notes}
                                    caption={$.strings.fingerPrint ? $.strings.fingerPrint.notes : 'ملاحظات'}
                                    onChange={this.handleUserInput}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    style={{ backgroundColor: 'white' }}
                                    disabled={this.state.dataObject.id > 0 ? true : false}
                                />
                            </div>
                        </div>
                    </Card >
                }
                {
                    (!this.state.dataObject.success || this.state.dataObject.saveSuccess) &&
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12" style={{ textAlign: 'center' }}>
                            <span style={{ color: color, fontSize: '18px', fontWeight: 'bold' }}>{this.state.resultText}</span>
                        </div>
                    </div>
                }
            </div >
        );
    }

    getFPDetails = async () => {
        this.setState({ loading: true })
        let obj = {
            NotID: this.props.match.params.NotID,
            Date: this.props.match.params.Date,
            EmpCode: this.props.match.params.EmpCode
        }
        let res = await http.getJsonAxios(http.actions.FingerPrint.urlGetFPInfoFromEmail, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                this.setState({
                    dataObject: {
                        success: true, notID: res.data.notID, empCode: res.data.empCode, empName: res.data.empName,
                        transDate: res.data.transDate
                    }, loading: false
                })
            }
            else {
                let resultText = $.strings.operationFailed;
                if (res.data.errorText) {
                    resultText = res.data.errorText;
                }
                this.setState({ loading: false, success: false, resultText: resultText });
            }
        }
        else {
            this.setState({ loading: false, success: false, resultText: $.strings.operationFailed })
        }
    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;
        let notId = this.state.dataObject.notID;
        if (!notId || notId === "" || notId === "0") {
            Util.showErrorMsg(this.messages, $.strings.fingerPrint.fingerPrintNotError)
            return false;
        }
        let _empCode = this.state.dataObject.empCode
        fieldValidationErrors.empCode = _empCode ? '' : $.strings.requiredFiled;
        if (fieldValidationErrors.empCode && fieldValidationErrors.empCode.length > 0) {
            return false;
        }

        let _empName = this.state.dataObject.empName;
        fieldValidationErrors.empName = _empName ? '' : $.strings.requiredFiled;
        if (fieldValidationErrors.empName && fieldValidationErrors.empName.length > 0) {
            return false;
        }

        if (this.state.dataObject.transType1 === undefined && this.state.dataObject.transType2 === undefined) {
            this.drpTransType1.focusInput.focus()
            Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTypeError)
            return false;
        }
        else if (this.state.dataObject.transType1) {
            if (this.state.dataObject.transType1.id <= 0) {
                Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTypeError)
                return false;
            }
            if (this.state.dataObject.transTime1 === undefined) {
                Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTimeError)
                return false;
            }
        }
        else if (this.state.dataObject.transType2) {
            if (this.state.dataObject.transType2.id <= 0) {
                Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTypeError)
                return false;
            }
            if (this.state.dataObject.transTime2 === undefined) {
                Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTimeError)
                return false;
            }
        }

        if (this.state.dataObject.notes === undefined || this.state.dataObject.notes.trim() === "") {
            Util.showErrorMsg(this.messages, $.strings.fingerPrint.notesError)
            this.txtNotes.focus()
            return false;
        }

        return true
    }
    validateForm() {
        let isFormValid = this.state.empCodeValid && this.state.empNameValid && this.state.transType1Valid
            && this.state.transType2Valid && this.state.transTime1Valid && this.state.transTime2Valid && this.state.notesValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true })
        let files = [] // ملفات المرفقات
        let file = undefined

        let obj = {
            notID: this.state.dataObject.notID,
            serial: !this.state.dataObject.id ? 0 : this.state.dataObject.id,
            empCode: this.state.dataObject.empCode,
            empName: this.state.dataObject.empName,
            insertDate: new Date(),
            transDate: this.state.dataObject.transDate,
            transType: this.state.dataObject.transType1 ? this.state.dataObject.transType1.id : -1,
            transType2: this.state.dataObject.transType2 ? this.state.dataObject.transType2.id : -1,
            transTime: this.state.dataObject.transTime1 ? this.state.dataObject.transTime1 : '00:00:00',
            transTime2: this.state.dataObject.transTime2 ? this.state.dataObject.transTime2 : '00:00:00',
            notes: this.state.dataObject.notes
        }

        let res = await http.postMultipartAxios(http.actions.FingerPrint.urlPostSaveFingerPrintFromEmail, obj, file, files, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                Util.showSuccessMsg(this.messages, $.strings.operationDone);
                this.setState({ dataObject: { ...this.state.dataObject, id: res.id, success: true, saveSuccess: true }, success: true, resultText: $.strings.operationDone, loading: false });
                return true;
            }
        }
        if (res.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return false
        }
    }
}
