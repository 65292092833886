import React from "react";
import styles from './FingerPrint.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import Button from '../../Components/Button';
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import InputTextArea from '../../Components/InputTextArea';
import Calendar from '../../Components/Calendar'
import SiteMap from '../../Components/SiteMap';
import ScrollTop from '../ScrollButton';
import WFTrans from '../WFTrans';
import InputTime from "../../Components/InputTime/InputTime";
import AlertBox from "../../Components/AlertBox";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class FingerPrint extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { empCode: '', empName: '', transType1: '', transType2: '', transTime1: '', transTime2: '', notes: '' },
            deleteErrors: { deleteNote: '' },
            transTypesList: Util.getInOutTypes(),
            isDesktop: window.innerWidth > 1250,
            dataObject: {
                id: 0,
                empCode: this.props.match.params.empCode || localStorage.getItem("useridHR") || '',
                empName: Util.getEmployeeInfo() || '',
                insertDate: new Date(),
                transDate: new Date(),
                transType1: undefined,
                transType2: undefined,
                transTime1: undefined,
                transTime2: undefined,
                notes: ''
            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(777) && !Util.checkRights(785)) {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = this.updatePredicate();

        if (!this.props.match.params.id) {
            let empCode = this.props.match.params.empCode || localStorage.getItem("useridHR") + "";
            let empName = Util.getEmployeeInfo() || ''
            if (empCode !== localStorage.getItem("useridHR") + "") {
                let obj = await this.getEmployeeInfo(empCode);
                empCode = obj.Code;
                empName = obj.Name;
            }
            this.setState({ dataObject: { ...this.state.dataObject, empCode: empCode, empName: empName }, isDesktop: _isDesktop, loading: false },
                () => setTimeout(() => { this.drpTransType1.focusInput.focus() }, 10));
        }
        else {
            if (this.props.match.params.id) {
                let data = await this.showFingerPrintInfo(this.props.match.params.id)
                this.loadData(data)
            }
        }
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }
    popupHasCalled = () => {
        document.removeEventListener('keydown', this.onPageKeyDown);
    }
    popupHasClosed = () => {
        document.addEventListener('keydown', this.onPageKeyDown);
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _empCode = this.state.dataObject.empCode
        fieldValidationErrors._empCode = _empCode ? '' : $.strings.requiredFiled;

        let _empName = this.state.dataObject.empName;
        fieldValidationErrors.empName = _empName ? '' : $.strings.requiredFiled;

        if (this.state.dataObject.transType1 === undefined && this.state.dataObject.transType2 === undefined) {
            this.drpTransType1.focusInput.focus()
            Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTypeError)
            return false;
        }
        else if (this.state.dataObject.transType1) {
            if (this.state.dataObject.transType1.id <= 0) {
                Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTypeError)
                return false;
            }
            if (this.state.dataObject.transTime1 === undefined) {
                Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTimeError)
                return false;
            }
        }
        else if (this.state.dataObject.transType2) {
            if (this.state.dataObject.transType2.id <= 0) {
                Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTypeError)
                return false;
            }
            if (this.state.dataObject.transTime2 === undefined) {
                Util.showErrorMsg(this.messages, $.strings.fingerPrint.transTimeError)
                return false;
            }
        }

        if (this.state.dataObject.notes === undefined || this.state.dataObject.notes.trim() === "") {
            Util.showErrorMsg(this.messages, $.strings.fingerPrint.notesError)
            this.txtNotes.focus()
            return false;
        }

        return true
    }
    validateForm() {
        let isFormValid = this.state.empCodeValid && this.state.empNameValid && this.state.transType1Valid
            && this.state.transType2Valid && this.state.transTime1Valid && this.state.transTime2Valid && this.state.notesValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        let screenHeader = (<div className={`${"p-grid"} `}>
            <div className="p-lg-3 p-md-12" style={{ verticalAlign: 'middle' }}>
                <h2 className={styles.addFingerPrint}>
                    {$.strings.links.requests.noFingerPrint + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </h2>
            </div>
        </div>
        )

        let siteMapItems = [
            { label: $.strings.links.requests.menu },
            {
                label: <div>
                    {$.strings.links.requests.noFingerPrint + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];

        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" className={styles.addFingerPrint}>
                {/* {screenHeader} */}
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={this.state.dataObject.id <= 0 ? this.resetFields : undefined}
                            onSave={this.state.dataObject.id <= 0 ? () => this.onSave(true) : undefined}
                            onTransDetails={this.state.dataObject.id > 0 ? this.wfTransDetails : undefined}
                            onShowAttachment={this.state.dataObject.id > 0 && this.state.dataObject.file_id > 0 ? this.onShowAttachment : undefined}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid" style={{ width: '100%' }}>
                    <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>

                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnPrevVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onPrevious(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNextVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNext(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnFirstVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onFirst(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnLastVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onLast(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnDeleteVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onDelete(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloneVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onClone(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloseVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.btnClose(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />

                <DeleteNoteDialog ref={ref => this.deleteNoteDlg = ref} visible={this.state.dlgDeleteNoteVisible} deleteErrors={this.state.deleteErrors} appendTo={this.props.parent}
                    onYes={this.btnDeleteYes} onNo={this.DeleteCancel} deleteNote='' />
                {
                    this.state.confirmBoxVisible &&
                    <AlertBox ref={e => this.confirmBox = e} header={$.strings.appName} defaultButton={Util.defaultButton.Yes} />
                }
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-3 p-lg-5 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtCode = el}
                                type="text"
                                id="id"
                                keyfilter="pnum"
                                value={this.state.dataObject.id || ''}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.fingerPrint.code}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-5 p-md-6 p-sm-12">
                            <Calendar
                                id="insertDate"
                                innerRef={(el) => this.dtpRequestDate = el}
                                value={this.state.dataObject.insertDate}
                                caption={$.strings.fingerPrint.requestDate}
                                onChange={this.handleUserInput}
                                tooltip={$.strings.fingerPrint.requestDate}
                                readOnlyInput={true}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-3 p-lg-5 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpCode = el}
                                type="text"
                                id="empCode"
                                keyfilter="pnum"
                                maxLength="4"
                                value={this.state.dataObject.empCode || ''}
                                autofocus={true}
                                autocomplete="off"
                                caption={$.strings.fingerPrint.empCode}
                                isRequired={true}
                                onChange={this.handleUserInput}
                                readOnly={true}
                                disabled
                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                        <div className="p-col-12 p-xl-4 p-lg-5 p-md-6 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtEmpName = el}
                                type="text"
                                id="empName"
                                maxLength="70"
                                value={this.state.dataObject.empName || ''}
                                autofocus={true}
                                caption={$.strings.fingerPrint.empName}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                readOnly={true}
                                disabled
                                style={{ width: '100%', color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                            />
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-3 p-lg-5 p-md-6 p-sm-12">
                            <Calendar
                                id="transDate"
                                innerRef={(el) => this.dtpTransDate = el}
                                ref={(el) => this.dtpTransDate = el}
                                value={this.state.dataObject.transDate}
                                caption={$.strings.fingerPrint.transDate}
                                onChange={this.handleUserInput}
                                tooltip={$.strings.fingerPrint.transDate}
                                readOnlyInput={true}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-6 p-xl-3 p-lg-5 p-md-6 p-sm-12">
                            <Dropdown
                                id="transType1"
                                innerRef={e => this.drpTransType1 = e}
                                value={this.state.dataObject.transType1 || ''}
                                options={this.state.transTypesList}
                                showClear={true}
                                caption={$.strings.fingerPrint.transType}
                                onChange={this.handleUserInput}
                                formErrors={this.state.formErrors}
                                optionLabel="name"
                                placeholder={$.strings.fingerPrint.selectTransType}
                                filter={true}
                                filterBy="code,name"
                                filterMatchMode="contains"
                                isRequired={true}
                                tooltip={$.strings.fingerPrint.transType}
                                tooltipOptions={{ position: 'top' }}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                        <div className="p-col-6 p-xl-3 p-lg-5 p-md-6 p-sm-12">
                            <InputTime
                                id="transTime1"
                                ref={e => this.dtpTransTime = e}
                                value={this.state.dataObject.transTime1}
                                caption={$.strings.fingerPrint.transTime}
                                valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, transTime1: e.value } })}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-6 p-xl-3 p-lg-5 p-md-6 p-sm-6">
                            <Dropdown
                                id="transType2"
                                innerRef={e => this.drpTransType2 = e}
                                value={this.state.dataObject.transType2 || ''}
                                options={this.state.transTypesList}
                                showClear={true}
                                caption={$.strings.fingerPrint.transType}
                                onChange={this.handleUserInput}
                                formErrors={this.state.formErrors}
                                optionLabel="name"
                                placeholder={$.strings.fingerPrint.selectTransType}
                                filter={true}
                                filterBy="code,name"
                                filterMatchMode="contains"
                                isRequired={true}
                                tooltip={$.strings.fingerPrint.transType}
                                tooltipOptions={{ position: 'top' }}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                        <div className="p-col-6 p-xl-3 p-lg-5 p-md-6 p-sm-6">
                            <InputTime
                                id="transTime2"
                                ref={e => this.dtpTransTime2 = e}
                                value={this.state.dataObject.transTime2}
                                caption={$.strings.fingerPrint.transTime}
                                valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, transTime2: e.value } })}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12 p-xl-7 p-lg-10 p-md-12 p-sm-12">
                            <InputTextArea
                                innerRef={ref => this.txtNotes = ref}
                                type="text"
                                id="notes"
                                maxLength="100"
                                value={this.state.dataObject.notes}
                                caption={$.strings.fingerPrint.notes}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                disabled={this.state.dataObject.id > 0 ? true : false}
                            />
                        </div>
                    </div>
                </Card>
                <ScrollTop />
                <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
            </div >
        );
    }

    getEmployeeInfo = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode,
            isHr: Util.checkISHR(),
            userId: localStorage.getItem('useridHR')
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeInfo, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployeeInfoObj;
        }
        return dataTemp
    }

    checkVacationFound = async (id) => {
        let objResult = { result: false, errorText: '' }
        let obj = {
            transDate: this.state.dataObject.transDate,
            empCode: this.state.dataObject.empCode,
            isHR: false
        }
        let res = await http.postMultipartAxios(http.actions.FingerPrint.urlCheckVacationOrRequestFound, obj, null, null, 0);
        if (res.status === 200) {
            objResult = res.data;
        }
        return objResult
    }

    showFingerPrintInfo = async (id) => {
        let dataTemp = []
        let obj = {
            id: id
        }
        let res = await http.getJsonAxios(http.actions.FingerPrint.urlGetFingerPrint, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    loadData = (data) => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(777)) {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = this.updatePredicate();
        let transType1 = this.state.transTypesList.filter(element => element.id + "" === data.transType + "")
        if (transType1 && transType1.length > 0)
            transType1 = transType1[0]

        let transType2 = this.state.transTypesList.filter(element => element.id + "" === data.transType2 + "")
        if (transType2 && transType2.length > 0)
            transType2 = transType2[0]

        let transTime1 = new Date(data.transTime)
        let transTime2 = new Date(data.transTime2)

        this.setState({
            mode: 'view',
            isDesktop: _isDesktop,
            dataObject: {
                ...this.state.dataObject,
                id: data.id,
                empCode: data.empCode,
                empName: data.empName,
                insertDate: new Date(data.insertDate),
                transDate: new Date(data.transDate),
                transType1: transType1,
                transType2: transType2,
                transTime1: transTime1.getFullYear() + "" === "1" ? '' : transTime1,
                transTime2: (transTime2.getFullYear() + "" === "0001" || transTime2.getFullYear() + "" === "1") ? '00:00:00' : transTime2,
                notes: data.notes,
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                //file_id: data.memoValue
            }
            , loading: false
        }, () => setTimeout(() => { this.drpTransType1.focusInput.focus() }, 10))
    }

    wfTransDetails = async () => {
        let id = Util.encryptStringToHexa(this.state.dataObject.id);
        await this.wfTransDlg.show(12, id)
    }

    onSave = async (checkVacation) => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }

        if (checkVacation && Util.getSettingById(260) + '' === '1') {
            let objResult = await this.checkVacationFound();
            if (objResult.result) {
                // Util.confirmDialog(objResult.errorText, 'pi pi-question-circle',
                //     async () => this.onSave(false), () => { });

                this.popupHasCalled();
                this.setState({ loading: false, confirmBoxVisible: true }, () => {
                    this.confirmBox.show(
                        async () => {
                            this.popupHasClosed();
                            let res = await this.onSave(false);
                            this.setState({ confirmBoxVisible: false });
                            return res;
                        },
                        () => {
                            this.popupHasClosed();
                            this.setState({ confirmBoxVisible: false });
                        }, undefined,
                        objResult.errorText
                    );
                })

                return;
            }
        }
        this.messages.clear();
        this.setState({ loading: true })
        let files = [] // ملفات المرفقات
        let file = this.state.dataObject.uploadedFile2

        let obj = {
            serial: !this.state.dataObject.id ? 0 : this.state.dataObject.id,
            empcode: this.state.dataObject.empCode,
            insertDate: new Date(),
            transDate: this.state.dataObject.transDate,
            transType: this.state.dataObject.transType1 ? this.state.dataObject.transType1.id : -1,
            transType2: this.state.dataObject.transType2 ? this.state.dataObject.transType2.id : -1,
            transTime: this.state.dataObject.transTime1 ? this.state.dataObject.transTime1 : '00:00:00',
            transTime2: this.state.dataObject.transTime2 ? this.state.dataObject.transTime2 : '00:00:00',
            notes: this.state.dataObject.notes,
            userCode: localStorage.getItem("useridHR") + ""
        }

        let saveResult
        let res = await http.postMultipartAxios(http.actions.FingerPrint.urlPostFingerPrint, obj, file, files, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return saveResult = false
        }
        this.setState({ loading: false })
    }

    resetFields = () => {
        this.setState({
            dataObject: {
                ...this.state.dataObject,
                id: 0,
                insertDate: new Date(),
                transDate: new Date(),
                transType1: undefined,
                transType2: undefined,
                transTime1: "00:00",
                transTime2: "00:00",
                notes: ''
            },
            formErrors: { empCode: '', empName: '', transType1: '', transType2: '', transTime1: '', transTime2: '', notes: '' },
        }
        )
    }
}