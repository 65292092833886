import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollTop from '../ScrollButton';
import Print from "../../Print";

const $ = window.$;

export default class NotificationsReport extends React.Component {
    constructor(props) {
        super(props);
        this.statusList = [
            { id: -1, name: $.strings.all },
            { id: 1, name: $.strings.notificationsReport.new },
            { id: 2, name: $.strings.notificationsReport.postponed },
            { id: 3, name: $.strings.notificationsReport.finished },
            { id: 4, name: $.strings.notificationsReport.canceled }
        ]
        this.notTypeList = []
        this.userTypeList = [
            { id: -1, name: $.strings.all },
            { id: 0, name: $.strings.notificationsReport.employee },
            { id: 1, name: $.strings.notificationsReport.hrEmployee }
        ];
        this.currDate = new Date();
        this.state = {
            isDesktop: window.innerWidth > 1250,
            fromDate: new Date(this.currDate.getFullYear(), this.currDate.getMonth(), 1),
            toDate: new Date(),
            userId: undefined,
            userType: this.userTypeList[0],
            data: [],
            notType: undefined,
            forUserId: undefined,
            fromHandleDate: undefined,
            toHandleDate: undefined
        };
    }

    componentDidMount = async () => {
        if (!$.license.Notifications_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(930)) {
            Util.goTo('/Access');
            return;
        }

        this.notTypeList = await this.getNotificationsTypes();

        window.addEventListener("resize", this.updatePredicate);
        this.setState({ notType: this.notTypeList && this.notTypeList.length > 0 ? this.notTypeList[0] : undefined, loading: false })

    }
    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 1250 });
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.notificationsReport.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card>
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
            </Card>
            <Accordion multiple activeIndex={[0, 1]}>
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <Calendar
                                id="fromDate"
                                value={this.state.fromDate}
                                caption={$.strings.notificationsReport.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <Calendar
                                id="toDate"
                                value={this.state.toDate}
                                caption={$.strings.notificationsReport.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <Dropdown
                                id="status"
                                value={this.state.status}
                                options={this.statusList}
                                caption={$.strings.notificationsReport.status}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <Dropdown
                                id="notType"
                                value={this.state.notType}
                                options={this.notTypeList}
                                caption={$.strings.notificationsReport.notType}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <Calendar
                                id="fromHandleDate"
                                value={this.state.fromHandleDate}
                                caption={$.strings.notificationsReport.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <Calendar
                                id="toHandleDate"
                                value={this.state.toHandleDate}
                                caption={$.strings.notificationsReport.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                id="userId"
                                value={this.state.userId}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.notificationsReport.userId}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <Input
                                id="forUserId"
                                value={this.state.forUserId}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.notificationsReport.forUserId}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                            <Dropdown
                                id="userType"
                                value={this.state.userType}
                                options={this.userTypeList}
                                caption={$.strings.notificationsReport.userType}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            style={gridStyle}
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.getScheme()}
                            dataSource={this.state.data}
                            exportFileName={$.strings.loginsReport.NewsReportTitle}
                        />
                    </div>
                </div>
            </Card>
            <ScrollTop />
        </div >
    }

    getNotificationsTypes = async () => {
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.Notifications.urlGetNotificationsType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        console.log(this.state.notType)
        let obj = {
            status: this.state.status ? this.state.status.id : "-1",
            notificationType: this.state.notType ? this.state.notType.ID : "-1",
            fromCode: this.state.userId ? this.state.userId : '',
            ownerCode: this.state.forUserId ? this.state.forUserId : '',
            destType: this.state.userType ? this.state.userType.id : "-1",
        }
        if (this.state.fromDate)
            obj.fromDate = this.state.fromDate
        if (this.state.toDate)
            obj.toDate = this.state.toDate
        if (this.state.fromHandleDate)
            obj.fromProccessingDate = this.state.fromHandleDate
        if (this.state.toHandleDate)
            obj.toProccessingDate = this.state.toHandleDate

        let res = await http.postMultipartAxios(http.actions.Notifications.urlGetNotificationsReport, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.notificationsReport.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let dateFilter = "", processDate = "";

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.notificationsReport.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.notificationsReport.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.status) {
                listOfFilters.push($.strings.notificationsReport.status + ": " + this.printFilters.status.name);
            }

            if (this.printFilters.notType) {
                listOfFilters.push($.strings.notificationsReport.notType + ": " + this.printFilters.notType.Name);
            }

            // Process Date Filter
            if (this.printFilters.fromHandleDate) {
                processDate = $.strings.notificationsReport.fromDate + ": " + Util.getDateFormat(this.printFilters.fromHandleDate);
            }
            if (this.printFilters.toHandleDate) {
                if (processDate !== "")
                    processDate += " - ";
                else
                    processDate = $.strings.notificationsReport.fromDate + ": ";
                processDate += Util.getDateFormat(this.printFilters.toHandleDate);
            }
            if (processDate !== "")
                listOfFilters.push(processDate)

            if (this.printFilters.userId) {
                listOfFilters.push($.strings.notificationsReport.userId + ": " + this.printFilters.userId);
            }

            if (this.printFilters.forUserId) {
                listOfFilters.push($.strings.notificationsReport.forUserId + ": " + this.printFilters.forUserId);
            }

            if (this.printFilters.userType) {
                listOfFilters.push($.strings.notificationsReport.userType + ": " + this.printFilters.userType.name);
            }

            if (this.printFilters.userId) {
                listOfFilters.push($.strings.notificationsReport.userId + ": " + this.printFilters.userId);
            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };


    getScheme = () => {
        return {
            name: "notificationsReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 5,
            columns: [
                { name: 'rowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'ID', header: $.strings.notificationsReport.code, width: 80, columnWidth: '2' },
                { name: 'InsDate', header: $.strings.notificationsReport.insDate, width: 110, columnWidth: '3' },
                { name: 'NoteTypeName', header: $.strings.notificationsReport.notTypeName, width: 130, columnWidth: '2' },
                { name: 'notification', header: $.strings.notificationsReport.notification, width: 120, columnWidth: '2' },
                { name: 'EmpName', header: $.strings.notificationsReport.empName, width: '*', minWidth: 150, columnWidth: '*' },
                { name: 'DelayedPerson', header: $.strings.notificationsReport.forEmpName, width: '*', minWidth: 150, columnWidth: '2' },
                { name: 'code', header: $.strings.notificationsReport.code, visibleInColumnChooser: false, visible: false },
                { name: 'Note', header: $.strings.notificationsReport.note, width: "*", minWidth: 150, columnWidth: '*' },
                { name: 'NoteType', header: $.strings.notificationsReport.noteType, width: 120, columnWidth: '2', visibleInColumnChooser: false, visible: false },
                { name: 'Status', header: $.strings.notificationsReport.status, width: 100, columnWidth: '2' },
                { name: 'StatusNo', header: $.strings.notificationsReport.status, width: "*", columnWidth: '*', visibleInColumnChooser: false, visible: false },
                { name: 'TragetDate', header: $.strings.notificationsReport.targetDate, width: 100, columnWidth: '*' },
                { name: 'ProeccessingNote', header: $.strings.notificationsReport.processNote, width: 130, columnWidth: '*' },
                { name: 'UserName', header: $.strings.notificationsReport.userName, width: 120, columnWidth: '*', visibleInColumnChooser: false, visible: false },
                { name: 'ProcessDate', header: $.strings.notificationsReport.processDate, width: 120, columnWidth: '*' }
            ]
        }
    }
}