import React from "react";
import ScrollTop from '../ScrollButton';
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import Checkbox from '../../Components/Checkbox';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ClockTransEdit from "../ClockTrans/ClockTransEdit";
import Print from "../../Print";

const $ = window.$;

export default class AttendanceReport extends React.Component {
    constructor(props) {
        super(props);
        this.transTypeList = [
            { id: 1, name: $.strings.attendanceReport.allTrans },
            { id: 0, name: $.strings.attendanceReport.firstInLastOut }
        ]
        this.state = {
            isDesktop: window.innerWidth > 1250,
            activeIndex: 0,
            transTypeList: this.transTypeList,
            empCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            transType: this.transTypeList[0],
            fromDate: new Date(),
            toDate: new Date(),
            showVacationsAndHolidays: false,
            takeAllowancePeriod: false,
            filterShown: true,
            data: [],
            data1: [],
            data2: []
        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(710)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(652)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();
        let shiftsPromise = this.getShifts();
        let shiftsList = await shiftsPromise;

        let empCode = ''
        if (this.props.match.params.id) {
            empCode = this.props.match.params.id;
            if (empCode !== localStorage.getItem("useridHR") + "") {
                let obj = await this.getEmployeeInfo(empCode);
                empCode = obj.Code;
            }
        }
        else {
            empCode = localStorage.getItem("useridHR")
        }

        this.setState({
            empCode: !Util.checkISHR() || this.props.match.params.id ? empCode : '',
            shiftsList: shiftsList, shift: shiftsList && shiftsList.length > 0 ? shiftsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })
    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../../MyStaff' },
            { label: $.strings.attendanceReport.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card>
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
            </Card>
            <Accordion multiple activeIndex={[0, 2]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtEmpCode = el}
                                type="text"
                                id="empCode"
                                value={this.state.empCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.attendanceReport.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                            <Dropdown
                                id="transType"
                                innerRef={e => this.drpTransType = e}
                                value={this.state.transType}
                                options={this.state.transTypeList}
                                caption={$.strings.attendanceReport.transType}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.attendanceReport.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.attendanceReport.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        {
                            Util.checkISHR() &&
                            <div className="p-col-6 p-xl-2 p-lg-4 p-md-4 p-sm-6">
                                <Dropdown
                                    id="shift"
                                    innerRef={e => this.drpShifts = e}
                                    value={this.state.shift}
                                    options={this.state.shiftsList}
                                    caption={$.strings.attendanceReport.shift}
                                    onChange={this.handleUserInput}
                                    optionLabel="ShiftName"
                                />
                            </div>
                        }

                        <div className="p-col-6 p-xl-3 p-lg-4 p-md-4 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showVacationsAndHolidays" caption={$.strings.attendanceReport.showVacationsAndHolidays}
                                onChange={e => this.setState({ showVacationsAndHolidays: e.checked })} checked={this.state.showVacationsAndHolidays} />
                        </div>
                        <div className="p-col-6 p-xl-3 p-lg-4 p-md-4 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="takeAllowancePeriod" caption={$.strings.attendanceReport.takeAllowancePeriod}
                                onChange={e => this.setState({ takeAllowancePeriod: e.checked })} checked={this.state.takeAllowancePeriod} />
                        </div>

                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.attendanceReport.attendanceReportTotal}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <DataGrid
                                //licenseKey="..."
                                defaultRowHeight="45"
                                style={gridStyle}
                                ref={e => this.gridData1 = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="serial"
                                scheme={this.getGridOneScheme()}
                                dataSource={this.state.data1}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <DataGrid
                                //licenseKey="..."
                                style={gridStyle}
                                defaultRowHeight="45"
                                ref={e => this.gridData2 = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="serial"
                                scheme={this.getGridOneScheme()}
                                dataSource={this.state.data2}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            style={gridStyle}
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.getScheme()}
                            dataSource={this.state.data}
                            exportFileName={$.strings.attendanceReport.title}
                        />
                    </div>
                </div>
            </Card>

            <ClockTransEdit ref={e => this.dlgClockTransEdit = e} onSuccess={this.onClockTransEditSuccess} />
            <ScrollTop />
        </div>
    }

    onClockTransEditSuccess = async () => {
        await this.getData()
    }

    getEmployeeInfo = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode,
            isHr: Util.checkISHR(),
            userId: localStorage.getItem('useridHR')
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeInfo, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployeeInfoObj;
        }
        return dataTemp
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ empCode: obj.code })
        }
    }

    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let dataTemp1 = []
        let dataTemp2 = []
        let obj = {
            sort: '0',
            allTransactions: this.state.transType && this.state.transType.id === 1 ? true : false,
            userId: localStorage.getItem("useridHR") + "",
            empCode: this.state.empCode ? this.state.empCode : '',
            section: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : -1,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            DDL_shift: this.state.shift ? this.state.shift.Code : '-1',
            ShowVocations: this.state.showVacationsAndHolidays,
            isHr: Util.checkISHR(),
            chkTakeAllowancePeriod: this.state.takeAllowancePeriod
        }

        let res = await http.getJsonAxios(http.actions.Attendance.urlGetAttendanceReport, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            dataTemp1 = res.data.list1;
            dataTemp2 = res.data.list2;
            if (dataTemp && dataTemp.length > 0) {
                dataTemp.forEach(function (obj) {
                    obj.date = new Date(obj.date);
                });
            }
        }

        this.setState({ data: dataTemp, data1: dataTemp1, data2: dataTemp2, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.attendanceReport.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";

            // Code Filter
            if (this.printFilters.empCode) {
                codeFilter = $.strings.attendanceReport.fromCode + ": " + this.printFilters.empCode;
            }

            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            if (this.printFilters.transType) {
                let name = this.printFilters.transType.name
                listOfFilters.push($.strings.attendanceReport.transType + ": " + name);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.attendanceReport.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter += $.strings.attendanceReport.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter);


            if (Util.checkISHR()) {
                if (this.printFilters.shift) {
                    listOfFilters.push($.strings.attendanceReport.shift + ": " + this.printFilters.shift.ShiftName);
                }

            }

            if (this.printFilters.showVacationsAndHolidays)
                listOfFilters.push($.strings.attendanceReport.showVacationsAndHolidays + ": " + (this.printFilters.showVacationsAndHolidays ? $.strings.yes : $.strings.no))
            if (this.printFilters.takeAllowancePeriod)
                listOfFilters.push($.strings.attendanceReport.takeAllowancePeriod + ": " + (this.printFilters.takeAllowancePeriod ? $.strings.yes : $.strings.no))

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };


    getGridOneScheme = () => {
        return {
            name: "attGridOneScheme_Table",
            filter: false,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 0,
            columns: [
                {
                    name: 'Trans', header: $.strings.attendanceReport.transTypeName, width: "*"

                },
                {
                    name: 'period', header: $.strings.attendanceReport.period, width: 150

                }
            ]
        }
    }
    buttonClick = (e, ctx) => {
        this.dlgClockTransEdit.show(ctx.item.empCode, ctx.item.date, 0);
    }
    getScheme = () => {
        return {
            name: "attendanceRptScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [

                {
                    name: 'rowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1'
                },
                {
                    name: 'btn', header: ' ', width: 60, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.clockTrans.clockTransEditTitle,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.buttonClick(e, ctx)
                },
                {
                    name: 'empCode', header: $.strings.attendanceReport.empCode, width: 120, columnWidth: '2'
                },
                {
                    name: 'empName', header: $.strings.attendanceReport.empName, width: this.state.isDesktop ? 200 : 300, columnWidth: '*'
                },
                {
                    name: 'date', header: $.strings.attendanceReport.date, width: 120, columnWidth: '2.5', dataType: 'Date', format: Util.gridDateFormat(),
                },
                {
                    name: 'day', header: $.strings.attendanceReport.day, width: 120, columnWidth: '2'
                },
                {
                    name: 'Login', header: $.strings.attendanceReport.in, width: 120, columnWidth: '2'
                },
                {
                    name: 'Logout', header: $.strings.attendanceReport.out, width: 120, columnWidth: '2'
                },
                {
                    name: 'period', header: $.strings.attendanceReport.period, width: 120, columnWidth: '2'
                },
                {
                    name: 'out1', header: $.strings.attendanceReport.out1, width: 120,
                    visible: this.state.data.filter(e => e.out1 && e.out1.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'in1', header: $.strings.attendanceReport.in1, width: 120,
                    visible: this.state.data.filter(e => e.in1 && e.in1.length).length > 0, columnWidth: '2'

                },
                {
                    name: 'period1', header: $.strings.attendanceReport.period, width: 120,
                    visible: this.state.data.filter(e => e.period1 && e.period1.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'out2', header: $.strings.attendanceReport.out2, width: 120,
                    visible: this.state.data.filter(e => e.out2 && e.out2.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'in2', header: $.strings.attendanceReport.in2, width: 120,
                    visible: this.state.data.filter(e => e.in2 && e.in2.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'period2', header: $.strings.attendanceReport.period2, width: 120,
                    visible: this.state.data.filter(e => e.period2 && e.period2.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'out3', header: $.strings.attendanceReport.out3, width: 120,
                    visible: this.state.data.filter(e => e.out3 && e.out3.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'in3', header: $.strings.attendanceReport.in3, width: 120,
                    visible: this.state.data.filter(e => e.in3 && e.in3.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'period3', header: $.strings.attendanceReport.period3, width: 120,
                    visible: this.state.data.filter(e => e.period3 && e.period3.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'out4', header: $.strings.attendanceReport.out4, width: 120,
                    visible: this.state.data.filter(e => e.out4 && e.out4.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'in4', header: $.strings.attendanceReport.in4, width: 120,
                    visible: this.state.data.filter(e => e.in4 && e.in4.length).length > 0, columnWidth: '2'
                },
                {
                    name: 'period4', header: $.strings.attendanceReport.period4, width: 120,
                    visible: this.state.data.filter(e => e.period4 && e.period4.length).length > 0, columnWidth: '2'
                },
                { name: 'from', header: $.strings.attendanceReport.from, width: 120, columnWidth: '2' },
                { name: 'to', header: $.strings.attendanceReport.to, width: 120, columnWidth: '2' },
                { name: 'notes', header: $.strings.attendanceReport.notes, width: "*", columnWidth: '2' }
            ]
        }
    }
}