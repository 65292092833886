import React from "react";
import styles from "./RequestsAliases.module.scss";
import DataGridView from '../../../Components/DataGridView/DataGridView';
import Http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import Messages from '../../../Components/Messages';
import Util from '../../../Util'
import Card from "../../../Components/Card";
import SiteMap from "../../../Components/SiteMap";
import RequestsAliasesAdd from "./RequestsAliasesAdd";
import ScreenCommonCode from "../../ScreenCommonCode";
import LookupsUserRights from "../LookupsUserRights/LookupsUserRights";

const $ = window.$;

export default class RequestsAliases extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            data: [],
            id: 0,
            isDesktop: true
        };
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = window.innerWidth > 1250

        let dataTemp = await this.onRefresh(true);
        this.setState({ data: dataTemp, loading: false, isDesktop: _isDesktop });
    }

    render() {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.staticFilesAndSettings },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (

            < div ref={e => this.divMain = e} className={styles.Lookups} >
                <ProgressSpinner loading={this.state.loading} />
                <LookupsUserRights ref={e => this.dlgLookupsUserRights = e} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />

                    {ScreenCommonCode.getScreenHeader_Buttons(this, false)}
                    <Messages innerRef={(el) => this.messages = el} />
                    {
                        this.state.RequestsAliasesAddVisible &&
                        <RequestsAliasesAdd ref={e => this.dlgRequestsAliasesAdd = e} onSuccess={this.onAddSuccess} onClose={this.onAddClose}
                            Data={this.state.objData} />
                    }
                </Card>
                <DataGridView
                    style={gridStyle}
                    ref={e => this.gridData = e}
                    isReport={true}
                    theme="default-light"
                    idProperty="serial"
                    scheme={this.getScheme()}
                    exportFileName={$.strings.requestsAliases.title}
                    dataSource={this.state.data}
                    defaultRowHeight={45}
                    onRowDoubleClick={this.onRowDoubleClick}
                />
            </div >
        );
    }

    setPageInfo = () => {
        let pageId
        let title = $.strings.requestsAliases.title;

        return { pageId: pageId, title: title };
    };

    onClose = async (e) => {
        this.onRefresh();
        this.setState({ dlgSearchVisible: false });
    };

    onRowDoubleClick = (e) => {
        let item = this.gridData.getCurrentRowItem();
        if (item) {
            this.popupHasCalled();
            this.setState({ RequestsAliasesAddVisible: true, objData: item })
        }
    }


    btnRefresh = e => {
        this.setState({ data: this.onRefresh() });
    };

    onRefresh = async (fromLoad) => {
        let dataTemp = [];
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        const params = {
            id: 0
        };

        let res = await Http.getJsonAxios(Http.actions.RequestsAliases.urlGetRequestsAliases, params, this.state.pageInfo.pageId);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        if (fromLoad) {
            return dataTemp;
        }
        else {
            this.setState({ data: dataTemp, loading: false })
        }
    }

    popupHasCalled = () => {
        document.removeEventListener('keydown', this.onPageKeyDown);
    }
    popupHasClosed = () => {
        document.addEventListener('keydown', this.onPageKeyDown);
    }

    // onNew = e => {
    //     this.popupHasCalled();
    //     this.setState({ RequestsAliasesAddVisible: true });
    // };

    onAddClose = (doRefreh) => {
        this.popupHasClosed();
        this.setState({ RequestsAliasesAddVisible: false });
        if (doRefreh)
            this.onRefresh(false)
    };

    onAddSuccess = result => {
        this.popupHasClosed();
        this.setState({ RequestsAliasesAddVisible: false });
        if (result) {
            this.onRefresh(false)
        }
    };

    onEditClick = (e) => {
        this.onRowDoubleClick(e);
    }
    onUsersRights = (e, ctx) => {
        //alert(ctx.item.Name);
        this.dlgLookupsUserRights.show(ctx.item.Type, ctx.item.Pos);
    }
    getScheme = () => {
        return {
            name: "requestsAliasesLookups_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 4,
            columns: [
                {
                    name: 'ser', header: '##', width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'ID', header: $.strings.requestsAliases.code, width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'btnEdit', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center',
                    title: $.strings.edit, iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEditClick(e)
                },
                {
                    name: 'Name', header: $.strings.requestsAliases.name, width: this.state.isDesktop ? '*' : 300,
                },
                {
                    name: 'EName', header: $.strings.requestsAliases.eName, width: this.state.isDesktop ? '*' : 300,
                }
            ]
        }
    }
}