import React from "react";
import Button from '../../Components/Button'
import http from "../../Api/http";
import Dialog from '../../Components/Dialog';
import DataTable from '../../Components/Datatable';
import Input from "../../Components/Input";
import SearchEmployees from '../Employees/SearchEmployees';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class ChangeApprovalUser extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            wfTransID: undefined,
            reqID: undefined,
            empCode: undefined,
            empName: undefined,
            newEmpCode: undefined,
            newEmpName: undefined,
            approvalEmp: undefined,
            reason: undefined,
            typeId: undefined
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button label={$.strings.save} icon="" className="p-button-success" onClick={this.onSave}>
                    &nbsp;&nbsp;
                    <i className="pi pi-save" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <Dialog header={$.strings.wfTransReport.changeApprovalTitle} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir }}
                modal onHide={this.onHide}>
                <div ref="divMain" >
                    <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-3 p-sm-12">
                            {$.strings.wfTransReport.currentApproval}
                        </div>
                        <div className="p-col-12 p-lg-3 p-sm-12">
                            <Input
                                type="text"
                                id="approvalEmp"
                                value={this.state.approvalEmp}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                type="text"
                                id="approvalEmpName"
                                value={this.state.approvalEmpName}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-3 p-sm-12">
                            {$.strings.wfTransReport.newApproval}
                        </div>
                        <div className="p-col-12 p-lg-3 p-sm-12">
                            <Input
                                type="text"
                                id="newEmpCode"
                                value={this.state.newEmpCode}
                                withgroup={true}
                                btn1icon="search"
                                btn1event={this.getEmployee}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                type="text"
                                id="newEmpName"
                                value={this.state.newEmpName}
                            />
                        </div>
                    </div>
                </div >
            </Dialog >
        );
    }

    getEmployee = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ newEmpCode: obj.code, newEmpName: obj.name })
        }
    }

    show = async (wfTransID, reqID, typeId, reason, approvalEmp, approvalEmpName, empCode) => {
        this.setState({
            visibleDialog: true, wfTransID: wfTransID, reqID: reqID, typeId: typeId, reason: reason,
            approvalEmp: approvalEmp, approvalEmpName: approvalEmpName, empCode: empCode
        })
    }

    onHide = () => {
        this.setState({
            visibleDialog: false, wfTransID: undefined, reqID: undefined, empCode: undefined, empName: undefined,
            newEmpCode: undefined, newEmpName: undefined, approvalEmp: undefined, reason: undefined, typeId: undefined
        })
    }

    onSave = async () => {
        this.setState({ loading: true })
        let success = false;
        let obj = {
            id: this.state.wfTransID,
            reqID: this.state.reqID,
            typeID: this.state.typeId,
            approvalEmp: this.state.approvalEmp,
            Reason: this.state.reason,
            EmpCode: this.state.empCode,
            newEmpCode: this.state.newEmpCode
        }
        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetSaveChangeEvaluator, obj, 0);
        
        if (res.status === 200) {
            success = res.data.success;
        }
        if (success) {
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
            this.onHide();
        }
        else {
            this.setState({ loading: false })
        }
    }
}