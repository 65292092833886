import React from "react";
import styles from './ScrollButton.module.scss'
import { ScrollTop } from 'primereact/scrolltop';

const $ = window.$;

export default class ScrollButton extends React.Component {
    render() {
        return <ScrollTop className="custom-scrolltop" threshold={100} />
    }
}