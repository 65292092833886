
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import 'stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css';
import Util from "./Util";

var pjson = require('../package.json');

const $ = window.$;

const exportDefault = {
    getPrintingHeaderStrings(listOfFilters, printingObjHeader) {
        let filters = [
            { row: 'firstHeader', col: 'rightText' }, { row: 'firstHeader', col: 'centerText' }, { row: 'firstHeader', col: 'leftText' },
            { row: 'secondHeader', col: 'rightText' }, { row: 'secondHeader', col: 'centerText' }, { row: 'secondHeader', col: 'leftText' },
            { row: 'thirdHeader', col: 'rightText' }, { row: 'thirdHeader', col: 'centerText' }, { row: 'thirdHeader', col: 'leftText' }
        ];

        if ($.strings.dir === "ltr") {
            filters = [
                { row: 'firstHeader', col: 'leftText' }, { row: 'firstHeader', col: 'centerText' }, { row: 'firstHeader', col: 'rightText' },
                { row: 'secondHeader', col: 'leftText' }, { row: 'secondHeader', col: 'centerText' }, { row: 'secondHeader', col: 'rightText' },
                { row: 'thirdHeader', col: 'leftText' }, { row: 'thirdHeader', col: 'centerText' }, { row: 'thirdHeader', col: 'rightText' }
            ];
        }
        if (listOfFilters) {
            for (let index = 0; index < listOfFilters.length; index++)
                printingObjHeader[filters[index].row][filters[index].col] = listOfFilters[index];
        }
        return printingObjHeader
    },
    getSummaryType(col) {
        let summaryList = [];
        Object.getOwnPropertyNames(col.aggregate).forEach(
            function (val, idx, array) {
                if (col.aggregate[val]) {
                    summaryList.push(val);
                }
            }
        );
        return summaryList;
    },
    getSummaryTypeWijmo(col) {
        let summaryList = [];
        switch (col.aggregate) {
            case 0:
                summaryList.push("None");
                break;
            case 1:
                summaryList.push("Sum");
                break;
            case 2:
                summaryList.push("Cnt");
                break;
            case 3:
                summaryList.push("Avg");
                break;
            case 4:
                summaryList.push("Max");
                break;
            case 5:
                summaryList.push("Min");
                break;
            case 6:
                summaryList.push("Rng");
                break;
            case 7:
                summaryList.push("Std");
                break;
            case 8:
                summaryList.push("Var");
                break;
            case 9:
                summaryList.push("StdPop");
                break;
            case 10:
                summaryList.push("VarPop");
                break;
            case 11:
                summaryList.push("CntAll");
                break;
            case 12:
                summaryList.push("First");
                break;
            case 13:
                summaryList.push("Last");
                break;
            default: {
                break;
            }
        }
        return summaryList;
    },
    getReportPrintObject(reportName, gridView, dataSource) {
        let columns = [];
        let objResult = gridView.getColumns();
        let isMobile = objResult.isMobile;
        let gridColumns = objResult.listOfColumns;

        if (gridColumns && gridColumns.length > 0) {
            for (let c = 0; c < gridColumns.length; c++) {
                let col = gridColumns[c];
                let colSummaryList = col && col.aggregate ? this.getSummaryTypeWijmo(col) : [];
                if (col.name && col.name !== '') {
                    columns.push({
                        colName: col.name,
                        columnCaption: col.header,
                        columnWidth: col.columnWidth || '',
                        visible: (!isMobile && col.visible === false) ? false : true,
                        firstSummaryType: colSummaryList && colSummaryList.length > 0 ? colSummaryList[0] : undefined,
                        secondSummaryType: colSummaryList && colSummaryList.length > 1 ? colSummaryList[1] : undefined
                    })
                }
                else {
                    if (col.header && col.header === $.strings.status) {
                        columns.push({
                            colName: 'statusName',
                            columnCaption: col.header,
                            columnWidth: '',
                            visible: col.visible === false ? false : true,
                        })
                    }
                }
            }
        }
        let arabicImage = Util.getSettingById(227) + "";
        let englishImage = Util.getSettingById(228) + "";

        let arabicName = Util.getSettingById(0) + "";
        let englishName = Util.getSettingById(1) + "";

        let arabicAddress = Util.getSettingById(2) + "";
        let englishAddress = Util.getSettingById(3) + "";

        //let img = await Util.GetSettingByIdImage();

        return {
            reportName: reportName || '', // (اسم التقرير)
            printVatReg: Util.getSettingById(229) + "" === "1" ? true : false, // (طباعة مشتغل مرخص)
            vatRegText: $.strings.vatReg + Util.getSettingById(4) + "", // (رقم المشتغل المرخص)
            vatRegFontName: Util.getSettingById(245) + "" || 'Arial', // (نوع خط المشتغل المرخص) 
            vatRegFontSize: Util.getSettingById(264) + "" || 12, // (حجم خط المشتغل المرخص) 
            pageMargins: {
                right: Util.getSettingById(235) + "" || 0, // (الهامش الأيمن)
                left: Util.getSettingById(236) || 0, // (الهامش الأيسر)
                top: Util.getSettingById(237) || 0, // (الهامش العلوي)
                bottom: Util.getSettingById(238) || 0 // (الهامش السفلي)
            },
            logo: {
                state: (Util.getSettingById(235) + "" === "1" ? "1" : "2") || 1, //  1= draw the logo header, 2= don't draw the logo header (حالة الترويسة)
                type: Util.getSettingById(226) || 1, // 1= text, 2= image (نوع الترويسة)
                image: Util.getNameByUserLanguage(arabicImage, englishImage) || '', // (صورة الترويسة)
                logoBottomBorderType: Util.getSettingById(249) || 2, // (نوع التسطير اسفل الترويسة)
                logoBorderType: Util.getSettingById(250) || 1, // (نوع الاطار المحيط بالترويسة)
                companyName: Util.getNameByUserLanguage(arabicName, englishName), // (اسم الشركة)
                companyNameFontName: Util.getSettingById(241) || 'Arial', // (خط اسم الشركة)
                companyNameFontSize: Util.getSettingById(242) || 12, // (حجم خط إسم الشركة)
                companyAddress: Util.getNameByUserLanguage(arabicAddress, englishAddress), // (عنوان الشركة)
                companyAddressFontName: Util.getSettingById(243) || 'Arial', // (خط عنوان الشركة)
                companyAddressFontSize: Util.getSettingById(244) || 12, // (حجم خط عنوان الشركة)
                imageStretch: true,
                lineSeparator: false // true or false for separator between logo header and first header
            },
            headers: {
                repeatedEveryPage: true,
                headersFontName: Util.getSettingById(245) || 'Arial', // نوع الخط نفس مشتغل مرخص
                headersFontSize: Util.getSettingById(246) || 12, // حجم الخط نفس مشتغل مرخص
                firstHeader: {
                    rightText: '',
                    centerText: '',
                    leftText: ''
                },
                secondHeader: {
                    rightText: '',
                    centerText: '',
                    leftText: ''
                },
                thirdHeader: {
                    rightText: '',
                    centerText: '',
                    leftText: ''
                }
            },
            data: {
                dataFontName: Util.getSettingById(247) || 'Arial', // (نوع خط البيانات)
                dataFontSize: Util.getSettingById(248) || 12, // (حجم خط البيانات)
                cellVerMargin: Util.getSettingById(239) || 0, // (هامش الخلية العلوي)
                cellHorMargin: Util.getSettingById(240) || 0, // (هامش الخلية الافقي)
                columns: columns, // array of columns each column contains 3 props (colName, columnCaption, columnWidth)
                dataSource: dataSource || [] // rows cells values
            },
            footers: {
                footerFontName: Util.getSettingById(247) || 'Arial', // نوع الخط نفس نوع خط البيانات
                footerFontSize: Util.getSettingById(248) || 12, // حجم الخط نفس حجم خط البيانات
                firstFooter: {
                    rightText: '',
                    centerText: '',
                    leftText: ''
                },
                secondFooter: {
                    rightText: '',
                    centerText: '',
                    leftText: ''
                },
                thirdFooter: {
                    rightText: '',
                    centerText: '',
                    leftText: ''
                }
            }
        }
    },
    doPrintReport(printObject) {
        localStorage.setItem("printObjectHR", "");
        localStorage.setItem("printObjectHR", JSON.stringify(printObject));
        Util.goToNewTab('/Reports/ReportsPrinting');
        //window.open('/ReportsPrinting', '_blank');
    },

    async printReportByDataBand(isLandscape) {
        try {
            let obj = localStorage.getItem("printObjectHR");
            if (!obj)
                return;
            obj = JSON.parse(obj);

            let isRtl = $.strings.dir === "rtl" ? true : false;
            var options = new Stimulsoft.Viewer.StiViewerOptions();
            options.width = "99%";
            options.height = "100%";
            options.appearance.htmlRenderMode = Stimulsoft.Report.Export.StiHtmlExportMode.Div;
            options.appearance.fullScreenMode = true;
            options.appearance.showTooltips = true;
            options.toolbar.showPrintButton = true;
            options.toolbar.showDesignButton = false;
            options.toolbar.showOpenButton = false;
            options.toolbar.showSaveButton = true;
            options.toolbar.showAboutButton = false;
            options.toolbar.showSendEmailButton = true;
            options.toolbar.showFullScreenButton = false;

            options.toolbar.viewMode = Stimulsoft.Viewer.StiWebViewMode.Continuous;
            options.exports.showExportToPdf = true;
            options.exports.ShowExportToWord2007 = true;
            options.exports.showExportDialog = true;

            let rightMargin = 0.1, leftMargin = 0.1, topMargin = 0.1;
            if (obj.pageMargins) {
                if (obj.pageMargins.right !== null)
                    rightMargin = obj.pageMargins.right;
                if (obj.pageMargins.left !== null)
                    leftMargin = obj.pageMargins.left;
                if (obj.pageMargins.top !== null)
                    topMargin = obj.pageMargins.top;
            }
            Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHnKUYVLDlxsm8fXFqOSOSu5YWCyk7BJ27IRBZgshZXuivJGQn" +
                "dztog138y18J8O7kSN7P+kHXiuBhP2zOFkRLAl4iir+1K6O2B2x6e1vLX1tVjV5UrWxl2Vs528PBj/bE4JlOAaEErR" +
                "AqJYE2QxzkVbI691yHqL3lr5LP64E2crTJytSELJd90fhiDpvnxyu77HFuRQUb7GnO58t+wjuObRsbfCfveaAmFqrg" +
                "ZNPB55eIEXQiTSrbHEUzt2lXMvn7bI0hszqLEg/OFsYQzqGOVVm7D39TtYRBAXsH6lAwcAr/VS0HhrzWVrMvX3o1km" +
                "qcb92RvSDuY+48tv4QN/Vwg/HUEXjWOqnn1CbH9G6wfjfOQxktM1I9KH5xKFr1cF1y2U6oRTeRM+4dQxEj4ajotw+9" +
                "QY5yWoEqBpUNZzc2gKNDYCNSIAEdshYJrYUBADhqUn+ZHQqvpI+Q8hcVrgEg8T9W4ZkPmFmeib/O3d3bsXGnECrmc8" +
                "lWly6Rv38TUXr+zhqzM59s9iAqmUg/f6Q6Mc";

            var viewer = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
            var report = new Stimulsoft.Report.StiReport();
            try {
                report.loadFile('../../assets/SSReports/GeneralReport.xml');
            }
            catch (e) {
                console.log(e)
            }
            var page = report.pages.getByIndex(0);

            switch (Util.getSettingById(231) + "") {
                case "0":
                    page.paperSize = Stimulsoft.System.Drawing.Printing.PaperKind.Custom;
                    break;
                case "1":
                    page.paperSize = Stimulsoft.System.Drawing.Printing.PaperKind.Letter;
                    break;
                case "8":
                    page.paperSize = Stimulsoft.System.Drawing.Printing.PaperKind.A3;
                    break;
                case "9":
                    page.paperSize = Stimulsoft.System.Drawing.Printing.PaperKind.A4;
                    break;
                case "11":
                    page.paperSize = Stimulsoft.System.Drawing.Printing.PaperKind.A5;
                    break;
                default: {
                    page.paperSize = Stimulsoft.System.Drawing.Printing.PaperKind.A4;
                    break;
                }
            }

            let autoLandScape = Util.getSettingById(230) + "";
            if (isLandscape && autoLandScape === "1") {
                page.orientation = Stimulsoft.Report.Components.StiPageOrientation.Landscape;
            }

            page.margins = new Stimulsoft.Report.Components.StiMargins(leftMargin, rightMargin, topMargin, 0);

            // Add data to datastore
            var dataSet = new Stimulsoft.System.Data.DataSet("jsonData");

            dataSet.readJson(obj.data.dataSource);
            var data = dataSet.tables.getByIndex(0);
            data.tableName = "jsonData";
            report.regData("jsonData", "jsonData", dataSet);
            report.dictionary.synchronize();

            let dataTemp = new Stimulsoft.System.Data.DataTable();
            for (let index = 0; index < obj.data.columns.length; index++) {
                let col = data.columns.list.find(c => c.columnName === obj.data.columns[index].colName);
                if (col) {
                    dataTemp.columns.add(col)
                }
            }
            for (let r = 0; r < data.rows.list.length; r++) {
                dataTemp.rows.add(data.rows.list[r]);
            }
            dataTemp.tableName = data.tableName;
            data = dataTemp;

            var headerBandLogo = report.getComponents().getByName("headerLogo");
            var headerBandLogo1 = report.getComponents().getByName("headerLogo1");
            var headerBandLogo2 = report.getComponents().getByName("headerLogo2");

            if (headerBandLogo != null) {
                if (obj.logo.state + "" === "1" && headerBandLogo != null) {
                    // حالة الترويسة مفعل
                    if (obj.logo.logoBorderType + "" === "1")
                        headerBandLogo.border = new Stimulsoft.Base.Drawing.StiBorder(Stimulsoft.Base.Drawing.StiBorderSides.None);
                    else {
                        switch (obj.logo.logoBorderType + "") {
                            case "1": {
                                headerBandLogo.border = new Stimulsoft.Base.Drawing.StiBorder(Stimulsoft.Base.Drawing.StiBorderSides.All);
                                headerBandLogo.border.style = Stimulsoft.Base.Drawing.StiPenStyle.Solid;
                                break;
                            }
                            case "2": {
                                headerBandLogo.border = new Stimulsoft.Base.Drawing.StiBorder(Stimulsoft.Base.Drawing.StiBorderSides.All);
                                headerBandLogo.border.style = Stimulsoft.Base.Drawing.StiPenStyle.Dot;
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    }
                    headerBandLogo.dockStyle = Stimulsoft.Report.Components.StiDockStyle.Top;
                    let logoImage = report.getComponents().getByName("imgLogo");//
                    let txtCompanyName = report.getComponents().getByName("txtCompanyName");
                    let txtCompanyAddress = report.getComponents().getByName("txtCompanyAddress");

                    let txtCompanyNameAr = report.getComponents().getByName("txtCoNameAr");
                    let txtCompanyAddressAr = report.getComponents().getByName("txtCoAddressAr");

                    let txtCompanyNameEn = report.getComponents().getByName("txtCoNameEn");
                    let txtCompanyAddressEn = report.getComponents().getByName("txtCoAddressEn");

                    if (obj.logo.type + "" === "2") {
                        headerBandLogo1.enabled = false;
                        headerBandLogo2.enabled = false;
                        // نوع الترويسة صورة
                        txtCompanyName.enabled = false;
                        txtCompanyAddress.enabled = false;
                        txtCompanyNameAr.enabled = false;
                        txtCompanyAddressAr.enabled = false;
                        txtCompanyNameEn.enabled = false;
                        txtCompanyAddressEn.enabled = false;
                        if (logoImage != null) {
                            logoImage.horAlignment = Stimulsoft.Base.Drawing.StiHorAlignment.Center;
                            logoImage.vertAlignment = Stimulsoft.Base.Drawing.StiVertAlignment.Center;
                            logoImage.enabled = true;

                            //console.log(`data:image/png;base64,${obj.logo.image}`)

                            try {
                                var sysImage = new Stimulsoft.System.Drawing.Image();
                                sysImage.base64 = obj.logo.image;
                                logoImage.image = sysImage;
                                logoImage.enabled = true;
                                //logoImage.imageData = `data:image/png;base64,${obj.logo.image}`;//obj.logo.image;
                            }
                            catch (e) {
                                console.log(e)
                            }
                            console.log(logoImage.imageData)

                            if (obj.logo.imageStretch) {
                                logoImage.stretch = true;
                            }
                            logoImage.dockStyle = Stimulsoft.Report.Components.StiDockStyle.Fill;
                        }
                    }
                    else {
                        // نوع الترويسة نص
                        // اسم الشركة
                        logoImage.enabled = false;
                        logoImage.canGrow = false;
                        logoImage.canShrink = true;
                        headerBandLogo.enabled = false;
                        if (Util.getSettingById(234) + "" === "1") {
                            headerBandLogo.enabled = false;
                            headerBandLogo2.enabled = false;
                            // حسب لغة التطبيق                            
                            if (txtCompanyName != null) {
                                txtCompanyName.text = obj.logo.companyName;
                                let fontName = txtCompanyName.font.fontFamily.name;
                                if (obj.logo.companyNameFontName)
                                    fontName = obj.logo.companyNameFontName
                                let fontSize = txtCompanyName.font.fontSize;
                                if (obj.logo.companyNameFontSize)
                                    fontSize = obj.logo.companyNameFontSize
                                txtCompanyName.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                                txtCompanyName.dockStyle = Stimulsoft.Report.Components.StiDockStyle.Top;
                            }
                            if (txtCompanyAddress != null) {
                                txtCompanyAddress.text = obj.logo.companyAddress;
                                let fontName = txtCompanyAddress.font.fontFamily.name;
                                let fontSize = txtCompanyAddress.font.fontSize;
                                if (obj.logo.companyAddressFontName)
                                    fontName = obj.logo.companyAddressFontName;
                                if (obj.logo.companyAddressFontSize)
                                    fontSize = obj.logo.companyAddressFontSize;
                                txtCompanyAddress.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                                txtCompanyAddress.dockStyle = Stimulsoft.Report.Components.StiDockStyle.Top;
                            }
                        }
                        else {
                            // باللغتين
                            headerBandLogo.enabled = false;
                            headerBandLogo1.enabled = false;
                            if (txtCompanyName != null) {
                                txtCompanyName.enabled = false;
                                txtCompanyName.canGrow = true;
                                txtCompanyName.canShrink = true;
                            }
                            if (txtCompanyAddress != null) {
                                txtCompanyAddress.enabled = false;
                                txtCompanyAddress.canGrow = true;
                                txtCompanyAddress.canShrink = true;
                            }
                            // الاسم والعنوان عربي
                            if (txtCompanyNameAr != null) {
                                txtCompanyNameAr.enabled = true;
                                txtCompanyNameAr.text = Util.getSettingById("0");
                                let fontName = txtCompanyNameAr.font.fontFamily.name;
                                if (obj.logo.companyNameFontName)
                                    fontName = obj.logo.companyNameFontName
                                let fontSize = txtCompanyNameAr.font.fontSize;
                                if (obj.logo.companyNameFontSize)
                                    fontSize = obj.logo.companyNameFontSize
                                txtCompanyNameAr.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                            }
                            if (txtCompanyAddressAr != null) {
                                txtCompanyAddressAr.enabled = true;
                                txtCompanyAddressAr.text = Util.getSettingById("2");
                                let fontName = txtCompanyAddressAr.font.fontFamily.name;
                                if (obj.logo.companyNameFontName)
                                    fontName = obj.logo.companyNameFontName
                                let fontSize = txtCompanyAddressAr.font.fontSize;
                                if (obj.logo.companyNameFontSize)
                                    fontSize = obj.logo.companyNameFontSize
                                txtCompanyAddressAr.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                            }
                            // الاسم والعنوان انجليزي
                            if (txtCompanyNameEn != null) {
                                txtCompanyNameEn.enabled = true;
                                txtCompanyNameEn.text = Util.getSettingById("1");
                                let fontName = txtCompanyNameEn.font.fontFamily.name;
                                if (obj.logo.companyNameFontName)
                                    fontName = obj.logo.companyNameFontName
                                let fontSize = txtCompanyNameEn.font.fontSize;
                                if (obj.logo.companyNameFontSize)
                                    fontSize = obj.logo.companyNameFontSize
                                txtCompanyNameEn.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                            }
                            if (txtCompanyAddressEn != null) {
                                txtCompanyAddressEn.enabled = true;
                                txtCompanyAddressEn.text = Util.getSettingById("3");
                                let fontName = txtCompanyAddressEn.font.fontFamily.name;
                                if (obj.logo.companyNameFontName)
                                    fontName = obj.logo.companyNameFontName
                                let fontSize = txtCompanyAddressEn.font.fontSize;
                                if (obj.logo.companyNameFontSize)
                                    fontSize = obj.logo.companyNameFontSize
                                txtCompanyAddressEn.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                            }
                        }
                    }
                }
                else {
                    // حالة الترويسة معطل، لا تظهر
                    headerBandLogo.enabled = false;
                    headerBandLogo1.enabled = false;
                    headerBandLogo2.enabled = false;
                }
            }

            // الخط الفاصل بين الترويسة وبيانات التقرير
            let lineHeader = report.getComponents().getByName("lineHeader");
            if (obj.logo && obj.logo.logoBottomBorderType && obj.logo.logoBottomBorderType + "" !== "0") {
                let line = report.getComponents().getByName("logoLine");
                if (line != null) {
                    line.width = page.width;
                    line.canGrow = true;
                    line.size = 2;
                    switch (obj.logo.logoBottomBorderType + "") {
                        case "1":
                            line.style = Stimulsoft.Base.Drawing.StiPenStyle.Dash;
                            break;
                        case "2":
                            line.style = Stimulsoft.Base.Drawing.StiPenStyle.DashDotDot;
                            break;
                        default:
                            line.style = Stimulsoft.Base.Drawing.StiPenStyle.Solid;
                            break;
                    }
                }
            }
            else {
                if (lineHeader != null)
                    lineHeader.enabled = false;
            }

            // طباعة رقم المشتغل المرخص
            let vatRegHeader = report.getComponents().getByName("vatRegHeader");
            let txtVatReg = report.getComponents().getByName("txtVatReg");
            if (!obj.printVatReg) {
                if (vatRegHeader != null)
                    vatRegHeader.enabled = true;
                if (txtVatReg != null)
                    txtVatReg.enabled = false;
            }
            else {
                if (txtVatReg != null) {
                    txtVatReg.text = obj.vatRegText;
                    txtVatReg.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                    let fontName = txtVatReg.font.fontFamily.name;
                    let fontSize = txtVatReg.font.fontSize;
                    if (obj.vatRegFontName)
                        fontName = obj.vatRegFontName;
                    if (obj.vatRegFontName)
                        fontSize = obj.vatRegFontSize;
                    txtVatReg.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                    txtVatReg.dockStyle = Stimulsoft.Report.Components.StiDockStyle.Left;
                }
            }

            // طباعة عنوان التقرير
            let reportTitleHeader = report.getComponents().getByName("reportTitleHeader");
            if (reportTitleHeader != null) {
                let txtReportTitle = report.getComponents().getByName("txtReportTitle");
                if (txtReportTitle != null) {
                    txtReportTitle.text = obj.reportName;
                }
            }

            let showFirstHeader = false; let showSecondHeader = false; let showThirdHeader = false;

            // Create First Header Band سطر الفلاتر الأول
            var headerBandFirst = report.getComponents().getByName("headerFirst");
            if (headerBandFirst != null) {
                if (obj.headers.repeatedEveryPage) {
                    headerBandFirst.printOn = Stimulsoft.Report.Components.StiPrintOnType.AllPages;
                }
                else {
                    headerBandFirst.printOn = Stimulsoft.Report.Components.StiPrintOnType.OnlyFirstPage;
                }
                var txtFirstRight = report.getComponents().getByName("txtFirstHeaderRight");
                var txtFirstCenter = report.getComponents().getByName("txtFirstHeaderCenter");
                var txtFirstLeft = report.getComponents().getByName("txtFirstHeaderLeft");
                if (obj.headers && obj.headers.firstHeader) {
                    if (txtFirstRight != null) {
                        if (obj.headers.firstHeader.rightText && obj.headers.firstHeader.rightText.length > 0) {
                            showFirstHeader = true;
                            txtFirstRight.text = obj.headers.firstHeader.rightText;
                            txtFirstRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                            if (!isRtl)
                                txtFirstRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                            let fontName = txtFirstRight.font.fontFamily.name;
                            let fontSize = txtFirstRight.font.fontSize;
                            if (obj.headers.headersFontName)
                                fontName = obj.headers.headersFontName
                            if (obj.headers.headersFontSize)
                                fontSize = obj.headers.headersFontSize
                            txtFirstRight.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                        }
                        else {
                            //txtFirstRight.enabled = false;
                            txtFirstRight.text = "";
                        }
                    }

                    if (txtFirstCenter != null) {
                        if (obj.headers.firstHeader.centerText && obj.headers.firstHeader.centerText.length > 0) {
                            showFirstHeader = true;
                            txtFirstCenter.text = obj.headers.firstHeader.centerText;
                            let fontName = txtFirstCenter.font.fontFamily.name;
                            let fontSize = txtFirstCenter.font.fontSize;
                            if (obj.headers.headersFontName)
                                fontName = obj.headers.headersFontName
                            if (obj.headers.headersFontSize)
                                fontSize = obj.headers.headersFontSize
                            txtFirstCenter.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                        }
                        else {
                            //txtFirstCenter.enabled = false;
                            txtFirstCenter.text = "";
                        }
                    }

                    if (txtFirstLeft != null) {
                        if (obj.headers.firstHeader.leftText && obj.headers.firstHeader.leftText.length > 0) {
                            showFirstHeader = true;
                            txtFirstLeft.text = obj.headers.firstHeader.leftText;
                            txtFirstLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                            if (!isRtl)
                                txtFirstLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                            let fontName = txtFirstLeft.font.fontFamily.name;
                            let fontSize = txtFirstLeft.font.fontSize;
                            if (obj.headers.headersFontName)
                                fontName = obj.headers.headersFontName
                            if (obj.headers.headersFontSize)
                                fontSize = obj.headers.headersFontSize
                            txtFirstLeft.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                        }
                        else {
                            //txtFirstLeft.enabled = false;
                            txtFirstLeft.text = "";
                        }
                    }
                }
                if (!showFirstHeader) {
                    txtFirstRight.enabled = false;
                    txtFirstCenter.enabled = false;
                    txtFirstLeft.enabled = false;
                    headerBandFirst.enabled = false;
                }
            }

            // Create Second Header Band
            var headerBandSecond = report.getComponents().getByName("headerSecond");
            if (headerBandSecond != null) {
                if (obj.headers.repeatedEveryPage) {
                    headerBandSecond.printOn = Stimulsoft.Report.Components.StiPrintOnType.AllPages;
                }
                else {
                    headerBandSecond.printOn = Stimulsoft.Report.Components.StiPrintOnType.OnlyFirstPage;
                }
                var txtSecondRight = report.getComponents().getByName("txtSecondHeaderRight");
                var txtSecondCenter = report.getComponents().getByName("txtSecondHeaderCenter");
                var txtSecondLeft = report.getComponents().getByName("txtSecondHeaderLeft");
                if (obj.headers && obj.headers.secondHeader) {
                    if (txtSecondRight != null) {
                        if (obj.headers.secondHeader.rightText && obj.headers.secondHeader.rightText.length > 0) {
                            showSecondHeader = true;
                            txtSecondRight.text = obj.headers.secondHeader.rightText;
                            txtSecondRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                            if (!isRtl)
                                txtSecondRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                            let fontName = txtSecondRight.font.fontFamily.name;
                            let fontSize = txtSecondRight.font.fontSize;
                            if (obj.headers.headersFontName)
                                fontName = obj.headers.headersFontName
                            if (obj.headers.headersFontSize)
                                fontSize = obj.headers.headersFontSize
                            txtSecondRight.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                        }
                        else {
                            //txtSecondRight.enabled = false;
                            txtSecondRight.text = "";
                        }
                    }

                    if (txtSecondCenter != null) {
                        if (obj.headers.secondHeader.centerText && obj.headers.secondHeader.centerText.length > 0) {
                            showSecondHeader = true;
                            txtSecondCenter.text = obj.headers.secondHeader.centerText;
                            let fontName = txtSecondCenter.font.fontFamily.name;
                            let fontSize = txtSecondCenter.font.fontSize;
                            if (obj.headers.headersFontName)
                                fontName = obj.headers.headersFontName
                            if (obj.headers.headersFontSize)
                                fontSize = obj.headers.headersFontSize
                            txtSecondCenter.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                        }
                        else {
                            //txtSecondCenter.enabled = false;
                            txtSecondCenter.text = "";
                        }
                    }

                    if (txtSecondLeft != null) {
                        if (obj.headers.secondHeader.leftText && obj.headers.secondHeader.leftText.length > 0) {
                            showSecondHeader = true;
                            txtSecondLeft.text = obj.headers.secondHeader.leftText;
                            txtSecondLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                            if (!isRtl)
                                txtSecondLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                            let fontName = txtSecondLeft.font.fontFamily.name;
                            let fontSize = txtSecondLeft.font.fontSize;
                            if (obj.headers.headersFontName)
                                fontName = obj.headers.headersFontName
                            if (obj.headers.headersFontSize)
                                fontSize = obj.headers.headersFontSize
                            txtSecondLeft.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                        }
                        else {
                            //txtSecondLeft.enabled = false;
                            txtSecondLeft.text = "";
                        }
                    }
                }
                if (!showSecondHeader) {
                    txtSecondRight.enabled = false;
                    txtSecondCenter.enabled = false;
                    txtSecondLeft.enabled = false;
                    headerBandSecond.enabled = false;
                }
            }

            // Create Third Header Band
            var headerBandThird = report.getComponents().getByName("headerThird");
            if (headerBandThird != null) {
                if (obj.headers.repeatedEveryPage) {
                    headerBandThird.printOn = Stimulsoft.Report.Components.StiPrintOnType.AllPages;
                }
                else {
                    headerBandThird.printOn = Stimulsoft.Report.Components.StiPrintOnType.OnlyFirstPage;
                }
                var txtThirdRight = report.getComponents().getByName("txtThirdHeaderRight");
                var txtThirdCenter = report.getComponents().getByName("txtThirdHeaderCenter");
                var txtThirdLeft = report.getComponents().getByName("txtThirdHeaderLeft");
                if (obj.headers && obj.headers.thirdHeader) {
                    if (txtThirdRight != null) {
                        if (obj.headers.thirdHeader.rightText && obj.headers.thirdHeader.rightText.length > 0) {
                            showThirdHeader = true;
                            txtThirdRight.text = obj.headers.thirdHeader.rightText;
                            txtThirdRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                            if (!isRtl)
                                txtThirdRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                            let fontName = txtThirdRight.font.fontFamily.name;
                            let fontSize = txtThirdRight.font.fontSize;
                            if (obj.headers.headersFontName)
                                fontName = obj.headers.headersFontName
                            if (obj.headers.headersFontSize)
                                fontSize = obj.headers.headersFontSize
                            txtThirdRight.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                        }
                        else {
                            //txtThirdRight.enabled = false;
                            txtThirdRight.text = "";
                        }
                    }

                    if (txtThirdCenter != null) {
                        if (obj.headers.thirdHeader.centerText && obj.headers.thirdHeader.centerText.length > 0) {
                            showThirdHeader = true;
                            txtThirdCenter.text = obj.headers.thirdHeader.centerText;
                            let fontName = txtThirdCenter.font.fontFamily.name;
                            let fontSize = txtThirdCenter.font.fontSize;
                            if (obj.headers.headersFontName)
                                fontName = obj.headers.headersFontName;
                            if (obj.headers.headersFontSize)
                                fontSize = obj.headers.headersFontSize;
                            txtThirdCenter.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                        }
                        else {
                            //txtThirdCenter.enabled = false;
                            txtThirdCenter.text = "";
                        }
                    }

                    if (txtThirdLeft != null) {
                        if (obj.headers.thirdHeader.leftText && obj.headers.thirdHeader.leftText.length > 0) {
                            showThirdHeader = true;
                            txtThirdLeft.text = obj.headers.thirdHeader.leftText;
                            txtThirdLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                            if (!isRtl)
                                txtThirdLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                            let fontName = txtThirdLeft.font.fontFamily.name;
                            let fontSize = txtThirdLeft.font.fontSize;
                            if (obj.headers.headersFontName)
                                fontName = obj.headers.headersFontName
                            if (obj.headers.headersFontSize)
                                fontSize = obj.headers.headersFontSize
                            txtThirdLeft.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                        }
                        else {
                            //txtThirdLeft.enabled = false;
                            txtThirdLeft.text = "";
                        }
                    }
                }
                if (!showThirdHeader) {
                    txtThirdRight.enabled = false;
                    txtThirdCenter.enabled = false;
                    txtThirdLeft.enabled = false;
                    headerBandThird.enabled = false;
                }
            }

            ////////////////////////////////////////////////////////////
            // Create HeaderBand
            var headerBand = report.getComponents().getByName("HeaderBand1");
            if (headerBand) {
                headerBand.canShrink = false;
                headerBand.canGrow = true;
                headerBand.height = 0.5;
                headerBand.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
            }

            // Create Databand
            var dataBand = report.getComponents().getByName("DataBand1");
            if (dataBand != null) {
                dataBand.dataSourceName = data.tableName;
                dataBand.height = 0.51;
                dataBand.canGrow = true;
                dataBand.wordWrap = true;
                dataBand.shiftMode = Stimulsoft.Report.Components.StiShiftMode.IncreasingSize;
                dataBand.columnDirection = Stimulsoft.Report.Components.StiColumnDirection.DownThenAcross;
                dataBand.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
            }
            // Create texts
            var pos = 0;
            var columnWidth = Stimulsoft.Base.StiAlignValue.alignToMinGrid(page.width / data.columns.count, 0.1, false);

            let colIndex = 0;
            //calculate max chars for each col
            let columnsLength = new Array(data.columns.list.length);
            if (obj.data.columns) {
                try {
                    for (let row = 0; row < obj.data.dataSource.length; row++) {
                        let index = 0;
                        for (let col = 0; col < obj.data.columns.length; col++) {
                            columnsLength[index] = obj.data.columns[col].columnCaption ? obj.data.columns[col].columnCaption.length : 0;
                            let val = obj.data.dataSource[row][col] + '';
                            if (columnsLength[col] < val.length)
                                columnsLength[index] = val.length;
                            index++;
                        }
                    }
                }
                catch (e) {
                    //alert(e)
                }
            }

            // Check if no columns has (*) width, set the last column width to (*)
            let colWithStarsFound = obj.data.columns.find(e => e.columnWidth + "" === "*" && e.visible === true);
            if (!colWithStarsFound) {

                const lastVisibleColumn = obj.data.columns.reverse().find(col => col.visible === true);
                let index = obj.data.columns.lastIndexOf(lastVisibleColumn);
                obj.data.columns[index].columnWidth = '*';
            }
            let columnWidthTotal = 0;
            // Calculate columns widths for the columns that contains integer or empty string or undefined

            let columnsWidthArr = new Array(data.columns.list.length);//obj.data.columns.length);
            if (isRtl) {
                for (let index = data.columns.list.length - 1; index >= 0; index--) {
                    let dataColumn = data.columns.list[index];
                    columnsWidthArr[index] = 1;
                    let colWidth = 0;//columnWidth;
                    let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                    if (col && col.visible) {
                        if (!col.columnWidth)
                            col.columnWidth = '';
                        if (!isNaN(col.columnWidth) && col.columnWidth !== '') {
                            // number value
                            colWidth = parseFloat(col.columnWidth);
                            columnsWidthArr[index] = colWidth;
                        }
                        else {
                            if (col.columnWidth + "" === "") {
                                colWidth = columnsLength[index] * 0.22;
                                if (colWidth < 1 || isNaN(colWidth)) colWidth = 1;
                                columnsWidthArr[index] = colWidth;
                            }
                        }
                    }
                    else {
                        colWidth = 0;
                        columnsWidthArr[index] = colWidth;
                    }
                    columnWidthTotal += colWidth;
                    colIndex++;
                }
            }
            else {
                for (let index = 0; index < data.columns.list.length; index++) {
                    let dataColumn = data.columns.list[index];
                    columnsWidthArr[index] = 1;
                    let colWidth = 0;
                    let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                    if (col && col.visible) {
                        if (!col.columnWidth)
                            col.columnWidth = '';
                        if (!isNaN(col.columnWidth) && col.columnWidth !== '') {
                            // number value
                            colWidth = parseFloat(col.columnWidth);
                            columnsWidthArr[index] = colWidth;
                        }
                        else {
                            if (col.columnWidth + "" === "") {
                                colWidth = columnsLength[index] * 0.22;
                                if (colWidth < 1 || isNaN(colWidth)) colWidth = 1;
                                columnsWidthArr[index] = colWidth;
                            }
                        }
                    }
                    else {
                        colWidth = 0;
                        columnsWidthArr[colIndex] = colWidth;
                    }
                    columnWidthTotal += colWidth;
                    colIndex++;
                }
            }

            //calculate total count for astric (*) in columns that contains astric (*)
            let starsCount = 0;
            for (let index = data.columns.list.length - 1; index >= 0; index--) {
                let dataColumn = data.columns.list[index];
                let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                if (col && col.visible) {
                    if (!col.columnWidth)
                        col.columnWidth = '';
                    if (isNaN(col.columnWidth) && col.columnWidth.includes('*')) {
                        let length = col.columnWidth.split('*').length;
                        starsCount += length;
                    }
                }
            }
            //calculate columns widths for the columns that contains astric (*)
            let avaiablePageWidth = page.width - columnWidthTotal;
            if (avaiablePageWidth > 0) {
                for (let index = data.columns.list.length - 1; index >= 0; index--) {
                    let dataColumn = data.columns.list[index];
                    let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                    if (col && col.visible) {
                        if (!col.columnWidth)
                            col.columnWidth = '';
                        if (isNaN(col.columnWidth) && col.columnWidth.includes('*')) {
                            let length = col.columnWidth.split('*').length;
                            let width = (avaiablePageWidth / starsCount) * length;
                            if (width < 1)
                                width = 2;
                            columnsWidthArr[index] = width;
                        }
                    }
                }
            }

            let firstGridFooter = false, secondGridFooter = false;
            firstGridFooter = obj.data.columns && obj.data.columns.some(element => {
                if (element.firstSummaryType && element.firstSummaryType.length > 0) {
                    return true;
                }
                return false;
            });
            secondGridFooter = obj.data.columns && obj.data.columns.some(element => {
                if (element.secondSummaryType && element.secondSummaryType.length > 0) {
                    return true;
                }
                return false;
            });

            let cellVerMargin = 0;
            let cellHorMargin = 0;
            if (obj.data.cellVerMargin !== null && obj.data.cellVerMargin !== undefined && obj.data.cellVerMargin !== "") {
                cellVerMargin = parseFloat(obj.data.cellVerMargin)
            }
            if (obj.data.cellHorMargin !== null && obj.data.cellHorMargin !== undefined && obj.data.cellHorMargin !== "") {
                cellHorMargin = parseFloat(obj.data.cellHorMargin)
            }


            // check column width greater than page width Use LandScape to print using Landscape page orientation.
            // في حال كان مجموع عرض الاعمدة اكبر من عرض الورقة الطولية يتم تحويلها الى عرضية
            let sumOfColumnsWidth = columnsWidthArr.reduce(function (a, b) { return a + b; }, 0);
            sumOfColumnsWidth = sumOfColumnsWidth.toFixed(2);
            if (parseFloat(sumOfColumnsWidth) > page.width.toFixed(2)) {
                if (!isLandscape) {
                    this.printReportByDataBand(true);
                    return;
                }
                else {
                    alert($.strings.printError);
                    window.close();
                    return;
                }
            }

            var nameIndex = 1;
            colIndex = 0;
            if (isRtl) {
                for (let index = data.columns.list.length - 1; index >= 0; index--) {
                    let dataColumn = data.columns.list[index];
                    let colCaption = dataColumn.columnName;
                    let colWidth = columnWidth;

                    let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                    if (!col || !col.visible) {
                        continue;
                    }
                    colCaption = col.columnCaption
                    colWidth = columnsWidthArr[index];

                    // Create text on header
                    let headerText = new Stimulsoft.Report.Components.StiText();
                    headerText.clientRectangle = new Stimulsoft.Base.Drawing.RectangleD(pos, 0, colWidth, 1);
                    headerText.text = colCaption;
                    headerText.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Center;
                    headerText.vertAlignment = Stimulsoft.Base.Drawing.StiVertAlignment.Center;
                    headerText.name = "HeaderText_" + nameIndex.toString();
                    headerText.wordWrap = true;
                    headerText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                    headerText.font = new Stimulsoft.System.Drawing.Font(headerText.font.fontFamily.name, 12, Stimulsoft.System.Drawing.FontStyle.Bold);
                    if (headerBand != null)
                        headerBand.components.add(headerText);

                    // Create text on Data Band
                    let dataText = new Stimulsoft.Report.Components.StiText();
                    dataText.clientRectangle = new Stimulsoft.Base.Drawing.RectangleD(pos, 0, colWidth, 0.5);

                    if (dataColumn.columnName === "status" || dataColumn.columnName === "checkBooksStatus") {
                        dataText.text = `{${data.tableName} .${"status_name"}}`;
                    }
                    else if (dataColumn.columnName === "is_allowed") {
                        dataText.text = `{${data.tableName} .${"is_allowed_caption"}}`;
                    }
                    else if (dataColumn.columnName === "is_granted") {
                        dataText.text = `{${data.tableName} .${"is_granted_name"}}`;
                    }
                    else if (dataColumn.columnName === "role_is_granted") {
                        dataText.text = `{${data.tableName} .${"role_is_granted_name"}}`;
                    } else if (dataColumn.dataType.ssTypeName + '' === 'DateTime') {
                        dataText.text = `{${data.tableName} .${dataColumn.columnName}.ToString("dd/MM/yyyy")}`;
                    }
                    else {
                        if (dataColumn.dataType.ssTypeName === "Decimal" || dataColumn.dataType.ssTypeName === "Double" || dataColumn.dataType.ssTypeName === "Float") {
                            // dataText.format = Stimulsoft.Report.Components.StiText.format("{{0}.{1}}");
                        }
                        dataText.text = `{${data.tableName} .${dataColumn.columnName}}`;
                    }
                    dataText.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                    dataText.name = "DataText_" + nameIndex.toString();
                    dataText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                    dataText.margins.right = cellHorMargin;
                    dataText.margins.top = cellVerMargin;


                    // Add highlight
                    // let condition = new Stimulsoft.Report.Components.StiCondition();
                    // condition.backColor = Stimulsoft.System.Drawing.Color.skyBlue;
                    // condition.textColor = Stimulsoft.System.Drawing.Color.black;
                    // condition._borderSides = Stimulsoft.Base.Drawing.StiBorderSides.All;

                    // condition.height = dataText.height;
                    // condition.expression = "(Line & 1) == 1";
                    // condition.item = Stimulsoft.Report.Components.StiFilterItem.Expression;
                    // condition.font = new Stimulsoft.System.Drawing.Font(obj.data.dataFontName, obj.data.dataFontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    // dataText.conditions.add(condition);

                    dataText.wordWrap = true;
                    dataText.canGrow = true;
                    //dataText.hideZeros = true;
                    dataText.growToHeight = true;
                    dataText.textQuality = Stimulsoft.Report.Components.StiTextQuality.Wysiwyg;

                    dataText.font = new Stimulsoft.System.Drawing.Font(obj.data.dataFontName, obj.data.dataFontSize, Stimulsoft.System.Drawing.FontStyle.Regular);

                    pos = pos + colWidth;
                    nameIndex++;
                    colIndex++;
                    if (dataBand != null)
                        dataBand.components.add(dataText);
                }
                colIndex = 0;
                if (firstGridFooter) {
                    // Create First FooterBand for grid
                    let footerBand = new Stimulsoft.Report.Components.StiFooterBand();
                    footerBand.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                    footerBand.height = 0.5;
                    footerBand.name = "FooterBand";
                    page.components.add(footerBand);

                    pos = 0;
                    columnWidth = Stimulsoft.Base.StiAlignValue.alignToMinGrid(page.width / data.columns.count, 0.1, false);
                    nameIndex = 1;
                    for (let index = data.columns.list.length - 1; index >= 0; index--) {
                        let dataColumn = data.columns.list[index];
                        let footerText = new Stimulsoft.Report.Components.StiText();
                        let colWidth = columnWidth;
                        let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                        if (!col || !col.visible)
                            continue;
                        colWidth = columnsWidthArr[index];
                        footerText.clientRectangle = new Stimulsoft.Base.Drawing.RectangleD(pos, 0, colWidth, 0.5);
                        footerText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                        footerText.font = new Stimulsoft.System.Drawing.Font(obj.footers.footerFontName, obj.footers.footerFontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                        footerText.wordWrap = true;
                        footerText.canGrow = true;
                        footerText.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Center;
                        if (col.firstSummaryType + "" === "Sum") {
                            footerText.text = "{SumD(DataBand1,jsonData." + dataColumn.columnName + ")}";
                        }
                        else if (col.firstSummaryType + "" === "Cnt") {
                            footerText.text = "{Count(DataBand1,jsonData." + dataColumn.columnName + ")}";
                        }
                        else {
                            footerText.text = " ";
                        }
                        footerText.name = "FooterText_" + index;
                        pos = pos + colWidth;
                        nameIndex++;
                        colIndex++;
                        footerBand.components.add(footerText);

                    }
                }
                colIndex = 0;
                if (secondGridFooter) {
                    // Create Second FooterBand for grid
                    let footerBand = new Stimulsoft.Report.Components.StiFooterBand();
                    footerBand.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                    footerBand.height = 0.5;
                    footerBand.name = "FooterBandSecond";
                    page.components.add(footerBand);

                    pos = 0;
                    columnWidth = Stimulsoft.Base.StiAlignValue.alignToMinGrid(page.width / data.columns.count, 0.1, false);
                    nameIndex = 1;
                    for (let index = data.columns.list.length - 1; index >= 0; index--) {
                        let dataColumn = data.columns.list[index];
                        let footerText = new Stimulsoft.Report.Components.StiText();
                        let colWidth = columnWidth;
                        let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                        if (!col || !col.visible)
                            continue;
                        colWidth = columnsWidthArr[index];
                        footerText.clientRectangle = new Stimulsoft.Base.Drawing.RectangleD(pos, 0, colWidth, 0.5);
                        footerText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                        footerText.font = new Stimulsoft.System.Drawing.Font(obj.footers.footerFontName, obj.footers.footerFontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                        footerText.wordWrap = true;
                        footerText.canGrow = true;
                        footerText.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Center;
                        if (col.firstSummaryType === "Sum") {
                            footerText.text = "{SumD(DataBand1,jsonData." + dataColumn.columnName + ")}";
                        }
                        else if (col.firstSummaryType === "Cnt") {
                            footerText.text = "{Count(DataBand1,jsonData." + dataColumn.columnName + ")}";
                        }
                        else {
                            footerText.text = " ";
                        }
                        footerText.name = "FooterTextSecond_" + index;
                        pos = pos + colWidth;
                        nameIndex++;
                        colIndex++;
                        footerBand.components.add(footerText);
                    }
                }
            }
            else {
                for (let index = 0; index < data.columns.list.length; index++) {
                    let dataColumn = data.columns.list[index];

                    let colCaption = dataColumn.columnName;
                    let colWidth = columnWidth;
                    let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                    if (!col || !col.visible) {
                        continue;
                    }
                    colCaption = col.columnCaption
                    colWidth = columnsWidthArr[index];

                    // Create text on header
                    let headerText = new Stimulsoft.Report.Components.StiText();
                    headerText.clientRectangle = new Stimulsoft.Base.Drawing.RectangleD(pos, 0, colWidth, 1);
                    headerText.text = colCaption;
                    headerText.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Center;
                    headerText.vertAlignment = Stimulsoft.Base.Drawing.StiVertAlignment.Center;
                    headerText.name = "HeaderText_" + nameIndex.toString();
                    headerText.wordWrap = true;
                    //headerText.brush = new Stimulsoft.Base.Drawing.StiSolidBrush(Stimulsoft.System.Drawing.Color.lightGreen);
                    headerText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                    headerText.font = new Stimulsoft.System.Drawing.Font(headerText.font.fontFamily.name, 12, Stimulsoft.System.Drawing.FontStyle.Bold);
                    if (headerBand != null)
                        headerBand.components.add(headerText);

                    // Create text on Data Band
                    let dataText = new Stimulsoft.Report.Components.StiText();
                    dataText.clientRectangle = new Stimulsoft.Base.Drawing.RectangleD(pos, 0, colWidth, 0.5);
                    if (dataColumn.columnName === "status" || dataColumn.columnName === "checkBooksStatus") {
                        dataText.text = `{${data.tableName} .${"status_name"}}`;
                    }
                    else if (dataColumn.columnName === "is_allowed") {
                        dataText.text = `{${data.tableName} .${"is_allowed_caption"}}`;
                    }
                    else if (dataColumn.columnName === "is_granted") {
                        dataText.text = `{${data.tableName} .${"is_granted_name"}}`;
                    }
                    else if (dataColumn.columnName === "role_is_granted") {
                        dataText.text = `{${data.tableName} .${"role_is_granted_name"}}`;
                    }
                    else if (dataColumn.dataType.ssTypeName + '' === 'DateTime') {
                        dataText.text = `{${data.tableName} .${dataColumn.columnName}.ToString("dd/MM/yyyy")}`;
                    }
                    else {
                        dataText.text = `{${data.tableName} .${dataColumn.columnName}}`;
                    }
                    //dataText.text = `{${data.tableName} .${dataColumn.columnName}}`;
                    dataText.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                    dataText.name = "DataText_" + nameIndex.toString();
                    dataText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                    dataText.margins.left = cellHorMargin;
                    dataText.margins.top = cellVerMargin;

                    // Add highlight
                    // let condition = new Stimulsoft.Report.Components.StiCondition();
                    // condition.backColor = Stimulsoft.System.Drawing.Color.skyBlue;
                    // condition.textColor = Stimulsoft.System.Drawing.Color.black;
                    // condition._borderSides = Stimulsoft.Base.Drawing.StiBorderSides.All;

                    // condition.height = dataText.height;
                    // condition.expression = "(Line & 1) == 1";
                    // condition.item = Stimulsoft.Report.Components.StiFilterItem.Expression;
                    // condition.font = new Stimulsoft.System.Drawing.Font(obj.data.dataFontName, obj.data.dataFontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    // dataText.conditions.add(condition);

                    dataText.wordWrap = true;
                    dataText.canGrow = true;
                    //dataText.hideZeros = true;
                    dataText.growToHeight = true;

                    dataText.font = new Stimulsoft.System.Drawing.Font(obj.data.dataFontName, obj.data.dataFontSize, Stimulsoft.System.Drawing.FontStyle.Regular);

                    pos = pos + colWidth;
                    nameIndex++;
                    colIndex++;

                    if (dataBand != null)
                        dataBand.components.add(dataText);
                }
                colIndex = 0;
                if (firstGridFooter) {
                    // Create First FooterBand for grid
                    let footerBand = new Stimulsoft.Report.Components.StiFooterBand();
                    footerBand.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                    footerBand.height = 0.5;
                    footerBand.name = "FooterBand";
                    page.components.add(footerBand);

                    pos = 0;
                    columnWidth = Stimulsoft.Base.StiAlignValue.alignToMinGrid(page.width / data.columns.count, 0.1, false);
                    nameIndex = 1;
                    for (let index = 0; index <= data.columns.list.length - 1; index++) {
                        let dataColumn = data.columns.list[index];
                        let footerText = new Stimulsoft.Report.Components.StiText();
                        let colWidth = columnWidth;
                        let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                        if (!col || !col.visible)
                            continue;

                        colWidth = columnsWidthArr[index];
                        footerText.clientRectangle = new Stimulsoft.Base.Drawing.RectangleD(pos, 0, colWidth, 0.5);
                        footerText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                        footerText.font = new Stimulsoft.System.Drawing.Font(obj.footers.footerFontName, obj.footers.footerFontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                        footerText.wordWrap = true;
                        footerText.canGrow = true;
                        footerText.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Center;
                        if (col.firstSummaryType === "Sum") {
                            footerText.text = "{SumD(DataBand1,jsonData." + dataColumn.columnName + ")}";
                        }
                        else if (col.firstSummaryType === "Cnt") {
                            footerText.text = "{Count(DataBand1,jsonData." + dataColumn.columnName + ")}";
                        }
                        else {
                            footerText.text = " ";
                        }
                        footerText.name = "FooterText_" + index;
                        pos = pos + colWidth;
                        nameIndex++;
                        colIndex++;
                        footerBand.components.add(footerText);
                    }
                }
                colIndex = 0;
                // Create Second FooterBand for grid
                if (secondGridFooter) {
                    let footerBand = new Stimulsoft.Report.Components.StiFooterBand();
                    footerBand.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                    footerBand.height = 0.5;
                    footerBand.name = "FooterBandSecond";
                    page.components.add(footerBand);

                    pos = 0;
                    columnWidth = Stimulsoft.Base.StiAlignValue.alignToMinGrid(page.width / data.columns.count, 0.1, false);
                    nameIndex = 1;
                    for (let index = 0; index <= data.columns.list.length - 1; index++) {
                        let dataColumn = data.columns.list[index];
                        let footerText = new Stimulsoft.Report.Components.StiText();
                        footerText.clientRectangle = new Stimulsoft.Base.Drawing.RectangleD(pos, 0, columnsWidthArr[index], 0.5);
                        let colWidth = columnWidth;
                        let col = obj.data.columns.find(e => e.colName + "" === dataColumn.columnName + "");
                        if (!col || !col.visible)
                            continue;
                        colWidth = columnsWidthArr[index];
                        footerText.clientRectangle = new Stimulsoft.Base.Drawing.RectangleD(pos, 0, colWidth, 0.5);
                        footerText.border.side = Stimulsoft.Base.Drawing.StiBorderSides.All;
                        footerText.font = new Stimulsoft.System.Drawing.Font(obj.footers.footerFontName, obj.footers.footerFontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                        footerText.wordWrap = true;
                        footerText.canGrow = true;
                        footerText.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Center;
                        if (col.secondSummaryType === "sum") {
                            footerText.text = "{SumD(DataBand1,jsonData." + dataColumn.columnName + ")}";
                        }
                        else if (col.secondSummaryType === "count") {
                            footerText.text = "{Count(DataBand1,jsonData." + dataColumn.columnName + ")}";
                        }
                        else {
                            footerText.text = " ";
                        }
                        footerText.name = "FooterText_" + index;
                        pos = pos + columnsWidthArr[index];
                        nameIndex++;
                        colIndex++;
                        footerBand.components.add(footerText);
                    }
                }
            }

            // Create Footer for page number and company name
            //var footerBandStatic = report.getComponents().getByName("pageLastFooter");
            var txtPageNum = report.getComponents().getByName("txtPageNum");
            if (txtPageNum != null) {
                txtPageNum.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                txtPageNum.text = "{PageNofM}";
                if (!isRtl) {
                    txtPageNum.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                    txtPageNum.text = "{PageNofM}";
                }
                let fontName = txtPageNum.font.fontFamily.name;
                let fontSize = 8;
                txtPageNum.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Bold);
                txtPageNum.dockStyle = Stimulsoft.Report.Components.StiDockStyle.Right;
                txtPageNum.wordWrap = true;
            }
            let txtCompanyFooter = report.getComponents().getByName("txtCompanyFooter");
            if (txtPageNum != null) {
                txtCompanyFooter.text = obj.logo.companyName + " " + pjson.version;
            }
            // Create First Footer Band
            let showFirstFooter = false, showSecondFooter = false, showThirdFooter = false;
            let footerFirst = report.getComponents().getByName("footerFirst");
            if (footerFirst != null) {
                var txtFirstFooterRight = report.getComponents().getByName("txtFirstFooterRight");
                var txtFirstFooterCenter = report.getComponents().getByName("txtFirstFooterCenter");
                var txtFirstFooterLeft = report.getComponents().getByName("txtFirstFooterLeft");
                if (obj.footers && obj.footers.firstFooter) {
                    if (obj.footers.firstFooter.rightText && obj.footers.firstFooter.rightText.length > 0) {
                        showFirstFooter = true;
                        txtFirstFooterRight.text = obj.footers.firstFooter.rightText;
                        txtFirstFooterRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                        if (!isRtl)
                            txtFirstFooterRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                        let fontName = txtFirstFooterRight.font.fontFamily.name;
                        let fontSize = txtFirstFooterRight.font.fontSize;
                        if (obj.headers.headersFontName)
                            fontName = obj.footers.footerFontName
                        if (obj.headers.headersFontSize)
                            fontSize = obj.footers.footerFontSize
                        txtFirstFooterRight.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    }
                    else {
                        //txtFirstFooterRight.enabled = false;
                        txtFirstFooterRight.text = "";
                    }

                    if (obj.footers.firstFooter.centerText && obj.footers.firstFooter.centerText.length > 0) {
                        showFirstFooter = true;
                        txtFirstFooterCenter.text = obj.footers.firstFooter.centerText;
                        txtFirstFooterCenter.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                        if (!isRtl)
                            txtFirstFooterCenter.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                        let fontName = txtFirstFooterCenter.font.fontFamily.name;
                        let fontSize = txtFirstFooterCenter.font.fontSize;
                        if (obj.headers.headersFontName)
                            fontName = obj.footers.footerFontName
                        if (obj.headers.headersFontSize)
                            fontSize = obj.footers.footerFontSize
                        txtFirstFooterCenter.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    }
                    else {
                        //txtFirstFooterCenter.enabled = false;
                        txtFirstFooterCenter.text = "";
                    }

                    if (obj.footers.firstFooter.leftText && obj.footers.firstFooter.leftText.length > 0) {
                        showFirstFooter = true;
                        txtFirstFooterLeft.text = obj.footers.firstFooter.leftText;
                        txtFirstFooterLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                        if (!isRtl)
                            txtFirstFooterLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                        let fontName = txtFirstFooterLeft.font.fontFamily.name;
                        let fontSize = txtFirstFooterLeft.font.fontSize;
                        if (obj.headers.headersFontName)
                            fontName = obj.footers.footerFontName
                        if (obj.headers.headersFontSize)
                            fontSize = obj.footers.footerFontSize
                        txtFirstFooterLeft.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    }
                    else {
                        //txtFirstFooterLeft.enabled = false;
                        txtFirstFooterLeft.text = "";
                    }
                }
                if (!showFirstFooter) {
                    txtFirstFooterRight.enabled = false;
                    txtFirstFooterCenter.enabled = false;
                    txtFirstFooterLeft.enabled = false;
                    footerFirst.enabled = false;
                }
            }

            // Create Second Footer Band
            let footerSecond = report.getComponents().getByName("footerSecond");
            var txtSecondFooterRight = report.getComponents().getByName("txtSecondFooterRight");
            var txtSecondFooterCenter = report.getComponents().getByName("txtSecondFooterCenter");
            var txtSecondFooterLeft = report.getComponents().getByName("txtSecondFooterLeft");
            if (footerSecond != null) {
                if (obj.footers && obj.footers.secondFooter) {
                    if (obj.footers.secondFooter.rightText && obj.footers.secondFooter.rightText.length > 0) {
                        showSecondFooter = true;
                        txtSecondFooterRight.text = obj.footers.secondFooter.rightText;
                        txtSecondFooterRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                        if (!isRtl)
                            txtSecondFooterRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                        let fontName = txtSecondFooterRight.font.fontFamily.name;
                        let fontSize = txtSecondFooterRight.font.fontSize;
                        if (obj.headers.headersFontName)
                            fontName = obj.footers.footerFontName
                        if (obj.headers.headersFontSize)
                            fontSize = obj.footers.footerFontSize
                        txtSecondFooterRight.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    }
                    else {
                        //txtSecondFooterRight.enabled = false;
                        txtSecondFooterRight.text = "";
                    }

                    if (obj.footers.secondFooter.centerText && obj.footers.secondFooter.centerText.length > 0) {
                        showSecondFooter = true;
                        txtSecondFooterCenter.text = obj.footers.secondFooter.centerText;
                        txtSecondFooterCenter.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                        if (!isRtl)
                            txtSecondFooterCenter.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                        let fontName = txtSecondFooterCenter.font.fontFamily.name;
                        let fontSize = txtSecondFooterCenter.font.fontSize;
                        if (obj.headers.headersFontName)
                            fontName = obj.footers.footerFontName
                        if (obj.headers.headersFontSize)
                            fontSize = obj.footers.footerFontSize
                        txtSecondFooterCenter.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    }
                    else {
                        //txtSecondFooterCenter.enabled = false;
                        txtSecondFooterCenter.text = "";
                    }

                    if (obj.footers.secondFooter.leftText && obj.footers.secondFooter.leftText.length > 0) {
                        showSecondFooter = true;
                        txtSecondFooterLeft.text = obj.footers.secondFooter.leftText;
                        txtSecondFooterLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                        if (!isRtl)
                            txtSecondFooterLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                        let fontName = txtSecondFooterLeft.font.fontFamily.name;
                        let fontSize = txtSecondFooterLeft.font.fontSize;
                        if (obj.headers.headersFontName)
                            fontName = obj.footers.footerFontName
                        if (obj.headers.headersFontSize)
                            fontSize = obj.footers.footerFontSize
                        txtSecondFooterLeft.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    }
                    else {
                        //txtSecondFooterLeft.enabled = false;
                        txtSecondFooterLeft.text = "";
                    }
                }
                if (!showSecondFooter) {
                    txtSecondFooterRight.enabled = false;
                    txtSecondFooterCenter.enabled = false;
                    txtSecondFooterLeft.enabled = false;
                    footerSecond.enabled = false;
                }
            }

            // Create Second Footer Band
            let footerThird = report.getComponents().getByName("footerThird");
            var txtThirdFooterRight = report.getComponents().getByName("txtThirdFooterRight");
            var txtThirdFooterCenter = report.getComponents().getByName("txtThirdFooterCenter");
            var txtThirdFooterLeft = report.getComponents().getByName("txtThirdFooterLeft");
            if (footerThird != null) {
                if (obj.footers && obj.footers.thirdFooter) {
                    if (obj.footers.thirdFooter.rightText && obj.footers.thirdFooter.rightText.length > 0) {
                        showThirdFooter = true;
                        txtThirdFooterRight.text = obj.footers.thirdFooter.rightText;
                        txtThirdFooterRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                        if (!isRtl)
                            txtThirdFooterRight.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                        let fontName = txtThirdFooterRight.font.fontFamily.name;
                        let fontSize = txtThirdFooterRight.font.fontSize;
                        if (obj.headers.headersFontName)
                            fontName = obj.footers.footerFontName
                        if (obj.headers.headersFontSize)
                            fontSize = obj.footers.footerFontSize
                        txtThirdFooterRight.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    }
                    else {
                        //txtThirdFooterRight.enabled = false;
                        txtThirdFooterRight.text = "";
                    }

                    if (obj.footers.thirdFooter.centerText && obj.footers.thirdFooter.centerText.length > 0) {
                        showThirdFooter = true;
                        txtThirdFooterCenter.text = obj.footers.thirdFooter.centerText;
                        txtThirdFooterCenter.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                        if (!isRtl)
                            txtThirdFooterCenter.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                        let fontName = txtThirdFooterCenter.font.fontFamily.name;
                        let fontSize = txtThirdFooterCenter.font.fontSize;
                        if (obj.headers.headersFontName)
                            fontName = obj.footers.footerFontName
                        if (obj.headers.headersFontSize)
                            fontSize = obj.footers.footerFontSize
                        txtThirdFooterCenter.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    }
                    else {
                        //txtThirdFooterCenter.enabled = false;
                        txtThirdFooterCenter.text = "";
                    }

                    if (obj.footers.thirdFooter.leftText && obj.footers.thirdFooter.leftText.length > 0) {
                        showThirdFooter = true;
                        txtThirdFooterLeft.text = obj.footers.thirdFooter.leftText;
                        txtThirdFooterLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Right;
                        if (!isRtl)
                            txtThirdFooterLeft.horAlignment = Stimulsoft.Base.Drawing.StiTextHorAlignment.Left;
                        let fontName = txtThirdFooterLeft.font.fontFamily.name;
                        let fontSize = txtThirdFooterLeft.font.fontSize;
                        if (obj.headers.headersFontName)
                            fontName = obj.footers.footerFontName
                        if (obj.headers.headersFontSize)
                            fontSize = obj.footers.footerFontSize
                        txtThirdFooterLeft.font = new Stimulsoft.System.Drawing.Font(fontName, fontSize, Stimulsoft.System.Drawing.FontStyle.Regular);
                    }
                    else {
                        //txtThirdFooterLeft.enabled = false;
                        txtThirdFooterLeft.text = "";
                    }
                }
                if (!showThirdFooter) {
                    txtThirdFooterRight.enabled = false;
                    txtThirdFooterCenter.enabled = false;
                    txtThirdFooterLeft.enabled = false;
                    footerThird.enabled = false;
                }
            }

            ////////////////////////////////////////////////////////////
            viewer.report = report;
            viewer.renderHtml('viewer');
        }
        catch (e) {
            console.log("Exception:: ", e)
        }
    }
}
export default exportDefault;