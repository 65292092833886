import React from 'react';
import styles from './Roles.module.scss';
import DataGrid from '../../../Components/DataGridView/DataGridView';
import RolesAdd from './RolesAdd';
import ButtonsBarLookupList from '../../../Components/ButtonsBarLookupList';
import Dialog from '../../../Components/Dialog';
import Http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import Messages from '../../../Components/Messages';
import Util from '../../../Util';
import Card from '../../../Components/Card';
import Print from '../../../Print';
import * as wjcCore from '@grapecity/wijmo';
const $ = window.$;

export default class RolesList extends React.Component {
  constructor(props) {
    super(props);
    this.doHotKeys = true;
    this.pageInfo = this.setPageInfo();
    this.state = {
      activeIndex: 0,
      loading: true,
      pageInfo: this.pageInfo,
      data: [],
      selectedRows: {},
      id: 0,
      name: '',
      StatusFilter: $.strings.active,
      globalFilter: '',
      isDesktop: window.innerWidth > 800,
      isDesktopForStatus: window.innerWidth > 400,
    };
  }
  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 800, isDesktopForStatus: 400 });
  };

  componentDidMount = async () => {
    if (!Util.checkISHR()) {
      Util.goTo('/Access');
      return;
    }
    if (!Util.checkIsMainUser()) {
      Util.goTo('/Access');
      return;
    }
    let dataTemp = await this.getData(true);
    document.addEventListener('keydown', this.onPageKeyDown);
    document.addEventListener('resize', this.updatePredicate);
    this.setState({ data: dataTemp, loading: false }, () => {
      this.printFilters = this.state;
    });
  };

  onPageKeyDown = async (e) => {
    if (e.ctrlKey && e.keyCode === Util.keyboardKeys.F5) return;
    if (!this.doHotKeys) return;
    this.doHotKeys = false;
    switch (e.keyCode) {
      case Util.keyboardKeys.F5: {
        e.preventDefault();
        await this.getData(false);
        break;
      }
      case Util.keyboardKeys.F6: {
        e.preventDefault();
        this.btnNewClick(e);
        break;
      }
      default: {
      }
    }
    this.doHotKeys = true;
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
    document.removeEventListener('resize', this.updatePredicate);
  };

  popupHasCalled = () => {
    document.removeEventListener('keydown', this.onPageKeyDown);
  };
  popupHasClosed = () => {
    document.addEventListener('keydown', this.onPageKeyDown);
  };

  drawLookup = () => {
    if (this.state.dlgAddVisible) {
      return (
        <RolesAdd
          ref={(el) => (this.addRoles = el)}
          onSearchSuccess={this.props.onSearchSuccess}
          editModeId={this.state.id}
          onAddSuccess={this.onAddSuccess}
          onAddClose={this.onAddClose}
          parent={this.divMain}
        />
      );
    }
  };

  render() {
    const gridStyle = { minHeight: this.state.activeIndex === null || this.state.activeIndex === undefined ? '65vh' : '65vh' };
    let dlgWidth = this.state.isDesktop ? '700px' : '80vh';

    return (
      <div ref={(e) => (this.divMain = e)} className={styles.roles} style={{ minHeight: '80vh' }}>
        <ProgressSpinner loading={this.state.loading} />
        <div className="p-grid">
          <h2 className={styles.title}>{this.state.pageInfo.title}</h2>
        </div>
        {this.state.dlgAddVisible && (
          <Dialog
            showHeader={false}
            visible={this.state.dlgAddVisible}
            modal={true}
            draggable={true}
            contentStyle={{ minHeight: '200px', backgroundColor: '#F4F4F4' }}
            style={{ width: dlgWidth, direction: $.strings.dir }}
            blockScroll={true}
            onHide={this.onClose}
            autoFocus={true}
            closable={false}
            ref={(el) => (this.pnlSearch = el)}
          >
            {this.drawLookup()}
          </Dialog>
        )}
        <div className="p-grid">
          <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
            <Card>
              <ButtonsBarLookupList
                onNew={this.btnNewClick}
                onEdit={this.onRowDoubleClick}
                onRefresh={async (e) => await this.getData(false)}
                onPrint={this.print}
                onExcelExport={this.onExcelExport}
                onErase={this.onErase}
                pageId={this.state.pageInfo.pageId}
                parent={this}
              />
              <Messages innerRef={(el) => (this.messages = el)} />
            </Card>
          </div>
        </div>
        {/*Buttons*/}
        <div className="p-grid">
          <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
            <Card>
              <DataGrid
                style={gridStyle}
                ref={(e) => (this.tblData = e)}
                exportFileName={this.state.pageInfo.title}
                isReport={true}
                theme="default-light"
                idProperty="ser"
                scheme={this.getScheme()}
                dataSource={
                  new wjcCore.CollectionView(this.state.data, {
                    getError: this.getError.bind(this),
                    currentPosition: this.tblData ? this.tblData.flex.selection.row : 0,
                  })
                }
                onRowDoubleClick={(e) => this.onRowDoubleClick(e)}
                pageId={this.state.pageInfo.pageId}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }
  getError(item, propName, parsing = false) {
    switch (propName) {
      default: {
        break;
      }
    }
    return null;
  }
  setPageInfo = () => {
    let pageId;
    let title;

    pageId = 0;
    title = $.strings.roles.title;

    return { pageId: pageId, title: title };
  };

  onClose = (e) => {
    this.setState({ dlgSearchVisible: false });
  };

  onRowDoubleClick = async (e) => {
    let selectedRowvalue = this.tblData.getCurrentRowItem();
    if (selectedRowvalue) {
      this.popupHasCalled();
      this.setState({ dlgAddVisible: true, id: selectedRowvalue.id });
    } else Util.showInfoMsg(this.messages, $.strings.selectRow);
  };

  getDataForSelection = async (id) => {
    let dataTemp;
    this.setState({ loading: true });
    const params = {
      status: Util.getStatusId(this.state.StatusFilter),
      id: id,
      role_id: 0,
    };

    let res = await Http.getJsonAxios(Http.actions.Roles.urlGetRoles, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data._permissionsRoles;
    }
    return dataTemp;
  };

  print = async () => {
    if (!Util.checkIsSuperAdmin()) {
      return;
    }
    if (!this.state.data || this.state.data.length <= 0) {
      Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint);
      return;
    }

    let listOfFilters = [];
    let obj = Print.getReportPrintObject(this.state.pageInfo.title, this.tblData, this.state.data);
    if (this.printFilters) {
      if (this.printFilters.StatusFilter) {
        listOfFilters.push($.strings.status + ': ' + this.printFilters.StatusFilter);
      }
    }

    obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers);

    Print.doPrintReport(obj);
  };


  onExcelExport = async () => {
    this.tblData.excelExport();
  };


  onErase = async () => {
    this.setState({ selectedRows: {}, id: 0, StatusFilter: $.strings.active }, async () => {
      if (this.tblData) this.tblData.clearFilters();
      await this.getData(false);
    });
  };

  getData = async (fromLoad) => {
    let dataTemp = [];
    if (!fromLoad) this.setState({ loading: true });
    const params = {
      id: -1,
    };

    let res = await Http.getJsonAxios(Http.actions.Roles.urlGetRoles, params, this.state.pageInfo.pageId);
    if (res.status === 200) {
      dataTemp = res.data.list;
    }
    if (fromLoad) return dataTemp;
    else {
      this.setState({ data: dataTemp, loading: false }, () => {
        this.printFilters = this.state;
      });
    }
  };

  btnNewClick = (e) => {
    this.popupHasCalled();
    this.setState({ dlgAddVisible: true, id: -1 });
  };

  onAddClose = (doRefreh) => {
    this.popupHasClosed();
    if (doRefreh) this.getData(false);
    this.setState({ dlgAddVisible: false });
  };

  onAddSuccess = (result) => {
    this.popupHasClosed();
    if (result) {
      this.setState({ dlgAddVisible: false });
      this.getData(false);
    }
  };

  getScheme = () => {
    let scheme = {
      name: 'rolesScheme_Table',
      filter: true,
      showFooter: true,
      showFilter: true,
      responsiveColumnIndex: 2,
      columns: [
        { name: 'row_number', header: '#', width: 70, dataType: 'Number', aggregate: 'Cnt', columnWidth: 2 },
        { name: 'id', header: $.strings.roles.id, width: 70, dataType: 'Number', columnWidth: 2 },
        {
          name: 'name',
          header: $.strings.roles.name,
          visible: true,
          visibleInColumnChooser: true,
          width: '*',
          minWidth: 150,
          columnWidth: '*',
        }
      ],
    };
    return scheme;
  };
}
