import React from "react";
import Button from '../../Components/Button'
import http from "../../Api/http";
import ScrollTop from '../ScrollButton';
import ProgressSpinner from "../../Components/ProgressSpinner";
import Card from "../../Components/Card";
import Util from "../../Util";
import Input from '../../Components/Input';
import SiteMap from "../../Components/SiteMap";
import Dropdown from "../../Components/Dropdown";
import Messages from "../../Components/Messages";
import Checkbox from '../../Components/Checkbox';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import SearchEmployees from '../Employees/SearchEmployees';
import DataGrid from '../../Components/DataGridView/DataGridView';
import Calendar from "../../Components/Calendar";
import { Accordion, AccordionTab } from 'primereact/accordion';
import ChangeApprovalUser from "./ChangeApprovalUser";
import WFStepDetails from "./WFStepDetails";
import Print from "../../Print";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class WFTransReport extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            fromDate: new Date(),
            toDate: new Date(),
            mainType: undefined,
            typesList: [],
            subType: undefined,
            subTypesList: [],
            empCode: '',
            approvalEmpCode: '',
            status: undefined,
            statusList: [],
            stepStatus: undefined,
            transID: '',
            data: []
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }

        if (Util.checkISHR()) {
            if (!Util.checkRights(929)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = this.updatePredicate();

        let mainTypeList = await this.getMainType();
        let statusList = await this.getStatus();

        let selectedMainType = mainTypeList.filter(e => e.ID === "-2");
        if (selectedMainType && selectedMainType.length > 0) {
            selectedMainType = selectedMainType[0];
        }

        this.setState({
            typesList: mainTypeList, mainType: selectedMainType, statusList: statusList,
            status: statusList && statusList.length > 0 ? statusList[0] : undefined,
            stepStatus: statusList && statusList.length > 0 ? statusList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    render() {
        const gridStyle = { minHeight: this.state.isDesktop ? '40vh' : '70vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.wfTransReport.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (<div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <ChangeApprovalUser ref={e => this.dlgChangeApprovalUser = e} onSuccess={this.onChangeApprovalUserSuccess} />
            <WFStepDetails ref={e => this.dlgWFStepDetails = e} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} style={{ paddingBottom: '10px' }} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.wfTransReport.fDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.wfTransReport.tDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="mainType"
                                value={this.state.mainType}
                                options={this.state.typesList}
                                caption={$.strings.handleRequests.mainType}
                                onChange={this.handleMainType}
                                optionLabel="Name"
                                placeholder={$.strings.handleRequests.selectMainType}
                                filter={true}
                                filterBy="ID,Name"
                                filterMatchMode="contains"
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="subType"
                                value={this.state.subType}
                                options={this.state.subTypesList}
                                caption={$.strings.handleRequests.subType}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                                placeholder={$.strings.handleRequests.selectSubType}
                                filter={true}
                                filterBy="ID,Name"
                                filterMatchMode="contains"
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                type="text"
                                id="empCode"
                                value={this.state.empCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.wfTransReport.empCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                type="text"
                                id="approvalEmpCode"
                                value={this.state.approvalEmpCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.wfTransReport.approvalEmpCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Dropdown
                                id="status"
                                value={this.state.status}
                                options={this.state.statusList}
                                caption={$.strings.wfTransReport.status}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Dropdown
                                id="stepStatus"
                                value={this.state.stepStatus}
                                options={this.state.statusList}
                                caption={$.strings.wfTransReport.stepStatus}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                type="text"
                                id="transID"
                                value={this.state.transID}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.wfTransReport.transTypeNo}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            style={gridStyle}
                            scheme={this.getScheme()}
                            exportFileName={$.strings.wfTransReport.title}
                            dataSource={this.state.data}
                        />
                    </div>
                </div>
            </Card>
            <ScrollTop />
        </div>
        );
    }
    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ empCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ approvalEmpCode: obj.code })
        }
    }

    handleMainType = (e) => {
        this.setState({ mainType: e.target.value }, () => this.getSubType());
    }
    getSubType = async () => {
        let dataTemp = []
        let obj = {
            type: this.state.mainType && this.state.mainType.ID > 0 ? this.state.mainType.ID : 0
        }
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetFillComboSubTypeByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.transSubTypeList;
        }
        this.setState({ subTypesList: dataTemp, subType: dataTemp && dataTemp.length > 0 ? dataTemp[0] : undefined });
    }
    getMainType = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR"),
            hr: Util.checkISHR(),
            requestType: this.state.mainType && this.state.mainType.ID > 0 ? this.state.mainType.ID : 0,
            transTypeNo: this.state.subType && this.state.subType.ID > 0 ? this.state.subType.ID : 0,
            transSubType: this.state.subType && this.state.subType.ID > 0 ? this.state.subType.Name : '',
            isDelegate: '0'
        }
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetFillComboWorkFLowTyps, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.workflowTypeList;
        }
        return dataTemp
    }

    getStatus = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            withAll: true
        }
        let res = await http.getJsonAxios(http.actions.RequestsStatus.urlGetRequestsStatus, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }

    getData = async (typeID, transID) => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            status: this.state.stepStatus && this.state.stepStatus.ID >= 0 ? this.state.stepStatus.ID : "-1",
            fromDate: this.state.fromDate && (this.state.fromDate + "").length > 0 ? this.state.fromDate : ' ',
            toDate: this.state.toDate && (this.state.toDate + "").length > 0 ? this.state.toDate : ' ',
            transID: this.state.transID && this.state.transID.length > 0 ? this.state.transID : ' ',
            approval: this.state.approvalEmpCode && this.state.approvalEmpCode.length > 0 ? this.state.approvalEmpCode : ' ',
            fromCode: this.state.empCode && this.state.empCode.length > 0 ? this.state.empCode : ' ',
            reason: this.state.subType && this.state.subType.ID > 0 ? this.state.subType.ID : "-2",
            type: this.state.mainType && this.state.mainType.ID > 0 ? this.state.mainType.ID : "-1",
            reqStatus: this.state.status && this.state.status.ID > 0 ? this.state.status.ID : "-1"
        }

        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetShowWFTrans, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state)
    }

    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.wfTransReport.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let dateFilter = "";

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.wfTransReport.fDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.wfTransReport.fDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.mainType) {
                listOfFilters.push($.strings.handleRequests.selectMainType + ": " + this.printFilters.mainType.Name);
            }
            if (this.printFilters.subType) {
                listOfFilters.push($.strings.handleRequests.subType + ": " + this.printFilters.subType.Name);
            }
            if (this.printFilters.empCode) {
                listOfFilters.push($.strings.wfTransReport.empCode + ": " + this.printFilters.empCode);
            }
            if (this.printFilters.approvalEmpCode) {
                listOfFilters.push($.strings.wfTransReport.approvalEmpCode + ": " + this.printFilters.approvalEmpCode);
            }
            if (this.printFilters.staus) {
                listOfFilters.push($.strings.wfTransReport.status + ": " + this.printFilters.status.Name);
            }

            if (this.printFilters.stepStatus) {
                listOfFilters.push($.strings.wfTransReport.stepStatus + ": " + this.printFilters.stepStatus.Name);
            }
            if (this.printFilters.transID) {
                listOfFilters.push($.strings.wfTransReport.transTypeNo + ": " + this.printFilters.transID);
            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    btnViewDetails = (e, ctx) => {
        this.dlgWFStepDetails.show(ctx.item.AprovalDate, ctx.item.RequestNote, ctx.item.WFNotes);
    }

    btnEditApprovalUser = async (e, ctx) => {
        if (ctx.item.StatusID !== "0") {
            alert($.strings.wfTransReport.cantChangeApproval + ' ' + ctx.item.Status)
            return;
        }
        if (ctx.item.ReqStatusID !== "0") {
            alert($.strings.wfTransReport.cantChangeApproval + ' ' + ctx.item.ReqFinalStatus)
            return;
        }
        if (!window.confirm($.strings.wfTransReport.changeApprovalConfirm)) {
            return;
        }
        this.setState({ loading: true })
        let empCode = "", empName = "";
        let success = false;
        let obj = {
            id: ctx.item.ID
        }
        let res = await http.getJsonAxios(http.actions.WFTrans.urlGetShowChangeEvaluator, obj, 0);
        if (res.status === 200) {
            success = res.data.success;
            empCode = res.data.empCode;
            empName = res.data.empName;
        }
        this.setState({ loading: false })
        if (success) {
            let id = ctx.item.ID;
            let reqID = ctx.item.TransID;
            let typeID = ctx.item.Type;
            let approvalEmp = ctx.item.ApprovalCode;
            let Reason = ctx.item.Reason;
            let ownerCode = ctx.item.EmpCode;
            this.dlgChangeApprovalUser.show(id, reqID, typeID, Reason, approvalEmp, empName, ownerCode);
        }
    }
    onChangeApprovalUserSuccess = async () => {
        await this.getData();
    }

    getScheme = () => {
        return {
            name: "personalHolidaysRptScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 5,
            columns: [
                { name: 'rowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                {
                    name: 'btnViewDetails', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.transDetails,
                    iconType: 'view', className: 'info', onClick: (e, ctx) => this.btnViewDetails(e, ctx)
                },
                {
                    name: 'btnEditApprovalUser', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.wfTransReport.changeApprovalTitle,
                    iconType: 'user', className: 'warning', onClick: (e, ctx) => this.btnEditApprovalUser(e, ctx)
                },
                { name: 'TransID', header: $.strings.wfTransReport.transID, width: 100, columnWidth: '2' },
                { name: 'EmpCode', header: $.strings.wfTransReport.empCode, width: 120, visible: false, visibleInColumnChooser: false },
                { name: 'EmpName', header: $.strings.wfTransReport.empName, width: this.state.isDesktop ? 250 : 300, columnWidth: '*' },
                { name: 'Approval', header: $.strings.wfTransReport.approvalUser, width: 120, columnWidth: '2' },
                { name: 'Role', header: $.strings.wfTransReport.approvalRole, width: 130, columnWidth: '2' },
                { name: 'RequestDate', header: $.strings.wfTransReport.requestDate, width: 120, columnWidth: '3' },
                { name: 'typeName', header: $.strings.wfTransReport.mainName, width: 150, columnWidth: '3' },
                { name: 'TransType', header: $.strings.wfTransReport.type, width: 150, columnWidth: '3' },
                { name: 'DaysNo', header: $.strings.wfTransReport.daysNo, width: 100, columnWidth: '2' },
                { name: 'FromDate', header: $.strings.wfTransReport.fromDate, width: 110, columnWidth: '3' },
                { name: 'StartDay', header: $.strings.wfTransReport.startDay, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'ToDate', header: $.strings.wfTransReport.toDate, width: 110, columnWidth: '3' },
                { name: 'EndDay', header: $.strings.wfTransReport.endDay, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'FromTime', header: $.strings.wfTransReport.fromTime, width: 100, columnWidth: '2' },
                { name: 'ToTime', header: $.strings.wfTransReport.toTime, width: 100, columnWidth: '2' },
                { name: 'Status', header: $.strings.wfTransReport.stepStatus, width: 100, columnWidth: '2' },
                { name: 'ReqFinalStatus', header: $.strings.wfTransReport.status, width: 100, columnWidth: '2' },
                { name: 'ID', header: $.strings.wfTransReport.status, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'Type', header: $.strings.wfTransReport.type2, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'Note', header: $.strings.wfTransReport.notes, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'WFNotes', header: $.strings.wfTransReport.wfNotes, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'TransTypeNo', header: $.strings.wfTransReport.transTypeNo, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'Reason', header: $.strings.wfTransReport.reason, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'ApprovalCode', header: $.strings.wfTransReport.approvalUser, visible: false, visibleInColumnChooser: false },
                { name: 'Steps', header: $.strings.wfTransReport.step, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'PerType', header: $.strings.wfTransReport.period, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'AprovalDate', header: $.strings.wfTransReport.approvalDate, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'ReqStatusID', header: $.strings.wfTransReport.status, width: 100, visible: false, visibleInColumnChooser: false }
            ]
        }
    }
}