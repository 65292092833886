import React from "react";
import Button from '../../Components/Button'
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';

const $ = window.$;

export default class ImageViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isDesktop: true,
            visibleDialog: false,
            img: undefined
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();
        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog
                    showHeader={false}
                    footer={footer}
                    visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir, height: '300px' }}
                    modal onHide={this.onHide}>
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <img alt="" src={this.state.img} title={this.props.title} style={{ height: '200px', width: '100%' }} />
                            </div>
                        </div>


                    </div >
                </Dialog >
            </div>
        );
    }
    show = (img) => {
        this.setState({ visibleDialog: true, img: img })
    }

    onHide = () => {
        this.setState({ visibleDialog: false, img: undefined })
    }
}