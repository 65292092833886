import React from "react";
import styles from './WebActivation.module.scss';
import Input from "../../Components/Input";
import InputPassword from "../../Components/Password";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import Checkbox from "../../Components/Checkbox";
import { async } from "regenerator-runtime";
import Dropdown from "../../Components/Dropdown";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class WebActivation extends React.Component {

    constructor(props) {
        super(props);
        this.languagesList = [
            { id: 1, name: $.strings.webActivation.arabic },
            { id: 2, name: $.strings.webActivation.english }
        ]
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: {},
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            empCode: '',
            empName: '',
            empLogin: '',
            empPassword: '',
            isSelfServiceEnabled: false,
            self_service_enabled: false,
            self_service_blocked: false,
            password_change_date: '',
            isPassChanged: false,
            language: undefined
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    handleUserPasswordInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value, isPassChanged: true })
    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;
        let result = true;
        fieldValidationErrors.empPassword = "";
        let _empPassword = this.state.empPassword;
        if (!_empPassword || _empPassword.length <= 0) {
            fieldValidationErrors.empPassword = $.strings.requiredFiled;
            result = false;
        }
        this.setState({ formErrors: fieldValidationErrors });
        return result;
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.save} icon="" className="p-button-success" onClick={this.onSave}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={$.strings.webActivation.title} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '40vw' : '80vw', direction: $.strings.dir }}
                    modal onHide={this.onHide}>
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-3 p-sm-12">
                                <Input
                                    id="empCode"
                                    value={this.state.empCode || ''}
                                    autocomplete="off"
                                    caption={$.strings.webActivation.empCode}
                                    readOnly={true}
                                    disabled
                                    style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                />
                            </div>
                            <div className="p-col-12 p-lg-9 p-sm-12">
                                <Input
                                    id="empName"
                                    value={this.state.empName || ''}
                                    autocomplete="off"
                                    caption={$.strings.webActivation.empName}
                                    readOnly={true}
                                    disabled
                                    style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-4 p-sm-12">
                                <Input
                                    id="empLogin"
                                    value={this.state.empLogin || ''}
                                    autocomplete="off"
                                    caption={$.strings.webActivation.empLogin}
                                // onChange={this.handleUserInput}
                                />
                            </div>
                            <div className="p-col-12 p-lg-4 p-sm-12">
                                <InputPassword
                                    id="empPassword"
                                    value={this.state.empPassword || ''}
                                    autocomplete="off"
                                    caption={$.strings.webActivation.empPassword}
                                    onChange={this.handleUserPasswordInput}
                                    inputId="empPassword"
                                    maxLength="16"
                                    formErrors={this.state.formErrors}
                                    feedback={false}
                                />
                            </div>
                            <div className="p-col-12 p-lg-4 p-sm-12" style={{ alignSelf: 'flex-end', textAlign: 'center' }}>
                                <Button label={$.strings.webActivation.createPassword} onClick={this.onCreatePassword} className={styles.createPassButton} />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Checkbox id="self_service_enabled" caption={$.strings.webActivation.self_service_enabled}
                                    onChange={e => this.setState({ self_service_enabled: e.checked })} checked={this.state.self_service_enabled} />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Checkbox id="self_service_blocked" caption={$.strings.webActivation.self_service_blocked}
                                    onChange={e => this.setState({ self_service_blocked: e.checked })} checked={this.state.self_service_blocked} />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-4 p-sm-12">
                                <Input
                                    id="password_change_date"
                                    value={this.state.password_change_date || ''}
                                    autocomplete="off"
                                    caption={$.strings.webActivation.password_change_date}
                                />
                            </div>
                            <div className="p-col-12 p-xl-4 p-lg-4 p-sm-12">
                                <Dropdown
                                    id="language"
                                    innerRef={e => this.drpLanguage = e}
                                    value={this.state.language}
                                    options={this.languagesList}
                                    caption={$.strings.webActivation.language}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div>
        );
    }

    onCreatePassword = () => {
        let nowDate = new Date();
        let seconds = nowDate.getSeconds();
        let pwd = (seconds + 2) + "" + seconds + "" + (seconds + 5) + "" + (seconds + 1)

        this.setState({ empPassword: pwd, isPassChanged: true })
    }

    show = async (empCode) => {
        let data = await this.getEmployeeData(empCode);
        this.setState({
            visibleDialog: true, empCode: data.code || '', empName: data.name || '', empLogin: data.emp_login ? data.emp_login : (data.code ? data.code : ''),
            self_service_enabled: (data.self_service_enabled === 1 ? true : false) || false,
            self_service_blocked: (data.self_service_blocked === 1 ? true : false) || '',
            isSelfServiceEnabled: (data.self_service_enabled === 1 ? true : false) || false, empPassword: '',
            password_change_date: data.password_change_date ? new Date(data.password_change_date).toLocaleDateString() : '',
            isPassChanged: false, loading: false,
            language: this.languagesList.find(e => e.id + '' === data.empLanguage + ''),
            formErrors: {},
        })
    }

    onHide = () => {
        this.setState({
            empCode: '', empName: '', empLogin: '', self_service_enabled: false,
            self_service_blocked: false, password_change_date: '', isPassChanged: false,
            isSelfServiceEnabled: false, empPassword: '',
            visibleDialog: false, formErrors: {}
        })
    }

    getEmployeeData = async (empCode) => {
        let dataTemp = [];
        this.setState({ loading: true })
        const params = {
            empCode: empCode
        };
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeData, params, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }

    onSave = async () => {
        await this.saveData();
    }

    saveData = async () => {
        if (this.state.isPassChanged && (!this.state.empPassword || this.state.empPassword.length <= 0)) {
            alert($.strings.webActivation.fillPass);
            return;
        }
        if (!this.state.isSelfServiceEnabled && this.state.self_service_enabled && (!this.state.empPassword || this.state.empPassword.length <= 0)) {
            alert($.strings.webActivation.fillPass);
            return;
        }
        this.setState({ loading: true })

        let obj = {
            empCode: this.state.empCode,
            empLogin: this.state.empLogin,
            selfServiceEnabled: this.state.self_service_enabled ? 1 : 0,
            selfServiceBlocked: this.state.self_service_blocked ? 1 : 0,
            isPassChanged: this.state.isPassChanged ? 1 : 0,
            password: this.state.empPassword && this.state.empPassword.length > 0 ? Util.encryptStringToHexa(this.state.empPassword) : '',
            empLang: this.state.language ? this.state.language.id : 1
        }

        let res = await http.getJsonAxios(http.actions.Employee.urlUpdateEmployeeData, obj, 0);

        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    alert(res.data.errorText);
                else
                    alert($.strings.operationDone);
                this.setState({ loading: false, visibleDialog: false })
                return;
            }
            else {
                if (res.data.errorText)
                    alert(res.data.errorText);
                else
                    alert($.strings.operationFailed);
                this.setState({ loading: false, visibleDialog: false })
                return;
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
        }
    }

}