import React from "react";
import styles from "./InputTextArea.module.scss";
import { InputTextarea as PrimeInputTextArea } from 'primereact/inputtextarea';
import Label from "../Label";
import Button from "../Button";
export default class InputTextArea extends React.Component {

  render() {
    return (
      <div style={{ width: '100%' }}>
        {this.props.caption && <Label htmlFor={this.props.htmlFor} isRequired={this.props.isRequired} style={{ width: '100%' }}>
          {this.props.caption}

        </Label>
        }
        {this.props.withgroup && (
          <div className="p-inputgroup">
            <PrimeInputTextArea
              ref={this.props.innerRef}
              className={styles.inputTextArea}
              {...this.props}
              onFocus={this.onFocus}
              autoFocus={this.props.autoFocus}
              style={{ width: '100%' }}
            >
              {this.props.children}
            </PrimeInputTextArea>
            <span
              className="p-inputgroup-addon"
              style={{ verticalAlign: "bottom" }}
            >
              <Button>
                <i className="fa fa-search" />
              </Button>
            </span>

          </div>
        )}
        {!this.props.withgroup && (
          <PrimeInputTextArea
            ref={this.props.innerRef}
            className={styles.inputTextArea}
            {...this.props}
            onFocus={this.onFocus}
            autoFocus={this.props.autoFocus}
            style={{ width: '100%' }}
          >
            {this.props.children}
          </PrimeInputTextArea>
        )}
        {

          this.props.formErrors && this.props.formErrors[this.props.id] &&
          <div className={styles.errorField} >{this.props.formErrors[this.props.id]}</div>

          // <Message severity="error" text={this.props.formErrors[this.props.id]} style={{ width: '100vw' }} />
        }
      </div>
    );
  }

  onFocus = event => {
    event.target.select();
  };
}
