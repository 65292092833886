import React from "react";
import Util from '../../Util';
import styles from './MyStaff.module.scss';
import './DataView.css';
import http from '../../Api/http';
import Card from '../../Components/Card';
import ProgressSpinner from "../../Components/ProgressSpinner";
import Input from "../../Components/Input";
import SiteMap from "../../Components/SiteMap";
import DataTable from "../../Components/Datatable";
import Button from "../../Components/Button";
import Messages from "../../Components/Messages";
import ScrollTop from '../ScrollButton';
import ButtonTemplate from '../../Components/SpecialComponents/EditorsAndTemplate/ButtonTemplate/EditorFunctions';
import OperationDialog from './Operations';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import Checkbox from "../../Components/Checkbox";
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import ButtonsBarLookupList from "../../Components/ButtonsBarLookupList";
import Dropdown from "../../Components/Dropdown";
import { Accordion, AccordionTab } from 'primereact/accordion';
import ClockTransEdit from "../ClockTrans/ClockTransEdit";
import AttendanceTrans from "../FilesAndSettings/AttendanceTrans";
import WebActivation from "../WebActivation/WebActivation";
import { async } from "regenerator-runtime";
import ChangeEmpCode from "./ChangeEmpCode";

const $ = window.$

export default class MyStaff extends React.Component {
    constructor(props) {
        super(props);
        this.speedDialItems = Util.checkISHR() ? this.getSpeedDialItemsHR() : this.getSpeedDialItems();
        this.empStatusList = [
            { id: -1, name: $.strings.all },
            { id: 0, name: $.strings.myStaff.attendOnly },
            { id: 1, name: $.strings.myStaff.absenceOnly },
            { id: 2, name: $.strings.myStaff.vacationOnly },
            { id: 3, name: $.strings.myStaff.sickVacationOnly },
            { id: 4, name: $.strings.myStaff.paidVacationOnly },
            { id: 5, name: $.strings.myStaff.unpaidVacationOnly }
        ]
        this.showDataDirectlyInReports = Util.getSettingById(257) === "1" ? true : false;
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 800,
            empCode: undefined,
            empCodeOrName: undefined,
            getEmpStatus: false,
            data: [],
            layout: 'grid',
            cardView: true,
            sectionsList: [],
            section: undefined,
            empStatus: this.empStatusList[0] || undefined,
            speedDialItems: this.speedDialItems.filter(e => e.visible === true)
        };
        this.scheme = this.getScheme();
    }

    componentDidMount = async () => {
        window.addEventListener("resize", this.updatePredicate)
        let employeesList = [];

        if (this.showDataDirectlyInReports) {
            let employeesListPromise = this.getEmployeesList();
            employeesList = await employeesListPromise;
        }
        let sectionsList = undefined;
        if (Util.checkISHR()) {
            let sectionsPromise = this.getSections();
            sectionsList = await sectionsPromise;
        }
        this.setState({
            data: employeesList || [], sectionsList: sectionsList,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            loading: false
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate)
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }
    render() {
        const siteMapItems = [
            { label: $.strings.myStaff.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" >
                <ProgressSpinner loading={this.state.loading} />
                <ClockTransEdit ref={e => this.dlgClockTransEdit = e} />
                {
                    this.state.dlgAttendnanceTransVisible && <AttendanceTrans ref={e => this.dlgAttendnanceTrans = e} onClose={this.onCloseAttendanceTrans} />
                }

                <WebActivation ref={e => this.dlgWebActivation = e} />
                <ChangeEmpCode ref={e => this.dlgChangeEmployeeCode = e} onSuccess={this.searchEmployee} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    <ButtonsBarLookupList onRefresh={this.searchEmployee} />
                    <Messages innerRef={(el) => this.messages = el} style={{ paddingBottom: '10px' }} />
                </Card>
                <Accordion multiple activeIndex={[0, 1]} >
                    <AccordionTab header={$.strings.filterCaption}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                        <Input
                                            id="empCodeOrName"
                                            value={this.state.empCodeOrName}
                                            caption={$.strings.myStaff.empCodeOrName}
                                            onChange={this.handleUserInput}
                                            onKeyDown={this.onKeyDown}
                                        />
                                    </div>
                                    {
                                        Util.checkISHR() &&
                                        <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                            <Dropdown
                                                id="section"
                                                value={this.state.section}
                                                options={this.state.sectionsList}
                                                caption={$.strings.absence.section}
                                                onChange={this.handleUserInput}
                                                optionLabel="Name"
                                                filter
                                            />
                                        </div>
                                    }
                                    <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12">
                                        <Dropdown
                                            id="empStatus"
                                            value={this.state.empStatus}
                                            options={this.empStatusList}
                                            caption={$.strings.myStaff.empStatus}
                                            onChange={this.handleUserInput}
                                            optionLabel="name"
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-3 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                        <Checkbox id="getEmpStatus" caption={$.strings.myStaff.getEmpStatus}
                                            onChange={e => this.setState({ getEmpStatus: e.checked })}
                                            checked={this.state.getEmpStatus} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header={$.strings.data}>
                        {
                            !this.state.cardView &&
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                    <DataTable
                                        isReport={false}
                                        ref={ref => (this.tblData = ref)}
                                        scrollHeight={this.state.isDesktop ? "38vh" : ''}
                                        scheme={this.scheme}
                                        value={this.state.data}
                                        exportFilename={$.strings.links.home}
                                        paginator
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                        rows={10}
                                        showContextMenu={false}
                                        responsive={true}
                                    />
                                </div>
                            </div>
                        }
                        {
                            this.state.cardView &&
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                    <div className="dataview">
                                        <div className="card">
                                            <DataView
                                                value={this.state.data}
                                                layout={this.state.layout}
                                                itemTemplate={this.itemTemplate}
                                                paginator
                                                rows={6}
                                                currentPageReportTemplate=" ({first} - {last}) of ({totalRecords})"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <OperationDialog ref={e => this.dlgOperations = e} onSuccess={this.searchEmployee} />

                    </AccordionTab>
                </Accordion>
                <ScrollTop />
            </div>
        )
    }
    itemTemplate = (item) => {
        if (!item) {
            return;
        }
        if (this.state.layout === 'list')
            return this.renderListItem(item);
        else if (this.state.layout === 'grid')
            return this.renderGridItem(item);
    }
    renderListItem = (data) => {
        return (
            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                <div className="object-list-item">
                    <img src={`data:image/jpeg;base64,${data.image}`} alt={data.name}
                        onClick={!this.state.isDesktop ? () => this.openMenu(data.code, data.name) : undefined} />
                    <div className="object-list-detail">
                        <div className="object-name">{data.name}</div>
                        <i className=""></i><span className="object-category">{data.code}</span>
                        <div className="object-description">{data.jobName}</div>
                    </div>
                    <div className={`${"object-list-action"} ${"speeddial-circle"} ${"speeddial-tooltip"}`}>
                        {this.state.isDesktop && this.state.speedDialItems && this.state.speedDialItems.length > 0 &&
                            <div className="object-list-action">
                                <Tooltip target=".speeddial-tooltip .p-speeddial-action" position="top" />
                                <SpeedDial model={this.state.speedDialItems} radius={80} showIcon="pi pi-bars" direction="up-right" type="circle"
                                    buttonStyle={{ height: '28px' }} />
                            </div>
                        }
                        {!this.state.isDesktop && <Button icon="pi pi-bars" label="" onClick={() => this.openMenu(data.code, data.name)}></Button>}
                        {/*<span className="object-price">${data.code}</span>
                        <Button icon="pi pi-bars" label="" onClick={() => this.openMenu(data.code, data.name)}></Button>
                        <span className={`object-badge status-${data.code.toLowerCase()}`}>{data.code}</span> */}
                    </div>
                </div>
            </div >
        );
    }

    renderGridItem = (data) => {

        return (
            <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                <div className="object-grid-item card">
                    {/* <div className="object-grid-item-top">
                        <div>
                            <i className="pi pi-tag object-category-icon"></i>
                            <span className="object-category">{data.code}</span>
                        </div>
                        <span className={`object-badge status-${data.code.toLowerCase()}`}>{data.code}</span>
                    </div> */}
                    <div className="object-grid-item-content">
                        <img src={`data:image/jpeg;base64,${data.image}`} alt={data.name} style={{ border: this.getImageBorder(data.empStatus) }}
                            onClick={!this.state.isDesktop ? () => this.openMenu(data.code, data.name) : undefined} />
                        <div className="object-name">{data.name}</div>
                        <div className="object-category">{data.code}</div>
                        <div className="object-description">{data.jobName}</div>
                    </div>
                    <div className={`${"object-grid-item-bottom"} ${"speeddial-circle"} ${"speeddial-tooltip"}`} >
                        {/*                        
                        <span className="object-price">${data.code}</span>*/}
                        {

                            this.state.isDesktop && this.state.speedDialItems && this.state.speedDialItems.length > 0 && (<div className="object-grid-item-bottom">
                                <Tooltip target=".speeddial-tooltip .p-speeddial-action" position="top" />
                                <SpeedDial model={this.state.speedDialItems} radius={80} showIcon="pi pi-bars" direction="up-right"
                                    buttonStyle={{ height: '28px' }} type="circle" onClick={e => this.setState({ empCode: data.code, empName: data.name })}
                                />
                            </div>
                            )
                        }
                    </div>
                </div>
            </div >
        );
    }

    getImageBorder = (empStatus) => {

        let border = '';
        switch (empStatus) {
            case 1:
                border = '3px solid Green';
                break;
            case 2:
                border = '3px solid Orange';
                break;
            case 3:
                border = '3px solid Blue';
                break;
            case 4:
                border = '3px solid Red';
                break;
            default: {
                border = '0px solid';
                break
            }
        }
        return border;
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }

    getEmployeesList = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: !this.state.empCodeOrName || this.state.empCodeOrName.length <= 0 ? '' : this.state.empCodeOrName,
            isHr: Util.checkISHR(),
            getEmpStatus: this.state.getEmpStatus ? this.state.getEmpStatus : false,
            section: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : "-1",
            empStatus: this.state.empStatus && this.state.empStatus.id >= 0 ? this.state.empStatus.id : -1,
        }

        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;
        }
        return dataTemp
    }

    showSplitButtons = (event) => {
        this.menu.toggle(event);
    }

    onKeyDown = async (e) => {
        if (e.keyCode === 13)
            await this.searchEmployee()
    }

    searchEmployee = async () => {
        this.setState({ loading: true })
        let employeesListPromise = this.getEmployeesList();
        let employeesList = await employeesListPromise;

        this.setState({ data: employeesList || [], loading: false });
    }

    openMenu = (code, name) => {
        this.dlgOperations.show(code, name)
    }

    getScheme = () => {
        return {
            name: "myStaffScheme_Table",
            filter: false,
            sigma: false,
            columns: [
                {
                    position: 0,
                    field: "rowNumber",
                    header: "##",
                    filterable: false,
                    width: "100px",
                    sortable: false,
                },
                {
                    position: 1,
                    header: '',
                    sortable: false,
                    width: '70px',
                    body: (rowData, props) => ButtonTemplate(rowData, props, 'menu', 'menu', event => this.openMenu(rowData, props))
                },
                {
                    position: 2,
                    field: "code",
                    columnKey: "2",
                    header: $.strings.myStaff.empCode,
                    sortable: false,
                    width: '150px',
                    filterable: false,
                },
                {
                    position: 3,
                    field: "name",
                    columnKey: "3",
                    header: $.strings.myStaff.empName,
                    sortable: false,
                    filterable: false,
                },
            ]
        };
    };

    attendanceReport = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo('/Reports/AttendanceReport/' + tempCode);
    }
    vacBalances = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo('/Reports/VacBalance/' + tempCode);
    }
    specialLeave = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/SpecialLeave/" + tempCode + "/Add");
    }
    vacLeave = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/Vacations/" + tempCode + "/Add");
    }
    overTime = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/OverTime/" + tempCode + "/Add");
    }
    memo = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/Memos/" + tempCode + "/Add");
    }
    workLeave = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("../Requests/WorkLeave/" + tempCode + "/Add");
    }
    noFingerPrint = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Requests/FingerPrint/" + tempCode + "/Add");
    }
    empInfo = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Reports/EmpInfo/" + tempCode);
    }
    salarySlip = () => {
        let tempCode = Util.encryptStringToHexa(this.state.empCode);
        Util.goTo("/Reports/SalarySlip/" + tempCode);
    }
    editClockTrans = () => {
        this.dlgClockTransEdit.show(this.state.empCode, new Date(), 0);
    }
    addAttendanceTrans = () => {
        this.setState({ dlgAttendnanceTransVisible: true }, () => {
            setTimeout(() => {
                this.dlgAttendnanceTrans.show(0, this.state.empCode, this.state.empName);
            }, 0);
        })
    }
    onCloseAttendanceTrans = () => {
        this.setState({ dlgAttendnanceTransVisible: false });
    }
    webActivation = async () => {
        await this.dlgWebActivation.show(this.state.empCode);
    }
    changeEmployeeCode = async () => {
        await this.dlgChangeEmployeeCode.show(this.state.empCode, this.state.empName);
    }

    getSpeedDialItems = () => {
        return [
            {
                label: $.strings.myStaff.attendanceReport,
                icon: 'pi pi-clock',
                command: this.attendanceReport,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(652)
            },
            {
                label: $.strings.myStaff.vacBalances,
                icon: 'pi pi-refresh',
                command: this.vacBalances,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(658)
            },
            {
                label: $.strings.myStaff.specialLeave,
                icon: 'pi pi-plus',
                command: this.specialLeave,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(782)
            },
            {
                label: $.strings.myStaff.vacLeave,
                icon: 'pi pi-plus',
                command: this.vacLeave,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(781)
            },
            {
                label: $.strings.myStaff.overTime,
                icon: 'pi pi-plus',
                command: this.overTime,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(783)
            },
            {
                label: $.strings.myStaff.memo,
                icon: 'pi pi-plus',
                command: this.memo,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(784)
            },
            {
                label: $.strings.myStaff.workLeave,
                icon: 'pi pi-plus',
                command: this.workLeave,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(786)
            },
            {
                label: $.strings.myStaff.noFingerPrint,
                icon: 'pi pi-plus',
                command: this.noFingerPrint,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(785)
            },
            {
                label: $.strings.myStaff.empInfo,
                icon: 'pi pi-user',
                command: this.empInfo,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(659)
            },
            {
                label: $.strings.myStaff.salarySlip,
                icon: 'pi pi-dollar',
                command: this.salarySlip,
                visible: (Util.checkISManager() || Util.checkISDelegated()) && Util.checkRights(662)
            }
        ];
    }

    getSpeedDialItemsHR = () => {
        return [
            {
                label: $.strings.myStaff.vacBalances,
                icon: 'pi pi-refresh',
                command: this.vacBalances,
                visible: Util.checkISHR() && Util.checkRights(715)
            },
            {
                label: $.strings.myStaff.editClockTrans,//'تعديل بيانات الساعة',
                icon: 'pi pi-clock',
                command: this.editClockTrans,
                visible: Util.checkISHR() && Util.checkRights(702)
            },
            {
                label: $.strings.myStaff.insertTrans,//'ادخال اجازة',
                icon: 'pi pi-plus',
                command: this.addAttendanceTrans,//() => alert($.strings.myStaff.insertTrans),
                visible: Util.checkISHR() && Util.checkRights(700)
            },
            {
                label: $.strings.myStaff.attendanceReport,
                icon: 'pi pi-clock',
                command: this.attendanceReport,
                visible: Util.checkISHR() && Util.checkRights(710)
            },
            {
                label: $.strings.myStaff.salarySlip,
                icon: 'pi pi-dollar',
                command: this.salarySlip,
                visible: Util.checkISHR() && Util.checkRights(736)
            },
            {
                label: $.strings.myStaff.empInfo,
                icon: 'pi pi-user',
                command: this.empInfo,
                visible: Util.checkISHR() && Util.checkRights(659)
            },
            // {
            //     label: 'تعديل الموظف',
            //     icon: 'pi pi-pencil',
            //     command: () => alert('تعديل الموظف'),
            //     visible: Util.checkISHR() && Util.checkRights(782)
            // },
            {
                label: $.strings.webActivation.title,
                icon: 'pi pi-pencil',
                command: this.webActivation,
                visible: Util.checkISHR() //&& Util.checkRights(782)
            },
            {
                label: $.strings.changeEmpCode.title,
                icon: 'pi pi-pencil',
                command: this.changeEmployeeCode,
                visible: Util.checkISHR() && $.license.SelfService_Module && Util.checkRights(968)
            }
        ];
    }
}