import React from "react";
import Card from '../../Components/Card';
import SiteMap from '../../Components/SiteMap';
import Button from '../../Components/Button';
import Util from "../../Util";
import ScrollTop from '../ScrollButton';
import http from '../../Api/http';
import Messages from '../../Components/Messages'
import WFTrans from '../WFTrans';
import Dropdown from "../../Components/Dropdown";
import Approval from './Approval';
import ProgressSpinner from '../../Components/ProgressSpinner';
import DataGrid from "../../Components/DataGridView/DataGridView";
import { Accordion, AccordionTab } from 'primereact/accordion';
import DMVacDecision from "../Vacations/DMVacDecision";
import VacEmpDetails from "../Vacations/VacEmpDetails";
import Input from "../../Components/Input";
import SearchEmployees from "../Employees/SearchEmployees";
import * as wjcCore from '@grapecity/wijmo';

const $ = window.$

export default class HandleRequests extends React.Component {
    constructor(props) {
        super(props);
        this.focusedRowTemp = null;
        this.rowIndex = -1;
        this.colIndex = -1;
        this.scrollPosition = { x: 0, y: 0 }
        this.state = {
            loading: true,
            data: [],
            mainType: undefined,
            section: undefined,
            sectionsList: [],
            empCode: undefined,
            empName: undefined,
            isDesktop: window.innerWidth > 1250
        };
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        let _isDesktop = window.innerWidth > 1250;
        let mainTypeList = await this.getMainType();
        let selectedMainType = mainTypeList.filter(e => e.ID === "-2");
        if (selectedMainType && selectedMainType.length > 0) {
            selectedMainType = selectedMainType[0];
        }
        this.sectionsList = await this.getSections() || [];
        window.addEventListener("resize", this.updatePredicate);
        this.setState({
            typesList: mainTypeList || [], mainType: selectedMainType, data: [], isDesktop: _isDesktop,
            loading: false, section: this.sectionsList && this.sectionsList.length > 0 ? this.sectionsList[0] : undefined
        }, () => this.getRequests());
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 1250 });
    }

    handleUserInput = (e) => {
        this.empCodeChanged = true;
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    getEmployeeFilter = () => {
        let employee = "";
        if (!this.empCodeChanged) {
            if (this.state.empCode)
                employee += this.state.empCode;
            if (employee.length > 0)
                employee += " / ";
            if (this.state.empName)
                employee += this.state.empName;
        }
        else {
            employee = this.state.empCode
        }
        return employee;
    }
    render() {
        const gridStyle = { minHeight: this.state.isDesktop ? '42vh' : '100vh' }

        let siteMapItems = [{ label: $.strings.links.handleRequests.menu }];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" style={{ minHeight: '70vh' }}>
                <ProgressSpinner loading={this.state.loading} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                    <SearchEmployees ref={e => this.dlgSearchEmployee = e} onSuccess={this.showEmpCode} />
                </Card>
                <Accordion activeIndex={0}>
                    <AccordionTab header={$.strings.filterCaption} >
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12">
                                <Dropdown
                                    id="mainType"
                                    innerRef={e => this.drpMainType = e}
                                    value={this.state.mainType}
                                    options={this.state.typesList}
                                    caption={$.strings.handleRequests.mainType}
                                    onChange={this.handleMainType}
                                    optionLabel="Name"
                                    placeholder={$.strings.handleRequests.selectMainType}
                                    filter={true}
                                    filterBy="ID,Name"
                                    filterMatchMode="contains"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12">
                                <Dropdown
                                    id="subType"
                                    innerRef={e => this.drpSubType = e}
                                    value={this.state.subType}
                                    options={this.state.subTypesList}
                                    caption={$.strings.handleRequests.subType}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                    placeholder={$.strings.handleRequests.selectSubType}
                                    filter={true}
                                    filterBy="ID,Name"
                                    filterMatchMode="contains"
                                />
                            </div>
                            {
                                Util.checkISHR() &&
                                <div className="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12">
                                    <Dropdown
                                        id="section"
                                        innerRef={e => this.drpSection = e}
                                        value={this.state.section}
                                        options={this.sectionsList}
                                        caption={$.strings.handleRequests.section}
                                        onChange={this.handleUserInput}
                                        optionLabel="Name"
                                        placeholder={$.strings.handleRequests.selectSection}
                                        filter={true}
                                        filterBy="ID,Name"
                                        filterMatchMode="contains"
                                    />
                                </div>
                            }
                            {
                                Util.checkISHR() &&
                                <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtEmpCode = el}
                                        keyfilter="pint"
                                        id="empCode"
                                        value={this.getEmployeeFilter()}
                                        autocomplete="off"
                                        onChange={this.handleUserInput}
                                        caption={$.strings.handleRequests.empCode}
                                        withgroup={true}
                                        btn1icon="search"
                                        btn1event={this.getEmpCode}
                                    />
                                </div>
                            }
                            <div className="pp-col-12 p-xl-1 p-lg-4 p-md-4 p-sm-12" style={{ alignSelf: 'flex-end', marginBottom: '5px' }}>
                                <Button onClick={this.getRequests} label={$.strings.handleRequests.showData} />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <Messages innerRef={(el) => this.messages = el} />
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <DataGrid
                                style={gridStyle}
                                ref={e => this.gridData = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="serial"
                                scheme={this.getSchemeDataGrid()}
                                dataSource={
                                    new wjcCore.CollectionView(this.state.data, {
                                        getError: undefined,
                                        //currentPosition: Util.setCurrentRow(this.gridData),
                                    })
                                }
                                exportFileName={$.strings.news.NewsReportTitle}
                            // selectionChanged={(s) => this.onSelectionChange(s)}
                            />
                        </div>
                    </div>
                </Card>
                <ScrollTop />
                <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
                <Approval ref={e => this.dlgApproval = e} onSuccess={this.getRequests} />
                <DMVacDecision ref={e => this.dlgDMVacDecision = e} onSuccess={this.onDMAccept} />
                <VacEmpDetails ref={e => this.dlgVacEmpDetails = e} />
            </div >
        );
    }

    getEmpCode = async () => {
        await this.dlgSearchEmployee.show();
    }
    showEmpCode = (obj) => {
        if (obj) {

            this.empCodeChanged = false;
            this.setState({ empCode: obj.code, empName: obj.name })
        }
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }

    handleMainType = (e) => {
        this.setState({ mainType: e.target.value }, () => this.getSubType());
    }
    getSubType = async () => {
        let dataTemp = []
        let obj = {
            type: this.state.mainType && this.state.mainType.ID > 0 ? this.state.mainType.ID : 0
        }
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetFillComboSubTypeByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.transSubTypeList;
        }
        this.setState({ subTypesList: dataTemp, subType: dataTemp && dataTemp.length > 0 ? dataTemp[0] : undefined });
    }
    getMainType = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR"),
            hr: Util.checkISHR(),
            requestType: this.state.mainType && this.state.mainType.ID > 0 ? this.state.mainType.ID : 0,
            transTypeNo: this.state.subType && this.state.subType.ID > 0 ? this.state.subType.ID : 0,
            transSubType: this.state.subType && this.state.subType.ID > 0 ? this.state.subType.Name : '',
            isDelegate: '0'
        }
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetFillComboWorkFLowTyps, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.workflowTypeList;
        }
        return dataTemp
    }
    getRequests = async () => {
        this.scrollPosition = this.gridData.flex.scrollPosition;
        this.setState({ loading: true }, () => {
            Util.gridColumnStartEditing(this.gridData, this.rowIndex, this.colIndex);
            this.gridData.flex.scrollPosition = Object.assign({}, this.scrollPosition);
        })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR"),
            hr: Util.checkISHR(),
            requestType: this.state.mainType && this.state.mainType.ID > 0 ? this.state.mainType.ID : -2,
            transTypeNo: '',
            transSubType: this.state.subType && this.state.subType.ID >= 0 ? this.state.subType.ID : -2,
            isDelegate: '0',
            section: this.state.section ? this.state.section.ID : '-1',
            empCode: this.state.empCode ? this.state.empCode : ''
        }
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetEmployeesRequests, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.rightList;
            if (dataTemp && dataTemp.length > 0) {
                dataTemp.forEach(function (obj) {
                    obj.RequestDate = new Date(obj.RequestDate);
                    obj.FromDate = new Date(obj.FromDate);
                    obj.ToDate = new Date(obj.ToDate);
                });
            }
        }
        this.setState({ data: dataTemp, loading: false }, () => {
            Util.gridColumnStartEditing(this.gridData, this.rowIndex, this.colIndex);
            this.gridData.flex.scrollPosition = Object.assign({}, this.scrollPosition);
        })
    }

    onSelectionChange = async (s) => {
    };

    saveGridState = () => {
        this.gridData.saveGridState();
    }

    viewTemplate = (rowData, column) => {
        return <>
            <Button icon="pi pi-id-card" label=" " className="p-button-warning" tooltip={$.strings.transDetails} onClick={e => this.wfTransDetails(rowData)}>
            </Button>
            <Button icon="pi pi-eye" label=" " className="p-button-info" tooltip={$.strings.view} onClick={e => this.viewRequest(rowData)}>
            </Button>
            {
                !Util.checkISHR() &&
                <Button icon="pi pi-check-square" label=" " className="p-button-success" tooltip={$.strings.handleRequests.handle} onClick={e => this.handleRequests(rowData)} />
            }
            {
                Util.checkISHR() &&
                <Button icon="pi pi-check-square" label=" " className="p-button-success" tooltip={$.strings.handleRequests.handle} onClick={e => this.handleRequestsHR(rowData)} />
            }
        </>
    }

    wfTransDetails = async (data) => {
        this.rowIndex = this.gridData.flex.selection.row;
        this.colIndex = this.gridData.flex.selection.col;
        this.scrollPosition = this.gridData.flex.scrollPosition;

        let id = Util.encryptStringToHexa(data.TransID)
        await this.wfTransDlg.show(data.Type, id)
    }

    viewRequest = (data) => {
        this.rowIndex = this.gridData.flex.selection.row;
        this.colIndex = this.gridData.flex.selection.col;
        this.scrollPosition = this.gridData.flex.scrollPosition;

        let id = Util.encryptStringToHexa(data.TransID);
        switch (data.Type + "") {
            case "1": {
                // طلبات الاجازات والمغادرات
                if (data.TransTypeNo + "" === "6" || data.TransTypeNo + "" === "7" || data.TransTypeNo + "" === "8" || data.TransTypeNo + "" === "9") {
                    // اجازات
                    if (data.PerType + "" === "0") {
                        Util.goToNewTab("../Requests/VacLessDay/" + id)
                    }
                    else {
                        Util.goToNewTab("../Requests/Vacations/" + id)
                    }
                }
                else if (data.TransTypeNo + "" === "5") {
                    // عمل اضافي
                    Util.goToNewTab("../Requests/OverTime/" + id)
                }
                else if (data.TransTypeNo + "" === "12") {
                    //  مغادرة خاصة مع خصم
                    Util.goToNewTab("../Requests/SpecialLeave/" + id)
                }
                else if (data.TransTypeNo + "" === "2") {
                    // مغادرة رسمية بدون خصم 
                    Util.goToNewTab("../Requests/WorkLeave/" + id)
                }
                break;
            }
            case "2": {
                // اخلاء الطرف
                break;
            }
            case "3": {
                // اقرار عودة
                break;
            }
            case "4": {
                // تذاكر السفر
                break;
            }
            case "5": {
                // الاجراءات الادارية
                break;
            }
            case "6": {
                // تصريح تنقل
                break;
            }
            case "7": {
                //المراسلات والمذكرات الادارية
                Util.goToNewTab("../Requests/Memos/" + id)
                break;
            }
            case "8": {
                // الاجازات المقترحة
                Util.goToNewTab("../Requests/ExpectedVacations/" + id)
                break;
            }
            case "9": {
                // التدريب
                break;
            }
            case "10": {
                // رحلة عمل
                break;
            }
            case "11": {
                // مخالصة رحلة عمل
                break;
            }
            case "12": {
                // عدم وجود بصمة
                Util.goToNewTab("../Requests/FingerPrint/" + id)
                break;
            }
            default: {
                break;
            }
        }
    }

    deleteAbsentRequest = async (reqId) => {
        this.setState({ loading: true })
        let obj = {
            id: reqId
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetDeleteHolidayReq, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                Util.showSuccessMsg(this.messages, res.data.errorText)
                let dataPromise = this.getMyRequests()
                let temp = await dataPromise
                this.setState({ data: temp, loading: false })
            }
        }
        else {
            if (res.data.errorText && res.data.errorText !== "") {
                Util.showErrorMsg(this.messages, res.data.errorText)
                this.setState({ loading: false })
            }
            else {
                Util.showErrorMsg(this.messages, $.strings.operationFailed)
                this.setState({ loading: false })
            }
        }
    }
    deleteMemo = async (memoId) => {
        this.setState({ loading: true })
        let obj = {
            memoId: memoId
        }
        let res = await http.getJsonAxios(http.actions.Memos.urlGetDeleteMemo, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                Util.showSuccessMsg(this.messages, res.data.errorText)
                let dataPromise = this.getMyRequests()
                let temp = await dataPromise
                this.setState({ data: temp, loading: false })
            }
        }
        else {
            if (res.data.errorText && res.data.errorText !== "") {
                Util.showErrorMsg(this.messages, res.data.errorText)
                this.setState({ loading: false })
            }
            else {
                Util.showErrorMsg(this.messages, $.strings.operationFailed)
                this.setState({ loading: false })
            }
        }
    }
    deleteFingerPrint = async (id) => {
        this.setState({ loading: true })
        let obj = {
            id: id
        }
        let res = await http.getJsonAxios(http.actions.FingerPrint.urlGetDeleteFingerPrint, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                Util.showSuccessMsg(this.messages, res.data.errorText)
                let dataPromise = this.getMyRequests()
                let temp = await dataPromise
                this.setState({ data: temp, loading: false })
            }
        }
        else {
            if (res.data.errorText && res.data.errorText !== "") {
                Util.showErrorMsg(this.messages, res.data.errorText)
                this.setState({ loading: false })
            }
            else {
                Util.showErrorMsg(this.messages, $.strings.operationFailed)
                this.setState({ loading: false })
            }
        }
    }
    deleteExpectedVacation = async (data) => {
        this.setState({ loading: true })
        let obj = {
            id: data.ReqID,
            empCode: localStorage.getItem("useridHR") + ""
        }
        let res = await http.getJsonAxios(http.actions.ExpectedVacation.urlGetDeleteExpectedVacation, obj, 0);
        if (res.status === 200) {
            if (res.data.success && res.data.success === true) {
                Util.showSuccessMsg(this.messages, res.data.errorText)
                let dataPromise = this.getMyRequests()
                let temp = await dataPromise
                this.setState({ data: temp, loading: false })
            }
            else {
                Util.showErrorMsg(this.messages, res.data.errorText)
                this.setState({ loading: false })
            }
        }
        else {
            if (res.data.errorText && res.data.errorText !== "") {
                Util.showErrorMsg(this.messages, res.data.errorText)
                this.setState({ loading: false })
            }
            else {
                Util.showErrorMsg(this.messages, $.strings.operationFailed)
                this.setState({ loading: false })
            }
        }
    }

    handleRequests = async (data) => {
        this.rowIndex = this.gridData.flex.selection.row;
        this.colIndex = this.gridData.flex.selection.col;
        this.scrollPosition = this.gridData.flex.scrollPosition;

        if (data.Type + "" === "1" && (data.TransTypeNo + "" === "7" || data.TransTypeNo + "" === "8" || data.TransTypeNo + "" === "9") && data.ViewOnly !== "1") {
            //this.setState({ loading: true })
            let obj = {
                reqId: data.TransID,
                empCode: data.EmpCode,
                fromDate: data.FromDate,
                toDate: data.ToDate
            }
            this.focusedRowTemp = null;
            let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetValidateSectionVacationsDM, obj, 0);
            if (res.status === 200) {
                if (res.data && res.data.success) {
                    //this.setState({ loading: false })
                    await this.dlgApproval.show(data);
                }
                else {
                    //this.setState({ focusedRowTemp: data, loading: false })
                    this.focusedRowTemp = data;
                    await this.dlgDMVacDecision.show(res.data.errorText);
                }
            }
            else {
                //this.setState({ loading: false })
            }
        }
        else {
            await this.dlgApproval.show(data);
        }
    }
    onDMAccept = async (type) => {
        switch (type) {
            case "yes":
                await this.dlgApproval.show(this.focusedRowTemp);//this.state.focusedRowTemp);
                break;
            case "details":
                //await this.dlgVacEmpDetails.show(this.state.focusedRowTemp.EmpCode, this.state.focusedRowTemp.FromDate, this.state.focusedRowTemp.ToDate);
                await this.dlgVacEmpDetails.show(this.focusedRowTemp.EmpCode, this.focusedRowTemp.FromDate, this.focusedRowTemp.ToDate)
                break;
            default:
                break;
        }
    }

    handleRequestsHR = async (data) => {
        this.rowIndex = this.gridData.flex.selection.row;
        this.colIndex = this.gridData.flex.selection.col;
        this.scrollPosition = this.gridData.flex.scrollPosition;

        if (data.Type + "" === "1" && (data.TransTypeNo + "" === "7" || data.TransTypeNo + "" === "8" || data.TransTypeNo + "" === "9") && data.ViewOnly !== "1") {
            //this.setState({ loading: true })
            let obj = {
                reqId: data.TransID,
                empCode: data.EmpCode,
                fromDate: data.FromDate,
                toDate: data.ToDate
            }
            let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetValidateSectionVacationsDM, obj, 0);
            if (res.status === 200) {
                if (res.data && res.data.success) {
                    // this.setState({ loading: false }, () => {
                    //     Util.gridColumnStartEditing(this.gridData, rowIndex, colIndex, false);
                    // });
                    await this.dlgApproval.show(data);
                }
                else {
                    //this.setState({ focusedRowTemp: data, loading: false })
                    this.focusedRowTemp = data;
                    await this.dlgDMVacDecision.show(res.data.errorText);
                }
            }
            else {
                // this.setState({ loading: false }, () => {
                //     Util.gridColumnStartEditing(this.gridData, rowIndex, colIndex, false);
                // });
            }
        }
        else {
            await this.dlgApproval.show(data);
        }
    }

    insDateTemplate = (rowData, column) => {
        let date = new Date(rowData['RequestDate'])
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{date.toLocaleDateString()}</span>
        </>
    }
    fromDateTemplate = (rowData, column) => {
        let date = new Date(rowData['FromDate'])
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData['FromDate']}</span>
        </>
    }
    toDateTemplate = (rowData, column) => {
        let date = new Date(rowData['ToDate'])
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData['ToDate']}</span>
        </>
    }

    mainRequestTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData['MainType']}</span>
        </>
    }

    subTypeTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData['TransType']}</span>
        </>
    }

    daysNoTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData['Days']}</span>
        </>
    }

    mainTemplate = (rowData, column) => {
        return <>
            <span className="p-column-title">{column.header}</span>
            <span>{rowData[column.field]}</span>
        </>
    }

    getButtonsTemplate = (ctx) => {
        return (
            <div >
                <Button className="p-button-warning" tooltip={$.strings.transDetails} onClick={(event) => this.wfTransDetails(ctx.item)} icon={"pi pi-id-card"} />
                <Button className="p-button-info" tooltip={$.strings.view} onClick={(event) => this.wfTransDetails(ctx.item)} icon={"pi pi-eye"} />
                <Button className="p-button-success" tooltip={$.strings.transDetails} onClick={(event) => this.handleRequests(ctx.item)} icon={"pi pi-check-square"} />
            </div>
        );
    };
    getSchemeDataGrid = () => {
        let scheme = {
            name: "handleRequestsScheme_Table",
            filter: true,
            showFooter: true,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 7,
            columns: [
                {
                    name: 'rowNumber', header: '##', width: 50, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: "ID", header: "ID", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "TypeID", header: "TypeID", visible: false, visibleInColumnChooser: false
                },
                // {
                //     name: "buttons", header: ' ',
                //     width: 150, body: this.getButtonsTemplate, isReadOnly: true
                // },
                {
                    name: 'btnWFTrans', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.transDetails,
                    iconType: 'card', className: 'warning', onClick: (e, ctx) => this.wfTransDetails(ctx.item)
                },
                {
                    name: 'btnViewRequest', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.view,
                    iconType: 'eye', className: 'info', onClick: (e, ctx) => this.viewRequest(ctx.item, undefined)
                },
                {
                    name: 'btnHandleRequest', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.handleRequests.handle,
                    iconType: 'handle', className: 'success', onClick: !Util.checkISHR() ? (e, ctx) => this.handleRequests(ctx.item, undefined) : (e, ctx) => this.handleRequestsHR(ctx.item, undefined)
                },
                {
                    name: "EmpCode", header: $.strings.handleRequests.empCode, visible: false, visibleInColumnChooser: false
                },
                {
                    name: "EmpName", header: $.strings.handleRequests.empName, width: this.state.isDesktop ? 200 : '*'
                },
                {
                    name: "RequestDate", header: $.strings.handleRequests.requestDate, width: 110, dataType: 'Date', format: Util.gridDateFormat()
                },
                {
                    name: "typeName", header: $.strings.handleRequests.mainType, width: 120 //this.state.isDesktop && !Util.checkISHR() ? 120 : '*'
                },
                {
                    name: "TransType", header: $.strings.handleRequests.subType, width: 150
                },
                {
                    name: "TotalTime", header: $.strings.handleRequests.totalTime, width: 90
                },
                {
                    name: "FromDate", header: $.strings.handleRequests.fromDate, width: 105, dataType: 'Date', format: Util.gridDateFormat()
                },
                {
                    name: "StartDay", header: $.strings.handleRequests.startDay, width: 95
                },
                {
                    name: "ToDate", header: $.strings.handleRequests.toDate, width: 105, dataType: 'Date', format: Util.gridDateFormat()
                },
                {
                    name: "EndDay", header: $.strings.handleRequests.endDay, width: 95
                },
                {
                    name: "FromTime", header: $.strings.handleRequests.fromTime, width: 80
                },
                {
                    name: "ToTime", header: $.strings.handleRequests.toTime, width: 80
                },
                {
                    name: "Type", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "RequestNote", header: $.strings.handleRequests.requestNotes, width: 120, visibleInColumnChooser: false
                },
                {
                    name: "ManagerNote", header: $.strings.handleRequests.managerNotes, visible: false, width: 120, visibleInColumnChooser: false
                },
                {
                    name: "Status", header: $.strings.handleRequests.status, visible: !Util.checkISHR() ? true : false, width: 100
                },
                {
                    name: "LateDays", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "ReturnDate", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "LastStepAction", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "TransTypeNo", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "ViewOnly", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "PerType", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "TransID", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "Steps", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "StatusID", visible: false, visibleInColumnChooser: false
                },
                {
                    name: "LeaveType", visible: true, visibleInColumnChooser: false
                },
            ]
        }
        return scheme;
    }

}
