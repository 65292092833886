import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollTop from '../ScrollButton';
import MessageDetails from "./MessageDetails";
import Print from "../../Print";

const $ = window.$;

export default class MessagesReport extends React.Component {
    constructor(props) {
        super(props);
        this.statusList = [
            { id: -1, name: $.strings.all },
            { id: 1, name: $.strings.messagesReport.sent },
            { id: 0, name: $.strings.messagesReport.notSent }
        ];
        this.currDate = new Date();
        this.state = {
            isDesktop: window.innerWidth > 1250,
            fromDate: new Date(),
            toDate: new Date(),
            status: undefined,
            empName: undefined,
            subject: undefined,
            data: []
        };
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(931)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = this.updatePredicate();
        this.setState({
            status: this.statusList && this.statusList.length > 0 ? this.statusList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../MyStaff' },
            { label: $.strings.messagesReport.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card>
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
            </Card>
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <Accordion multiple activeIndex={[0, 1]}>
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-6">
                            <div className="p-grid">
                                <div className="p-col-6 p-lg-6 p-sm-6">
                                    <Calendar
                                        id="fromDate"
                                        value={this.state.fromDate}
                                        caption={$.strings.messagesReport.fromDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="p-col-6 p-lg-6 p-sm-6">
                                    <Calendar
                                        id="toDate"
                                        value={this.state.toDate}
                                        caption={$.strings.messagesReport.toDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-6">
                            <Dropdown
                                id="status"
                                value={this.state.status}
                                options={this.statusList}
                                caption={$.strings.messagesReport.status}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-6">
                            <Input
                                type="text"
                                id="empName"
                                value={this.state.empName}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.messagesReport.empName}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div><div className="p-col-12 p-lg-2 p-sm-6">
                            <Input
                                type="text"
                                id="subject"
                                value={this.state.subject}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.messagesReport.subject}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            style={gridStyle}
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.getScheme()}
                            dataSource={this.state.data}
                            exportFileName={$.strings.messagesReport.title}
                        />
                    </div>
                </div>
            </Card>
            <MessageDetails ref={e => this.dlgMessageDetails = e} />
            <ScrollTop />
        </div >
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ empName: obj.name })
        }
    }

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            fromDate: this.state.fromDate && (this.state.fromDate + "").length > 0 ? new Date(this.state.fromDate) : '',
            toDate: this.state.toDate && (this.state.toDate + "").length > 0 ? new Date(this.state.toDate) : '',
            status: this.state.status && this.state.status.id >= -1 ? this.state.status.id : "-1",
            empName: this.state.empName ? this.state.empName : '',
            subject: this.state.subject ? this.state.subject : ''
        }

        let res = await http.getJsonAxios(http.actions.Messages.urlGetMessagesList, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.messagesReport.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let dateFilter = "";

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.messagesReport.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.messagesReport.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.status) {
                listOfFilters.push($.strings.messagesReport.status + ": " + this.printFilters.status.name);
            }
            if (this.printFilters.empName) {
                listOfFilters.push($.strings.messagesReport.empName + ": " + this.printFilters.empName);
            }
            if (this.printFilters.subject) {
                listOfFilters.push($.strings.messagesReport.subject + ": " + this.printFilters.subject);
            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    resendMsg = async (item) => {
        if (window.confirm($.strings.messagesReport.resendConfirm)) {
            await this.resendSMS(item.ID);
        }
    }
    resendSMS = async (id) => {
        this.setState({ loading: true })
        let success = false
        let obj = {
            ID: id
        }

        let res = await http.getJsonAxios(http.actions.Messages.urlResendMsg, obj, 0);
        if (res.status === 200) {
            success = res.data.success;
        }
        if (success) {
            alert($.strings.operationDone);

            await this.getData();
        }
        else {
            this.setState({ loading: false });
        }
    }
    viewDetails = (item) => {
        this.dlgMessageDetails.show(item.Data)
    }

    getScheme = () => {
        return {
            name: "messagesReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 5,
            columns: [
                { name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                {
                    name: 'viewDetails', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.messagesReport.msgDetails,
                    iconType: 'eye', className: 'info', onClick: (e, ctx) => this.viewDetails(ctx.item)
                },
                {
                    name: 'resendMsg', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.messagesReport.resend,
                    iconType: 'send', className: 'warning', onClick: (e, ctx) => this.resendMsg(ctx.item)
                },
                { name: 'ID', header: $.strings.messagesReport.code, visible: false, visibleInColumnChooser: false },
                { name: 'Name', header: $.strings.messagesReport.name, width: 290, columnWidth: '*' },
                {
                    name: 'Email', header: $.strings.messagesReport.email, width: this.state.isDesktop ? '*' : 290, columnWidth: '*'
                },
                { name: 'Data', header: $.strings.messagesReport.data, width: 130, visible: false, visibleInColumnChooser: false },
                { name: 'InsDate', header: $.strings.messagesReport.insDate, width: 130, columnWidth: '3' },
                { name: 'InsTime', header: $.strings.messagesReport.insTime, width: 130, columnWidth: '3' },
                { name: 'Status', header: $.strings.messagesReport.status, width: 130, columnWidth: '2' },
                { name: 'SentDate', header: $.strings.messagesReport.sentDate, width: 130, columnWidth: '3' },
                { name: 'SendTime', header: $.strings.messagesReport.sentTime, width: 130, columnWidth: '3' },
                { name: 'Tries', header: $.strings.messagesReport.tries, width: 130, columnWidth: '2' }
            ]
        }
    }
}