import React from "react";
import { Button as PrimeButton } from 'primereact/button';

const $ = window.$;

export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            // <button ref={this.props.innerRef} title={this.props.tooltip} className={`${styles.button1} ${this.props.innerClass || ''} `} {...this.props} >
            //     {this.props.children}                
            // </button>
            <PrimeButton ref={this.props.innerRef} label={this.props.label || ' '} {...this.props} 
                style={{ marginInlineStart: '5px', minWidth: '30px', minHeight: '30px' }
                } tooltipOptions={{ position: 'bottom' }}>
                {this.props.children}
            </PrimeButton >
        );
    }
}
