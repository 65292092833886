import React from "react";
import styles from "./RadioButton.module.scss";
import { RadioButton as PrimeRadioButton } from "primereact/radiobutton";
import Label from "../../Components/Label";

export default class RadioButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <div style={{ width: '100%', minWidth: 'auto' }}>
                <PrimeRadioButton inputId={this.props.inputId} name={this.props.name} Text={this.props.value} {...this.props}
                    className={`${styles.radioButton} ${this.props.innerClass || ''}`} />
                {
                    this.props.value &&
                    <Label htmlFor={this.props.inputId} >{this.props.value}</Label>
                }
            </div>
        );
    }
}
