import React from "react";
import Util from './Util';

const $ = window.$;

function checkLicense() {
    $.license.Att_Module = false
    $.license.Budgeting_Module = false
    $.license.Evaluation_Module = false
    $.license.HR_KSA_Module = false
    $.license.HR_Module = false
    $.license.Hireing_Module = false
    $.license.Notifications_Module = false
    $.license.SelfService_Module = false
    $.license.Training_Module = false

    // $.license.SelfService_Module = true
    // $.license.Notifications_Module = true
    // $.license.Att_Module = true
    // $.license.HR_Module = true

    let modules = JSON.parse(localStorage.getItem("licenseModulesHR"));

    if (modules) {

        $.license.Att_Module = modules.Att_Module
        $.license.Budgeting_Module = modules.Budgeting_Module
        $.license.Evaluation_Module = modules.Evaluation_Module
        $.license.HR_KSA_Module = modules.HR_KSA_Module
        $.license.HR_Module = modules.HR_Module
        $.license.Hireing_Module = modules.Hireing_Module
        $.license.Notifications_Module = modules.Notifications_Module
        $.license.SelfService_Module = modules.SelfService_Module
        $.license.Training_Module = modules.Training_Module
    }
}


export default checkLicense;