import React from "react";
import styles from './SendMessages.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import Util from '../../Util'
import MultiSelect from '../../Components/MultiSelect';
import Card from '../../Components/Card'
import SiteMap from '../../Components/SiteMap';
import ScrollTop from '../ScrollButton';
import Editor from '../../Components/InputEditor';
import { Accordion, AccordionTab } from 'primereact/accordion';
import RadioButton from '../../Components/RadioButton';
import DataGrid from '../../Components/DataGridView/DataGridView';
import Button from "../../Components/Button";
import AddPerson from "./AddPerson";

const _ = require("lodash");
const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class SendMessages extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.nowDate = new Date();
        this.employeesTypesList = [
            //{ id: -1, name: $.strings.all },
            { id: 1, name: $.strings.sendMessages.sectionsManager },
            { id: 2, name: $.strings.sendMessages.directManager }
        ]
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { title: '', messageText: '' },
            isDesktop: window.innerWidth > 1250,
            data: [],
            employeeType: null,
            section: null,
            sectionsList: [],
            job: null,
            jobsList: [],
            branch: null,
            branchesList: [],
            department: undefined,
            departmentsList: [],
            dataObject: {
                title: '',
                messageText: '',
                messageType: $.strings.sendMessages.mobileMsg,
                employeesList: [],
                messageLength: 0
            }
        }
    }
    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }
        if (!Util.checkRights(730)) {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = window.innerWidth > 1250;
        let _jobs = await this.getJobs();
        let _department = await this.getDepartments();
        let _sectionsList = await this.getSections();
        let _branchesList = await this.getBranches();

        this.setState({
            jobsList: _jobs, sectionsList: _sectionsList, departmentsList: _department,
            branchesList: _branchesList, isDesktop: _isDesktop, loading: false
        });
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state))
        return newHash
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state, [name]: value } })
    }
    handleMultiSelect = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;

        let _title = true;
        fieldValidationErrors.title = '';

        if (!this.state.dataObject.title || this.state.dataObject.title.length < 0) {
            fieldValidationErrors.title = $.strings.requiredFiled;
            _title = false;
        }


        let _details = true;
        let objEditor = this.editor.editorElement.__quill;
        let editorText = objEditor.getText(0);
        if (!objEditor || !editorText || editorText.trim().length <= 0) {
            fieldValidationErrors.details = $.strings.requiredFiled;
            _details = false;
        }

        let _employees = true;
        if (!this.gridData.state.selectedItems || this.gridData.state.selectedItems.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.sendMessages.selectEmployees);
            _employees = false;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            titleValid: _title,
            detailsValid: _details,
            employeesValid: _employees
        }, this.validateForm);

        return _title && _details && _employees;
    }
    validateForm() {
        let isFormValid = this.state.titleValid && this.state.detailsValid && this.state.employeesValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        let siteMapItems = [
            { label: $.strings.links.staticFilesAndSettings },
            {
                label: <div>
                    {$.strings.sendMessages.title + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        const gridStyle = { minHeight: '45vh' }
        return (
            <div ref="divMain" className={styles.addWorkLeave}>
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookupList onRefresh={this.getData} onSave={this.onSave} />
                    </div>
                </div>
                <AddPerson ref={e => this.dlgAddPerson = e} onAddSuccess={this.onAddPersonSuccess} />
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid" style={{ width: '100%' }}>
                    <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)}
                    toDo={e => this.onNew(e, false)} onCancel={this.onCancel} appendTo={this.props.parent} />
                <Accordion multiple activeIndex={[0, 1]}>
                    <AccordionTab header={$.strings.filterCaption}>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <MultiSelect
                                    id="employeeType"
                                    value={this.state.employeeType}
                                    options={this.employeesTypesList}
                                    caption={$.strings.sendMessages.employeeType}
                                    onChange={this.handleMultiSelect}
                                    optionLabel="name"
                                    display="chip"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <MultiSelect
                                    id="section"
                                    value={this.state.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.sendMessages.section}
                                    onChange={this.handleMultiSelect}
                                    optionLabel="Name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <MultiSelect
                                    id="branch"
                                    value={this.state.branch}
                                    options={this.state.branchesList}
                                    caption={$.strings.sendMessages.branch}
                                    onChange={this.handleMultiSelect}
                                    optionLabel="name"
                                    display="chip"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <MultiSelect
                                    id="department"
                                    value={this.state.department}
                                    options={this.state.departmentsList}
                                    caption={$.strings.sendMessages.department}
                                    onChange={this.handleMultiSelect}
                                    optionLabel="name"
                                    display="chip"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <MultiSelect
                                    id="job"
                                    value={this.state.job}
                                    options={this.state.jobsList}
                                    caption={$.strings.sendMessages.job}
                                    onChange={this.handleMultiSelect}
                                    optionLabel="name"
                                    display="chip"
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-6 p-sm-12">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-4 p-sm-12">
                                <Input
                                    id="title"
                                    value={this.state.dataObject.title}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, title: e.target.value } })}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                    caption={$.strings.sendMessages.subject}
                                />
                            </div>
                            <div className="p-col-12 p-lg-8 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <div className="p-grid">
                                    <div className="p-lg-4 p-sm-12">
                                        <label>{$.strings.sendMessages.msgType}</label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="rbMobile"
                                            value={$.strings.sendMessages.mobileMsg}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, messageType: e.value } })}
                                            checked={this.state.dataObject.messageType === $.strings.sendMessages.mobileMsg}
                                        ></RadioButton>
                                    </div>
                                    <div className="p-col-12 p-lg-6 p-sm-12">
                                        <RadioButton
                                            inputId="rbEmail"
                                            value={$.strings.sendMessages.emailMsg}
                                            name="Status"
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, messageType: e.value } })}
                                            checked={this.state.dataObject.messageType === $.strings.sendMessages.emailMsg}
                                        ></RadioButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Editor
                                    id="messageText"
                                    innerRef={e => this.editor = e}
                                    value={this.state.dataObject.messageText}
                                    style={{ height: '345px' }}
                                    onTextChange={(e) => this.setState({
                                        dataObject: {
                                            ...this.state.dataObject, details:
                                                e.htmlValue, messageLength: e.textValue.length
                                        }
                                    })}
                                    isRequired={true}
                                    caption={$.strings.sendMessages.messageText}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                {
                                    this.state.dataObject.messageType === $.strings.sendMessages.mobileMsg
                                        ?
                                        this.state.dataObject.messageLength && this.state.dataObject.messageLength > 0 ?
                                            this.state.dataObject.messageLength - 1 : this.state.dataObject.messageLength
                                        :
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6 p-sm-12">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <h2> {$.strings.sendMessages.employeesList}</h2>
                            </div>
                            {/* <div className="p-col-6 p-lg-2 p-sm-6">
                                <Button label={$.strings.sendMessages.selectAll} icon="" className="p-button-info" onClick={this.onSelectAll} />
                            </div>
                            <div className="p-col-6 p-lg-2 p-sm-6">
                                <Button label={$.strings.sendMessages.cancelAll} icon="" className="p-button-danger" onClick={this.onUnSelectAll} />
                            </div>
                            <div className="p-col-6 p-lg-2 p-sm-6">
                                <Button label={$.strings.sendMessages.flipSelection} icon="" className="p-button-warning" onClick={this.onFlipSelection} />
                            </div>
                            <div className="p-col-6 p-lg-3 p-sm-6">
                                <Button label={$.strings.sendMessages.saveData} icon="" className="p-button-success" />
                            </div> */}
                        </div>
                        <div className="p-grid">
                            <Button icon="pi pi-plus" label={$.strings.sendMessages.addPerson} onClick={this.onAddPerson} />
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <DataGrid
                                    style={gridStyle}
                                    ref={e => this.gridData = e}
                                    isReport={true}
                                    theme="default-light"
                                    idProperty="serial"
                                    scheme={this.getScheme()}
                                    dataSource={this.state.data}
                                    exportFileName={$.strings.sendMessages.newsReportTitle}
                                    checkBoxSelector={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <ScrollTop />
            </div >
        );
    }
    onAddPerson = () => {
        this.dlgAddPerson.onShow();
    }
    onAddPersonSuccess = (name, mobile, email) => {
        let dataTemp = _.cloneDeep(this.state.data);
        if (dataTemp) {
            dataTemp.push({
                rowNumber: dataTemp.length + 1, Code: dataTemp.length + 1,
                Name: name, Mobile: mobile, Email: email
            });
            this.setState({ data: dataTemp });
        }
    }
    getJobs = async () => {
        let dataTemp = []
        let obj = {
            type: 3
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp;
    }
    getDepartments = async () => {
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp;
    }
    getSections = async () => {
        let dataTemp = []
        let obj = {
            type: 3
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getBranches = async () => {
        let dataTemp = []
        let obj = {
            type: 7
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp;
    }

    getData = async (id) => {
        this.setState({ loading: true });
        let dataTemp = []
        let branchesList = [], departmentsList = [], jobsList = [], managementsList = [], employeeTypesList = []
        if (this.state.branch) {
            this.state.branch.forEach(element => {
                branchesList.push(element.pos);
            });
        }
        if (this.state.section) {
            this.state.section.forEach(element => {
                departmentsList.push(element.ID);
            });
        }
        if (this.state.job) {
            this.state.job.forEach(element => {
                jobsList.push(element.pos);
            });
        }
        if (this.state.department) {
            this.state.department.forEach(element => {
                managementsList.push(element.pos);
            });
        }
        if (this.state.employeeType) {
            this.state.employeeType.forEach(element => {
                employeeTypesList.push(element.id);
            });
        }
        let obj = {
            branchesList: branchesList,
            departmentsList: departmentsList,
            jobsList: jobsList,
            managementsList: managementsList,
            employeeTypesList: employeeTypesList
        }

        let res = await http.postMultipartAxios(http.actions.Messages.urlGetSmsMessages, obj, null, null, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        this.setState({ data: dataTemp, loading: false });
    }

    onSelectAll = (data) => {
        let selectedItems = this.gridData.flex.rows.map(element => {
            element.isSelected = true;
        });
        this.setState({ selectedItems: selectedItems });
    }
    onUnSelectAll = (data) => {
        let selectedItems = this.gridData.flex.rows.map(element => {
            element.isSelected = false;
        });
        this.setState({ selectedItems: selectedItems });
    }
    onFlipSelection = (data) => {
        let selectedItems = this.gridData.flex.rows.map(element => {
            element.isSelected = element.isSelected ? false : true;
        });
        this.setState({ selectedItems: selectedItems });
    }

    onSave = async () => {
        this.messages.clear();
        if (!this.validateField()) {
            return false;
        }

        this.setState({ loading: true })
        let res = undefined;
        if (this.state.id === 0) {
            res = await this.saveData(false);
        }
        else {
            res = await this.saveData(true);
        }
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.message)
                    Util.showSuccessMsg(this.messages, res.data.message);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
            }
        }
        else {
            if (res.data.message !== "") {
                Util.showErrorMsg(this.messages, res.data.message);
                this.setState({ loading: false })
            }
            else {
                Util.showErrorMsg(this.messages);
                this.setState({ loading: false })
            }
        }
    }
    saveData = async (isUpdate) => {
        let list = [];
        let dataTemp = this.gridData.state.selectedItems;
        if (dataTemp) {
            let isMobile = this.state.dataObject.messageType === $.strings.sendMessages.mobileMsg ? true : false
            for (let i = 0; i < dataTemp.length; i++) {
                list.push({ Name: dataTemp[i]._data.Name, Mobile: isMobile ? dataTemp[i]._data.Mobile : dataTemp[i]._data.Email });
            }
        }
        let objEditor = this.editor.editorElement.__quill;
        let editorText = objEditor.getText(0);
        let obj = {
            Subject: this.state.dataObject.title,
            Message: editorText,
            list: list
        }

        let res = await http.postMultipartAxios(http.actions.Messages.urlPostSaveSmsMessages, obj, 0);

        return res;
    }
    resetFields = () => {
        this.nowDate = new Date();
        let objEditor = this.editor.editorElement.__quill;
        objEditor.setText("");
        this.setState({
            loading: true,
            mode: 'new',
            formErrors: { title: '', messageText: '' },
            isDesktop: window.innerWidth > 1250,
            data: [],
            employeeType: null,
            section: null,
            job: null,
            branch: null,
            department: undefined,
            dataObject: {
                title: '',
                messageText: '',
                messageType: $.strings.sendMessages.mobileMsg,
                employeesList: []
            }
        });
    }

    getScheme = () => {
        return {
            name: "sendMessagesScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                {
                    name: 'select', header: ' ', width: 80, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'rowNumber', header: '#', width: 80, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'Code', header: $.strings.sendMessages.empCode, width: 100,
                },
                {
                    name: 'Name', header: $.strings.sendMessages.empName, width: "*",
                },
                {
                    name: 'Mobile', header: $.strings.sendMessages.mobile, width: 120,
                },
                {
                    name: 'Email', header: $.strings.sendMessages.email, width: 180,
                }
            ]
        }
    }
}