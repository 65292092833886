import '@grapecity/wijmo.styles/wijmo.css';
import './Late.css';
import * as React from 'react';
import * as wijmo from '@grapecity/wijmo';
import * as wjChart from '@grapecity/wijmo.react.chart';
import * as wjCharts from '@grapecity/wijmo.chart';
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation';
import http from '../../../../Api/http'
import ProgressSpinner from '../../../ProgressSpinner';

const $ = window.$

export default class Late extends React.Component {
    constructor(props) {
        super(props);
        this.palette = ['rgba(181,72,54,1)', 'rgba(96, 163, 234,1)', 'rgba(137,194,53,1)', 'rgba(227,119,164,1)',
            'rgba(166,137,49,1)', 'rgba(166,114,166,1)', 'rgba(208,192,65,1)', 'rgba(227,88,85,1)',
            'rgba(104,112,106,1)'
        ];
        this.state = {
            loading: true,
            isDesktop: true,
            dataLate: []
        }
    }

    _updateChartHeaderLate = (group) => {
        let item = group.items[0], path = '', headers = [];
        //
        for (let i = 0; i <= group.level; i++) {

            let prop = this.state.dataLate.groupDescriptions[i].propertyName, hdr = wijmo.format('<a href="#{path}">{prop}</a>: {value}', {
                path: path,
                prop: $.strings.charts[wijmo.toHeaderCase(prop)],
                value: item[prop]
            });
            headers.push(hdr);
            path += '/' + item[prop];
        }
        //

        if (this.header) {
            this.header.innerHTML = headers.length > 0
                ? /*$.strings.charts.lateTile + ' ' +*/ headers.join(', ')
                : '';/*$.strings.charts.lateTile;*/
        }
    }
    initializeLateChart = (flex) => {
        this.theChartLate = flex;
        this.header = document.querySelector('#lateHeader');
        if (this.header) {
            this.header.addEventListener('click', (e) => this._onHeaderClickLate(e));
        }
    };
    lateSelectionChanged = () => {
        if (this.theChartLate.selection) {
            let point = this.theChartLate.selection.collectionView.currentItem;
            if (point && point.group && !point.group.isBottomLevel) {
                this.showGroupLate(point.group, true, this.theChartLate.selection);
            }
        }
    };
    _onHeaderClickLate = (e) => {
        if (e.target instanceof HTMLAnchorElement) {
            e.preventDefault();
            //
            // get the link path
            let path = e.target.href;
            path = path.substr(path.lastIndexOf('#') + 1);
            let paths = path.split('/');

            // find the group that matches the path
            let src = this.state.dataLate;
            for (let i = 1; i < paths.length; i++) {
                for (let j = 0; j < src.groups.length; j++) {
                    let group = src.groups[j];
                    if (group.name === paths[i]) {
                        src = group;
                        break;
                    }
                }
            }
            // show the selected group
            this.showGroupLate(src);
        }
    };
    showGroupLate = async (group, isDrillDown, selected) => {
        // update titles
        this._updateChartHeaderLate(group);
        var level = 'level' in group ? group.level + 1 : 0;
        //this.theChartLate.axisX.title = $.strings.charts[wijmo.toHeaderCase(this.state.dataLate.groupDescriptions[level].propertyName)];
        //
        // update the series color (use a different one for each level)
        var palette = this.theChartLate.palette || wjCharts.Palettes.standard;
        this.theChartLate.series[0].style = {
            fill: palette[level],
            stroke: palette[level]
        };
        //
        // update data
        this.theChartLate.itemsSource = this.getGroupData(group, isDrillDown, selected);
        this.theChartLate.selection = null;
    };



    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;
        let data = await this.getData();
        this.setState({
            dataLate: data, groupedViewDataLate: this.getGroupData(data), isDesktop: _isDesktop,
            palette: this.palette, loading: false
        });
    }
    render() {

        // return <div className="container-fluid">
        //     <ProgressSpinner loading={this.state.loading} />
        //     <h4 id="lateHeader">{$.strings.charts.lateTile}</h4>
        //     <wjChart.FlexChart bindingX="name" selectionMode="Point" itemsSource={this.state.groupedViewDataLate} selectionChanged={this.lateSelectionChanged}
        //         initialized={this.initializeLateChart} palette={this.state.palette}>
        //         <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        //         <wjChart.FlexChartSeries binding="Late" name={$.strings.charts.late} ></wjChart.FlexChartSeries>
        //         <wjChart.FlexChartSeries binding="SectionCount" name={$.strings.charts.empCount} ></wjChart.FlexChartSeries>

        //         <wjChart.FlexChartAxis wjProperty="axisX" title=""></wjChart.FlexChartAxis>
        //         <wjChart.FlexChartAxis wjProperty="axisY" title="" ></wjChart.FlexChartAxis>
        //         <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
        //     </wjChart.FlexChart>

        // </div>;

        return (
            <>
                <span id="lateHeader">{$.strings.charts.lateTile}</span>
                <wjChart.FlexChart bindingX="name" selectionMode="Point" itemsSource={this.state.groupedViewDataLate} selectionChanged={this.lateSelectionChanged}
                    initialized={this.initializeLateChart} palette={this.state.palette}>
                    <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
                    <wjChart.FlexChartSeries binding="Late" name={$.strings.charts.late} ></wjChart.FlexChartSeries>
                    <wjChart.FlexChartSeries binding="SectionCount" name={$.strings.charts.empCount} ></wjChart.FlexChartSeries>

                    <wjChart.FlexChartAxis wjProperty="axisX" title=""></wjChart.FlexChartAxis>
                    <wjChart.FlexChartAxis wjProperty="axisY" title="" ></wjChart.FlexChartAxis>
                    <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
                </wjChart.FlexChart>
            </>
        )
    }

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            dtpFromDate: new Date(),
            dtpToDate: new Date(),
            fromCode: '',
            toCode: ''
        }
        let res = await http.getJsonAxios(http.actions.Dashboards.urlGetLateDashboard, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.forEach(element => {
                    //element.Total = dataTemp.filter((obj) => obj.Section === obj.Section+"").length
                });
            }
        }

        return new wijmo.CollectionView(dataTemp, {
            groupDescriptions: ['Section', 'EmpName']
        });
    }

    getGroupData = (group, isDrillDown, selected) => {
        // get items for this group, aggregate by sales
        let arr = [];
        if (isDrillDown) {
            group.groups.forEach(function (g) {
                if (selected && selected._binding === "SectionCount") {
                    arr.push({
                        name: g.name,
                        Late: g.getAggregate(wijmo.Aggregate.Sum, 'Late'),
                        group: g
                    });
                }
                else {
                    if (g.items && g.items.length > 0 && g.items[0].Late > 0) {
                        arr.push({
                            name: g.name,
                            Late: g.getAggregate(wijmo.Aggregate.Sum, 'Late'),
                            group: g
                        });
                    }
                }
            });
        }
        else {
            group.groups.forEach(function (g) {
                arr.push({
                    name: g.name,
                    Late: g.getAggregate(wijmo.Aggregate.Cnt, 'Late'),
                    SectionCount: g.getAggregate(wijmo.Aggregate.Cnt, 'Section'),
                    group: g
                });
            });
        }
        // return a new collection view sorted by sales
        return new wijmo.CollectionView(arr, {
            sortDescriptions: [
                new wijmo.SortDescription('Late', false),
            ]
        });
    }


}
