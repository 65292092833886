import React from "react";
import http from '../../Api/http';
import ProgressSpinner from '../../Components/ProgressSpinner';
import Util from "../../Util";

const $ = window.$

export default class MailHandel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            success: false,
            resultText: '',
            isDesktop: true
        };
    }

    componentDidMount = async () => {

        let _isDesktop = this.updatePredicate();
        if (!this.props.match.params.C || !this.props.match.params.RT || !this.props.match.params.T ||
            !this.props.match.params.WFStep || !this.props.match.params.WFTransID) {
            Util.goTo("../../Access");
        }
        
        this.setState({ isDesktop: _isDesktop, loading: false })
        let resultPromise = this.handleMail()
        let temp = await resultPromise
        this.setState({ data: temp, isDesktop: _isDesktop, loading: false })
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    render() {
        
        let color = this.state.success ? 'green' : 'red';
        return (
            < div ref="divMain" style={{ minHeight: '70vh' }}>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12" style={{ textAlign: 'center' }}>
                        <span style={{ color: color, fontSize: '18px', fontWeight: 'bold' }}>{this.state.resultText}</span>
                    </div>
                </div>
            </div >
        );
    }

    handleMail = async () => {
        this.setState({ loading: true })
        let obj = {
            type: this.props.match.params.T,
            Code: this.props.match.params.C,
            WFTransID: this.props.match.params.WFTransID,
            WFStep: this.props.match.params.WFStep,
            RT: this.props.match.params.RT
        }
        
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetHandelRequestFromEmail, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                let resultText = $.strings.operationDone;
                if (res.data.errorText) {
                    resultText = res.data.errorText;
                }
                this.setState({ loading: false, success: true, resultText: resultText });
            }
            else {
                let resultText = $.strings.operationFailed;
                if (res.data.errorText) {
                    resultText = res.data.errorText;
                }
                this.setState({ loading: false, success: false, resultText: resultText });
            }
        }
        else {
            this.setState({ loading: false, success: false, resultText: $.strings.operationFailed })
        }
    }
}
