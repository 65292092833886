import React from "react";
import { FlexGridColumn, FlexGrid, FlexGridCellTemplate, FlexGridColumnGroup } from '@grapecity/wijmo.react.grid';
import * as wjcCore from '@grapecity/wijmo';
import { GroupPanel } from "@grapecity/wijmo.react.grid.grouppanel";
import "@grapecity/wijmo.styles/wijmo.css";
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import * as wjGrid from '@grapecity/wijmo.grid';
import { ExportMode, ScaleMode, FlexGridPdfConverter } from '@grapecity/wijmo.grid.pdf';
import { PdfPageOrientation } from '@grapecity/wijmo.pdf';
import * as gridXlsx from '@grapecity/wijmo.grid.xlsx';
import { ListBox } from '@grapecity/wijmo.react.input';
import { showPopup, hidePopup, saveFile } from '@grapecity/wijmo';
import FlexGridContextMenu from './FlexGridContextMenu';
import { Selector } from "@grapecity/wijmo.grid.selector";
import * as schemeHelper from "./SchemeHelper";
import styles from "./DataGridView.module.scss";
//import Button from '../Button';

//const { parse, stringify } = require('flatted/cjs');
const $ = window.$;

export default class DataGridView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValueFilters: schemeHelper.createFilters(props.scheme),
            summaryReducer: schemeHelper.createSummaryReducer(props.scheme),
            footerRows: schemeHelper.createFooterRows(props.scheme),
            groupColumn: schemeHelper.createGroupColumn(props.scheme, props.scheme.allowGrouping),
            selection: undefined,
            currentSelection: null,
            _defaultRowHeight: this.props.defaultRowHeight || 50,
            customCell: true,
            narrow: false,
            scaleMode: ScaleMode.ActualSize,
            exportMode: ExportMode.All,
            orientation: PdfPageOrientation.Portrait,
            progress: 0,
            selectedItems: undefined,
            buttonsColumns: this.createButtonsColumns(this.props.scheme)
        };
    }
    componentDidMount = () => {
        this.setState({ _defaultRowHeight: this.flex.rows.defaultSize * 2 }, () => this._updateGridLayout());

        if (this.columnPicker) {
            hidePopup(this.columnPicker.hostElement);
        }
        window.addEventListener("resize", this._updateGridLayout());
        this.flex.addEventListener(window, "resize", this._updateGridLayout());

        this.loadGridState();
    }

    createButtonsColumns = () => {
        let schemePtr = schemeHelper.create(this.props.scheme);
        let columns = schemePtr.columns.filter(col => !col.visible || col.visible === true)
        let buttonsColumns = [];
        columns.map((col, index) => {
            if (col.buttonBody && col.buttonBody === "button") {

                buttonsColumns.push({
                    colName: col.name,
                    template: CellMaker.makeButton({
                        attributes: { title: col.title },
                        cssClass: this.setButtonClass(col.className, col.iconType),
                        click: col.onClick
                    })
                }
                );
            }
        });
        return buttonsColumns
    }

    dooo = () => {
        this.loadGridState();
    }

    render = () => {
        let schemePtr = schemeHelper.create(this.props.scheme);
        let columns = schemePtr.columns.filter(col => !col.visible || col.visible === true)
        if (this.state.columns) {
            columns = this.state.columns
        }
        return <div>
            {/* <button onClick={this.dooo}>Load</button> */}
            {
                schemePtr.filter &&
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        {/* <span ></span> */}
                        <input style={{ width: '100%' }} onInput={this.filter.bind(this)} placeholder={$.strings.globalFilter} />
                    </div>
                </div>
            }
            {
                schemePtr.allowGrouping && !this.state.narrow &&
                <GroupPanel className="group-panel" grid={this.state.flex} placeholder={$.strings.groupingRow} />
            }

            <FlexGrid id="theGridTallRows" headersVisibility="Column" isReadOnly={this.props.isReport ? true : false} quickAutoSize={false}
                quickRowHeights={false} wordWrap={true}
                initialized={this.initialized.bind(this)} itemsSource={this.props.dataSource} {...this.props} >
                {
                    this.props.columnGroupsList && this.props.columnGroupsList.map(col => {
                        return <FlexGridColumnGroup binding={col.columns ? '' : col.name} header={col.header} width={col.width} align="center" minWidth={col.minWidth}>
                            {
                                col.columns && col.columns.map(innerCol => {
                                    return <FlexGridColumnGroup binding={innerCol.name} header={innerCol.header} width={innerCol.width}
                                        minWidth={innerCol.minWidth} dataType={col.dataType} format={col.format} />
                                })
                            }
                        </FlexGridColumnGroup>
                    })
                }
                {
                    columns.map(col => {
                        if (!col.columns || col.columns.length <= 0) {
                            if (!this.props.columnGroupsList || !this.props.columnGroupsList.find(c => c.name === col.name)) {
                                return <FlexGridColumn name={col.name} header={col.header} binding={col.name} width={col.width} aggregate={col.aggregate}
                                    allowSorting={schemePtr.sortable && (col.sortable || col.sortable === true)} wordWrap={true} autoRowHeights={this.props.dataSource.length < 500 ? true : false}
                                    visible={col.visible} isReadOnly={col.isReadOnly ? true : false} dataType={col.dataType}
                                    cellTemplate={this.createButton(col)} minWidth={col.minWidth}
                                    format={col.format}>
                                    {
                                        col.body && (<FlexGridCellTemplate cellType="Cell" template={col.body} />)
                                    }
                                </FlexGridColumn>
                            }
                        }
                    })
                }

                {schemePtr.filter && <FlexGridFilter />}
            </FlexGrid>
            <ListBox initialized={this.initializedPicker.bind(this)} />
        </div>
    }
    checkColumnGroups = (colName) => {
        let colGroupsList = this.props.columnGroupsList;
        if (!colGroupsList || colGroupsList.length <= 0)
            return false;
        let exist = false;
        for (let i = 0; i < colGroupsList.length; i++) {
            for (let c = 0; c < colGroupsList[i].columns.length; c++) {
                if (colGroupsList[i].columns[c].name === colName) {
                    exist = true;
                    return exist;
                }
            }
        }
        return exist;
    }
    createButton = (col) => {
        if (col.name === "") {
            alert(" Add Column Name")
        }
        let button = this.state.buttonsColumns.find(e => e.colName === col.name);
        if (button) {
            return button.template;
        }
        return undefined;
    }
    saveGridState(flexGrid, scheme) {
        if (!flexGrid) return;
        let state = {
            columns: JSON.parse(flexGrid.columnLayout).columns,
            sortDescriptions: flexGrid.collectionView.sortDescriptions.map(function (sortDesc) {
                return { property: sortDesc.property, ascending: sortDesc.ascending };
            }),
            groupDescriptions:
                flexGrid.collectionView.groupDescriptions.map(function (groupDesc) {
                    return { propertyName: groupDesc.propertyName };
                }),
        };
        localStorage[scheme.name] = JSON.stringify(state);
    }

    loadGridState = () => {
        var json = localStorage.getItem(this.props.scheme.name);
        if (json) {
            let state = JSON.parse(json);
            var view = this.flex.collectionView;
            if (view) {
                view.deferUpdate(function () {
                    if (state.sortDescriptions) {
                        view.sortDescriptions.clear();
                        for (let i = 0; i < state.sortDescriptions.length; i++) {
                            var sortDesc = state.sortDescriptions[i];
                            view.sortDescriptions.push(new wjcCore.SortDescription(sortDesc.property, sortDesc.ascending));
                        }
                    }
                    if (state.groupDescriptions) {
                        view.groupDescriptions.clear();
                        for (let i = 0; i < state.groupDescriptions.length; i++) {
                            var groupDesc = state.groupDescriptions[i];
                            view.groupDescriptions.push(new wjcCore.PropertyGroupDescription(groupDesc.propertyName));
                        }
                    }
                });
            }
            this.setState({ columns: state.columns })
        }
    }
    // Export To Excel
    excelExport() {
        let currentDate = new Date();
        let date = currentDate.getFullYear() + '_' + (currentDate.getMonth() + 1) + '_' + currentDate.getDate();
        let time = currentDate.getMinutes() + ' ' + currentDate.getHours() + ' ';

        let dateStr = date + ' ' + time;

        gridXlsx.FlexGridXlsxConverter.saveAsync(
            this.flex,
            {
                includeColumns: function (column) {
                    return !column.name.startsWith('btn');
                },
                formatItem: this.formatItemXlsx,
            },
            this.props.exportFileName ? this.props.exportFileName + ' ' + dateStr : 'FlexGrid.xlsx',
            null,
            null,
            (progress) => this.setState({ progress: progress }),
            true
        );
    }
    setButtonClass = (className, iconType) => {
        let _className = "";
        switch (className.toLowerCase()) {
            case "danger": {
                _className = "cellMaker-button-danger";
                break;
            }
            case "warning": {
                _className = "cellMaker-button-warning";
                break;
            }
            case "info": {
                _className = "cellMaker-button-info";
                break;
            }
            case "success": {
                _className = "cellMaker-button-success";
                break;
            }
            default: {
                _className = "cellMaker-button";
                break;
            }
        }
        switch (iconType.toLowerCase()) {
            case "search": {
                _className += " pi pi-search";
                break;
            }
            case "card": {
                _className += " pi pi-id-card";
                break;
            }
            case "view":
            case "eye": {
                _className += " pi pi-eye";
                break;
            }
            case "edit": {
                _className += " pi pi-pencil";
                break;
            }
            case "delete": {
                _className += " pi pi-trash";
                break;
            }
            case "add": {
                _className += " pi pi-plus";
                break;
            }
            case "handle": {
                _className += " pi pi-check-square";
                break;
            }
            case "user": {
                _className += " pi pi-user";
                break;
            }
            case "send": {
                _className += " pi pi-send";
                break;
            }
            case "key": {
                _className += " pi pi-key";
                break;
            }
            case "lock":
                {
                    _className += " pi pi-lock";
                    break;
                }
            case "un_lock":
                {
                    _className += " pi pi-lock-open";
                    break;
                }
            default: {
                break;
            }
        }
        return _className;
    }
    filter(e) {
        const schemePtr = schemeHelper.create(this.props.scheme);
        let filter = e.target.value.toLowerCase();
        this.flex.collectionView.filter = item => {
            let result = false;
            for (let i = 0; i < schemePtr.columns.length; i++) {
                if (!schemePtr.columns[i].body || schemePtr.columns[i].body !== 'button') {
                    let r = item[schemePtr.columns[i].name] + "";
                    r = r.toString().toLowerCase().indexOf(filter) > -1;
                    if (r) {
                        result = true;
                        break;
                    }
                }
            }
            return (filter.length === 0 || result);
        };
    }

    initialized(flexgrid) {
        this.flex = flexgrid;
        this.flex.selectionMode = wjcCore.asEnum(3, wjGrid.SelectionMode);
        this.flex.columnHeaders.rows.defaultSize = 50;

        //////////// Add Footer Row
        let schemePtr = schemeHelper.create(this.props.scheme);

        this.setState({ flex: flexgrid });
        if (schemePtr.showFooter) {
            this.flex.columnFooters.rows.push(new wjGrid.GroupRow());
            this.flex.bottomLeftCells.setCellData(0, 0, '');
        }
        //////////// Responsive Mode
        this.flex.formatItem.addHandler((s, e) => {
            // show rows as cards on narrow layout            
            let narrow = this.flex.hostElement.clientWidth < 400;//600;
            if (e.panel === s.cells && e.col === schemePtr.responsiveColumnIndex && narrow) {
                let format = this.getColumnReponsive();
                let html = wjcCore.format(format, s.rows[e.row].dataItem);
                e.cell.innerHTML = html;
                let hiddenColCount = 0;
                for (let index = 0; index < schemePtr.columns.length; index++) {
                    if (schemePtr.columns[index].visible === false) {
                        hiddenColCount++;
                    }
                    else {
                        if (schemePtr.columns[index].buttonBody && schemePtr.columns[index].buttonBody === 'button') {
                            let temp = index;
                            temp = (temp - hiddenColCount) * 2;
                            e.cell.childNodes[temp].addEventListener('click', this.onButtonClick.bind(this, index, s.rows[e.row].dataItem));
                        }
                    }
                }
            }
        });
        //////////// Custome Footer Calculation
        this.flex.itemsSourceChanged.addHandler((s, e) => {
            if (schemePtr.showFooter) {
                let columns = schemePtr.columns;
                columns = columns.filter(col => col.customAggregate === true && (col.dataType === "Time" || col.dataType === "Object"));
                if (columns && columns.length > 0 && flexgrid.itemsSource) {
                    let total = { hours: 0, minutes: 0 };
                    columns.forEach(col => {
                        total = { days: 0, hours: 0, minutes: 0 };
                        flexgrid.itemsSource.forEach(function (item) {

                            let value = item[col.name];
                            if (!value)
                                value = '0:0'
                            var parts = []
                            if ((value + "").includes(" ")) {
                                parts = value.split(" ");
                                total.days += parseInt(parts[0]);
                            }
                            if ((value + "").includes(":")) {
                                parts = value.split(":");
                            }
                            else {
                                parts.push(0)
                                parts.push(0)
                            }

                            var temp;
                            temp = (+parts[1] + total.minutes);
                            total.minutes = temp % 60;
                            total.hours = (+parts[0] + total.hours) + (temp - total.minutes) / 60;
                        });
                        if (isNaN(total.days))
                            total.days = 0
                        if (isNaN(total.hours))
                            total.hours = 0
                        if (isNaN(total.minutes))
                            total.minutes = 0

                        let transTime = this.zeroPad(total.hours) + ":" + this.zeroPad(total.minutes);
                        if (total.days && total.days > 0) {
                            transTime = total.days + " " + transTime;
                        }

                        if (!transTime.startsWith("Na")) {
                            flexgrid.columnFooters.setCellData(flexgrid.columnFooters.rows.length - 1, schemePtr.columns.indexOf(col), transTime, true);
                        }
                    });
                }
            }
            this.loadGridState();
            this._updateGridLayout();
        });

        //////////// Context Menu
        if (this.props.showContextMenu !== false) {
            new FlexGridContextMenu(flexgrid, this.props.scheme, this.showColumnPicker, this.props.exportFileName, this.saveGridState);
        }

        if (this.props.checkBoxSelector) {
            this.selector = new Selector(flexgrid, {
                itemChecked: (s, e) => {
                    this.setState({
                        selectedItems: flexgrid.rows.filter(r => r.isSelected)
                    });
                }
            });
        }

        // Double Click
        if (this.props.onRowDoubleClick) {
            flexgrid.hostElement.addEventListener('dblclick', e =>
                flexgrid.selection && flexgrid.selection._row > -1 ?
                    this.props.onRowDoubleClick(flexgrid.rows[flexgrid.selection._row].dataItem) : undefined
            );
        }

        if (this.props.onKeyDown) {
            this.flex.hostElement.addEventListener('keydown', (e) => {
                this.props.onKeyDown(this.flex, e);
            })
        }
    }

    select = (row, col) => {
        if (this.flex)
            this.flex.select(row, col);
    };
    focus = () => {
        if (this.flex)
            this.flex.focus();
    };
    startEditing = (row, col) => {
        //this.flex.startEditing(true, row, col, true);
    }

    scrollIntoView = (row, col) => {
        this.flex.scrollIntoView(true, this.flex.scrollPosition = {
            ...this.flex.scrollPosition,
            x: this.flex.scrollSize.width,
            y: this.flex.scrollSize.height,
        });

    }

    zeroPad = (num) => {
        var str = num.toString();
        if (str.length < 2) {
            str = "0" + str;
        }
        return str;
    }
    getColumnReponsive = () => {
        const schemePtr = schemeHelper.create(this.props.scheme);
        let format = [];
        let columns = schemePtr.columns;
        if (this.state.columns) {
            columns = this.state.columns;
        }
        if (!columns || columns.length <= 0) {
            return;
        }
        for (let index = 0; index < columns.length; index++) {
            if (columns[index].visible === false) {
                continue;
            }
            let col = columns[index];

            let name = col.name;
            let caption = col.header + '';
            if (caption && caption.length > 0) {
                caption += ': ';
            }
            if (this.state.narrow) {
                // Responsive
                let objCol = this.props.scheme.columns.find(e => e.name === col.name);

                if (objCol && objCol.buttonBody && objCol.buttonBody === 'button') {
                    let buttonHtml = '<button style="margin-top:10px;margin-bottom:10px;" class=\'' + this.setButtonClass(objCol.className, objCol.iconType) + '\' ';
                    buttonHtml += '></button>';
                    format.push(buttonHtml)
                }
                else {
                    format.push('<div class="item-detail">' + caption + '{' + name + '}</div>');
                }
            }
            else {
                if (col.buttonBody && col.buttonBody === 'button') {
                    let buttonHtml = '<button style="margin-top:10px;margin-bottom:10px;" class=\'' + this.setButtonClass(col.className, col.iconType) + '\' ';
                    buttonHtml += '></button>';
                    format.push(buttonHtml)
                }
                else {
                    format.push('<div class="item-detail">' + caption + '{' + name + '}</div>');
                }
            }

        }

        let html = format.join(' ');

        return html;
    }

    onButtonClick(colIndex, item) {

        const schemePtr = schemeHelper.create(this.props.scheme);
        let ctx = { item: item }
        schemePtr.columns[colIndex].onClick(null, ctx)
    }

    _updateGridLayout = () => {
        const schemePtr = schemeHelper.create(this.props.scheme);
        // show/hide columns
        let narrow = this.flex.hostElement.clientWidth < 400;
        this.flex.columns.forEach(col => {
            col.visible = col.index === schemePtr.responsiveColumnIndex ? narrow : !narrow;
            if (!narrow) {
                for (let index = 0; index < schemePtr.columns.length; index++) {
                    if (schemePtr.columns[index].name === col.name) {
                        col.visible = schemePtr.columns[index].visible === false ? false : true;
                        break;
                    }
                }
            }
        });


        // make rows taller on phone layout
        let columns = schemePtr.columns.filter(col => col.visible === undefined || col.visible === null || col.visible === true)
        let visibleColumnsCount = schemePtr.columns.length;

        if (columns && columns.length > 0) {
            visibleColumnsCount = columns.length;
        }
        //this.flex.rows.defaultSize = this.state._defaultRowHeight * (narrow ? (visibleColumnsCount + 3) : 1); // if autoRowHeight===false;
        if (narrow) {
            this.flex.rows.defaultSize = this.state._defaultRowHeight * (narrow ? (visibleColumnsCount + 3) : 1); // if autoRowHeight===false;
            this.flex.rows.defaultSize = this.flex.rows.defaultSize / 1.5;
        }
        else {
            if (!this.props.defaultRowHeight) {
                this.flex.rows.defaultSize = this.state._defaultRowHeight * (narrow ? (visibleColumnsCount + 3) : 1);
            }
            else {
                this.flex.rows.defaultSize = this.props.defaultRowHeight * (narrow ? (visibleColumnsCount + 3) : 1);
            }
        }
        //this.flex.rows.defaultSize = this.state._defaultRowHeight; // if autoRowHeight===true;
        // hide column headers on narrow layouts
        this.flex.headersVisibility = narrow ? "None" : "Column";
        this.setState({ narrow: narrow });
    }

    getColumns = () => {
        let listOfColumns = [];
        let narrow = this.flex ? this.flex.hostElement.clientWidth < 400 : false;
        let objResult = { isMobile: narrow, listOfColumns: [] }
        if (narrow) {
            const schemePtr = schemeHelper.create(this.props.scheme)
            let columns = schemePtr.columns;
            if (this.state.columns) {
                columns = this.state.columns;
            }
            if (!columns || columns.length <= 0) {
                return;
            }

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].visible === false) {
                    continue;
                }
                let col = columns[index];
                let objGridCol = this.flex.columns.find(gridCol => gridCol.name === col.name);
                if (objGridCol) {
                    objGridCol.columnWidth = col.columnWidth;
                    listOfColumns.push(objGridCol);
                }
            }
        }
        else {
            let columns = this.flex.columns; //schemePtr.columns.filter(col => col.visible === undefined || col.visible === null || col.visible === true)          
            const schemePtr = schemeHelper.create(this.props.scheme);
            if (schemePtr.columns) {
                for (let i = 0; i < columns.length; i++) {
                    let obj = schemePtr.columns.find((e) => e.name === columns[i].name);
                    if (obj && columns[i].visible) {
                        columns[i].columnWidth = obj.columnWidth;
                        listOfColumns.push(columns[i]);
                    }
                }
            }
        }
        objResult.listOfColumns = listOfColumns;
        return objResult;
    };

    // Print Grid
    printDoc() {
        // create PrintDocument
        let dir = $.strings.dir;
        let doc = new wjcCore.PrintDocument({
            title: "HR Self-Service",
            copyCss: false // prevent cross-origin issues in jsfiddle            
        });
        doc.addCSS("direction", dir);
        // add CSS explicitly (since we can't use copyCss in jsfiddle)
        doc.append('<link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet">');
        doc.append('<link href="https://cdn.grapecity.com/wijmo/5.latest/styles/wijmo.min.css" rel="stylesheet">');
        // add some simple text        
        doc.append("<div style=\"direction:" + dir + ";text-align:center \">");
        doc.append("<h1>قائمة الموظفين</h1>");
        doc.append("</div>");
        doc.append("<div style=\"direction:" + dir + "\">");
        doc.append("<p> قائمة الموظفين الخاصة<b> بشركة اسراء للبرمجة والكمبيوتر </b></p>");
        // add a printer-friendly version of a FlexGrid to the document
        doc.append("<p>نابلس شارع فيصل هاتف 2373001 فاكس 2373002</p>");
        doc.append("</div>");
        let tbl = this.renderTable(dir);
        doc.append(tbl);
        // print the document
        doc.print();
    }
    // renders grid as a table
    renderTable(direction) {
        // start table
        let tbl = "<table style=\"direction:" + direction + ";border:1px solid black;border-collapse: collapse \">";
        // headers
        if (this.flex.headersVisibility & wjGrid.HeadersVisibility.Column) {
            tbl += "<thead style=\"border:1px solid black\">";
            for (let r = 0; r < this.flex.columnHeaders.rows.length; r++) {
                tbl += this.renderRow(this.flex.columnHeaders, r, true);
            }
            tbl += "</thead>";
        }
        // body
        tbl += "<tbody style=\"border:1px solid black\">";
        for (let r = 0; r < this.flex.rows.length; r++) {
            tbl += this.renderRow(this.flex.cells, r, false);
        }
        tbl += "</tbody>";
        // footers        
        if (this.flex.columnFooters && this.flex.columnFooters.rows.length > 0) {
            tbl += "<tfoot style=\"border:1px solid black\">";
            for (let r = 0; r < this.flex.columnFooters.rows.length; r++) {
                tbl += this.renderRow(this.flex.columnFooters, r, true);
            }
            tbl += "</tfoot>";
        }
        // done
        tbl += "</table>";

        return tbl;
    }
    renderRow(panel, r, isColumnHeader) {
        let tr = "", row = panel.rows[r];
        if (row.renderSize > 0) {
            tr += "<tr>";
            for (let c = 0; c < panel.columns.length; c++) {
                let col = panel.columns[c];
                if (col.renderSize > 0 && !col.cellTemplate) {
                    // get cell style, content
                    let style = "width:" + col.renderSize + "px;";
                    if (isColumnHeader) {
                        style += "text-align:center;";
                    }
                    else {
                        style += "text-align:" + col.getAlignment() + ";";
                    }
                    style += "padding-right: 6px;border:1px solid black;";

                    let content = panel.getCellData(r, c, true);
                    if (!row.isContentHtml && !col.isContentHtml) {
                        content = wjcCore.escapeHtml(content);
                    }
                    // add cell to row
                    switch (panel.cellType) {
                        case wjGrid.CellType.ColumnHeader: {
                            tr += '<th style="' + style + '">' + content + "</th>";
                            break;
                        }
                        case wjGrid.CellType.ColumnFooter: {
                            style += "bgcolor:Black;color:white;"
                            tr += '<th style="' + style + '">' + content + "</th>";
                            break;
                        }
                        default: {
                            // show boolean values as checkboxes
                            let raw = panel.getCellData(r, c, false);
                            if (raw === true) {
                                content = "&#9745;";
                            }
                            else if (raw === false) {
                                content = "&#9744;";
                            }
                            tr += '<td style="' + style + '">' + content + "</td>";
                            break;
                        }
                    }

                }
            }
            tr += "</tr>";
        }
        return tr;
    }

    // Export Grid To PDF
    exportPDF() {
        FlexGridPdfConverter.export(this.flex, this.props.exportFileName ? this.props.exportFileName : 'FlexGrid.pdf', {
            maxPages: 10,
            exportMode: this.state.exportMode,
            scaleMode: this.state.scaleMode,
            documentOptions: {
                pageSettings: {
                    layout: this.state.orientation
                },
                header: {
                    declarative: {
                        text: '\t&[Page]\\&[Pages]'
                    }
                },
                footer: {
                    declarative: {
                        text: '\t&[Page]\\&[Pages]'
                    }
                }
            },
            embeddedFonts: [
                {
                    source: 'resources/fira/Scheherazade-Regular.ttf',
                    name: 'fira',
                    style: 'normal',
                    weight: 'normal',
                    sansSerif: true
                },
                {
                    source: 'resources/fira/Scheherazade-Bold.ttf',
                    name: 'fira',
                    style: 'normal',
                    weight: 'bold',
                    sansSerif: true
                },
                {
                    source: 'resources/fira/simpo.ttf',
                    name: 'fira',
                    style: 'normal',
                    weight: 'bold',
                    sansSerif: true
                },
                {
                    source: 'resources/fira/SimplifiedArabicBold.ttf',
                    name: 'fira',
                    style: 'normal',
                    weight: 'bold',
                    sansSerif: true
                },
                {
                    source: 'resources/fira/simpbdo.ttf',
                    name: 'fira',
                    style: 'normal',
                    weight: 'bold',
                    sansSerif: true
                },
                {
                    source: 'resources/fira/TarifArabic-Regular.ttf',
                    name: 'fira',
                    style: 'normal',
                    weight: 'bold',
                    sansSerif: true
                }
            ],
            styles: {
                cellStyle: {
                    backgroundColor: '#ffffff',
                    borderColor: '#c6c6c6'
                },
                altCellStyle: {
                    backgroundColor: '#f9f9f9'
                },
                groupCellStyle: {
                    backgroundColor: '#dddddd'
                },
                headerCellStyle: {
                    backgroundColor: '#eaeaea'
                }
            }
        });
    }

    // Export To Excel
    exportExcel() {
        gridXlsx.FlexGridXlsxConverter.saveAsync(this.flex, {}, this.props.exportFileName ? this.props.exportFileName : 'FlexGrid.xlsx', null, null, progress => this.setState({ progress: progress }), true);
    }
    //
    cancel() {
        gridXlsx.FlexGridXlsxConverter.cancelAsync(() => this.setState({ progress: 0 }));
    }

    exportCSV() {
        let rng = new wjGrid.CellRange(0, 0, this.flex.rows.length - 1, this.flex.columns.length - 1), csv = this.flex.getClipString(rng, wjGrid.ClipStringOptions.CSV, true, false);
        saveFile(csv, this.props.exportFileName ? this.props.exportFileName + '.csv' : 'FlexGrid.csv');
    }

    // Column Picker (Chooser)
    initializedPicker(picker) {
        this.columnPicker = picker;
    }
    showColumnPicker = () => {
        if (this.flex) {
            this.columnPicker.itemsSource = this.flex.columns;
            this.columnPicker.collectionView.filter = (item) => {
                return this.props.scheme.columns.find(e => e.name + "" === item._name + "" && e.visibleInColumnChooser !== false);
            }
            //this.columnPicker.itemsSource = this.props.scheme.columns.filter(e => e.visibleInColumnChooser !== false);
            this.columnPicker.checkedMemberPath = 'visible';
            this.columnPicker.displayMemberPath = 'header';
            this.columnPicker.lostFocus.addHandler(() => {
                hidePopup(this.columnPicker.hostElement);
            });

            let ref = this.flex.hostElement.querySelector(".wj-topleft");
            let host = this.columnPicker.hostElement;
            if (!host.offsetHeight) {
                showPopup(host, ref, false, true, false);
                this.columnPicker.focus();
            }
            else {
                hidePopup(host, true, true);
                this.flex.focus();
            }
            this.columnPicker.focus();
        }
    }

    getCurrentRowItem = () => {
        if (!this.flex.selectedItems) return undefined;
        return this.flex.selectedItems.length > 0 ? this.flex.selectedItems[0] : undefined;
    };
}