import '@grapecity/wijmo.styles/wijmo.css';
import './Absence.css';
import * as React from 'react';
import * as wijmo from '@grapecity/wijmo';
import * as wjChart from '@grapecity/wijmo.react.chart';
import * as wjCharts from '@grapecity/wijmo.chart';
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation';
import http from '../../../../Api/http'
import ProgressSpinner from '../../../ProgressSpinner';

const $ = window.$

export default class Absence extends React.Component {
    constructor(props) {
        super(props);
        this.palette = ['rgba(181,72,54,1)', 'rgba(119,179,0,1)', 'rgba(153,51,204,1)', 'rgba(255,136,0,1)',
            'rgba(204,0,0,1)', 'rgba(0,204,163,1)', 'rgba(61,109,204,1)', 'rgba(82,82,82,1)', 'rgba(0,0,0,1)'
        ]
        this.state = {
            loading: true,
            isDesktop: true,
            dataAbsence: []
        }
    }

    _updateChartHeaderAbsence = (group) => {
        let item = group.items ? group.items[0] : [], path = '', headers = [];
        for (let i = 0; i <= group.level; i++) {
            let prop = this.state.dataAbsence.groupDescriptions[i].propertyName;
            let hdr = wijmo.format('<a href="#{path}">{prop}</a>: {value}', {
                path: path,
                prop: $.strings.charts[wijmo.toHeaderCase(prop)],
                value: item[prop]
            });
            headers.push(hdr);
            path += '/' + item[prop];
        }
        this.header.innerHTML = headers.length > 0
            ? /*$.strings.charts.absenceTitle + ' ' +*/ headers.join(', ')
            : '';//$.strings.charts.absenceTitle;
    }
    initializeAbsenceChart = (flex) => {
        this.theChartAbsence = flex;
        this.header = document.querySelector('#absenceHeader');
        if (this.header) {
            this.header.addEventListener('click', (e) => this._onHeaderClickAbsence(e));
        }
    };
    absenceSelectionChanged = () => {
        if (this.theChartAbsence.selection) {
            let point = this.theChartAbsence.selection.collectionView.currentItem;
            if (point && point.group && !point.group.isBottomLevel) {
                this.showGroupAbsence(point.group, true, this.theChartAbsence.selection);
            }
        }
    };
    _onHeaderClickAbsence = (e) => {
        if (e.target instanceof HTMLAnchorElement) {
            e.preventDefault();
            //
            // get the link path
            let path = e.target.href;
            path = path.substr(path.lastIndexOf('#') + 1);
            let paths = path.split('/');

            // find the group that matches the path
            let src = this.state.dataAbsence;
            for (let i = 1; i < paths.length; i++) {
                for (let j = 0; j < src.groups.length; j++) {
                    let group = src.groups[j];
                    if (group.name === paths[i]) {
                        src = group;
                        break;
                    }
                }
            }
            // show the selected group
            this.showGroupAbsence(src);
        }
    };
    showGroupAbsence = async (group, isDrillDown, selected) => {
        // update titles
        this._updateChartHeaderAbsence(group);
        var level = 'level' in group ? group.level + 1 : 0;
        //this.theChartAbsence.axisX.title = $.strings.charts[wijmo.toHeaderCase(this.state.dataAbsence.groupDescriptions[level].propertyName)];
        //
        // update the series color (use a different one for each level)
        var palette = this.theChartAbsence.palette || wjCharts.Palettes.standard;
        this.theChartAbsence.series[0].style = {
            fill: palette[level],
            stroke: palette[level]
        };
        //
        // update data
        this.theChartAbsence.itemsSource = this.getGroupData(group, isDrillDown, selected);
        this.theChartAbsence.selection = null;
    };



    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;
        let data = await this.getData();
        this.setState({
            dataAbsence: data, groupedViewDataAbsence: this.getGroupData(data), isDesktop: _isDesktop,
            palette: this.palette, loading: false
        });
    }
    render() {

        // return <div className="container-fluid">
        //     <ProgressSpinner loading={this.state.loading} />
        //     <h4 id="absenceHeader">{$.strings.charts.absenceTitle}</h4>
        //     <wjChart.FlexChart bindingX="name" selectionMode="Point" itemsSource={this.state.groupedViewDataAbsence} selectionChanged={this.absenceSelectionChanged}
        //         initialized={this.initializeAbsenceChart} palette={this.state.palette}>
        //         <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
        //         <wjChart.FlexChartSeries binding="Absence" name={$.strings.charts.Absence} ></wjChart.FlexChartSeries>
        //         <wjChart.FlexChartSeries binding="SectionCount" name={$.strings.charts.empCount} ></wjChart.FlexChartSeries>

        //         <wjChart.FlexChartAxis wjProperty="axisX" title=""></wjChart.FlexChartAxis>
        //         <wjChart.FlexChartAxis wjProperty="axisY" title="" ></wjChart.FlexChartAxis>
        //         <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
        //     </wjChart.FlexChart>

        // </div>;

        return (
            <>
            <span id="absenceHeader">{$.strings.charts.absenceTitle}</span>
            <wjChart.FlexChart bindingX="name" selectionMode="Point" itemsSource={this.state.groupedViewDataAbsence} selectionChanged={this.absenceSelectionChanged}
                initialized={this.initializeAbsenceChart} palette={this.state.palette}>
                <wjChart.FlexChartLegend position="None"></wjChart.FlexChartLegend>
                <wjChart.FlexChartSeries binding="Absence" name={$.strings.charts.Absence} ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries binding="SectionCount" name={$.strings.charts.empCount} ></wjChart.FlexChartSeries>

                <wjChart.FlexChartAxis wjProperty="axisX" title=""></wjChart.FlexChartAxis>
                <wjChart.FlexChartAxis wjProperty="axisY" title="" ></wjChart.FlexChartAxis>
                <wjChartAnimate.FlexChartAnimation></wjChartAnimate.FlexChartAnimation>
            </wjChart.FlexChart>
            </>
        )
    }

    getData = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            dtpFromDate: new Date(),
            dtpToDate: new Date(),
            fromCode: '',
            toCode: ''
        }
        let res = await http.getJsonAxios(http.actions.Dashboards.urlGetAbsenseDashboard, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }

        return new wijmo.CollectionView(dataTemp, {
            groupDescriptions: ['Section', 'EmpName']
        });
    }

    getGroupData = (group, isDrillDown, selected) => {
        // get items for this group, aggregate by sales
        let arr = [];
        if (isDrillDown) {
            group.groups.forEach(function (g) {
                if (selected && selected._binding === "SectionCount") {
                    arr.push({
                        name: g.name,
                        Absence: g.getAggregate(wijmo.Aggregate.Sum, 'Absence'),
                        group: g
                    });
                }
                else {
                    if (g.items && g.items.length > 0 && g.items[0].Absence > 0) {
                        arr.push({
                            name: g.name,
                            Absence: g.getAggregate(wijmo.Aggregate.Sum, 'Absence'),
                            group: g
                        });
                    }
                }
            });
        }
        else {
            group.groups.forEach(function (g) {
                arr.push({
                    name: g.name,
                    Absence: g.getAggregate(wijmo.Aggregate.Cnt, 'Absence'),
                    SectionCount: g.getAggregate(wijmo.Aggregate.Cnt, 'Section'),
                    group: g
                });
            });
        }
        // return a new collection view sorted by sales
        return new wijmo.CollectionView(arr, {
            sortDescriptions: [
                new wijmo.SortDescription('Absence', false),
            ]
        });
    }
}