import React from 'react';
import Button from '../../Components/Button';
import Dialog from '../../Components/Dialog';
import Checkbox from '../../Components/Checkbox';

const $ = window.$;

export default class EditRights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pageInfo: {
        pageId: 0,
        title: $.strings.PermissionsRolesAccess.access_name,
      },
      formErrors: { oldPwd: '', newPwd: '', confirmPwd: '' },
      id: 0,
      is_granted: 0,
      role_is_granted: 0,
      with_granted: 0,
      isDesktop: window.innerWidth > 800,
    };
  }

  componentDidMount = async () => {
    this.show(this.props.id, this.props.is_granted, this.props.role_is_granted, this.props.with_granted);
    //this.setState({ isDesktop: _isDesktop, loading: false });
  };

  handleUserInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    this.setState({ [name]: value });
  };

  render() {
    const footer = (
      <div style={{ textAlign: 'center' }}>
        <Button label={$.strings.saveData} icon="pi pi-check" className="p-button-success" onClick={this.onSave} />
        <Button label={$.strings.cancel} icon="pi pi-times" className="p-button-danger" onClick={this.onHide} />
      </div>
    );

    const myIcon = <button className="p-dialog-titlebar-icon p-link"></button>;
    return !this.state.with_granted ? (
      <div>
        <Dialog
          header={$.strings.PermissionsRolesAccess.access_name}
          footer={footer}
          icons={myIcon}
          visible={this.state.visibleDialog}
          style={{
            width: this.state.isDesktop ? '20vw' : '50vw',
            direction: $.strings.dir,
          }}
          modal
          onHide={this.onHide}
        >
          <div ref="divMain">
            <div className="p-grid">
              <div className="p-col-12 p-lg-12 p-sm-12">
                <Checkbox
                  id={this.state.id}
                  caption={$.strings.PermissionsRolesAccess.is_granted}
                  checked={this.state.is_granted}
                  onChange={(e) => this.setState({ is_granted: e.checked })}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    ) : (
      <div>
        <Dialog
          header={$.strings.PermissionsRolesAccess.access_name}
          footer={footer}
          icons={myIcon}
          visible={this.state.visibleDialog}
          style={{
            width: this.state.isDesktop ? '20vw' : '50vw',
            direction: $.strings.dir,
          }}
          modal
          onHide={this.onHide}
        >
          <div ref="divMain">
            <div className="p-grid">
              <div className="p-col-12 p-lg-6 p-sm-12">
                <Checkbox
                  id={this.state.id}
                  caption={$.strings.PermissionsRolesAccess.is_granted}
                  checked={this.state.is_granted}
                  onChange={(e) => this.setState({ is_granted: e.checked })}
                />
              </div>
              <div className="p-col-12 p-lg-6 p-sm-12">
                <Checkbox
                  id={this.state.id}
                  caption={$.strings.PermissionsUsersAccess.role_is_granted}
                  checked={this.state.role_is_granted}
                  onChange={(e) => this.setState({ role_is_granted: e.checked })}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
  show = (id, is_granted, role_is_granted, with_granted) => {
    this.setState({
      visibleDialog: true,
      id: id,
      is_granted: is_granted,
      role_is_granted: role_is_granted,
      with_granted: with_granted,
      loading: false,
      isDesktop: window.innerWidth > 800,
    });
  };

  onHide = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({ id: 0, is_granted: false, role_is_granted: false, visibleDialog: false });
  };

  onSave = async () => {
    if (this.props.onSuccess) {
      this.props.onSuccess(this.state.id, this.state.is_granted, this.state.role_is_granted);
    }
    this.setState({ visibleDialog: false });
  };
}
