import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollTop from '../ScrollButton';
import ClockTransEdit from "./ClockTransEdit";
import Messages from "../../Components/Messages";
import Print from "../../Print";

const $ = window.$;

export default class ClockTransRpt extends React.Component {
    constructor(props) {
        super(props);
        this.transTypeList = [
            { id: 0, name: $.strings.all },
            { id: 1, name: $.strings.clockTrans.correctTransOnly },
            { id: 2, name: $.strings.clockTrans.wrongTransOnly }
        ]
        this.state = {
            isDesktop: window.innerWidth > 1250,
            transTypeList: this.transTypeList,
            fromCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            toCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            transType: this.transTypeList[0],
            fromDate: new Date(),
            toDate: new Date(),
            filterShown: true,
            data: []
        };
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(709)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!Util.checkRights(961)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();

        let shiftsPromise = this.getShifts();
        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();

        let shiftsList = await shiftsPromise;
        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        let empCode = ''
        if (this.props.match.params.id) {
            empCode = this.props.match.params.id;
            if (empCode !== localStorage.getItem("useridHR") + "") {
                let obj = await this.getEmployeeInfo(empCode);
                empCode = obj.Code;
            }
        }
        else {
            empCode = !Util.checkISHR() ? localStorage.getItem("useridHR") : ''
        }

        this.setState({
            empCode: empCode,
            shiftsList: shiftsList, branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            shift: shiftsList && shiftsList.length > 0 ? shiftsList[0] : undefined,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../MyStaff' },
            { label: $.strings.clockTrans.reportTitle }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]}>
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.clockTrans.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.clockTrans.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.clockTrans.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.clockTrans.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-6">
                            <Dropdown
                                id="transType"
                                innerRef={e => this.drpTransType = e}
                                value={this.state.transType}
                                options={this.state.transTypeList}
                                caption={$.strings.clockTrans.transType}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                    </div>
                    {
                        Util.checkISHR() &&
                        <div className="p-grid">
                            <div className="p-col-6 p-lg-2 p-sm-6">
                                <Dropdown
                                    id="shift"
                                    innerRef={e => this.drpShifts = e}
                                    value={this.state.shift}
                                    options={this.state.shiftsList}
                                    caption={$.strings.clockTrans.shift}
                                    onChange={this.handleUserInput}
                                    optionLabel="ShiftName"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-6">
                                <Dropdown
                                    id="section"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.section}
                                    options={this.state.sectionsList}
                                    caption={$.strings.clockTrans.section}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="branch"
                                    value={this.state.branch}
                                    options={this.state.branchesList}
                                    caption={$.strings.clockTrans.branch}
                                    onChange={this.handleUserInput}
                                    optionLabel="Name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="department"
                                    innerRef={e => this.drpSections = e}
                                    value={this.state.department}
                                    options={this.state.departmentsList}
                                    caption={$.strings.clockTrans.department}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                        </div>
                    }
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            style={gridStyle}
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.getScheme()}
                            exportFileName={$.strings.clockTrans.reportTitle}
                            dataSource={this.state.data}
                            defaultRowHeight={45}
                            onRowDoubleClick={Util.checkISHR() ? this.onRowDoubleClick : undefined}
                        />
                    </div>
                </div>
            </Card>
            <ScrollTop />
            <ClockTransEdit ref={e => this.dlgClockTransEdit = e} onSuccess={this.clockTransEditSuccess} />
        </div >
    }

    onRowDoubleClick = (item) => {
        if (Util.checkISHR()) {
            this.dlgClockTransEdit.show(item.EmpNo, new Date(item.Date), 0);
        }
    }
    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }

    getShifts = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetShifts, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }
    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            fromCode: this.state.fromCode && this.state.fromCode.length > 0 ? this.state.fromCode : '00000',
            toCode: this.state.toCode && this.state.toCode.length > 0 ? this.state.toCode : 'ZZZZZ',
            shift: this.state.shift && this.state.shift.Code.length > 0 ? this.state.shiftsList.indexOf(this.state.shift) - 2 : -1,
            cmbShiftValue: this.state.shift && this.state.shift.Code.length > 0 ? this.state.shift.Code : '-1',
            isHr: Util.checkISHR(),
            empCode: localStorage.getItem("useridHR") + "",
            userId: localStorage.getItem("useridHR") + "",
            cmbDepartmentValue: this.state.department && this.state.department.pos >= 0 ? this.state.department.pos : -1,
            m_ShowTrans: this.state.transType.id,
            m_Sections: this.state.section && this.state.section.ID >= 0 ? this.state.section.ID : -1,
            branchId: this.state.branch && this.state.branch.Pos >= 0 ? this.state.branch.Pos : "-1",
        }

        let res = await http.getJsonAxios(http.actions.ClockTrans.urlGetClockTrans, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                dataTemp.forEach(element => {
                    element.Date = new Date(element.Date);
                });
            }
        }

        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.clockTrans.reportTitle, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";

            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.clockTrans.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.clockTrans.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.clockTrans.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.clockTrans.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter);

            if (this.printFilters.transType) {
                let name = this.printFilters.transType.name
                listOfFilters.push($.strings.clockTrans.transType + ": " + name);
            }

            if (Util.checkISHR()) {
                if (this.printFilters.shift) {
                    listOfFilters.push($.strings.clockTrans.shift + ": " + this.printFilters.shift.ShiftName);
                }
                if (this.printFilters.section) {
                    listOfFilters.push($.strings.clockTrans.section + ": " + this.printFilters.section.Name);
                }
                if (this.printFilters.branch) {
                    listOfFilters.push($.strings.clockTrans.branch + ": " + this.printFilters.branch.Name);
                }
                if (this.printFilters.department) {
                    listOfFilters.push($.strings.clockTrans.department + ": " + this.printFilters.department.name);
                }

            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    buttonClick = (e, ctx) => {
        //if (window.confirm($.strings.clockTrans.editConfirmation)) {
        this.dlgClockTransEdit.show(ctx.item.EmpNo, new Date(ctx.item.Date), 0);
        //}
    }
    clockTransEditSuccess = async () => {
        await this.getData();
    }
    getScheme = () => {
        return {
            name: "clockTransReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 6,
            columns: [
                { name: 'RowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '2' },
                {
                    name: 'buttonClick', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.clockTrans.clockTransEditTitle,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.buttonClick(e, ctx)
                },
                {
                    name: 'CardNo', header: $.strings.clockTrans.cardNo, width: 100, columnWidth: '2'
                },
                {
                    name: 'ClockNo', header: $.strings.clockTrans.clockNo, width: 100, columnWidth: '2'
                },
                {
                    name: 'ClockName', header: $.strings.clockTrans.clockName, width: 150, columnWidth: '*'
                },
                {
                    name: 'EmpNo', header: $.strings.clockTrans.empCode, width: 110, columnWidth: '2'
                },
                {
                    name: 'Name', header: $.strings.clockTrans.empName, width: 220, columnWidth: '*'
                },
                {
                    name: 'Date', header: $.strings.clockTrans.date, width: 100, dataType: 'Date', columnWidth: '3', format: Util.gridDateFormat(),
                },
                {
                    name: 'Day', header: $.strings.clockTrans.day, width: 100, columnWidth: '2'

                },
                {
                    name: 'Time', header: $.strings.clockTrans.time, width: 100, columnWidth: '2'
                },
                {
                    name: 'TransType', header: $.strings.clockTrans.transType, width: 100, columnWidth: '2'
                },
                {
                    name: 'exception', header: $.strings.clockTrans.exception, width: 100, columnWidth: '3'
                }
            ]
        }
    }
}