import React from "react";
import styles from "./GeneralLookups.module.scss";
import DataGridView from '../../../Components/DataGridView/DataGridView';
import ButtonsBarLookupList from "../../../Components/ButtonsBarLookupList";
import RadioButton from "../../../Components/RadioButton";
import Http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import { FaFlag } from 'react-icons/fa'
import Messages from '../../../Components/Messages';
import Util from '../../../Util'
import Button from '../../../Components/Button';
import Card from "../../../Components/Card";
import SiteMap from "../../../Components/SiteMap";
import GeneralAdd from "./GeneralAdd";
import ScreenCommonCode from "../../ScreenCommonCode";
import LookupsUserRights from "../LookupsUserRights/LookupsUserRights";

const $ = window.$;

export default class Lookups extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            data: [],
            id: 0,
            isDesktop: true
        };
    }

    componentDidMount = async () => {
        if (!$.license.HR_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR() || !Util.checkRights(539)) {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = window.innerWidth > 1250

        let dataTemp = await this.onRefresh(true);
        this.setState({ data: dataTemp, loading: false, isDesktop: _isDesktop });
    }

    render() {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: $.strings.links.staticFilesAndSettings },
            { label: this.state.pageInfo.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        return (

            < div ref={e => this.divMain = e} className={styles.Lookups} >
                <ProgressSpinner loading={this.state.loading} />
                <LookupsUserRights ref={e => this.dlgLookupsUserRights = e} />
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />

                    {ScreenCommonCode.getScreenHeader_Buttons(this, false)}
                    <Messages innerRef={(el) => this.messages = el} />
                    <GeneralAdd lookupType={this.props.lookupType} ref={e => this.dlgGeneralAdd = e} onAddSuccess={this.onAddSuccess} onAddClose={this.onAddClose} />
                </Card>
                <DataGridView
                    style={gridStyle}
                    ref={e => this.gridData = e}
                    isReport={true}
                    theme="default-light"
                    idProperty="serial"
                    scheme={this.getScheme()}
                    exportFileName={$.strings.clockTrans.reportTitle}
                    dataSource={this.state.data}
                    defaultRowHeight={45}
                    onRowDoubleClick={this.onRowDoubleClick}
                />
            </div >
        );
    }

    setPageInfo = () => {
        let pageId
        let title
        switch (this.props.lookupType) {
            case "3": { // Jobs
                title = $.strings.lookups.jobsTitle;
                break
            }
            case "4": { // PaidVacTypes
                title = $.strings.lookups.paidVacTypesTitle;
                break
            }
            case "7": { // Branches
                title = $.strings.lookups.branchesTitle;
                break
            }
            case "8": { // Departments
                title = $.strings.lookups.departmentsTitle;
                break
            }
            case "11": { // ClockList
                title = $.strings.lookups.clockTitle;
                break
            }
            case "12": { // EmployeesClass
                title = $.strings.lookups.empClassificationsTitle;
                break
            }
            case "22": { // ShiftTransTypes
                title = $.strings.lookups.shiftTransTypesTitle;
                break
            }
            case "25": { // Classifications
                title = $.strings.lookups.classificationTitle;
                break
            }
            case "26": { // InternalMemosTypes
                title = $.strings.lookups.internalMemosTypesTitle;
                break
            }
            case "29": { // Documents
                title = $.strings.lookups.documentsTitle;
                break
            }
            case "30": { // ShiftPeriodsTypes
                title = $.strings.lookups.shiftPeriodsTypesTitle;
                break
            }
            default: {
                title = "";
                break
            }
        }

        return { pageId: pageId, title: title };
    };

    onClose = async (e) => {
        this.onRefresh();
        this.setState({ dlgSearchVisible: false });
    };

    onRowDoubleClick = (item) => {
        alert(item.name);
    }


    btnRefresh = e => {
        this.setState({ data: this.onRefresh() });
    };

    onRefresh = async (fromLoad) => {
        let dataTemp = [];
        if (!fromLoad) {
            this.setState({ loading: true })
        }
        const params = {
            type: this.props.lookupType,
            pos: -1
        };


        let res = await Http.getJsonAxios(Http.actions.MultiFl.urlGetMultiFlByTypePos, params, this.state.pageInfo.pageId);
        if (res.status === 200) {
            dataTemp = res.data.dataObject;
            if (fromLoad) {
                return dataTemp;
            }
            else {
                this.setState({ data: dataTemp, loading: false })
            }
        }
    }

    onNew = e => {
        this.dlgGeneralAdd.show(-1)
    };

    onAddClose = (doRefreh) => {
        if (doRefreh)
            this.onRefresh(false)
    };

    onAddSuccess = result => {
        if (result) {
            this.onRefresh(false)
        }
    };

    onEditClick = (e, ctx) => {
        this.dlgGeneralAdd.show(ctx.item.Pos)
    }
    onUsersRights = (e, ctx) => {
        //alert(ctx.item.Name);
        this.dlgLookupsUserRights.show(ctx.item.Type, ctx.item.Pos);
    }
    getScheme = () => {
        return {
            name: "generalLookups_Table_" + this.props.lookupType,
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 4,
            columns: [
                {
                    name: 'Pos', header: $.strings.lookups.id, width: 100, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'btnEdit', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center',
                    title: $.strings.edit, iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEditClick(e, ctx)
                },
                {
                    name: 'btnUsers', header: ' ', width: 50, visible: Util.checkISHR() && (this.props.lookupType + "" === "7" || this.props.lookupType + "" === "22"),
                    buttonBody: 'button', align: 'center', title: $.strings.lookups.userRights,
                    iconType: 'key', className: 'info', onClick: (e, ctx) => this.onUsersRights(e, ctx)
                },
                {
                    name: 'Users', header: $.strings.lookups.users, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'Name', header: $.strings.lookups.name, width: this.state.isDesktop ? '*' : 300,
                },
                {
                    name: 'EName', header: $.strings.lookups.eName, width: this.state.isDesktop ? '*' : 300,
                },
                {
                    name: 'Symbol', header: $.strings.lookups.symbol, width: this.state.isDesktop ? '*' : 300,
                    visible: (this.props.lookupType + "" === "4" || this.props.lookupType + "" === "22")
                },
                {
                    name: 'ClassName', header: $.strings.lookups.showInWeb, width: this.state.isDesktop ? '*' : 300,
                    visible: (this.props.lookupType + "" === "4" || this.props.lookupType + "" === "29" || this.props.lookupType + "" === "26")
                }
            ]
        }
    }
}