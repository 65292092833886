import React from "react";
import Button from '../../../Components/Button'
import Messages from '../../../Components/Messages'
import http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import Dialog from '../../../Components/Dialog';
import Util from '../../../Util'
import Input from "../../../Components/Input";
import Dropdown from "../../../Components/Dropdown";
import { Fieldset } from "primereact/fieldset";
import InputTime from "../../../Components/InputTime";
import PeriodsSearch from "./PeriodsSearch";
import Card from "../../../Components/Card";
import ButtonsBarLookup from "../../../Components/ButtonsBarLookup";
import Spinner from "../../../Components/Spinner";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class PeriodsAdd extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.nowDate = new Date();
        this.nowDate.setHours(0, 0, 0);
        this.refreshRequired = false;
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: {},
            isDesktop: window.innerWidth > 800,
            visibleDialog: false,
            restPeriodDisabled: false,
            restValueDisabled: false,
            classificationsList: [],
            isViewOnly: false,
            dataObject: {
                id: -1,
                classification: undefined,
                fromTime: this.nowDate,
                toTime: this.nowDate,
                fromRest: this.nowDate,
                toRest: this.nowDate,
                restPeriod: this.nowDate,
                period: this.nowDate,
                periodDays: 1
            }
        }
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(944)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
            return;
        }

        window.addEventListener("resize", this.updatePredicate)

        this.setState({ classificationsList: [], loading: false })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate)
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    handleFromTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, fromTime: e.value } }, () => this.calcPeriodsHoursNo())
    }
    handleToTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, toTime: e.value } }, () => this.calcPeriodsHoursNo())
    }

    handleFromTimeRest = (e) => {
        this.setState({
            dataObject: { ...this.state.dataObject, fromRest: e.value }, restPeriodDisabled: false,
            restValueDisabled: this.checkDisabledField(e.value) || this.checkDisabledField(this.state.dataObject.toRest) ? true : false
        }, () => this.calcPeriodsHoursNo())
    }
    handleToTimeRest = (e) => {
        this.setState({
            dataObject: { ...this.state.dataObject, toRest: e.value }, restPeriodDisabled: false,
            restValueDisabled: this.checkDisabledField(e.value) || this.checkDisabledField(this.state.dataObject.fromRest) ? true : false
        }, () => this.calcPeriodsHoursNo())
    }

    handleRestPeriodTime = (e) => {
        this.setState({
            dataObject: { ...this.state.dataObject, restPeriod: e.value },
            restPeriodDisabled: this.checkDisabledField(e.value) ? true : false,
            restValueDisabled: false
        }, () => this.calcPeriodsHoursNo())
    }

    checkDisabledField = (value) => {
        if (value instanceof Date) {
            if (value.getHours() > 0 || value.getMinutes() > 0)
                return true;
        }
        else if (value !== "00:00") {
            return true;
        }

        return false;
    }

    calcPeriodsHoursNo = () => {
        let tempFromHours = 0, tempFromMinutes = 0, tempToHours = 0, tempToMinutes = 0;
        if (this.state.dataObject.fromTime instanceof Date) {
            tempFromHours = this.state.dataObject.fromTime.getHours();
            tempFromMinutes = this.state.dataObject.fromTime.getMinutes();
        }
        else {
            tempFromHours = parseInt(this.state.dataObject.fromTime.split(':')[0])
            tempFromMinutes = parseInt(this.state.dataObject.fromTime.split(':')[1])
        }

        if (this.state.dataObject.toTime instanceof Date) {
            tempToHours = this.state.dataObject.toTime.getHours();
            tempToMinutes = this.state.dataObject.toTime.getMinutes();
        }
        else {
            tempToHours = parseInt(this.state.dataObject.toTime.split(':')[0])
            tempToMinutes = parseInt(this.state.dataObject.toTime.split(':')[1])
        }

        let fromDate = new Date();
        fromDate.setHours(tempFromHours, tempFromMinutes, 0);

        let toDate = new Date();
        toDate.setHours(tempToHours, tempToMinutes, 0);


        var hoursTo = toDate.getHours();
        var hoursFrom = fromDate.getHours();
        if (hoursTo === 0)
            hoursTo = 24;

        var hoursNo = ((new Date(1, 1, 1, hoursTo, toDate.getMinutes()) -
            new Date(1, 1, 1, hoursFrom, fromDate.getMinutes())) / 60000);


        let resFromHours = 0, resFromMinutes = 0, resToHours = 0, resToMinutes = 0, resPeriodHours = 0, resPeriodMinutes = 0;
        if (this.state.dataObject.fromRest instanceof Date) {
            resFromHours = this.state.dataObject.fromRest.getHours();
            resFromMinutes = this.state.dataObject.fromRest.getMinutes();
        }
        else {
            resFromHours = parseInt(this.state.dataObject.fromRest.split(':')[0])
            resFromMinutes = parseInt(this.state.dataObject.fromRest.split(':')[1])
        }

        if (this.state.dataObject.toRest instanceof Date) {
            resToHours = this.state.dataObject.toRest.getHours();
            resToMinutes = this.state.dataObject.toRest.getMinutes();
        }
        else {
            resToHours = parseInt(this.state.dataObject.toRest.split(':')[0])
            resToMinutes = parseInt(this.state.dataObject.toRest.split(':')[1])
        }


        if (this.state.dataObject.restPeriod instanceof Date) {
            resPeriodHours = this.state.dataObject.restPeriod.getHours();
            resPeriodMinutes = this.state.dataObject.restPeriod.getMinutes();
        }
        else {
            resPeriodHours = parseInt(this.state.dataObject.restPeriod.split(':')[0])
            resPeriodMinutes = parseInt(this.state.dataObject.restPeriod.split(':')[1])
        }

        if (resFromHours > 0 || resToHours > 0) {
            let restPeriod = ((new Date(1, 1, 1, resToHours, resToMinutes) -
                new Date(1, 1, 1, resFromHours, resFromMinutes)) / 60000);
            if (restPeriod < 0)
                restPeriod = restPeriod * -1; //0;
            hoursNo = hoursNo - restPeriod;
            if (hoursNo < 0)
                hoursNo *= -1;
        }
        else if (resPeriodHours > 0 || resPeriodMinutes > 0) {
            hoursNo -= resPeriodHours * 60;
            hoursNo -= resPeriodMinutes;
        }

        let obj = Util.getNumberByTimeFormat(hoursNo);
        let hours = obj.hours + "";
        if (hours.length === 1)
            hours = "0" + hours;

        let minutes = obj.minutes + "";
        if (minutes.length === 1)
            minutes = "0" + minutes;

        let result = hours + ":" + minutes;
        this.setState({ dataObject: { ...this.state.dataObject, period: result } })
    }

    validateField() {
        let result = true;
        let fieldValidationErrors = this.state.formErrors;

        fieldValidationErrors.classification = "";
        if (!this.state.dataObject.classification || this.state.dataObject.classification.pos < 0) {
            result = false;
            fieldValidationErrors.classification = $.strings.requiredFiled;
        }

        fieldValidationErrors.fromTime = "";
        if (!this.state.dataObject.fromTime || this.state.dataObject.fromTime === "00:00" ||
            (this.state.dataObject.fromTime instanceof Date && this.state.dataObject.fromTime.getHours() === 0 &&
                this.state.dataObject.fromTime.getMinutes() === 0)) {
            result = false;
            fieldValidationErrors.fromTime = $.strings.requiredFiled;
        }

        fieldValidationErrors.toTime = "";
        if (!this.state.dataObject.toTime || this.state.dataObject.toTime === "00:00" ||
            (this.state.dataObject.toTime instanceof Date && this.state.dataObject.toTime.getHours() === 0 &&
                this.state.dataObject.toTime.getMinutes() === 0)) {
            result = false;
            fieldValidationErrors.toTime = $.strings.requiredFiled;
        }
        fieldValidationErrors.periodDays = "";
        if (!this.state.dataObject.periodDays) {
            result = false;
            fieldValidationErrors.periodDays = $.strings.requiredFiled;
        }

        this.setState({ formErrors: fieldValidationErrors })

        return result;
    }

    render() {

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <Dialog header={$.strings.periods.title}
                    icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '600px' : '80vw', direction: $.strings.dir }}
                    modal onHide={e => this.onHide(e, true)}>
                    <ProgressSpinner loading={this.state.loading} />
                    <PeriodsSearch ref={e => this.dlgPeriodsSearch = e} onSuccess={this.onSearchSuccess} />
                    <Card>
                        {
                            !this.state.isViewOnly &&
                            <ButtonsBarLookup
                                onNew={Util.checkRights(945) ? e => this.resetFields(e, true) : undefined}
                                onClose={e => this.onHide(e, true)}
                                onSave={this.checkAccessByMode() ? this.onSave : undefined}
                                onSaveThenClose={this.checkAccessByMode() ? e => this.onSaveThenClose(e) : undefined} />
                        }
                        <Messages innerRef={(el) => this.messages = el} />
                    </Card>

                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtCode = el}
                                    id="id"
                                    value={this.state.dataObject.id === -1 ? '' : this.state.dataObject.id}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.periods.code}
                                    readOnly={true}
                                />
                            </div>
                            {/* <div className="p-col-12 p-lg-1 p-sm-12">
                                <Button icon="pi pi-search" onClick={e => this.dlgPeriodsSearch.show()} />
                            </div> */}
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="classification"
                                    innerRef={e => this.drpClassification = e}
                                    value={this.state.dataObject.classification}
                                    options={this.state.classificationsList}
                                    caption={$.strings.periods.classification}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                            <div className="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                <Spinner
                                    id="periodDays"
                                    value={this.state.dataObject.periodDays}
                                    min={1}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, periodDays: e.value } })}
                                    caption={$.strings.periods.periodDays}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                        <Fieldset legend={$.strings.periods.period}>
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="fromTime"
                                        ref={e => this.dtpFromTime = e}
                                        value={this.state.dataObject.fromTime}
                                        caption={$.strings.periods.from}
                                        valueChanged={this.handleFromTime}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="toTime"
                                        ref={e => this.dtpToTime = e}
                                        value={this.state.dataObject.toTime}
                                        caption={$.strings.periods.to}
                                        valueChanged={this.handleToTime}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="period"
                                        value={this.state.dataObject.period}
                                        caption={$.strings.periods.period}
                                        //valueChanged={this.handleUserInput}
                                        isDisabled={true}
                                        isReadOnly={true}
                                        style={{ color: 'black', fontWeight: 'bold', fontSize: '13px' }}
                                    />
                                </div>
                            </div>
                        </Fieldset>
                        <Fieldset legend={$.strings.periods.rest}>
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="fromRest"
                                        ref={e => this.dtpFromRestTime = e}
                                        value={this.state.dataObject.fromRest}
                                        caption={$.strings.periods.from}
                                        valueChanged={this.handleFromTimeRest}
                                        isDisabled={this.state.restPeriodDisabled}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="toRest"
                                        ref={e => this.dtpToRestTime = e}
                                        value={this.state.dataObject.toRest}
                                        caption={$.strings.periods.to}
                                        valueChanged={this.handleToTimeRest}
                                        isDisabled={this.state.restPeriodDisabled}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <InputTime
                                        id="restPeriod"
                                        ref={e => this.dtpRestPeriod = e}
                                        value={this.state.dataObject.restPeriod}
                                        caption={$.strings.periods.restPeriod}
                                        valueChanged={this.handleRestPeriodTime}
                                        isDisabled={this.state.restValueDisabled}
                                    />
                                </div>
                            </div>
                        </Fieldset>
                    </div >
                </Dialog >
            </div>
        );
    }

    checkAccessByMode = () => {
        if (this.state.dataObject.id >= 0) // update
        {
            return Util.checkRights(946);
        }
        else // insert
        {
            return Util.checkRights(945);
        }
    }

    onSearchSuccess = (period) => {
        
    }

    show = async (pos, viewOnly) => {
        let obj = undefined;
        let classList = await this.getClassifications();
        if (pos >= 0) {
            obj = await this.getData(pos)
            
            this.setState({
                visibleDialog: true, formErrors: {}, loading: false,
                dataObject: {
                    ...this.state.dataObject,
                    id: obj ? obj.code : -1,
                    classification: obj && classList ? classList.find(e => e.pos + "" === obj.classification_id + "") : undefined,
                    fromTime: obj ? obj.fromTime : '00:00',
                    toTime: obj ? obj.toTime : '00:00',
                    fromRest: obj ? obj.fromRest1 : '00:00',
                    toRest: obj ? obj.toRest1 : '00:00',
                    restPeriod: obj ? obj.restPeriod : '00:00',
                    period: obj ? obj.period : '00:00',
                    periodDays: obj.periodDays
                }, classificationsList: classList || [],
                restValueDisabled: obj && obj.fromRest1 !== "00:00:00" ? true : false,
                restPeriodDisabled: obj && obj.restPeriod !== "00:00:00" ? true : false,
                isViewOnly: viewOnly
            }, () => setTimeout(() => { this.txtCode.focus(); this.hash = Util.hashState(this.state.dataObject) }, 10));
        }
        else {
            this.setState({
                visibleDialog: true, formErrors: {}, loading: false,
                dataObject: {
                    ...this.state.dataObject,
                    id: obj ? obj.code : -1,
                    classification: classList && classList.length > 0 ? classList[0] : undefined,
                    fromTime: obj ? obj.fromTime : '00:00',
                    toTime: obj ? obj.toTime : '00:00',
                    fromRest: obj ? obj.fromRest1 : '00:00',
                    toRest: obj ? obj.toRest1 : '00:00',
                    restPeriod: obj ? obj.restPeriod : '00:00',
                    period: obj ? obj.period : '00:00'
                }, classificationsList: classList || [],
                restValueDisabled: false, restPeriodDisabled: false, isViewOnly: viewOnly
            }, () => setTimeout(() => { this.txtCode.focus(); this.hash = Util.hashState(this.state.dataObject) }, 10));
        }
    }

    onHide = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
                async () => await this.onSaveThenClose(e, false),
                () => this.onHide(e, false)
            );
            return;
        }
        if (this.refreshRequired && this.props.onSuccess) {
            this.props.onSuccess()
        }
        this.setState({ visibleDialog: false, formErrors: {} })
    }

    getData = async (pos) => {
        this.setState({ loading: true });
        let dataTemp = undefined;
        let obj = {
            classPos: "",
            fromTime: "",
            totime: "",
            periodPos: pos
        }

        let res = await http.getJsonAxios(http.actions.Periods.urlGetPeriodsList, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp && dataTemp.length > 0)
                dataTemp = dataTemp[0];
        }
        return dataTemp
    }

    getClassifications = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 30
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.select, ename: $.strings.select })
            }
        }
        return dataTemp;
    }

    onSave = async (e) => {
        if (this.state.dataObject.id < 0 && !Util.checkRights(945)) {
            Util.showErrorMsg(this.messages, $.strings.periods.insertRightsError + " (945) ")
            return;
        }
        if (this.state.dataObject.id >= 0 && !Util.checkRights(946)) {
            Util.showErrorMsg(this.messages, $.strings.periods.editRightsError + " (946) ")
            return;
        }
        this.saveData(e, true, false);
    };

    onSaveThenClose = async (e) => {
        if (this.state.dataObject.id < 0 && !Util.checkRights(945)) {
            Util.showErrorMsg(this.messages, $.strings.periods.insertRightsError + " (945) ")
            return;
        }
        if (this.state.dataObject.id >= 0 && !Util.checkRights(946)) {
            Util.showErrorMsg(this.messages, $.strings.periods.editRightsError + " (946) ")
            return;
        }
        this.saveData(e, true, true);
    };

    saveData = async (e, fromSave, closeAfterSave) => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        this.setState({ loading: true })
        let files = [] // ملفات المرفقات
        let file = this.state.dataObject.uploadedFile2

        let obj = {
            Pos: this.state.dataObject.id,
            FromWork: this.state.dataObject.fromTime,
            ToWork: this.state.dataObject.toTime,
            FromRest1: this.state.dataObject.fromRest,
            ToRest1: this.state.dataObject.toRest,
            FromRest2: '00:00',
            ToRest2: '00:00',
            Period: this.state.dataObject.period,
            notused1: this.state.dataObject.classification.pos,
            Rest: this.state.dataObject.restPeriod,
            PeriodDays: this.state.dataObject.periodDays
        }

        let saveResult
        let res = await http.postMultipartAxios(http.actions.Periods.urlPostSavePeriods, obj, file, files, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (closeAfterSave) {
                    if (this.props.onSuccess)
                        this.props.onSuccess();
                    this.refreshRequired = true;
                    this.setState({ visibleDialog: false, loading: false });
                    return saveResult = true
                }
                else {

                    if (res.data.errorText)
                        Util.showSuccessMsg(this.messages, res.data.errorText);
                    else
                        Util.showSuccessMsg(this.messages);
                    this.resetFields(e, false)
                    this.hash = Util.hashState(this.state.dataObject)
                    this.setState({ loading: false }, () => this.hash = Util.hashState(this.state.dataObject))
                    this.refreshRequired = true;
                    return saveResult = true
                }
            }
        }
        if (res.data.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return saveResult = false
        }
    }
    resetFields = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
                async () => await this.onSave(e, false),
                () => this.resetFields(e, false)
            );
            return;
        }
        this.setState({
            dataObject: {
                ...this.state.dataObject,
                id: -1,
                classification: this.state.classificationsList && this.state.classificationsList.length > 0 ? this.state.classificationsList[0] : undefined,
                fromTime: this.nowDate,
                toTime: this.nowDate,
                fromRest: this.nowDate,
                toRest: this.nowDate,
                restPeriod: this.nowDate,
                period: this.nowDate,
                periodDays: 1
            },
            formErrors: {},
        }, () => this.hash = Util.hashState(this.state.dataObject))
    }
}