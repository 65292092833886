import React from "react";
import DataGrid from '../../../Components/DataGridView/DataGridView';
import ScrollTop from '../../ScrollButton';
import Card from "../../../Components/Card";
import SiteMap from "../../../Components/SiteMap";
import ProgressSpinner from "../../../Components/ProgressSpinner";
import Util from "../../../Util";
import Input from '../../../Components/Input';
import SearchEmployees from '../../Employees/SearchEmployees';
import Dropdown from "../../../Components/Dropdown";
import Calendar from "../../../Components/Calendar";
import ButtonsBarLookupList from '../../../Components/ButtonsBarLookupList';
import http from '../../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Messages from "../../../Components/Messages";
import Print from "../../../Print";

const $ = window.$;

export default class ShiftsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDesktop: true,
            fromCode: '',
            toCode: '',
            fromDate: new Date(),
            toDate: new Date(),

            section: undefined,
            branch: undefined,
            department: undefined,
            data: []
        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            if (!$.license.Att_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(950)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = window.innerWidth > 1250;


        let branchesPromise = this.getBranches();
        let sectionsPromise = this.getSections();
        let departmentsPromise = this.getDepartments();


        let branchesList = await branchesPromise;
        let sectionsList = await sectionsPromise;
        let departmentsList = await departmentsPromise;

        this.setState({
            branchesList: branchesList, sectionsList: sectionsList, departmentsList: departmentsList,
            section: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            branch: branchesList && branchesList.length > 0 ? branchesList[0] : undefined,
            department: departmentsList && departmentsList.length > 0 ? departmentsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        //let gridStyle = { height: this.state.isDesktop ? '42vh' : '100% !important' }
        let siteMapItems = [
            { label: $.strings.links.shifts.menu },
            { label: $.strings.shiftsList.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.onPrint} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.shiftsList.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                                onBlur={this.onFromCodeBlure}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.shiftsList.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.shiftsList.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.shiftsList.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="section"
                                innerRef={e => this.drpSections = e}
                                value={this.state.section}
                                options={this.state.sectionsList}
                                caption={$.strings.shiftsList.section}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                                filter
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="department"
                                innerRef={e => this.drpSections = e}
                                value={this.state.department}
                                options={this.state.departmentsList}
                                caption={$.strings.shiftsList.department}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                filter
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="branch"
                                innerRef={e => this.drpBranch = e}
                                value={this.state.branch}
                                options={this.state.branchesList}
                                caption={$.strings.shiftsList.branch}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                                filter
                            />
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.data}>
                    <DataGrid
                        ref={e => this.gridData = e}
                        isReport={true}
                        theme="default-light"
                        idProperty="serial"
                        // style={gridStyle}
                        scheme={this.getScheme()}
                        exportFileName={$.strings.shiftsList.title}
                        dataSource={this.state.data}
                        defaultRowHeight={45}
                    />
                </AccordionTab>
            </Accordion>
            <ScrollTop />
        </div>
    }

    onFromCodeBlure = () => {
        if (!this.state.toCode || this.state.toCode.length <= 0)
            this.setState({ toCode: this.state.fromCode });
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }


    getBranches = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.AttReports.urlGetFillCmbBranches, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }
    getDepartments = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 8
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
            }
        }
        return dataTemp
    }
    getTransTypes = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 26
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp)
                dataTemp.splice(0, 0, { pos: -1, name: $.strings.all, ename: $.strings.all })
        }
        return dataTemp;
    }
    getStatus = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            withAll: true
        }
        let res = await http.getJsonAxios(http.actions.RequestsStatus.urlGetRequestsStatus, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = [];
        let obj = {
            fromCode: this.state.fromCode && this.state.fromCode.length > 0 ? this.state.fromCode : '00000',
            toCode: this.state.toCode && this.state.toCode.length > 0 ? this.state.toCode : 'ZZZZZ',
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            sectionId: this.state.section ? parseInt(this.state.section.ID) : -1,
            depId: this.state.department ? parseInt(this.state.department.pos) : -1,
            branchId: this.state.branch ? parseInt(this.state.branch.Pos) : -1
        }
        let res = await http.getJsonAxios(http.actions.Shifts.urlGetShiftsList, obj, 0);

        if (res.status === 200) {
            if (res.data && res.data.success) {
                dataTemp = res.data.list;
            }
        }

        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    onPrint = () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.shiftsList.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];

        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";
            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.shiftsList.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.shiftsList.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.shiftsList.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.shiftsList.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.section) {
                dateFilter = $.strings.shiftsList.section + ": " + this.printFilters.section.Name;
            }
            if (this.printFilters.department) {
                dateFilter = $.strings.shiftsList.department + ": " + this.printFilters.department.name;
            }
            if (this.printFilters.branch) {
                dateFilter = $.strings.shiftsList.branch + ": " + this.printFilters.branch.Name;
            }

        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    getScheme = () => {
        let scheme = {
            name: "shiftsWeeklyListScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [
                { name: 'rowNumber', header: '##', width: 80, type: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'empCode', header: $.strings.shiftsList.empCode, width: 120, columnWidth: '2' },
                { name: 'empName', header: $.strings.shiftsList.empName, width: this.state.isDesktop ? 200 : '*', columnWidth: '*' },
                { name: 'date', header: $.strings.shiftsList.date, width: 100, dataType: 'Date', columnWidth: '3' },
                { name: 'day', header: $.strings.shiftsList.day, width: 100, columnWidth: '2' },
                { name: 'period', header: $.strings.shiftsList.period, width: 120, columnWidth: '1' },
                { name: 'fromTime', header: $.strings.shiftsList.fromTime, width: 120, columnWidth: '2' },
                { name: 'toTime', header: $.strings.shiftsList.toTime, width: 120, columnWidth: '2' },
                { name: 'ablimit1', header: $.strings.shiftsList.beforeIn, width: 100, columnWidth: '2' },
                { name: 'ablimit2', header: $.strings.shiftsList.afterIn, width: 100, columnWidth: '2' },
                { name: 'ablimit3', header: $.strings.shiftsList.beforeOut, width: 100, columnWidth: '2' },
                { name: 'ablimit4', header: $.strings.shiftsList.afterOut, width: 100, columnWidth: '2' }
            ]
        }
        return scheme;
    }
}