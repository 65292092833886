import React from "react";
import http from '../Api/http';
import ProgressSpinner from '../Components/ProgressSpinner';
import Util from "../Util";

const $ = window.$

export default class CRM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            success: false,
            resultText: '',
            isDesktop: true
        };
    }

    componentDidMount = async () => {

        let _isDesktop = this.updatePredicate();
        if (!this.props.match.params.uID || !this.props.match.params.token || !this.props.match.params.empCode) {
            Util.goTo("../../Access");
        }

        this.setState({ isDesktop: _isDesktop, loading: false })
        let resultPromise = this.handleCrmLogin()
        let temp = await resultPromise
        this.setState({ data: temp, isDesktop: _isDesktop, loading: false })
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    render() {
        let color = this.state.success ? 'green' : 'red';
        return (
            < div ref="divMain" style={{ minHeight: '70vh' }}>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12" style={{ textAlign: 'center' }}>
                        <span style={{ color: color, fontSize: '18px', fontWeight: 'bold' }}>{this.state.resultText}</span>
                    </div>
                </div>
            </div >
        );
    }

    handleCrmLogin = async () => {
        this.setState({ loading: true })
        let obj = {
            userId: this.props.match.params.uID,
            token: this.props.match.params.token,
            empCode: this.props.match.params.empCode,
        }
        let res = await http.postMultipartAxios(http.actions.Login.doLoginCRM, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                this.setState({ loading: false, success: true }, () => this.loginSuccess(res.data));
            }
            else {
                let resultText = $.strings.operationFailed;
                if (res.data.errorText) {
                    resultText = res.data.errorText;
                }
                this.setState({ loading: false, success: false, resultText: resultText });
            }
        }
        else {
            this.setState({ loading: false, success: false, resultText: $.strings.operationFailed })
        }
    }

    loginSuccess = (data) => {
        localStorage.setItem("loginTypeHR", "Employee Portal");
        localStorage.setItem("ISHR", "0");
        localStorage.setItem("tabModeHR", "Accordion");
        localStorage.setItem("rememberMeHR", false);

        localStorage.removeItem("rememberMeUserNameHR");

        localStorage.setItem("useridHR", data.empCode);
        localStorage.setItem("userNameHR", data.empName);
        localStorage.setItem("userNameEnHR", data.empNameEn);
        localStorage.setItem("mcPWDHR", data.MCPWD);
        localStorage.setItem("tabModeHR", "Accordion");
        localStorage.setItem("roleHR", data.jobName);
        localStorage.setItem("roleEnHR", data.jobNameEn);
        localStorage.setItem("empLanguageHR", data.empLanguage);

        localStorage.setItem("rightsHR", JSON.stringify(data.rightsList));
        localStorage.setItem("newsHR", JSON.stringify(data.newsList));

        localStorage.setItem("licenseModulesHR", JSON.stringify(data.licenseModules));
        localStorage.setItem("userImageHR", data.empImage);
        localStorage.setItem("isLoggedInHR", data.token)
        if (data.isManager) {
            localStorage.setItem("isManagerHR", "true");
            localStorage.setItem("isEmp", "false");
        }
        else {
            localStorage.setItem("isManagerHR", "false");
            localStorage.setItem("isEmp", "true");
        }

        localStorage.setItem("notCount", data.notCount);
        // Emp Settings
        let settings = data.empSettingsObj ? data.empSettingsObj.list : []
        Util.loadEmpSettings(settings);

        if (data.empLanguage === 1) {
            localStorage.setItem("HRlang", "ar");
        }
        else if (data.empLanguage === 2) {
            localStorage.setItem("HRlang", "en");
        }
        if (localStorage.getItem("mcPWDHR") + "" === "true") {
            this.setState({ userPwd: "", loading: false },
                () => setTimeout(this.mChangePwd.show(), 0));
            return
        }

        if (Util.checkISManager()) {
            Util.goTo("/HandleRequests", "");
        }
        else {
            Util.goTo("/", "");
        }
        window.location.reload();
    }
}
