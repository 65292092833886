import React from "react";
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import Spinner from '../../Components/Spinner';

const $ = window.$;

export default class EndServicesBalance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            transTypeList: [],
            statusList: [],
            empCode: localStorage.getItem("useridHR"),
            empName: localStorage.getItem("userNameHR"),
            basicSalary: undefined,
            appDate: undefined,
            yearsCount: undefined,
            totalAmount: undefined,
            loansAmount: undefined,
            netAmount: undefined,
        };
    }

    componentDidMount = async () => {
        if (Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }
        else {
            if (!$.license.SelfService_Module) {
                Util.goTo('/License');
                return;
            }
            if (!Util.checkRights(750)) {
                Util.goTo('/Access');
                return;
            }
        }
        let _isDesktop = this.updatePredicate();
        let dataPromise = this.getData(true);
        let data = await dataPromise;

        this.setState({
            basicSalary: data.basicSalary || undefined, appDate: data.appDateStr || undefined,
            yearsCount: data.yearsCount || undefined, totalAmount: data.totalAmount || undefined,
            loansAmount: data.loansAmount || undefined, netAmount: data.netAmount || undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    render = () => {
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.endServicesBalance.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card>
                <ButtonsBarLookupList onRefresh={this.getData} />
            </Card>
            <Card id="divFilters">
                <div className="p-grid">
                    <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                        <Input
                            id="empCode"
                            value={this.state.empCode}
                            caption={$.strings.endServicesBalance.empCode}
                            onKeyDown={this.onReadOnlyKeyDown}
                        />
                    </div>
                    <div className="p-col-12 p-xl-4 p-lg-8 p-md-8 p-sm-12">
                        <Input
                            id="empName"
                            value={this.state.empName}
                            caption={$.strings.endServicesBalance.empName}
                            onKeyDown={this.onReadOnlyKeyDown}
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                        <Input
                            id="appDate"
                            value={this.state.appDate}
                            caption={$.strings.endServicesBalance.appDate}
                            onKeyDown={this.onReadOnlyKeyDown}
                        />
                    </div>
                    <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                        <Input
                            id="yearsCount"
                            value={this.state.yearsCount}
                            caption={$.strings.endServicesBalance.yearsCount}
                            onKeyDown={this.onReadOnlyKeyDown}
                        />
                    </div>
                    <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                        <Spinner
                            id="basicSalary"
                            value={this.state.basicSalary}
                            min={0}
                            useGrouping
                            caption={$.strings.endServicesBalance.basicSalary}
                            onKeyDown={this.onReadOnlyKeyDown}
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                        <Spinner
                            id="totalAmount"
                            value={this.state.totalAmount}
                            min={0}
                            useGrouping
                            caption={$.strings.endServicesBalance.totalAmount}
                            onKeyDown={this.onReadOnlyKeyDown}
                        />
                    </div>
                    <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                        <Spinner
                            id="loansAmount"
                            value={this.state.loansAmount}
                            min={0}
                            useGrouping
                            caption={$.strings.endServicesBalance.loansAmount}
                            onKeyDown={this.onReadOnlyKeyDown}
                        />
                    </div>
                    <div className="p-col-12 p-xl-2 p-lg-4 p-md-4 p-sm-12">
                        <Spinner
                            id="netAmount"
                            value={this.state.netAmount}
                            min={0}
                            useGrouping
                            caption={$.strings.endServicesBalance.netAmount}
                            style={{ fontWeight: '700' }}
                            onKeyDown={this.onReadOnlyKeyDown}
                        />
                    </div>
                </div>
            </Card>

        </div >
    }

    onReadOnlyKeyDown = (e) => {
        e.preventDefault();
    };

    getData = async (fromLoad) => {
        if (!fromLoad)
            this.setState({ loading: true });
        let data = []
        let obj = {
            empCode: this.state.empCode
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEndServicesBalance, obj, 0);
        if (res.status === 200) {
            data = res.data;
        }
        if (!fromLoad) {
            this.setState({
                basicSalary: data.basicSalary || undefined, appDate: data.appDateStr || undefined,
                yearsCount: data.yearsCount || undefined, totalAmount: data.totalAmount || undefined,
                loansAmount: data.loansAmount || undefined, netAmount: data.netAmount || undefined,
                loading: false
            });
        }
        else {
            return data;
        }
    }
}