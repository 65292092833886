import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollTop from '../ScrollButton';
import Messages from "../../Components/Messages";
import Print from "../../Print";

const $ = window.$;

export default class NewsReport extends React.Component {
    constructor(props) {
        super(props);
        this.targetList = Util.getNewsTarget()
        this.statusList = Util.getNewsStatus()
        this.state = {
            isDesktop: window.innerWidth > 1250,
            targetList: this.targetList,
            statusList: this.statusList,
            fromCode: undefined,
            toCode: undefined,
            newsTitle: undefined,
            fromDate: undefined,
            toDate: undefined,
            expieryDate: undefined,
            status: this.statusList[0],
            target: this.targetList[0],
            empCode: undefined,
            empId: undefined,
            jobId: undefined,
            sectionId: undefined,
            object: undefined,
            objectCaption: '',
            objectsList: undefined,
            data: []
        };
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (Util.checkISHR()) {
            if (!Util.checkRights(714)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            if (!Util.checkRights(654)) {
                Util.goTo('/Access');
                return;
            }
        }

        let _isDesktop = this.updatePredicate();
        this.setState({ isDesktop: _isDesktop, loading: false })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }
    handleTarget = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value }, () => this.getObjectsList());
    }

    render = () => {
        const gridStyle = { minHeight: this.state.filterShown ? '42vh' : '55vh' }
        let siteMapItems = [
            { label: !this.props.match.params.id ? $.strings.links.reports.menu : $.strings.myStaff.title, url: !this.props.match.params.id ? '' : '../MyStaff' },
            { label: $.strings.news.newsReportTitle }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]}>
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.news.fromCode}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.news.toCode}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Calendar
                                id="fromDate"
                                value={this.state.fromDate}
                                caption={$.strings.news.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Calendar
                                id="toDate"
                                value={this.state.toDate}
                                caption={$.strings.news.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Calendar
                                id="expieryDate"
                                value={this.state.expieryDate}
                                caption={$.strings.news.expieryDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Dropdown
                                id="status"
                                value={this.state.status}
                                options={this.state.statusList}
                                caption={$.strings.news.status}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-6">
                            <Input
                                id="newsTitle"
                                value={this.state.newsTitle}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.news.newsTitle}
                            />
                        </div>
                        <div className="p-col-6 p-lg-2 p-sm-6">
                            <Dropdown
                                id="target"
                                value={this.state.target}
                                options={this.state.targetList}
                                caption={$.strings.news.target}
                                onChange={this.handleTarget}
                                optionLabel="name"
                            />
                        </div>
                        {
                            this.state.target && this.state.target.id >= 0 && this.state.target.id !== 2 &&
                            <div className="p-col-6 p-lg-2 p-sm-6">
                                <Dropdown
                                    id="object"
                                    value={this.state.object}
                                    options={this.state.objectsList}
                                    caption={this.state.objectCaption}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    showClear
                                    filter
                                    filterBy="code,name"
                                />
                            </div>
                        }
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            style={gridStyle}
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            scheme={this.getScheme()}
                            dataSource={this.state.data}
                            exportFileName={$.strings.news.newsReportTitle}
                        />
                    </div>
                </div>
            </Card>
            <ScrollTop />
        </div >
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp) {
                let data = []
                for (let i = 0; i < dataTemp.length; i++) {
                    if (i === 0) {
                        //data.push({ id: -1, name: $.strings.all })
                    }
                    data.push({ id: dataTemp[i].ID, name: dataTemp[i].Name })
                }
                dataTemp = data;
            }
        }
        this.setState({ objectsList: dataTemp, object: dataTemp[0] || undefined, objectCaption: $.strings.news.sectionTarget, loading: false });
    }
    getJobs = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 3
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
            if (dataTemp) {
                let data = []
                for (let i = 0; i < dataTemp.length; i++) {
                    if (i === 0) {
                        data.push({ id: -1, name: $.strings.all })
                    }
                    data.push({ id: dataTemp[i].pos, name: dataTemp[i].name })
                }
                dataTemp = data;
            }
        }
        this.setState({ objectsList: dataTemp, object: dataTemp[0] || undefined, objectCaption: $.strings.news.jobTarget, loading: false });
    }
    getEmployees = async () => {
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            searchValue: "",
            getEmpStatus: false,
            section: -1,
            isHr: Util.checkISHR(),
            empStatus: -1
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.empObjList;
            if (dataTemp) {
                let data = []
                for (let i = 0; i < dataTemp.length; i++) {
                    if (i === 0) {
                        data.push({ id: -1, name: $.strings.all })
                    }
                    data.push({ id: dataTemp[i].code, name: dataTemp[i].name })
                }
                dataTemp = data;
            }
        }
        this.setState({ objectsList: dataTemp, object: dataTemp[0] || undefined, objectCaption: $.strings.news.empTarget, loading: false });
    }

    getObjectsList = async () => {
        if (!this.state.target || this.state.target.id <= -1 || this.state.target.id === 2) {
            this.setState({ object: undefined, objectsList: undefined });
            return;
        }
        let objectCaption = $.strings.news.sectionTarget;
        let targetId = this.state.target.id;
        switch (targetId) {
            case 0: // employees
                this.setState({ loading: true, objectCaption: $.strings.news.empTarget })
                await this.getEmployees();
                break;
            case 1: // job
                this.setState({ loading: true, objectCaption: $.strings.news.jobTarget })
                await this.getJobs();
                break;
            case 2: // sections managers
                break;
            case 3: // section
                this.setState({ loading: true, objectCaption: $.strings.news.sectionTarget })
                await this.getSections()
                break;
            default:
                break;
        }
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            fromDate: this.state.fromDate || '',
            toDate: this.state.toDate || '',
            expieryDate: this.state.expieryDate || '',
            fromCode: this.state.fromCode || '',
            toCode: this.state.toCode || '',
            newsTitle: this.state.newsTitle || '',
            status: this.state.status && this.state.status.id > 0 ? this.state.status.id : '-1',
            target: this.state.target && this.state.target.id > 0 ? this.state.target.id : '-1',
            empCode: this.state.target && this.state.target.id === 0 ? this.state.object.id : '',
            jobId: this.state.target && this.state.target.id === 1 ? this.state.object.id : '',
            sectionId: this.state.target && this.state.target.id === 3 ? this.state.object.id : '',
            userId: localStorage.getItem("useridHR")
        }

        let res = await http.getJsonAxios(http.actions.News.urlGetNews, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportCSV();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.news.newsReportTitle, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";
            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.news.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.news.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.news.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.news.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            // expieryDate Filter
            if (this.printFilters.expieryDate) {
                listOfFilters.push($.strings.news.expieryDate + ": " + Util.getDateFormat(this.printFilters.expieryDate));
            }

            if (this.printFilters.status) {
                listOfFilters.push($.strings.news.status + ": " + this.printFilters.status.name);
            }

            if (this.printFilters.newsTitle) {
                listOfFilters.push($.strings.news.newsTitle + ": " + this.printFilters.newsTitle);
            }

            if (this.printFilters.target) {
                listOfFilters.push($.strings.news.target + ": " + this.printFilters.target.name);
            }
            if (this.printFilters.object) {
                listOfFilters.push(this.state.objectCaption + ": " + this.printFilters.object.name);
            }
        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    onViewClick = (e, ctx) => {
        let id = Util.encryptStringToHexa(ctx.item.No);
        Util.goTo("../../NewsAdd/" + id);
    }
    onEditClick = (e, ctx) => {
        if (window.confirm($.strings.news.editConfirmation)) {
            let id = Util.encryptStringToHexa(ctx.item.No);
            Util.goTo("../../NewsAdd/" + id);
        }
    }
    onDeleteClick = async (e, ctx) => {
        if (window.confirm($.strings.news.deleteConfirmation)) {
            let id = Util.encryptStringToHexa(ctx.item.No);
            this.setState({ loading: true });
            let result = await this.onDelete(id);
            if (result) {
                this.getData();
            }
            this.setState({ loading: false });
        }
    }
    onDelete = async (id) => {
        let result = false;
        let obj = {
            id: id
        }
        let res = await http.getJsonAxios(http.actions.News.urlDeleteNews, obj, 0);
        if (res.status === 200) {
            result = res.data.success;
        }
        return result;
    }
    getScheme = () => {
        return {
            name: "newsReportScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 8,
            columns: [
                { name: 'RowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                {
                    name: 'onViewClick', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.view,
                    iconType: 'view', className: 'info', onClick: (e, ctx) => this.onViewClick(e, ctx)
                },
                {
                    name: 'onEditClick', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.edit,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEditClick(e, ctx)
                },
                {
                    name: 'onDeleteClick', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.delete,
                    iconType: 'delete', className: 'danger', onClick: (e, ctx) => this.onDeleteClick(e, ctx)
                },
                { name: 'No', header: $.strings.news.code, width: 80, columnWidth: '2' },
                { name: 'NewsDate', header: $.strings.news.newsDate, width: 120, columnWidth: '3' },
                { name: 'NewsTime', header: $.strings.news.newsTime, width: 120, columnWidth: '2' },
                { name: 'Target', header: $.strings.news.target, width: 130, columnWidth: '3' },
                { name: 'NewsAddress', header: $.strings.news.newsTitle, width: "*", columnWidth: '*' },
                { name: 'NewsDetails', header: $.strings.news.newsDetails, width: "*", visible: false, visibleInColumnChooser: false },
                { name: 'EmpName', header: $.strings.news.targetName, width: "*", columnWidth: '*' },
                { name: 'EmpCode', header: $.strings.news.empCode, width: 100, visible: false, visibleInColumnChooser: false },
                { name: 'ExpieryDate', header: $.strings.news.expieryDate, width: 120, columnWidth: '3' },
                { name: 'StatusNo', header: $.strings.news.status, width: 100, visible: false, visibleInColumnChooser: false, columnWidth: '2' },
                { name: 'Status', header: $.strings.news.status, width: 120, columnWidth: '2' }
            ]
        }
    }
}