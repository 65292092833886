import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import SearchEmployees from "./SearchEmployees";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class ChangeEmpCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            formErrors: {},
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            empCode: '',
            empName: '',
            newEmpCode: '',
        }
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;
        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    validateField() {

        let result = true;
        let fieldValidationErrors = this.state.formErrors;

        fieldValidationErrors.empCode = "";
        if (!this.state.empCode || this.state.empCode.length <= 0) {
            fieldValidationErrors.empCode = $.strings.requiredFiled;
            return result = false;
        }
        
        fieldValidationErrors.newEmpCode = "";
        if (!this.state.newEmpCode || this.state.newEmpCode.length <= 0) {
            fieldValidationErrors.newEmpCode = $.strings.requiredFiled;
            return result = false;
        }
        
        this.setState({ formErrors: fieldValidationErrors })

        return result;
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.save} icon="" className="p-button-success" onClick={this.onSave}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <Dialog header={$.strings.changeEmpCode.title} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir }}
                    modal onHide={this.onHide}>
                    <ProgressSpinner loading={this.state.loading} />
                    <SearchEmployees ref={e => this.dlgSearchEmployees = e} onSuccess={this.onSearchEmployeeSuccess} />
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-4 p-sm-12">
                                <Input
                                    id="empCode"
                                    maxLength={Util.getCodeLen()}
                                    value={this.state.empCode}
                                    caption={$.strings.changeEmpCode.empCode}
                                    disabled
                                />
                            </div>
                            <div className="p-col-12 p-lg-8 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Input
                                    id="empName"
                                    maxLength="100"
                                    value={this.state.empName}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-4 p-sm-12">
                                <Input
                                    id="newEmpCode"
                                    innerRef={e => this.txtNewEmpCode = e}
                                    maxLength={Util.getCodeLen()}
                                    value={this.state.newEmpCode}
                                    onChange={this.handleUserInput}
                                    autoFocus={true}
                                    caption={$.strings.changeEmpCode.newEmpCode}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div>
        );
    }

    onEmployeesSearch = (e, checkChanging) => {
        this.dlgSearchEmployees.show();
    }
    onSearchEmployeeSuccess = (obj) => {
        if (obj) {
            this.setState({ empCode: obj.code, empName: obj.name });
        }
    }
    getEmployee = async () => {
        if (!this.state.empCode || this.state.empCode.length < 0) {
            this.setState({ empCode: '', empName: '', loading: false });
            return;
        }
        this.setState({ loading: true });
        let dataTemp = []
        let obj = {
            userId: localStorage.getItem('useridHR'),
            isHr: Util.checkISHR(),
            empCode: Util.encryptStringToHexa(this.state.dataObject.empCode)
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeInfo, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployeeInfoObj;
            if (dataTemp) {
                this.setState({ empCode: dataTemp.Code, empName: dataTemp.Name, loading: false });
                return;
            }
            else {
                Util.showInfoMsg(this.messages, $.strings.changeEmpCode.employeeNotFound)
                this.setState({ empCode: '', empName: '', loading: false })
            }
        }
        else {
            this.setState({ loading: false });
        }
    }

    show = (empCode, empName) => {
        this.setState({ visibleDialog: true, empCode: empCode, empName: empName, newEmpCode: '', formErrors: {}, },
            () => setTimeout(() => {
                this.txtNewEmpCode.focus()
            }, 10));
    }

    onHide = () => {
        this.setState({ visibleDialog: false, empCode: '', empName: '', newEmpCode: '', formErrors: {} })
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.setState({ loading: true })
        let obj = {
            oldCode: this.state.empCode,
            newCode: this.state.newEmpCode
        }

        let res = await http.getJsonAxios(http.actions.Employee.urlChangeEmployeeCode, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText)
                    alert(res.data.errorText);
                else
                    alert($.strings.operationDone);
                if (this.props.onSuccess) {
                    await this.props.onSuccess();
                }
                this.setState({ loading: false, visibleDialog: false })
                return true
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return false
        }
    }
}