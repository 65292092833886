import React from "react";
import styles from './Substitute.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import Calendar from '../../Components/Calendar'
import SiteMap from '../../Components/SiteMap';
import ScrollTop from '../ScrollButton';
import Editor from '../../Components/InputEditor';
import Checkbox from "../../Components/Checkbox";
import DataGridView from "../../Components/DataGridView/DataGridView";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class Substitute extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.nowDate = new Date();
        this.vacationsTypesList = [
            { id: 6, name: $.strings.substitute.sickVacation },
            { id: 7, name: $.strings.substitute.annualVacation },
            { id: 8, name: $.strings.substitute.paidVacation },
            { id: 9, name: $.strings.substitute.unpaidVacation }
        ];
        this.substituteFromList = [
            { id: 0, name: $.strings.substitute.allEmployees },
            { id: 1, name: $.strings.substitute.sameSection },
            { id: 2, name: $.strings.substitute.sameBranch },
            { id: 3, name: $.strings.substitute.sameDepartment },
            { id: 4, name: $.strings.substitute.sameDirectManager }
        ]
        this.substituteStatusList = [
            { id: 0, name: $.strings.substitute.not },
            { id: 1, name: $.strings.substitute.optinal },
            { id: 2, name: $.strings.substitute.required },
        ]

        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { vacationType: '', vacationSubType: '', substituteFrom: '', substituteStatus: '', secondSubstituteStatus: '', daysCount: '' },
            deleteErrors: { deleteNote: '' },
            isDesktop: window.innerWidth > 1250,
            subVacationTypesList: [],
            data: [],
            dataObject: {
                id: 0,
                vacationType: undefined,
                subVacationType: undefined,
                substituteFrom: undefined,
                substituteStatus: undefined,
                secondSubstituteStatus: undefined,
                daysCount: 0,
                allowFirstSubstituteVacation: false,
                allowSecondSubstituteVacation: false
            }
        }
    }
    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }
        if (!Util.checkRights(753)) {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = window.innerWidth > 1250;
        let subVacationTypesList = await this.getSubVacationTypes();
        let _data = await this.getData()

        this.setState({ data: _data, subVacationTypesList: subVacationTypesList, isDesktop: _isDesktop, loading: false });
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;

        let _vacationType = true;
        fieldValidationErrors.vacationType = '';
        if (!this.state.dataObject.vacationType || this.state.dataObject.vacationType.id < 0) {
            fieldValidationErrors.vacationType = $.strings.requiredFiled;
            _vacationType = false;
        }

        let _subVacationType = true;
        fieldValidationErrors.subVacationType = '';
        if (this.state.dataObject.vacationType && this.state.dataObject.vacationType.id === 8 &&
            (!this.state.dataObject.subVacationType || this.state.dataObject.subVacationType.pos < 0)) {
            fieldValidationErrors.subVacationType = $.strings.requiredFiled;
            _subVacationType = false;
        }

        let _substituteFrom = true;
        fieldValidationErrors.substituteFrom = '';
        if (!this.state.dataObject.substituteFrom || this.state.dataObject.substituteFrom.id < 0) {
            fieldValidationErrors.substituteFrom = $.strings.requiredFiled;
            _substituteFrom = false;
        }

        let _substituteStatus = true;
        fieldValidationErrors.substituteStatus = '';
        if (!this.state.dataObject.substituteStatus || this.state.dataObject.substituteStatus.id < 0) {
            fieldValidationErrors.substituteStatus = $.strings.requiredFiled;
            _substituteStatus = false;
        }

        let _secondSubstituteStatus = true;
        fieldValidationErrors.secondSubstituteStatus = '';
        if (!this.state.dataObject.secondSubstituteStatus || this.state.dataObject.secondSubstituteStatus.id < 0) {
            fieldValidationErrors.secondSubstituteStatus = $.strings.requiredFiled;
            _secondSubstituteStatus = false;
        }

        let _daysCount = true;
        fieldValidationErrors.daysCount = '';
        if (!this.state.dataObject.daysCount || this.state.dataObject.daysCount < 0 || (this.state.dataObject.daysCount + "").length <= 0) {
            fieldValidationErrors.daysCount = $.strings.requiredFiled;
            _daysCount = false;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            vacationTypeValid: _vacationType,
            subVacationTypeValid: _subVacationType,
            substituteFromValid: _substituteFrom,
            substituteStatusValid: _substituteStatus,
            secondSubstituteStatusValid: _secondSubstituteStatus,
            daysCountValid: _daysCount
        }, this.validateForm);

        return _vacationType && _subVacationType && _substituteFrom && _substituteStatus && _secondSubstituteStatus && _daysCount
    }
    validateForm() {
        let isFormValid = this.state.vacationTypeValid && this.state.subVacationTypeValid && this.state.substituteFromValid && this.state.substituteStatusValid
            && this.state.secondSubstituteStatusValid && this.state.daysCountValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        let siteMapItems = [
            { label: $.strings.links.staticFilesAndSettings },
            {
                label: <div>
                    {$.strings.substitute.title + " "}
                </div>
            }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }
        const gridStyle = { minHeight: '45vh' }
        return (
            <div ref="divMain" className={styles.substituteSettings}>
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={this.resetFields}
                            onSave={this.onSave}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid" style={{ width: '100%' }}>
                    <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <Card>
                    {/* <div className="p-grid">
                        <div className="p-col-12 p-lg-1 p-sm-12">
                            <Input
                                id="id"
                                keyfilter="pnum"
                                value={this.state.dataObject.id || ''}
                                autofocus={true}
                                caption={$.strings.substitute.code}
                            />
                        </div>
                    </div> */}
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="vacationType"
                                value={this.state.dataObject.vacationType || ''}
                                options={this.vacationsTypesList}
                                caption={$.strings.substitute.vacationType}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                                optionLabel="name"
                            />
                        </div>
                        {   // paid vacation
                            this.state.dataObject.vacationType && this.state.dataObject.vacationType.id === 8 &&
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Dropdown
                                    id="subVacationType"
                                    value={this.state.dataObject.subVacationType || ''}
                                    options={this.state.subVacationTypesList}
                                    caption={$.strings.substitute.vacationSubType}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    filter
                                    filterBy="name"
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        }

                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="substituteFrom"
                                value={this.state.dataObject.substituteFrom || ''}
                                options={this.substituteFromList}
                                caption={$.strings.substitute.substituteFrom}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="substituteStatus"
                                value={this.state.dataObject.substituteStatus || ''}
                                options={this.substituteStatusList}
                                caption={$.strings.substitute.substituteStatus}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="secondSubstituteStatus"
                                value={this.state.dataObject.secondSubstituteStatus || ''}
                                options={this.substituteStatusList}
                                caption={$.strings.substitute.secondSubstituteStatus}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Input
                                id="daysCount"
                                keyfilter="pnum"
                                value={this.state.dataObject.daysCount}
                                autofocus={true}
                                caption={$.strings.substitute.daysCount}
                                onChange={this.handleUserInput}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="allowFirstSubstituteVacation"
                                caption={$.strings.substitute.allowFirstSubstituteVacation}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, allowFirstSubstituteVacation: e.checked } })}
                                checked={this.state.dataObject.allowFirstSubstituteVacation} />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="allowSecondSubstituteVacation"
                                caption={$.strings.substitute.allowSecondSubstituteVacation}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, allowSecondSubstituteVacation: e.checked } })}
                                checked={this.state.dataObject.allowSecondSubstituteVacation} />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <DataGridView
                                style={gridStyle}
                                ref={e => this.gridData = e}
                                isReport={true}
                                theme="default-light"
                                idProperty="rowNumber"
                                scheme={this.getScheme()}
                                dataSource={this.state.data}
                                exportFileName={$.strings.substitute.time}
                            />
                        </div>
                    </div>
                </Card>
                <ScrollTop />
            </div >
        );
    }

    getSubVacationTypes = async () => {
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp;
    }

    getData = async (id) => {
        let dataTemp = []
        let obj = {
            id: id && id.length > 0 ? id : Util.encryptStringToHexa('')
        }
        let res = await http.getJsonAxios(http.actions.Settings.urlGetSubstituteSettings, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }

        return dataTemp
    }
    loadData = (data) => {
        this.messages.clear();
        let vacationType = this.vacationsTypesList.find(element => element.id + "" === data.VacationType + "");
        let subVacationType = this.state.subVacationTypesList.find(element => element.pos + "" === data.VacationSubType + "");

        let substituteFrom = this.substituteFromList.find(element => element.id + "" === data.From + "");

        let substituteStatus = this.substituteStatusList.find(element => element.id + "" === data.Status + "");
        let secondSubstituteStatus = this.substituteStatusList.find(element => element.id + "" === data.Status2 + "");
        let daysCount = data.DaysCount
        let allowVac_AltEmp = data.AllowVac_AltEmp + "" === "1" ? true : false;
        let allowVac_AltEmp2 = data.AllowVac_AltEmp2 + "" === "1" ? true : false

        this.setState({
            mode: 'edit',
            dataObject: {
                ...this.state.dataObject,
                id: data.ID,
                vacationType: vacationType,
                subVacationType: subVacationType,
                substituteFrom: substituteFrom,
                substituteStatus: substituteStatus,
                secondSubstituteStatus: secondSubstituteStatus,
                daysCount: daysCount,
                allowFirstSubstituteVacation: allowVac_AltEmp,
                allowSecondSubstituteVacation: allowVac_AltEmp2
            },
            loading: false
        });
    }

    onSave = async () => {
        this.messages.clear();
        if (!this.validateField()) {
            return false;
        }

        this.setState({ loading: true })
        let res = await this.saveData();
        if (res.status === 200) {
            let data = [];
            if (res.data.success) {
                let msg = $.strings.operationDone;
                Util.showSuccessMsg(this.messages, msg);
                this.resetFields()
                data = await this.getData();
                this.hash = this.hashState();
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data.errorText && res.data.errorText.length > 0) {
                    msg = res.data.errorText;
                }
                Util.showErrorMsg(this.messages, msg);
            }
            this.setState({ data: data, loading: false });
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data.errorText && res.data.errorText.length > 0) {
                msg = res.data.errorText;
            }
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false });
        }
    }
    saveData = async () => {
        let obj = {
            ID: this.state.dataObject.id && this.state.dataObject.id > 0 ? this.state.dataObject.id : '',
            vacationType: this.state.dataObject.vacationType.id,
            vacationSubTypeVal: this.state.dataObject.subVacationType ? this.state.dataObject.subVacationType.pos : 0,
            substituteFrom: this.state.dataObject.substituteFrom.id,
            substituteStatus: this.state.dataObject.substituteStatus.id,
            substituteStatus2: this.state.dataObject.secondSubstituteStatus.id,
            days: this.state.dataObject.daysCount,
            allowAltEmp: this.state.dataObject.allowFirstSubstituteVacation,
            allowAltEmp2: this.state.dataObject.allowSecondSubstituteVacation
        }
        let res = await http.getJsonAxios(http.actions.Settings.urlPostSaveSubstituteSettings, obj, null, null, 0);
        return res;
    }

    resetFields = () => {
        this.messages.clear();
        this.nowDate = new Date();
        this.setState({
            dataObject: {
                id: 0,
                vacationType: undefined,
                subVacationType: undefined,
                substituteFrom: undefined,
                substituteStatus: undefined,
                secondSubstituteStatus: undefined,
                daysCount: 0,
                allowFirstSubstituteVacation: false,
                allowSecondSubstituteVacation: false
            },
            formErrors: { vacationType: '', vacationSubType: '', substituteFrom: '', substituteStatus: '', secondSubstituteStatus: '', daysCount: '' },
        });
    }

    onEdit = async (e, ctx) => {
        this.setState({ loading: true })
        let id = Util.encryptStringToHexa(ctx.item.ID);
        let objData = await this.getData(id);
        if (objData && objData.length > 0) {
            objData = objData[0];
            this.loadData(objData);
        }
        this.setState({ loading: false })
    }
    getScheme = () => {
        return {
            name: "substituteScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 4,
            columns: [
                {
                    name: 'rowNumber', header: '#', width: 50, dataType: 'Number', aggregate: 'Cnt'
                },
                {
                    name: 'onEdit', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.edit,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEdit(e, ctx)
                },
                {
                    name: 'ID', header: $.strings.substitute.code, width: 50,
                },
                {
                    name: 'VacationType', header: $.strings.substitute.vacationType, width: 80, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'VacationTypeName', header: $.strings.substitute.vacationType, width: this.state.isDesktop ? "*" : 290,
                },
                {
                    name: 'VacationSubType', header: $.strings.substitute.vacationSubType, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'VacationSubTypeName', header: $.strings.substitute.vacationSubType, width: this.state.isDesktop ? "*" : 290,
                },
                {
                    name: 'From', header: $.strings.substitute.substituteFrom, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'FromName', header: $.strings.substitute.substituteFrom, width: this.state.isDesktop ? "*" : 290,
                },
                {
                    name: 'Status', header: $.strings.substitute.substituteStatus, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'StatusName', header: $.strings.substitute.substituteStatus, width: 140,
                },
                {
                    name: 'Status2', header: $.strings.substitute.status, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'StatusName2', header: $.strings.substitute.secondSubstituteStatus, width: 170,
                },
                {
                    name: 'DaysCount', header: $.strings.substitute.daysCount, width: 80,
                },
                {
                    name: 'AllowVac_AltEmp', header: $.strings.substitute.allowFirstSubstituteVacation, width: 200, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'AllowVac_AltEmpName', header: $.strings.substitute.allowFirstSubstituteVacation, width: 200,
                },
                {
                    name: 'AllowVac_AltEmp2', header: $.strings.substitute.allowSecondSubstituteVacation, width: 200, visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'AllowVac_AltEmp2Name', header: $.strings.substitute.allowSecondSubstituteVacation, width: 200,
                }
            ]
        }
    }
}