import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class NotificationsApproval extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            formErrors: { notes: '', toDate: '', evalPeriod: '' },
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();

        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _notes = this.state.notes;
        fieldValidationErrors.notes = _notes ? '' : $.strings.requiredFiled;

        let _toDate = true
        if (this.props.type && this.props.type === "postpone") {
            _toDate = this.state.toDate
            if (!_toDate) {
                fieldValidationErrors.toDate = _toDate ? '' : $.strings.requiredFiled;
            }
            else {
                let nowDate = new Date();
                fieldValidationErrors.toDate = _toDate <= nowDate ? $.strings.handleNotifications.dateError : ''
            }
        }

        let _evalPeriod = true
        if (this.props.type && this.props.type === "evaluation") {
            _evalPeriod = this.state.evalPeriod
            fieldValidationErrors.evalPeriod = $.strings.requiredFiled
        }

        this.setState({
            formErrors: fieldValidationErrors,
            notesValid: _notes,
            toDateValid: _toDate,
            evalPeriodValid: _evalPeriod,
        }, this.validateForm);

        return _notes && _toDate && _evalPeriod;
    }
    validateForm() {
        let isFormValid = this.state.oldPwdValid && this.state.newPwdValid && this.state.confirmPwdValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        const footer = (
            <div className="p-grid" style={{ textAlign: 'center' }}>
                <div className="p-col-12 p-lg-12 p-sm-12" style={{ textAlign: 'center' }}>
                    <Button label={$.strings.handleNotifications.save} icon="" className="p-button-success" onClick={this.onSave}>
                        &nbsp;&nbsp;
                        <i className="pi pi-save" />
                    </Button>
                </div>
            </div >
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog
                    header={$.strings.handleNotifications.title}
                    footer={footer} icons={myIcon}
                    visible={this.state.visibleDialog}
                    contentStyle={{
                        minHeight: this.props.type && this.props.type === "postpone" ? '420px' : ''
                    }}
                    style={{ width: this.state.isDesktop ? '35vw' : '70vw', direction: $.strings.dir }}
                    modal
                    onHide={this.onHide}
                >
                    <div ref="divMain" >
                        <Messages innerRef={(el) => this.messages = el} />
                        <div className="p-grid">
                            <div className="p-col-2 p-lg-2 p-sm-12">
                                <Input
                                    id="id"
                                    value={this.state.id}
                                    autocomplete="off"
                                    caption={$.strings.handleNotifications.code}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                            {
                                this.props.type && this.props.type === "postpone" &&
                                <div className="p-col-12 p-lg-4 p-sm-12">
                                    <Calendar
                                        id="toDate"
                                        innerRef={(el) => this.dtpToDate = el}
                                        value={this.state.toDate}
                                        caption={$.strings.handleNotifications.toDate}
                                        onChange={this.handleUserInput}
                                        readOnlyInput={true}
                                        readOnly={true}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                    />
                                </div>
                            }
                        </div>
                        {
                            this.props.type && this.props.type === "evaluation" &&
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-5 p-sm-12">
                                    <Dropdown
                                        id="evalPeriod"
                                        value={this.state.evalPeriod}
                                        options={this.state.evalPeriodList}
                                        caption={$.strings.handleNotifications.evalPeriod}
                                        placeholder={$.strings.handleNotifications.selectNotType}
                                        onChange={this.handleUserInput}
                                        optionLabel="Name"
                                        isRequired={true}
                                        formErrors={this.state.formErrors}

                                    />
                                </div>
                            </div>
                        }
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    innerRef={e => this.txtNotes = e}
                                    id="notes"
                                    maxLength="70"
                                    value={this.state.notes}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.handleNotifications.notes}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog >
            </div >
        );
    }
    show = (id, empCode, empName, listOfNotifications) => {
        this.setState({
            empCode: empCode, empName: empName, toDate: undefined, notes: '',
            evalPeriod: undefined, id: id, visibleDialog: true, listOfNotifications: listOfNotifications,
            formErrors: { notes: '', toDate: '', evalPeriod: '' },
        }, () => setTimeout(() => { this.txtNotes.focus() }, 10))
    }

    onSave = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }

        this.setState({ loading: true })
        let obj = {
            id: this.state.id,
            type: this.props.type,
            isHR: Util.checkISHR(),
            toDate: this.state.toDate ? this.state.toDate : new Date(),
            notes: this.state.notes,
            evaluationId: this.state.evalPeriod && this.state.evalPeriod.id > 0 ? this.state.evalPeriod.id : 0,
            evaluationText: this.state.evalPeriod ? this.state.evalPeriod.name : "",
            empCode: this.state.empCode ? this.state.empCode : '',
            empName: this.state.empName ? this.state.empName : '',
            listOfNotifications: this.state.listOfNotifications ? this.state.listOfNotifications : null,
            userId: localStorage.getItem("useridHR") + ""
        }

        let saveResult
        let res = await http.postMultipartAxios(http.actions.Notifications.urlPostHandleNotifications, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText) {
                    alert(res.data.errorText);
                }
                else {
                    alert($.strings.operationDone);
                }
                this.props.onSuccess();
                this.setState({ loading: false, visibleDialog: false })
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return saveResult = false
        }
    }

    onHide = () => {
        this.setState({ visibleDialog: false, formErrors: { oldPwd: '', newPwd: '', confirmPwd: '' } })
    }
}