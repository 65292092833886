import React from "react";
import styles from './LookupsUserRights.module.scss'
import Button from '../../../Components/Button'
import Messages from '../../../Components/Messages'
import http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import Dialog from '../../../Components/Dialog';
import Util from '../../../Util'
import DataGridView from "../../../Components/DataGridView/DataGridView";
import { async } from "regenerator-runtime";
import Checkbox from "../../../Components/Checkbox";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class LookupsUserRights extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: {},
            isDesktop: true,
            visibleDialog: false,
            empCode: localStorage.getItem("useridHR"),
            data: []
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        let _isDesktop = this.updatePredicate();
        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.checked;

        let _data = this.state.data
        _data = _data.map(
            el => el.user_id + "" === name + "" ? { ...el, has_right: value } : el
        )
        this.setState({ data: _data })
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _newPwd = this.state.newPwd;
        fieldValidationErrors.newPwd = _newPwd ? '' : $.strings.requiredFiled;

        let _confirmPwd = this.state.confirmPwd;
        fieldValidationErrors.confirmPwd = _confirmPwd ? '' : $.strings.requiredFiled;

        if (_confirmPwd + "" !== _newPwd + "") {
            _confirmPwd = undefined
            fieldValidationErrors.confirmPwd = $.strings.confirmPwdError
        }
        this.setState({
            formErrors: fieldValidationErrors,
            newPwdValid: _newPwd,
            confirmPwdValid: _confirmPwd,
        }, this.validateForm);

        return _newPwd && _confirmPwd;
    }
    validateForm() {
        let isFormValid = this.state.newPwdValid && this.state.confirmPwdValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.ok} icon="" className="p-button-success" onClick={async (e) => await this.onSave()}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        const gridStyle = { minHeight: this.state.filterShown ? '40vh' : '50vh' }
        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={$.strings.lookups.userRights} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '450px' : '85vw', direction: $.strings.dir }}
                    modal={true} onHide={this.onHide} blockScroll contentStyle={{ maxHeight: this.state.isDesktop ? '80%' : '370px' }}>
                    <div ref="divMain" >
                        {
                            this.state.isDesktop &&
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-12 p-sm-12">
                                    <DataGridView
                                        style={gridStyle}
                                        ref={e => this.gridData = e}
                                        isReport={false}
                                        showContextMenu={false}
                                        theme="default-light"
                                        idProperty="serial"
                                        scheme={this.getScheme()}
                                        exportFileName={$.strings.clockTrans.reportTitle}
                                        dataSource={this.state.data}
                                        defaultRowHeight={45}
                                        onRowDoubleClick={this.onRowDoubleClick}
                                    />
                                </div>
                            </div>
                        }

                        {
                            !this.state.isDesktop && this.state.data &&
                            this.state.data.map((e, index) => {
                                let rowClass = (index % 2) === 0 ? styles.evenRow : styles.oddRow
                                return (
                                    <div className={`${"p-grid"} ${rowClass}`} style={{ margin: '5px',border:'1px solid gray' }}>
                                        <div className="p-col-12 p-lg-12 p-sm-12">
                                            <div style={{ alignSelf: 'flex-end' }}>#: {e.rowIndex}</div>
                                            <div style={{ alignSelf: 'flex-end' }}>{$.strings.lookups.userName + ": " + e.user_name}</div>
                                            <Checkbox id={e.user_id} caption={$.strings.lookups.hasRight} checked={e.has_right}
                                                onChange={this.handleUserInput} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div >
                </Dialog >
            </div>
        );
    }
    show = async (type, pos) => {
        let dataTemp = await this.getData(type, pos);
        this.setState({ visibleDialog: true, type: type, pos: pos, data: dataTemp || [], loading: false });
    }

    onHide = () => {
        this.setState({ visibleDialog: false, data: [], formErrors: {} });
    }

    getData = async (type, pos) => {
        let dataTemp = [];
        this.setState({ loading: true })
        const params = {
            type: type,
            pos: pos,
            userStatus: -1
        };

        let res = await http.getJsonAxios(http.actions.MultiFl.urlGetMultiFlRights, params, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp;
    }

    onSave = async () => {
        let users = 0;
        this.setState({ loading: true })

        let dataTemp = this.state.data;
        if (dataTemp) {
            for (let index = 0; index < dataTemp.length; index++) {
                if (dataTemp[index]["has_right"]) {
                    users += Math.pow(2, parseInt(dataTemp[index]["user_id"]));
                }
            }
        }
        let obj = {
            users: parseInt(users),
            type: parseInt(this.state.type),
            pos: parseInt(this.state.pos)
        }
        
        let res = await http.postMultipartAxios(http.actions.MultiFl.urlPostUpdateMultiFlUsers, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                this.setState({ loading: false, visibleDialog: false })
            }
            else {
                if (res.data.errorText && res.data.errorText !== "") {
                    alert(res.data.errorText);
                    this.setState({ loading: false })
                }
                else {
                    alert($.strings.operationFailed);
                    this.txtNewPwd.container.firstChild.focus()
                    this.setState({ loading: false })
                }
            }
        }
        else {
            if (res.data.errorText && res.data.errorText !== "") {
                alert(res.data.errorText);
                this.setState({ loading: false })
            }
            else {
                alert($.strings.operationFailed);
                this.setState({ loading: false })
            }
        }
    }

    getScheme = () => {
        return {
            name: "lookupsRights_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                {
                    name: 'rowIndex', header: '#', width: 70, dataType: 'Number', isReadOnly: true
                },
                {
                    name: 'user_id', header: $.strings.lookups.userId, visible: false, visibleInColumnChooser: false, isReadOnly: true
                },
                {
                    name: 'has_right', header: $.strings.lookups.hasRight, width: 80, isReadOnly: false
                },
                {
                    name: 'user_name', header: $.strings.lookups.userName, width: this.state.isDesktop ? '*' : 300, isReadOnly: true
                }
            ]
        }
    }
}