import React from "react";
import Button from '../../../Components/Button'
import Messages from '../../../Components/Messages'
import http from "../../../Api/http";
import ProgressSpinner from '../../../Components/ProgressSpinner'
import Dialog from '../../../Components/Dialog';
import Util from '../../../Util'
import Input from "../../../Components/Input";
import Dropdown from "../../../Components/Dropdown";
import { Fieldset } from "primereact/fieldset";
import InputTime from "../../../Components/InputTime";
import DataGridView from "../../../Components/DataGridView/DataGridView";
import SCC from "../../ScreenCommonCode";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class ShiftsSearch extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            isDesktop: window.innerWidth > 1250,
            visibleDialog: false,
            data: [],
            dataObject: {
                shiftCode: undefined,
                shiftName: undefined,
            }
        }
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250;
        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.ok} icon="" className="p-button-success" onClick={this.onAccept}>
                    &nbsp;&nbsp;
                    <i className="pi pi-check" />
                </Button>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );
        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        const gridStyle = { minHeight: '30vh' }

        return (
            <div>
                <Dialog header={$.strings.shifts.titleSearch} footer={!this.state.isViewOnly ? footer : undefined}
                    icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir }}
                    modal onHide={this.onHide}>
                    <ProgressSpinner loading={this.state.loading} />
                    <div className="p-grid" style={{ width: '100%' }}>
                        <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                            <Messages innerRef={(el) => this.messages = el} />
                        </div>
                    </div>
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-2 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtCode = el}
                                    id="shiftCode"
                                    value={this.state.dataObject.shiftCode}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.shifts.code}
                                    onChange={e => SCC.handleUserInput(this, e)}
                                    onBlur={async e => await this.getData()}
                                />
                            </div>

                            <div className="p-col-12 p-lg-4 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtName = el}
                                    id="shiftName"
                                    value={this.state.dataObject.shiftName}
                                    autofocus={true}
                                    autocomplete="off"
                                    caption={$.strings.shifts.shiftName}
                                    onChange={e => SCC.handleUserInput(this, e)}
                                    onBlur={async e => await this.getData()}
                                />
                            </div>
                            <div className="p-col-1 p-lg-1 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Button icon="pi pi-search" onClick={e => this.getData(false)} />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <DataGridView
                                    ref={e => this.gridData = e}
                                    style={gridStyle}
                                    isReport={true}
                                    showContextMenu={false}
                                    theme="default-light"
                                    idProperty="id"
                                    scheme={this.getScheme()}
                                    defaultRowHeight={45}
                                    dataSource={this.state.data}
                                />
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div>
        );
    }
    show = async () => {
        let obj = undefined;

        let data = await this.getData(true)
        this.setState({
            visibleDialog: true, formErrors: {}, loading: false, data: data,
            dataObject: {
                ...this.state.dataObject,
                fromTime: obj ? obj.fromTime : '00:00',
                toTime: obj ? obj.toTime : '00:00',
            }
        });
    }

    onHide = () => {
        this.setState({ data: [], visibleDialog: false })
    }

    getData = async (fromLoad) => {
        if (!fromLoad)
            this.setState({ loading: true });
        let dataTemp = [];
        let obj = {
            shiftCode: this.state.dataObject.shiftCode ? this.state.dataObject.shiftCode : '',
            shiftName: this.state.dataObject.shiftName ? this.state.dataObject.shiftName : ''
        }

        let res = await http.getJsonAxios(http.actions.Shifts.urlGetShiftsSearch, obj, 0);
        if (res.status === 200) {
            if (res.data)
                dataTemp = res.data.list;
        }
        
        if (!fromLoad)
            this.setState({ data: dataTemp, loading: false })
        else
            return dataTemp
    }

    onAccept = () => {
        let selectedShift = this.gridData.getCurrentRowItem();
        if (selectedShift) {
            if (this.props.onSuccess)
                this.props.onSuccess(selectedShift)
            this.setState({ visibleDialog: false })
        }
        else {
            Util.showErrorMsg(this.messages, $.strings.selectRow)
        }
    }

    buttonSelect = (e, ctx) => {
        if (ctx.item) {
            if (this.props.onSuccess)
                this.props.onSuccess(ctx.item)
            this.setState({ visibleDialog: false })
        }
        else {
            Util.showErrorMsg(this.messages, $.strings.selectRow)
        }
    }
    getScheme = () => {
        return {
            name: "shiftsSearchScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 4,
            columns: [
                { name: 'rowNumber', header: '##', width: 60, dataType: 'Number', aggregate: 'Cnt' },
                { name: 'code', header: $.strings.periods.code, width: 100, visible: false, },
                {
                    name: 'buttonView', header: ' ', width: 50, buttonBody: 'button', align: 'center',
                    title: $.strings.view, iconType: 'search', className: 'info', onClick: (e, ctx) => this.buttonSelect(e, ctx),
                    visible: Util.checkISHR(), visibleInColumnChooser: Util.checkISHR()
                },
                { name: 'year', header: $.strings.shifts.year, width: 100 },
                {
                    name: 'shiftName', header: $.strings.shifts.shiftName, width: "*",
                }
            ]
        }
    }

}