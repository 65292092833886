import React from "react";
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages';
import Http from '../../Api/http';
import ProgressSpinner from '../../Components/ProgressSpinner';
import DeleteNoteDialog from '../../Components/DeleteNoteDialog';
import Util from '../../Util';
import Card from "../../Components/Card";
import Dialog from "../../Components/Dialog";
import SCC from '../ScreenCommonCode'
import RadioButton from "../../Components/RadioButton/index";
import { Fieldset } from 'primereact/fieldset';
import Dropdown from "../../Components/Dropdown";
import Checkbox from "../../Components/Checkbox";
import DataGridView from "../../Components/DataGridView/DataGridView";
import * as wjcCore from '@grapecity/wijmo';
import * as wjGrid from '@grapecity/wijmo.grid';
import "@grapecity/wijmo.styles/wijmo.css";
import Button from "../../Components/Button";
import { Toast } from 'primereact/toast';
import AlertBox from "../../Components/AlertBox";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class LateSettings extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null;
        this.discountTypesList = [
            { id: 0, name: $.strings.lateSettings.none },
            { id: 1, name: $.strings.lateSettings.allDelay },
            { id: 2, name: $.strings.lateSettings.increaseOnly },
        ]
        this.emptyDataObject = {
            SettingsID: '',
            Type: 1,
            Name: '',
            EName: '',
            hasDeduction: false,
            hoursAllowed: 0,
            deductionType: this.discountTypesList[0],
            deductionDays: 0,
            TransCount: 0,
            absTransType: undefined,
            Note: '',
            data: []
        }
        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            formErrors: {},
            deleteErrors: { deleteNote: '' },
            selectedRow: {},
            dataObject: this.emptyDataObject,
            mode: 'new'
        }
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250
        let transTypeList = await this.getTransTypesList();
        this.setState({ transTypeList: transTypeList, loading: false, isDesktop: _isDesktop });
    }

    popupHasCalled = () => {
        document.removeEventListener('keydown', this.onPageKeyDown);
    }
    popupHasClosed = () => {
        document.addEventListener('keydown', this.onPageKeyDown);
    }

    validateFields() {
        let result = true;
        let fieldsValidationErrors = this.state.formErrors;

        fieldsValidationErrors.Name = "";
        if (!this.state.dataObject.Name || this.state.dataObject.Name <= 0) {
            fieldsValidationErrors.Name = $.strings.requiredFiled;
            result = false;
        }
        fieldsValidationErrors.EName = "";
        if (!this.state.dataObject.EName || this.state.dataObject.EName <= 0) {
            fieldsValidationErrors.EName = $.strings.requiredFiled;
            result = false;
        }


        return result;
    }

    render() {
        const editLateFooter = (
            <React.Fragment>
                <Button label={$.strings.cancel} icon="pi pi-times" iconPos={$.strings.dir === "rtl" ? 'right' : 'left'}
                    className="p-button-text p-button-danger" onClick={this.hideLateDialog} />
                <Button label={$.strings.save} icon="pi pi-check" iconPos={$.strings.dir === "rtl" ? 'right' : 'left'}
                    className="p-button-text p-button-success" onClick={this.saveLate} />
            </React.Fragment>
        );
        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                {
                    this.state.alertBoxVisible &&
                    <AlertBox ref={e => this.alertBox = e} header={$.strings.appName} appendTo={this.props.parent} yesCaption={$.strings.ok} />
                }
                <Dialog header={this.state.pageInfo.title} onHide={this.onClose} modal
                    visible={this.state.visibleDialog} closeOnEscape={false}
                    style={{ width: this.state.isDesktop ? '40vw' : '80vw', direction: $.strings.dir }}>
                    <Card>
                        <ButtonsBarLookup
                            onNew={e => this.onNew(e, true)}
                            onDelete={async (e) => await this.onDelete(e, true)}
                            onClose={e => this.onClose(e, true)}
                            onSave={e => this.onSave(e, true)}
                            onPrevious={e => this.onPrevious(e, true)}
                            onNext={e => this.onNext(e, true)}
                            onFirst={e => this.onFirst(e, true)}
                            onLast={e => this.onLast(e, true)}
                            onSaveThenClose={e => this.onSaveThenClose(e)} />
                        <Messages innerRef={(el) => this.messages = el} />
                    </Card>

                    <div className="p-grid">
                        <div className="p-col-12 p-lg-4 p-sm-12">
                            <Input
                                innerRef={(el) => this.txtCode = el}
                                id="SettingsID"
                                maxLength="50"
                                value={this.state.dataObject.SettingsID}
                                autofocus={true}
                                caption={$.strings.lateSettings.code}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                innerRef={e => this.txtName = e}
                                id="Name"
                                maxLength="50"
                                value={this.state.dataObject.Name}
                                autoFocus={true}
                                caption={$.strings.lateSettings.name}
                                onChange={(e) => SCC.handleUserInput(this, e)}
                                onBlur={this.onNameBlur}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="EName"
                                maxLength="50"
                                value={this.state.dataObject.EName || ''}
                                caption={$.strings.sections.eName}
                                onChange={(e) => SCC.handleUserInput(this, e)}
                                isRequired={true}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-4 p-sm-12" style={{ alignSelf: 'center' }}>
                            <Checkbox id="hasDeduction" caption={$.strings.lateSettings.isDelayPenalties}
                                onChange={(e) => SCC.handleUserCheckbox(this, e)}
                                checked={this.state.dataObject.hasDeduction} />
                        </div>
                        <div className="p-col-12 p-lg-4 p-sm-12">
                            <Input
                                id="hoursAllowed"
                                keyfilter="pnum"
                                value={this.state.dataObject.hoursAllowed}
                                caption={$.strings.lateSettings.allowdLateHours}
                                onChange={(e) => SCC.handleUserInput(this, e)}
                            />
                        </div>
                        <div className="p-col-12 p-lg-4 p-sm-12">
                            <Input
                                id="TransCount"
                                keyfilter="pint"
                                value={this.state.dataObject.TransCount}
                                caption={$.strings.lateSettings.repeatDelayTimes}
                                onChange={(e) => SCC.handleUserInput(this, e)}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-4 p-sm-12">
                            <Dropdown
                                id="deductionType"
                                innerRef={e => this.drpDiscountType = e}
                                value={this.state.dataObject.deductionType}
                                options={this.discountTypesList}
                                caption={$.strings.lateSettings.discountType}
                                onChange={(e) => SCC.handleUserInput(this, e)}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-4 p-sm-12">
                            <Input
                                id="deductionDays"
                                keyfilter="pnum"
                                value={this.state.dataObject.deductionDays}
                                caption={$.strings.lateSettings.discounDaysCount}
                                onChange={(e) => SCC.handleUserInput(this, e)}
                            />
                        </div>
                        <div className="p-col-12 p-lg-4 p-sm-12">
                            <Dropdown
                                id="absTransType"
                                innerRef={e => this.drpTransType = e}
                                value={this.state.dataObject.absTransType}
                                options={this.state.transTypeList}
                                caption={$.strings.lateSettings.transType}
                                onChange={(e) => SCC.handleUserInput(this, e)}
                                optionLabel="name"
                                filter={true}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <Input
                                id="Note"
                                maxLength="80"
                                value={this.state.dataObject.Note}
                                caption={$.strings.lateSettings.notes}
                                onChange={(e) => SCC.handleUserInput(this, e)}
                                onBlur={this.onBlueNotes}
                            />
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <Button label={""} icon="pi pi-plus" onClick={this.onAddLateSettings} />
                            <Card>
                                <DataGridView
                                    style={{ maxHeight: '200px', height: '200px' }}
                                    ref={e => this.gridData = e}
                                    isReport={false}
                                    showContextMenu={false}
                                    scheme={this.getScheme()}
                                    defaultRowHeight={45}
                                    allowAddNew={false}
                                    keyActionEnter='CycleEditable'
                                    dataSource={new wjcCore.CollectionView(this.state.dataObject.data, {
                                        getError: this._getError.bind(this),
                                    })}
                                    //onKeyDown={this.onKeyDown}
                                    gotFocus={s => this.startEditing(s)}
                                    selectionChanged={s => this.startEditing(s)}
                                    cellEditEnding={this.cellEditEnding}
                                    cellEditEnded={this.cellEditEnded}
                                />
                            </Card>
                        </div>
                    </div>
                    <Dialog visible={this.state.visibleLateDialog} style={{ width: this.state.isDesktop ? '300px' : '75%' }} header={this.state.pageInfo.title}
                        modal className="p-fluid" footer={editLateFooter} onHide={this.hideLateDialog} contentStyle={{ direction: $.strings.dir }}>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    id="TransCount"
                                    keyfilter="pint"
                                    value={this.state.selectedRow.TransCount}
                                    autoFocus={true}
                                    caption={$.strings.lateSettings.lateCount}
                                    onChange={e => this.setState({ selectedRow: { ...this.state.selectedRow, TransCount: e.target.value } }, () => {
                                        let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
                                        this.gridData.flex.select(rowIndex, 1)
                                    })}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    id="Deduction"
                                    keyfilter="pnum"
                                    value={this.state.selectedRow.Deduction}
                                    caption={$.strings.lateSettings.discounDaysCount}
                                    onChange={e => this.setState({ selectedRow: { ...this.state.selectedRow, Deduction: e.target.value } }, () => {
                                        let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
                                        this.gridData.flex.select(rowIndex, 1)
                                    })}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    id="Note"
                                    maxLength="70"
                                    value={this.state.selectedRow.Note}
                                    caption={$.strings.lateSettings.notes}
                                    onChange={e => this.setState({ selectedRow: { ...this.state.selectedRow, Note: e.target.value } }, () => {
                                        let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
                                        this.gridData.flex.select(rowIndex, 1)
                                    })}
                                />
                            </div>
                        </div>
                    </Dialog>
                </Dialog>
            </div>
        );
    }
    onNameBlur = (e) => {
        if (!this.state.dataObject.EName)
            this.setState({ dataObject: { ...this.state.dataObject, EName: e.target.value } }, e.target.selectAll);
    }

    getTransTypesList = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 22
        }
        let res = await Http.getJsonAxios(Http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }

    onNew = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
                async () => await this.onSave(e, false),
                () => this.onNew(e, false)
            );
            return;
        }
        this.messages.clear();
        this.resetFields()
    }

    getMaxID = async (fromLoad) => {
        let code = '';
        if (!fromLoad)
            this.setState({ loading: true });
        const params = {

        };

        let res = await Http.getJsonAxios(Http.actions.LateSettings.urlGetMaxSettingId, params, 0);
        if (res.status === 200) {
            code = res.data;
        }
        if (fromLoad)
            return code;
        else {
            this.setState({ dataObject: { ...this.state.dataObject, code: code }, loading: false })
        }
    };

    resetFields = async () => {
        let maxCode = await this.getMaxID(true);
        let obj = this.emptyDataObject;
        obj.SettingsID = maxCode;
        obj.data = [{
            rowNumber: 1, TransCount: '', Deduction: '', Note: ''
        }]

        this.setState({
            selectedRow: {},
            dataObject: this.emptyDataObject,
            mode: 'new',
            loading: false
        }, () => { this.txtName.focus(); this.hash = Util.hashState(this.state.dataObject); });
    }

    onSave = async (e) => {
        this.save(e, true, false);
    };

    onSaveThenClose = async (e) => {
        this.save(e, true, true);
    };

    save = async (e, fromSave, closeAfterSave) => {
        //waiting progress
        this.setState({ loading: true });

        //validate
        if (!this.validateFields()) {
            this.setState({ loading: false, showErrors: true });
            return false;
        }

        let isSucceeded = true;
        let result = await this.sendData();

        if (result.status === 200) {
            if (result.data.success) {
                if (closeAfterSave) {
                    if (this.props.onAddSuccess) {
                        this.props.onAddSuccess(true);
                    }
                    this.setState({ visibleDialog: false, loading: false });
                } else {
                    Util.showSuccessMsg(this.messages);
                    if (fromSave) {
                        this.needRefresh = true;
                        this.resetFields();
                    }
                    this.hash = Util.hashState(this.state.dataObject);
                }
            }
            else {
                isSucceeded = false;
                let msg = $.strings.operationFailed;
                if (result.data.errorText && result.data.errorText.length > 0) {
                    msg = result.data.errorText;
                    Util.showErrorMsg(this.messages, msg, false);
                }
            }
        } else {
            isSucceeded = false;
            let msg = $.strings.operationFailed;
            if (result.errorText && result.errorText.length > 0) {
                msg = result.errorText;
                Util.showErrorMsg(this.messages, msg, false);
            }
        }
        this.setState({ loading: false });
        return isSucceeded;
    };

    sendData = async () => {
        let lateSettingsDetailsList = [];
        let dataTemp = this.state.dataObject.data;
        if (dataTemp) {
            for (let index = 0; index < dataTemp.length; index++) {
                lateSettingsDetailsList.push({
                    SettingsID: this.state.dataObject.mode === "new" ? 0 : this.state.dataObject.SettingsID,
                    TransCount: dataTemp[index]["TransCount"],
                    Deduction: dataTemp[index]["Deduction"],
                    DeductionType: 0,
                    OtherPenalties: 0,
                    Note: dataTemp[index]["Note"],
                })
            }
        }

        let obj = {
            SettingsID: this.state.mode === "new" ? 0 : this.state.dataObject.SettingsID,
            Type: 1,
            Name: this.state.dataObject.Name,
            Ename: this.state.dataObject.EName,
            hasdeduction: this.state.dataObject.hasDeduction ? 1 : 0,
            AbsTransType: this.state.dataObject.absTransType ? this.state.dataObject.absTransType.pos : -1,
            HoursAllowed: this.state.dataObject.hoursAllowed,
            DeductionType: this.state.dataObject.deductionType.id,
            TransCount: this.state.dataObject.TransCount,
            DeductionDays: this.state.dataObject.deductionDays,
            Note: this.state.dataObject.Note,
            lateSettingsDetailsList: lateSettingsDetailsList
        }

        let result
        let res = await Http.postMultipartAxios(Http.actions.LateSettings.urlPostSaveLateSettingsAndDetails, obj, 0);
        result = res;
        return result;
    };

    onFirst = (e, checkChanging) => {
        this.navigate(Util.navigationEnum.FIRST, checkChanging, '');
    }

    onPrevious = (e, checkChanging) => {
        this.navigate(Util.navigationEnum.PREVIOUS, checkChanging, '');
    }

    onNext = (e, checkChanging) => {
        this.navigate(Util.navigationEnum.NEXT, checkChanging, '');
    }

    onLast = (e, checkChanging) => {
        this.navigate(Util.navigationEnum.LAST, checkChanging, '');
    }

    navigate = async (navigationType, checkChanging, specificCode) => {
        //check fields changing
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged,
                'pi pi-question-circle',
                async (e) => { await this.save(e, false, false); this.navigate(navigationType, false, specificCode) },
                (e) => {
                    switch (navigationType) {
                        case Util.navigationEnum.PREVIOUS:
                            this.onPrevious(e, false);
                            break;
                        case Util.navigationEnum.NEXT:
                            this.onNext(e, false);
                            break;
                        case Util.navigationEnum.FIRST:
                            this.onFirst(e, false);
                            break;
                        case Util.navigationEnum.LAST:
                            this.onLast(e, false);
                            break;
                        default: {
                            break;
                        }
                    }
                });
            return;
        }
        this.setState({ loading: true });
        //determind the id
        let code = 0;
        if (
            navigationType === Util.navigationEnum.PREVIOUS ||
            navigationType === Util.navigationEnum.NEXT
        ) {
            if (this.state.dataObject.SettingsID)
                code = this.state.dataObject.SettingsID;
            else {
                if (navigationType === Util.navigationEnum.PREVIOUS)
                    navigationType = Util.navigationEnum.FIRST;
                else
                    navigationType = Util.navigationEnum.LAST;
            }
        }
        if (navigationType === Util.navigationEnum.LAST) code = 9999999;
        if (navigationType === Util.navigationEnum.GETIT) code = specificCode;

        let param = {
            settingId: code,
        };

        let url = "";
        switch (navigationType) {
            case Util.navigationEnum.PREVIOUS:
                url = Http.actions.LateSettings.urlGetPrevLateSettings;
                break;
            case Util.navigationEnum.NEXT:
                url = Http.actions.LateSettings.urlGetNextLateSettings;
                break;
            case Util.navigationEnum.FIRST:
                url = Http.actions.LateSettings.urlGetFirstLateSettings;
                break;
            case Util.navigationEnum.LAST:
                url = Http.actions.LateSettings.urlGetLastLateSettings;
                break;
            case Util.navigationEnum.GETIT:
            default: {
                url = Http.actions.LateSettings.urlGetLateSettingsAndDetails;
                break;
            }
        }

        let res = await Http.getJsonAxios(url, param, 0);
        let result = false;
        if (res.status === 200) {
            if (res.data.success) {
                result = true;
                if (res.data.lateSettingsObj && res.data.lateSettingsObj.SettingsID === this.state.dataObject.SettingsID) {
                    if (navigationType === Util.navigationEnum.PREVIOUS)
                        Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
                    if (navigationType === Util.navigationEnum.NEXT)
                        Util.showInfoMsg(this.messages, $.strings.recordsEnds);
                    this.setState({ loading: false });
                } else {
                    let obj;
                    if (res.data.lateSettingsObj) {
                        obj = res.data.lateSettingsObj;
                    }
                    this.display(obj);
                }
            }
        }
        if (!result) {
            Util.showInfoMsg(this.messages, $.strings.operationFailed);
            this.setState({ loading: false });
        }
    }
    display = (data) => {
        if (!data) {
            return;
        }

        if (this.messages)
            this.messages.clear();
        if (data.lateSettingsDetailsList) {
            let index = 1;
            data.lateSettingsDetailsList.forEach(element => {
                element.rowNumber = index;
                index++;
            });
        }
        this.setState(
            {
                dataObject: {
                    ...this.state.dataObject,
                    SettingsID: data.SettingsID,
                    Type: data.Type,
                    Name: data.Name,
                    EName: data.Ename,
                    hasDeduction: data.hasdeduction,
                    hoursAllowed: data.HoursAllowed,
                    deductionType: this.discountTypesList.find(e => e.id + "" === data.DeductionType + ""),
                    deductionDays: data.DeductionDays,
                    TransCount: data.TransCount,
                    absTransType: this.state.transTypeList.find(e => e.pos + "" === data.AbsTransType + ""),
                    Note: data.Note,
                    data: data.lateSettingsDetailsList
                },
                mode: 'edit',
                formErrors: {},
                visibleDialog: true,
                loading: false
            }, () => {
                this.hash = Util.hashState(this.state.dataObject)
                setTimeout(() => {
                    this.txtName.focus()
                }, 10);
            }
        );
    };

    onDelete = async (e, showMsg) => {
        if (showMsg) {
            Util.confirmDialog($.strings.lateSettings.deleteConfirm, 'pi pi-question-circle',
                async () => { this.onDelete(e, false) },
                () => undefined
            );
            return;
        }
        this.setState({ loading: true })
        let obj = {
            settingId: this.state.dataObject.SettingsID
        }

        let res = await Http.getJsonAxios(Http.actions.LateSettings.urlDeleteLateSettings, obj, 0);
        if (res.status === 200 && res.data.success) {
            Util.showSuccessMsg(this.messages)
            this.resetFields()
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.errorText)
                msg = res.data.errorText;

            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false })
        }
    }

    show = async () => {
        let maxCode = await this.getMaxID(true);
        let obj = this.emptyDataObject;
        obj.SettingsID = maxCode;
        obj.data = [];
        if (this.state.isDesktop) {
            obj.data = [{ rowNumber: 1, TransCount: '', Deduction: '', Note: '' }]
        }

        obj.code =
            this.setState({
                visibleDialog: true,
                loading: false,
                formErrors: {},

                dataObject: this.emptyDataObject
            }, () => this.hash = Util.hashState(this.state.dataObject));

    }

    onClose = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
                async () => await this.onSaveThenClose(e, false),
                () => this.onClose(e, false)
            );
            return;
        }
        let doRefresh = this.needRefresh
        if (this.props.onAddClose) {
            this.props.onAddClose(doRefresh);
        }
        this.setState({ dataObject: this.emptyDataObject, visibleDialog: false, loading: false }, () => this.hash = Util.hashState(this.state.dataObject))
    }

    onEditClick = (e, ctx) => {
        let item = ctx.item;
        let index = this.gridData.flex.selection.row;
        this.setState({ messageBoxEventArgs: { item, index }, visibleLateDialog: true, selectedRow: item, isNew: false }, () => {
            let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
            this.gridData.flex.select(rowIndex, 1)
        });
    }

    onDeleteClick = (e, ctx, showMsg) => {
        if (showMsg) {
            Util.confirmDialog($.strings.lateSettings.deleteSettingsConfirm, 'pi pi-question-circle',
                async () => { this.onDeleteClick(e, ctx, false) },
                () => undefined
            );
            return;
        }
        let index = this.gridData.flex.selection.row;
        let dataTemp = this.state.dataObject.data;
        if (dataTemp) {
            dataTemp.splice(index, 1);

            this.setState({ dataObject: { ...this.state.dataObject, data: dataTemp }, }, () => {
                let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
                this.gridData.flex.select(rowIndex, 1)
            });
        }
    }

    onBlueNotes = () => {
        if (this.gridData) {
            this.gridData.focus();
            this.gridData.select(this.gridData.flex.rows.length - 1, 2)
            this.gridData.startEditing(true, this.gridData.flex.rows.length - 1, 2)
        }
    }
    messageBoxOk = () => {
        this.setState({ visibleMessageBox: false })
    }
    onAddLateSettings = () => {
        if (this.state.isDesktop) {
            let dataTemp = this.state.dataObject.data;
            if (dataTemp && dataTemp.length > 0 && !dataTemp[dataTemp.length - 1]["TransCount"]) {
                this.gridData.focus();
                this.gridData.select(this.gridData.flex.rows.length - 1, 2)
                this.gridData.startEditing(true, this.gridData.flex.rows.length - 1, 2)
                return
            }
            dataTemp.push({
                rowNumber: dataTemp.length + 1,
            })
            this.setState({ dataObject: { ...this.state.dataObject, data: dataTemp } }, () => {
                this.gridData.focus();
                this.gridData.select(this.gridData.flex.rows.length - 1, 2)
                this.gridData.startEditing(true, this.gridData.flex.rows.length - 1, 2)
            });
        }
        else {
            this.setState({ visibleLateDialog: true, isNew: true }, () => {
                this.gridData.focus();
                this.gridData.select(this.gridData.flex.rows.length - 1, 2)
                this.gridData.startEditing(true, this.gridData.flex.rows.length - 1, 2)
            })
        }
    }
    getScheme = () => {
        return {
            name: "lateSettings_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 4,
            columns: [
                { name: 'rowNumber', header: '#', width: 80, dataType: 'Number', aggregate: 'Cnt', isReadOnly: true },
                {
                    name: 'btnEdit', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.edit,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEditClick(e, ctx),
                    visible: Util.checkISHR() && $.license.Att_Module, visibleInColumnChooser: false
                },
                {
                    name: 'btnDelete', header: ' ', width: 50, buttonBody: 'button', align: 'center', title: $.strings.delete,
                    iconType: 'delete', className: 'danger', onClick: (e, ctx) => this.onDeleteClick(e, ctx, true),
                    visible: Util.checkISHR() && $.license.Att_Module, visibleInColumnChooser: false
                },
                { name: 'TransCount', header: $.strings.lateSettings.lateCount, width: 150 },
                { name: 'Deduction', header: $.strings.lateSettings.discounDaysCount, width: 150 },
                { name: 'Note', header: $.strings.lateSettings.notes, width: '*', }
            ]
        }
    }
    _getError(item, propName, parsing = false) {
        switch (propName) {
            case "TransCount": {
                if (!item[propName] || item[propName] + "" === "") {
                    return $.strings.lateSettings.lateCountValueError;
                }
                if (isNaN(item[propName])) {
                    return $.strings.lateSettings.lateCountValueError;
                }

                return '';
            }
            case "Deduction": {
                if (!item[propName] || item[propName] + "" === "") {
                    return $.strings.lateSettings.discounDaysCountValueError;
                }
                if (isNaN(item[propName])) {
                    return $.strings.lateSettings.discounDaysCountValueError;
                }
                return '';
            }
            case "Note": {
                break;
            }
            default: {
                break;
            }
        }
        return null;
    }
    startEditing = (flex) => {
        let index = flex.selection.col, col = index > -1 ? flex.columns[index] : null;
        if (col && !col.isReadOnly) {
            setTimeout(() => {
                flex.startEditing(false); // quick mode
            }, 50); // let the grid update first
        }
    }
    cellEditEnding = (s, e) => { // s: grid, e: eventArgs
        let colName = e.panel._cols[e.col]._name;
        let value = s.activeEditor.value;
        let dataTemp = this.state.dataObject.data;
        switch (colName) {
            case "TransCount": {
                if (!value || value < 0) {
                    e.cancel = true;
                }
                else {
                    let isFound = dataTemp.filter(e => e.TransCount + "" === value + "")
                    if (isFound && isFound.length > 0) {
                        let foundIndex = dataTemp.findIndex(e => e.TransCount + "" === value + "")
                        if (e.row !== foundIndex && foundIndex > -1) {
                            e.cancel = true;
                            e.stayInEditMode = true;
                            s.rows[e.row].dataItem.isError = true;
                            // this.alertBox.show(
                            //     (e) => { },
                            //     undefined,
                            //     undefined,
                            //     $.strings.lateSettings.lateCountError
                            // )
                            this.popupHasCalled();
                            this.setState({ alertBoxVisible: true }, () => {
                                this.alertBox.show(
                                    async () => {
                                        this.popupHasClosed();
                                        this.setState({ alertBoxVisible: false })
                                    },
                                    undefined,
                                    undefined,
                                    $.strings.lateSettings.lateCountError
                                );
                            })
                            return;
                        }
                    }
                }
                break;
            }
            default: {
                break;
            }
        }
    }
    cellEditEnded = (s, e) => { // s: grid, e: eventArgs
        let colName = e.panel._cols[e.col]._name
        let value = s.getCellData(e.row, e.col);
        let dataTemp = this.state.dataObject.data;
        switch (colName) {
            case "TransCount": {
                if (value && value.length > 0) {
                    dataTemp[e.row]["TransCount"] = value;
                    this.setState({ dataObject: { ...this.state.dataObject, data: dataTemp } }, () => {
                        this.gridData.focus();
                        this.gridData.select(e.row, 2)
                        this.gridData.startEditing(true, e.row, 2)
                    });
                }
                break;
            }
            default: {
                break;
            }
        }
        return;
    }
    onKeyDown = (flex, e) => {
        let currentRow = flex.selection.row, row = flex.rows[currentRow];
        let currentCol = flex.selection.col

        if (e.keyCode === 13 && currentRow === flex.rows.length - 1 && row && row.dataItem.Note) {
            flex.collectionView.addNew(new wjGrid.Row({ Note: '' })) // create new item and insert it to the last position
            let dataTemp = this.state.dataObject.data;
            dataTemp[flex.rows.length - 1]['rowNumber'] = flex.rows.length;
            this.setState({ dataObject: { ...this.state.dataObject, data: dataTemp } }, () => {
                this.gridData.focus();
                this.gridData.select(currentRow, 2)
                this.gridData.startEditing(true, currentRow, 2)
            });
            flex.startEditing(true, currentRow + 1, 2) // move selection to next row first editable cell and start editing
        }
    }

    setPageInfo = () => {
        let pageId
        let title = $.strings.lateSettings.title;

        return { pageId: pageId, title: title };
    };

    saveLate = () => {
        let _formErrors = this.state.formErrors;
        let result = true;
        _formErrors.TransCount = "";
        if (!this.state.selectedRow || this.state.selectedRow.TransCount === undefined || (this.state.selectedRow.TransCount + "").length === 0) {
            _formErrors.TransCount = $.strings.requiredFiled;
            result = false;;
        }
        _formErrors.Deduction = "";
        if (!this.state.selectedRow || this.state.selectedRow.Deduction === undefined) {
            _formErrors.Deduction = $.strings.requiredFiled;
            result = false;
        }
        if (!result) {
            this.setState({ formErrors: _formErrors }, () => {
                let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
                this.gridData.flex.select(rowIndex, 1)
            })
            return;
        }
        let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1;
        let dataTemp = this.state.dataObject.data

        if (this.state.isNew) {
            // add Late
            if (dataTemp && dataTemp.length > 0) {
                let found = dataTemp.filter(e => e.TransCount === this.state.selectedRow.TransCount)
                if (found && found.length > 0) {
                    _formErrors.TransCount = $.strings.lateSettings.lateCountError;
                    this.setState({ formErrors: _formErrors }, () => {
                        let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
                        this.gridData.flex.select(rowIndex, 1)
                    })
                    return;
                }
            }
            dataTemp.push({
                rowNumber: dataTemp.length + 1,
                TransCount: this.state.selectedRow.TransCount,
                Deduction: this.state.selectedRow.Deduction,
                Note: this.state.selectedRow.Note
            })
        }
        else {
            // edit Late
            if (dataTemp && dataTemp.length > 0) {
                let found = dataTemp.filter(e => e.TransCount === this.state.selectedRow.TransCount)
                let foundIndex = dataTemp.findIndex(e => e.TransCount === this.state.selectedRow.TransCount)
                if (found && found.length > 0) {
                    if (rowIndex !== foundIndex) {
                        _formErrors.TransCount = $.strings.lateSettings.lateCountError;
                        this.setState({ formErrors: _formErrors }, () => {
                            let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
                            this.gridData.flex.select(rowIndex, 1)
                        })
                        return;
                    }
                }
            }
            dataTemp[rowIndex]["TransCount"] = this.state.selectedRow.TransCount;
            dataTemp[rowIndex]["Deduction"] = this.state.selectedRow.Deduction;
            dataTemp[rowIndex]["Note"] = this.state.selectedRow.Note;
        }
        this.setState({ dataObject: { ...this.state.dataObject, data: dataTemp }, visibleLateDialog: false, selectedRow: {} }, () => {
            let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
            this.gridData.flex.select(rowIndex, 1)
        });
    }
    hideLateDialog = () => {
        this.setState({ visibleLateDialog: false }, () => {
            let rowIndex = this.state.messageBoxEventArgs ? this.state.messageBoxEventArgs.index : -1
            this.gridData.flex.select(rowIndex, 1)
        })
    }
}