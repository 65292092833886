import React from "react";
import Input from "../../Components/Password";
import Button from '../../Components/Button'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import Editor from "../../Components/InputEditor";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class SeeNews extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            mode: 'new',
            isDesktop: true,
            visibleDialog: false,
            newsList: [],
            newsId: '',
            destinationID: '',
            newsTitle: '',
            newsDetails: ''
        }
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250
        this.setState({ isDesktop: _isDesktop, loading: false })
    }

    render() {
        const footer = (
            <div style={{ textAlign: 'center' }}>
                <Button label={$.strings.cancel} icon="" className="p-button-danger" onClick={this.onHide}>
                    &nbsp;&nbsp;
                    <i className="pi pi-times" />
                </Button>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={$.strings.seenNews.title} footer={footer} icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '50vw' : '90vw', direction: $.strings.dir }}
                    modal onHide={this.onHide}>
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <h2>{this.state.newsTitle}</h2>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Editor
                                    innerRef={e => this.editor = e}
                                    value={this.state.newsDetails}
                                    caption={$.strings.newsAdd.details}
                                    style={{ height: '350px', pointerEvents: "none" }}
                                    headerTemplate={<div></div>}
                                    onTextChange={this.onChange}
                                    readOnly
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div>
        );
    }
    onChange = () => {

    }
    show = (newsId, destinationID, newsTitle, newsDetails) => {
        this.setState({
            visibleDialog: true, newsId: newsId, destinationID: destinationID, newsTitle: newsTitle, newsDetails: newsDetails
        }, async () => { await this.setNewsSeen() });
    }

    onHide = () => {
        if (this.state.newsList) {
            if (this.props.onSuccess) {
                this.props.onSuccess(this.state.newsList)
            }
        }
        this.setState({
            visibleDialog: false, newsId: '', destinationID: '',
            newsTitle: '', newsDetails: ''
        })
    }

    setNewsSeen = async () => {
        this.setState({ loading: true })

        let obj = {
            newsID: this.state.newsId,
            destinationID: this.state.destinationID
        }

        let res = await http.getJsonAxios(http.actions.News.urlSetNewsRead, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                this.setState({ newsList: res.data.newsList, loading: false, doRefreshNews: true })
                return
            }
        }
        this.setState({ loading: false })
    }
}